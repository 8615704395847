import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { CompanyTypeModel } from "../../http/Company/Models/CompanyTypeModel";
import { OnBoardingRuleSetViewModel } from "../../http/Redux/Models/OnBoardingRuleSetViewModel";
import { ArtefactToString, Artefacts } from "./StepsModel";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import useCompanyTypes from "../../util/hooks/queries/useCompanyTypes";


const SetupDropDown = ({ Set, handleSendToList, company }:
  {
    Set: OnBoardingRuleSetViewModel,
    // eslint-disable-next-line no-unused-vars
    handleSendToList: (newValue: any, artefact: number) => void,
    company: CompanyViewModel
  }) => {

  const { data: companyTypes } = useCompanyTypes();
  const [model, setModel] = useState<any[]>([]);
  const [theValue, setTheValue] = useState<any>();

  useEffect(() => {

    if (Set.artefactId === Artefacts.companyTypeId) {
      setModel(companyTypes as CompanyTypeModel[]);
    }

  }, [company.companyTypeId])

  return (
    <TextField
      select
      sx={{ width: "100%" }}
      size="small"
      error={Number((company as any)[ArtefactToString(Set.artefactId as number)]) === 0}
      onChange={(ev) => setTheValue(ev.target.value)}
      value={(company as any)[ArtefactToString(Set.artefactId as number)] || 0}
      inputProps={{
        onBlur: () => { handleSendToList(theValue, Set.artefactId) },
      }}
      label={Set.artefactName}>
      <MenuItem value={0}>Please Select</MenuItem>
      {model.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SetupDropDown;