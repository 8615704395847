import { Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { SearchResultModel } from "./SearchView";

const SearchDataGridDetailPanel = ({ row: rowProp }: { row: SearchResultModel }) => {
  const getIconBasedOnStatus = (status: string | null) => {
    if (!status || status.toLowerCase() === "none")
      return (
        <Tooltip placement="right" title={status || "None"}>
          <HelpIcon sx={{ color: "#808080 !important" }} />
        </Tooltip>
      );

    if (status.toLowerCase() === "pendingchecks") {
      return (
        <Tooltip placement="right" title={status}>
          <PendingIcon sx={{ color: "#e68a00 !important" }} />
        </Tooltip>
      );
    }

    if (!["passed"].includes(status.toLowerCase()))
      return (
        <Tooltip placement="right" title={status}>
          <CancelIcon sx={{ color: "#a01616 !important" }} />
        </Tooltip>
      );

    return (
      <Tooltip placement="right" title={status}>
        <CheckCircleIcon sx={{ color: "#008000 !important" }} />
      </Tooltip>
    );
  };

  return (
    <Stack
      sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "rgba(0, 0, 0, 0.116)" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <h3>Summary of Company Compliance Details:</h3>
            </Grid>
            <div className="hr-line" />
            <Grid xs={1}>
              <Typography variant="caption">Overall Compliance Status:</Typography>
            </Grid>
            <Grid xs={2}>{getIconBasedOnStatus(rowProp.overallCompliance)}</Grid>
            <Grid xs={1}>
              <Typography variant="caption">CIPC Status:</Typography>
            </Grid>
            <Grid xs={2}>{getIconBasedOnStatus(rowProp.cipc)}</Grid>
            <Grid xs={1}>
              <Typography variant="caption">SARS Status:</Typography>
            </Grid>
            <Grid xs={2}>{getIconBasedOnStatus(rowProp.sars)}</Grid>
            <Grid xs={1}>
              <Typography variant="caption">B-BBEE Status:</Typography>
            </Grid>
            <Grid xs={2}>{getIconBasedOnStatus(rowProp.bbbee)}</Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SearchDataGridDetailPanel;
