import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faFloppyDisk, faCancel } from "@fortawesome/free-solid-svg-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// eslint-disable-next-line no-unused-vars
import dayjs, { Dayjs } from "dayjs";
import { Box, FormControl, FormLabel, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import { useAppSelector } from "../../../../app/hooks";
import { BeeRatingStatus } from "../../../../util/enums/BeeRatingStatus";
import { CompanyBeeRatingStatusModel } from "../../../../http/Company/Models/CompanyBeeRatingStatusModel";
import {
  GetCompanyBeeRatingStatus,
  SaveCompanyBeeRatingStatus,
} from "../../../../http/Company/beeDetailsApi";
import MyLoader from "../../../UI/LoadingOverlay";

interface CompanyBeeRatingStatusProps {
  // eslint-disable-next-line no-unused-vars
  setBeeDisabled: (isDisabled: boolean) => void;
}

const CompanyBeeRatingStatus = ({ setBeeDisabled }: CompanyBeeRatingStatusProps) => {
  const initialLoad = useRef(true);
  const companyData = useAppSelector((c) => c.company);
  const beeRatingStatuses = useAppSelector((x) => x.beeRatingStatusList);

  // eslint-disable-next-line no-unused-vars
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [companyBeeRatingStatus, setCompanyBeeRatingStatus] = useState<CompanyBeeRatingStatusModel>(
    {
      companyId: 0,
      effectiveDateTime: new Date(),
      beeRatingStatusId: 0,
      expectedDate: null,
      comment: "",
      dateCreated: new Date(),
      createdBy: "",
      dateModified: new Date(),
      modifiedBy: "",
      dateDeleted: null,
      deletedBy: null,
    } as CompanyBeeRatingStatusModel
  );
  // eslint-disable-next-line no-unused-vars
  const [companyBeeRatingStatusComparer, setCompanyBeeRatingStatusComparer] =
    useState<CompanyBeeRatingStatusModel>({} as CompanyBeeRatingStatusModel);

  const [edit, setEdit] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [expectedDate, setExpectedDate] = useState<Dayjs | null | undefined>(null);

  const validatePendingDateCapture = (modelToValidate: CompanyBeeRatingStatusModel) => {
    if (
      modelToValidate.beeRatingStatusId === BeeRatingStatus.ratingPending &&
      !modelToValidate.expectedDate
    )
      return false;

    return true;
  };

  const saveBeeRatingStatus = async () => {
    const modelToSave = { ...companyBeeRatingStatus };
    const valid = validatePendingDateCapture(modelToSave);

    if (!modelToSave.companyId) modelToSave.companyId = companyData.companyId;

    if (!valid) {
      Swal.fire("Validation error", "Please capture an expected date", "error");
      return;
    }

    try {
      setShowLoader(true);
      await SaveCompanyBeeRatingStatus(modelToSave);
      setEdit(false);
    } catch (error) {
      toast.error(
        "An error occurred saving the Company's B-BBEE rating status. Please try again or contact support."
      );
      setShowLoader(false);
      setEdit(true);
    } finally {
      setShowLoader(false);
    }
  };

  const EnableEdit = () => {
    if (!edit) {
      setEdit(true);
      return;
    }

    saveBeeRatingStatus();
  };

  const CancelEdit = async () => {
    setEdit(false);
    setCompanyBeeRatingStatus(companyBeeRatingStatusComparer);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...companyBeeRatingStatus, [propName]: event.target.value };
    setCompanyBeeRatingStatus(newVal);
  };

  const handleDateChange = (newDate: Dayjs | null | undefined, propName: string) => {
    setExpectedDate(newDate);

    const newValue = {
      ...companyBeeRatingStatus,
      [propName]: newDate?.toDate(),
    };
    setCompanyBeeRatingStatus(newValue);
  };

  const handleRatingDropdownChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...companyBeeRatingStatus, [propName]: +event.target.value };
    setCompanyBeeRatingStatus(newVal);

    if (
      +event.target.value === BeeRatingStatus.declined ||
      +event.target.value === BeeRatingStatus.ratingPending
    ) {
      setBeeDisabled(true);
    } else {
      setBeeDisabled(false);
    }
  };

  const loaderFunction = useCallback(async () => {
    try {
      setShowLoader(true);
      if (initialLoad.current) {
        const companyBeeRatingModel = await GetCompanyBeeRatingStatus(companyData.companyId);

        if (companyBeeRatingModel.companyId === 0) {
          const newVal = { ...companyBeeRatingModel, companyId: companyData.companyId };
          setCompanyBeeRatingStatus(newVal);
        }

        setCompanyBeeRatingStatus(companyBeeRatingModel);
        setCompanyBeeRatingStatusComparer(JSON.parse(JSON.stringify(companyBeeRatingModel)));

        if (
          companyBeeRatingModel.beeRatingStatusId === BeeRatingStatus.declined ||
          companyBeeRatingModel.beeRatingStatusId === BeeRatingStatus.ratingPending
        ) {
          setBeeDisabled(true);
        } else {
          setBeeDisabled(false);
        }
      }
    } finally {
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    loaderFunction();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MyLoader active={showLoader}>
        <Box sx={{ paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem" }}>
          <div className={styles.editSave}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-general-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <Box className={styles.btnBox}>
                <Box className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={EnableEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </Box>
                <Box className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </Box>
              </Box>
            )}
          </div>
          <Grid id="mainContainer" container spacing={2} direction="column">
            <Grid id="ratingStatusGrid" item xs={12} columnSpacing={2}>
              <FormControl disabled={!edit}>
                <FormLabel id="bee-rating-statuses">Rating Status:</FormLabel>
                <TextField
                  id="ratingStatusDropdown"
                  select
                  size="small"
                  value={companyBeeRatingStatus.beeRatingStatusId || ""}
                  onChange={(ev) => handleRatingDropdownChange(ev, "beeRatingStatusId")}
                >
                  <MenuItem value={0}>
                    <em>Please Select</em>
                  </MenuItem>
                  {beeRatingStatuses
                    .filter(
                      (s) =>
                        s.id === BeeRatingStatus.captured ||
                        s.id === BeeRatingStatus.declined ||
                        s.id === BeeRatingStatus.ratingPending ||
                        s.id === BeeRatingStatus.followup
                    )
                    .map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid id="pendingDateAndComments" container item spacing={2} direction="column">
              <Grid item xs={6}>
                {(companyBeeRatingStatus.beeRatingStatusId === BeeRatingStatus.ratingPending || companyBeeRatingStatus.beeRatingStatusId === BeeRatingStatus.followup) && (
                  <DesktopDatePicker
                    label="Expected Date"
                    disablePast
                    disabled={!edit}
                    value={expectedDate}
                    onChange={(ev) => {
                      handleDateChange(ev, "expectedDate");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" disabled={!edit} />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "50%" }}
                  label="Comments"
                  id="commentBox"
                  disabled={!edit}
                  value={companyBeeRatingStatus.comment || ""}
                  onChange={(ev) => {
                    handleValueChange(ev, "comment");
                  }}
                  multiline
                  rows={6}
                  maxRows={6}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </MyLoader>
    </LocalizationProvider>
  );
};

export default CompanyBeeRatingStatus;
