/* eslint-disable react/require-default-props */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import JSZip from "jszip";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useQueryClient } from "@tanstack/react-query";
import { DocumentDtoModel } from "../../../http/DocumentManagement/Models/DocumentDtoModel";
import { DocumentIcon } from "../../../util/MUI_Theme/Icons";
import CustomizedDialogs from "../../../components/OnBoarding/Documents/UploadDialog";
import { deleteCompanyDocumentAsync } from "../../../http/DocumentManagement/DocumentManagement";
import DeleteDocumentDialog from "../../../components/Company/CompanyPost/CompanyDocuments/DeleteDocumentDialog";
import queryKeys from "../../../util/hooks/queries/queryKeys";
import useCompanyDocuments from "../../../util/hooks/queries/useCompanyDocuments";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";


interface SupportingDocumentProps {
  companyId?: number | null,
  documentList: Array<DocumentDtoModel>;
  isRegistration: boolean;
  connected?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  handleDocumentsOnPDF?: (documents: DocumentDtoModel[]) => void,
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SupportingDocuments = ({ companyId, documentList, isRegistration, connected, handleDocumentsOnPDF }: SupportingDocumentProps) => {
  const { data: signedInCompany } = useBaseCompanyInfo();
  const { data: documents, refetch } = useCompanyDocuments(companyId || 0, connected || false);

  const [documentToProcess, setDocumentToProcess] = useState<DocumentDtoModel>({} as DocumentDtoModel);
  const [localDocumentList, setLocalDocumentList] = useState(documentList);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAdId = account?.localAccountId || "";

  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const isOwnProfile = signedInCompany?.companyId === companyId;

  useEffect(() => {
    if (!connected) {
      queryClient.invalidateQueries({queryKey: [queryKeys.companyDocuments]});
      queryClient.removeQueries({queryKey: [queryKeys.companyDocuments]});
      setLocalDocumentList(documentList);
      return;
    }

    if (documents) {
      setLocalDocumentList(documents);
      handleDocumentsOnPDF?.(documents);
    }

  }, [documents?.length, documentList.length]);

  const handleDownloadFile = async (fileUrl: string, fileNameToSave: string) => {
    const fileName = fileNameToSave;
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const windowUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = windowUrl;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
    });
  };

  const HandleDeleteFile = async (fileId: string, userId: string) => {
    if (!fileId || !userId) return;

    await deleteCompanyDocumentAsync(companyId || 0, fileId, userId);

    // Remove the deleted document from the list and update the state
    const newFileArray: Array<DocumentDtoModel> = localDocumentList.map((docModel: DocumentDtoModel) => {
      if (docModel.id === fileId) {
        const replacementModel: DocumentDtoModel = {
          documentTypeName: docModel.documentTypeName,
          displayOrder: docModel.displayOrder,
          documentUrl: "",
          fileName: "",
          typeId: docModel.typeId,
        } as DocumentDtoModel;
        return replacementModel;
      }
      return docModel;
    });

    setLocalDocumentList(newFileArray);

    queryClient.invalidateQueries({ queryKey: [queryKeys.companyDocuments] });
  };

  const handleMultipleDownloadFiles = async (filesToDownload: Array<DocumentDtoModel>) => {
    const zip = new JSZip();
    if (!filesToDownload || filesToDownload.length === 0) return;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < filesToDownload.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(filesToDownload[i].documentUrl);
      const docBlob = response.blob();
      zip.file(filesToDownload[i].fileName, docBlob);

      if (i === filesToDownload.length - 1) {
        // eslint-disable-next-line no-await-in-loop
        const completeZipFile = await zip.generateAsync({
          type: "blob",
          streamFiles: true,
          compression: "DEFLATE",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(completeZipFile);
        link.download = `company_${filesToDownload[i].companyId}_documents.zip`;
        link.click();
      }
    }
  }

  const showDownloadAllDialog = () => {
    Swal.fire({
      title: "Download all company documents",
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      if (!result.isConfirmed) return;

      handleMultipleDownloadFiles(localDocumentList);
    });
  };

  const handleUploadSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.companyDocuments] });
    refetch();
  };

  return (
    <Grid container xs={12} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} className="profile-view-labels">
        <DocumentIcon /> {isRegistration ? "UPLOAD YOUR DOCUMENTS" : "SUPPORTING DOCUMENTS"}
        {connected ||
          (isRegistration && (
            <div>
              {!isRegistration ? (
                <Button
                  variant="text"
                  className="btn-custom-download-pdf"
                  onClick={showDownloadAllDialog}
                  sx={{ textAlign: "right", float: "right", marginTop: "-12px" }}
                >
                  Download All
                </Button>
              ) : (
                <Typography id="Documents-Sub-Heading">
                  Please ensure that all documents uploaded are the latest, valid documents.
                  Supported files: PDF,JPG and PNG - no larger than 10MB
                </Typography>
              )}
            </div>
          ))}
      </Grid>

      <Grid item xs={isRegistration ? 4 : 7} className="doc-box-top">
        Document
      </Grid>
      {isRegistration && (
        <Grid item xs={3} className="doc-box-top">
          Status
        </Grid>
      )}
      <Grid item xs={1} className="doc-box-top">
        Expiry Date
      </Grid>
      <Grid xs={3} className="doc-box-top">
        {isRegistration ? "Upload" : "Attached"}
      </Grid>
      {connected && isOwnProfile && (
        <Grid item xs={1} className="doc-box-top">
          Delete
        </Grid>
      )}
      {localDocumentList.map((document) => (
        <Grid container item>
          <Grid item xs={isRegistration ? 4 : 7} className="doc-box">
            {connected ? (
              <Typography className="typ-Bold" noWrap>
                {document.documentTypeName || ""}
              </Typography>
            ) : (
              <Skeleton variant="rounded" width={430} height={20} animation={false} />
            )}
          </Grid>
          {isRegistration && (
            <Grid item xs={3} className="doc-box">
              {connected ? (
                <>
                  {" "}
                  {document.documentUrl ? (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography variant="subtitle1">Document Uploaded </Typography>
                      <CheckIcon className="icon-for-text-valid" />
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography variant="subtitle2">Document Not Uploaded </Typography>
                      <ClearIcon className="icon-for-text-failed" />
                    </Stack>
                  )}
                </>
              ) : (
                <Skeleton variant="rounded" width={100} height={20} animation={false} />
              )}
            </Grid>
          )}
          <Grid item xs={1} className="doc-box">
            {connected ? (
              <Typography noWrap>
                {document?.expiryDate?.toString().split("T")[0] || "N/A"}
              </Typography>
            ) : (
              <Skeleton variant="rounded" width={100} height={20} animation={false} />
            )}
          </Grid>
          <Grid item xs={3} className="doc-box">
            {connected && isOwnProfile ? (
              <Typography
                className="profile-icon-attachment-link"
                noWrap
                onClick={() => {
                  if (document.documentUrl) {
                    handleDownloadFile(document.documentUrl, document.fileName).catch(
                      console.error
                    );
                  } else {
                    setDocumentToProcess(document);
                    setOpen(true);
                  }
                }}
              >
                <div className="profile-icon-attachment-box">
                  <AttachmentIcon className="profile-icon-attachment" />
                </div>
                {document?.fileName}
              </Typography>
            ) : (
              <Skeleton variant="rounded" width={160} height={20} animation={false} />
            )}
          </Grid>
          {connected && isOwnProfile && (
            <Grid item xs={1} className="doc-box">
              <IconButton
                id="delete-file-button"
                disabled={!document.companyId}
                onClick={() => {
                  setDocumentToProcess(document);
                  setShowDeleteDialog(true);
                }}
              >
                <DeleteIcon
                  className={
                    connected && isOwnProfile && document.id !== "00000000-0000-0000-0000-000000000000"
                      ? "doc-table-content-icon"
                      : "doc-table-content-icon-disabled"
                  }
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      {open && (
        <CustomizedDialogs
          handleClose={() => setOpen(false)}
          title={documentToProcess.documentTypeName}
          open={open}
          documentType={documentToProcess.docTypeId || 0}
          companyId={companyId || 0}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
      <DeleteDocumentDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        documentToDelete={documentToProcess}
        setDocumentToDelete={setDocumentToProcess}
        confirmCallback={() => HandleDeleteFile(documentToProcess.id, userAdId)}
      />
      {isRegistration && (
        <Button variant="contained" onClick={showDownloadAllDialog} sx={{ marginTop: "15px" }}>
          <AddIcon /> Download All Documents
        </Button>
      )}
    </Grid>
  );
};

SupportingDocuments.defaultProps = {
  connected: false,
};

export default SupportingDocuments;
