/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum GenderTypes {
  None = 0,
  Male = 1,
  Female = 2,
  Other = 3,
}
export enum RaceTypes {
  None = 0,
  White = 1,
  Black = 2,
  Other = 3,
}
export enum ShareholderTypes {
  None = 0,
  Person = 1,
  Company = 2,
}

export enum DocumentTypes {
  None = 0,
  Id = 13,
  Registration = 12,
}


export const genderTypeToString = (value: GenderTypes) => {
  switch (value) {
    case GenderTypes.None:
      return "None"
    case GenderTypes.Male:
      return "Male"
    case GenderTypes.Female:
      return "Female"
    case GenderTypes.Other:
      return "Other"
    default:
      return "Unknown";
  }
};

export const raceTypeToString = (value: RaceTypes) => {
  switch (value) {
    case RaceTypes.None:
      return "None"
    case RaceTypes.White:
      return "white"
    case RaceTypes.Black:
      return "Black"
    case RaceTypes.Other:
      return "Other"
    default:
      return "Unknown";
  }
};

export const shareholderTypeToString = (value: ShareholderTypes) => {
  switch (value) {
    case ShareholderTypes.None:
      return "None"
    case ShareholderTypes.Person:
      return "Person"
    case ShareholderTypes.Company:
      return "Company"
    default:
      return "Unknown";
  }
};


export const shareholderTypeDocToString = (value: ShareholderTypes) => {
  switch (value) {
    case ShareholderTypes.None:
      return "None"
    case ShareholderTypes.Person:
      return "ID"
    case ShareholderTypes.Company:
      return "Company Registration"
    default:
      return "Unknown";
  }
};