/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Button, Modal, Slide } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";

import axios from "axios";
import { Dayjs } from "dayjs";

import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DocumentTypeModel } from "../../http/Redux/Models/DocumentTypeModel";
import DocumentType from "../../util/enums/DocumentType";
import { selectDocumentTypeList } from "../../http/Redux/Store/DocumentTypesSlice";
import { fetchDocumentTypes } from "../../http/Redux/Store/DocumentTypeActions";
import { addNewCompanyDocumentAsync, 
  downloadSupplierUploadDataPlatormAdminTemplate,
  downloadSupplierUploadTemplate } from "../../http/DocumentManagement/DocumentManagement";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import ConnectionType, { connectionTypeToString } from "../../util/enums/ConnectionType";
import { getUserWorkCentre } from "../../http/Redux/Store/UserWorkCentreSlice";
import useAllowedPermission from "../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../util/enums/PermissionClassification";


const style = {
  position: "absolute",
  top: "25%",
  left: "25%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface ConnectionListUploadButtonProps {
  connectionType: ConnectionType,
  children: string,// eslint-disable-line react/require-default-props
}

const storageAccountURL = process.env.REACT_APP_STORAGE_ACCOUNT_URL;

const ConnectionListUploadButton = (
  {
    connectionType,
    children,
  }: ConnectionListUploadButtonProps) => {
  const dispatch = useAppDispatch();

  const docTypes: Array<DocumentTypeModel> = useAppSelector(selectDocumentTypeList);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const hasPermission = useAllowedPermission();

  const userAdId = account?.localAccountId || "";
  const companyId = account?.idTokenClaims?.extension_companyid;

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [uploads, setUploadFiles] = useState<Array<File>>([] as Array<File>);

  const [doesDocExpire, setDoesDocExpire] = useState<boolean>(false);
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<Dayjs | null>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<number>(0);
  const list = useAppSelector(getUserWorkCentre);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ResetUploadComponents = () => {
    setUploadFiles([] as Array<File>);
  };

  const HandleFileUpload = (filesToUpload: File[]) => {
    // Start rename `supplier_upload_template.xlsx`
    const customFilename = `supplierlist_${companyId}_${userAdId}_${connectionType}.xlsx`;
    const updatedfilesToUpload = filesToUpload.map((file) => {
      if (file) {
        return new File([file], customFilename, {
          type: file.type,
          lastModified: file.lastModified,
        });
      }

      return file;
    });
    // End rename `supplier_upload_template.xlsx`

    if (updatedfilesToUpload.length === 0) return;

    setShowBackdrop(true);

    setUploadFiles(updatedfilesToUpload);


    const workCentreId = list.find(x => x.targetUrl === "/network/suppliers" && !x.isRead)?.id || 0;

    addNewCompanyDocumentAsync(
      companyId as number,
      doesDocExpire,
      doesDocExpire ? (selectedExpiryDate?.toDate() as Date) : new Date(0),
      selectedDocumentType as number,
      userAdId,
      updatedfilesToUpload,
      workCentreId,
      DocumentType.SupplierList

    );


    setTimeout(() => {
      ResetUploadComponents();
      setOpen(false);
      setShowBackdrop(false);
      // call the method if it's not null
      Swal.fire({
        title: "File uploaded successfully",
        text: `Thanks for uploading your ${connectionTypeToString(connectionType)} list.
              We're processing your information, and will notify you once this has been completed.`,
        allowEnterKey: false,
        allowEscapeKey: true,
        allowOutsideClick: false,
        icon: "success",
      });
    }, 3000);
  };

  useEffect(() => {
    setShowBackdrop(true);

    const loader = async () => {
      if (docTypes === null || docTypes === undefined || docTypes.length === 0) {
        dispatch(fetchDocumentTypes());
      }

      const currentDocType = docTypes.find((docType) => docType.name === "Supplier List");
      if (currentDocType !== undefined) {
        setSelectedDocumentType(currentDocType.id);
      }

      setShowBackdrop(false);
    };
    loader();
  }, [dispatch, setShowBackdrop, docTypes]);

  const handleDownloadTemplate = async () => {
    let templateFilename = "";
    if (connectionType === ConnectionType.Supplier) { templateFilename = "supplier_upload_template.xlsx"; }
    if (connectionType === ConnectionType.Client) { templateFilename = "client_upload_template.xlsx"; }

    if (hasPermission(PermissionClassification.ViewPlatformCompanyData))
      await downloadSupplierUploadDataPlatormAdminTemplate();
    else
      await downloadSupplierUploadTemplate();
  }

  return (
    <div>
      <LoadingBackdrop showBackdrop={showBackdrop} />
      <Button
        onClick={handleOpen}
        variant="contained" id="step-supply"
      >
        {children}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-upload-suppliers"
        aria-describedby="modal-upload-suppliers"
      >
        <Slide direction="left" in={open}>
          <Box sx={style}>
            <Box
              id="modal-upload-suppliers-header"
              sx={{ typography: "h6", textTransform: "uppercase", textAlign: "center", mb: 2 }}
            >
              Upload your {connectionTypeToString(connectionType)}s
            </Box>

            <Box
              id="modal-upload-suppliers-download-template"
              sx={{ typography: "subtitle1", mb: 3 }}
            >
              Please{" "}
              <Button color="primary" onClick={handleDownloadTemplate}>
                <u>click here</u>
              </Button>{" "}
              to download the excel template with the mandatory and optional data attributes.
            </Box>

            <Box id="modal-upload-suppliers-dropzone" sx={{ mt: 2 }}>
              <DropzoneArea
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ]}
                filesLimit={1}
                showAlerts
                showPreviewsInDropzone
                onChange={(files) => {
                  HandleFileUpload(files);
                }}
              />
            </Box>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default ConnectionListUploadButton;
