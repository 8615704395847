import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchFormRequestAsync, makePatchRequestAsync, makePostRequestAsync } from "../http/Helpers/httpRequestHelpers";
import { CompanyProfileImageModel } from "./Redux/Models/CompanyProfileImageModel";
import { CompanyProfileModel } from "./Redux/Models/CompanyProfileModel";


const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default {
  async getParticularProfileImage(companyId: number, staged: boolean) {

    const url = `${baseUrl}/api/Company/CompanyProfileImage/Image/${staged}?companyId=${companyId}`;
    return makeGetRequestAsync<CompanyProfileImageModel>(apiScopes, url);
  },
  async getParticularCompanyProfile(companyId: number, staged: boolean) {

    const url = `${baseUrl}/api/Company/CompanyProfileImage/Details/${staged}?companyId=${companyId}`;
    return makeGetRequestAsync<CompanyProfileModel>(apiScopes, url);
  },
  async uploadImage(companyId: number | undefined, formFile: FormData, oldProfileFileName: string) {

    const url = `${baseUrl}/api/Company/CompanyProfileImage/Update/Image?companyId=${companyId}&oldProfileFileName=${oldProfileFileName}`;
    return (await (makePatchFormRequestAsync(apiScopes, url, formFile))).status;
  },
  async updateProfileDetails(companyProfile: CompanyProfileModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/CompanyProfileImage/Update/Details/${staged}`;
    return (await (makePatchRequestAsync(apiScopes, url, companyProfile))).status;
  },
  async addProfileDetails(companyProfile: CompanyProfileModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/CompanyProfileImage/Add/Details/${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, companyProfile))).data;
  },
}