import { FC, ReactNode, useState } from "react";
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface AvailableActionProps {
  icon?: ReactNode, // eslint-disable-line react/require-default-props
  title: string,
  onClick: () => void,
  disabled?: boolean, // eslint-disable-line react/require-default-props
}

const AvailableActionButton: FC<AvailableActionProps> = (props) => (
  <ListItem disablePadding>
    <ListItemButton disabled={props.disabled === true} onClick={props.onClick}>
      <ListItemIcon sx={{ minWidth: "40px" }}>
        {props.icon}
      </ListItemIcon>
      <ListItemText primary={props.title} />
    </ListItemButton>
  </ListItem>
);

const AvailableActionsButton = ({ actionsList }: { actionsList: AvailableActionProps[] }) => {
  const [showAvailableActions, setShowAvailableActions] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12} pb={1}>
        <button
          style={{width:"200px"}}
          type="button"
          className="btn-custom-table-top-actions"
          onClick={() => setShowAvailableActions((x) => !x)}
        >
        AVAILABLE ACTIONS
        </button>
        {
          showAvailableActions &&
          <List sx={{ width: "200px" }} className="action-search-btn-list">
            {actionsList.map((action, index) => (
              <AvailableActionButton
                key={index}
                icon={action.icon}
                disabled={action.disabled}
                title={action.title}
                onClick={() => {
                  action.onClick();
                  setShowAvailableActions(false);
                }}
              />
            ))}
          </List>
        }
      </Grid>
    </Grid>
  );
}

export default AvailableActionsButton;
