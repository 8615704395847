import React, { useEffect, useState } from "react";
import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { CompanyExtendedDetailsModel } from "../../../../http/Redux/Models/CompanyExtendedDetailsModel";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import WithRequiredPermission from "../../../WithRequiredPermission";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import ExtendedCompanyDetailsService from "../../../../http/Company/ExtendedCompanyDetailsService";
import { fetchCompanyExtendedDetails } from "../../../../http/Redux/Store/CompanyExtendedDetailsActions";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getCompanyExtendedDetails } from "../../../../http/Redux/Store/CompanyExtendedDetailsSlice";

interface CompanyDetailPostProps {
  // eslint-disable-next-line react/require-default-props
  headerText?: string;
  companyId: number;
}


const CompanyExtendedDetails = ({
  headerText = "",
  companyId,
}: CompanyDetailPostProps) => {
  const companyExtendedDetails: CompanyExtendedDetailsModel = useAppSelector(getCompanyExtendedDetails);
  const [data, setData] = useState<CompanyExtendedDetailsModel>(companyExtendedDetails);
  const [edit, setEdit] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const dispatch = useAppDispatch();


  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const EnableEdit = () => {
    setEdit(!edit);
  };

  const CancelEdit = () => {
    setEdit(false);
  };


  const Save = async () => {

    if (companyExtendedDetails.companyId > 0) {
      await ExtendedCompanyDetailsService.update(data, false);
    }
    else {
      await ExtendedCompanyDetailsService.create(data, false);
    }
    dispatch(fetchCompanyExtendedDetails(data?.companyId, false));
    toast.success("Company Extended Details Updated");
    setEdit(false);
  }


  useEffect(() => {
    setData(companyExtendedDetails);
  }, [companyExtendedDetails.numberOfEmployees])



  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      className="Accord-Hide"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>

      </AccordionSummary>
      <AccordionDetails>

        <div className={styles.editSave}>
          <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-general-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <div className={styles.btnBox}>
                <div className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={Save}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
                <div className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </WithRequiredPermission>
        </div>


        <div className={styles.postBody}>
          <div className={styles.postInfo}>
            <Grid container className="g-container">
              <Grid item xs={4}>
                <TextField
                  type="text"
                  disabled={!edit}
                  value={data?.numberOfEmployees}
                  size="small"
                  sx={{ width: "98%", marginRight: "2%" }}
                  id="number-of-employees"
                  label="Number Of Employees"
                  onChange={(ev) => setData({ ...data, numberOfEmployees: Number(ev.target.value), companyId })}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default CompanyExtendedDetails;