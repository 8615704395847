import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UserPromptActivityArrayModel, UserPromptActivityModel } from "../Models/UserPromptActivityModel";
import { UserPromptResponseArrayModel, UserPromptResponseModel } from "../Models/UserPromptResponseModel";

const initialUserPromptActivityState: UserPromptActivityArrayModel = {
  allUserPromptActivity: [],
  particularUserPromptActivity: {
    id: 0,
    popupPromptId: 0,
    accountId: "",
    status: 0,
    dateCreated: new Date(),
    dateModified: new Date(),
  },
  particularUserPromptActivityStatus: 0,

}

const initialUserPromptResponseState: UserPromptResponseArrayModel = {
  particularUserPromptResponse: {
    id: 0,
    accountId: "",
    isDeleted: false,
    registeredName: "",
    tradingName: "",
    vatNumber: "",
    phoneNumber: "",
    bbbeeSectorId: 0,
    bbbeeTurnoverId: 0,
  },
}


export const userPromptResponseSlice = createSlice({
  name: "userPromptResponse",
  initialState: initialUserPromptResponseState,
  reducers: {
    setParticularUserPromptResponse(state, action: PayloadAction<UserPromptResponseModel>) {
      state.particularUserPromptResponse = action.payload; // eslint-disable-line no-param-reassign
    },

  },
})



export const userPromptActivitySlice = createSlice({
  name: "userPromptActivity",
  initialState: initialUserPromptActivityState,
  reducers: {
    setUserPromptActivity(state, action: PayloadAction<UserPromptActivityModel[]>) {
      state.allUserPromptActivity = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularUserPromptActivity(state, action: PayloadAction<UserPromptActivityModel>) {
      state.particularUserPromptActivity = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularUserPromptActivityStatus(state, action: PayloadAction<number>) {
      state.particularUserPromptActivityStatus = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


export const getUserPromptActivity = (state: RootState) => state.userPromptActivity.allUserPromptActivity;
export const getUserPromptActivityStatus = (state: RootState) => state.userPromptActivity.particularUserPromptActivityStatus;
