import { IconButton, Tooltip, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import DataSourceTypes from "../../../util/enums/DataSourceTypes";
import { UserMessage } from "../../../http/CompanyUserManagement/Messages/UserMessage";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import { SupplierMatchViewModel } from "../../../http/Redux/Models/SupplierMatchViewModel";

interface ViewCellProps {
  row: SupplierMatchViewModel;
  stage: boolean;
  selectedClientId: number
  users: UserMessage[];
  allTasks: AgentTaskListGridModel[];
  userId: number,
  handleViewMatch: () => void;
}

const ViewCell = ({ row, stage, selectedClientId, users, allTasks, userId, handleViewMatch }: ViewCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [userAssignedId, setUserAssignedId] = useState(0);
  const [updateModel, setUpdateModel] = useState<AgentTaskModel>({} as AgentTaskModel);

  useEffect(() => {

    if (allTasks.length !== 0) {
      const update = allTasks.find(x => x.agentTaskId === row.agencyId) as AgentTaskListGridModel;

      if (update) {
        setUserAssignedId(update.agentUserId);
        setUpdateModel({
          ...updateModel,
          id: update.agentTaskId,
          status: AgentTaskStatusTypes.Abandoned,
          completion: update.completion,
          comment: update.comment,
          agentUserId: update.agentUserId,
          clientId: update.clientId,
          agentCompanyId: update.agentCompanyId,
          createdBy: update.createdBy,
          dateCreated: update.dateCreated,
          expectedNextUpdateDate: update.expectedNextUpdateDate,
          uploadTempCompanyId: row.uploadTempCompanyId,
        });
      }


    }
  }, [])


  const handleSaveAgentTask = async () => {
    setIsLoading(true);
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = userId;
    model.clientId = selectedClientId;
    model.dataSource = stage ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSourceTypes.MatchTable;
    model.uploadTempCompanyId = row.uploadTempCompanyId;
    await CompanyVerificationService.addAgentTask(model, false).finally(() => {
      setIsLoading(false);
    });
    setOpenAssignDialog(false);
    toast.success("Task Allocated");

  }

  const handleAllocateTo = async () => {

    setIsLoading(true);

    await CompanyVerificationService.updateAgentTask(updateModel, stage).finally(() => {
      setIsLoading(false);
    });
    const updateTask = updateModel as AgentTaskModel;
    updateTask.agentUserId = selectedUserId;
    updateTask.status = AgentTaskStatusTypes.Assigned;
    await CompanyVerificationService.addAgentTask(updateTask, stage).finally(() => {
      setIsLoading(false);
      setOpenDialog(false);
    });
    toast.success("Task Reassigned");
  }

  const handleUserChange = (value: string) => {
    setSelectedUserId(Number(value));
  };

  const handleOpenAllocateUser = () => {
    setOpenDialog(true);
  }

  const handleOpenDialogAddAgentTask = () => {
    setOpenAssignDialog(true);
  }


  const handleReturnTaskToPool = async () => {
    setIsLoading(true);
    await CompanyVerificationService.updateAgentTask(updateModel, stage).finally(() => {
      setIsLoading(false);
    });
  }


  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };


  return (
    <>
      <div>
        <LoadingBackdrop showBackdrop={isLoading} />
        <Tooltip title="Task Allocations">
          <IconButton
            onClick={handleClickMenu}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={opens ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opens ? "true" : undefined}
          >
            <BookmarksIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={opens}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
        >

          <MenuItem onClick={handleViewMatch}>
            <SearchIcon /> View Match & Clean
          </MenuItem>
          {userAssignedId !== 0 ? (
            <>
              <MenuItem onClick={handleReturnTaskToPool}>
                <BookmarkRemoveIcon /> Return Task To Pool
              </MenuItem>
              <MenuItem onClick={handleOpenAllocateUser}>
                <BookmarkAddIcon /> Reallocate Task
              </MenuItem>
            </>
          ) : (
            <MenuItem onClick={handleOpenDialogAddAgentTask}>
              <BookmarkAddIcon /> Assign To Agent
            </MenuItem>
          )}
        </Menu>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Reallocate Task
        </DialogTitle>
        <DialogContent >
          <Box sx={{ width: "300px", height: "90px", padding: "10px" }}>
            <TextField
              id="agent"
              select
              value={selectedUserId}
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Select Agent"
              onChange={(e) => handleUserChange(e.target.value)}
              size="small"
            >
              {users.map((record) => (
                record.id !== userAssignedId && (
                  <MenuItem key={record.id} value={record.id}>
                    {record.name}
                  </MenuItem>
                )
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">Cancel</Button>
          <Button onClick={handleAllocateTo} variant="contained" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog >
      <Dialog
        open={openAssignDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Assign Task
        </DialogTitle>
        <DialogContent >
          <Box sx={{ width: "300px", height: "90px", padding: "10px" }}>
            <TextField
              id="agent"
              select
              value={selectedUserId}
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Select Agent"
              onChange={(e) => handleUserChange(e.target.value)}
              size="small"
            >
              {users.map((record) => (
                <MenuItem key={record.id} value={record.id}>
                  {record.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignDialog} variant="contained">Cancel</Button>
          <Button onClick={handleSaveAgentTask} variant="contained" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );

}

export default ViewCell;