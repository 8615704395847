import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { ChatMessageModel } from "./Models/ChatMessageModel";
import { ChatSummaryModel } from "./Models/ChatSummaryModel";
import { InitilizaChatGroupMessage } from "./Models/InitilizaChatGroupMessage";
import { PostMessageModel } from "./Models/PostMessageModel";

const apiScopes = protectedResources.notificationsWebApi.scopes;
const baseUrl = protectedResources.notificationsWebApi.endpoint;

/*
 *  Search user chats
 */
export async function SearchChatMessages(companyId: number, searchQuery: string) {
  const url = `${baseUrl}/api/Notifications/Messaging/SearchChatMessages/${companyId}?searchQuery=${searchQuery}`;
  return (await makeGetRequestAsync<Array<ChatSummaryModel>>(apiScopes, url)).data;
}
/*
 *  Retrieves user chats
 */
export async function GetChatList(companyId: number) {
  const url = `${baseUrl}/api/Notifications/Messaging/GetChatList/${companyId}`;
  return (await makeGetRequestAsync<Array<ChatSummaryModel>>(apiScopes, url)).data;
}

/*
 *  Retrieves chat messages
 */
export async function GetChatGroupMessageList(chatGroupId: number, companyId: number) {
  const url = `${baseUrl}/api/Notifications/Messaging/GetChatGroupMessageList/${chatGroupId}/${companyId}`;
  return makeGetRequestAsync<Array<ChatMessageModel>>(apiScopes, url);
}

/*
 *  Initialize Chat Group
 */
export async function InitializeChatGroup(data: InitilizaChatGroupMessage) {
  const url = `${baseUrl}/api/Notifications/Messaging/InitializeChatGroup`;
  return makePostRequestAsync<unknown, InitilizaChatGroupMessage>(apiScopes, url, data);
}

/*
 *  Post new chat message
 */
export async function PostMessage(data: PostMessageModel) {
  const url = `${baseUrl}/api/Notifications/Messaging/PostMessage`;
  return makePostRequestAsync<unknown, PostMessageModel>(apiScopes, url, data);
}

/*
 *  Read chat messages
 */
export async function ReadChat(chatGroupId: number) {
  const url = `${baseUrl}/api/Notifications/Messaging/ReadChat/${chatGroupId}`;
  return makePostRequestAsync<boolean, unknown>(apiScopes, url);
}

/*
 *  Has new chat messages
 */
export async function HasNewMessages(latest: Date) {
  const url = `${baseUrl}/api/Notifications/Messaging/HasNewMessages?latest=${latest.toString().slice(0, 19).replace("T", " ")}`;
  return makeGetRequestAsync<boolean>(apiScopes, url);
}

export async function Read(id: number, companyId: number) {
  const url = `${baseUrl}/api/Notifications/Messaging/Read?id=${id}&companyId=${companyId}`;
  return makePostRequestAsync(apiScopes, url);
}
