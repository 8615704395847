import { MenuItem, Button, Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router";
import { AddRequestVerificationModel } from "../../../http/Verification/AddVerificationRequestModel";
import { useAppSelector } from "../../../app/hooks";
import { CompanyRequestModel } from "../../../http/Redux/Models/CompanyRequestModel";
import RequestStatus, { requestStatusToString } from "../../../util/enums/RequestStatus";
import RequestType, { requestTypeToString } from "../../../util/enums/RequestType";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import useAllowedPermission from "../../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import ClientSpecificData from "../../ClientSpecificData/ClientSpecificData";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import formatDate from "../../../util/formatter/formatDate";
import {
  AgentTaskStatusTypes,
  AgentTaskStatusTypesToString,
  RequestToAgentTaskStatusType,
} from "../../../util/enums/AgentTaskStatusTypes";
import DataSourceTypes, { DataSourceTypesToString } from "../../../util/enums/DataSourceTypes";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";

// eslint-disable-next-line react/require-default-props
const VerifyCompanyDetails = ({
  status,
  companyId,
  staged,
  workcentreAgentPath,
  agentPathId,
  clientId,
}: {
  status: number;
  companyId: number;
  staged: boolean;
  workcentreAgentPath: boolean;
  agentPathId: number;
  // eslint-disable-next-line react/require-default-props
  clientId?: number;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listValueRequestStatus, setListValueRequestStatus] = useState("");
  const [listValueRequestType, setListValueRequestType] = useState("0");
  const [comment, setComment] = useState<string>("");
  const [lastRequest, setLastRequest] = useState<CompanyRequestModel>({} as CompanyRequestModel);
  const [editable] = useState<AddRequestVerificationModel>({} as AddRequestVerificationModel);
  const [task, setTask] = useState<AgentTaskModel>({} as AgentTaskModel);
  const userProfile = useAppSelector(getUserProfile);
  const [dateRequested, setRequestDate] = useState<string>("");
  const hasAllowedPermission = useAllowedPermission();
  const [viewClientSpecificDetails, setViewClientSpecificDetails] = useState(false);
  const [agentTaskList, setAgentTaskList] = useState<AgentTaskListGridModel>(
    {} as AgentTaskListGridModel
  );
  const [expectedDate, setExpectedDate] = useState<Dayjs | null>(null);
  const [isFollowUp, setIsFollowUp] = useState<boolean>(false);
  const [isNotClaimed, setIsNotClaimed] = useState<boolean>(true);

  const [hasFollowUp, setHasFollowUp] = useState<boolean>(false);
  const [validationCompleted, setValidationCompleted] = useState<boolean>(false);
  const [agentTaskClaimedId, setAgentTaskClaimedId] = useState(0);
  const navigate = useNavigate();

  const handleChangeRequestStatus = (event: number) => {
    setListValueRequestStatus(event.toString());
    if (event === RequestStatus.Followup) {
      setIsFollowUp(true);
    } else {
      setIsFollowUp(false);
    }
  };

  const handleChangeRequestType = (event: ChangeEvent<HTMLInputElement>) => {
    setListValueRequestType(event.target.value);
  };

  const handleValidate = () => {
    setIsLoading(true);

    const updateTask: AgentTaskModel = task;
    updateTask.status = RequestToAgentTaskStatusType(Number(listValueRequestStatus));
    if (Number(listValueRequestStatus) === RequestStatus.Completed) {
      updateTask.completion = "100";
    }
    updateTask.comment = comment;
    if (expectedDate !== null) {
      updateTask.expectedNextUpdateDate = expectedDate.toDate();
    }
    let agentId = 0;
    const loader = async () => {
      if (task.id !== undefined) {
        await CompanyVerificationService.updateAgentTask(updateTask, staged).finally(() => {
          setValidationCompleted(true);
          setIsLoading(true);
        });
      } else {
        agentId = (await CompanyVerificationService.addAgentTask(updateTask, staged)) as number;
      }
    };

    loader();

    const addRecord: AddRequestVerificationModel = editable;
    addRecord.requestType = Number(listValueRequestType) === 0 ? 4 : Number(listValueRequestType);
    addRecord.status = Number(listValueRequestStatus);
    addRecord.companyId = companyId;
    addRecord.agentUserId = userProfile.id as number;
    addRecord.agentTaskId = agentPathId !== 0 ? agentPathId : agentId;

    const load = async () => {
      await CompanyVerificationService.addRequestVerification(addRecord, staged);
    };
    load();
    toast.success("Validation Completed");
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setExpectedDate(newDate);
  };

  useEffect(() => {
    if (agentPathId !== 0) {
      setIsNotClaimed(false);
      if (userProfile.id) {
        const loader = async () => {
          setIsLoading(true);
          const data = await CompanyVerificationService.getAgentTaskList(userProfile.id as number);
          let currentTask = data.filter((x) => x.agentTaskId === agentPathId)[0];

          if (!currentTask) {
            toast.error("Unable to load agent task. Please try again, or contact support.");
            currentTask =  {} as AgentTaskListGridModel;
          }

          const date = new Date();
          setTask({
            ...task,
            id: currentTask?.agentTaskId,
            companyId: currentTask?.companyId,
            status: currentTask?.statusId,
            completion: currentTask?.completion,
            comment: currentTask?.comment,
            agentUserId: currentTask?.agentUserId,
            clientId: currentTask?.clientId,
            agentCompanyId: currentTask?.agentCompanyId,
            createdBy: currentTask?.createdBy,
            dataSource: DataSourceTypesToString(currentTask?.dataSource),
            pageSource: currentTask?.pageSource,
            dateCreated: currentTask?.dateCreated,
            expectedNextUpdateDate: currentTask?.expectedNextUpdateDate,
          });

          if (
            currentTask.status === AgentTaskStatusTypesToString(3) &&
            formatDate(currentTask.expectedNextUpdateDate) > formatDate(date)
          ) {
            setHasFollowUp(true);
          }

          setAgentTaskList(currentTask);
          setIsLoading(false);
        };

        loader();
      }

      if (lastRequest.agentName === undefined && companyId !== undefined) {
        const requestLoader = async () => {
          const value = await CompanyVerificationService.getCompanyRequest(companyId, staged);
          const requestDate = formatDate(value.requestDate);
          setLastRequest(value);
          if (value) {
            setRequestDate(requestDate);
          }
        };

        requestLoader();
      }
    } else {
      setIsNotClaimed(true);
    }
  }, [userProfile.id, agentTaskList.agentTaskId, agentPathId, hasFollowUp]);

  const handleSaveAgentTask = async () => {
    setIsLoading(true);
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.companyId = companyId;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = userProfile.id as number;
    model.clientId = clientId as number;
    model.dataSource = staged ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSourceTypes.DataMgmt;
    const id = await CompanyVerificationService.addAgentTask(model, staged).finally(() => {
      setIsLoading(false);
    });

    setAgentTaskClaimedId(id as number);
  };

  useEffect(() => {
    if (agentTaskClaimedId !== 0) {
      const template = `/workcentre/companyProfile/${clientId}/${companyId}/${0}/${staged}/${agentTaskClaimedId}`;
      navigate(template);
    }
    if (validationCompleted) {
      const template = `/agent/workcentre/0/${clientId}`;
      navigate(template);
    }
  }, [agentTaskClaimedId, validationCompleted]);

  return (
    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) &&
      !Number.isNaN(status) &&
      workcentreAgentPath ? (
          <div className="company-validation-entire-box">
            {!isNotClaimed ? (
              <>
                <Grid container>
                  <Grid xs={12}>
                    <h3>Company Task</h3>
                    <div className="hr-line" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={12}>
                    {lastRequest.agentName ? (
                      <p className="last-verification">
                        Updated on: {dateRequested} by {lastRequest.agentName} with status{" "}
                        {AgentTaskStatusTypesToString(lastRequest.status)}
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={6} className="verification-grid">
                    <TextField
                      label="Status"
                      id="demo-multiple-checkbox-dash"
                      select
                      size="small"
                      sx={{ width: "98%", marginTop: "8px" }}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                        handleChangeRequestStatus(Number(ev.target.value))
                      }
                    >
                      {Object.keys(RequestStatus)
                        .filter((x) => x === "1" || x === "3" || x === "4")
                        .map((name) => (
                          <MenuItem key={Number(name)} value={Number(name)}>
                            {requestStatusToString(Number(name))}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  {isFollowUp ? (
                    <Grid xs={6} className="verification-grid">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="Expected Date"
                          value={expectedDate}
                          disablePast
                          onChange={(ev) => {
                            handleDateChange(ev);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ width: "100%", marginTop: "8px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  ) : (
                    <Grid xs={6} className="verification-grid">
                      <TextField
                        label="Information Source"
                        size="small"
                        sx={{ width: isFollowUp ? "98%" : "100%", marginTop: "8px" }}
                        select
                        value={listValueRequestType || ""}
                        onChange={(ev: ChangeEvent<HTMLInputElement>) => handleChangeRequestType(ev)}
                      >
                        {Object.keys(RequestType)
                          .filter(
                            (x) => x === "0" || x === "1" || x === "2" || x === "3" || x === "4"
                          )
                          .map((name) => (
                            <MenuItem key={Number(name)} value={Number(name)}>
                              {requestTypeToString(Number(name))}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  )}

                  <Grid xs={12} className="verification-grid">
                    <TextField
                      variant="outlined"
                      label="Comment"
                      size="small"
                      sx={{ marginTop: "8px" }}
                      id="comment"
                      onChange={(ev) => {
                        setComment(ev.target.value);
                      }}
                      multiline
                      maxRows={5}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={12} className="verification-grid">
                    <p className="last-verification-followup">
                      {" "}
                      {hasFollowUp
                        ? `Set for follow up on ${formatDate(agentTaskList?.expectedNextUpdateDate)}`
                        : ""}
                    </p>
                    <Button className="btn-validate" variant="contained" onClick={handleValidate}>
                      {" "}
                      Update Task
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Button variant="contained" onClick={handleSaveAgentTask}>
                {" "}
                Claim Task
              </Button>
            )}
            {viewClientSpecificDetails ? (
              <ClientSpecificData
                clientId={clientId as number}
                supplierCompanyId={companyId}
                onClose={() => setViewClientSpecificDetails(false)}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
    </div>
  );
};

export default VerifyCompanyDetails;
