
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { Avatar, Typography } from "@mui/material";
import { colorPrimaryActive, colorPrimaryGrey } from "../../../util/MUI_Theme/Theme";

// ----------------------------------------------------------------------

interface StepperSteps {
  title: string,
}

const reponsiveHeightAndWidths = ({ sm, md, lg }: { sm: number; md: number; lg: number; }) => ({
  "@media (min-width:300px)": {
    width: sm,
    height: sm,
  },
  "@media (min-width:600px)": {
    width: md,
    height: md,
  },
  "@media (min-width:1200px)": {
    width: lg,
    height: lg,
  },
});

const reponsiveLineHeight = ({ sm, md, lg }: { sm: number; md: number; lg: number; }) => ({
  "@media (min-width:300px)": {
    height: sm,
    marginLeft: "-50px", 
    width: "calc(100% + 100px)",
  },
  "@media (min-width:600px)": {
    height: md,
    marginLeft: 0,
    width: "calc(100%)",
  },
  "@media (min-width:1200px)": {
    height: lg,
    marginLeft: 0,
    width: "calc(100%)",
  },
});

const reponsiveLineTop = ({ sm, md, lg }: { sm: number; md: number; lg: number; }) => ({
  "@media (min-width:300px)": {
    top: sm,
  },
  "@media (min-width:600px)": {
    top: md,
  },
  "@media (min-width:1200px)": {
    top: lg,
  },
});

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 24.5,
    ...reponsiveLineTop({ sm: 40, md: 47, lg: 51 }),
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 17,
    ...reponsiveLineHeight({ sm: 11, md: 13, lg: 17 }),
    border: 0,
    borderRadius: 1,
    backgroundColor: colorPrimaryGrey,
    // Extend line past the icon on step 1
    width: "calc(100%px)",
    zIndex:-1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 4,
  width: 66,
  height: 66,
  ...reponsiveHeightAndWidths({ sm: 40, md: 55, lg: 66 }),
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.disabled,
  backgroundColor: colorPrimaryGrey,
  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  }),
}));

const ColorlibStepIcon = (props: StepIconProps, index: number) => {
  const { active, completed, className } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption" color={completed || active ? colorPrimaryActive : colorPrimaryGrey}>
        <b>STEP</b>
      </Typography>
      <Box zIndex={2}>
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
          <Avatar
            style={{
              backgroundColor: completed || active ? colorPrimaryActive : colorPrimaryGrey,
              fontWeight: "bolder",
            }}
          >
            {index + 1}
          </Avatar>
        </ColorlibStepIconRoot>
      </Box>
    </Box>
  );
}


const CustomizedSteppers = (
  {
    activeStep,
    steps,
  }: {
    activeStep: number,
    steps: StepperSteps[],
  }) => (

  <Box minWidth="100%">
    <Box sx={{ width: "100%" }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel StepIconComponent={stepProps => (ColorlibStepIcon(stepProps, index))}>
              <Typography style={{fontSize:14}} variant="caption" color={activeStep >= index ? colorPrimaryActive : ""}>
                <b>{step.title}</b>
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  </Box>
);

export default CustomizedSteppers;