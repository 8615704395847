import { FormControlLabel, Grid, styled, Switch, SwitchProps, Typography } from "@mui/material";
import { FC, useState } from "react";

interface IosSwitchButtonProps {
  checkedLabel: string,
  uncheckedLabel: string,
  // eslint-disable-next-line react/require-default-props
  checkedByDefault?: boolean,
  // eslint-disable-next-line no-unused-vars
  onChangeCallback: (isChecked: boolean) => void,
}

const IosSwitchButton: FC<IosSwitchButtonProps> = (props) => {
  const [checked, setChecked] = useState(props.checkedByDefault);

  const IOSSwitch = styled((switchProps: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...switchProps}
      checked={checked}
      onChange={() => {
        const newState = !checked;
        setChecked(newState);
        props.onChangeCallback(newState);
      
      }} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
          opacity: 1,
          border: "2px solid #4B69F9",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#4B69F9",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? "#fff" : "#fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      color: "#4B69F9",
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#FFFFFF",
      border: "2px solid #4B69F9",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return(
    <Grid item xs={12} mt={2}>
      <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} />}
        label={<Typography variant="caption">{checked ? props.checkedLabel : props.uncheckedLabel}</Typography>}
      />
    </Grid>
  )
}

export default IosSwitchButton;