import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyNetwork } from "../../../http/NetworkAndConnect/Netconn";

const useCompanyNetwork = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyDetails, companyId],
    queryFn: async () => (GetCompanyNetwork(companyId)),
  })

  return query;
}

export default useCompanyNetwork;