import { Box, Rating } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import FormDialog from "../Dialogs/FormDialog";
import { AddCompanyRatingAsync, GetCompanyRatingAsync } from "../../http/Company/CompanyRating";
import { AddCompanyRatingMessage } from "../../http/Company/Messages/AddCompanyRatingMessage";
import InLineSpinner from "../LoadingSpinners/InlineSpinner";
import NetconnSearchType from "../../util/enums/NetconnSearchType";

const CompanyRating = (
  {
    companyRegisteredName,
    averageCompanyRating,
    companyId,
    searchType,
  }:
    {
      companyRegisteredName: string,
      averageCompanyRating: number,
      companyId: number,
      searchType: number,
    }) => {
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [currentRatingValue, setCurrentRatingValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hover, setHover] = useState(-1);

  const hoverLabels: { [index: string]: string } = {
    1: "Useless",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent",
  };

  const getLabelText = () => `${currentRatingValue} Star${currentRatingValue !== 1 ? "s" : ""}, ${hoverLabels[currentRatingValue]}`;

  const loadCompanyRating = () => {
    const loader = async () => {
      setIsLoading(true);
      setShowRatingModal(true);
      const companyRating = await GetCompanyRatingAsync(companyId);
      setCurrentRatingValue(companyRating);
      setIsLoading(false);
    }
    loader();
  }

  const onSubmitRatingHandler = () => {
    const uploadData = async () => {
      try {
        setIsLoading(true);
        const data: AddCompanyRatingMessage = { companyId, rating: currentRatingValue };
        await AddCompanyRatingAsync(data);
        setIsLoading(false);
        toast.success(`Updated company rating - ${companyRegisteredName}`)
        setShowRatingModal(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    uploadData();
  }



  return (
    <Box>
      {
        showRatingModal === true &&
        <FormDialog
          title="RATE COMPANY"
          subDescription={companyRegisteredName}
          submitButtonText="Submit rating"
          handleClose={() => { setShowRatingModal(false); }}
          handleSubmit={onSubmitRatingHandler}
        >
          {
            isLoading ? <InLineSpinner /> :
              <Box px={2} sx={{ width: 500, display: "flex", alignItems: "center" }}>
                <Rating
                  size="large"
                  precision={1}
                  defaultValue={0}
                  value={currentRatingValue}
                  getLabelText={getLabelText}
                  onChange={(_event, newValue) => { setCurrentRatingValue(newValue ?? 0) }}
                  onChangeActive={(_event, newHover) => { setHover(newHover); }}
                />
                {currentRatingValue > 0 && (
                  <Box sx={{ ml: 2 }}>{hoverLabels[hover !== -1 ? hover : currentRatingValue]}</Box>
                )}
              </Box>
          }
        </FormDialog>
      }
      {searchType === NetconnSearchType.Supplier && (
        <Box onClick={loadCompanyRating}>
          <Rating
            defaultValue={0}
            precision={1}
            value={averageCompanyRating}
            readOnly
          />
        </Box>
      )}
      {searchType !== NetconnSearchType.Supplier && (

        <Rating
          defaultValue={0}
          precision={1}
          value={averageCompanyRating}
          readOnly
        />

      )}

    </Box>
  )
}

export default CompanyRating;