import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { CompanyComplianceSummary } from "./Models/CompanyComplianceSummary";


const baseUrl = `${protectedResources.complianceWebApi.endpoint}/api/compliance`;
const apiScopes = protectedResources.complianceWebApi.scopes;

/**
 * Attaches a given access token to a Wep API call. Returns information about the user
 */
// eslint-disable-next-line import/prefer-default-export
export const getCompanyComplianceSummary = async (companyId: number) => {
  const url = `${baseUrl}/CompanyComplianceSummary/${companyId}`;
  const result = (await makeGetRequestAsync<CompanyComplianceSummary>(apiScopes, url));
  return result;
}