/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum ConnectionType {
  None = 0,
  Client = 1,
  Supplier = 2,
  Follow = 3,
}

export default ConnectionType;

export const connectionTypeToString = (value: ConnectionType) => {
  switch (value) {
    case ConnectionType.None:
      return "None";
    case ConnectionType.Client:
      return "Client";
    case ConnectionType.Supplier:
      return "Supplier";
    case ConnectionType.Follow:
      return "Follow";
    default:
      return "Unknown Connection Type";
  }
};
