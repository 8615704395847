import { Box, CircularProgress, Grid, Typography } from "@mui/material";

// eslint-disable-next-line react/require-default-props
const InLineSpinner = ({ message = "" }: { message?: string }) => (
  <Box component="div" sx={{ display: "inline" }}>
    <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={2} sx={{mx: 3}}>
        <CircularProgress />
      </Grid>
      {message && (
        <Grid item>
          <Typography variant="subtitle1" component="p" sx={{ textAlign: "center" }}>
            {message}
          </Typography>
        </Grid>
      )}
    </Grid>

  </Box>
);

export default InLineSpinner;