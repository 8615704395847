import { Box, Container, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FC, ReactNode } from "react";
import themeOptions, { registrationPageGrey } from "../../util/MUI_Theme/Theme";
import Footer from "./Footer";

interface BasePageProps {
  // eslint-disable-next-line react/require-default-props
  ignoreHorizontalPadding?: boolean,
  children: ReactNode
}
const BasePage: FC<BasePageProps> = (props) => {
  const theme = createTheme(themeOptions);
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <Container
          sx={{
            backgroundColor: registrationPageGrey,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            paddingX: props.ignoreHorizontalPadding ? 0 : 4.5,
          }}
          maxWidth={false}
          disableGutters
        >

          <Box mt={8}>
            {props.children}
          </Box>
        </Container>
        <Footer />
      </>
    </ThemeProvider>

  )
};
export default BasePage;