import Drawer from "@mui/material/Drawer";

const TemporaryDrawer = (
  {
    showDrawer = false,
    onCloseDrawerCallback,
    children,
  }:
    {
      showDrawer: boolean,
      onCloseDrawerCallback: () => void,
      children: JSX.Element, // eslint-disable-line no-undef
    }) => (
  <Drawer
    anchor="right"
    className="drawer-temp"
    open={showDrawer}
    onClose={() => onCloseDrawerCallback()}
  >
    {children}
  </Drawer>
)

export default TemporaryDrawer;