import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationArrayModel, NotificationModel } from "../../Notifications/Models/NotificationModel";
import { RootState } from "../../../app/store";
import { ChatSummaryArrayModel, ChatSummaryModel } from "../../Messaging/Models/ChatSummaryModel";

const initialNotificationState: NotificationArrayModel = {
  allReadNotifications: [],
  allUnreadNotifications: [],
}

const initialChatState: ChatSummaryArrayModel = {
  all: [],
}


export const NotificationSlice = createSlice({
  name: "notification",
  initialState: initialNotificationState,
  reducers: {
    setReadNotification(state, action: PayloadAction<NotificationModel[]>) {
      state.allReadNotifications = action.payload; // eslint-disable-line no-param-reassign
    },
    setUnreadNotification(state, action: PayloadAction<NotificationModel[]>) {
      state.allUnreadNotifications = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const MessagingSlice = createSlice({
  name: "messaging",
  initialState: initialChatState,
  reducers: {
    setMessaging(state, action: PayloadAction<ChatSummaryModel[]>) {
      state.all = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})




export const getReadNotifications = (state: RootState) => state.notification.allReadNotifications;
export const getUnreadNotifications = (state: RootState) => state.notification.allUnreadNotifications;
export const getMessageChats = (state: RootState) => state.messaging.all;
