import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { dataManagementPartnerClientSlice, dataManagementPartnerSlice } from "./DataManagementPartnerSlice";
import { DataManagementPartnerModel } from "../Models/DataManagementPartnerModel";
import { RootState } from "../../../app/store";
import CompanyVerificationService from "../../CompanyVerificationService";
import { ManagedCompaniesModel } from "../Models/ManagedCompaniesModel";

// Data Management Get All Partners 
export const dataManagementPartnerActions = dataManagementPartnerSlice.actions;

export const fetchDataManagementPartners = (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const response: DataManagementPartnerModel[] = await CompanyVerificationService.getAllDMP();
    dispatch(dataManagementPartnerActions.setDataManagementPartner(response));
  };

// Data Management Get All Partner Clients
export const dataManagementPartnerClientActions = dataManagementPartnerClientSlice.actions;

export const fetchDataManagementPartnerClients = (partnerId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const response: ManagedCompaniesModel[] = await CompanyVerificationService.getManagedCompaniesByPartnerId(partnerId, staged);
    dispatch(dataManagementPartnerClientActions.setDataManagementPartnerClient(response));
  };
