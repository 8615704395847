/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import ButtonGroup from "../../Registration/Sections/ButtonGroup";
import MyPDF from "./PDF";
import useUnspcOptions from "../../../util/hooks/queries/useUnspcOptions";
import useCompanyUnspscSelection from "../../../util/hooks/queries/useCompanyUnspscSelection";
import { DocumentDtoModel } from "../../../http/DocumentManagement/Models/DocumentDtoModel";
import { UnspscSegment } from "../../../http/Configuration/UnspscSegment";
import { CompanyComplianceSummary } from "../../../http/Compliance/Models/CompanyComplianceSummary";
import useCountries from "../../../util/hooks/queries/useCountries";
import { CompanyProfileViewModel } from "../../../http/Company/Models/CompanyProfileViewModel";
import { CompanyProfileSettings } from "../../Registration/RegistrationSteps/SupportingDocumentsStepFour";
import useProvinces from "../../../util/hooks/queries/useProvinces";
import useBeeSectors from "../../../util/hooks/queries/useBeeSectors";
import useBeeTurnoverBands from "../../../util/hooks/queries/useBeeTurnoverBands";
import useBanks from "../../../util/hooks/queries/useBanks";
import useBankAccountTypes from "../../../util/hooks/queries/useBankAccountTypes";

interface PDFProps {
  connected: boolean;
  company: CompanyProfileViewModel;
  companyProfileSettings: CompanyProfileSettings;
  document: DocumentDtoModel[];
  profileImage: string;
  companyComplianceSummary: CompanyComplianceSummary;
}

const ProfilePDFDownload = ({
  connected,
  company,
  companyProfileSettings,
  document,
  profileImage,
  companyComplianceSummary,
}: PDFProps) => {
  const navigate = useNavigate();
  const unspscOptions = useUnspcOptions();
  const unspscSelectionIdList = useCompanyUnspscSelection();
  const { data: countries = [] } = useCountries();
  const { data: provinces = [] } = useProvinces();
  const { data: beeSectors = [] } = useBeeSectors();
  const { data: annualTurnover = [] } = useBeeTurnoverBands();
  const handleNavigateToHome = async () => {
    navigate("/home");
  };

  const selectedItems = unspscOptions?.data?.segments.map((seg) => {
    const segmentFamilies = seg.families.filter((x) => unspscSelectionIdList?.data?.includes(x.id));
    if (segmentFamilies.length > 0) {
      return { ...seg, families: segmentFamilies } as UnspscSegment;
    }
    return null;
  });

  const handleDownloadPDF = async () => {
    const doc = (
      <MyPDF
        countries={countries}
        provinces={provinces}
        beeSectors={beeSectors}
        annualTurnover={annualTurnover}
        companyProfileSettings={companyProfileSettings}
        selectedItems={selectedItems as UnspscSegment[]}
        company={company}
        document={document as DocumentDtoModel[]}
        profileImage={profileImage}
        companyComplianceSummary={companyComplianceSummary}
      />
    );
    const asPdf = pdf([] as any); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "document.pdf");
  };

  const renderButtonGroup = (isCompanyConnected: boolean) => {
    if (!connected) {
      return (
        <ButtonGroup
          px={12}
          buttons={[
            {
              label: "back to dashboard",
              align: "left",
              type: "primary",
              onClick: handleNavigateToHome,
            },
          ]}
        />
      );
    }

    return (
      <ButtonGroup
        px={12}
        buttons={[
          {
            label: "back to dashboard",
            align: "left",
            type: "primary",
            onClick: handleNavigateToHome,
          },
          {
            label: "Download Profile PDF",
            align: "right",
            type: "secondary",
            onClick: handleDownloadPDF,
          },
        ]}
      />
    );
  };


  return (
    <div>
      <Box sx={{ m: 0, p: 0, width: "100%" }}>
        {renderButtonGroup(connected)}
      </Box>
    </div>
  );
};

export default ProfilePDFDownload;
