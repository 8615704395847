import { Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState, KeyboardEvent } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FieldModel } from "../../../http/Configuration/Fields/FieldModel";
import { ValidationModel } from "../../../http/Configuration/Validation/ValidationModel";


const TestingValidationContainer = ({ validationList, fieldsList, backToView }: { validationList: ValidationModel, fieldsList: FieldModel, backToView: () => void }) => {
  const [listValidations, setListValidation] = useState<ValidationModel>(validationList as ValidationModel);
  const [listFields, setListField] = useState<FieldModel>(fieldsList as FieldModel);
  const [error, setError] = useState<string>("");

  const onInputChange = (ev: KeyboardEvent<HTMLInputElement>) => {
    const val = listValidations;
    if (val) {
      const value = (ev.target as HTMLInputElement).value;
      // run validation on field -- could append this into the regex and do it all in one shot.
      if (val.minLength && val.minLength > 0) {
        if (value.length < val.minLength) {
          setError("Too short");
        } else {
          setError("");
        }
      }
      if (val.pattern && val.pattern !== "") {
        if (!value.match(val.pattern)) {
          setError(val.defaultMessage as string);
        } else {
          setError("");
        }
      }

    }
  }

  useEffect(() => {

    setListValidation(validationList);
    setListField(fieldsList);

  }, [fieldsList, listFields.id, listValidations.id, validationList]);
  return (
    <Grid container>
      <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
        <Tooltip title="Back To Field Validation Table">
          <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid xs={3} sx={{ minWidth: "350px" }}>
        <Typography><b>Field Name:</b> {listFields.name}</Typography>
        <Typography><b>Validation Max Length:</b> {listValidations.maxLength}</Typography>
        <Typography><b>Validation Min Length:</b> {listValidations.minLength}</Typography>
        <Typography><b>Pattern To Match:</b> {listValidations.pattern}</Typography>
        <Typography><b>Pattern Mismatch Message:</b> {listValidations.defaultMessage}</Typography>
      </Grid>
      <Grid xs={3} sx={{ minWidth: "350px" }}>
        <div className={listFields.variant ? "active" : "not-active"}>
          <TextField
            name={listFields.label}
            variant={listFields.variant ? "outlined" : "standard"}
            label={listFields.label}
            type={listFields.type}
            size="small"
            sx={{ width: "100%" }}
            margin="normal"
            onInput={(ev) => { onInputChange(ev as KeyboardEvent<HTMLInputElement>) }}
            error={!!error}
            helperText={error} />
        </div>

      </Grid>
    </Grid>

  )
};

export default TestingValidationContainer;