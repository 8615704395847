import { Button, Grid, ListItemButton, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import SweetAlert2 from "react-sweetalert2";
import { AddCompanyBeeGroup } from "../../../../http/Company/company";
import { CompanyBeeGroupModel } from "../../../../http/Company/Models/CompanyBeeGroupModel";
import HoldingCompanySwitcher from "../CompanyDetails/HoldingCompanySwitcher";
import { selectBaseCompanyInfo } from "../../../../http/Redux/Store/baseCompanyInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getCompanyBeeGroup } from "../../../../http/Redux/Store/CompanyBeeGroupSlice";
import { CompanyBeeGroupEditModel } from "../../../../http/Company/Models/CompanyBeeGroupEditModel";
import "../../../Connect/SearchResult/SweetAlert.css";
import { viewCompanyBeeGroupActions } from "../../../../http/Redux/Store/CompanyBeeGroupAction";
import { selectUserCompanyList } from "../../../../http/Redux/Store/UserCompanyListSlice";
import { UserCompanyModel } from "../../../../http/Redux/Models/UserCompanyModel";

const CompanyBEECertPost = ({ edit, label }: { edit: boolean, label: string }) => {
  const dispatch = useAppDispatch();

  const userCompanies = useAppSelector(selectUserCompanyList);

  // eslint-disable-next-line no-unused-vars
  const [userCompaniesState, setUserCompaniesState] = useState<Array<UserCompanyModel>>([] as Array<UserCompanyModel>);

  const [companyValue, setCompanyValue] = useState(0);
  const [send] = useState<CompanyBeeGroupEditModel>({} as CompanyBeeGroupEditModel);
  const [isAdded, setIsAdded] = useState(0);
  const [swalPropsChangeAdd, setSwalPropsChangeAdd] = useState({});
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const company = useAppSelector(selectBaseCompanyInfo);
  const groupingList: CompanyBeeGroupModel[] = useAppSelector(getCompanyBeeGroup);

  const chooseHolding = useCallback((fields: string) => {
    if (fields !== "") {
      setCompanyValue(Number(fields));
    }
  }, []);

  const saveCompanyBeeGroup = async (groupToAdd: CompanyBeeGroupEditModel, companyId: number, groupId: number, registeredName: string) => {
    const state = await AddCompanyBeeGroup(groupToAdd);
    setIsAdded(state);
    dispatch(
      viewCompanyBeeGroupActions.addNewCompanyToBeeGroup({
        id: state,
        companyId: companyValue,
        groupId: companyId,
        registeredName,
      })
    );
  }

  const handleClickSave = async () => {
    const add: CompanyBeeGroupEditModel = send;
    add.dateCreated = new Date();
    add.createdBy = userAccount || "";
    add.modifiedBy = userAccount || "";
    add.dateModified = new Date();
    add.companyId = companyValue;
    add.groupId = company.companyId;

    const userCompany = userCompanies.find(uc => uc.companyId === companyValue) as UserCompanyModel;

    if (add.companyId === 0) {
      return;
    }

    saveCompanyBeeGroup(add, companyValue, company.companyId, userCompany.registeredName);

    setUserCompaniesState(userCompaniesState.filter(comp => comp.companyId !== companyValue));
  };

  const initialUserCompanyFilter = () => {
    const initialFilteredList = userCompanies.filter(comp =>
      !groupingList.some(group => group.companyId === comp.companyId)
    );
    return initialFilteredList;
  }

  /* Sets the local user companies state with a list of companies minus the
   * current signed in user's company.
   */
  useEffect(() => {
    setUserCompaniesState(initialUserCompanyFilter());
  },[])

  useEffect(() => {
    if (isAdded === 200) {
      setSwalPropsChangeAdd({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });

      setTimeout(() => {
        setIsAdded(0);
      }, 2100);
    }
  }, [isAdded, timeVal]);

  return (
    <Grid>
      <HoldingCompanySwitcher
        companyList={userCompaniesState}
        label={label}
        field=""
        choose={chooseHolding}
        disabled={!edit}
        useId={Boolean(true)}
        companyId={company.companyId}
      />
      <Button disabled={!edit || companyValue === 0} onClick={handleClickSave}>Add</Button>

      <div>
        {groupingList.map((record) => (
          <Grid item xs={3}>
            <Paper elevation={2}>
              <ListItemButton>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <h3>{record.registeredName}</h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ListItemButton>
            </Paper>
          </Grid>
        ))}
      </div>
      <SweetAlert2 {...swalPropsChangeAdd} />
    </Grid>
  );
};

export default CompanyBEECertPost;
