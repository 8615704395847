// eslint-disable-next-line no-shadow
enum AddressType { // eslint-disable-line no-unused-vars
  None = 0,        // eslint-disable-line no-unused-vars
  Physical = 1,    // eslint-disable-line no-unused-vars
  Postal = 2,      // eslint-disable-line no-unused-vars
}

export default AddressType;

export const addressTypeToString = (value: AddressType) => {
  switch (value) {
    case AddressType.None:
      return "None";
    case AddressType.Physical:
      return "Physical";
    case AddressType.Postal:
      return "Postal";
    default:
      return "Unknown Address Type";
  }
};
