import { FormControl, FormLabel, Typography, FormGroup, Grid, FormControlLabel, Checkbox, TextField, Box } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import debounce from "lodash.debounce";
import FormDialog from "../../../Dialogs/FormDialog"
import { UnspscSegment } from "../../../../http/Configuration/UnspscSegment";
import InLineSpinner from "../../../LoadingSpinners/InlineSpinner";
import { UnspscFamily } from "../../../../http/Configuration/UnspscFamily";
import useSimpleTextSearchSegmentsAndFamilies from "../../../../util/hooks/search/useSimpleTextSearchSegmentsAndFamilies";
import useUnspcOptions from "../../../../util/hooks/queries/useUnspcOptions";

const GlobalProductServicesSelector = ({
  companyUnspscSelection,
  handleClose,
  onSubmitSelectionCallback,
}: {
  companyUnspscSelection: number[],
  handleClose: () => void,
  // eslint-disable-next-line no-unused-vars
  onSubmitSelectionCallback: (selection: number[]) => void,
}) => {
  const [internalSelection, setInternalSelection] = useState<number[]>(companyUnspscSelection)
  const [searchResults, setSearchResults] = useState<UnspscSegment[]>([])
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { data: unspscOptions = { segments: [], families: [] } } = useUnspcOptions();
  const simpleSearch = useSimpleTextSearchSegmentsAndFamilies(unspscOptions.segments);

  const handleOnGlobalCategoryChange = (checked: boolean, currentItemId: number) => {
    checked ?
      setInternalSelection(prevState => [...prevState, currentItemId])
      :
      setInternalSelection(prevState => prevState.filter((x) => x !== currentItemId));
  }

  const handleOnSearchTextChange = (searchTextStr: string) => {
    setSearchText(searchTextStr);
    const loader = async () => {
      try {
        setIsLoadingSearchResults(true);
        const result = simpleSearch.search(searchTextStr);
        setSearchResults(result);
        setIsLoadingSearchResults(false);
      } catch (error) {
        setIsLoadingSearchResults(false);
      }
    }

    loader();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResults = useMemo(() => debounce(handleOnSearchTextChange, 300), []);

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  const renderCategoryCheckList = () => ((searchResults.length > 0 || searchText.length > 0
    ? searchResults : unspscOptions.segments).map((seg: UnspscSegment) =>
    (
      <FormControl key={seg.id} sx={{ m: 3, mb: 4 }} component="fieldset" variant="standard">
        <FormLabel component="legend" className="header-sub-products">
          <Typography variant="h5" component="p">
            {seg.name}
          </Typography>
        </FormLabel>
        <FormGroup key={seg.id} row>
          <Grid item container xs={12} rowSpacing={2} columnSpacing={3}>
            {
              seg.families.map((fam: UnspscFamily) => (
                <Grid item xs={4} key={fam.code}>
                  <FormControlLabel
                    key={fam.id}
                    control={
                      <Checkbox key={fam.code}
                        checked={internalSelection.includes(fam.id)}
                        onChange={(ev) => handleOnGlobalCategoryChange(ev.target.checked, fam.id)}
                      />
                    }
                    label={fam.name}
                  />
                </Grid>
              ))
            }
          </Grid>
        </FormGroup>
      </FormControl>
    ))
  )

  return (
    <FormDialog
      fullWidth
      maxWidth="lg"
      submitButtonText="SAVE"
      closeButtonText="CANCEL"
      handleClose={handleClose}
      handleSubmit={() => {
        onSubmitSelectionCallback(internalSelection);
        handleClose();
      }}
      title="PRODUCT/SERVICE CATEGORIES"
      description="Please indicate your primary product and/or service offering only.
                  Keep in mind that buyers may be wary of a supplier who claims to do everything."
      subDescription="B1LINK is authorized to adopt The United Nations Standard Products and Services Code (UNSPSC)."
    >
      <div>
        <Grid item container xs={12} spacing={2}>
          <>
            <TextField
              label="search for products or services"
              variant="filled"
              onChange={(ev) => debouncedResults(ev.target.value)}
              sx={{ width: "93%", marginLeft: "18px", marginTop: "18px" }}
            />
            {isLoadingSearchResults && <InLineSpinner />}
            {
              isLoadingSearchResults === false && searchText.length > 0 && searchResults.length === 0 ?
                <Box>
                  <b>No results found</b>
                </Box> :
                renderCategoryCheckList()
            }
          </>
        </Grid>
      </div>
    </FormDialog>
  )
}

export default GlobalProductServicesSelector;