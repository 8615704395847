import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Grid,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

import { UnspscSegment } from "../../../../http/Configuration/UnspscSegment";
import InLineSpinner from "../../../LoadingSpinners/InlineSpinner";
import VerificationSection from "../../../WorkCenter/Verification/VerificationSection";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";

import "../CompanyPost.css";
import GlobalProductServicesSelector from "./GlobalProductAndServicesSelector";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import useAllowedPermission from "../../../../util/hooks/useAllowedPermission";
import { CompanyViewModel } from "../../../../http/Company/Models/CompanyViewModel";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyViewModelState } from "../../../../http/Redux/Store/companySlice";
import useUnspcOptions from "../../../../util/hooks/queries/useUnspcOptions";

interface CompanyProductsAndServicesProps {
  headerText: string;
  company: CompanyViewModel;
  // eslint-disable-next-line react/require-default-props
  fromDashboard?: boolean;
  // eslint-disable-next-line no-unused-vars
  OnSave: (companyDetails: CompanyViewModel, selection?: Array<number>) => void;
  workcentreAgentPath: boolean;
  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;
  staged: boolean;
  // eslint-disable-next-line react/require-default-props
  agentTaskId?: number;
}

const CompanyProductsAndServicesPost = ({
  headerText = "",
  fromDashboard,
  company,
  OnSave,
  workcentreAgentPath,
  SetDirtyCallback,
  staged,
  agentTaskId = 0,
}: CompanyProductsAndServicesProps) => {
  // React hooks
  const dispatch = useAppDispatch();

  const companyId = useAppSelector((state) => state.baseCompanyInfo.companyId);

  const [canEdit, setEdit] = useState(false);
  const [editCompany, setEditCompany] = useState<CompanyViewModel>(company);
  const [companyComparer, setCompanyComparer] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);

  const { isLoading: loadingCategories, data: unspscOptions = { families: [], segments: [] } } = useUnspcOptions();

  const hasAllowedPermission = useAllowedPermission();

  const debouncedCompanyValue = useInputDebounce(editCompany);

  const CancelEdit = () => {
    setEdit(false);
  };

  const performDirtyCheck = (checkVal: CompanyViewModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(companyComparer)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleUnspscSelectionChange = (selectionArray: Array<number>) => {
    const newVal = { ...editCompany, selectedUnspscFamilies: selectionArray } as CompanyViewModel;
    setEditCompany(newVal);
    performDirtyCheck(newVal);
  };


  useEffect(() => {
    dispatch(setCompanyViewModelState(debouncedCompanyValue));
  }, [debouncedCompanyValue]);

  // Change handlers
  const handleSaveChange = async () => {
    const updateCompany: CompanyViewModel = { ...editCompany };
    const selectedProdServCodes: Array<number> =
      [...updateCompany.selectedUnspscFamilies] || ([] as Array<number>);

    OnSave(updateCompany, selectedProdServCodes);
    setEditCompany(updateCompany);
    setCompanyComparer(JSON.parse(JSON.stringify(updateCompany)));

    // Set IsDirty to false after a save.
    SetDirtyCallback(false);
  };

  const handleCategoryDelete = (deletedItemId: number) => {
    const currentSelection = editCompany.selectedUnspscFamilies;
    const newVal: CompanyViewModel = { ...editCompany, selectedUnspscFamilies: currentSelection.filter((cs) => cs !== deletedItemId) }
    setEditCompany(newVal);
    performDirtyCheck(newVal);
  };

  const toggleEdit = async () => {
    if (canEdit === true) {
      await handleSaveChange();
    }
    setEdit((prevState) => !prevState);
  };

  const handleDescriptionTextChange = (textValue: React.ChangeEvent<HTMLInputElement>) => {
    const newVal: CompanyViewModel = { ...editCompany, tradeDescription: textValue.target.value };
    setEditCompany(newVal);
    performDirtyCheck(newVal);
  };

  // array/object mapping
  const renderSelectedItemChips = () => {
    const selectedItems = unspscOptions.segments.map((seg) => {
      const segmentFamilies = seg.families.filter((x) => editCompany.selectedUnspscFamilies.includes(x.id));
      if (segmentFamilies.length > 0) {
        return { ...seg, families: segmentFamilies } as UnspscSegment;
      }
      return null;
    });

    return selectedItems
      .filter((x) => x != null)
      .map((item) => (
        <Grid xs={12}>
          <Box sx={{ width: "100%" }}>
            <Typography>
              <b>{item?.name}</b>
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}
          >
            {item?.families.map((f) => (
              <Chip
                sx={{ fontWeight: "bold" }}
                key={f.id}
                label={f.name}
                disabled={!canEdit}
                onDelete={() => handleCategoryDelete(f.id)}
              />
            ))}
          </Box>
        </Grid>
      ));
  };

  return (
    <Accordion expanded className="Accord-Hide">
      {!fromDashboard &&
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="Accord-heading"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">{headerText}</h3>
          </Typography>

        </AccordionSummary>
      }
      <AccordionDetails id="step-service">
        <div className={styles.editSave}>
          <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
            {!canEdit && !loadingCategories && (
              <div className={styles.btnBox}>
                <Tooltip title="Edit">
                  <FontAwesomeIcon
                    icon={faPencilSquare}
                    aria-hidden="true"
                    id="edit-general-details"
                    onClick={toggleEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
            )}
            {canEdit && !fromDashboard && (
              <div className={styles.btnBox}>
                <div className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={toggleEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
                <div className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </WithRequiredPermission>
        </div>

        {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) && workcentreAgentPath && (
          <div className="post__header_top">
            <VerificationSection section="CompanyService" companyId={companyId} staged={staged} agentTaskId={agentTaskId} />
          </div>
        )}

        <div className={styles.postBody}>
          {loadingCategories ? (
            <InLineSpinner message="Loading..." />
          ) : (
            <Grid id="productServControlGrid" container rowSpacing={3}>
              <Grid container xs={12} sx={{ m: 1 }}>
                <Box sx={{ width: "100%" }}>
                  <InputLabel>
                    Please capture a brief description of your products and service offerings which
                    will be displayed in the marketplace to support connections:
                  </InputLabel>
                </Box>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  value={editCompany.tradeDescription || ""}
                  rows={4}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleDescriptionTextChange(ev)}
                  disabled={!canEdit}
                  className={canEdit ? "focus" : ""}
                />
              </Grid>
              {!loadingCategories && unspscOptions.segments && (
                <Grid container item xs={12} sx={{ m: 1 }}>
                  <Box sx={{ width: "100%" }}>
                    <InputLabel>Global Products & Services</InputLabel>
                    {editCompany?.selectedUnspscFamilies?.length > 0 && unspscOptions.families && renderSelectedItemChips()}
                    <Button
                      variant="outlined"
                      disabled={!canEdit}
                      onClick={() => setShowEditCategoriesModal(true)}
                    >
                      SELECT PRODUCT/SERVICES
                    </Button>
                  </Box>
                </Grid>
              )}

              {showEditCategoriesModal && (
                <Grid xs={12}>
                  <GlobalProductServicesSelector
                    companyUnspscSelection={editCompany.selectedUnspscFamilies || [] as Array<number>}
                    handleClose={() => setShowEditCategoriesModal(false)}
                    onSubmitSelectionCallback={(selection) => {
                      handleUnspscSelectionChange(selection);
                    }}
                  />
                </Grid>
              )}

              <Grid container xs={12} sx={{ m: 1 }}>
                <FormControlLabel
                  control={<Checkbox disabled={!canEdit} />}
                  label="Notify me about new opportunities for my products and services"
                />
              </Grid>
            </Grid>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyProductsAndServicesPost;
