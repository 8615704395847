import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/BackspaceOutlined";
import { useEffect, useState } from "react";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";


// eslint-disable-next-line no-unused-vars
const EditUpload = ({ data, handleAbandon }: { data: AgentTaskListGridModel, handleAbandon: (rowId: number) => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateModel, setUpdateModel] = useState<AgentTaskModel>({} as AgentTaskModel);

  const handleClickAbandon = () => {

    setIsLoading(true);
    const loader = async () => {
      await CompanyVerificationService.updateAgentTask(updateModel, data.dataSource === "Staged").finally(() => {
        setIsLoading(false);
      });
    }
    loader();
    handleAbandon(updateModel.id);
  }


  useEffect(() => {
    setUpdateModel({
      ...updateModel,
      id: data.agentTaskId,
      companyId: data.companyId,
      status: AgentTaskStatusTypes.Abandoned,
      completion: data.completion,
      comment: data.comment,
      agentUserId: data.agentUserId,
      clientId: data.clientId,
      agentCompanyId: data.agentCompanyId,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      expectedNextUpdateDate: data.expectedNextUpdateDate,
    })
  }, [])

  return (
    <>
      <LoadingBackdrop showBackdrop={isLoading} />
      <Tooltip title="Abandon Task">
        <IconButton onClick={handleClickAbandon}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </>

  )
}

export default EditUpload;