interface DashboardStatsCardModel {
  myBuyersCount: number,
  mySuppliersCount: number,
  connectionRequestsMadeCount: number,
  connectionRequestsReceivedCount: number,
  invitedConnectionsCount: number
}


export const DashboardStatsHeadings = [
  {
    "ref": "myBuyersCount",
    "title": "My Buyers",
    "url": "/network/clients",
  }, {
    "ref": "mySuppliersCount",
    "title": "My Suppliers",
    "url": "/network/suppliers",
  },
  {
    "ref": "connectionRequestsMadeCount",
    "title": "Connection Requests Made",
    "url": "/network/made",
  },
  {
    "ref": "connectionRequestsReceivedCount",
    "title": "Connection Requests Received",
    "url": "/network/received",
  },
  {
    "ref": "invitedConnectionsCount",
    "title": "Invited Connections ",
    "url": "/network/invited",
  },
];


export default DashboardStatsCardModel;