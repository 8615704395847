import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DropzoneArea } from "material-ui-dropzone";
import { Box, Typography } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { addNewCompanyDocumentAsync } from "../../../http/DocumentManagement/DocumentManagement";
import LoadingOverlay from "../../UI/LoadingOverlay";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// eslint-disable-next-line no-unused-vars
const CustomizedDialogs = ({
  title,
  handleClose,
  open,
  documentType,
  companyId,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onUploadSuccess = () => {}, // Default parameter
}: {
  title: string;
  handleClose: () => void;
  open: boolean;
  documentType: number;
  companyId: number;
  // eslint-disable-next-line react/require-default-props
  onUploadSuccess?: () => void; // Optional parameter
}) => {
  // eslint-disable-next-line no-unused-vars
  const [upload, setUploadFile] = React.useState<Array<File>>([] as Array<File>);
  const [newTitle, setTitle] = React.useState("");
  const { accounts } = useMsal();  
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const [isLoading, setIsLoading] = React.useState(false);

  const UploadFile = async () => {
    setIsLoading(true);
    await addNewCompanyDocumentAsync(
      companyId,
      false,
      new Date(0),
      documentType,
      userAccount as string,
      upload
    ).finally(() => {
      setIsLoading(false);
      onUploadSuccess(); 
      handleClose();
      toast.success("Document Uploaded");
    });
  };

  React.useEffect(() => {
    if (upload.length !== 0) {
      setTitle(upload[0].name);
    }
  }, [companyId, upload, upload.length])

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <LoadingOverlay active={isLoading}>
            <Box height={300} width={500} sx={{ margin: 2 }}>
              <DropzoneArea
                filesLimit={1}
                showAlerts={false}
                dropzoneText="Drop File"
                showPreviews={false}
                showFileNames
                showPreviewsInDropzone={false}
                onChange={(files) => { setUploadFile(files) }}
              />
              <br />
              <Typography>Upload: {newTitle}</Typography>
            </Box>
          </LoadingOverlay>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => UploadFile()} variant="contained">
            Save Upload
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
};

export default CustomizedDialogs;
