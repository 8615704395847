import { faPencilSquare, faFloppyDisk, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  MenuItem,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  OutlinedInput,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Box,
  Alert,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import { GetSectorTurnoverBands, GetValidBeeLevels } from "../../../../http/Company/beeDetailsApi";
import { BeeDetailsModel } from "../../../../http/Company/Models/BeeDetailsModel";
import { SectorModel } from "../../../../http/Company/Models/SectorModel";
import { TurnoverModel } from "../../../../http/Company/Models/TurnoverModel";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import InLineSpinner from "../../../LoadingSpinners/InlineSpinner";
import WithRequiredPermission from "../../../WithRequiredPermission";
import CompanyBEEGrouping from "./CompanyBEEGrouping";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchBeeLevels } from "../../../../http/Redux/Store/BeeLevelListActions";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyBeeDetailsState } from "../../../../http/Redux/Store/companySlice";
import useSouthAfricaSpecificFeature from "../../../../util/hooks/useSouthAfricaSpecificFeature";

import { BeeLevelModel } from "../../../../http/Company/Models/BeeLevelModel";
import { BdgSubPercentageModel } from "../../../../http/Company/Models/BdgSubPercentageModel";
import useAllowedPermission from "../../../../util/hooks/useAllowedPermission";
import { fetchAllBeeRatingLookupLists } from "../../../../http/Redux/Store/BeeRatingsLookupActions";
import { ReadRepDocumentDto } from "../../../../http/DocumentManagement/Models/ReadRepDocumentDto";
import DocumentType from "../../../../util/enums/DocumentType";
import { getCompanyDocumentByTypeIdAsync } from "../../../../http/DocumentManagement/DocumentManagement";
import CompanyBeeRatingStatus from "./CompanyBeeRatingStatus";
import { BeeRatingStatus, beeRatingStatusToString } from "../../../../util/enums/BeeRatingStatus";
import { BeeRatingSource } from "../../../../util/enums/BeeRatingSource";
import { BeeClassification, beeClassificationToString } from "../../../../util/enums/BeeClassification";

/*
 _____  _      _        _                _      _                                                                    _
|_   _|| |    (_)      (_)              | |    (_)                                                                  | |
  | |  | |__   _  ___   _  ___    __ _  | |__   _   __ _    ___   ___   _ __ ___   _ __    ___   _ __    ___  _ __  | |_
  | |  | '_ \ | |/ __| | |/ __|  / _` | | '_ \ | | / _` |  / __| / _ \ | '_ ` _ \ | '_ \  / _ \ | '_ \  / _ \| '_ \ | __|
  | |  | | | || |\__ \ | |\__ \ | (_| | | |_) || || (_| | | (__ | (_) || | | | | || |_) || (_) || | | ||  __/| | | || |_
  \_/  |_| |_||_||___/ |_||___/  \__,_| |_.__/ |_| \__, |  \___| \___/ |_| |_| |_|| .__/  \___/ |_| |_| \___||_| |_| \__|
                                                    __/ |                         | |
                                                   |___/                          |_|

  ... and this little blurb won't be here permanently.
*/

interface companyBeeProps {
  beeData: BeeDetailsModel;
  sectorList: Array<SectorModel>;

  // eslint-disable-next-line react/require-default-props
  isWorkCentre?: boolean;
  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  OnBeeSave: (beeDetails: BeeDetailsModel) => void;
  // eslint-disable-next-line no-unused-vars
  showLoadingBackdrop: (show: boolean) => void;
}

const CompanyBEEDetails = ({
  beeData = {} as BeeDetailsModel,
  sectorList,
  isWorkCentre,
  OnBeeSave,
  SetDirtyCallback,
  showLoadingBackdrop,
}: companyBeeProps) => {
  const dispatch = useAppDispatch();
  const countryCheck = useSouthAfricaSpecificFeature();
  const beeLevels = useAppSelector((state) => state.beeLevelList);

  const baseCompanyInfo = useAppSelector((state) => state.baseCompanyInfo);
  const beeRatingStatuses = useAppSelector((state) => state.beeRatingStatusList);
  const beeRatingSources = useAppSelector((state) => state.beeRatingSourceList);
  const beeRatingVerficationAgents = useAppSelector(
    (state) => state.beeRatingVerificationAgencyList
  );

  const initialLoadCheck = useRef(true);
  const hasPermission = useAllowedPermission();

  const [beeLocal, setBeeLocal] = useState<BeeDetailsModel>({ ...beeData });
  const debouncedBeeValue = useInputDebounce(beeLocal, 500);

  // eslint-disable-next-line no-unused-vars
  const [isBeeDisabled, setIsBeeDisabled] = useState<boolean | undefined>(undefined);
  const [beeDetailsComparer, setBeeDetailsComparer] = useState<BeeDetailsModel>(
    {} as BeeDetailsModel
  );

  const [validBeeLevelMenuItems, setValidBeeLevelMenuItems] = useState<Array<BeeLevelModel>>(
    [] as Array<BeeLevelModel>
  );
  const [hasBeeDetails, setHasBeeDetails] = useState<boolean>(false);
  const [certExpDate, setCertExpDate] = useState<Dayjs | null>(
    dayjs(beeData?.certificateExpiryDate || null)
  );
  const [turnoverBands, setTurnoverBands] = useState<Array<TurnoverModel>>(
    [] as Array<TurnoverModel>
  );
  const [turnoverLoader, setTurnoverLoader] = useState<boolean>(false);
  const [beeLevelLoader, setBeeLevelLoader] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [bdgSubPercObj, setBdgSubPercObj] = useState<BdgSubPercentageModel>({
    blackYouth: 0,
    blackDisabled: 0,
    blackUnemployed: 0,
    blackRural: 0,
    blackMilitaryVet: 0,
    blackFarmWorkers: 0,
  });

  const [bdgSubPercSum, setBdgSubPercSum] = useState<number>(0);
  const [docData, setDocData] = useState<ReadRepDocumentDto>({} as ReadRepDocumentDto);

  const [miningEntityToggle, setMiningEntityToggle] = useState<boolean>(false);

  const agriSectorId = useMemo(
    () => sectorList.find((sector) => sector.value.toLowerCase() === "agribee")?.id || 0,
    [sectorList]
  );

  const transportSectorId = useMemo(
    () => sectorList.find((sector) => sector.value.toLowerCase() === "transport")?.id || 0,
    [sectorList]
  );

  const verificationAgentSourceId = useMemo(
    () =>
      beeRatingSources.find((source) => source.name.toLowerCase() === "verification certificate")
        ?.id || 0,
    [beeRatingSources]
  );

  const maxAllowedCertExpiryDate = dayjs().add(365, "day");

  // Generates a URL to fetch the current BEE certificate
  const generateAndSetDocUrl = (fileId: string) => {
    const blobStorageUrl = process.env.REACT_APP_STORAGE_ACCOUNT_URL || "";
    if (!blobStorageUrl) return "";

    const docUrl = `${blobStorageUrl}/documents/${fileId}`;

    return docUrl;
  };

  // Handles the fetching of the current BEE certificate document when the button is clicked
  const handleCertDocClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    documentData: ReadRepDocumentDto
  ) => {
    const { id, fileName } = documentData;

    if (Object.keys(documentData).length === 0) {
      Swal.fire("Invalid File Data", "The file is either corrupt or doesn't exist in storage.", "error");
      return;
    }

    const fileUrl = generateAndSetDocUrl(id || "");

    if (!fileUrl) return;

    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const windowUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = windowUrl;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
    });
  };

  const handleMiningToggleChange = () => {
    if (miningEntityToggle) {
      const newVal = { ...beeLocal, localServiceProvider: false, localGoodsManufacturer: false };
      setBeeLocal(newVal);
      setMiningEntityToggle(false);
      return;
    }

    setMiningEntityToggle(true);
  }

  // Got this guy from here: https://stackoverflow.com/a/39046365
  const sumObjectValues = useCallback(
    (obj: object) => Object.values(obj).reduce((a, b) => a + b, 0),
    []
  );

  const getCertExpiryStatus = (certExpiryDateString: string | null) => {
    if (!certExpiryDateString) {
      return (
        <Alert
          severity="info"
          icon={false}
          sx={{ color: "#ffffff", backgroundColor: "#808080", justifyContent: "center" }}
        >
          {beeRatingStatusToString(BeeRatingStatus.none)}
        </Alert>
      );
    }

    const certExpiryDate = new Date(certExpiryDateString);

    const today = new Date();

    const differenceInTime = certExpiryDate.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // difference in time divided by 84,600,000 (total MS in a day)

    if (differenceInDays > 30)
      return (
        <Alert severity="success" icon={false} sx={{ color: "#ffffff", backgroundColor: "#008000", justifyContent: "center" }} >
          {beeRatingStatusToString(BeeRatingStatus.rated)}
        </Alert>
      );

    if (differenceInDays > 0 && differenceInDays < 30)
      return (
        <Alert severity="warning" icon={false} sx={{ color: "#ffffff", backgroundColor: "#ffa500", justifyContent: "center" }}>
          {beeRatingStatusToString(BeeRatingStatus.ratingExpiring)}
        </Alert>
      );

    if (differenceInDays <= 0)
      return (
        <Alert severity="error" icon={false} sx={{ color: "#ffffff", backgroundColor: "#ff0000", justifyContent: "center" }}>
          {beeRatingStatusToString(BeeRatingStatus.ratingExpired)}
        </Alert>
      );

    return (
      <Alert severity="info" icon={false} sx={{ color: "#ffffff", backgroundColor: "#808080", justifyContent: "center" }}>
        {beeRatingStatusToString(BeeRatingStatus.none)}
      </Alert>
    );
  }

  // Extracts the Black-Designated Group sub percentages from the passed in BEEDetails object
  const extractBdgValsFromMainObject = useCallback((beeDataObj: BeeDetailsModel) => {
    const extractedVals: BdgSubPercentageModel = {
      blackYouth: beeDataObj.blackYouth || 0,
      blackDisabled: beeDataObj.blackDisabled || 0,
      blackUnemployed: beeDataObj.blackUnemployed || 0,
      blackRural: beeDataObj.blackRural || 0,
      blackMilitaryVet: beeDataObj.blackMilitaryVet || 0,
      blackFarmWorkers: beeDataObj.farmWorkers || 0,
    };
    return extractedVals;
  }, []);

  // Fetches and processes the valid BEE Levels as per the selected
  const processValidBeeLevels = useCallback(
    async (selectedSectorId: number, selectedTurnoverBandId: number, setBoPercentage: number) => {
      try {
        if (!selectedSectorId || !selectedTurnoverBandId)
          setValidBeeLevelMenuItems([] as Array<BeeLevelModel>);

        if (setBoPercentage === null || setBoPercentage === undefined || setBoPercentage < 0)
          return;

        setBeeLevelLoader(true);
        const validBeeLevels = await GetValidBeeLevels(
          selectedSectorId,
          selectedTurnoverBandId,
          setBoPercentage || 0
        );
        const currentLevels = [...beeLevels];

        // Take the beeLevels object and filter here, returning the MenuItems.
        const validLevels: Array<BeeLevelModel> = currentLevels.filter((level) =>
          validBeeLevels.includes(level.id)
        );
        setValidBeeLevelMenuItems(validLevels);
      } catch (error) {
        Swal.fire(
          "An error happened loading B-BBEE Levels",
          "Please try again, or contact support.",
          "error"
        );
        setValidBeeLevelMenuItems([] as Array<BeeLevelModel>);
      } finally {
        setBeeLevelLoader(false);
      }
    },
    []
  );

  // Evaluates if the BEE base data for BEE level selected has changed, and proceeds accordingly
  const evaluateBaseBeeData = () => {
    const localCopy = { ...beeLocal };
    const selectedSector = localCopy.sectorId;
    const selectedAnnualTurnoverBand = localCopy.annualTurnover;
    const boPercentage = localCopy.boPercentage || 0;

    if (!selectedSector || !selectedAnnualTurnoverBand) return;

    processValidBeeLevels(selectedSector, selectedAnnualTurnoverBand, boPercentage);
  };

  // Determines if data has been modified since initial load
  const performDirtyCheck = (checkVal: BeeDetailsModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(beeDetailsComparer)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  // Fetches the turnover bands for the selected sector
  const getTurnoverBandsForSector = useCallback(async (sectorIdVal: number) => {
    try {
      setTurnoverLoader(true);

      if (!sectorIdVal) {
        setTurnoverBands([] as Array<TurnoverModel>);
        return;
      }

      const turnoverBandApiResults = await GetSectorTurnoverBands(sectorIdVal);
      setTurnoverBands(turnoverBandApiResults.data);
    } finally {
      setTurnoverLoader(false);
    }
  }, []);

  // Handles updating the main BEE model for basic captures
  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...beeLocal, [propName]: event.target.value };
    setBeeLocal(newVal);
    performDirtyCheck(newVal);
  };

  // Makes sure the percentage values in this component don't exceed upper or lower bounds.
  const percentageChecker = (value: string, min = 0, max = 100) => {
    if (+value < min) return min.toString();

    if (+value > max) return max.toString();

    return value.toString();
  };

  // Handles updating the main BEE model for numeric captures
  const handleNumericValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    let { value } = event.target;

    value = percentageChecker(value);

    if (value.startsWith("0") && value.length > 1) value = value.substring(1);

    if (!value) value = "0";

    let newVal;

    const decimalRegex = /^(\d+(\.\d*)?)?$/;
    if (decimalRegex.test(value)) {
      newVal = { ...beeLocal, [propName]: value };
      setBeeLocal(newVal);
      performDirtyCheck(newVal);
    }
  };

  // Handles updating the main BEE model for checkbox/boolean captures
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, propName: string) => {
    const newVal = { ...beeLocal, [propName]: event?.target.checked };
    setBeeLocal(newVal);
    performDirtyCheck(newVal);
  };

  // Handles updating the main BEE mdoel for Select/dropdown changes
  const handleSelectValueChange = (event: SelectChangeEvent<number>, propName: string) => {
    const newVal = { ...beeLocal, [propName]: event.target.value };
    setBeeLocal(newVal);
    performDirtyCheck(newVal);
  };

  // Handles the special snow-flake date change capture and updates
  const handleDateChange = (newDate: Dayjs | null, propName: string) => {
    setCertExpDate(newDate);

    const newValue = {
      ...beeLocal,
      [propName]: newDate?.toDate().toISOString() || new Date().toISOString(),
    };

    setBeeLocal(newValue);
    performDirtyCheck(newValue);
  };

  // Handles the logic when a user selects a BEE sector
  const handleBeeSectorChange = async (event: SelectChangeEvent<number>) => {
    setTurnoverLoader(true);
    if (event.target.value === "0") {
      setTurnoverLoader(false);
      return;
    }

    const sectorIdValue = +event.target.value;

    // We also reset the annual turnover band selection to 0
    const newValue = {
      ...beeLocal,
      sectorId: sectorIdValue,
      annualTurnover: 0,
      certificateLevelId: 0,
    };
    setBeeLocal(newValue);

    await getTurnoverBandsForSector(sectorIdValue);

    performDirtyCheck(newValue);
  };

  // Handles the logic when a user makes updates to the Black-Designated Group sub-percentages
  const handleBdgValueUpdate = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...beeLocal, [propName]: +event.target.value };
    setBeeLocal(newVal);

    const bdgUpdate = { ...bdgSubPercObj, [propName]: +event.target.value };
    setBdgSubPercObj(bdgUpdate);
    setBdgSubPercSum(sumObjectValues(bdgUpdate));

    performDirtyCheck(newVal);
  };

  // Handles the special snowflake logic for when a turnover band changes.
  const handleTurnoverChange = (event: React.MouseEvent | KeyboardEvent, newTurnover: number) => {
    const newValue = { ...beeLocal, annualTurnover: newTurnover };
    setBeeLocal(newValue);
    performDirtyCheck(newValue);
  };

  // Used to show or hide the BEE sections
  const ShowBeeSections = () => {
    setHasBeeDetails(!hasBeeDetails);
  };

  // A suite of quick if-statements to determine if the user has completed the BEE data correctly
  const beeSpecificValidationChecks = () => {
    // If verification certificate is selected as source, a verification agent must be selected.
    if (isWorkCentre && beeLocal.ratingSourceId && !beeLocal.verificationAgencyId)
      return { result: false, validation: "No verification agency selected" };

    if (beeLocal.bwoPercentage && beeLocal.boPercentage) {
      if (beeLocal.bwoPercentage > beeLocal.boPercentage) {
        return { result: false, validation: "Black Woman-Owned % cannot exceed Black-Owned %" };
      }
    }

    if (isWorkCentre && (beeLocal.boPercentage && beeLocal.boPercentage >= 51) && !beeLocal.isBlackOwned)
      return {
        result: false,
        validation:
          "A value is required for Is Black-Owned when Black-Owned % is equal to or greater than 51%",
      };

    if (isWorkCentre && beeLocal.bwoPercentage && beeLocal.bwoPercentage >= 51 && !beeLocal.isBlackWomanOwned)
      return {
        result: false,
        validation:
          "A value is required for Is Black Woman-Owned when Black Woman-Owned % is equal to or greater than 30%",
      };

    if (isWorkCentre && beeLocal.blackDesignatedGroup && (beeLocal.blackDesignatedGroup > beeLocal.boPercentage))
      return {
        result: false,
        validation: "Black Designated Group % must be equal to or lower than Black-Owned %",
      };

    if (isWorkCentre && beeLocal.blackDesignatedGroup && (bdgSubPercSum < beeLocal.blackDesignatedGroup))
      return {
        result: false,
        validation: "All BDG sub-percentages must equal or exceed the Black Designated Group %",
      };

    if (!beeLocal.certificateLevelId)
      return {
        result: false,
        validation: "Please capture a B-BBEE certificate level.",
      };

    if (
      !beeLocal.certificateExpiryDate ||
      new Date(beeLocal.certificateExpiryDate).setHours(0, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0)
    )
      return {
        result: false,
        validation: "Please capture a valid B-BBEE certificate expiry date.",
      };

    return { result: true, validation: "" };
  };

  const prepareObjectForSave = (objectToPrep: BeeDetailsModel) => {
    const preppedBeeModel: BeeDetailsModel = {
      id: objectToPrep.id,
      sectorId: objectToPrep.sectorId,
      annualTurnover: objectToPrep.annualTurnover,
      boPercentage: !objectToPrep.boPercentage ? 0 : Number(objectToPrep.boPercentage),
      bwoPercentage: !objectToPrep.bwoPercentage ? 0 : Number(objectToPrep.bwoPercentage),
      certificateLevelId: objectToPrep.certificateLevelId,
      certificateExpiryDate: objectToPrep.certificateExpiryDate,
      isDeleted: objectToPrep.isDeleted,
      companyId: objectToPrep.companyId,
      isCurrent: objectToPrep.isCurrent,
      validationStatus: objectToPrep.validationStatus,
      validationDateTime: objectToPrep.validationDateTime ? dayjs(objectToPrep.validationDateTime).toDate().toISOString() : null,
      hasBEECertificate: objectToPrep.hasBEECertificate,
      certificateDocId: objectToPrep.certificateDocId,
      ratingSourceId: objectToPrep.ratingSourceId,
      verificationAgencyId: objectToPrep.verificationAgencyId,
      vas: objectToPrep.vas,
      localServiceProvider: objectToPrep.localServiceProvider,
      localGoodsManufacturer: objectToPrep.localGoodsManufacturer,
      isBlackOwned: objectToPrep.isBlackOwned || 1, // Save as not specified by default
      isBlackWomanOwned: objectToPrep.isBlackWomanOwned || 1,
      isFlowThrough: objectToPrep.isFlowThrough,
      blackDesignatedGroup: !objectToPrep.blackDesignatedGroup ? 0 : objectToPrep.blackDesignatedGroup,
      blackYouth: !objectToPrep.blackYouth ? 0 : Number(objectToPrep.blackYouth),
      blackDisabled: !objectToPrep.blackDisabled ? 0 : Number(objectToPrep.blackDisabled),
      blackUnemployed: !objectToPrep.blackUnemployed ? 0 : Number(objectToPrep.blackUnemployed),
      blackRural: !objectToPrep.blackRural ? 0 : Number(objectToPrep.blackRural),
      blackMilitaryVet: !objectToPrep.blackMilitaryVet ? 0 : Number(objectToPrep.blackMilitaryVet),
      farmWorkers: !objectToPrep.farmWorkers ? 0 : Number(objectToPrep.farmWorkers),
      legacyBDG: objectToPrep.legacyBDG,
    }

    return preppedBeeModel;
  }

  // Enables editing and handles saving when the save Icon is clicked
  const EnableEdit = () => {
    if (!edit) {
      setEdit(true);
      return;
    }

    const beeSpecificValidation = beeSpecificValidationChecks();

    if (!beeSpecificValidation.result) {
      Swal.fire({
        title: "Please resolve all validation errors",
        text: beeSpecificValidation.validation,
        icon: "error",
        showCloseButton: true,
        allowEnterKey: true,
        allowEscapeKey: true,
        allowOutsideClick: false,
      });
      setEdit(true);
      return;
    }

    const preppedSaveModel = prepareObjectForSave(beeLocal);
    OnBeeSave(preppedSaveModel);
    setBeeDetailsComparer(preppedSaveModel);
    SetDirtyCallback(false);
    setEdit(false);
  };

  // Cancels an edit, and reverts the data to a snapshot taken when the component first loaded
  const CancelEdit = async () => {
    // setTurnoverBands([] as Array<TurnoverModel>);
    setEdit(false);
    setBeeLocal(beeDetailsComparer);

    SetDirtyCallback(false);
    // await getTurnoverBandsForSector(beeDetailsComparer.sectorId);
  };

  // Handles the BEE level dropdown's visibility based on if there are valid levels to load
  // as per the selected sector and turnover bands
  const processBeeLevelDropdownVisibility = () => {
    if (
      validBeeLevelMenuItems.length === 0 &&
      (!beeLocal.sectorId || !beeLocal.annualTurnover) &&
      !turnoverLoader &&
      !beeLevelLoader
    ) {
      return null;
    }

    if (
      validBeeLevelMenuItems.length === 0 &&
      beeLocal.sectorId > 0 &&
      beeLocal.annualTurnover > 0 &&
      !turnoverLoader &&
      !beeLevelLoader
    ) {
      return (
        <Typography variant="subtitle1" sx={{ color: "#ff0000" }}>
          No valid B-BBEE levels found. Please check your details, or contact support.
        </Typography>
      );
    }

    if (
      validBeeLevelMenuItems.length > 0 &&
      beeLocal.sectorId > 0 &&
      beeLocal.annualTurnover === 0 &&
      !turnoverLoader &&
      !beeLevelLoader
    ) {
      return (
        <Typography variant="subtitle1" sx={{ color: "#ff0000" }}>
          Please select a turnover band to load the valid B-BBEE levels.
        </Typography>
      );
    }

    return (
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <InputLabel id="bee-level-label">B-BBEE Level</InputLabel>
        {beeLevelLoader ? (
          <InLineSpinner />
        ) : (
          <Select
            sx={{
              display:
                beeLocal.sectorId &&
                  beeLocal.annualTurnover &&
                  (beeLocal.bwoPercentage || 0) <= (beeLocal.boPercentage || 0)
                  ? "flex"
                  : "none",
            }}
            label="B-BBEE Level"
            id="bee-level"
            margin="dense"
            displayEmpty
            inputProps={{ style: { fontSize: 14 } }}
            value={beeLocal?.certificateLevelId || 0}
            size="small"
            disabled={!edit}
            onChange={(ev) => {
              handleSelectValueChange(ev, "certificateLevelId");
            }}
            input={<OutlinedInput label="B-BBEE Level" />}
          >
            <MenuItem value={0}>
              <em>Please Select</em>
            </MenuItem>
            {validBeeLevelMenuItems.map((beeLevel) => (
              <MenuItem value={beeLevel.id}>{beeLevel.value}</MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    );
  };

  // Main loader function for the component
  const loaderFunction = useCallback(async () => {
    showLoadingBackdrop(true);
    try {
      if (!beeLevels || beeLevels.length === 0) dispatch(fetchBeeLevels());

      try {
        if (beeLocal.sectorId !== 0) {
          getTurnoverBandsForSector(beeLocal.sectorId);
        }

        evaluateBaseBeeData();
      } finally {
        if (turnoverLoader) setTurnoverLoader(false);
      }

      if (beeData?.localServiceProvider || beeData?.localGoodsManufacturer)
        setMiningEntityToggle(true);

      if (
        !beeRatingStatuses ||
        beeRatingStatuses.length === 0 ||
        !beeRatingSources ||
        beeRatingSources.length === 0 ||
        !beeRatingVerficationAgents ||
        beeRatingVerficationAgents.length === 0
      )
        dispatch(fetchAllBeeRatingLookupLists());

      const bdgValsFromMainObj = extractBdgValsFromMainObject(beeData);
      setBdgSubPercObj(bdgValsFromMainObj);
      setBdgSubPercSum(sumObjectValues(bdgValsFromMainObj));

      const documentData: ReadRepDocumentDto = await getCompanyDocumentByTypeIdAsync(
        beeData.companyId || baseCompanyInfo.companyId,
        DocumentType.BBBEECertificate
      );
      setDocData(documentData);
    } finally {
      showLoadingBackdrop(false);
    }
  }, []);

  const processRatingSource = () => {
    const sourceArray = [...beeRatingSources];
    const currentTurnoverBand: TurnoverModel = turnoverBands.find((tob) => tob.id === beeLocal.annualTurnover) || {} as TurnoverModel;

    // If Transport Sector
    if (beeLocal.sectorId === transportSectorId) {

      // And EME:
      if (currentTurnoverBand.beeClassificationName === beeClassificationToString(BeeClassification.eme)) {
        const results = sourceArray.filter((source) => source.id === BeeRatingSource.auditorAccountingLetter).map((item) => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ));

        return results;
      }

      if (currentTurnoverBand.beeClassificationName === beeClassificationToString(BeeClassification.qse)) {
        const results = sourceArray.filter((source) => source.id !== BeeRatingSource.affidavit).map((item) => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ));

        return results;
      }
    }

    // If Generic: Then ONLY Certificate!
    if (currentTurnoverBand.beeClassificationName === beeClassificationToString(BeeClassification.generic)) {
      const results = sourceArray.filter((source) => source.id === BeeRatingSource.verificationCertificate).map((item) => (
        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
      ));

      return results;
    }

    // If QSE and >= 51% BO:
    if (currentTurnoverBand.beeClassificationName === beeClassificationToString(BeeClassification.qse) && beeLocal.boPercentage >= 51.0) {
      const results = sourceArray.filter((source) => source.id !== BeeRatingSource.auditorAccountingLetter).map((item) => (
        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
      ));

      return results;
    }

    // Otherwise, if QSE and < 51%, remove affidavit
    if (currentTurnoverBand.beeClassificationName === beeClassificationToString(BeeClassification.qse) && beeLocal.boPercentage < 51.0) {
      const results = sourceArray
        .filter(
          (source) =>
            source.id !== BeeRatingSource.affidavit &&
            source.id !== BeeRatingSource.auditorAccountingLetter
        )
        .map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ));

      return results;
    }

    // Get everything else.
    const results = sourceArray.filter((source) => source.id !== BeeRatingSource.auditorAccountingLetter).map((item) => (
      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
    ));

    return results;
  };

  // "Main" useEffect
  useEffect(() => {
    if (initialLoadCheck.current) {
      setBeeDetailsComparer(JSON.parse(JSON.stringify(beeData)));
      loaderFunction();

      initialLoadCheck.current = false;
    }

    if (beeData?.companyId !== undefined) setHasBeeDetails(true);
  }, [beeData?.id]);

  // useEffect for handling sector, turnover, and Black-Owned percentage changes, which influence
  // the BEE level's automatic filtering
  useEffect(() => {
    evaluateBaseBeeData();
  }, [beeLocal.sectorId, beeLocal.annualTurnover]);

  // useEffect for debounced updates to the main Company object in the parent component.
  useEffect(() => {
    dispatch(setCompanyBeeDetailsState(debouncedBeeValue));
  }, [debouncedBeeValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {!countryCheck ? (
        <Grid container item sx={{ width: "100%", paddingBottom: "10px" }} justifyContent="center">
          <Typography fontWeight={700} variant="h3">
            This feature is not available in your country.
          </Typography>
        </Grid>
      ) : (
        <>
          {isWorkCentre && (
            <WithRequiredPermission hideIfMissingPermission permission={PermissionClassification.AccessPlatformConfiguration}>
              <CompanyBeeRatingStatus setBeeDisabled={setIsBeeDisabled} />
            </WithRequiredPermission>
          )}

          {(!isWorkCentre || (isWorkCentre && isBeeDisabled !== undefined && isBeeDisabled.toString() !== "true")) && (
            <Accordion expanded className="Accord-Hide">
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="Accord-heading">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3 className="heading-sub-3">B-BBEE Details</h3>
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="step-B">
                <Box className={styles.postBody}>
                  <Box className={styles.postBody}>
                    <Box className={styles.Info} sx={{ width: "100%", float: "left" }}>
                      <Grid container>
                        <Grid xs={12}>
                          <Typography variant="h6" color="black">
                            <Switch
                              id="beeCertSwitch"
                              sx={{ marginTop: "-5px" }}
                              onChange={ShowBeeSections}
                              checked={hasBeeDetails} />
                            <span>Does your company have a B-BBEE Certificate/Affidavit?</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                {hasBeeDetails && (
                  <>
                    <Box className={styles.editSave} sx={{ width: "100%", float: "right" }}>
                      <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
                        {!edit && (
                          <Tooltip title="Edit">
                            <FontAwesomeIcon
                              icon={faPencilSquare}
                              aria-hidden="true"
                              id="edit-general-details"
                              onClick={EnableEdit}
                              className="hover-cursor" />
                          </Tooltip>
                        )}
                        {edit && (
                          <Box className={styles.btnBox}>
                            <Box className={styles.btnBoxLeft}>
                              <Tooltip title="Save">
                                <FontAwesomeIcon
                                  icon={faFloppyDisk}
                                  aria-hidden="true"
                                  id="edit-save-general"
                                  onClick={EnableEdit}
                                  className="hover-cursor" />
                              </Tooltip>
                            </Box>
                            <Box className={styles.btnBoxRight}>
                              <Tooltip title="Cancel Edit">
                                <FontAwesomeIcon
                                  icon={faCancel}
                                  aria-hidden="true"
                                  id="edit-cancel-general"
                                  onClick={CancelEdit}
                                  className="hover-cursor" />
                              </Tooltip>
                            </Box>
                          </Box>
                        )}
                      </WithRequiredPermission>
                    </Box>

                    <Grid
                      id="mainContainer"
                      container
                      xs={12}
                      spacing={5}
                      sx={{ marginBottom: "10px" }}>
                      <Grid id="beeCertExpIndicator" container item spacing={2} alignItems="center">
                        <Grid item xs={1}>
                          <Typography>Certificate Status:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          {getCertExpiryStatus(beeLocal.certificateExpiryDate)}
                        </Grid>
                      </Grid>

                      <Grid id="beeSectorControlGrid" container item spacing={2}>
                        <Grid item container xs={6} alignContent="space-evenly">
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="bee-sector-label">B-BBEE Sector</InputLabel>
                            <Select
                              label="B-BBEE Sector"
                              id="bee-sector"
                              margin="dense"
                              displayEmpty
                              inputProps={{ style: { fontSize: 14 } }}
                              value={beeLocal?.sectorId || 0}
                              size="small"
                              disabled={!edit}
                              onChange={(ev) => {
                                ev.preventDefault();
                                handleBeeSectorChange(ev);
                              }}
                              input={<OutlinedInput label="B-BBEE Sector" />}
                            >
                              <MenuItem value={0}>
                                <em>Please Select</em>
                              </MenuItem>
                              {sectorList.map((sector) => (
                                <MenuItem value={sector.id}>{sector.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          container
                          flexDirection="column"
                          xs={6}
                          sx={{ display: !beeLocal.sectorId ? "none" : "flex" }}>
                          {turnoverLoader ? (
                            <>
                              <InputLabel id="annualTurnoverLabel">Annual Turnover:</InputLabel>
                              <InLineSpinner />
                            </>
                          ) : (
                            <>
                              <InputLabel id="annualTurnoverLabel">Annual Turnover:</InputLabel>
                              <ToggleButtonGroup
                                id="annualTurnoverButtons"
                                value={beeLocal.annualTurnover || 0}
                                fullWidth
                                exclusive
                                sx={{ width: "100%" }}
                                onChange={handleTurnoverChange}
                                aria-label="text turnover"
                                size="small"
                                disabled={!edit}
                              >
                                {turnoverBands.map((band) => (
                                  <ToggleButton
                                    value={band.id}
                                    aria-label={band.beeClassificationName}
                                  >
                                    <div>
                                      <p>{band.beeClassificationName}</p>
                                      <p>{band.name}</p>
                                    </div>
                                  </ToggleButton>
                                ))}
                              </ToggleButtonGroup>
                            </>
                          )}
                        </Grid>
                      </Grid>

                      <Grid id="beeBOControlGrid" container item spacing={2} flexDirection="row">
                        <Grid id="boPercGrid" item container xs={6} spacing={2}>
                          <Grid item container xs={12}>
                            <TextField
                              type="text"
                              fullWidth
                              size="small"
                              disabled={!edit}
                              inputProps={{
                                pattern: "[0-9]*.?[0-9]*",
                                inputMode: "decimal",
                                maxLength: 6,
                              }}
                              label="Black-Owned Percentage"
                              value={beeLocal?.boPercentage || 0}
                              error={beeLocal?.boPercentage < 0.0 || beeLocal?.boPercentage > 100.0}
                              helperText={
                                (beeLocal?.boPercentage < 0.0 &&
                                  "Value must be greater than or equal to 0.") ||
                                (beeLocal?.boPercentage > 100.0 &&
                                  "Value must be less than or equal to 100.")
                              }
                              onChange={(ev) => {
                                handleNumericValueChange(ev, "boPercentage");
                              }}
                              onBlur={() => {
                                evaluateBaseBeeData();
                              }}
                            />
                          </Grid>
                          <Grid item container xs={12}>
                            <TextField
                              type="text"
                              inputProps={{
                                pattern: "[0-9]{3}.?[0-9]{2}",
                                inputMode: "decimal",
                                maxLength: 6,
                              }}
                              fullWidth
                              size="small"
                              disabled={!edit}
                              label="Black Women Owned Percentage"
                              value={beeLocal?.bwoPercentage || 0}
                              error={
                                beeLocal?.bwoPercentage < 0.0 ||
                                beeLocal?.bwoPercentage > 100 ||
                                beeLocal?.bwoPercentage > beeLocal?.boPercentage
                              }
                              helperText={
                                (beeLocal?.bwoPercentage < 0.0 &&
                                  "Value must be greater than or equal to 0.") ||
                                (beeLocal?.bwoPercentage > 100 &&
                                  "Value must be less than or equal to 100.") ||
                                (beeLocal?.bwoPercentage > beeLocal?.boPercentage &&
                                  "Black Women Owned Percentage cannot exceed Black Owned Percentage.")
                              }
                              onChange={(ev) => {
                                handleNumericValueChange(ev, "bwoPercentage");
                              }}
                            />
                          </Grid>
                        </Grid>

                        {hasPermission(PermissionClassification.ViewPlatformCompanyData) && (
                          <Grid id="isBoGrid" item container xs={3} spacing={2}>
                            {beeLocal?.boPercentage >= 51.0 && (
                              <Grid item container xs={12}>
                                <TextField
                                  select
                                  label="51% Black-Owned?"
                                  id="bee-isBo"
                                  margin="dense"
                                  sx={{ width: "100%", marginTop: 0 }}
                                  value={beeLocal?.isBlackOwned || 0}
                                  size="small"
                                  disabled={!edit}
                                  error={
                                    edit &&
                                    beeLocal?.boPercentage >= 51.0 &&
                                    !beeLocal?.isBlackOwned
                                  }
                                  helperText={
                                    edit &&
                                    beeLocal?.boPercentage >= 51.0 &&
                                    !beeLocal?.isBlackOwned &&
                                    "Please select a value"
                                  }
                                  onChange={(ev) => {
                                    ev.preventDefault();
                                    handleValueChange(ev, "isBlackOwned");
                                  }}
                                >
                                  <MenuItem value={0}>
                                    <em>Please Select</em>
                                  </MenuItem>
                                  <MenuItem value={1}>Not Specified</MenuItem>
                                  <MenuItem value={2}>Yes</MenuItem>
                                  <MenuItem value={3}>No</MenuItem>
                                </TextField>
                              </Grid>
                            )}
                            {beeLocal?.bwoPercentage >= 30.0 && beeLocal?.bwoPercentage <= beeLocal?.boPercentage && (
                              <Grid item container xs={12}>
                                <TextField
                                  select
                                  label="30% Black Woman-Owned?"
                                  id="bee-isBwo"
                                  margin="dense"
                                  inputProps={{ style: { fontSize: 14 } }}
                                  sx={{ width: "100%", marginTop: 0 }}
                                  value={beeLocal?.isBlackWomanOwned || 0}
                                  size="small"
                                  disabled={!edit}
                                  error={
                                    edit &&
                                    beeLocal?.bwoPercentage >= 30.0 &&
                                    !beeLocal?.isBlackWomanOwned
                                  }
                                  helperText={
                                    edit &&
                                    beeLocal?.boPercentage >= 30.0 &&
                                    !beeLocal?.isBlackWomanOwned &&
                                    "Please select a value"
                                  }
                                  onChange={(ev) => {
                                    ev.preventDefault();
                                    handleValueChange(ev, "isBlackWomanOwned");
                                  }}
                                >
                                  <MenuItem value={0}>
                                    <em>Please Select</em>
                                  </MenuItem>
                                  <MenuItem value={1}>Not Specified</MenuItem>
                                  <MenuItem value={2}>Yes</MenuItem>
                                  <MenuItem value={3}>No</MenuItem>
                                </TextField>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>

                      {hasPermission(PermissionClassification.ViewPlatformCompanyData) && (
                        <Grid id="bdgGrid" container item spacing={2}>
                          <Grid item container flexDirection="row" spacing={2}>
                            {beeLocal?.boPercentage > 0 && (
                              <>
                                <Grid item container xs={6}>
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black Designated Group %"
                                    value={beeLocal?.blackDesignatedGroup || 0}
                                    error={
                                      (beeLocal?.blackDesignatedGroup || 0.0) < 0.0 ||
                                      (beeLocal?.blackDesignatedGroup || 0.0) >
                                      beeLocal?.boPercentage
                                    }
                                    helperText={
                                      ((beeLocal?.blackDesignatedGroup || 0.0) < 0.0 &&
                                        "Value must be greater than or equal to 0.") ||
                                      ((beeLocal?.blackDesignatedGroup || 0.0) >
                                        beeLocal?.boPercentage &&
                                        "Value must be less than or equal to Black-Owned Percentage.")
                                    }
                                    onChange={(ev) => {
                                      handleNumericValueChange(ev, "blackDesignatedGroup");
                                    }}
                                  />
                                </Grid>

                                <Grid item container xs={6}>
                                  <Tooltip title="Does this company follow the Flow Through ownership principal?">
                                    <FormControlLabel
                                      label="Flow Through Ownership?"
                                      control={
                                        <Checkbox
                                          checked={beeLocal?.isFlowThrough || false}
                                          disabled={!edit}
                                          onChange={(ev) => {
                                            handleCheckboxChange(ev, "isFlowThrough");
                                          }}
                                        />
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          {(beeLocal?.blackDesignatedGroup || 0) > 0 && (
                            <Grid item container xs={6} spacing={2} justifyContent="flex-end">
                              <Grid item container xs={11} flexDirection="column" spacing={2}>
                                <Grid item id="blackYouthGrid">
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black Youth %"
                                    value={beeLocal?.blackYouth || 0}
                                    error={
                                      (beeLocal?.blackYouth || 0.0) < 0.0 ||
                                      (bdgSubPercSum || 0.0) <
                                      (beeLocal?.blackDesignatedGroup || 0.0)
                                    }
                                    helperText={
                                      (beeLocal?.blackYouth || 0.0) < 0.0 &&
                                      "Value must be greater than or equal to 0."
                                    }
                                    onChange={(ev) => {
                                      handleBdgValueUpdate(ev, "blackYouth");
                                    }}
                                  />
                                </Grid>
                                <Grid item id="blackDisabledGrid">
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black Disabled %"
                                    value={beeLocal?.blackDisabled || 0}
                                    error={
                                      (beeLocal?.blackDisabled || 0.0) < 0.0 ||
                                      (bdgSubPercSum || 0.0) <
                                      (beeLocal?.blackDesignatedGroup || 0.0)
                                    }
                                    helperText={
                                      (beeLocal?.blackDisabled || 0.0) < 0.0 &&
                                      "Value must be greater than or equal to 0."
                                    }
                                    onChange={(ev) => {
                                      handleBdgValueUpdate(ev, "blackDisabled");
                                    }}
                                  />
                                </Grid>
                                <Grid item id="blackUnemployedGrid">
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black Unemployed %"
                                    value={beeLocal?.blackUnemployed || 0}
                                    error={
                                      (beeLocal?.blackUnemployed || 0.0) < 0.0 ||
                                      (bdgSubPercSum || 0.0) <
                                      (beeLocal?.blackDesignatedGroup || 0.0)
                                    }
                                    helperText={
                                      (beeLocal?.blackUnemployed || 0.0) < 0.0 &&
                                      "Value must be greater than or equal to 0."
                                    }
                                    onChange={(ev) => {
                                      handleBdgValueUpdate(ev, "blackUnemployed");
                                    }}
                                  />
                                </Grid>
                                <Grid item id="blackRuralGrid">
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black People living in Rural Areas %"
                                    value={beeLocal?.blackRural || 0}
                                    error={
                                      (beeLocal?.blackRural || 0.0) < 0.0 ||
                                      (bdgSubPercSum || 0.0) <
                                      (beeLocal?.blackDesignatedGroup || 0.0)
                                    }
                                    helperText={
                                      (beeLocal?.blackRural || 0.0) < 0.0 &&
                                      "Value must be greater than or equal to 0."
                                    }
                                    onChange={(ev) => {
                                      handleBdgValueUpdate(ev, "blackRural");
                                    }}
                                  />
                                </Grid>
                                <Grid item id="blackMilitaryVetGrid">
                                  <TextField
                                    type="text"
                                    inputProps={{
                                      pattern: "[0-9]{3}.?[0-9]{2}",
                                      inputMode: "decimal",
                                      maxLength: 6,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled={!edit}
                                    label="Black Military Veterans %"
                                    value={beeLocal?.blackMilitaryVet || 0}
                                    error={
                                      (beeLocal?.blackMilitaryVet || 0.0) < 0.0 ||
                                      (bdgSubPercSum || 0.0) <
                                      (beeLocal?.blackDesignatedGroup || 0.0)
                                    }
                                    helperText={
                                      (beeLocal?.blackMilitaryVet || 0.0) < 0.0 &&
                                      "Value must be greater than or equal to 0."
                                    }
                                    onChange={(ev) => {
                                      handleBdgValueUpdate(ev, "blackMilitaryVet");
                                    }}
                                  />
                                </Grid>
                                {beeLocal?.sectorId === agriSectorId && (
                                  <Grid item id="blackFarmWorkers">
                                    <TextField
                                      type="text"
                                      inputProps={{
                                        pattern: "[0-9]{3}.?[0-9]{2}",
                                        inputMode: "decimal",
                                        maxLength: 6,
                                      }}
                                      fullWidth
                                      size="small"
                                      disabled={!edit}
                                      label="Black Farm Workers %"
                                      value={beeLocal?.farmWorkers || 0}
                                      error={
                                        (beeLocal?.farmWorkers || 0.0) < 0.0 ||
                                        (bdgSubPercSum || 0.0) <
                                        (beeLocal?.blackDesignatedGroup || 0.0)
                                      }
                                      helperText={
                                        (beeLocal?.farmWorkers || 0.0) < 0.0 &&
                                        "Value must be greater than or equal to 0."
                                      }
                                      onChange={(ev) => {
                                        handleBdgValueUpdate(ev, "farmWorkers");
                                      }}
                                    />
                                  </Grid>
                                )}
                                {(bdgSubPercSum || 0.0) < (beeLocal?.blackDesignatedGroup || 0.0) && (
                                  <Grid item id="bdgSubPercValidationLabel">
                                    <Typography variant="subtitle2" color="#ff0000">
                                      Total Sub-categories percentage is less than the BDG%
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}

                      {hasPermission(PermissionClassification.ViewPlatformCompanyData) && (
                        <Grid id="beeRatingMetadataGrid" container item spacing={2}>
                          <Grid item container xs={12} spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                select
                                id="bee-rating-source"
                                sx={{ width: "100%", marginTop: 0 }}
                                label="B-BBEE Rating Source"
                                inputProps={{ style: { fontSize: 14 } }}
                                value={beeLocal?.ratingSourceId || 0}
                                size="small"
                                disabled={!edit}
                                onChange={(ev) => {
                                  ev.preventDefault();
                                  handleValueChange(ev, "ratingSourceId");
                                }}
                              >
                                <MenuItem value={0}>
                                  <em>Please Select</em>
                                </MenuItem>
                                {processRatingSource()}
                                {/* {beeRatingSources.map((source) => (
                                  <MenuItem value={source.id}>{source.name}</MenuItem>
                                ))} */}
                              </TextField>
                            </Grid>
                            {beeLocal?.ratingSourceId === verificationAgentSourceId && (
                              <Grid item xs={6}>
                                <TextField
                                  select
                                  label="B-BBEE Verification Agency"
                                  id="bee-verification-agency"
                                  sx={{ width: "100%", marginTop: 0 }}
                                  inputProps={{ style: { fontSize: 14 } }}
                                  value={beeLocal?.verificationAgencyId || 0}
                                  size="small"
                                  disabled={!edit}
                                  error={
                                    edit &&
                                    beeLocal?.ratingSourceId > 0 &&
                                    !beeLocal?.verificationAgencyId
                                  }
                                  helperText={
                                    edit &&
                                    beeLocal?.ratingSourceId > 0 &&
                                    !beeLocal?.verificationAgencyId &&
                                    "Please select a value"
                                  }
                                  onChange={(ev) => {
                                    ev.preventDefault();
                                    handleValueChange(ev, "verificationAgencyId");
                                  }}
                                >
                                  <MenuItem value={0}>
                                    <em>Please Select</em>
                                  </MenuItem>
                                  {beeRatingVerficationAgents.map((va) => (
                                    <MenuItem value={va.id}>{va.name}</MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      <Grid id="certificateDownloadGrid" container item spacing={2}>
                        {docData && Object.keys(docData).length > 0 ? (
                          <Grid item container xs={6}>
                            <IconButton
                              onClick={(ev) => {
                                handleCertDocClick(ev, docData);
                              }}
                            >
                              <Box>
                                <Typography variant="body1" color="#1976d2">
                                  View most recent certificate:
                                </Typography>
                              </Box>
                              <PictureAsPdfIcon />
                            </IconButton>
                          </Grid>
                        ) : (
                          <Grid item container xs={6}>
                            <IconButton
                              onClick={() => {
                                Swal.fire(
                                  "B-BBEE document not available",
                                  "A B-BBEE document is not available",
                                  "warning"
                                );
                              }}
                            >
                              <Box>
                                <Typography variant="body1" color="#ff0000">
                                  B-BBEE document not available
                                </Typography>
                              </Box>
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>

                      <Grid id="beeCertDetailsControlGrid" container item spacing={2}>
                        <Grid item container xs={6}>
                          {processBeeLevelDropdownVisibility()}
                        </Grid>
                        <Grid item container flexDirection="column" xs={6}>
                          <DesktopDatePicker
                            label="Certificate Expiry Date"
                            value={certExpDate}
                            onChange={(ev) => {
                              handleDateChange(ev, "certificateExpiryDate");
                            }}
                            disabled={!edit}
                            maxDate={maxAllowedCertExpiryDate}
                            renderInput={(params) => (
                              <TextField {...params} size="small" disabled={!edit} />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box className={styles.postBeeGrouping}>
                      <CompanyBEEGrouping edit={edit} label="B-BBEE Company Grouping" />
                    </Box>

                    {isWorkCentre && (hasPermission(PermissionClassification.ViewPlatformCompanyData)) && (
                      <Grid id="localServicesGrid" item container spacing={2} sx={{ marginTop: "1rem" }}>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Switch
                            id="localServicesSwitch"
                            disabled={!edit}
                            checked={miningEntityToggle}
                            onChange={handleMiningToggleChange}
                          />
                          <Typography variant="h6" color="#000">
                            Are services/goods provided to a mining entity?
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          flexDirection="column"
                          sx={{ display: miningEntityToggle ? "flex" : "none" }}
                        >
                          <Tooltip
                            title={
                              "Local Services refers to a company providing services that " +
                              "is incorporated and registered in terms of the Companies Act (Act 71 of 2008), " +
                              "with operations in the Country and subject to South African laws."
                            }
                          >
                            <FormControlLabel
                              label="Local Service Provider?"
                              control={
                                <Checkbox
                                  checked={beeLocal?.localServiceProvider || false}
                                  disabled={!edit}
                                  onChange={(ev) => {
                                    handleCheckboxChange(ev, "localServiceProvider");
                                  }}
                                />
                              }
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              "Local Goods refers to goods manufactured with a minimum of 60% Local Content" +
                              " during the assembly or manufacturing of the product in South Africa."
                            }
                          >
                            <FormControlLabel
                              label="Local Goods Manufacturer?"
                              control={
                                <Checkbox
                                  checked={beeLocal?.localGoodsManufacturer || false}
                                  disabled={!edit}
                                  onChange={(ev) => {
                                    handleCheckboxChange(ev, "localGoodsManufacturer");
                                  }}
                                />
                              }
                            />
                          </Tooltip>
                          {beeLocal?.sectorId === transportSectorId && (
                            <FormControlLabel
                              label="Value Adding Supplier?"
                              control={
                                <Checkbox
                                  checked={beeLocal?.vas || false}
                                  disabled={!edit}
                                  onChange={(ev) => {
                                    handleCheckboxChange(ev, "vas");
                                  }}
                                />
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </LocalizationProvider>
  );
};

export default CompanyBEEDetails;
