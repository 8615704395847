/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ExclusionReasonModel } from "../../Company/Models/ExclusionReasonModel";

export interface ExclusionReasonListState {
  exclusionReasonList: Array<ExclusionReasonModel>;
}

const exclusionReasonListSlice = createSlice({
  name: "exclusionReasonList",
  initialState: [] as Array<ExclusionReasonModel>,
  reducers: {
    setExclusionReasonListState: (
      state,
      action: PayloadAction<Array<ExclusionReasonModel>>
    ) => action.payload,
  },
});

export const selectExclusionReasonList = (state: RootState) => state.exclusionReasonList;
export default exclusionReasonListSlice;
