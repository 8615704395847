import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { AddCompanyRatingMessage } from "./Messages/AddCompanyRatingMessage";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export const AddCompanyRatingAsync = async (
  message : AddCompanyRatingMessage
) => {
  const url = `${baseUrl}/api/Company/CompanyRatings`;
  const result = await makePostRequestAsync<void, AddCompanyRatingMessage>(
    apiScopes,
    url,
    message
  );
  return result.data;
};

export const GetCompanyRatingAsync = async (companyId: number) => {
  const url = `${baseUrl}/api/Company/CompanyRatings/${companyId}`;
  const result = await makeGetRequestAsync<number>(apiScopes,url);
  return result.data;
};