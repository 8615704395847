/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DocumentTypeModel } from "../Models/DocumentTypeModel";


export interface DocumentTypeListState {
  documentTypeList: Array<DocumentTypeModel>;
}

const documentTypeListSlice = createSlice({
  name: "documentTypeList",
  initialState: [] as Array<DocumentTypeModel>,
  reducers: {
    setDocumentTypeListState: (
      state,
      action: PayloadAction<Array<DocumentTypeModel>>
    ) => action.payload,
  },
});

export const selectDocumentTypeList = (state: RootState) => state.documentTypeList as Array<DocumentTypeModel>;
export default documentTypeListSlice;