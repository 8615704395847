/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompanyNetworkSearchResults } from "../../NetworkAndConnect/Models/CompanyNetworkSearchResults";
import { SearchModel } from "../../NetworkAndConnect/Models/SearchModel";

export interface CompanyNetworkSearchResultsState {
  companyNetworkSearchResults: CompanyNetworkSearchResults
}

const networkSearchSlice = createSlice({
  name: "companyNetworkSearchResults",
  initialState: {} as CompanyNetworkSearchResults,
  reducers: {
    setCompanyNetworkSearchResults(state, action: PayloadAction<CompanyNetworkSearchResults>) {
      // eslint-disable-next-line no-param-reassign
      state.searchResults = action.payload.searchResults;

      // eslint-disable-next-line no-param-reassign
      state.followedCompanies = action.payload.followedCompanies;

      // eslint-disable-next-line no-param-reassign
      state.myFollowers = action.payload.myFollowers;
    },
    addCompanyNetworkSearchResult(state, action: PayloadAction<SearchModel>) {
      state.searchResults.push(action.payload);
    },
    addCompanyFollowedConnection(state, action: PayloadAction<number>) {
      state.followedCompanies.push(action.payload);
    },
    addCompanyFollowerConnection(state, action: PayloadAction<number>) {
      state.myFollowers.push(action.payload);
    },
    removeCompanyClientNetworkSearchResult(state, action: PayloadAction<number>) {
      const results: Array<SearchModel> = state.searchResults;

      if (results)
        state.searchResults = state.searchResults.filter((searchResult) => searchResult.companyId !== action.payload);
    },
    removeCompanyFollowedNetworkConnection(state, action: PayloadAction<number>) {
      const followed = state.followedCompanies;

      if (followed)
        state.followedCompanies = state.followedCompanies.filter((connection) => connection !== action.payload);
    },
    removeCompanyFollowerNetworkConnection(state, action: PayloadAction<number>) {
      const follower = state.myFollowers;

      if (follower)
        state.myFollowers = state.myFollowers.filter((connection) => connection !== action.payload);
    },
  },
})

export default networkSearchSlice;
export const getCompanyNetworkSearchResults = (state: RootState) => state.networkSearch.searchResults;
export const getNetworkSearchMyFollowers = (state: RootState) => state.networkSearch.myFollowers;
export const getNetworkSearchCompaniesImFollowing = (state: RootState) => state.networkSearch.followedCompanies;
