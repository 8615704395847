import { Grid, FormControlLabel, Checkbox, TextField } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import debounce from "lodash.debounce";
import FuzzySearch from "../../util/fuzzySearch"
import FormDialog from "../Dialogs/FormDialog"

export interface MultiItemSelectorOption {
  id: number,
  displayValue: string,
}

interface MultiItemSelectorProps {
  currentSelectionArray: number[],
  optionsList: MultiItemSelectorOption[],
  title: string,
  description: string,
  subDescription?: string,// eslint-disable-line react/require-default-props
  hasSearchInput?: boolean// eslint-disable-line react/require-default-props
  handleClose: () => void,
  onSubmitSelectionCallback: (selectionList: number[]) => void;// eslint-disable-line no-unused-vars
}

const MultiItemSelector = ({
  currentSelectionArray,
  optionsList,
  handleClose,
  title,
  description,
  subDescription,
  hasSearchInput = false,
  onSubmitSelectionCallback,
}: MultiItemSelectorProps) => {

  const [internalSelection, setInternalSelection] = useState<number[]>(currentSelectionArray)
  const [searchResults, setSearchResults] = useState<MultiItemSelectorOption[]>([])

  const handleOnItemChange = (checked: boolean, currentItemId: number) => {
    checked ?
      setInternalSelection(prevState => [...prevState, currentItemId])
      :
      setInternalSelection(prevState => prevState.filter((x) => x !== currentItemId));
  }

  const handleOnSearchTextChange = (searchText: string) => {
    const results = FuzzySearch<MultiItemSelectorOption>(
      {
        list: optionsList,
        searchText,
        keys: ["displayValue"],
      })

    setSearchResults(results)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResults = useMemo(() => debounce(handleOnSearchTextChange, 300), []);

  useEffect(() => () => {
    debouncedResults.cancel();
  });
  const renderCheckList = () => ((searchResults.length > 0 ? searchResults : optionsList).map((item) => (
    <Grid item xs={12} key={item.id}>
      <FormControlLabel
        key={item.id}
        control={
          <Checkbox key={item.id}
            checked={internalSelection.includes(item.id)}
            onChange={(ev) => handleOnItemChange(ev.target.checked, item.id)}
          />
        }
        label={item.displayValue}
      />
    </Grid>
  )));

  return (
    <FormDialog
      submitButtonText="Submit"
      closeButtonText="Discard Changes"
      handleClose={handleClose}
      handleSubmit={() => {
        onSubmitSelectionCallback(internalSelection);
        handleClose();
      }}
      title={title}
      description={description}
      subDescription={subDescription}
    >
      <div>
        <Grid item container xs={12}>
          <>
            {hasSearchInput &&
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(ev) => debouncedResults(ev.target.value)}
                sx={{ width: "100%", marginBottom: "10px" }}
              />
            }
            {renderCheckList()}
          </>
        </Grid>
      </div>
    </FormDialog>
  )
}

export default MultiItemSelector;