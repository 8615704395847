import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/Search";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import { toast } from "react-toastify";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router";
import CheckCircleIcon from "@mui/icons-material/Check";
import SweetAlert2 from "react-sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ManagedCompaniesModel } from "../../../http/Redux/Models/ManagedCompaniesModel";
import { selectBaseCompanyInfo } from "../../../http/Redux/Store/baseCompanyInfoSlice";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import LiquidGraph from "./LiquidGraph";
import { CompaniesRequiringVerificationModel } from "../../../http/Redux/Models/CompaniesRequiringVerificationModel";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { BaseCompanyInfo } from "../../../http/Company/Models/BaseCompanyInfo";
import {
  AgentTaskStatusTypes,
  AgentTaskStatusTypesToString,
} from "../../../util/enums/AgentTaskStatusTypes";
import DataSourceTypes, { DataSourceTypesToString } from "../../../util/enums/DataSourceTypes";

import SupervisorClaimMgmt from "../AgentSupervisor/SupervisorClaimMgmt";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import { useHasAnyAllowedPermissions } from "../../../util/hooks/useAllowedPermission";
import formatDate from "../../../util/formatter/formatDate";
import { fetchCompanyWorkCentreHolder } from "../../../http/Redux/Store/CompanyWorkCentreHolderActions";
import { getSetCompanyHolder } from "../../../http/Redux/Store/CompanyWorkCentreHolderSlice";
import ClaimActions from "./ClaimActions";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import UserProfileService from "../../../http/UserProfileService";
import { UserMessage } from "../../../http/CompanyUserManagement/Messages/UserMessage";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";

const DetailPanelContent = ({ row: rowProp }: { row: CompaniesRequiringVerificationModel }) => (
  <Stack
    sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "rgba(0, 0, 0, 0.116)" }}
    direction="column"
  >
    <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
      <Stack direction="column" spacing={1} sx={{ height: 1 }}>
        <Grid container>
          <Grid xs={12}>
            <h3>Summary of Company Details:</h3>
          </Grid>
          <div className="hr-line" />
          <Grid xs={2}>Tax Clearance Number:</Grid>
          <Grid xs={2}>
            {rowProp.taxClearanceNumber ? (
              rowProp.taxClearanceNumber
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2}>Tax Expiry Date:</Grid>
          <Grid xs={2}>
            {rowProp.certificateExpiryDate ? (
              formatDate(rowProp.certificateExpiryDate)
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2}>Vat:</Grid>
          <Grid xs={2}>
            {rowProp.vatNumber ? (
              rowProp.vatNumber
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2}>BO%:</Grid>
          <Grid xs={2}>
            {rowProp.bOPercentage ? (
              rowProp.bOPercentage
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2}>BWO%:</Grid>
          <Grid xs={2}>
            {rowProp.bWOPercentage ? (
              rowProp.bWOPercentage
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2}>Annual TurnOver:</Grid>
          <Grid xs={2}>
            {rowProp.annualTurnover ? (
              rowProp.annualTurnover
            ) : (
              <Tooltip placement="right" title="no entry set">
                <NearbyErrorIcon className="cl-red" />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  </Stack>
);

interface ViewCellProps {
  row: CompaniesRequiringVerificationModel;
  stage: boolean;
  // eslint-disable-next-line no-unused-vars
  setClaimed: (claim: boolean) => void;
  selectedClientId: number;
  allTasks: AgentTaskListGridModel[];
  users: UserMessage[];
}

const ViewCell: React.FC<ViewCellProps> = ({
  row,
  stage,
  setClaimed,
  selectedClientId,
  allTasks,
  users,
}) => {
  const navigate = useNavigate();
  const [activeClaim, setActiveClaim] = useState(false);
  const company: BaseCompanyInfo = useAppSelector(selectBaseCompanyInfo);
  const hasAllowedPermission = useHasAnyAllowedPermissions();


  const redirectToStagedView = (
    clientId: number,
    supplierId: number,
    status: number,
    staged: boolean,
    agentTaskId: number
  ) => {
    const template = `/workcentre/companyProfile/${clientId}/${supplierId}/${status}/${staged}/${agentTaskId}`;
    navigate(template);
  };

  const handleClaim = (isClaimed: boolean) => {
    setClaimed(isClaimed);
    setActiveClaim(isClaimed);
  }




  return (
    <>
      <ClaimActions
        row={row}
        stage={stage}
        Claim={activeClaim}
        selectedClientId={selectedClientId === 0.1 ? 0 : selectedClientId}
        PageSource={PageSourceTypes.DataMgmt}
        SetIsClaimed={handleClaim} />
      {!hasAllowedPermission([PermissionClassification.SuperAgent]) && (
        <IconButton
          onClick={() => {
            redirectToStagedView(
              company.companyId,
              row.companyId,
              row.validationStatus,
              stage,
              row.verificationAgencyId
            );
          }}
        >
          <ManageSearchIcon />
        </IconButton>
      )}
      {hasAllowedPermission([PermissionClassification.SuperAgent]) && (
        <div>
          <SupervisorClaimMgmt users={users} allTasks={allTasks} row={row} stage={stage} selectedClientId={selectedClientId === 0.1 ? 0 : selectedClientId} />
        </div>
      )}
    </>
  );
};

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
          <path
            className="ant-empty-img-1"
            d={
              "M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 " +
              "2.225L13.56 69.674v15.383h108.475V69.674z"
            }
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d={
              "M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 " +
              "2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 " +
              "61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 " +
              "5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 " +
              "2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 " +
              "5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            }
          />
        </g>
        <path
          className="ant-empty-img-3"
          d={
            "M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 " +
            "8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          }
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
);

const SupplierManagement = ({ clientId }: { clientId: number }) => {
  const [allManagedCompanies, setAllManagedCompanies] = useState<ManagedCompaniesModel[]>(
    [] as ManagedCompaniesModel[]
  );
  const [verificationCompanies, setVerificationCompanies] = useState<
    CompaniesRequiringVerificationModel[] | undefined
  >(undefined);
  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [def] = useState("Search...");
  const [isLoading, setIsLoading] = useState(false);
  const company: BaseCompanyInfo = useAppSelector(selectBaseCompanyInfo);
  const [passedValue, setPassedValue] = useState(false);
  const dispatch = useAppDispatch();
  const [needClean, setNeedClean] = useState(0);
  const [suppliers, setSupplier] = useState(0);
  const [expiredBEE, setExpiredBEE] = useState(0);
  const [expired30Days, setExpired30Days] = useState(0);
  const [needAttentionIn60Days, setNeedAttentionIn60Days] = useState(0);

  const [selectionVisable, setSelectionVisable] = useState(false);
  const user = useAppSelector(getUserProfile);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [claimed, setClaimed] = useState(false);
  const [swalPropsChangeSearch, setSwalPropsChangeSearch] = useState({});
  const [showTable, setShowTable] = useState(false);

  const getCurrentlySelectedClient = useAppSelector(getSetCompanyHolder);

  const [users, setUsers] = useState<UserMessage[]>([] as UserMessage[]);
  const [allTasks, setAllTasks] = useState<AgentTaskListGridModel[]>([] as AgentTaskListGridModel[]);

  const [rowCountState, setRowCountState] = useState(0);
  const [pageRowCountState, setPageRowCountState] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  } as GridPaginationModel);
  const [prevPaginationModel, setPrevPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  } as GridPaginationModel);

  const setTheClaimed = (claim: boolean) => {
    setClaimed(claim);
  };
  const columns: GridColDef[] = [
    {
      field: "dataSource",
      headerName: "Data Source",
      headerClassName: "custom-header",
      flex: 1,
      filterable: true,
      minWidth: 150,
    },
    {
      field: "clientCompanyId",
      headerName: "Client Company",
      flex: 1,
      filterable: false,
      sortable: false,
      minWidth: 200,
    },
    {
      field: "tradingName",
      headerName: "Trading Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "vatNumber",
      headerName: "VAT Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "bOPercentage",
      headerName: "BO Percentage",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params.value ? `${params.value} %` : "0%"}
        </div>
      ),
    },
    {
      field: "bWOPercentage",
      headerName: "BWO Percentage",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params.value ? `${params.value} %` : "0%"}
        </div>
      ),
    },
    {
      field: "certificateLevelId",
      headerName: "Certificate Level",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sector",
      headerName: "Sector",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => <div>{params.value ? params.value : "None"}</div>,
    },
    {
      field: "annualTurnover",
      headerName: "Annual Turnover",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <div>{params.value ? params.value : "None"}</div>,
    },
    {
      field: "validationStatus",
      headerName: "Validation Status",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => <div>{AgentTaskStatusTypesToString(params.value)}</div>,
    },
    {
      field: "validationRequiredBy",
      headerName: "Validation Required By",
      minWidth: 180,
      renderCell: (params) => (
        <div className={new Date(params.value) < new Date() ? "red-text" : ""}>
          {params.value === "0001-01-01T00:00:00+00:00" ? "" : formatDate(params.value)}
        </div>
      ),

    },
    {
      field: "validationFollowUpDateTime",
      headerName: "Validation Follow Up Date",
      minWidth: 180,
      renderCell: (params) => (
        <div className={new Date(params.value) < new Date() ? "red-text" : ""}>
          {params.value === "0001-01-01T00:00:00+00:00" ? "" : formatDate(params.value)}
        </div>
      ),
    },
    {
      field: "validationDateTime",
      headerName: "Validation Date & Time",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div className={new Date(params.value) < new Date() ? "red-text" : ""}>
          {params.value === "0001-01-01T00:00:00+00:00" ? "" : formatDate(params.value)}
        </div>
      ),
    },
    {
      field: "ratingSource",
      headerName: "Rating Source",
      flex: 1,
      minWidth: 160,
      renderCell: (params) => <div>{params.value ? params.value : "None"}</div>,
    },
    {
      field: "verificationAgencyId",
      headerName: "Verification Agency",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "beeVerificationAgencyId",
      headerName: "BEE Verification Agency",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "beeAgentName",
      headerName: "BEE Agent Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "vAS",
      headerName: "VAS",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "localServiceProvider",
      headerName: "Local Service Provider",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "localGoodsManufacturer",
      headerName: "Local Goods Manufacturer",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "isBlackOwned",
      headerName: "Is Black Owned",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "isBlackWomanOwned",
      headerName: "Is Black Woman Owned",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "isFlowThrough",
      headerName: "Is Flow Through",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "blackDesignatedGroup",
      headerName: "Black Designated Group",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div>
          {params.value ? `${params.value} %` : "0%"}
        </div>
      ),
    },
    {
      field: "blackYouth",
      headerName: "Black Youth",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "blackDisabled",
      headerName: "Black Disabled",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "blackUnemployed",
      headerName: "Black Unemployed",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "blackRural",
      headerName: "Black Rural",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "blackMilitaryVet",
      headerName: "Black Military Vet",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "farmWorkers",
      headerName: "Farm Workers",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params.value} %
        </div>
      ),
    },
    {
      field: "certificateExpiryDate",
      headerName: "Certificate Expiry Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div className={new Date(params.value) < new Date() ? "red-text" : ""}>
          {params.value === "0001-01-01T00:00:00+00:00" ? "" : formatDate(params.value)}
        </div>
      ),
    },
    {
      field: "taxClearanceNumber",
      headerName: "Tax Clearance Pin",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Contact Name",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "landLine",
      headerName: "Land Line",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "certificateDocumentId",
      headerName: "Certificate Document",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => <div>{params.value ? params.value : "None"}</div>,
    },
    {
      field: "excludeFromSpend",
      headerName: "Exclude from Spend",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "mySupplierNumber",
      headerName: "My Supplier Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "threeYearContract",
      headerName: "Three-Year Contract",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => <div>{params.value ? "Yes" : "No"}</div>,
    },
    {
      field: "exclusionReasonId",
      headerName: "Exclusion Reason",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "mySupplierName",
      headerName: "My Supplier Name",
      flex: 1,
      filterable: true,
      minWidth: 200,
    },
    {
      field: "certificateStatus",
      headerName: "Certificate Status",
      width: 170,
    },
    {
      field: "periodCertificateStatus",
      headerName: "Period Certificate Status",
      width: 170,
    },
    {
      field: "ratingStatus",
      headerName: "Rating Status",
      width: 170,
    },
    {
      field: "ratingStatusExpectedDate",
      headerName: "Rating Status ExpectedDate",
      width: 170,
      renderCell: (params) => (
        <div className={new Date(params.value) < new Date() ? "red-text" : ""}>
          {params.value === "0001-01-01T00:00:00+00:00" ? "" : formatDate(params.value)}
        </div>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 170,
    },
    {
      field: "companyId",
      headerName: "",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell
          selectedClientId={selectedClientId}
          row={params.row as CompaniesRequiringVerificationModel}
          stage={DataSourceTypesToString(params.row.dataSource) === DataSourceTypes.Staged}
          setClaimed={setTheClaimed}
          allTasks={allTasks}
          users={users}
        />
      ),
    },
  ];
  const handleSelectionChange = (params: GridRowSelectionModel) => {
    if (params.length !== 0) {
      setSelectedRowIds(params);
      setSelectionVisable(true);
    } else {
      setSelectionVisable(false);
    }
  };
  const isExpiredIn30Days = (expiryDate: string) => {
    const expiryTimestamp = Date.parse(expiryDate);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(expiryTimestamp)) {
      return false; // If expiryDate is not a valid date, consider it as not expired.
    }
    const currentDate = new Date().getTime();
    const thirtyDaysFromNow = currentDate - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    return expiryTimestamp > thirtyDaysFromNow;
  };
  const isExpiredBy1DayOrGreater = (expiryDate: string) => {
    const expiryTimestamp = Date.parse(expiryDate);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(expiryTimestamp)) {
      return false; // If expiryDate is not a valid date, consider it as not expired.
    }
    const currentDate = new Date().getTime();
    const oneDayAgo = currentDate - 24 * 60 * 60 * 1000; // 1 day in milliseconds
    return expiryTimestamp <= oneDayAgo;
  };
  const isExpiringIn60Days = (expiryDate: string) => {
    const expiryTimestamp = Date.parse(expiryDate);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(expiryTimestamp)) {
      return false; // If expiryDate is not a valid date, consider it as not expiring in 60 days.
    }
    const currentDate = new Date().getTime();
    const sixtyDaysFromNow = currentDate + 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds
    return expiryTimestamp === sixtyDaysFromNow;
  };
  const handleClientChange = async (value: string) => {
    const changedClientId = Number(value);
    setSelectedClientId(changedClientId);
    setShowTable(false);
    if (changedClientId !== 0 && changedClientId !== 0.1) {
      setIsLoading(true);
      const data = await CompanyVerificationService.getAllCompaniesRequiringVerification(
        0,
        20,
        searchTerm,
        false,
        changedClientId
      ).finally(() => {
        setIsLoading(false);
        setShowTable(true);
      });
      setVerificationCompanies(data);
      setRowCountState(data[0].total);
      setNeedClean(data.filter(x => x.validationStatus !== AgentTaskStatusTypes.Completed).length);
      setSupplier(data.length);
      setExpired30Days(data.filter(x => isExpiredIn30Days(x.certificateExpiryDate.toString())).length);
      setExpiredBEE(data.filter(x => isExpiredBy1DayOrGreater(x.certificateExpiryDate.toString())).length);
      setNeedAttentionIn60Days(data.filter(x => isExpiringIn60Days(x.certificateExpiryDate.toString())).length);
      dispatch(fetchCompanyWorkCentreHolder(Number(value), getCurrentlySelectedClient.matchCompanyId));
    }
    if (changedClientId === 0.1) {
      setIsLoading(true);
      const data = await CompanyVerificationService.getAllUnmanagedCompaniesRequiringVerification(
        0,
        20,
        searchTerm,
        false
      ).finally(() => {
        setIsLoading(false);
        setShowTable(true);
      });
      setVerificationCompanies(data);
      setRowCountState(data[0].total);
      setNeedClean(data.filter(x => x.validationStatus !== AgentTaskStatusTypes.Completed).length);
      setSupplier(data.length);
      setExpired30Days(data.filter(x => isExpiredIn30Days(x.certificateExpiryDate.toString())).length);
      setExpiredBEE(data.filter(x => isExpiredBy1DayOrGreater(x.certificateExpiryDate.toString())).length);
      setNeedAttentionIn60Days(data.filter(x => isExpiringIn60Days(x.certificateExpiryDate.toString())).length);
    }
  };

  useEffect(() => {
    if (getCurrentlySelectedClient.dataCompanyId > 0) {
      setSelectedClientId(getCurrentlySelectedClient.dataCompanyId);
      handleClientChange(getCurrentlySelectedClient.dataCompanyId.toString());
    }

  }, []);


  useEffect(() => {
    if (company.companyId !== undefined) {
      const load = async () => {
        const data = await CompanyVerificationService.getAllAgentTaskLists();

        const userData = (await UserProfileService.getAllCompanyUsers(company.companyId)).data;
        setUsers(userData);
        setAllTasks(data);
      };

      load();
    }
  }, [users.length, company.companyId])

  useEffect(() => {



    if (prevPaginationModel.page !== paginationModel.page && selectedClientId > 0.1) {

      let amount = pageRowCountState;
      if (prevPaginationModel.page > paginationModel.page) {
        amount -= 20;
        const loader = async () => {
          setIsLoading(true);
          const data = await CompanyVerificationService.getAllCompaniesRequiringVerification(
            amount,
            20,
            searchTerm,
            false,
            selectedClientId
          );
          setVerificationCompanies(data);
        };
        loader().finally(() => { setIsLoading(false) });

        setPageRowCountState(amount);
      }
      else {
        amount += 20;
        const loader = async () => {
          setIsLoading(true);
          const data = await CompanyVerificationService.getAllCompaniesRequiringVerification(
            amount,
            20,
            searchTerm,
            false,
            selectedClientId
          );
          setVerificationCompanies(data);
        };
        loader().finally(() => { setIsLoading(false) });
        setPageRowCountState(amount);
      }

      setPrevPaginationModel(paginationModel);

    }
    if (prevPaginationModel.page !== paginationModel.page && selectedClientId === 0.1) {

      let amount = pageRowCountState;
      if (prevPaginationModel.page > paginationModel.page) {
        amount -= 20;
        const loader = async () => {
          setIsLoading(true);
          const data = await CompanyVerificationService.getAllUnmanagedCompaniesRequiringVerification(
            amount,
            20,
            searchTerm,
            false
          );
          setVerificationCompanies(data);
        };
        loader().finally(() => { setIsLoading(false) });

        setPageRowCountState(amount);
      }
      else {
        amount += 20;
        const loader = async () => {
          setIsLoading(true);
          const data = await CompanyVerificationService.getAllUnmanagedCompaniesRequiringVerification(
            amount,
            20,
            searchTerm,
            false
          );
          setVerificationCompanies(data);
        };
        loader().finally(() => { setIsLoading(false) });
        setPageRowCountState(amount);
      }

      setPrevPaginationModel(paginationModel);

    }

  }, [pageRowCountState, paginationModel.page, prevPaginationModel.page])

  useEffect(() => {
    if (clientId && !passedValue) {
      setSelectedClientId(clientId);
      handleClientChange(clientId.toString());
      setPassedValue(true);
    }
    if (company.companyId !== undefined && allManagedCompanies.length === 0) {
      const loader = async () => {
        setIsLoading(true);
        const data = await CompanyVerificationService.getManagedCompaniesByPartnerId(
          company.companyId,
          false
        );
        setAllManagedCompanies(data);
        setIsLoading(false);
      };

      loader();
    }

    if (claimed) {
      toast.success("Added to My Tasks");
    }
  }, [
    company.companyId,
    allManagedCompanies.length,
    selectedClientId,
    needClean,
    claimed,
    verificationCompanies?.length,
  ]);

  const searchQuery = async (searchText: string) => {
    setSearchTerm(searchText);

    if (searchText.length >= 3) {
      if (selectedClientId !== 0) {
        setIsLoading(true);
        const data = await CompanyVerificationService.getAllCompaniesRequiringVerification(
          0,
          20,
          searchText,
          false,
          selectedClientId
        );
        setVerificationCompanies(data);
        setIsLoading(false);
      }
    } else {
      setSwalPropsChangeSearch({
        show: true,
        title: "Minimum of 3 Characters",
        icon: "warning",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);

  const handleAllClickOpen = () => {
    setIsLoading(true);
    selectedRowIds.forEach(async (element) => {
      if (verificationCompanies?.find((x) => x.companyId === element)) {
        const list = verificationCompanies?.find((x) => x.companyId === element);
        const model: AgentTaskModel = {} as AgentTaskModel;
        model.companyId = list?.companyId as number;
        model.status = AgentTaskStatusTypes.Assigned;
        model.completion = "0";
        model.comment = "";
        model.clientId = selectedClientId;
        model.agentUserId = user.id as number;
        await CompanyVerificationService.addAgentTask(model, list?.dataSource === "Staged");
      }
    });
    setIsLoading(false);
    setClaimed(true);
  };








  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading

  return (
    <Grid container>
      <LoadingBackdrop showBackdrop={isLoading} />
      <Grid xs={3}>
        <TextField
          id="company"
          select
          value={selectedClientId}
          sx={{ width: "100%", marginBottom: "10px" }}
          label="Select Client"
          onChange={(e) => handleClientChange(e.target.value)}
          size="small"
        >
          <MenuItem value={0.1}>Unmanaged Suppliers</MenuItem>
          {allManagedCompanies.map((record) => (
            <MenuItem value={record.companyId}>{record.registeredName}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid xs={3} />
      <Grid xs={6}>
        {showTable && (
          <Grid container sx={{ width: "100%", marginBottom: 2 }}>
            <Grid xs={2} sx={{ marginBottom: 1 }}>
              <LiquidGraph Number={suppliers} />
            </Grid>
            <Grid xs={4} sx={{ marginTop: 1 }}>
              Suppliers
            </Grid>
            <Grid xs={2}>
              <LiquidGraph Number={needClean} />
            </Grid>
            <Grid xs={4} sx={{ marginTop: 1 }}>
              Need Cleaning
            </Grid>
            <Grid xs={2} sx={{ marginBottom: 1 }}>
              <LiquidGraph Number={expiredBEE} />
            </Grid>
            <Grid xs={4} sx={{ marginTop: 1 }}>
              Expired B-BBEEE
            </Grid>
            <Grid xs={2}>
              <LiquidGraph Number={needAttentionIn60Days} />
            </Grid>
            <Grid xs={4} sx={{ marginTop: 1 }}>
              Need Attention in 60 Days
            </Grid>
            <Grid xs={2} sx={{ marginBottom: 1 }}>
              <LiquidGraph Number={expired30Days} />
            </Grid>
            <Grid xs={4} sx={{ marginTop: 1 }}>
              Urgent Expired 30 days
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid xs={12}>
        <div className="agent-list-box-small">
          <div className="agent-list-search-switch">
            <div
              className="search-components-wrap"
              style={{ border: "1px solid rgba(0, 0, 0, 0.116)", borderRadius: "3px" }}
            >
              <InputBase
                id="search-bar"
                value={searchTerm}
                className="text-main-search"
                onInput={(ev: any) => setSearchTerm(ev.target.value)}
                sx={{ width: "96%", border: "0px" }}
                placeholder={def}
                size="small"
              />

              <IconButton
                type="submit"
                aria-label="search"
                sx={{ width: "4%" }}
                onClick={() => searchQuery(searchTerm)}
                className="switch-to-advanced-btn"
              >
                <ManageSearchIcon style={{ fill: "#435cd2" }} />
              </IconButton>
            </div>
          </div>
        </div>
        {selectionVisable && (
          <Button onClick={handleAllClickOpen} sx={{ fontSize: "0.8125rem", float: "right" }}>
            <CheckCircleIcon /> Claim All Companies
          </Button>
        )}
      </Grid>
      <Grid xs={12}>
        {showTable && (
          <div style={{ height: 400, width: "100%" }}>
            <DataGridPro
              onRowSelectionModelChange={handleSelectionChange}
              checkboxSelection
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    clientCompanyId: false,
                    vatNumber: false,
                    bOPercentage: false,
                    bWOPercentage: false,
                    certificateLevelId: false,
                    sectorId: false,
                    annualTurnover: false,
                    ratingSourceId: false,
                    verificationAgencyId: false,
                    vAS: false,
                    localServiceProvider: false,
                    localGoodsManufacturer: false,
                    isBlackOwned: false,
                    isBlackWomanOwned: false,
                    isFlowThrough: false,
                    blackDesignatedGroup: false,
                    blackYouth: false,
                    blackDisabled: false,
                    blackUnemployed: false,
                    blackRural: false,
                    blackMilitaryVet: false,
                    farmWorkers: false,
                    certificateExpiryDate: false,
                    taxClearanceNumber: false,
                    name: false,
                    email: false,
                    landLine: false,
                    certificateDocumentId: false,
                    excludeFromSpend: false,
                    mySupplierNumber: false,
                    threeYearContract: false,
                    exclusionReasonId: false,
                    mySupplierName: false,
                  },
                },
              }}
              disableRowSelectionOnClick
              rows={verificationCompanies ?? []}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              rowCount={rowCountState}
              paginationMode="server"
              pagination
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              slots={{
                toolbar: CustomToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              getRowId={(row: CompaniesRequiringVerificationModel) => row.companyId}
            />
          </div>
        )}
      </Grid>
      <SweetAlert2 {...swalPropsChangeSearch} />
    </Grid>
  );
};

export default SupplierManagement;
