const AddressModelProps = {
  addressId: "addressId",
  line1: "line1",
  line2: "line2",
  suburb: "suburb",
  city: "city",
  postalCode: "postalCode",
  addressTypeId: "addressTypeId",
  provinceId: "provinceId",
  countryId: "countryId",
  isDeleted: "isDeleted",
  companyId: "companyId",
  municipalityId: "municipalityId",
  unitNumber: "unitNumber",
  officeBlockOrComplexName: "officeBlockOrComplexName",
};

export default AddressModelProps;