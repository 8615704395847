import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { viewConnectionRequestSlice, viewConnectionRequestForCompanySlice } from "./ConnectionRequestSlice"
import { RootState } from "../../../app/store"
import { ViewConnectionRequestModel } from "../Models/ViewConnectionRequestModel";
import ConnectionRequestService from "../../ConnectionRequestService";

export const viewConnectionRequestActions = viewConnectionRequestSlice.actions
export const viewConnectionRequestForCompanyActions = viewConnectionRequestForCompanySlice.actions


export const fetchViewConnectionRequests = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: ViewConnectionRequestModel[] = await (await ConnectionRequestService.getViewConnectionRequests(companyId));
  dispatch(viewConnectionRequestActions.setViewConnectionRequest(response))

}

export const fetchViewConnectionRequestsForCompany = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: ViewConnectionRequestModel[] = await (await ConnectionRequestService.getViewConnectionRequestsForCompany(companyId));
  dispatch(viewConnectionRequestForCompanyActions.setViewConnectionRequest(response))

}

