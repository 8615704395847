import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAccount, useMsal } from "@azure/msal-react";
import { GetUserCompanies } from "../../../../http/Company/company";
import { UserCompanyModel } from "../../../../http/Redux/Models/UserCompanyModel";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { UserCompanyListActions } from "../../../../http/Redux/Store/UserCompanyListActions";
import { selectUserCompanyList } from "../../../../http/Redux/Store/UserCompanyListSlice";

interface SearchProps {
  // eslint-disable-next-line react/require-default-props
  companyList?: Array<UserCompanyModel>,
  // eslint-disable-next-line no-unused-vars
  choose: (text: string) => void;
  // eslint-disable-next-line react/require-default-props
  clear?: () => void;
  label: string;
  field: string;
  disabled: boolean;
  useId: boolean;
  companyId: number;
}

const HoldingCompanySwitcher: React.FC<SearchProps> = ({
  companyList,
  choose,
  clear,
  label,
  field,
  disabled,
  useId,
  companyId,
}) => {
  const dispatch = useAppDispatch();
  const userCompaniesFromRedux = useAppSelector(selectUserCompanyList);

  const [listValue, setListValues] = useState("");
  const [labelValue, setLabelValue] = useState(label);
  const [changed, setChanged] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAdId = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const [userCompaniesState, setUserCompaniesState] = useState<Array<UserCompanyModel>>([] as Array<UserCompanyModel>);

  const clearHoldingCompany = () => {
    setListValues("");
    if (typeof clear === "function") clear();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setChanged(true);
    setListValues(event.target.value);
    setSelectedCompanyId(parseInt(event.target.value, 10));
    choose(event.target.value);
  };


  const getUserCompanyDataFromApi = async () => {
    const userCompaniesFromApi = await GetUserCompanies();

    // Dispatch to the reducer
    dispatch(UserCompanyListActions.setUserCompanyListState(userCompaniesFromApi));

    return userCompaniesFromApi;
  }

  const removeCompanyFromArray = (data: Array<UserCompanyModel>, companyIdToRemove: number) =>
    data.filter((company) => company.companyId !== companyIdToRemove);

  useEffect(() => {
    setLabelValue(label);

    if (!listValue) setListValues(field);

    // Check if we need to load something into local state:
    if (userCompaniesState.length === 0) {
      if (companyList) {
        const filteredCompanies = companyList?.filter((company) => company.companyId !== companyId);

        setUserCompaniesState(filteredCompanies || ([] as Array<UserCompanyModel>));
        return;
      }

      const loader = async () => {
        if (userCompaniesFromRedux.length > 0) {
          setUserCompaniesState(removeCompanyFromArray(userCompaniesFromRedux, companyId));
          return;
        }

        const dataFromApi = await getUserCompanyDataFromApi();
        setUserCompaniesState(removeCompanyFromArray(dataFromApi, companyId));
      };

      loader();
    }

    if (changed) {
      clearHoldingCompany();
      setUserCompaniesState(userCompaniesState.filter(comp => comp.companyId !== selectedCompanyId));
      setChanged(false);
      setSelectedCompanyId(0);
    }

  }, [companyList?.length, companyId, label, userAdId, userCompaniesState.length]);

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="demo-multiple-checkbox-dash-label">{labelValue}</InputLabel>
      <Select
        className="select-holding"
        id="demo-multiple-checkbox-dash"
        margin="none"
        size="medium"
        value={listValue || ""}
        disabled={disabled}
        onChange={handleChange}
        input={<OutlinedInput label={labelValue} />}
        endAdornment={
          <IconButton
            sx={{ visibility: (listValue) ? "visible" : "hidden" }}
            onClick={clearHoldingCompany}
            disabled={disabled}
          >
            <CloseIcon className="cl-blue" />
          </IconButton>
        }
        sx={{ "& .MuiSelect-iconOutlined": { display: (listValue) ? "none" : "" } }}
      >
        {userCompaniesState.map((name) => (
          <MenuItem key={name.companyId} value={useId ? name.companyId : name.registeredName}>
            {name.registeredName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default HoldingCompanySwitcher;
