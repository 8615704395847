// eslint-disable-next-line no-shadow
enum Sections { // eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  General = 1,// eslint-disable-line no-unused-vars
  Contacts = 2,// eslint-disable-line no-unused-vars
  Address = 3,// eslint-disable-line no-unused-vars
  Bee = 4,// eslint-disable-line no-unused-vars
  Product = 5,// eslint-disable-line no-unused-vars
  BankDetails = 6,// eslint-disable-line no-unused-vars
  Compliance = 7,// eslint-disable-line no-unused-vars
  AdditionalContacts = 8,// eslint-disable-line no-unused-vars
  CompanyRating = 9,// eslint-disable-line no-unused-vars
}

export default Sections;

export const sectionToString = (value: Sections) => {
  switch (value) {
    case Sections.None:
      return "None"
    default:
      return "Unknown";
  }
};