import { makeDeleteRequestNoBodyAsync, makePUTRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { NewSubscriptionMessage } from "./NewSubscriptionMessage";
import { protectedResources } from "../../authConfig"
import { ReplaceSubscriptionMessage } from "./ReplaceSubscriptionMessage";

const baseUrl = protectedResources.billingWebApi.endpoint;
const scopes = protectedResources.billingWebApi.scopes;

export const CreateNewBilingSubscription = async (message: NewSubscriptionMessage) => {
  const url = `${baseUrl}/api/Billing/Subscriptions`;
  return (await makePostRequestAsync<string, NewSubscriptionMessage>(scopes, url, message)).data;
}

export const CancelSubscription = async () => {
  const url = `${baseUrl}/api/Billing/Subscriptions`;
  return (await makeDeleteRequestNoBodyAsync(scopes, url)).data;
}

export const ReplaceSubscription = async(message: ReplaceSubscriptionMessage) => {
  const url = `${baseUrl}/api/Billing/Subscriptions/replace`;
  return (await makePUTRequestAsync<string, ReplaceSubscriptionMessage>(scopes, url, message)).data;
}