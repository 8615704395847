
import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import ManageSearchIcon from "@mui/icons-material/Search";
import SweetAlert2 from "react-sweetalert2";
import { toast } from "react-toastify";
import { SupplierMatchViewModel } from "../../../../http/Redux/Models/SupplierMatchViewModel";
import CompanyVerificationService from "../../../../http/CompanyVerificationService";
import { AgentSearchListModel } from "../../../../http/Redux/Models/AgentSearchListModel";
import EditUpload from "./EditUpload";
import AcceptMatch from "./AcceptMatch";
import "../../../../components/Connect/SearchResult/SweetAlert.css";
import LoadingBackdrop from "../../../../components/Backdrop/LoadingBackdrop";

interface ViewCellProps {
  row: AgentSearchListModel;
  uploadedCompanyData: SupplierMatchViewModel;
}




// eslint-disable-next-line react/no-unstable-nested-components
const ViewCell: React.FC<ViewCellProps> = ({ row, uploadedCompanyData }) => (
  <div>
    <AcceptMatch data={row} supplierRow={uploadedCompanyData} />
  </div>
);

const ViewMatch = ({ uploadedCompanyData, back }: { uploadedCompanyData: SupplierMatchViewModel, back: () => void }) => {
  const [supplierMatchView, setSupplierMatchView] = useState<AgentSearchListModel[]>([]);
  const [uploadedSupplier, setUploadedSuppliers] = useState({} as SupplierMatchViewModel);
  const [searchTerm, setSearchTerm] = useState("");
  const [swalPropsChangeSearch, setSwalPropsChangeSearch] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showRematchButton, setShowRematchButton] = useState(false);



  const handleRematch = async () => {

    await CompanyVerificationService.rematch(uploadedSupplier);
    toast.success("Rematch in progress");
    back();
  }

  const handleShowRematch = (updatedViewModel: SupplierMatchViewModel) => {
    setShowRematchButton(true);
    const uploaded = {} as SupplierMatchViewModel;
    uploaded.uploadRegisteredName = updatedViewModel.uploadRegisteredName;
    uploaded.uploadClientVendorNumber = updatedViewModel.uploadClientVendorNumber;
    uploaded.uploadRegistrationNumber = updatedViewModel.uploadRegistrationNumber;
    uploaded.uploadTradingName = updatedViewModel.uploadTradingName;
    uploaded.uploadVATNumber = updatedViewModel.uploadVATNumber;
    uploaded.clientCompanyId = updatedViewModel.clientCompanyId;
    uploaded.uploadTempCompanyId = updatedViewModel.uploadTempCompanyId;
    uploaded.uploadContactEmail = updatedViewModel.uploadContactEmail;
    uploaded.uploadContactName = updatedViewModel.uploadContactName;
    uploaded.uploadContactPhone = updatedViewModel.uploadContactPhone;

    setUploadedSuppliers(uploaded);
  }


  const columns: GridColDef[] = [
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "holdingCompanyName",
      headerName: "Holding Company Name",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "vATNumber",
      headerName: "VAT Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "tradingName",
      headerName: "Trading Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "tradeDescription",
      headerName: "Trade Description",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "companyId",
      headerName: "Actions",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell row={params.row as AgentSearchListModel} uploadedCompanyData={uploadedSupplier} />
      ),
    },
  ];

  const searchQuery = async (searchText: string) => {
    setSearchTerm(searchText);
    setIsLoading(true);
    if (searchText.length >= 3) {

      const data = await CompanyVerificationService.getAgentSearchList(searchText);
      const supplierMatches: AgentSearchListModel[] = [];
      data.forEach(element => {

        if (Number(element.companyId) > 0) {
          const sub: AgentSearchListModel = {} as AgentSearchListModel;
          sub.registrationNumber = element.registrationNumber;
          sub.registeredName = element.registeredName;
          sub.vATNumber = element.vATNumber;
          sub.tradingName = element.tradingName;
          sub.tradeDescription = element.tradeDescription;
          sub.holdingCompanyName = element.holdingCompanyName;
          sub.companyId = element.companyId;
          supplierMatches.push(sub);
        }


      });
      setSupplierMatchView(supplierMatches);


    }
    else {
      setSwalPropsChangeSearch({
        show: true,
        title: "Minimum of 3 Characters",
        icon: "warning",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,

      });
    }
    setIsLoading(false);

  }

  useEffect(() => {

    if (!isLoaded) {
      const sub: AgentSearchListModel = {} as AgentSearchListModel;
      sub.registrationNumber = uploadedCompanyData.matchedRegistrationNumber
      sub.registeredName = uploadedCompanyData.matchedRegisteredName
      sub.vATNumber = uploadedCompanyData.matchedVATNumber;
      sub.tradingName = uploadedCompanyData.matchedTradingName;
      sub.tradeDescription = uploadedCompanyData.matchedTradeDescription;
      sub.holdingCompanyName = uploadedCompanyData.matchedHoldingCompanyName;
      sub.companyId = uploadedCompanyData.uniqueNumber;
      setSupplierMatchView([...supplierMatchView, sub]);
      setIsLoaded(true);


      setUploadedSuppliers(uploadedCompanyData);


    }

  }, [uploadedSupplier.uploadRegisteredName, isLoaded, uploadedCompanyData.matchedRegistrationNumber, uploadedCompanyData.matchedRegisteredName, uploadedCompanyData.matchedVATNumber, uploadedCompanyData.matchedTradingName, uploadedCompanyData.matchedTradeDescription, uploadedCompanyData.uploadHoldingCompanyName, uploadedCompanyData.uniqueNumber, supplierMatchView.length]);

  const [opens, setOpens] = React.useState(false);

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const companyId = await CompanyVerificationService.createStaged(uploadedCompanyData);
    const supplierData = uploadedCompanyData as SupplierMatchViewModel;
    supplierData.matchToCompanyId = companyId as number;
    await CompanyVerificationService.createMaster(supplierData);
    toast.success("Master Created");
    setOpens(false);
    setIsLoading(false);
    back();
  }

  return (
    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      <Box sx={{ width: "100%" }}>
        <Grid container>
          <Grid xs={12}>
            <Button variant="contained" onClick={back}>Back</Button>
          </Grid>
          <div className="hr-line" />
          <Grid xs={6} container>
            <Grid xs={6}>Registered Name</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadRegisteredName}</Grid>
            <Grid xs={6}>Supplier Number</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadClientVendorNumber}</Grid>
            <Grid xs={6}>Reg No</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadRegistrationNumber}</Grid>
            <Grid xs={6}>Vat No</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadVATNumber}</Grid>
            <Grid xs={6}>Trading Name</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadTradingName}</Grid>
            <Grid xs={6}>Contact Name</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadContactName}</Grid>
            <Grid xs={6}>Contact Email</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadContactEmail}</Grid>
            <Grid xs={6}>Contact Number</Grid>
            <Grid xs={6}>{uploadedSupplier.uploadContactPhone}</Grid>
            <Grid xs={10} />
            <Grid xs={2}>
              <EditUpload data={uploadedSupplier} handleShowRematch={handleShowRematch} />
            </Grid>
          </Grid>
          <div className="hr-line" />
          <Grid xs={12}>
            <Button variant="contained" sx={{ marginBottom: 2 }} onClick={handleClickOpen} >Create Master</Button>
            {showRematchButton && (
              <Button variant="contained" sx={{ marginBottom: 2, marginLeft: 2 }} onClick={handleRematch} >Rematch</Button>
            )}
            <Dialog
              open={opens}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Create Master
              </DialogTitle>
              <DialogContent>

                <Grid xs={12} container>
                  <Grid xs={6}>Registered Name</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadRegisteredName}</Grid>
                  <Grid xs={6}>Supplier Number</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadClientVendorNumber}</Grid>
                  <Grid xs={6}>Reg No</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadRegistrationNumber}</Grid>
                  <Grid xs={6}>Vat No</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadVATNumber}</Grid>
                  <Grid xs={6}>Trading Name</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadTradingName}</Grid>
                  <Grid xs={6}>Contact Name</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadContactName}</Grid>
                  <Grid xs={6}>Contact Email</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadContactEmail}</Grid>
                  <Grid xs={6}>Contact Number</Grid>
                  <Grid xs={6}>{uploadedSupplier.uploadContactPhone}</Grid>
                </Grid>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="contained">Cancel</Button>
                <Button onClick={handleCreate} variant="contained" autoFocus>
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid xs={12}>
            <div className="agent-list-box-small">
              <div className="agent-list-search-switch">
                <div
                  className="search-components-wrap"
                  style={{ border: "1px solid rgba(0, 0, 0, 0.116)", borderRadius: "3px" }}
                >
                  <InputBase
                    id="search-bar"
                    value={searchTerm}
                    className="text-main-search"
                    onInput={(ev: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(ev.target.value)}
                    sx={{ width: "96%", border: "0px" }}
                    placeholder="Search..."
                    size="small" />
                  <IconButton
                    type="submit"
                    aria-label="search"
                    sx={{ width: "4%" }}
                    onClick={() => searchQuery(searchTerm)}
                    className="switch-to-advanced-btn"
                  >
                    <ManageSearchIcon style={{ fill: "#435cd2" }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            {supplierMatchView[0]?.registeredName ?
              <div style={{ height: 500, width: "100%" }}>
                <DataGridPro
                  columns={columns}
                  rows={supplierMatchView.filter(x => x.companyId > 0)}
                  disableRowSelectionOnClick
                  pagination
                  getRowId={(row: AgentSearchListModel) => row.companyId as number} />
              </div> :
              <Alert variant="outlined" severity="info">
                No company has matched with uploaded supplier.<br />
                (<i>Create a master or edit upload supplier details and rematch</i>)
              </Alert>}
          </Grid>
        </Grid>
        <SweetAlert2 {...swalPropsChangeSearch} />
      </Box>

    </div >
  )
}

export default ViewMatch;