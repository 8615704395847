import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent } from "react";

const extractFieldData = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
  let fieldName = null;
  let fieldValue = null;
  if ("name" in ev.target && "value" in ev.target) {
    fieldName = ev.target.name;
    fieldValue = ev.target.value;
  } else {
    const currentTarget = ev.currentTarget as HTMLInputElement | HTMLTextAreaElement;
    fieldName = currentTarget.name;
    fieldValue = currentTarget.value;
  }
  return { fieldName, fieldValue };
};

export default extractFieldData;