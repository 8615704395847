import { TextField as MUITextField, TextFieldProps as MuiTextFieldProps, TextFieldVariants } from "@mui/material"
import { colorNotificationFailed } from "../../../../util/MUI_Theme/Theme";

export type TextFieldProps<Variant extends TextFieldVariants = TextFieldVariants> =
  MuiTextFieldProps<Variant> & {
    // enables the default MUI error handling behaviour
    // eslint-disable-next-line react/require-default-props
    defaultErrorBehaviour?: boolean;
  };

const TextField = (props: TextFieldProps) => {
  const title = typeof props.label === "string" ?
    props.label.charAt(0).toUpperCase() + props.label.slice(1)
    : props.label;

  return (
    <span style={{paddingTop : 15, marginTop: 20}}>
      {
        props.label &&
        <div className="text-label-box">
          {props.required &&
            <span className="text-label-astrid">*</span>
          }
          {title}
        </div>
      }
      <MUITextField
        {...props}
        color={props.error ? "warning" : undefined}
        label={null}
        placeholder={props.placeholder ?? `Enter the ${props.label}`}
        sx={{
          width: "90%",

          // placeholder color
          "& input::placeholder": {
            color: props.error ? colorNotificationFailed : "inherit",
          },

          // border color
          "& .MuiOutlinedInput-root": {
            borderColor: "#101820",
          },
        }}
        error={props.defaultErrorBehaviour ? props.error :
          (props.required &&
            (props.value === undefined ||
              props.value === null ||
              props.value.toString().length === 0) &&
            !props.disabled
          )
        }
        helperText={props.defaultErrorBehaviour ? props.helperText :
          (
            props.helperText ??
            (props.required &&
              (props.value === undefined ||
                props.value === null ||
                props.value.toString().length === 0) &&
              !props.disabled &&
              "This field is required!")
          )
        }
      />
    </span>
  );
}

export default TextField;