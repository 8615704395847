/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ProvinceModel } from "../../Company/Models/ProvinceModel";


export interface ProvinceListState {
  provinceList: Array<ProvinceModel>;
}

const provinceListSlice = createSlice({
  name: "provinceList",
  initialState: [] as Array<ProvinceModel>,
  reducers: {
    setProvinceListState: (
      state,
      action: PayloadAction<Array<ProvinceModel>>
    ) => action.payload,
  },
});

export const selectProvinceList = (state: RootState) => state.provinceList;
export default provinceListSlice;
