import axios from "axios";

const baseUrl = process.env.REACT_APP_PROFILE_SHARE;

export default {
  async GetShare(hash: string) {
    const url = `${baseUrl}/api/ProfileShare/${hash}`;
    const result = await axios.get(url);
    return result.data;
  },
}

