import { IconButton, Tooltip, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";
import { UserMessage } from "../../../http/CompanyUserManagement/Messages/UserMessage";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import DataSourceTypes, { DataSourceTypesToString } from "../../../util/enums/DataSourceTypes";






interface ViewCellProps {
  row: AgentTaskListGridModel;
  stage: boolean;
  handleAbandon: () => void;
  userList: UserMessage[];
}




const ViewCell = ({ row, stage, handleAbandon, userList }: ViewCellProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateModel, setUpdateModel] = useState<AgentTaskModel>({} as AgentTaskModel);
  const isStaged = DataSourceTypesToString(row.dataSource) === DataSourceTypes.Staged;

  const handleAllocateTo = async () => {

    setIsLoading(true);

    await CompanyVerificationService.updateAgentTask(updateModel, isStaged).finally(() => {
      setIsLoading(false);
    });
    const updateTask = updateModel as AgentTaskModel;
    updateTask.agentUserId = selectedUserId;
    updateTask.status = AgentTaskStatusTypes.Assigned;
    updateTask.uploadTempCompanyId = row.uploadTempCompanyId;
    await CompanyVerificationService.addAgentTask(updateTask, isStaged).finally(() => {
      setIsLoading(false);
      setOpenDialog(false);
    });
    toast.success("Task Reassigned");
    handleAbandon();
  }

  const handleUserChange = (value: string) => {
    setSelectedUserId(Number(value));
  };

  const handleOpenAllocateUser = () => {
    setOpenDialog(true);
  }


  const handleReturnTaskToPool = async () => {
    setIsLoading(true);
    await CompanyVerificationService.updateAgentTask(updateModel, isStaged).finally(() => {
      setIsLoading(false);
    });
    handleAbandon();
  }

  useEffect(() => {

    setUpdateModel({
      ...updateModel,
      id: row.agentTaskId,
      companyId: row.companyId,
      status: AgentTaskStatusTypes.Abandoned,
      completion: row.completion,
      comment: row.comment,
      agentUserId: row.agentUserId,
      clientId: row.clientId,
      agentCompanyId: row.agentCompanyId,
      createdBy: row.createdBy,
      dateCreated: row.dateCreated,
      expectedNextUpdateDate: row.expectedNextUpdateDate,
      uploadTempCompanyId: row.uploadTempCompanyId,
    });

  }, [])


  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const redirectToStagedView = (clientId: number, supplierId: number, status: number, staged: boolean, agentTaskId: number) => {
    const template = `/workcentre/companyProfile/${clientId}/${supplierId}/${status}/${staged}/${agentTaskId}`;
    navigate(template);
  };


  return (
    <>
      <div>
        <LoadingBackdrop showBackdrop={isLoading} />
        <Tooltip title="Task Allocations">
          <IconButton
            onClick={handleClickMenu}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={opens ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opens ? "true" : undefined}
          >
            <BookmarksIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={opens}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
        >
          {row.pageSource === PageSourceTypes.DataMgmt && (
            <MenuItem onClick={() => { redirectToStagedView(row.clientId, row.companyId, row.statusId, stage, row.agentTaskId); }}>
              <SearchIcon /> View Company Details
            </MenuItem>
          )}
          <MenuItem onClick={handleReturnTaskToPool}>
            <BookmarkRemoveIcon /> Return Task To Pool
          </MenuItem>
          <MenuItem onClick={handleOpenAllocateUser}>
            <BookmarkAddIcon /> Reallocate Task

          </MenuItem>
        </Menu>
      </div><Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Reallocate Task
        </DialogTitle>
        <DialogContent >
          <Box sx={{ width: "300px", height: "90px", padding: "10px" }}>
            <TextField
              id="agent"
              select
              value={selectedUserId}
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Select Agent"
              onChange={(e) => handleUserChange(e.target.value)}
              size="small"
            >
              {userList.map((record) => (
                record.id !== row.agentUserId && (
                  <MenuItem key={record.id} value={record.id}>
                    {record.name}
                  </MenuItem>
                )
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">Cancel</Button>
          <Button onClick={handleAllocateTo} variant="contained" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );

}

export default ViewCell;