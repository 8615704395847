import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyTaxInformation } from "../../../http/Company/CompanyTaxInformation";
import { TaxInformation } from "../../../http/Company/Models/CompanyTaxInformation";

const useCompanyTaxInformation = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyTaxInformation, companyId],
    queryFn: async () => (await GetCompanyTaxInformation(companyId)).data as TaxInformation,
    staleTime: 600000,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyTaxInformation;