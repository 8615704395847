import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { CoidaDetails } from "../../../http/Company/Models/CoidaDetails";
import { GetCompanyCoidaDetails } from "../../../http/Company/CompanyCoidaDetails";

const useCompanyCoidaDetails = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyCoidaDetails, companyId],
    queryFn: async () => (await GetCompanyCoidaDetails(companyId)).data as CoidaDetails,
    staleTime: 600000,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyCoidaDetails;