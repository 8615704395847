import React from "react"
import { Alert, Box, Button } from "@mui/material"
import { useNavigate } from "react-router"
import PermissionClassification from "../util/enums/PermissionClassification"
import { useHasAnyAllowedPermissions } from "../util/hooks/useAllowedPermission"
import BasePage from "../pages/BasePage"

const AuthenticatePage = ({
  permissions,
  children,
}: {
  children: React.ReactNode,
  permissions?: PermissionClassification[] // eslint-disable-line react/require-default-props
}) => {
  const hasAnyAllowedPermissions = useHasAnyAllowedPermissions();
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/mysubscriptions");
  };
  return (
    <span>
      {
        (permissions == null || permissions.length === 0 || hasAnyAllowedPermissions(permissions)) ?
          children :
          <BasePage pageHeader="" pageHeaderSub="">
            <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <Alert
                severity="error"
                action={<Button variant="contained" onClick={handleRedirect}>Upgrade Now</Button>}>
                Unfortunately, you do not currently have access to this page.
                We kindly request that you consider upgrading your current subscription package in order to unlock a wide range of features,
                including this one and many more.
              </Alert>

            </Box>


          </BasePage>
      }
    </span >
  )
}

export default AuthenticatePage