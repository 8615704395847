import React from "react";
import { Dayjs } from "dayjs";
import CustomDatePicker from "../../../../components/CustomComponents/CustomDatePicker";

interface DatePickerProps {
  // eslint-disable-next-line react/require-default-props
  required?: boolean,
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean,
  // eslint-disable-next-line react/require-default-props
  format?: string,
  value: Dayjs | null,
  title: string,
  width: string,
  // eslint-disable-next-line react/require-default-props
  disablePast?: boolean,
  // eslint-disable-next-line react/require-default-props
  disableFuture?: boolean,
  // eslint-disable-next-line no-unused-vars
  setDatePicker: (selectedDate: Dayjs | null) => void,
}

const DatePicker = ({
  required,
  disabled = false,
  format = "YYYY-MM-DD",
  value,
  title,
  width,
  disablePast = false,
  disableFuture = false,
  setDatePicker,
}: DatePickerProps) => (
  <>
    <div className="text-label-box">
      {required && <span className="text-label-astrid">*</span>}
      {title}
    </div>
    <CustomDatePicker
      width={width}
      disablePast={disablePast}
      disableFuture={disableFuture}
      setDateSelector={setDatePicker}
      value={value}
      disabled={disabled}
      format={format}
    />
  </>
);

export default DatePicker