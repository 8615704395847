import React, { useEffect, useRef, useState } from "react";
import { TextField, Grid, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faFloppyDisk, faPencilSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ContactModel } from "../../../../http/Company/Models/ContactModel";
import "../CompanyPost.css";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import { removeUnsavedContactsFromState, setCompanyContactState } from "../../../../http/Redux/Store/companySlice";
import { useAppDispatch } from "../../../../app/hooks";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import WithRequiredPermission from "../../../WithRequiredPermission";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";

interface SecondaryContactProps {
  contactData: ContactModel;
  /* eslint-disable no-unused-vars */
  SetDirtyCallback: (isDirty: boolean) => void;
  OnContactSave: (contactToSave: ContactModel) => void;
  OnContactDelete: (contactToDelete: ContactModel, staged: boolean) => Promise<void>;
  /* eslint-disable no-unused-vars */
}

const SecondaryContact = ({
  contactData,
  SetDirtyCallback,
  OnContactSave,
  OnContactDelete,
}: SecondaryContactProps) => {
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<boolean>(false);
  const [contact, setContact] = useState<ContactModel>(contactData);
  const [contactComparison, setContactComparison] = useState<ContactModel>({} as ContactModel);

  const initialLoadCheck = useRef(true);
  const [validateField] = useFieldValidation("companyProfile");

  const validateContact = () => {
    if (contact?.name.trim() === "") {
      return false;
    }

    if (!contact.username || contact.username.trim() === "") {
      return false;
    }

    if (!contact.designation || contact.designation?.trim() === "") {
      return false;
    }

    if (!contact.mobile || contact.mobile.trim() === "") {
      return false;
    }

    if (!contact.email || contact.email.trim() === "") {
      return false;
    }

    if (!contact.idNumber || contact.idNumber.trim() === "") {
      return false;
    }

    return true;
  };

  const debouncedContactValue = useInputDebounce(contact);

  // Validation Error State Variables:
  const [contactNameInvalid, setContactNameInvalid] = useState<boolean>(false);
  const [contactUserNameInvalid, setContactUsernameInvalid] = useState<boolean>(false);
  const [contactDesignationInvalid, setContactDesignationInvalid] = useState<boolean>(false);
  const [contactMobileInvalid, setContactMobileInvalid] = useState<boolean>(false);
  const [contactEmailInvalid, setContactEmailInvalid] = useState<boolean>(false);
  const [contactIdNumberInvalid, setContactIdNumberInvalid] = useState<boolean>(false);

  const EnableEdit = () => {
    if (edit) {
      const isValid = validateContact();
      if (!isValid) {
        Swal.fire({
          title: "Validation Errors",
          text: "Please resolve all validation errors, and try again",
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowOutsideClick: false,
        });

        return;
      }

      OnContactSave(contact);
      SetDirtyCallback(false);
    }

    setEdit(!edit);
  };

  const DeleteThisContact = () => {
    if (edit) {
      Swal.fire({
        title: "Are you sure you want to delete this contact?",
        icon: "question",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          OnContactDelete(contactData, false);
        }
      });
    }

    setEdit(!edit);
  };

  const CancelContactAdd = () => {
    dispatch(removeUnsavedContactsFromState());
    setEdit(!edit);
    SetDirtyCallback(false);
  };

  const CancelEdit = () => {
    setEdit(!edit);
    setContact(contactComparison);
    SetDirtyCallback(false);
  };

  const performDirtyCheck = (checkVal: ContactModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(contactComparison)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...contact, [propName]: event.target.value };
    setContact(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      setContactComparison(JSON.parse(JSON.stringify(contactData)));
      initialLoadCheck.current = false;
    }
  }, [contactData.id]);

  useEffect(() => {
    dispatch(setCompanyContactState(debouncedContactValue));
  }, [debouncedContactValue]);

  return (
    <div className={styles.postBody}>
      <div className="post_second_header">
        <div className="head-left">
          <h3>Additional Contact</h3>
        </div>
        <div className="head-right" />
      </div>
      <div className={styles.editSave}>
        <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
          {!edit && (
            <Tooltip title="Edit">
              <FontAwesomeIcon
                icon={faPencilSquare}
                aria-hidden="true"
                id="edit-general-details"
                onClick={EnableEdit}
                className="hover-cursor"
              />
            </Tooltip>
          )}
          {edit && contactData.companyId > 0 && (
            <div className={styles.btnBox}>
              <div className={styles.btnBoxLeft}>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    icon={faTrash}
                    aria-hidden="true"
                    id="edit-delete-contact"
                    onClick={DeleteThisContact}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxCenter}>
                <Tooltip title="Save">
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    aria-hidden="true"
                    id="edit-save-contact"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxRight}>
                <Tooltip title="Cancel Edit">
                  <FontAwesomeIcon
                    icon={faCancel}
                    aria-hidden="true"
                    id="edit-cancel-general"
                    onClick={CancelEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {edit && contactData.companyId === 0 && (
            <div className={styles.btnBox}>
              <div className={styles.btnBoxLeft}>
                <Tooltip title="Save">
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    aria-hidden="true"
                    id="edit-save-general"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxRight}>
                <Tooltip title="Cancel Edit">
                  <FontAwesomeIcon
                    icon={faCancel}
                    aria-hidden="true"
                    id="edit-cancel-general"
                    onClick={CancelContactAdd}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </WithRequiredPermission>
      </div>
      <Grid container className="g-container">
        <Grid container xs={4}>
          <TextField
            type="text"
            sx={{ width: "98%", marginRight: "2%" }}
            disabled={!edit}
            id="secondary-contact-name"
            label="Contact Name"
            size="small"
            value={contact?.name || ""}
            error={contactNameInvalid}
            helperText={
              contactNameInvalid &&
              validateField("Contact Name", contact?.name ?? "").validationErrorText
            }
            onChange={(ev) => handleValueChange(ev, "name")}
            onBlur={() => {
              const invalidField = validateField("Contact Name", contact?.name ?? "").isInvalid;

              if (invalidField) {
                setContactNameInvalid(true);
                return;
              }

              setContactNameInvalid(false);
            }}
          />
        </Grid>
        <Grid container xs={4}>
          <TextField
            type="text"
            sx={{ width: "98%", marginRight: "2%" }}
            disabled={!edit}
            id="secondary-contact-username"
            label="Username"
            size="small"
            value={contact?.username || ""}
            error={contactUserNameInvalid}
            helperText={
              contactUserNameInvalid &&
              validateField("Username", contact?.username ?? "").validationErrorText
            }
            onChange={(ev) => handleValueChange(ev, "username")}
            onBlur={() => {
              const invalidField = validateField("Username", contact?.username ?? "").isInvalid;

              if (invalidField) {
                setContactUsernameInvalid(true);
                return;
              }

              setContactUsernameInvalid(false);
            }}
          />
        </Grid>
        <Grid container xs={4}>
          <TextField
            id="secondary-contact-designation"
            disabled={!edit}
            label="Designation"
            sx={{ width: "100%" }}
            size="small"
            fullWidth
            value={contact?.designation || ""}
            error={contactDesignationInvalid}
            helperText={
              contactDesignationInvalid &&
              validateField("Designation", contact?.designation ?? "").validationErrorText
            }
            onChange={(ev) => {
              const newVal = { ...contact, designation: ev.target.value };
              setContact(newVal);
              performDirtyCheck(newVal);
            }}
            onBlur={() => {
              const invalidField = validateField(
                "Designation",
                contact?.designation ?? ""
              ).isInvalid;

              if (invalidField) {
                setContactDesignationInvalid(true);
                return;
              }

              setContactDesignationInvalid(false);
            }}
          />
        </Grid>
      </Grid>
      <Grid container className="g-container">
        <Grid container xs={4}>
          <TextField
            type="text"
            disabled={!edit}
            id="secondary-contact-mobile"
            label="Contact Number"
            size="small"
            sx={{ width: "98%", marginRight: "2%" }}
            value={contact?.mobile}
            error={contactMobileInvalid}
            helperText={
              contactMobileInvalid &&
              validateField("Mobile", contact?.mobile ?? "").validationErrorText
            }
            onChange={(ev) => handleValueChange(ev, "mobile")}
            onBlur={() => {
              const invalidField = validateField("Mobile", contact?.mobile ?? "").isInvalid;

              if (invalidField) {
                setContactMobileInvalid(true);
                return;
              }

              setContactMobileInvalid(false);
            }}
          />
        </Grid>
        <Grid container xs={4}>
          <TextField
            type="text"
            disabled={!edit}
            sx={{ width: "98%", marginRight: "2%" }}
            id="secondary-contact-email"
            label="Email"
            size="small"
            value={contact?.email}
            error={contactEmailInvalid}
            helperText={
              contactEmailInvalid &&
              validateField("Email", contact?.email ?? "").validationErrorText
            }
            onChange={(ev) => handleValueChange(ev, "email")}
            onBlur={() => {
              const invalidField = validateField("Email", contact?.email ?? "").isInvalid;

              if (invalidField) {
                setContactEmailInvalid(true);
                return;
              }

              setContactEmailInvalid(false);
            }}
          />
        </Grid>
        <Grid container xs={4}>
          <TextField
            type="text"
            disabled={!edit}
            id="secondary-contact-id"
            label="ID Number"
            sx={{ width: "100%" }}
            size="small"
            value={contact?.idNumber}
            error={contactIdNumberInvalid}
            helperText={
              contactIdNumberInvalid &&
              validateField("ID Number", contact?.idNumber ?? "").validationErrorText
            }
            onChange={(ev) => handleValueChange(ev, "idNumber")}
            onBlur={() => {
              const invalidField = validateField("ID Number", contact?.idNumber ?? "").isInvalid;

              if (invalidField) {
                setContactIdNumberInvalid(true);
                return;
              }

              setContactIdNumberInvalid(false);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SecondaryContact;
