/* eslint-disable react/no-unstable-nested-components */
// eslint-disable-next-line import/no-extraneous-dependencies
import { color } from "d3-color";
// eslint-disable-next-line import/no-extraneous-dependencies
import { interpolateRgb } from "d3-interpolate";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import LiquidFillGauge from "react-liquid-gauge";


const Liquid = ({ Number }) => {
  const [gaugeValue, setGaugeValue] = useState(Number);

  const startColor = "#6495ed"; // cornflowerblue
  const endColor = "#000080"; // navy blue


  const radius1 = 20;
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = interpolate(gaugeValue / 100);
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  useEffect(() => {
    setGaugeValue(Number);
  }, [Number, gaugeValue]);

  return (
    <div>
      <LiquidFillGauge
        style={{ margin: "0 auto" }}
        width={radius1 * 2}
        height={radius1 * 2}
        value={gaugeValue}
        percent="%"
        textSize={1}
        textOffsetX={0}
        textOffsetY={0}
        textRenderer={(props) => {
          const value = Math.round(props.value);
          const radius = Math.min(props.height / 2, props.width / 2);
          const textPixels = (props.textSize * radius / 1.5);
          const valueStyle = {
            fontSize: textPixels,
            fontWeight: 600,
            paddingTop: 1,

          };


          return (
            <tspan>
              <tspan className="value" style={valueStyle}>{value}</tspan>
            </tspan>
          );
        }}
        riseAnimation
        waveAnimation
        waveFrequency={2}
        waveAmplitude={1}
        gradient
        gradientStops={gradientStops}
        circleStyle={{
          fill: fillColor,
        }}
        waveStyle={{
          fill: fillColor,
        }}
        textStyle={{
          fill: "#444",
          marginTop: "10px",
          fontFamily: "Arial",
        }}
        waveTextStyle={{
          fill: "#fff",
          fontFamily: "Arial",
        }}
      />
    </div>
  );
}

export default Liquid;

