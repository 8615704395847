import useValidation from "./useValidation";

const useGoodsAndServiceValidation = (selection: number[], description: string) => {

  const [validateFields] = useValidation(
    [
      {
        displayName: "Products/Services",
        fieldName: "product/services",
        fieldValue: selection.length,
        customValidation: [
          {
            hasError: selection.length > 3,
            errorMessage: "The maximum allowable number of products/services is 3.",
          },
          {
            hasError: selection.length <= 0,
            errorMessage: "Choose a minimum of 1 to 3 products/services provided by our company",
          },
        ],
      },
      {
        displayName: "Description",
        fieldName: "description",
        fieldValue: description,
        required: true,
        minLength: 10,
        maxLength: 1000,
      },
    ]);
  return validateFields
}

export default useGoodsAndServiceValidation;