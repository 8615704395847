import { Box, Stepper, Step, StepButton, Skeleton } from "@mui/material";



const OnBoardingStepperSkeleton = () => (
  <Box sx={{ width: "200px" }}>
    <Stepper activeStep={0} orientation="vertical" sx={{ width: "100%" }}>
      {[...Array(8)].map((_, index) => (

        <Step disabled={false} onClick={() => {
          // Handle click event here
        }}>
          <StepButton
            sx={{ fontWeight: 900 }}
            icon={<Skeleton variant="circular" width={24} height={24} />} // Replace with your step state icon
            key={index}
          >
            <Skeleton variant="text" sx={{ width: "20vh" }} height={24} /> {/* Replace with your step label */}
          </StepButton>
        </Step>

      ))}
    </Stepper>
  </Box>
)


export default OnBoardingStepperSkeleton;