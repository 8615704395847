import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyProfileSettingsByCompanyId } from "../../../http/Company/CompanyProfileSettingsApi";
import { CompanyProfileSettings } from "../../../http/Company/Models/CompanyProfileSettings";

const useCompanyProfileSettings = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyProfileSettings, companyId],
    queryFn: async () => (await GetCompanyProfileSettingsByCompanyId(companyId)).data as CompanyProfileSettings,
    staleTime: 600000,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyProfileSettings;