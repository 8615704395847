import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetSupplierLocations } from "../../Company/Dashboard";
import { SupplierLocationsModel } from "../../Company/Models/SupplierLocationsModel";
import supplierLocationsSlice from "./DashboardSlice";


export const SupplierLocationsActions = supplierLocationsSlice.actions;

export const fetchSupplierLocations = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<SupplierLocationsModel> = (await GetSupplierLocations(companyId)).data;
  dispatch(SupplierLocationsActions.setSupplierLocationsState(apiResponse));
}