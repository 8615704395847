/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BankNameModel } from "../../Company/Models/BankNameModel";


export interface BankNameListState {
  bankNameList: Array<BankNameModel>;
}

const bankNameListSlice = createSlice({
  name: "bankNameList",
  initialState: [] as Array<BankNameModel>,
  reducers: {
    setBankNameListState: (
      state,
      action: PayloadAction<Array<BankNameModel>>
    ) => action.payload,
  },
});

export const selectBankNameList = (state: RootState) => state.bankNameList;
export default bankNameListSlice;
