/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum RedemptionType {
  Onetime = 0,
  Limited = 1,
  Forever = 2,
}

export default RedemptionType;

export const redemptionTypeToString = (value: RedemptionType) => {
  switch (value) {
    case RedemptionType.Onetime:
      return "One time"
    case RedemptionType.Limited:
      return "Limited"
    case RedemptionType.Forever:
      return "Forever"
    default:
      return "Unknown";
  }
};