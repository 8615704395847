/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum DocumentType {
  Unknown = 0,
  CertificateOfIncorporation = 1,
  ProofOfResidence = 2,
  CompanyProfile = 3,
  CompanyLetterhead = 4,
  ProofOfBanking = 5,
  CertifiedIdentityDocument = 6,
  TaxCertificate = 7,
  VATCertificate = 8,
  BBBEECertificate = 9,
  SupplierList = 10,
  ClientList = 11,
}

export default DocumentType;

export const documentTypeToString = (value: DocumentType) => {
  switch (value) {
    case DocumentType.CertificateOfIncorporation:
      return "Certificate of Incorporation";
    case DocumentType.ProofOfResidence:
      return "Proof of Residence";
    case DocumentType.CompanyProfile:
      return "Company Profile";
    case DocumentType.CompanyLetterhead:
      return "Company Letterhead";
    case DocumentType.ProofOfBanking:
      return "Proof of Banking";
    case DocumentType.CertifiedIdentityDocument:
      return "Certified Identity Document";
    case DocumentType.TaxCertificate:
      return "Tax Certificate";
    case DocumentType.VATCertificate:
      return "VAT Certificate";
    case DocumentType.BBBEECertificate:
      return "B-BBEE Certificate/Affidavit";
    case DocumentType.SupplierList:
      return "Supplier List";
    case DocumentType.ClientList:
      return "Client List";
    default:
      return "Unknown Document Type";
  }
};
