/* eslint-disable react/require-default-props */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface BasicDialogProps {
  dialogTitle: string;
  dialogText: React.ReactNode | string;
  openCloseSwitch: boolean;
  okButtonText?: string;
  secondaryButtonText?: string;
  closeButtonText?: string;
  hideOkButton?: boolean;
  hideSecondaryButton?: boolean;
  onOkClick?: () => void;
  onSecondaryClick?: () => void;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}




const BasicDialog = ({
  dialogTitle,
  dialogText,
  openCloseSwitch,
  okButtonText = "",
  closeButtonText = "Close",
  secondaryButtonText = "",
  hideOkButton = false,
  hideSecondaryButton = false,
  onOkClick,
  onSecondaryClick,
  onClose,
}: BasicDialogProps) => (

  <BootstrapDialog
    aria-labelledby="customized-dialog-title"
    open={openCloseSwitch}
    onClose={onClose}
  >
    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      {dialogTitle}
    </BootstrapDialogTitle>
    <DialogContent dividers>
      {
        React.isValidElement(dialogText) ? dialogText :
          <Typography gutterBottom>
            {dialogText}
          </Typography>
      }

    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>{closeButtonText}</Button>
      {!hideSecondaryButton &&
        <Button variant="contained" onClick={onSecondaryClick}>{secondaryButtonText}</Button>
      }
      {!hideOkButton &&
        <Button variant="contained" onClick={onOkClick}>{okButtonText}</Button>
      }

    </DialogActions>
  </BootstrapDialog>

);

export default BasicDialog;