import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import CompanyShareholdersSlice from "./CompanyShareholdersSlice";
import CompanyShareholdersService from "../../Company/CompanyShareholdersService";
import { CompanyShareholdersModel } from "../Models/CompanyShareholders";


export const companyShareholdersActions = CompanyShareholdersSlice.actions;

export const fetchCompanyShareholders =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyShareholdersModel[] = await CompanyShareholdersService.getCompanyShareholdersModel(companyId, staged);
      dispatch(companyShareholdersActions.setAllCompanyExtendedDetail(response));
    };