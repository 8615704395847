import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, {
  downloadFileAsync,
  makePostFormRequestAsync,
  makePostRequestAsync,
} from "../Helpers/httpRequestHelpers";
import { DocumentTypeModel } from "../Redux/Models/DocumentTypeModel";
import { DocumentDtoModel } from "./Models/DocumentDtoModel";
import { DocumentModel } from "./Models/DocumentModel";

import { AddClientDocTypeConnectionDto } from "../NetworkAndConnect/Dtos/AddClientDocTypeConnectionDto";
import { DeleteClientDocTypeConnectionDto } from "../NetworkAndConnect/Dtos/DeleteClientDocTypeConnectionDto";

import DocumentType from "../../util/enums/DocumentType";
import { AddClientDocTypesConnectionDto } from "../NetworkAndConnect/Dtos/AddClientDocTypesConnectionDto";
import { ReadRepDocumentDto } from "./Models/ReadRepDocumentDto";
import formatDate from "../../util/formatter/formatDate";

const apiScopes = protectedResources.documentManagementWebApi.scopes;
const baseUrl = protectedResources.documentManagementWebApi.endpoint;

export const getRequiredDocumentTypes = async () => {
  const url = `${baseUrl}/api/Documentation/GetAllRequiredDocTypes`;
  return makeGetRequestAsync<Array<DocumentTypeModel>>(apiScopes, url);
};

export const getCompanyRequiredDocumentTypes = async () => {
  const url = `${baseUrl}/api/Documentation/GetAllCompanyRequiredDocTypes`;
  return makeGetRequestAsync<Array<DocumentTypeModel>>(apiScopes, url);
}

export const getCompanyDocumentAsync = async (documentId: string) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocument/${documentId}`;
  return makeGetRequestAsync<DocumentModel>(apiScopes, url);
};

export const getCompanyDocumentsAsync = async (companyId: number) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocuments/${companyId}`;
  return makeGetRequestAsync<Array<DocumentModel>>(apiScopes, url);
};

export const getCompanyDocumentByTypeIdAsync = async (companyId: number, documentTypeId: number) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocumentByType/${companyId}/${documentTypeId}`;
  return (await makeGetRequestAsync<ReadRepDocumentDto>(apiScopes, url)).data;
};

export const getCompanyDocumentWithTypesByIDAsync = async (documentId: string) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocument/${documentId}`;
  return makeGetRequestAsync<DocumentModel>(apiScopes, url);
};

export const getCompanyDocumentsWithTypesAsync = async (companyId: number) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocumentsWithTypes/${companyId}`;
  return makeGetRequestAsync<Array<DocumentDtoModel>>(apiScopes, url);
};
export const getCompanyDocumentsWithTypesOnBoardingAsync = async (companyId: number, types: Array<number>) => {
  const url = `${baseUrl}/api/Documentation/GetCompanyDocumentsWithOnboardingTypes/${companyId}`;
  return (await makePostRequestAsync(apiScopes, url, types)).data as DocumentDtoModel[];
};

export const addNewCompanyDocumentAsync = async (
  companyId: number,
  documentExpires: boolean,
  expiryDate: Date,
  typeId: number,
  userId: string,
  documentFiles: File[],
  workCentreId?: number,
  documentTypeEnum: number = DocumentType.Unknown

) => {
  const formData = new FormData();
  formData.append("companyId", companyId.toString());
  formData.append("documentExpires", documentExpires ? "true" : "false");

  if (documentExpires)
    formData.append(
      "expiryDate",
      formatDate(expiryDate)
    );

  formData.append("typeId", typeId.toString());
  formData.append("userId", userId.toString());
  formData.append("workCentreId", workCentreId?.toString() || "");
  formData.append("documentTypeEnum", documentTypeEnum.toString());
  formData.append("file", documentFiles[0]);

  const url = `${baseUrl}/api/Documentation/AddNewDocument`;

  return makePostFormRequestAsync(apiScopes, url, formData);
};


export const addNewCompanyShareholderDocumentAsync = async (
  companyId: number,
  documentExpires: boolean,
  expiryDate: Date,
  typeId: number,
  userId: string,
  documentFiles: File[],
  shareholderId: number,
  id: string,
  documentTypeEnum: number = DocumentType.Unknown

) => {
  const formData = new FormData();
  formData.append("companyId", companyId.toString());
  formData.append("documentExpires", documentExpires ? "true" : "false");

  if (documentExpires)
    formData.append(
      "expiryDate",
      formatDate(expiryDate)
    );

  formData.append("typeId", typeId.toString());
  formData.append("userId", userId.toString());
  formData.append("shareholderId", shareholderId?.toString() || "");
  formData.append("id", id || "");
  formData.append("documentTypeEnum", documentTypeEnum.toString());
  formData.append("file", documentFiles[0]);

  const url = `${baseUrl}/api/Documentation/AddNewShareholderDocument`;

  return (await makePostFormRequestAsync(apiScopes, url, formData)).data as string;
};

export const updateCompanyDocumentAsync = async (
  documentId: string,
  userId: string,
  documentFile: FileList
) => {
  const formData = new FormData();

  formData.append("documentId", documentId.toString());
  formData.append("userId", userId.toString());
  formData.append("file", documentFile[0]);

  const url = `${baseUrl}/api/Documentation/UpdateExistingDocument`;

  return makePostFormRequestAsync(apiScopes, url, formData);
};

export const deleteCompanyDocumentAsync = async (
  companyId: number,
  documentId: string,
  userId: string
) => {
  const url = `${baseUrl}/api/Documentation/DeleteDocument`;

  const data = {
    companyId,
    documentId,
    userId,
  };

  return makePostRequestAsync(apiScopes, url, data);
};

export const addConnectionClientDocumentType = async (docType: AddClientDocTypeConnectionDto) => {
  const url = `${baseUrl}/api/Documentation/AddConnectionClientDocumentType`;
  await makePostRequestAsync(apiScopes, url, docType)
};

export const addMultipleConnectionClientDocTypes = async (docType: AddClientDocTypesConnectionDto) => {
  const url = `${baseUrl}/api/Documentation/AddMultipleConnectionClientDocumentTypes`;
  await makePostRequestAsync(apiScopes, url, docType)
};

export const deleteConnectionClientDocumentType = async (docType: DeleteClientDocTypeConnectionDto) => {
  const url = `${baseUrl}/api/Documentation/DeleteConnectionClientDocumentType`;
  await makePostFormRequestAsync(apiScopes, url, docType);
}

/*
 *  download supplier upload template
 */
export async function downloadSupplierUploadTemplate() {
  const downloadUrl = `${baseUrl}/api/documentation/SupplierUploadTemplate`;
  await downloadFileAsync(apiScopes, downloadUrl);
}

/*
 *  download supplier upload template for platfrom data admi
 */
export async function downloadSupplierUploadDataPlatormAdminTemplate() {
  const downloadUrl = `${baseUrl}/api/documentation/SupplierUploadTemplate/platformdatatemplate`;
  await downloadFileAsync(apiScopes, downloadUrl);
}