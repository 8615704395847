import { useCallback, useEffect, useState } from "react";
import { DashboardSupplierStatusSummary } from "../../http/Company/Models/DashboardSupplierStatusSummary";
import {
  GetCompanySupplierStatusSummary,
  GetExistingAndPotentialSupplierCounts,
  GetInsightsSupplierBySegmentSummary,
} from "../../http/Company/Dashboard";
import SupplierStatusChart from "./Chart/Insights/SupplierStatusChart";
import { DashboardInsightsSupplierBySegmentSummary } from "../../http/Company/Models/InsightsSuppliersBySegmentsSummary";
import SupplierByProdSegChart from "./Chart/Insights/SupplierByProdSegChart";
import InLineSpinner from "../LoadingSpinners/InlineSpinner";
import { ExistingAndPotentialSupplierCounts } from "../../http/Company/Models/ExistingAndPotentialSupplierCounts";

// NEW UI

const Insight = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companySupplierStatusSummary, setCompanySupplierStatusSummary] =
    useState<DashboardSupplierStatusSummary>({
      totalSuppliers: 0,
      regulatoryCompliantSupplierCount: 0,
      bbbeeCompliantSupplierCount: 0,
      blackOwnedSupplierCount: 0,
      blackWomenOwnedSupplierCount: 0,
      regulatoryCompliantSupplierPercentage: 0.0,
      bbbeeCompliantSupplierPercentage: 0.0,
      blackOwnedSupplierPercentage: 0.0,
      blackWomenOwnedSupplierPercentage: 0.0,
    } as DashboardSupplierStatusSummary);

  const [insightsSuppliersSegmentsSummary, setInsightsSupplierSegmentsSummary] = useState<
    Array<DashboardInsightsSupplierBySegmentSummary>
  >([] as Array<DashboardInsightsSupplierBySegmentSummary>);

  const [existingAndPotentialSupplierCounts, setExistingAndPotentialSupplierCounts] =
    useState<ExistingAndPotentialSupplierCounts>({
      totalExistingSuppliers: 0,
      totalExistingSuppliersThisWeek: 0,
      totalExistingPercentageThisWeek: 0.0,
      totalPotentialSuppliers: 0,
      totalPotentialSuppliersThisWeek: 0,
      totalPotentialPercentageThisWeek: 0.0,
    } as ExistingAndPotentialSupplierCounts);

  const getCompanySupplierStatusSummary = async () => {
    const apiResults: DashboardSupplierStatusSummary = await GetCompanySupplierStatusSummary();
    setCompanySupplierStatusSummary(apiResults);
  }

  const getInsightsSuppliersByProdSegSummary = async () => {
    const apiResults: Array<DashboardInsightsSupplierBySegmentSummary> = await GetInsightsSupplierBySegmentSummary();
    setInsightsSupplierSegmentsSummary(apiResults);
  }

  const getExistingAndPotentialSupplierCount = async () => {
    const apiResults: ExistingAndPotentialSupplierCounts = await GetExistingAndPotentialSupplierCounts();
    setExistingAndPotentialSupplierCounts(apiResults);
  }

  const renderSupplierStatusChart = useCallback((isDataLoading: boolean) => {
    if (isDataLoading) {
      return (
        <div
          style={{
            minHeight: "330px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InLineSpinner message="Loading " />
        </div>
      );
    }

    return (
      <>
        <h2>Suppliers By Status</h2>
        <br />
        <SupplierStatusChart chartData={companySupplierStatusSummary} />
      </>
    );
  }, [companySupplierStatusSummary]);

  const renderProductSegmentChart = useCallback((isDataLoading: boolean) => {
    if (isDataLoading) {
      return (
        <div
          style={{
            minHeight: "330px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InLineSpinner message="Loading Product Segment Stats..." />
        </div>
      );
    }

    return (
      <>
        <h2>Product Segments</h2>
        <br />
        <SupplierByProdSegChart chartData={insightsSuppliersSegmentsSummary} />
      </>
    );
  }, [insightsSuppliersSegmentsSummary]);

  const renderTotalCards = useCallback(
    (isDataLoading: boolean) => {
      if (isDataLoading) {
        return (
          <div
            style={{
              minHeight: "330px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InLineSpinner message="Loading Stats..." />
          </div>
        );
      }

      return (
        <>
          <div className="sideBlockInsight sideBlockInsightTop" id="totalExistingSupplierCard">
            <h3>Total Existing Suppliers</h3>
            <h1>{existingAndPotentialSupplierCounts.totalExistingSuppliers}</h1>
            <h3>{`${existingAndPotentialSupplierCounts.totalExistingPercentageThisWeek.toFixed(
              2
            )}% from the last week`}</h3>
          </div>
          <div className="sideBlockInsight" id="totalPotentialSupplierCard">
            <h3>Total Potential Suppliers</h3>
            <h1>{existingAndPotentialSupplierCounts.totalPotentialSuppliers}</h1>
            <h3>{`${existingAndPotentialSupplierCounts.totalPotentialPercentageThisWeek.toFixed(
              2
            )}% from the last week`}</h3>
          </div>
        </>
      );
    },
    [
      existingAndPotentialSupplierCounts.totalExistingSuppliers,
      existingAndPotentialSupplierCounts.totalExistingPercentageThisWeek,
      existingAndPotentialSupplierCounts.totalPotentialSuppliers,
      existingAndPotentialSupplierCounts.totalPotentialPercentageThisWeek,
    ]
  );

  const loadData = useCallback(async () => {
    await getCompanySupplierStatusSummary();
    await getInsightsSuppliersByProdSegSummary();
    await getExistingAndPotentialSupplierCount();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [loadData]);

  return (
    <div style={{ width: "100%", height: "420px", padding: 15 }}>
      <div style={{ float: "left", width: "80%", height: "420px" }}>
        <h1>MY SUPPLIER INSIGHTS</h1>
        <br />
        <div style={{ width: "40%", float: "left", marginRight: "75px" }}>
          {renderSupplierStatusChart(isLoading)}
        </div>
        <div style={{ width: "40%", float: "left" }}>{renderProductSegmentChart(isLoading)}</div>
      </div>
      <div style={{ width: "20%", float: "left" }}>{renderTotalCards(isLoading)}</div>
    </div>
  );
}




export default Insight;