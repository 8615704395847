import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "../http/Helpers/httpRequestHelpers";
import { UserWorkCentreModel } from "./Redux/Models/UserWorkCentreModel";


const apiScopes = protectedResources.notificationsWebApi.scopes;
const baseUrl = protectedResources.notificationsWebApi.endpoint;


export default {

  async GetWorkCentreList(accountId: string) {
    const url = `${baseUrl}/api/Notifications/UserWorkCentre?accountId=${accountId}`;
    const result = (await makeGetRequestAsync<UserWorkCentreModel[]>(apiScopes, url)).data;
    return result;
  },
  async Update(userWorkCentre: UserWorkCentreModel) {
    const url = `${baseUrl}/api/Notifications/UserWorkCentre`;
    return (await makePatchRequestAsync(apiScopes, url, userWorkCentre)).data;
  },
  async Add(userWorkCentre: UserWorkCentreModel) {
    const url = `${baseUrl}/api/Notifications/UserWorkCentre`;
    return (await makePostRequestAsync(apiScopes, url, userWorkCentre)).data;
  },

}
