import axios from "axios";
import { permissions } from "./Permissions";

const baseurl = `${process.env.REACT_APP_CONFIGURATION_WEBAPI_ENDPOINT}`;

const AddPermission = async (permissionEntry: permissions) => {

  const url = `${baseurl}/api/UserManagement/Permissions/AddPermission`;
  const result = await axios.post(url, permissionEntry);
  return result;
}

export default AddPermission;