import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { BeeRatingLookupListModel } from "../Redux/Models/BeeRatingLookupListModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

// eslint-disable-next-line import/prefer-default-export
export const GetAllBeeRatingLookupLists = async () => {
  const url = `${baseUrl}/api/Company/Bee/GetAllBeeRatingLookupLists`;
  const result = await makeGetRequestAsync<BeeRatingLookupListModel>(
    apiScopes,
    url
  );
  return result.data;
};