import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetRuleEvaluation } from "../../../../http/NetworkAndConnect/Netconn";
import { ConnectionRuleModel } from "../../../../http/NetworkAndConnect/Models/ConnectionRuleModel";
import { ConnectionRuleResult } from "../../../Connect/ConnectRuleDialog/ConnectionRuleResult";
import { checkAgainstRulesetAttributes, checkAgainstRulesetDocuments } from "../../../Connect/ConnectRuleDialog/ConnectionRuleLogic";


const Redirect = ({ evaluatingCompanyId, evaluatedCompanyId, refresh }: { evaluatingCompanyId: number, evaluatedCompanyId: number, refresh: boolean }) => {


  const [, setEvaluationResult] = useState<ConnectionRuleModel>({} as ConnectionRuleModel);
  const [resultArray, setResultArray] = useState<ConnectionRuleResult>({} as ConnectionRuleResult);
  const navigation = useNavigate();

  const fetchEvaluation = async (evaluatingId: number, evaluatedId: number) => {
    const result = await GetRuleEvaluation(evaluatingId, evaluatedId);
    setEvaluationResult(result);
    return result;
  };
  const [, setRulePassPercentage] = useState<number>(0);
  const calculateSuccessPercentage = (
    successfulAttrs: number,
    successfulDocuments: number,
    totalRules: number
  ) => ((successfulAttrs + successfulDocuments) / (totalRules)) * 100

  const analyseEvaluationModel = (evaluationModel: ConnectionRuleModel) => {
    // Get the entries from the eval model.
    const evalObjectProperties = Object.keys(evaluationModel.evaluation);

    // Generate a list of attributes and documents from the passed in ruleset metadata.
    const evalAttributeNames = evaluationModel.attributes.map((model) => model.columnName);
    const evalDocumentNames = evaluationModel.documents.map((model) => model.name);

    const [successAttr, failedAttr] = checkAgainstRulesetAttributes(
      evaluationModel,
      evalAttributeNames,
      evalDocumentNames,
      evalObjectProperties
    );
    const [successDocs, failedDocs] = checkAgainstRulesetDocuments(
      evaluationModel,
      evalAttributeNames,
      evalDocumentNames,
      evalObjectProperties
    );


    const successPercentage = calculateSuccessPercentage(
      successAttr.length,
      successDocs.length,
      evaluationModel.attributes.length + evaluationModel.documents.length
    );

    setRulePassPercentage(successPercentage); // Nuke if needed.

    const hasFailures = (failedAttr.length > 0 || failedDocs.length > 0)

    const returnObj = {} as ConnectionRuleResult;
    returnObj.hasFailures = hasFailures;
    returnObj.percentage = successPercentage;
    return returnObj
  };

  const process = async () => {
    const apiResult = await fetchEvaluation(evaluatingCompanyId, evaluatedCompanyId);
    setResultArray(analyseEvaluationModel(apiResult));
  }


  useEffect(() => {

    if (resultArray.percentage === 100) {

      navigation("/network/request");

    }
    else {
      process();
    }

  }, [evaluatedCompanyId, evaluatingCompanyId, resultArray.percentage, navigation, refresh])


  return (
    <div />
  )

}

export default Redirect;


