import { DataGridPro, DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Chip, Divider, Grid, IconButton, Paper, Stack, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import formatDate from "../../util/formatter/formatDate";
import { DataManagementPartnerModel } from "../../http/Redux/Models/DataManagementPartnerModel";
import { useAppSelector } from "../../app/hooks";
import { getAllDataManagementPartners } from "../../http/Redux/Store/DataManagementPartnerSlice";
import AddPartner from "../../components/DataMangementPartner/AddPartner";
import { ManagedCompaniesModel } from "../../http/Redux/Models/ManagedCompaniesModel";
import CompanyVerificationService from "../../http/CompanyVerificationService";
import AddClient from "../../components/DataMangementPartner/AddClient";


const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 
            0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 
            1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 
            4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 
            0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 
            3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 
            7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 
            2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 
            2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          // eslint-disable-next-line max-len
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
)


const DetailPanelContent = ({ row: rowProp }: { row: DataManagementPartnerModel }) => {
  const [managedCompanies, setManagedCompanies] = useState<ManagedCompaniesModel[]>([] as ManagedCompaniesModel[]);

  useEffect(() => {


    const loader = async () => {
      const list = await CompanyVerificationService.getManagedCompaniesByPartnerId(
        rowProp.companyId,
        false
      );

      setManagedCompanies(list);
    };

    loader();

  }, [])


  return (
    <Stack
      sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "#ECECEC" }}
      direction="column">
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <h3>Partner Clients</h3>
              <Divider />
            </Grid>

            <Grid xs={12}>
              {managedCompanies.length !== 0 ?
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                  {managedCompanies.map((x) =>

                    <Chip
                      key={`${x.companyId}_${x.id}`}
                      label={x.registeredName}
                      className="chip-colored"
                      size="small"
                    />
                  )}
                </Box>
                : "None"
              }
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  )
};


const Partner = () => {
  const partners: DataManagementPartnerModel[] = useAppSelector(getAllDataManagementPartners);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);
  const [isSelected, setIsSelected] = useState(0);
  const [isViewing, setIsViewing] = useState(true);

  const handleAddClient = (id: number) => {
    setIsAdding(false);
    setIsAddingClient(true);
    setIsViewing(false);
    setIsSelected(id);
  }

  const columns: GridColDef[] = [
    {
      field: "registeredName",
      headerName: "Registered Name",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 220,

    },
    {
      field: "providerSince",
      headerName: "Provider Since",
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => (
        <div>
          {formatDate(params.value)}
        </div>
      ),
    },
    {
      field: "companyId",
      headerName: "Actions",
      sortable: false,
      disableColumnMenu: true,
      width: 160,
      renderCell: (params) => (
        <div>
          <IconButton className="icons-dmp" sx={{ marginRight: 1 }} onClick={() => handleAddClient(params.value)}>
            <LibraryAddIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleAddPartner = () => {
    setIsAdding(true);
    setIsViewing(false);
  }

  const handleBack = () => {
    setIsAdding(false);
    setIsViewing(true);
    setIsAddingClient(false);
  }

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);

  return (

    <div style={{ height: 500, width: "100%" }}>
      {isViewing && (
        <>
          <Button sx={{ marginRight: 2, marginTop: 0.2, marginBottom: 2 }} disabled={isAdding} variant="contained" onClick={handleAddPartner}>Add Data Partner</Button>
          <DataGridPro
            sx={{ height: "400px" }}
            rows={partners ?? []}
            columns={columns}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            getRowId={(row: DataManagementPartnerModel) => row.companyId} />
        </>
      )}
      {isAdding && (
        <AddPartner back={handleBack} />
      )}
      {isAddingClient && (
        <AddClient back={handleBack} partner={isSelected} />
      )}
    </div>


  )
}


export default Partner;