import { EditRoleMessage } from "./Messages/EditRoleMessage";
import { UserMessage } from "./Messages/UserMessage";
import { ModulePermissions } from "./Messages/Module";
import { CreateRoleMessage } from "./Messages/CreateRoleMessage";
import { RoleMessage } from "./Messages/RoleMessage";
import makeGetRequestAsync,
{ makeDeleteRequestNoBodyAsync, makeGetRequestAsyncNoAuth,
  makePUTRequestAsync, makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";

import { protectedResources } from "../../authConfig";
import { UserInvitationMessage } from "./Messages/UserInvitationMessage";
import { Permission } from "./Messages/Permission";
import { UpdateCompanyUserRolesMessage } from "./Messages/UpdateCompanyUserMessage";
import { UpdateUserStatusMessage } from "./Messages/UpdateUserStatusMessage";

const baseCompanyUrl = protectedResources.companyApi.endpoint;
const apiCompanyScopes = protectedResources.companyApi.scopes;

const SendCompanyUserInvitation = async (userInvitationMessage: UserInvitationMessage) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/UserInvitation`;
  const result = await makePostRequestAsync<UserInvitationMessage, UserInvitationMessage>(
    apiCompanyScopes, url, userInvitationMessage);
  return result.data;
}

export default SendCompanyUserInvitation;

export const GetCompanyUserInvites = async (companyId: number) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/UserInvitations?companyId=${companyId}`;
  const result = await makeGetRequestAsync<UserInvitationMessage[]>(apiCompanyScopes, url);
  return result.data;
}

export const GetUserInvitations = async (companyId: number) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/UserInvitations?companyId=${companyId}`;
  const result = await makeGetRequestAsyncNoAuth<UserInvitationMessage[]>(url);
  return result.data;
}

export const UpdateUserStatus = async (userId : number, isActive: boolean) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/UpdateUserStatus`;
  await makePUTRequestAsync<null, UpdateUserStatusMessage>(apiCompanyScopes,url, {userId, isActive});
}

export const ResendUserInvite = async (userInvitationId: number) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/ResendUserInvite/${userInvitationId}`;
  await makePostRequestAsync<null, null>(apiCompanyScopes,url);
}

export const AcceptUserInviteAsync = async (userInvitationToken: string) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/AcceptUserInvitation`;
  await makePUTRequestAsync<void, unknown>(apiCompanyScopes,url,{userInvitationToken, isAccepted: true});
}

// user management api call begin here
const baseUrl = `${protectedResources.userManagementWebApi.endpoint}/api/UserManagement`;
const scopes = protectedResources.userManagementWebApi.scopes;

export const GetCompanyRoles = async (companyId: number) => {
  const url = `${baseUrl}/roles/company/${companyId}`;
  const result = await makeGetRequestAsync<RoleMessage[]>(scopes, url);
  return result.data;
}

export const CreateNewRoleAsync = async (newRole: CreateRoleMessage) => {
  const url = `${baseUrl}/roles`;
  const result = await makePostRequestAsync<RoleMessage, CreateRoleMessage>(scopes, url, newRole);
  return result.data;
}

export const GetCompanyUsers = async () => {
  const url = `${baseUrl}/User/company`;
  const result = await makeGetRequestAsync<UserMessage[]>(scopes, url);
  return result.data;
}

export const GetCompanyUsersWithId = async (companyId: number) => {
  const url = `${baseUrl}/User/company/${companyId}`;
  const result = await makeGetRequestAsync<UserMessage[]>(scopes, url);
  return result.data;
}

export const GetPermissionsByModule = async () => {
  const url = `${baseUrl}/Permissions/ByModule`;
  const result = await makeGetRequestAsync<ModulePermissions[]>(scopes, url);
  return result.data;
}

export const GetCurrentUserPermissions = async () => {
  const url = `${baseUrl}/Permissions/User`;
  const result = await makeGetRequestAsync<Permission[]>(scopes, url);
  return result.data;
}

export const UpdateCompanyUserRoles = async (updateMessage: UpdateCompanyUserRolesMessage) => {
  const url = `${baseUrl}/User/userRole`;
  await makePatchRequestAsync<void, UpdateCompanyUserRolesMessage>(scopes, url, updateMessage);
}

export const UpdateRoleAsync = async (updateMessage: EditRoleMessage) => {
  const url = `${baseUrl}/roles`;
  await makePatchRequestAsync<void, EditRoleMessage>(scopes, url, updateMessage);
}

export const DeleteRoleAsync = async (roleId: number) => {
  const url = `${baseUrl}/roles/${roleId}`;
  await makeDeleteRequestNoBodyAsync(scopes, url);
}

export const DeleteUserAsync = async (userId: number) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/DeleteUser/${userId}`;
  await makeDeleteRequestNoBodyAsync(scopes, url);
}

export const RemoveUserFromCompanyAsync = async (userId: number) => {
  const url = `${baseCompanyUrl}/api/Company/CompanyUser/RemoveUserFromCompany/${userId}`;
  await makePostRequestAsync(scopes, url);
}

export const SwitchToCompany = async (companyId: number) => {
  const url = `${baseUrl}/companySwitching`;
  await makePostRequestAsync(scopes, url, {companyId});
}

export const GetPlatformRoles = async () => {
  const url = `${baseUrl}/roles/platform`;
  const result = await makeGetRequestAsync<RoleMessage[]>(scopes, url);
  return result.data;
}

export const GetAssignedPlatformRoles = async (companyId: number) => {
  const url = `${baseUrl}/companyroles/platform/${companyId}`;
  const result = await makeGetRequestAsync<number[]>(scopes, url);
  return result.data;
}

export const AssignPlatformRoles = async (companyId: number, roleIdList: number[]) => {
  const data = { companyId, roleIdList };
  const url = `${baseUrl}/companyroles/platform`;
  await makePostRequestAsync(scopes, url, data);
}

