// eslint-disable-next-line no-shadow
enum ActivityStatus { // eslint-disable-line no-unused-vars
  Closed = 0,// eslint-disable-line no-unused-vars
  Show = 4,// eslint-disable-line no-unused-vars
  InActive = 5,// eslint-disable-line no-unused-vars
}

export default ActivityStatus;


