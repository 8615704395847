/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ConnectionModel } from "../../Connect/Models/ConnectionModel";
import { CompanyNetworkConnections } from "../../NetworkAndConnect/Models/CompanyNetworkConnections";

export interface CompanyNetworkConnectionsState {
  companyNetworkConnections: CompanyNetworkConnections
}

const networkSlice = createSlice({
  name: "companyNetworkConnections",
  initialState: {} as CompanyNetworkConnections,
  reducers: {
    setCompanyNetworkConnections(state, action: PayloadAction<CompanyNetworkConnections>) {
      // eslint-disable-next-line no-param-reassign
      state.clients = action.payload.clients;

      // eslint-disable-next-line no-param-reassign
      state.suppliers = action.payload.suppliers;

      // eslint-disable-next-line no-param-reassign
      state.followedCompanies = action.payload.followedCompanies;

      // eslint-disable-next-line no-param-reassign
      state.myFollowers = action.payload.myFollowers;
    },
    addCompanyClientConnection(state, action: PayloadAction<ConnectionModel>) {
      state.clients.push(action.payload);
    },
    addCompanySupplierConnection(state, action: PayloadAction<ConnectionModel>) {
      state.suppliers.push(action.payload);
    },
    addCompanyFollowedConnection(state, action: PayloadAction<number>) {
      state.followedCompanies.push(action.payload);
    },
    addCompanyFollowerConnection(state, action: PayloadAction<number>) {
      state.myFollowers.push(action.payload);
    },
    removeCompanyClientNetworkConnection(state, action: PayloadAction<number>) {
      const clients = state.clients;

      if (clients)
        state.clients = state.clients.filter((connection) => connection.id !== action.payload);
    },
    removeCompanySupplierNetworkConnection(state, action: PayloadAction<number>) {
      const suppliers = state.suppliers;

      if (suppliers)
        state.suppliers = state.suppliers.filter((connection) => connection.id !== action.payload);
    },
    removeCompanyFollowedNetworkConnection(state, action: PayloadAction<number>) {
      const followed = state.followedCompanies;

      if (followed)
        state.followedCompanies = state.followedCompanies.filter((connection) => connection !== action.payload);
    },
    removeCompanyFollowerNetworkConnection(state, action: PayloadAction<number>) {
      const follower = state.myFollowers;

      if (follower)
        state.myFollowers = state.myFollowers.filter((connection) => connection !== action.payload);
    },
  },
})

export default networkSlice;
export const getCompanyNetworkClients = (state: RootState) => state.companyNetworkConnections.clients;
export const getCompanyNetworkSuppliers = (state: RootState) => state.companyNetworkConnections.suppliers;
export const getMyFollowers = (state: RootState) => state.companyNetworkConnections.myFollowers;
export const getCompaniesImFollowing = (state: RootState) => state.companyNetworkConnections.followedCompanies;

export const getTotalCompanyNetworkClients = (state: RootState) => state.companyNetworkConnections.clients?.length;
export const getTotalCompanyNetworkSuppliers = (state: RootState) => state.companyNetworkConnections.suppliers?.length;
export const getTotalMyFollowers = (state: RootState) => state.companyNetworkConnections.myFollowers?.length;
export const getTotalCompaniesImFollowing = (state: RootState) => state.companyNetworkConnections.followedCompanies?.length;