/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum ContactType {
  None = 0,
  Primary = 1,
  Secondary = 2,
  GeneralCompany = 3,
}

export default ContactType;

export const contactTypeToString = (value: ContactType) => {
  switch (value) {
    case ContactType.None:
      return "None";
    case ContactType.Primary:
      return "Primary";
    case ContactType.Secondary:
      return "Secondary";
    case ContactType.GeneralCompany:
      return "GeneralCompany";
    default:
      return "Unknown Contact Type";
  }
};
