import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import workCenterJson from "./WorkCenterList.json";
import { WorkCenterListInterface } from "./interfaces/WorkCenterList";
import WorkCenterCard from "./WorkCenterCard";


const useStyles = makeStyles({
  papers: {
    width: "97%",
    height: "100%",
    marginTop: "2%",
    marginLeft: "10px",
    marginRight: "10px",
    padding: "10px",
  },
  paper: {
    width: "100%",
    height: "100%",
    marginTop: "2%",
    marginLeft: "10px",
    marginRight: "10px",
    padding: "10px",
  },
  card:
  {
    width: "20%",
    float: "left",
    marginRight: "40px",
    marginTop: "10px",
    marginLeft: "200px",
  },
  view:
  {
    width: "50%",
    float: "left",
    marginLeft: "left",
    border: "1px solid lightgray",
    marginTop: "10px",
  },

});


const WorkCenterView = () => {
  const workItems: WorkCenterListInterface[] = workCenterJson;
  const [rows] = useState<WorkCenterListInterface[]>(workItems);
  const { id } = useParams();
  const idNumber = Number(id);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();



  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (value: string) => () => {
    const template = `/companyProfile/${value}`;
    navigate(template);
  };

  return (
    <>
      
      <div className="heading-box"><h1>My Work Center</h1></div>
      <div className={classes.card}>
        <WorkCenterCard />
      </div>
      <div className={classes.view}>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table-heading">
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Task/Action</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.filter(x => x.itemId === idNumber)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={handleRowClick(row.companyName)}>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.task}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.date}</TableCell>
                  </TableRow>
                )
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </div>
    </>
  );
}

export default WorkCenterView;