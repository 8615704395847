import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { FieldModel } from "../../../http/Redux/Models/FieldModel";

// eslint-disable-next-line max-len, no-unused-vars, react/require-default-props
const FieldsDropDowns = ({ label = "", chooseField, allFields, edit }: { label: string, chooseField: (id: number) => void, allFields: Array<FieldModel>, edit?: string }) => {
  const [listValue, setListValues] = useState(edit);
  const [rows, setRows] = useState<Array<FieldModel>>([] as Array<FieldModel>);

  const handleChange = (event: string) => {
    setListValues(event);
    chooseField(Number(event));
  };
  useEffect(() => {
    setRows(allFields);
  }, []);
  return (
    <TextField
      label={label}
      select
      sx={{ width: "98%" }}
      size="small"
      id="demo-multiple-checkbox-dash"
      value={listValue}
      onChange={(ev) => handleChange(ev.target.value)}
    >
      {rows.map((name) => (
        <MenuItem key={name.id} value={name.id}>
          {name.name}
        </MenuItem>
      ))}
    </TextField>
  );


};

export default FieldsDropDowns;