/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum ServiceType {
  None = 0,
  Company = 1, 
  Audit = 2, 
  Mailer = 3,  
  DocumentManagement = 4,
  CompanyValidation = 5,
  Configuration = 6,
  Onboarding = 7,
  Billing = 8,
  Subscriptions = 9,
  Clean = 10,
  NetworkConnect = 11,
  CompanyVerification = 12,
  UserManagement = 13,
}

export default ServiceType;

export const serviceTypeToString = (value: ServiceType) => {
  switch (value) {
    case ServiceType.None:
      return "None"
    case ServiceType.Company:
      return "Company"
    case ServiceType.Audit:
      return "Audit"
    case ServiceType.Mailer:
      return "Mailer"
    case ServiceType.DocumentManagement:
      return "Document Management"
    case ServiceType.CompanyValidation:
      return "Company Validation"
    case ServiceType.Configuration:
      return "Configuration"
    case ServiceType.Onboarding:
      return "Onboarding"
    case ServiceType.Billing:
      return "Billing"
    case ServiceType.Subscriptions:
      return "Subscriptions"
    case ServiceType.Clean:
      return "Clean"
    case ServiceType.NetworkConnect:
      return "Network & Connections"
    case ServiceType.CompanyVerification:
      return "Company Verification"
    case ServiceType.UserManagement:
      return "User Management"
    default:
      return "Unknown Service";
  }
};
