import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import networkSlice from "./NetworkSlice";
import { GetCompanyNetwork, GetPotentialCompanyConnections } from "../../NetworkAndConnect/Netconn";
import { CompanyNetworkConnections } from "../../NetworkAndConnect/Models/CompanyNetworkConnections";
import connectSlice from "./ConnectSlice";
import { ConnectionModel } from "../../Connect/Models/ConnectionModel";

export const netconnNetworkConnectionActions = networkSlice.actions;
export const netconnPotentialConnectionActions = connectSlice.actions;

export const fetchCompanyNetwork =
  (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      if (companyId) {
        const response: CompanyNetworkConnections = await GetCompanyNetwork(companyId);
        dispatch(netconnNetworkConnectionActions.setCompanyNetworkConnections(response));
      }
    };

export const fetchCompanyPotentialConnections =
  (
    pageNumber: number,
    pageSize: number,
    companyId: number,
    searchString: string | undefined | null
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {

      const response: Array<ConnectionModel> = await GetPotentialCompanyConnections(
        pageNumber,
        pageSize,
        companyId,
        searchString
      );
      dispatch(netconnPotentialConnectionActions.setCompanyPotentialConnections(response));
    };
