import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { userPromptActivitySlice } from "./UserPromptActivitySlice"
import { RootState } from "../../../app/store"
import { UserPromptActivityModel } from "../Models/UserPromptActivityModel";
import UserPromptActivityService from "../../UserPromptActivityService";


export const userPromptActivityActions = userPromptActivitySlice.actions


export const fetchUserPromptActivity = (accountId: string, companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: UserPromptActivityModel[] = await UserPromptActivityService.UserPromptActivity(accountId, companyId).then(data => data);
  dispatch(userPromptActivityActions.setUserPromptActivity(response))
}

export const fetchUserPromptActivityStatus = (accountId: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: number = (await UserPromptActivityService.UserPromptActivityStatus(accountId));
  dispatch(userPromptActivityActions.setParticularUserPromptActivityStatus(response))
}
