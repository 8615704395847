import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import BusinessLocationTable from "./BusinessLocationTable";
import { useAppSelector } from "../../../../../app/hooks";
import { CompanyBusinessLocationModel } from "../../../../../http/Company/Models/CompanyBusinessLocationModel";
import {
  AddCompanyBusinessLocation,
  DeleteCompanyBusinessLocation,
  UpdateCompanyBusinessLocation,
} from "../../../../../http/Company/CompanyBusinessLocations";

interface CompanyBusinessLocationsProps {
  // eslint-disable-next-line react/require-default-props
  headerText?: string;
  companyId: number;
  // eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
  useStaged: boolean;
}
const CompanyBusinessLocations = (props: CompanyBusinessLocationsProps) => {
  const { headerText, companyId, useStaged } = props;

  const countries = useAppSelector((x) => x.countryList);
  const provinces = useAppSelector((x) => x.provinceList);

  const [expanded, setExpanded] = useState<string | false>("companyBusinessLocationPanel");

  const togglePanelExpansion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const addNewBusinessLocation = useCallback(async (location: CompanyBusinessLocationModel) => {
    try {
      await AddCompanyBusinessLocation(location, useStaged);
      toast.success("Company business location added successfully");
    } catch (error) {
      toast.error("An error occurred saving the location.");
    }
  }, []);

  const updateNewBusinessLocation = useCallback(async (location: CompanyBusinessLocationModel) => {
    try {
      await UpdateCompanyBusinessLocation(location, useStaged);
      toast.success("Company business location updated successfully");
    } catch (error) {
      toast.error("An error occurred saving the location updates.");
    }
  }, []);

  const deleteBusinessLocation = useCallback(async (companyLocationId: number) => {
    try {
      await DeleteCompanyBusinessLocation(companyLocationId, useStaged);
      toast.success("Company business location deleted successfully.");
    } catch (error) {
      toast.error("An error occurred deleting the location.");
    }
  }, []);

  return (
    <Accordion
      expanded={expanded === "companyBusinessLocationPanel"}
      onChange={togglePanelExpansion("companyBusinessLocationPanel")}
      className="Accord-Hide"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        className="Accord-heading"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="post__body">
          <div className="post__company__info">
            <Grid container>
              <Grid item xs={12}>
                <BusinessLocationTable
                  companyId={companyId}
                  staged={useStaged}
                  countryList={countries}
                  provinceList={provinces}
                  handleAddCallback={addNewBusinessLocation}
                  handleEditCallback={updateNewBusinessLocation}
                  handleDeleteCallback={deleteBusinessLocation}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyBusinessLocations;
