import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Stepper, Step, StepButton, Typography, Slide, useTheme, Box } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailIcon from "@mui/icons-material/Mail";
import BasePage from "../BasePage";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import { NotificationModel } from "../../http/Notifications/Models/NotificationModel";
import Notification from "../../components/Header/Notifications/Notification";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchRead, fetchUnread } from "../../http/Redux/Store/NotificationActions";
import { getReadNotifications, getUnreadNotifications } from "../../http/Redux/Store/NotificationsSlice";

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const GetStepState = (index: number) => {


  if (index === 1) {
    return <DraftsIcon sx={{ color: "#1976d2" }} />
  }

  return <MailIcon sx={{ color: "#1976d2" }} />

}

const Notifications = () => {
  const { accounts } = useMsal();
  const theme = useTheme();
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const accountId = accounts[0].homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const [value, setValue] = useState<number>(0);
  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const read = useAppSelector(getReadNotifications);
  const unread = useAppSelector(getUnreadNotifications);

  const [unreadNotifications, setUnreadNotifications] = useState<Array<NotificationModel>>(
    [] as Array<NotificationModel>
  );
  const [readNotifications, setReadNotifications] = useState<Array<NotificationModel>>(
    [] as Array<NotificationModel>
  );



  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
  };


  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const steps = [
    {
      id: 0,
      label: "Unread",
      total: unreadNotifications.length,
      description: "",
      timeout: 1000,
    },
    {
      id: 1,
      label: "Read",
      total: readNotifications.length,
      description: "",
      timeout: 1200,
    },
  ];

  const refreshNotifications = useCallback(async () => {

    dispatch(fetchUnread(accountId, false));
    dispatch(fetchRead(accountId, true));

    setUnreadNotifications(unread);
    setReadNotifications(read);

  }, [accountId, unread?.length, read?.length]);

  useEffect(() => {
    setBackdropVisible(true);
    setUnreadNotifications(unread);
    setReadNotifications(read);
    setBackdropVisible(false);
  }, []);


  return (
    <BasePage pageHeader="All Notifications">
      <LoadingBackdrop showBackdrop={backdropVisible} />
      <div className="config-header-box">

        <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className="config-sub-box">
          <div className="config-sub-box-left">
            <Stepper activeStep={value} orientation="vertical" sx={{ marginTop: "10px", marginBottom: "10px" }}>
              {steps.map((step) => (
                <Slide
                  direction="right"
                  in
                  mountOnEnter
                  unmountOnExit
                  style={{ transformOrigin: "0 0 0" }}
                  {...({ timeout: step.timeout })}
                >
                  <Step disabled={false} onClick={() => {
                    handleChangeTab(step.id)
                  }}>
                    <StepButton
                      disabled={false}
                      className="stepButton"
                      sx={{ fontWeight: 900, borderRight: step.id === value ? "2px solid #435cd2" : "" }}
                      // eslint-disable-next-line no-nested-ternary
                      icon={GetStepState(step.id)}
                      key={step.id}>
                      {step.label}
                    </StepButton>
                  </Step>
                </Slide>
              )
              )}
            </Stepper>
          </div>
          <div className="config-sub-box-right">
            <Box className="platformConfigurationBox">
              <div style={{ width: "100%", height: "80px", paddingLeft: "20px", paddingTop: "10px" }}>
                <Typography>
                  <h3 className="heading-sub">Notifications</h3>

                  <div style={{ float: "left", marginLeft: "-5px", color: " #435cd2" }}>
                    <KeyboardArrowRightIcon />
                  </div>
                  <div style={{ float: "left" }}>
                    <Typography>
                      {steps[value].label} ({steps[value].total})
                    </Typography>
                  </div>

                </Typography>
              </div>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Box sx={{ height: "480px", overflow: "scroll", paddingLeft: "10px", paddingRight: "10px" }}>
                    {unreadNotifications?.map((notification) => (
                      <Notification notification={notification} refresh={refreshNotifications} />
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Box sx={{ height: "480px", overflow: "scroll", paddingLeft: "10px", paddingRight: "10px" }}>
                    {readNotifications?.map((notification) => (
                      <Notification notification={notification} refresh={refreshNotifications} />
                    ))}
                  </Box>
                </TabPanel>
              </SwipeableViews>
            </Box>
          </div>
        </div>
      </div>
    </BasePage >
  )
}

export default Notifications;



