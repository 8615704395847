/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum PermissionClassification {
  None = 0,
  CanViewDashboard = 1,
  CanViewContacts = 2,
  CanViewGeneralDetails = 3,
  CanViewAddresses = 4,
  CanViewBEEDetails = 5,
  CanViewDocuments = 6,
  CanViewProductServices = 7,
  CanViewBankDetails = 8,
  CanViewUserManagment = 9,
  // Audit Trail
  ViewAuditTrail = 10,
  // Company profile
  EditCompanyprofile = 11,
  EditBankingDetails = 12,
  Sharemyprofile = 13,
  // Compliance Management
  ViewComplianceManagementDashboard = 14,
  ViewComplianceDetail = 15,
  // Home
  ViewDashboards = 16,
  // eRFx
  ViewBulletinBoard = 17,
  VieweRFxDetails = 18,
  Participateinopportunities = 19,
  AddNewOpportunities = 20,
  ManageOpportunities = 21,
  EvaluateBids = 22,
  AwardRegretBids = 23,
  Searchfilters = 24,
  // Find Companies
  AccessViewMoreDetails = 25,
  Viewcompliancecolourindicators = 26,
  Hoveroverviewofproductoffering = 27,
  Displayclientvendornumbers = 28,
  AvailableActions_Onboardsupplier = 29,
  AvailableActions_SendMessage = 30,
  AvailableActions_ChangeSupplierStatus = 31,
  AvailableActions_RateSupplier = 32,
  AvailableActions_InitiateaneRFx = 33,
  AvailableActions_CreateaConnection = 34,
  AvailableActions_AddBusinessUnit = 35,
  AvailableActions_AddSubAccount = 36,
  AvailableActions_EditManualSupplier = 37,
  MultiSelectCompanies = 38,
  ExtractMySupplierlist = 39,
  // Help & Support
  ViewHelpSupport = 40,
  // Manage my profile
  Managemycommunicationsettings = 41,
  // Manage Users
  ViewManageUsers = 42,
  EditManageUsers = 43,
  // Messaging
  ManageMessaging = 44,
  Respondtomessaging = 45,
  Attachsupportingdocumentstomessages = 46,
  Bulkmessagingfunctionality = 47,
  // My Compliance
  ViewMyComplianceStatus = 48,
  ViewMyComplianceDetail = 49,
  // My Subscription
  ViewMySubscription = 50,
  EditMySubscription = 51,
  // Onboarding
  ViewOnboardingDashboard = 52,
  Editonboarding = 53,
  AddnewOnboardingRequest = 54,
  CreateEditManualSuppliers = 55,
  // PP Report
  ViewPreferentialProcurementReport = 56,
  // Jank iBuy Permission
  iBuySpecific = 57,
  // Vendor Spend Permission
  VendorSpendUpload = 58,


  // Global platform user permissionsGlobalCompanySwitching = 10001,
  GlobalCompanySwitching = 10001,
  ViewPlatformCompanyData = 10002,
  ModifyPlatformCompanyData = 10003,
  AccessPlatformConfiguration = 10004,
  PlatformCompanyDataValidationView = 10005,
  AssignPlatformRoles = 10006,
  ViewAgentWorkCentre = 10007,
  SuperAgent = 10008,
}

export default PermissionClassification;