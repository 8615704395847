/* eslint-disable no-unused-vars */
import { Grid, Typography, Stack } from "@mui/material";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { IRegistrationStepPageComponent, RegistrationStepResponse } from "./IRegistrationStepComponent";
import TextField from "../CustomComponents/Controls/TextField";
import UploadCompanyProfileModal from "../CustomComponents/UploadCompanyProfileModal";


const ProfileStepSix = forwardRef<IRegistrationStepPageComponent, unknown>((_props, ref) => {

  const [website, setWebsite] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDataSimulation = async () => {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 2000)); // eslint-disable-line no-promise-executor-return
      setIsLoading(false);
    };
    loadDataSimulation();
  }, [])

  const onSubmitHandler = async () => {
    // The API call will be made here.
    // Remember: validation will also be peformed here.
    await new Promise(resolve => setTimeout(resolve, 100)); // eslint-disable-line no-promise-executor-return

    // dummy response
    const response: RegistrationStepResponse = {
      submittedSuccessfully: true,
      errorMessages: [],
    };

    return response;
  };

  const onSaveAndContinueHandler = async () => {
    await new Promise(resolve => setTimeout(resolve, 100)); // eslint-disable-line no-promise-executor-return
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };
  };

  // Expose the methods and properties useImperativeHandle (the will be accessible form the ref)
  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));


  const handleWebsiteUpdate = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = ev.currentTarget.name;
    const fieldValue = ev.currentTarget.value;
    if (fieldName)
      setWebsite(fieldValue);
  }


  return (
    <Grid container sx={{ marginLeft: "20px", marginTop: "20px" }}>
      <Grid xs={12} md={4}>
        <Grid xs={12}>
          <div className="box-label-profile-step">Upload Your Company Logo</div>
        </Grid>
        <Grid xs={12}>
          <UploadCompanyProfileModal staged={false} />
          <Typography>Supported formats: JPG and PNG</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} md={4}>
        <TextField
          name="companyWebsite"
          required={false}
          label="Company Website Link"
          value={website}
          onChange={handleWebsiteUpdate}
        />
      </Grid>
    </Grid>
  )
});


export default ProfileStepSix;