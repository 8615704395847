import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetAllCountries } from "../../Company/Country";
import { CountryModel } from "../../Company/Models/CountryModel";
import CountryListSlice from "./CountryListSlice";

export const CountryListActions = CountryListSlice.actions;

export const fetchCountries = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<CountryModel> = (await GetAllCountries()).data;
  dispatch(CountryListActions.setCountryListState(apiResponse));
}