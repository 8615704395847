import { useEffect } from "react";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { TourProvider } from "@reactour/tour";
import {
  EventType,
  InteractionType,
  IPublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { b2cPolicies } from "./authConfig";

import "./App.css";
import Pages from "./AppPages";
import Share from "./pages/SharedProfile/ProfileShare";
import NavigationHeader from "./pages/NewUI/NavigationHeader";
import themeOptions from "./util/MUI_Theme/Theme";

const stepsMain = [
  {
    selector: ".step-one",
    content:
      "This is the System Navigation. Use the navigation to redirect to various pages in B1Link.",
  },
  {
    selector: ".step-two",
    content: "Dashboard Statistics",
  },
  {
    selector: ".step-three",
    content: "User profile.",
  },
  {
    selector: ".step-Four",
    content: "Company profile",
  },
];

const App = ({ instance }: { instance: IPublicClientApplication }) => {
  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        // This handles the instance where a user clicks the Cancel button on the B2C sign-up/password change/etc pages.
        if (event.error && event.error.errorMessage.indexOf("AADB2C90091") > -1) {
          instance.loginRedirect(b2cPolicies.authorities.signUpSignIn as RedirectRequest);
        }

        // This handles the "Forgot Password" processing/routing.
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword as RedirectRequest)
              .catch((e) => {
                throw e;
              });
          }
        }
      }

      // Added this to negate the AADB2C90077 error occassionally experienced.
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.loginRedirect(b2cPolicies.authorities.signUpSignIn as RedirectRequest);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);


  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/ProfileShare/:hash" element={<Share />} />
          <Route
            path="/*"
            element={
              <MsalProvider instance={instance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <TourProvider
                    scrollSmooth
                    steps={stepsMain}
                    styles={{
                      popover: (base) => ({
                        ...base,
                        "--reactour-accent": "#435CD2",
                        borderRadius: 5,
                      }),
                      maskArea: (base) => ({ ...base, rx: 5 }),
                      maskWrapper: (base) => ({ ...base, color: "#435CD2" }),
                      badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
                      controls: (base) => ({ ...base, marginTop: 100 }),
                      close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
                    }}
                  >
                    <NavigationHeader />
                    <Pages />
                  </TourProvider>
                </MsalAuthenticationTemplate>
              </MsalProvider>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
