import {

  Grid,
  IconButton,
  MenuItem,
  Box,
  SelectChangeEvent,
  Tooltip,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import OrderByType from "../../util/enums/OrderByType";
import SearchViewFields from "../../util/enums/SearchViewFields";
import { OrderByModel } from "../../http/NetworkAndConnect/Models/OrderByModel";

interface SortOrderControllerProps {

  // eslint-disable-next-line no-unused-vars
  UpdateSortOrder: (sortOrders: Array<OrderByModel>) => void;
}

// eslint-disable-next-line no-unused-vars
const SortOrderController = ({ UpdateSortOrder }: SortOrderControllerProps) => {

  // eslint-disable-next-line no-unused-vars
  const [sortField, setSortField] = useState<SearchViewFields>(SearchViewFields.CompanyId);
  const [sortDirection, setSortDirection] = useState<OrderByType>(OrderByType.Ascending);

  const buildNewOrderByArray = (newSortField: SearchViewFields, newSortDirection: OrderByType) => {
    const orderByArray: Array<OrderByModel> = [];
    const orderByModel: OrderByModel = {
      fieldName: newSortField,
      orderByDirection: newSortDirection,
    };
    orderByArray.push(orderByModel);
    return orderByArray;
  }

  const handleSortOrderChange = () => {
    if (sortDirection === OrderByType.Descending) {
      setSortDirection(OrderByType.Ascending);

      const orderByArray = buildNewOrderByArray(sortField, OrderByType.Ascending);

      UpdateSortOrder(orderByArray);
      return;
    }

    setSortDirection(OrderByType.Descending);

    const orderByArray = buildNewOrderByArray(sortField, OrderByType.Descending);
    UpdateSortOrder(orderByArray);
  };

  const handleSortFieldChange = (event: SelectChangeEvent<SearchViewFields>) => {
    const newSortField = event.target.value as SearchViewFields;
    setSortField(newSortField);
    const orderByArray = buildNewOrderByArray(newSortField, sortDirection);

    UpdateSortOrder(orderByArray);
  };

  return (
    <Box sx={{ paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
      <Grid container>
        <Grid xs={2} sx={{ textAlign: "right" }}>
          <Tooltip title="Sort Direction">
            <IconButton onClick={handleSortOrderChange} className="bounce2" >
              {sortDirection === OrderByType.Descending ? <SouthIcon /> : <NorthIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={10}>

          <TextField
            select
            id="sortBySelect"
            value={sortField}
            label="Sort By"
            sx={{ width: 200, marginLeft: "10px" }}
            onChange={() => handleSortFieldChange}
            size="small"
          >
            <MenuItem value={SearchViewFields.CompanyId}>Company ID</MenuItem>
            <MenuItem value={SearchViewFields.RegisteredName}>Registered Name</MenuItem>
            <MenuItem value={SearchViewFields.RegistrationNumber}>Registration Number</MenuItem>
            <MenuItem value={SearchViewFields.TradingName}>Trading Name</MenuItem>
            <MenuItem value={SearchViewFields.BEELevel}>B-BBEE Level</MenuItem>
            <MenuItem value={SearchViewFields.BOPercentage}>Black-Owned Percentage</MenuItem>
            <MenuItem value={SearchViewFields.BWOPercentage}>
              Black Women Owned Percentage
            </MenuItem>
            <MenuItem value={SearchViewFields.Rating}>Rating</MenuItem>
            <MenuItem value={SearchViewFields.CreatedDate}>Created Date</MenuItem>
          </TextField>

        </Grid>
      </Grid>
    </Box>
  );
};

export default SortOrderController;
