import React, { useCallback, useEffect, useState } from "react";
import {
  Box, Button, Chip, Divider,
  Grid,
  IconButton,
  Paper, Stack, Tooltip,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import "./css/Panels.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import { DataGridPro, DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import { UserInvitationMessage } from "../../../http/CompanyUserManagement/Messages/UserInvitationMessage";
import { RoleMessage } from "../../../http/CompanyUserManagement/Messages/RoleMessage";
import {
  GetCompanyUserInvites, GetCompanyUsers,
  RemoveUserFromCompanyAsync,
  ResendUserInvite, UpdateUserStatus,
} from "../../../http/CompanyUserManagement/userManagementApi";
import WithRequiredPermission from "../../WithRequiredPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import { useAppSelector } from "../../../app/hooks";
import InLineSpinner from "../../LoadingSpinners/InlineSpinner";
import styles from "../../../pages/UserManagement/UserManagement.module.scss";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import InviteUser from "../InviteUser";
import EditUser from "../EditUser";

const DetailPanelContent = ({ row: rowProp, roles: rolesProps }: { row: UserInvitationMessage, roles: RoleMessage[] }) => (
  <Stack
    sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "#ECECEC" }}
    direction="column"
  >
    <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
      <Stack direction="column" spacing={1} sx={{ height: 1 }}>
        <Grid container>
          <Grid xs={12}>
            <h3>Roles</h3>
            <Divider />
          </Grid>

          <Grid xs={12}>
            {rowProp.roleIdList &&
              (rowProp.roleIdList?.filter((u: number) => u > 0).length > 0 ?
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                  {rolesProps?.filter(r => r.id > 0 && rowProp.roleIdList.includes(r.id))?.map((x: RoleMessage) =>
                    <Chip
                      key={`${rowProp?.uniqueNumber}_${x.id}`}
                      label={x.name}
                      className="chip-colored"
                      size="small"
                    />
                  )
                  }
                </Box>
                : "None"
              )
            }
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  </Stack>
);

const ViewUsers = ({ roles }: { roles: RoleMessage[] }) => {
  const [userInvites, setUserInvites] = useState<UserInvitationMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentRecord, setCurrentRecord] = useState<UserInvitationMessage>({} as UserInvitationMessage);
  const [isAdding, setIsAdding] = useState(false);
  const [isViewing, setIsViewing] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const companyId = useAppSelector((state) => state.baseCompanyInfo.companyId);
  const myCurrentUser = useAppSelector((redux) => redux.userProfile).particularUserProfile;
  const [invitationIdToResendTo, setInvitationIdToResendTo] = useState<number | undefined>();
  const [userIdToToggleActiveStatus, setUserIdToToggleActiveStatus] = useState<number | undefined>();

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      // user invites
      const data = await GetCompanyUserInvites(companyId);
      const userData = await GetCompanyUsers();
      const temp: UserInvitationMessage[] = userData.map(u => ({
        email: u.email,
        firstName: u.name,
        lastName: "",
        mobileNumber: u.mobile,
        companyId: u.companyId,
        accepted: true,
        userId: u.id,
        roleIdList: u.roles?.map(r => r.id),
        isActive: u.isActive,
      } as UserInvitationMessage))
      setUserInvites([...data, ...temp]);
      setIsLoading(false);
    }
    loader();
  }, [companyId, userInvites.length]);

  const toggleEditUserDrawer = (props: UserInvitationMessage) => {
    if (props.accepted === false) { return; }
    setIsEditing(true);
    setIsAdding(false);
    setIsViewing(false);
    setCurrentRecord(props);
  };

  const displayRows = (userInvites);

  useEffect(() => {
    setCurrentRecord(currentRecord || {} as UserInvitationMessage);
  }, [currentRecord, roles, userInvites, invitationIdToResendTo]);

  const refresh = async () => {
    setIsLoading(true);
    // user invites
    const data = await GetCompanyUserInvites(companyId);
    const userData = await GetCompanyUsers();
    const temp: UserInvitationMessage[] = userData.map(u => ({
      email: u.email,
      firstName: u.name,
      lastName: "",
      mobileNumber: u.mobile,
      companyId: u.companyId,
      accepted: true,
      userId: u.id,
      roleIdList: u.roles?.map(r => r.id),
      isActive: u.isActive,
    } as UserInvitationMessage))
    setUserInvites([...data, ...temp]);
    setIsLoading(false);
  }

  const resendInviteAsync = (userId: number) => {
    const resendEmail = async () => {
      try {
        setInvitationIdToResendTo(userId);
        await ResendUserInvite(userId);
        toast.success("Invite email resent");
        setInvitationIdToResendTo(undefined);
      }
      catch {
        setInvitationIdToResendTo(undefined);
        toast.error("Failed to resend invite email");
      }
    }
    resendEmail();
  }

  const removeUserAsync = (userId: number) => {
    // Yeah, miss me with that ZERO nonsense.
    if (userId === 0) return;

    // I ain't gonna lie... Doing calls like this makes as much sense as swimming with
    // sharks in a swimsuit made of the finest steak...
    const removeUserApiCall = async (userIdToDelete: number) => {
      try {
        Swal.fire({
          title: "Warning!",
          text: "Are you sure you want to remove this user?",
          icon: "warning",
          allowEnterKey: false,
          allowEscapeKey: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await RemoveUserFromCompanyAsync(userIdToDelete);
            toast.success("User has been removed successfully from your company user roster.");
          }
          await refresh();
        });
      }
      catch (error) {
        toast.error("Removal of user failed. Please contact support!");
      }
    }

    removeUserApiCall(userId);
  }

  const toggleActiveStatusAsync = (user: UserInvitationMessage) => {
    const makeApiCallAsync = async () => {
      try {
        setUserIdToToggleActiveStatus(user.userId ?? 0);
        await UpdateUserStatus(user.userId ?? 0, !user.isActive);
        setUserInvites((prevState) =>
          prevState.map(u => u.userId === user.userId ?
            { ...u, isActive: !u.isActive }
            : u
          ));
        toast.success("User active status updated succesfully");
        setUserIdToToggleActiveStatus(undefined);
      } catch (error) {
        toast.error("Failed to update user active status");
        setUserIdToToggleActiveStatus(undefined);
      }
    }
    makeApiCallAsync();
  }

  const renderEditUserRolesButton = (user: UserInvitationMessage) => (
    user.accepted === true &&
    <Tooltip title="Edit User">
      <IconButton
        className="fly-icon"
        onClick={() => toggleEditUserDrawer(user)}>
        <EditIcon />
      </IconButton>
    </Tooltip>

  )

  const renderResendEmailButton = useCallback((user: UserInvitationMessage) => {
    if (user.accepted) { return null; }
    // conditionally render spinner
    if (invitationIdToResendTo === user.invitationId) {
      return (
        <InLineSpinner />
      )
    }
    // render email button
    return (
      <Tooltip title="Resend Invite">
        <IconButton
          className="fly-icon"
          onClick={() => resendInviteAsync(user.invitationId ?? 0)}>
          <SendIcon />
        </IconButton>
      </Tooltip>
    );
  }, [invitationIdToResendTo]);

  const renderActivateOrDeactivateButton = useCallback((user: UserInvitationMessage) => {
    if (!user.accepted) { return null; }
    // conditionally render spinner
    if (userIdToToggleActiveStatus === user.userId) {
      return <InLineSpinner />;
    }
    // render email button
    return (
      <Tooltip title={user.isActive === false ? "Re-activate User" : "De-activate User"}>
        <IconButton
          sx={{ marginRight: 2 }}
          disabled={userIdToToggleActiveStatus != null || user.userId === myCurrentUser.id}
          onClick={(ev) => {
            ev.stopPropagation();
            toggleActiveStatusAsync(user);
          }}>
          {user.isActive ? <LockIcon /> : <LockOpenIcon />}
        </IconButton>
      </Tooltip>
    );
  }, [userIdToToggleActiveStatus, myCurrentUser])

  // eslint-disable-next-line no-unused-vars
  const renderRemoveUserButton = useCallback((user: UserInvitationMessage) => {
    // Don't render if the user invite is not accepted.
    if (!user.accepted) return null;

    return (
      <Tooltip title="Remove User From Company">
        <IconButton
          sx={{ marginRight: 2 }}
          disabled={user.userId === myCurrentUser.id}
          onClick={(ev) => {
            ev.stopPropagation();
            removeUserAsync(user.userId ?? 0);
          }}>
          {(user.isActive && user.userId !== myCurrentUser.id) ? <PersonOffIcon /> : null}
        </IconButton>
      </Tooltip>
    );

  }, [myCurrentUser.id]);

  const columns: GridColDef[] = [
    {
      field: "accepted",
      headerName: "",
      headerClassName: "custom-header",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      maxWidth: 60,
      renderCell: (params) => (
        <div style={{ marginTop: 3 }}>
          {params.value ? (
            <Tooltip title={params.row.isActive === false ? "Invite Not Accepted" : "Invite Accepted"}>
              <div className={styles.headingEachBoxRight}>
                <span className={styles.headingTable}>
                  {params.row.isActive === false ? <Close /> : <AccountCircleIcon className="cl-blue" />}
                </span>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Invite Sent">
              <MarkEmailReadIcon className="cl-green" />
            </Tooltip>
          )}
        </div>
      ),

    },
    {
      field: "firstName",
      headerName: "Username",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "mobileNumber",
      headerName: "Contact Number",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "userId",
      headerName: "Actions",
      maxWidth: 160,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span style={{ display: "flex", gap: "0px", padding: "0" }}>
            {renderResendEmailButton(params.row)}
            {renderActivateOrDeactivateButton(params.row)}
            {renderEditUserRolesButton(params.row)}
            {renderRemoveUserButton(params.row)}
          </span>
        </div>
      ),
    },

  ];

  const inviteList = displayRows.map((rows, index) => ({
    ...rows,
    uniqueNumber: index + 1,
  }));

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} roles={roles} />, []);

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);

  const setUserId = () => {
    setInvitationIdToResendTo(0);
  }

  const backToView = () => {
    setIsAdding(false);
    setIsEditing(false);
    setIsViewing(true);
    refresh();
  }


  return (
    <Box sx={{ flexGrow: 1 }}>
      <LoadingBackdrop showBackdrop={isLoading} />

      {isAdding &&
        <WithRequiredPermission permission={PermissionClassification.EditManageUsers}>
          <InviteUser
            backToView={backToView}
            setUserIdNull={setUserId}
            addNewUserInvitationCallback={(newInvite) => setUserInvites((prevState) => [newInvite, ...prevState])}
            roles={roles} />
        </WithRequiredPermission>
      }
      {isViewing &&
        <>
          <Button variant="contained" onClick={() => {
            setIsAdding(true);
            setIsEditing(false);
            setIsViewing(false);
          }}>Invite User
          </Button>
          <DataGridPro
            sx={{ marginTop: 2 }}
            rows={inviteList ?? []}
            columns={columns}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            getRowId={(row: UserInvitationMessage) => row?.uniqueNumber as number} />
        </>
      }
      {isEditing &&
        <WithRequiredPermission permission={PermissionClassification.EditManageUsers}>
          <EditUser
            backToView={backToView}
            currentRecord={currentRecord}
            roles={roles} />
        </WithRequiredPermission>
      }
    </Box>
  );

};

export default ViewUsers;