import React from "react";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DashboardSupplierStatusSummary } from "../../../../http/Company/Models/DashboardSupplierStatusSummary";

interface SupplierStatusChartProps {
  chartData: DashboardSupplierStatusSummary;
}

const SupplierStatusChart = ({ chartData }: SupplierStatusChartProps) => {
  const options: ApexOptions = {
    chart: {
      height: 330,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#4B69F9", "#101820", "#4B69F9", "#6F87FA", "#4B69F9", "#101820", "#6F87FA"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000"],
      },
      formatter(val) {
        return `${Math.round(parseFloat(val.toString()))}%`;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
        color: "#000",
        opacity: 1,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ["Regulatory Compliant", "B-BBEE Compliant", "Black-Owned", "Black Women-Owned"],
      title: {
        text: `Percentage of suppliers (out of ${chartData.totalSuppliers} total suppliers)`,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
      min: 0,
      max: 100,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        formatter: (value) => `${Math.round(value)}% (of ${chartData.totalSuppliers} total suppliers)`,
      },
    },
  };

  const series = [
    {
      name: "",
      data: [
        chartData.regulatoryCompliantSupplierPercentage,
        chartData.bbbeeCompliantSupplierPercentage,
        chartData.blackOwnedSupplierPercentage,
        chartData.blackWomenOwnedSupplierPercentage,
      ],
    },
  ];

  return <ReactApexChart type="bar" options={options} series={series} height={330} />;
};

export default SupplierStatusChart;
