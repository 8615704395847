import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import PermissionSlice from "./PermissionSlice"
import { RootState } from "../../../app/store"
import { PermissionModel } from "../Models/PermissionModel";
import PermissionService from "../../PermissionService";

export const permissionActions = PermissionSlice.actions

export const fetchPermissions = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
  if (getState().permission.allPermissions.length === 0) {
    const response: PermissionModel[] = await PermissionService.getAllPermissions();
    dispatch(permissionActions.setPermission(response))
  }
}
export const fetchParticularPermission = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: PermissionModel = await PermissionService.getParticularPermission(id);
  dispatch(permissionActions.setParticularPermission(response))
}