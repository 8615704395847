import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makeDeleteRequestNoBodyAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { CompanyProfileSettings } from "./Models/CompanyProfileSettings";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export async function GetCompanyProfileSettingsByCompanyId(companyId: number) {
  const url = `${baseUrl}/api/Company/CompanyProfileSettings/GetCompanyProfileSettingsByCompanyId/${companyId}`;
  return makeGetRequestAsync<CompanyProfileSettings>(apiScopes, url);
}

export async function GetCompanyProfileSettingsById(companyProfileSettingsId: number) {
  const url = `${baseUrl}/api/Company/CompanyProfileSettings/GetCompanyProfileSettingsByCompanyId/${companyProfileSettingsId}`;
  return makeGetRequestAsync<CompanyProfileSettings>(apiScopes, url);
}

export async function AddCompanyProfileSettings(companyProfileSettings: CompanyProfileSettings) {
  const url = `${baseUrl}/api/Company/CompanyProfileSettings/AddCompanyProfileSettings`;
  return makePostRequestAsync<void, CompanyProfileSettings>(apiScopes, url, companyProfileSettings);
}

export async function UpdateCompanyProfileSettings(companyProfileSettings: CompanyProfileSettings) {
  const url = `${baseUrl}/api/Company/CompanyProfileSettings/UpdateCompanyProfileSettings`;
  return makePostRequestAsync<void, CompanyProfileSettings>(apiScopes, url, companyProfileSettings);
}

export async function DeleteCompanyProfileSettings(companyProfileSettingsId: number) {
  const url = `${baseUrl}/api/Company/CompanyProfileSettings/DeleteCompanyProfileSettings/${companyProfileSettingsId}`;
  return makeDeleteRequestNoBodyAsync(apiScopes, url);
}