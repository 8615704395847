import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ProfileImageModel, ProfileImageArrayModel } from "../Models/ProfileImageModel";
import { UserProfileArrayModel, UserProfileModel } from "../Models/UserProfileModel";
import { UserRoleArrayModel, UserRoleModel } from "../Models/UserRoleModel";
import { SearchHistoryArrayModel, SearchHistoryModel } from "../Models/SearchHistoryModel";

const initialUserProfileState: UserProfileArrayModel = {
  allUserProfiles: [],
  particularUserProfile: {
    id: 0,
    name: "",
    surname: "",
    mobile: "",
    email: "",
    accountId: "",
    dateCreated: new Date,
    dateModified: new Date,
    modifiedBy: "",
    createdBy: "",
    defaultCompany: "",
    notificationType: 0,
    visibilityType: 0,
    accountStatus: 0,
    profileFileName: "",
    companyId: 0,
    isActive: true,
    companyCount: 0,
    marketingType: 0,
  },
}

const initialProfileImageState: ProfileImageArrayModel = {
  allProfileImages: [],
  particularProfileImage: {
    url: "",
  },
}

const initialUserRoleState: UserRoleArrayModel = {
  allUserRoles: [],
  particularUserRole: {
    userId: 0,
    roleId: 0,
    isDeleted: false,
    dateCreated: new Date,
    dateModified: new Date,
    dateDeleted: new Date,
    modifiedBy: "",
    createdBy: "",
    deletedBy: "",
    companyId: 0,
    userInvitationId: 0,
  },
}

const initialSearchHistoryState: SearchHistoryArrayModel = {
  allSearchHistory: [],
}

export const searchHistorySlice = createSlice({
  name: "searchHistory",
  initialState: initialSearchHistoryState,
  reducers: {
    setSearchHistory(state, action: PayloadAction<SearchHistoryModel[]>) {
      state.allSearchHistory = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const userRoleSlice = createSlice({
  name: "userRole",
  initialState: initialUserRoleState,
  reducers: {
    setUserRole(state, action: PayloadAction<UserRoleModel[]>) {
      state.allUserRoles = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularUserRole(state, action: PayloadAction<UserRoleModel>) {
      state.particularUserRole = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const profileImageSlice = createSlice({
  name: "profileImage",
  initialState: initialProfileImageState,
  reducers: {
    setProfileImage(state, action: PayloadAction<ProfileImageModel[]>) {
      state.allProfileImages = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularProfileImage(state, action: PayloadAction<ProfileImageModel>) {
      state.particularProfileImage = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})



export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialUserProfileState,
  reducers: {
    setUserProfile(state, action: PayloadAction<UserProfileModel[]>) {
      state.allUserProfiles = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularUserProfile(state, action: PayloadAction<UserProfileModel>) {
      state.particularUserProfile = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const getUserProfile = (state: RootState) => state.userProfile.particularUserProfile;
export const getProfileImage = (state: RootState) => state.profileImage.particularProfileImage;
export const getUserRole = (state: RootState) => state.userRole.particularUserRole;
export const getSearchHistory = (state: RootState) => state.searchHistory.allSearchHistory;
