/* eslint-disable react/require-default-props */

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
} from "@mui/material";

interface FormDialogProps {
  title: string,
  children?: JSX.Element, // eslint-disable-line no-undef
  handleClose?: () => void,
  handleSubmit: () => void,
  description?: string,
  subDescription?: string,
  submitButtonText?: string,
  closeButtonText?: string,
  hideCloseButton?: boolean,
  fullWidth?: boolean,
  maxWidth?: DialogProps["maxWidth"],
}

const FormDialog = ({
  title,
  description,
  subDescription,
  children,
  closeButtonText = "Close",
  submitButtonText = "OK",
  hideCloseButton = false,
  fullWidth = false,
  maxWidth = "sm",
  handleSubmit,
  handleClose,
}: FormDialogProps) => (
  <Dialog open onClose={handleClose} maxWidth={maxWidth} fullWidth={fullWidth} className="font-style-set-b1">
    <DialogTitle className="heading-title-products">{title}</DialogTitle>
    <DialogContent>
      {
        description &&
        <DialogContentText sx={{ fontWeight: "600" }}>
          {description}
        </DialogContentText>
      }
      <Box py={2}>
        {
          subDescription &&
          <p>{subDescription}</p>
        }
      </Box>
      {
        children &&
        <Box>
          {children}
        </Box>
      }
    </DialogContent>
    <DialogActions>
      <Grid container className="hr-container-products" xs={12}>
        <Grid xs={12}>
          <div style={{ width: "100%", height: "fit-content", backgroundColor: "white" }}>
            <div style={{ width: "20%", float: "left", marginRight: "-10px" }}>
              {!hideCloseButton && handleClose != null && (
                <button
                  type="button"
                  className="btn-custom-table-actions-product"
                  onClick={handleClose}
                >
                  {closeButtonText}
                </button>
              )}
            </div>
            <div style={{ width: "20%", float: "right" }}>
              <button
                type="button"
                className="btn-custom-table-actions-adv-product"
                onClick={handleSubmit}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

export default FormDialog;
