import {
  useQuery,
} from "@tanstack/react-query"
import { useAccount, useMsal } from "@azure/msal-react";
import queryKeys from "./queryKeys";
import UserProfileService from "../../../http/UserProfileService";
  
  
const useUserProfile = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount: string = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "") || "";

  const query = useQuery({
    queryKey: [queryKeys.userProfile, account?.idTokenClaims?.extension_companyid],
    queryFn: () => UserProfileService.getParticularUserProfile(userAccount),
  })
  
  return query;
}
  
export default useUserProfile;