/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  ChatList,
  IChatItemProps,
  MessageList,
  MessageType,
  Input,
} from "react-chat-elements";
import { Grid, List, IconButton, Box, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import "react-chat-elements/dist/main.css";
import { useQueryClient } from "@tanstack/react-query";
import { GetChatGroupMessageList, PostMessage, Read, ReadChat, SearchChatMessages } from "../../http/Messaging/Messaging";
import { ChatMessageModel } from "../../http/Messaging/Models/ChatMessageModel";
import { PostMessageModel } from "../../http/Messaging/Models/PostMessageModel";
import styles from "./Chat.module.scss";
import dotsGif from "../../util/images/dotsDown.gif";
import { ChatSummaryModel } from "../../http/Messaging/Models/ChatSummaryModel";
import useBaseCompanyInfo from "../../util/hooks/queries/useBaseCompanyInfo";
import useMessaging from "../../util/hooks/queries/useMessaging";
import queryKeys from "../../util/hooks/queries/queryKeys";
import useInputDebounce from "../../util/hooks/useInputDebounce";

const MessageModelToMessageType = (message: ChatMessageModel): MessageType => ({
  id: message.id,
  position: message.isSender ? "right" : "left",
  text: message.text,
  title: message.userName,
  date: message.date,
  dateString: message.date.toString().slice(0, 16).replace("T", " "),
  type: "text",
  status: message.isRead ? "read" : "received",
  titleColor: "black",
  focus: false,
  forwarded: false,
  replyButton: false,
  removeButton: false,
  notch: false,
  retracted: false,
  reply: false,
});



const Chat = ({ refreshChatView }: { refreshChatView: boolean }) => {
  const ref = React.useRef(null);
  const listref = React.createRef<HTMLUListElement>();
  const messageListref = React.createRef<MessageList>();
  const inputRef = React.createRef<HTMLInputElement>();
  const [chatList, setChatList] = useState<IChatItemProps[]>([]);
  const [chatGroupId, setChatGroupId] = useState<number | null>(null);
  const [messageList, setMessageList] = useState<MessageType[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingChatList, setLoadingChatList] = useState(false);
  const [loadingMessageList, setLoadingMessageList] = useState(false);
  const [componentHeight] = useState(650);
  const [chatName, setChatName] = useState("");
  const { data: company } = useBaseCompanyInfo();
  const { data: MessageLists } = useMessaging();
  const companyId = company?.companyId as number;
  const queryClient = useQueryClient();
  const [chatStats, setChatStats] = useState("No chats available.");
  const [searchText, setSearchText] = useState("");

  const loader = () => {
    if(searchText === undefined){
      setLoadingChatList(true);
      setChatStats("No chats available.");
      try {
        // get chat list
        const results = MessageLists || [] as ChatSummaryModel[];

        // map to chat items
        const chats = results?.map(
          (item: ChatSummaryModel) => ({
            id: item.id,
            avatar: "defualtavatar.png",
            avatarFlexible: false,
            alt: "defualtavatar",
            title: item.name,
            subtitle:
              item.latest == null
                ? "No messages yet"
                : item.latest.text,
            date: item.latest?.date ?? null,
            unread: item.unread,
          } as IChatItemProps)
        );
      

        setChatList(chats as IChatItemProps[]);

        // begin refresh loop
      } finally {
        setLoadingChatList(false);
        setLoading(false);
      }
    }
  };

  // load chat messages
  const loadChat = async (chat: IChatItemProps) => {
    setLoadingMessageList(true);


    const updateChat = () => {
      Read(chat.id as number, companyId);
    }

    updateChat();
    loader();

    try {
      // get id of current chat
      const id = Number.parseInt(chat.id.toString(), 10);
      setChatName(chat.title as string);
      // get messages of current chat
      const results = await GetChatGroupMessageList(id, company?.companyId ?? 0);
      // map to message type
      const messages = results.data.map(MessageModelToMessageType);
      // update message list
      setMessageList(messages);
      // select group
      setChatGroupId(id);
      // read chat
      const read = await ReadChat(id);
      if (read.data) {
        // update chat list and remove unread from selected chat
        const selectedChat = chatList.findIndex((c) => c.id === id);
        if (selectedChat !== undefined) {
          chatList[selectedChat].unread = 0;
          setChatList(chatList);
        }
      }
    } finally {
      setLoadingMessageList(false);
    }
  };
  // search messages
  const handleSearchOnTextChange = async (searchQuery: string) => {
    // get input text
    if(searchQuery !== undefined){
      setLoadingChatList(true);
      try {
        // get chat list
        const searchResults = SearchChatMessages(companyId, searchQuery);
        const results = searchResults || [] as ChatSummaryModel[];
        console.log(results);
        // map to chat items
        const chats = (await results)?.map(
          (item: ChatSummaryModel) => ({
            id: item.id,
            avatar: "defualtavatar.png",
            avatarFlexible: false,
            alt: "defualtavatar",
            title: item.name,
            subtitle:
              item.latest == null
                ? "No messages yet"
                : item.latest.text,
            date: item.latest?.date ?? null,
            unread: item.unread,
          } as IChatItemProps)
        );
        console.log(chats.length);
        setChatList(chats as IChatItemProps[]);
        if((await searchResults)?.length === 0){
          setChatStats(`No chats matched: '${searchQuery}'`);
        }
        // begin refresh loop
      } finally {
        setLoadingChatList(false);
        setLoading(false);
      }
    }
    else{
      loader();
    }
  }
  const newSearchText = useInputDebounce(searchText);
  useEffect(() => {
    if(newSearchText === undefined || newSearchText.length === 0){
      loader();
    }else{
      handleSearchOnTextChange(newSearchText);
    }
  },[newSearchText]);
  useEffect(() => {

    if (refreshChatView) {
      const load = async () => {
        queryClient.refetchQueries({ queryKey: [queryKeys.messaging] });
      }
      load();
    }
    loader();
  }, [companyId, MessageLists?.length, refreshChatView]);
  // send message
  const handleSend = async () => {
    // get last id
    const lastId = messageList?.[messageList.length - 1]?.id as number ?? 0;

    // get input text
    const text = inputRef.current?.value as string;

    // create message
    const message = MessageModelToMessageType({
      text,
      id: lastId + 1,
      isSender: true,
      userName: "displayName",
      date: new Date(),
    } as ChatMessageModel);

    // add message to list
    const messages = [...(messageList ?? []), message];

    setLoadingMessageList(true);

    try {
      // post message to server
      const data = {
        text: message.text,
        groupId: chatGroupId,
        companyId: company?.companyId,
        // temp, gets updated in business logic
        username: "tmp",
        userId: "tmp",
      };

      await PostMessage(data as PostMessageModel);

      // update message list
      setMessageList(messages);

      // update chat list
      const chat = chatList.findIndex((c) => c.id === chatGroupId);
      if (chat !== undefined) {
        chatList[chat].subtitle = message.text;
        chatList[chat].date = new Date();

        // make this chat the first in the list
        chatList.sort((a, b) => {
          const aDate = a.date?.valueOf() ?? 0;
          const bDate = b.date?.valueOf() ?? 0;
          return bDate - aDate;
        });

        setChatList(chatList );
      }
      const load = async () => {
        queryClient.refetchQueries({ queryKey: [queryKeys.messaging] });
      }
      load();
      loader();

    } catch (e) {
      // remove message from list if request fails
      messages.pop();
      setMessageList(messages);
    } finally {

      if (inputRef.current !== null) inputRef.current.value = "";
      setLoadingMessageList(false);
    }
  };

  if (loading) return <div className={styles.chatBox}>Loading...</div>;

  const inputHeight = 50;

  return (
    <div style={{ height: "100%", width: "100%" }} >

      <Grid ref={ref} container>
        {/* side bar for chat list */}



        <Grid item xs={3} sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
          {loadingChatList && <div className={styles.chatBox}>Loading...</div>}
          {!loadingChatList && chatList?.length === 0 && <div className={styles.chatBox}>{chatStats}</div>}
          {!loadingChatList && chatList !== null && (

            <>
              <Box m={2}>
                <TextField
                  value={searchText}
                  onChange={(e) => {setSearchText(e.target.value)}}
                  placeholder="Search By Text Message, Username or Company"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="icon-primaryActive" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              </Box>
              <List 
                style={{
                  overflow: "auto",
                  maxHeight: componentHeight,
                }}>
                <ChatList
                  id="chat-list"
                  dataSource={chatList}
                  onClick={loadChat}
                  lazyLoadingImage={dotsGif} />
              </List>
            </>

          )}
        </Grid>
        <Grid item xs={9}>
          {/* message list */}
          {loadingMessageList && <div className={styles.chatBox}>Loading...</div>}
          {!loadingMessageList && messageList === null && <div className={styles.chatBox}>Please select a chat...</div>}
          {!loadingMessageList && messageList !== null && (

            <>
              <div className={styles.chatTop}>{chatName}</div>
              <div className={styles.chatBoxList}>

                {messageList?.length === 0 ? (
                  <div>No messages yet...</div>
                ) : (
                  <List
                    id="message-list"
                    ref={listref}
                    style={{
                      backgroundColor: "light-gray",
                      width: "100%",
                    }}>
                    <MessageList
                      referance={messageListref}
                      lockable
                      toBottomHeight="100%"
                      dataSource={messageList as MessageType[]} />
                  </List>
                )}
              </div>
              <div className={styles.chatBoxParent}>


                <Input
                  maxHeight={inputHeight}
                  placeholder="Type here..."
                  referance={inputRef}
                  multiline={false}
                  rightButtons={<IconButton color="primary" onClick={handleSend} aria-label="upload picture"><SendIcon /></IconButton>}
                  inputStyle={{
                    border: "2px solid #dedede",
                    height: "35px",
                    borderRadius: "5px",
                  }} />

              </div>
            </>

          )}

        </Grid>
      </Grid>
    </div>

  );
};

export default Chat;

