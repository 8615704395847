import { Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning"; 
import LinkIcon from "@mui/icons-material/Link"; 
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt"; 
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";

// eslint-disable-next-line no-shadow
enum SupplierUploadInvitationStatus { // eslint-disable-line no-unused-vars
  UploadedButNotSent = -1,// eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  EmailSent = 1,// eslint-disable-line no-unused-vars
  InvalidEmail = 2,// eslint-disable-line no-unused-vars
  Registered = 3,// eslint-disable-line no-unused-vars
}

export default SupplierUploadInvitationStatus;

export const supplierUploadInvitationStatusToString = (value: SupplierUploadInvitationStatus) => {
  switch (value) {
    case SupplierUploadInvitationStatus.UploadedButNotSent:
      return "Uploaded but not sent"
    case SupplierUploadInvitationStatus.EmailSent:
      return "Email sent"
    case SupplierUploadInvitationStatus.InvalidEmail:
      return "Invalid email address"
    case SupplierUploadInvitationStatus.Registered:
      return "Registered"
    default:
      return "Unknown";
  }
};

export const getSupplierUploadInvitionStatusComponent = (value: SupplierUploadInvitationStatus) => {
  switch (value) {
    case SupplierUploadInvitationStatus.UploadedButNotSent:
      return (
        <Tooltip title="Uploaded but not sent">
          <CommentsDisabledIcon color="disabled" />
        </Tooltip>
      )
    case SupplierUploadInvitationStatus.EmailSent:
      return (
        <Tooltip title="Email sent">
          <CheckCircleIcon color= "success" />
        </Tooltip>
      )
    case SupplierUploadInvitationStatus.InvalidEmail:
      return (
        <Tooltip title="Invalid Email Address">
          <WarningIcon color="warning" />
        </Tooltip>
      )
    case SupplierUploadInvitationStatus.Registered:
      return (
        <Tooltip title="Registered">
          <LinkIcon color="primary" />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Unknown">
          <PsychologyAltIcon />
        </Tooltip>
      )
  }
}