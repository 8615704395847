import { Box, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Button, Alert, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { AxiosError } from "axios";
import FormDialog from "../Dialogs/FormDialog";
import { getAvailableVouchers, getRedeemableVoucher } from "../../http/SubscriptionManagement/SubscriptionApi";
import { VoucherMessage } from "../../http/SubscriptionManagement/Messages/VoucherMessage";
import DiscountTypeEnum from "../../http/SubscriptionManagement/Enums/DiscountTypeEnum";
import InLineSpinner from "../LoadingSpinners/InlineSpinner";

const RedeemVoucherModal = (
  {
    onCloseCallback,
    onSubmitCallback,
    selectedPlanId,
  }:
    {
      onCloseCallback: () => void,
      // eslint-disable-next-line no-unused-vars
      onSubmitCallback: (selectedVoucher?: VoucherMessage) => void,
      selectedPlanId: number,
    }) => {
  const [voucherId, setVoucherId] = useState<number | null>(null);
  const [voucherCode, setVoucherCode] = useState<string>("");
  const [availableVouchers, setAvailableVouchers] = useState<VoucherMessage[]>([]);
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("An unexpected error occured, please contact B1Link support");
  const [validVoucher, setValidVoucher] = useState<VoucherMessage | undefined>()


  useEffect(() => {
    const loader = async () => {
      try {
        setIsLoading(true);
        const data = await getAvailableVouchers([selectedPlanId]);
        setAvailableVouchers(data);
        setIsLoading(false);
      }
      catch {
        setIsLoading(false);
        setErrorMessage("An unexpected error occured, please contact B1Link support");
        setShowError(true)
      }
    }
    loader();
  }, [selectedPlanId])

  const confirmVoucher = () => {
    // eslint-disable-next-line consistent-return
    const loader = async () => {
      setIsLoading(true);
      try {
        setValidVoucher(undefined);
        setShowError(false);
        const data = await getRedeemableVoucher(voucherCode, [selectedPlanId]);
        setValidVoucher(data);
      }
      catch (err) {
        const error = err as AxiosError<any>;
        if (error.response?.status === 400 && error.response.data != null) {
          setErrorMessage(error.response.data as string);
        }

        setValidVoucher(undefined);
        setShowError(true);
      }
      setIsLoading(false);
    }

    if (voucherCode.trim().length > 0) {
      loader();
    } else {
      setShowError(false);
      setValidVoucher(availableVouchers.find(x => x.id === voucherId))
    }
  }


  return (
    <FormDialog
      fullWidth
      maxWidth="sm"
      title="Redeem Voucher"
      submitButtonText="Submit"
      subDescription="Please enter you voucher code or select one from the list below"
      handleClose={() => onCloseCallback()}
      handleSubmit={() => {
        if (validVoucher) {
          onSubmitCallback(validVoucher);
          onCloseCallback();
        }
      }}
    >
      {
        isLoading ? <InLineSpinner /> :
          <>
            <br />
            <Box>
              <TextField
                id="filled-basic"
                label="Enter voucher code"
                variant="filled"
                value={voucherCode}
                sx={{ width: "100%" }}
                onChange={(ev) => {
                  setVoucherCode(ev.target.value);
                  setVoucherId(null);
                }}
              />
            </Box>
            {
              availableVouchers.length > 0 &&
              <>
                <br />
                or
                <Box>
                  <FormControl sx={{ mx: 2, py: 2 }}>
                    <FormLabel>Select a voucher</FormLabel>
                    <RadioGroup
                      value={voucherId}
                      onChange={(ev) => {
                        setVoucherId(parseInt(ev.target.value, 10));
                        setVoucherCode("");
                      }}
                    >
                      {
                        availableVouchers.map(v =>
                          <FormControlLabel
                            key={`voucher_${v.id.toString()}`}
                            value={v.id}
                            control={<Radio checked={voucherId === v.id} />}
                            // eslint-disable-next-line max-len
                            label={`${v.name} Voucher ( ${v.discountType === DiscountTypeEnum.FlatRateDiscount ? `R ${v.discount} off` : `${v.discount}% off`})`}
                          />
                        )
                      }
                    </RadioGroup>
                  </FormControl>
                </Box>
              </>
            }
            <div className="hr-line" />
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              onClick={confirmVoucher}>
              Confirm voucher
            </Button>
            <Box sx={{ pt: 2 }}>
              {
                showError &&
                <Grid container>
                  <Grid xs={10} md={10}>
                    <br />
                    <Alert severity="error">{errorMessage}</Alert>
                  </Grid>
                </Grid>
              }
              {
                validVoucher &&
                <>
                  <Grid container>
                    <Grid xs={10} md={10}>
                      <br />
                      <Alert severity="success">Valid voucher retrieved</Alert>
                    </Grid>
                  </Grid>

                  <Typography variant="body1" color="textSecondary" component="span">
                    Name: {validVoucher.name}
                  </Typography>
                  <br />
                  <Typography variant="body1" color="textSecondary" component="span">Discount:
                    {validVoucher.discountType === DiscountTypeEnum.FlatRateDiscount ?
                      `R ${validVoucher.discount} off` : `${validVoucher.discount}% off`}
                  </Typography>
                  <br />
                  <Typography variant="body1" color="textSecondary" component="span">
                    Code: {validVoucher.code}
                  </Typography>
                  <br />
                  <Typography variant="body1" color="textSecondary" component="span">
                    Expiry Date: {validVoucher.code}
                  </Typography>
                </>
              }
            </Box>
          </>
      }
    </FormDialog>
  )
}

export default RedeemVoucherModal;