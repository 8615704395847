import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import {
  companyVerificationSlice,
  companyRequestSlice,
  companyValidationConfigSlice,
  companyValidationRankingSlice,
} from "./CompanyVerificationSlice";
import { RootState } from "../../../app/store";
import { CompanyVerificationModel } from "../Models/CompanyVerificationModel";
import CompanyVerificationService from "../../CompanyVerificationService";
import { CompanyRequestModel } from "../Models/CompanyRequestModel";
import { CompanyValidationConfigModel } from "../Models/CompanyValidationConfigModel";
import { CompanyValidationRankingModel } from "../Models/CompanyValidationRankingModel";


export const companyVerificationActions = companyVerificationSlice.actions;

export const fetchCompanyVerifications =
  (id: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch, getState) => {
      if (getState().companyVerifications.allCompanyVerifications.length === 0) {
        const response: CompanyVerificationModel[] =
          await CompanyVerificationService.getAllCompanyVerifications(id, staged);
        dispatch(companyVerificationActions.setCompanyVerification(response));
      }
    };

export const companyRequestActions = companyRequestSlice.actions;

export const fetchCompanyRequest =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyRequestModel = await CompanyVerificationService.getCompanyRequest(
        companyId,
        staged
      );
      dispatch(companyRequestActions.setParticularCompanyRequest(response));
    };

export const companyValidationConfigActions = companyValidationConfigSlice.actions;

export const fetchCompanyValidationConfig =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyValidationConfigModel[] =
        await CompanyVerificationService.getAllCompanyValidationConfig(companyId, staged);
      dispatch(companyValidationConfigActions.setCompanyValidationConfig(response));
    };

export const companyValidationRankingActions = companyValidationRankingSlice.actions;

export const fetchCompanyValidationRanking =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyValidationRankingModel[] =
        await CompanyVerificationService.getAllCompanyValidationRankings(companyId, staged);
      dispatch(companyValidationRankingActions.setCompanyValidationRanking(response));
    };



