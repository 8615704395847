/* eslint-disable no-unused-vars */
import React, { ReactNode, RefObject, useRef, useState } from "react";
import { Card, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CompanyRegistrationStepOne from "../../Registration/RegistrationSteps/CompanyRegistrationStepOne";
import BasePage from "../BasePage";
import { IRegistrationStepPageComponent } from "../../Registration/RegistrationSteps/IRegistrationStepComponent";
import CompanyProfileSidePanel from "./EditCompanySideBar";
import CompanyContactStepTwo from "../../Registration/RegistrationSteps/CompanyContactStepTwo";
import ComplianceInformationStepThree from "../../Registration/RegistrationSteps/ComplianceInformationStepThree";
import GoodsAndServicesStepFive from "../../Registration/RegistrationSteps/GoodsAndServicesStepFive";
import ProfileStepSix from "../../Registration/RegistrationSteps/ProfileStepSix";
import SupportingDocumentsStepFour from "../../Registration/RegistrationSteps/SupportingDocumentsStepFour";
import ButtonGroup from "../../Registration/Sections/ButtonGroup";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import { BaseCompanyInfo } from "../../../http/Company/Models/BaseCompanyInfo";
import { useAppSelector } from "../../../app/hooks";




interface RegistrationPageProps {
  title: string,
  children: ReactNode,
  pageRef: RefObject<IRegistrationStepPageComponent>,
  pageNumber: number,
}



const BaseEditCompanyProfile = () => {
  const { data: baseCompany = {} as BaseCompanyInfo } = useBaseCompanyInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const userInfo = useAppSelector((reduxState) => reduxState.userProfile);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const clearErrorMessages = () => errorMessages.length > 0 && setErrorMessages([]);

  const pageRefs = {
    companyInfo: useRef<IRegistrationStepPageComponent>(null),
    contactDetails: useRef<IRegistrationStepPageComponent>(null),
    compliance: useRef<IRegistrationStepPageComponent>(null),
    documentation: useRef<IRegistrationStepPageComponent>(null),
    goods: useRef<IRegistrationStepPageComponent>(null),
    profile: useRef<IRegistrationStepPageComponent>(null),
  };


  const pages: RegistrationPageProps[] = [
    {
      title: "COMPANY INFORMATION",
      children: <CompanyRegistrationStepOne title="COMPANY INFORMATION" ref={pageRefs.companyInfo} />,
      pageRef: pageRefs.companyInfo,
      pageNumber: 0,
    },
    {
      title: "COMPANY CONTACT DETAILS",
      children: <CompanyContactStepTwo ref={pageRefs.contactDetails} />,
      pageRef: pageRefs.contactDetails,
      pageNumber: 1,
    },
    {
      title: "COMPLIANCE INFORMATION",
      children: <ComplianceInformationStepThree ref={pageRefs.compliance} />,
      pageRef: pageRefs.compliance,
      pageNumber: 2,
    },
    {
      title: "SUPPORTING DOCUMENTATION",
      children: <SupportingDocumentsStepFour ref={pageRefs.documentation} />,
      pageRef: pageRefs.documentation,
      pageNumber: 3,
    },
    {
      title: "GOODS AND SERVICES",
      children: <GoodsAndServicesStepFive ref={pageRefs.goods} />,
      pageRef: pageRefs.goods,
      pageNumber: 4,
    },
    {
      title: "PROFILE",
      children: <ProfileStepSix ref={pageRefs.profile} />,
      pageRef: pageRefs.profile,
      pageNumber: 5,
    },
  ];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const handleSave = async () => {
    const currentPageRef = pages.find(x => x.pageNumber === currentPage)?.pageRef.current;
    if (currentPageRef) {
      const submissionResponse = await currentPageRef.onSubmit();
      const pageHasErrors = submissionResponse.submittedSuccessfully === false && submissionResponse.errorMessages.length > 0;

      // show error modal
      if (pageHasErrors) {
        setErrorMessages(submissionResponse.errorMessages);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      } clearErrorMessages()

      // increment counter
      if (!pageHasErrors) {

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

  }

  return (
    <BasePage>
      <Grid container>
        <Grid item xs={3} sx={{ paddingRight: "20px", paddingLeft: "20px" }}>
          <Card>
            <CompanyProfileSidePanel
              currentSection={currentPage}
              setSection={handlePageChange}
            />
          </Card>
        </Grid>
        <Grid item xs={9} sx={{ paddingRight: "20px" }}>
          <Card sx={{ paddingBottom: "20px", height: "fit-content" }}>
            <div style={{ height: "fit-content" }}>
              {pages.find(x => x.pageNumber === currentPage)?.children}
            </div>
            <br />
            <div>
              <ButtonGroup
                buttons={[
                  {
                    label: "SAVE CHANGES",
                    align: "right",
                    onClick: handleSave,
                    type: "outlined",

                  }, {
                    label: "BACK TO PROFILE VIEW",
                    align: "left",
                    onClick: () => navigate(`/viewprofile/${baseCompany.companyId}`),
                    type: "secondary",

                  },
                ]}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </BasePage>
  )
};

export default BaseEditCompanyProfile;