import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { UnspscSegment } from "../../../../http/Configuration/UnspscSegment";

interface SelectedProductAndServicesDisplayProps {
  unspscOptions: { segments: UnspscSegment[] };
  selectedProductsIdList: number[];
  setUpdatedSelection?: React.Dispatch<React.SetStateAction<number[]>>; // eslint-disable-line react/require-default-props
  showMainCategories?: boolean,  // eslint-disable-line react/require-default-props
}

const SelectedProductAndServicesDisplay: React.FC<SelectedProductAndServicesDisplayProps> = ({
  unspscOptions,
  selectedProductsIdList,
  setUpdatedSelection,
  showMainCategories = false,
}) => {
  const selectedItems = unspscOptions.segments.map((seg) => {
    const segmentFamilies = seg.families.filter((x) => selectedProductsIdList.includes(x.id));
    if (segmentFamilies.length > 0) {
      return { ...seg, families: segmentFamilies } as UnspscSegment;
    }
    return null;
  });

  return (
    <>
      {selectedItems
        .filter((x) => x != null)
        .map((item) => (
          <Grid item xs={12} key={item!.id}>
            {
              showMainCategories &&
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6">
                  <b>{item?.name}</b>
                </Typography>
              </Box>
            }
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
              {item?.families.map((fam) => (
                setUpdatedSelection ? 
                  <Chip
                    size="small"
                    key={fam.id}
                    label={fam.name}
                    onDelete={() => setUpdatedSelection && setUpdatedSelection(prevState => prevState.filter((x) => x !== fam.id))}
                    sx={{ fontWeight: "bold", mr: 1, mb: 1 }}
                  /> :
                  <Chip size="medium" sx={{ fontWeight: "bold", mx: 1, mb: 1 }} label={fam.name} />
              ))}
            </Box>
          </Grid>
        ))}
    </>
  );
};

export default SelectedProductAndServicesDisplay;
