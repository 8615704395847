import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { CompanyComplianceSummary } from "../../../http/Compliance/Models/CompanyComplianceSummary";
import { getCompanyComplianceSummary } from "../../../http/Compliance/CompanyComplianceSummaryApi";

const useCompanyComplianceSummary = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyComplianceSummary, companyId],
    queryFn: async () => (await getCompanyComplianceSummary(companyId)).data as CompanyComplianceSummary,
    staleTime: 600000,
  })

  return query;
}

export default useCompanyComplianceSummary;