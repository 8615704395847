import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {
  IRegistrationStepPageComponent,
  RegistrationStepResponse,
} from "./IRegistrationStepComponent";
import { BeeDetailsModel } from "../../../http/Company/Models/BeeDetailsModel";
import { CoidaDetails } from "../../../http/Company/Models/CoidaDetails";
import { TaxInformation } from "../../../http/Company/Models/CompanyTaxInformation";
import { useAppSelector } from "../../../app/hooks";

import BeeInfo from "../CustomComponents/Sub-Components/BeeInfo";
import TaxInfo from "../CustomComponents/Sub-Components/TaxInfo";
import CoidaInfo from "../CustomComponents/Sub-Components/CoidaInfo";
import RegistrationSkeleton from "../Sections/RegistrationSkeleton";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import useBeeLevels from "../../../util/hooks/queries/useBeeLevels";
import { SectorModel } from "../../../http/Company/Models/SectorModel";
import useBeeSectors from "../../../util/hooks/queries/useBeeSectors";
import { BeeLevelModel } from "../../../http/Company/Models/BeeLevelModel";
import BankingInfo from "../CustomComponents/Sub-Components/BankingInfo";
import { BankAccountDetailsModel } from "../../../http/Company/Models/BankAccountDetailsModel";
import useBanks from "../../../util/hooks/queries/useBanks";
import { Bank } from "../../../http/Company/Models/Bank";
import formatDate from "../../../util/formatter/formatDate";
import useCompanyBankingValidation from "../../../util/hooks/validation/useCompanyBankingValidation";
import { CountryModel } from "../../../http/Company/Models/CountryModel";
import useCountries from "../../../util/hooks/queries/useCountries";
import { CompanyProfileSettings } from "../../../http/Company/Models/CompanyProfileSettings";
import useCompanyBeeDetails from "../../../util/hooks/queries/useCompanyBeeDetails";
import useCompanyTaxInformation from "../../../util/hooks/queries/useCompanyTaxInformation";
import useCompanyCoidaDetails from "../../../util/hooks/queries/useCompanyCoidaDetails";
import useCompanyBankingDetails from "../../../util/hooks/queries/useCompanyBankingDetails";
import useCompanyBeeDetailsMutation from "../../../util/hooks/mutations/useCompanyBeeDetailsMutation";
import useCompanyProfileSettings from "../../../util/hooks/queries/useCompanyProfileSettings";
import useCompanyProfileSettingsMutation from "../../../util/hooks/mutations/useCompanyProfileSettingsMutation";
import useCompanyTaxDetailsMutation from "../../../util/hooks/mutations/useCompanyTaxDetailsMutation";
import useCompanyCoidaDetailsMutation from "../../../util/hooks/mutations/useCompanyCoidaDetailsMutation";
import useCompanyBankingDetailsMutation from "../../../util/hooks/mutations/useCompanyBankingDetailsMutation";

const ComplianceInformationStepThree = forwardRef<IRegistrationStepPageComponent, unknown>(
  (_props, ref) => {
    // Redux
    const userInfo = useAppSelector((reduxState) => reduxState.userProfile);

    // useQuery
    const { isLoading: isBaseCompanyInfoLoading, data: baseCompanyInfo } = useBaseCompanyInfo();
    const { isLoading: isBeeSectorsLoading, data: beeSectors = [] as Array<SectorModel> } =
      useBeeSectors();
    const { isLoading: isBeeLevelsLoading, data: beeLevels = [] as Array<BeeLevelModel> } =
      useBeeLevels();
    const { isLoading: isBanksLoading, data: bankList = [] as Array<Bank> } = useBanks();
    const { isLoading: isCountriesLoading, data: countries = [] as Array<CountryModel> } =
      useCountries();

    const { isLoading: isCompanyProfileSettingsLoading, data: companyProfileSettingsQuery } =
      useCompanyProfileSettings(
        baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId
      );

    const { isLoading: isCompanyBeeDetailsLoading, data: beeDetailsQuery } = useCompanyBeeDetails();
    const { isLoading: isCompanyTaxDetailsLoading, data: companyTaxDetailsQuery } =
      useCompanyTaxInformation(
        baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId
      );
    const { isLoading: isCompanyCoidaDetailsLoading, data: companyCoidaQuery } =
      useCompanyCoidaDetails(
        baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId
      );
    const { isLoading: isCompanyBankingDetailsLoading, data: bankingDetailsQuery } =
      useCompanyBankingDetails(
        baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId
      );

    // Mutators
    const { mutate: mutateCps } = useCompanyProfileSettingsMutation();
    const { mutate: mutateBee } = useCompanyBeeDetailsMutation();
    const { mutate: mutateTax } = useCompanyTaxDetailsMutation();
    const { mutate: mutateCoida } = useCompanyCoidaDetailsMutation();
    const { mutate: mutateBanking } = useCompanyBankingDetailsMutation();

    const southAfricanCountryId = useMemo(
      () =>
        countries.find(
          (country) => country.isDefault && country.name.toLowerCase() === "south africa"
        )?.id || 0,
      [countries]
    );

    const DEFAULT_BEE_OBJECT: BeeDetailsModel = useMemo(
      () => ({
        id: 0,
        sectorId: 0,
        annualTurnover: 0,
        boPercentage: 0,
        bwoPercentage: 0,
        certificateLevelId: 0,
        certificateExpiryDate: null,
        isDeleted: false,
        companyId: baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId,
        isCurrent: true,
        validationStatus: 0,
        validationDateTime: null,
        hasBEECertificate: null,
        certificateDocId: null,
        ratingSourceId: null,
        verificationAgencyId: null,
        vas: null,
        localServiceProvider: null,
        localGoodsManufacturer: null,
        isBlackOwned: null,
        isBlackWomanOwned: null,
        isFlowThrough: null,
        blackDesignatedGroup: null,
        blackYouth: null,
        blackDisabled: null,
        blackUnemployed: null,
        blackRural: null,
        blackMilitaryVet: null,
        farmWorkers: null,
        legacyBDG: null,
        certificateIssueDate: null,
        beeSectionEnabled: false,
      }),
      [baseCompanyInfo?.companyId, userInfo.particularUserProfile.companyId]
    );

    const DEFAULT_TAX_INFO_OBJECT: TaxInformation = useMemo(
      () => ({
        companyId: baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId,
        taxClearanceNumber: null,
        taxClearanceNumberExpiryDate: null,
        vatNumber: null,
        vatRegistrationDate: null,
        taxClearanceNumberIssueDate: null,
      }),
      [baseCompanyInfo?.companyId, userInfo.particularUserProfile.companyId]
    );

    const DEFAULT_COIDA_OBJECT: CoidaDetails = useMemo(
      () => ({
        id: 0,
        cfRegistrationNumber: null,
        certificateNumber: null,
        dateOfIssue: null,
        expiryDate: null,
        companyId: baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId,
        createdBy: "00000000-0000-0000-0000-000000000000",
        dateCreated: "",
        modifiedBy: "00000000-0000-0000-0000-000000000000",
        dateModified: "",
        isDeleted: false,
        deletedBy: null,
        dateDeleted: null,
        coidaSectionEnabled: false,
      }),
      [baseCompanyInfo?.companyId, userInfo.particularUserProfile.companyId]
    );

    const DEFAULT_BANKING_OBJECT: BankAccountDetailsModel = useMemo(
      () => ({
        id: 0,
        bankId: 0,
        accountTypeId: 0,
        accountName: baseCompanyInfo?.companyRegisteredName || null,
        accountNumber: null,
        branchName: null,
        swiftCode: null,
        branchCode: null,
        isDeleted: false,
        companyId: baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId,
        countryId: null,
        isPrimary: false,
        dateOfIssue: null,
        createdBy: "00000000-0000-0000-0000-000000000000",
        dateCreated: formatDate(new Date()),
        modifiedBy: "00000000-0000-0000-0000-000000000000",
        dateModified: formatDate(new Date()),
        bankAccountSectionEnabled: false,
      }),
      [
        baseCompanyInfo?.companyId,
        baseCompanyInfo?.companyRegisteredName,
        userInfo.particularUserProfile.companyId,
      ]
    );

    const [invalidFields, setInvalidFields] = useState<Array<string>>([] as Array<string>);

    const [companyProfileSettings, setCompanyProfileSettings] = useState<CompanyProfileSettings>({
      id: 0,
      companyId: baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId,
      hasBeeEnabled: false,
      hasTaxEnabled: false,
      hasVatEnabled: false,
      hasCoidaEnabled: false,
      hasBankAccountDetailsEnabled: false,
      postalSameAsPhysical: false,
      isDeleted: false,
      createdBy: "00000000-0000-0000-0000-000000000000",
      dateCreated: dayjs().format("YYYY-MM-DD"),
      modifiedBy: "00000000-0000-0000-0000-000000000000",
      dateModified: dayjs().format("YYYY-MM-DD"),
    } as CompanyProfileSettings);
    const [beeDetails, setBeeDetails] = useState<BeeDetailsModel>(DEFAULT_BEE_OBJECT);
    const [taxDetails, setTaxInformation] = useState<TaxInformation>(DEFAULT_TAX_INFO_OBJECT);
    const [initialTaxDetails, setInitialTaxInformation] =
      useState<TaxInformation>(DEFAULT_TAX_INFO_OBJECT);
    const [coidaDetails, setCoidaDetails] = useState<CoidaDetails>(DEFAULT_COIDA_OBJECT);
    const [bankDetails, setBankDetails] = useState<BankAccountDetailsModel>(DEFAULT_BANKING_OBJECT);
    const [isBankForeign, setIsBankForeign] = useState<boolean>(false);

    const validateBankingData = useCompanyBankingValidation(bankDetails, isBankForeign);

    const handleCompanyProfileSettingsChange = useCallback(
      (fieldname: string, value: boolean) => {
        const newVal = { ...companyProfileSettings, [fieldname]: value } as CompanyProfileSettings;
        setCompanyProfileSettings(newVal);
      },
      [companyProfileSettings]
    );

    // This is a special handler for Tax, since the section has 2 dependent-ish radio buttons.
    const handleCompanyProfileTaxSettingsChange = useCallback(
      (isSectionEnabled: boolean) => {
        if (!isSectionEnabled) {
          const newVal = {
            ...companyProfileSettings,
            hasTaxEnabled: false,
            hasVatEnabled: false,
          } as CompanyProfileSettings;
          setCompanyProfileSettings(newVal);

          return;
        }

        const newVal = { ...companyProfileSettings, hasTaxEnabled: true } as CompanyProfileSettings;
        setCompanyProfileSettings(newVal);
      },
      [companyProfileSettings]
    );

    const validateBeeData = (dataToValidate: BeeDetailsModel) => {
      const errorMessageArray: Array<string> = [];

      if (!companyProfileSettings?.hasBeeEnabled) return errorMessageArray;

      if (
        !dataToValidate.certificateIssueDate ||
        new Date(dataToValidate.certificateIssueDate).setHours(0, 0, 0, 0) >
        new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push("B-BBEE - Please capture a valid B-BBEE Certificate Issue Date.");

      if (
        !dataToValidate.certificateExpiryDate ||
        new Date(dataToValidate.certificateExpiryDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push("B-BBEE - Please capture a valid B-BBEE Certificate Expiry Date.");

      if (dataToValidate.sectorId === 0) {
        errorMessageArray.push("B-BBEE - Please capture a B-BBEE Sector.");
      }

      if (dataToValidate.annualTurnover === 0) {
        errorMessageArray.push("B-BBEE - Please capture a Turnover Range.");
      }

      if (dataToValidate.boPercentage === undefined || dataToValidate.boPercentage === null) {
        errorMessageArray.push("B-BBEE - Please capture a valid Black-Owned Percentage.");
      }

      if (dataToValidate.bwoPercentage === undefined || dataToValidate.bwoPercentage === null) {
        errorMessageArray.push("B-BBEE - Please capture a valid Black Women-Owned Percentage.");
      }

      if (!dataToValidate.certificateLevelId)
        errorMessageArray.push("B-BBEE - Please capture a B-BBEE certificate level.");

      if (dataToValidate.bwoPercentage && dataToValidate.boPercentage) {
        if (dataToValidate.bwoPercentage > dataToValidate.boPercentage) {
          errorMessageArray.push("B-BBEE - Black Woman-Owned % cannot exceed Black-Owned %.");
        }
      }

      return errorMessageArray;
    };

    const validateTaxData = (dataToValidate: TaxInformation) => {
      const errorMessageArray: Array<string> = [];

      if (!companyProfileSettings?.hasTaxEnabled) return errorMessageArray;

      if (!taxDetails.taxClearanceNumber) {
        errorMessageArray.push("SARS - Please capture a valid Tax Clearance PIN.");
      }

      if (
        !dataToValidate.taxClearanceNumberIssueDate ||
        new Date(dataToValidate.taxClearanceNumberIssueDate).setHours(0, 0, 0, 0) >
        new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push("SARS - Please capture a valid Tax Clearance Issue Date.");

      if (
        !dataToValidate.taxClearanceNumberExpiryDate ||
        new Date(dataToValidate.taxClearanceNumberExpiryDate).setHours(0, 0, 0, 0) <=
        new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push(
          "SARS - Please capture a valid Tax Clearance Expiry Date. (Expiry date cannot be in the past)"
        );

      if (companyProfileSettings?.hasVatEnabled) {
        if (taxDetails.vatNumber && taxDetails.vatNumber.length === 0) {
          errorMessageArray.push(
            "SARS - Please capture a valid VAT Number. (VAT number cannot be empty)"
          );
        }

        if (taxDetails.vatNumber && /^4\d{10}$/.test(taxDetails.vatNumber.toString())) {
          errorMessageArray.push(
            "SARS - Please capture a valid VAT Number. (Begins with 4, and is 10 digits)"
          );
        }

        if (
          !dataToValidate.vatRegistrationDate ||
          new Date(dataToValidate.vatRegistrationDate).setHours(0, 0, 0, 0) >
          new Date().setHours(0, 0, 0, 0)
        )
          errorMessageArray.push(
            "SARS - Please capture a valid VAT Registration Date. (VAT registration date cannot be in the future)"
          );
      }

      return errorMessageArray;
    };

    const validateCoidaData = (dataToValidate: CoidaDetails) => {
      const errorMessageArray: Array<string> = [];

      if (!companyProfileSettings?.hasCoidaEnabled) return errorMessageArray;

      if (!dataToValidate.cfRegistrationNumber)
        errorMessageArray.push(
          "COIDA - Please enter a valid Compensation Fund (CF) Registration Number."
        );

      if (!dataToValidate.certificateNumber)
        errorMessageArray.push("COIDA - Please enter a valid Certificate Number.");

      if (
        dataToValidate.dateOfIssue &&
        new Date(dataToValidate.dateOfIssue).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push(
          "COIDA - Please enter a valid Date of Issue. (Date of Issue cannot be in the future)"
        );

      if (
        dataToValidate.expiryDate &&
        new Date(dataToValidate.expiryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      )
        errorMessageArray.push("COIDA - Please enter a valid Expiry Date.");

      return errorMessageArray;
    };

    const saveCompanyProfileSettings = async (
      companyProfileSettingsData: CompanyProfileSettings
    ) => {
      const localCopy = { ...companyProfileSettingsData };
      // const currentDate = dayjs().format("YYYY-MM-DD");

      try {
        // If this is a new settings instance, save the instance.
        if (!localCopy.id) {
          // localCopy.companyId =
          //   baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId;
          // localCopy.createdBy =
          //   userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
          // localCopy.dateCreated = currentDate;
          // localCopy.modifiedBy =
          //   userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
          // localCopy.dateModified = currentDate;
          mutateCps({ mutationMode: "add", mutationDetails: localCopy });
          return;
        }

        // localCopy.modifiedBy =
        //   userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        // localCopy.dateModified = currentDate;
        mutateCps({ mutationMode: "update", mutationDetails: localCopy });
        // await UpdateCompanyProfileSettings(localCopy);
      } catch (error) {
        Swal.fire(
          "An error occurred when saving.",
          "Unable to save Company Profile Settings",
          "error"
        );
      }
    };

    const saveBeeDetails = async (beeInfo: BeeDetailsModel) => {
      const localCopy = { ...beeInfo };
      // If the section is disabled, and no new data has been added/edited
      if (localCopy.id === 0 && !companyProfileSettings?.hasBeeEnabled) return;

      // If the section was enabled initially, but is no longer enabled:
      if (localCopy.id > 0 && !companyProfileSettings?.hasBeeEnabled) {
        mutateBee({ mutationMode: "delete", mutationDetails: localCopy, staged: false });
        setBeeDetails({ ...beeDetails, id: 0 });
        return;
      }

      // If the company ID is still empty here, set the thing.
      if (!localCopy.companyId)
        localCopy.companyId =
          baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId;

      // Update if the ID is not 0
      if (localCopy.id > 0) {
        mutateBee({ mutationMode: "update", mutationDetails: localCopy, staged: false });
        return;
      }

      // Add new if the ID is 0
      if (!localCopy.isCurrent) localCopy.isCurrent = true;
      mutateBee({ mutationMode: "add", mutationDetails: localCopy, staged: false });
      // await AddNewBeeDetails(localCopy, false);
    };

    const saveTaxDetails = async (taxInfo: TaxInformation) => {
      const localCopy = { ...taxInfo };

      if (!localCopy.companyId)
        localCopy.companyId =
          baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId;

      if (!companyProfileSettings?.hasTaxEnabled) {
        if (initialTaxDetails && initialTaxDetails.taxClearanceNumber) {
          mutateTax({
            mutationMode: "delete",
            mutationDetails: localCopy,
          });
          setTaxInformation(DEFAULT_TAX_INFO_OBJECT);
          setInitialTaxInformation(DEFAULT_TAX_INFO_OBJECT);
        }
        return;
      }

      // Using "add" or "update" will achieve the same objective here.
      mutateTax({ mutationMode: "update", mutationDetails: localCopy });
    };

    const saveCoidaDetails = async (coidaInfo: CoidaDetails) => {
      const localCopy = { ...coidaInfo };
      const currentDate = dayjs().format("YYYY-MM-DD");

      if (localCopy.id === 0 && !companyProfileSettings?.hasCoidaEnabled) return;

      if (coidaInfo.id > 0 && !companyProfileSettings?.hasCoidaEnabled) {
        localCopy.dateCreated = currentDate;
        localCopy.createdBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        localCopy.modifiedBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        localCopy.dateModified = currentDate;
        localCopy.deletedBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        localCopy.dateDeleted = currentDate;

        mutateCoida({
          mutationMode: "delete",
          mutationDetails: localCopy,
        });
        setCoidaDetails(DEFAULT_COIDA_OBJECT);
        return;
      }

      // If the company ID is still empty here, set the thing.
      if (!localCopy.companyId)
        localCopy.companyId =
          baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId;

      if (coidaInfo.id > 0) {
        localCopy.dateModified = currentDate;
        localCopy.modifiedBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        mutateCoida({
          mutationMode: "update",
          mutationDetails: localCopy,
        });
        return;
      }

      localCopy.createdBy =
        userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
      localCopy.dateCreated = currentDate;
      localCopy.modifiedBy =
        userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
      localCopy.dateModified = currentDate;
      mutateCoida({
        mutationMode: "add",
        mutationDetails: localCopy,
      });
    };

    const saveBankDetails = async (bankInfo: BankAccountDetailsModel) => {
      const localCopy = { ...bankInfo };
      const currentDate = dayjs().format("YYYY-MM-DD");

      if (localCopy.id === 0 && !companyProfileSettings?.hasBankAccountDetailsEnabled) return;

      if (localCopy.id > 0 && !companyProfileSettings?.hasBankAccountDetailsEnabled) {
        localCopy.deletedBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        localCopy.dateDeleted = currentDate;
        mutateBanking({
          mutationMode: "delete",
          mutationDetails: localCopy,
        });
        setBankDetails(DEFAULT_BANKING_OBJECT);
        return;
      }

      // Remove bankId and accountTypeId if bank is foreign.
      if (isBankForeign) {
        delete localCopy.bankId;
        delete localCopy.accountTypeId;
      }

      if (!localCopy.companyId)
        localCopy.companyId =
          baseCompanyInfo?.companyId || userInfo.particularUserProfile.companyId;

      if (localCopy.id > 0) {
        localCopy.modifiedBy =
          userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
        localCopy.dateModified = currentDate;
        mutateBanking({
          mutationMode: "update",
          mutationDetails: localCopy,
        });
        return;
      }

      localCopy.createdBy =
        userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
      localCopy.dateCreated = currentDate;
      localCopy.modifiedBy =
        userInfo.particularUserProfile.accountId || "00000000-0000-0000-0000-000000000000";
      localCopy.dateModified = currentDate;
      mutateBanking({
        mutationMode: "add",
        mutationDetails: localCopy,
      });
    };

    const onSubmitHandler = async () => {
      const response: RegistrationStepResponse = {
        submittedSuccessfully: false,
        errorMessages: [],
      };

      try {
        // Step 1: Validation:
        const beeInfoErrors: Array<string> = validateBeeData(beeDetails);
        const taxInfoErrors: Array<string> = validateTaxData(taxDetails);
        const coidaInfoErrors: Array<string> = validateCoidaData(coidaDetails);

        const overallErrors = [...beeInfoErrors, ...taxInfoErrors, ...coidaInfoErrors];

        // Thar be validation nonsense! Arrrr!
        if (overallErrors.length > 0) {
          response.errorMessages = overallErrors;
          response.submittedSuccessfully = false;
          return response;
        }

        if (companyProfileSettings?.hasBankAccountDetailsEnabled) {
          const validationResults = validateBankingData();

          if (validationResults.isInvalid) {
            setInvalidFields(validationResults.invalidFields);
            return {
              submittedSuccessfully: false,
              errorMessages: validationResults.validationErrors,
            } as RegistrationStepResponse;
          }
        }

        // The API call will be made here.
        await saveBeeDetails({ ...beeDetails });
        await saveTaxDetails({ ...taxDetails });
        await saveCoidaDetails(coidaDetails);
        await saveBankDetails(bankDetails);

        if (companyProfileSettings) await saveCompanyProfileSettings(companyProfileSettings);

        response.submittedSuccessfully = true;

        return response;
      } catch (error) {
        response.errorMessages.push("Something went wrong when saving compliance data.");
        response.submittedSuccessfully = false;
        return response;
      }
    };

    const onSaveAndContinueHandler = async () => {
      try {
        await saveBeeDetails(beeDetails);
        await saveTaxDetails(taxDetails);
        await saveCoidaDetails(coidaDetails);
        await saveBankDetails(bankDetails);
        await saveCompanyProfileSettings(companyProfileSettings);

        return {
          submittedSuccessfully: true,
          errorMessages: [],
        };
      } catch (error) {
        return {
          submittedSuccessfully: false,
          errorMessages: ["Something went wrong when saving compliance data."],
        };
      }
    };

    const setModelState = useCallback(async () => {
      if (!isCompanyProfileSettingsLoading && companyProfileSettingsQuery)
        setCompanyProfileSettings(companyProfileSettingsQuery);

      if (!isCompanyBeeDetailsLoading && beeDetailsQuery) setBeeDetails(beeDetailsQuery);

      if (!isCompanyTaxDetailsLoading && companyTaxDetailsQuery) {
        setTaxInformation(companyTaxDetailsQuery);
        setInitialTaxInformation(companyTaxDetailsQuery);
      }

      if (!isCompanyCoidaDetailsLoading && companyCoidaQuery) setCoidaDetails(companyCoidaQuery);

      if (!isCompanyBankingDetailsLoading && bankingDetailsQuery) {
        const firstBank =
          bankingDetailsQuery.length > 0 ? bankingDetailsQuery[0] : DEFAULT_BANKING_OBJECT;
        setBankDetails(firstBank);
      }
    }, [
      DEFAULT_BANKING_OBJECT,
      bankingDetailsQuery,
      beeDetailsQuery,
      companyCoidaQuery,
      companyProfileSettingsQuery,
      companyTaxDetailsQuery,
      isCompanyBankingDetailsLoading,
      isCompanyBeeDetailsLoading,
      isCompanyCoidaDetailsLoading,
      isCompanyProfileSettingsLoading,
      isCompanyTaxDetailsLoading,
    ]);

    const foreignBankCheck = useCallback(() => {
      if (!bankDetails || bankDetails.countryId === 0) return;

      bankDetails.countryId !== southAfricanCountryId
        ? setIsBankForeign(true)
        : setIsBankForeign(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onSubmit: onSubmitHandler,
      onSaveAndContinue: onSaveAndContinueHandler,
    }));

    useEffect(() => {
      setModelState();
      if (!isCompanyBankingDetailsLoading) foreignBankCheck();
    }, [
      baseCompanyInfo?.companyId,
      foreignBankCheck,
      isBanksLoading,
      isCompanyBankingDetailsLoading,
      setModelState,
    ]);

    if (
      isBaseCompanyInfoLoading ||
      isCompanyProfileSettingsLoading ||
      isBeeSectorsLoading ||
      isBeeLevelsLoading ||
      isBanksLoading ||
      isCountriesLoading ||
      isCompanyBeeDetailsLoading ||
      isCompanyCoidaDetailsLoading ||
      isCompanyTaxDetailsLoading ||
      isCompanyBankingDetailsLoading
    )
      return <RegistrationSkeleton />;

    return (
      <>
        <BeeInfo
          beeDetailsModel={beeDetails}
          sectorsList={beeSectors}
          levelsList={beeLevels}
          sectionEnabled={companyProfileSettings?.hasBeeEnabled || false}
          updateSectionEnabled={handleCompanyProfileSettingsChange}
          updateDataModel={setBeeDetails}
        />

        <TaxInfo
          taxInfoModel={taxDetails}
          updateTaxInfoModel={setTaxInformation}
          sectionEnabled={companyProfileSettings?.hasTaxEnabled || false}
          vatSectionEnabled={companyProfileSettings?.hasVatEnabled || false}
          updateSectionEnabled={handleCompanyProfileTaxSettingsChange}
          updateVatSectionEnabled={handleCompanyProfileSettingsChange}
        />

        <CoidaInfo
          coidaDetailsModel={coidaDetails}
          updateCoidaDetailsModel={setCoidaDetails}
          sectionEnabled={companyProfileSettings?.hasCoidaEnabled || false}
          updateSectionEnabled={handleCompanyProfileSettingsChange}
        />

        <BankingInfo
          bankingDetailsModel={bankDetails}
          companyName={baseCompanyInfo?.companyRegisteredName || ""}
          updateBankingDetailsModel={setBankDetails}
          setForeignBankFlag={setIsBankForeign}
          bankList={bankList}
          invalidFields={invalidFields}
          countryList={countries}
          southAfricanCountryId={southAfricanCountryId}
          sectionEnabled={companyProfileSettings?.hasBankAccountDetailsEnabled || false}
          updateSectionEnabled={handleCompanyProfileSettingsChange}
        />
      </>
    );
  }
);

export default ComplianceInformationStepThree;
