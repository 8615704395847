import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import getUnspscOptions from "../../../http/Configuration/configurationApi";

const useUnspcOptions = () => {
  const query = useQuery({
    queryKey: [queryKeys.unspscOptions],
    queryFn: getUnspscOptions,
    staleTime: 600000, 
  })

  return query;
}

export default useUnspcOptions;