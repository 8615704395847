import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetSectors } from "../../Company/beeDetailsApi";
import { SectorModel } from "../../Company/Models/SectorModel";
import beeSectorListSlice from "./BeeSectorListSlice";

export const beeSectorListActions = beeSectorListSlice.actions;

export const fetchBeeSectors = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<SectorModel> = (await GetSectors()).data;
  dispatch(beeSectorListActions.setBeeSectorListState(apiResponse));
}