import HelpIcon from "@mui/icons-material/Help";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { useRef, useState } from "react";
import TextField from "../../Registration/CustomComponents/Controls/TextField";
import ButtonGroup from "../../Registration/Sections/ButtonGroup";
import InvitationsListComponent, { InvitationListPropsComponent, NewConnectionInvitationMessage } from "./InvitationsListComponent";
import generateRandomString from "../../Registration/Utils/generateRandomString";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import { SendNewConnectionRequests } from "../../../http/Company/cleanApi";
import ConnectionListUploadButton from "../../../components/Company/ConnectionListUploadButton";
import ConnectionType from "../../../util/enums/ConnectionType";
import { colorLightGrey } from "../../../util/MUI_Theme/Theme";

const primaryRepresentativePlaceHolder = "[Primary company representative full name]";
const companyNamePlaceHolder = "[Company Name]"
const invitedCompanyNamePlaceHolder = "[Invited Company Name]"

const getDefaultMessage = (type: ConnectionType) => {
  const role = type === ConnectionType.Client ? "Client" : "Supplier";
  return `Hello ${primaryRepresentativePlaceHolder},
\n${companyNamePlaceHolder} has requested for you to join their B1LINK Network as their ${role}!
\nPlease follow this link to register and set-up your account & profile for ${invitedCompanyNamePlaceHolder}`;
};

const Invite = ({ onHandleClose: onClose, connectionType }: { onHandleClose: () => void, connectionType: ConnectionType }) => {
  const baseCompanyInfo = useBaseCompanyInfo();
  const [messageText, setMessageText] = useState(getDefaultMessage(connectionType));
  const [isSaving, setIsSaving] = useState(false);
  const invitationsRef = useRef<InvitationListPropsComponent>(null);

  const onSubmitHandler = async () => {
    const invitationMessages = invitationsRef.current?.internalData.map((cr) => ({
      ...cr,
      messageText: messageText
        .replaceAll(primaryRepresentativePlaceHolder, cr.fullName)
        .replaceAll(invitedCompanyNamePlaceHolder, cr.registeredCompanyName)
        .replaceAll(companyNamePlaceHolder, baseCompanyInfo.data?.companyRegisteredName ?? ""),
      connectionType,
    }));

    if (invitationMessages == null) { return; }

    try {
      setIsSaving(true);
      const isValid = await invitationsRef.current?.validate();
      if (isValid) {
        await SendNewConnectionRequests(invitationMessages);
        onClose();
      }
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  }

  const uniqueKey = generateRandomString(6);

  return (
    <Box py={5}>
      <InvitationsListComponent
        defaultData={[{}] as NewConnectionInvitationMessage[]}
        ref={invitationsRef}
        uniqueKey={uniqueKey}
        canAddMore
      />
      <Divider sx={{ borderColor: colorLightGrey, borderWidth: "thin", minWidth: "100%" }} />
      <Grid container px={10} pb={10} py={5}>
        <Grid item xs={12} mb={5}>
          <ConnectionListUploadButton connectionType={connectionType}>
            IMPORT CSV FILE
          </ConnectionListUploadButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="inputLabel">
            Standard Message
            <HelpIcon className="icon-adv-help" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="standardMessage"
            placeholder="Enter a personalised message"
            value={messageText}
            onChange={(ev) => setMessageText(ev.currentTarget.value)}
            multiline
            minRows={5}
            disabled
          />
        </Grid>
      </Grid>
      <Box pb={3} mb={3}>
        <ButtonGroup
          px={8}
          buttons={[{
            widthInPx: 250,
            label: "send request(s)",
            align: "right",
            type: "primary",
            disabled: isSaving,
            isLoading: isSaving,
            onClick: () => onSubmitHandler(),
          }]}
        />
      </Box>
    </Box>
  )
}

export default Invite;
