import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";

const CompanyAdminUserPost = ({
  headerText = "",
  adminName = "",
  adminRole = "",
  adminEmail = "",
  adminMobileNo = "",
}: {
  headerText: string;
  adminName: string;
  adminRole: string;
  adminEmail: string;
  adminMobileNo: string;
}) => (
  <Accordion expanded className="Accord-Hide">
    <AccordionSummary
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      className="Accord-heading"
    >
      <Typography sx={{ width: "33%", flexShrink: 0 }}>
        <h3 className="heading-sub-3">{headerText}</h3>
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={1}>
        <Grid item xs={2}><b>Name :</b></Grid>
        <Grid item xs={9}>{adminName}</Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}><b>Role :</b></Grid>
        <Grid item xs={9}>{adminRole}</Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}><b>Email :</b></Grid>
        <Grid item xs={9}>{adminEmail}</Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}><b>Contact Number:</b></Grid>
        <Grid item xs={9}>{adminMobileNo}</Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default CompanyAdminUserPost;
