import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { PermissionModel, PermissionArrayModel } from "../Models/PermissionModel";

const initialPermissionState: PermissionArrayModel = {
  allPermissions: [],
  particularPermission: {
    id: 0,
    name: "",
    description: "",
    isActive: false,
    featureName: "",
    moduleId: undefined,
    classification: undefined,
    dateCreated: new Date(),
    dateModified: new Date(),
    createdBy: "",
    modifiedBy: "",
  },
}

const PermissionSlice = createSlice({
  name: "permission",
  initialState: initialPermissionState,
  reducers: {
    setPermission(state, action: PayloadAction<PermissionModel[]>) {
      state.allPermissions = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularPermission(state, action: PayloadAction<PermissionModel>) {
      state.particularPermission = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export default PermissionSlice;

export const getAllPermissions = (state: RootState) => state.permission.allPermissions;