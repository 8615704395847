/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum RequestType {
  None = 0,
  Call = 1,
  Google = 2,
  Email = 3,
  Other = 4,
}

export default RequestType;

export const requestTypeToString = (value: RequestType) => {
  switch (value) {
    case RequestType.None:
      return "None"
    case RequestType.Call:
      return "Call"
    case RequestType.Google:
      return "Google"
    case RequestType.Email:
      return "Email"
    case RequestType.Other:
      return "Other"
    default:
      return "Unknown";
  }
};