// eslint-disable-next-line no-shadow
enum CompanyProfileSideBar { // eslint-disable-line no-unused-vars
  General = 1, // eslint-disable-line no-unused-vars
  Contacts = 2, // eslint-disable-line no-unused-vars
  Address = 3, // eslint-disable-line no-unused-vars
  Bee = 4, // eslint-disable-line no-unused-vars
  Bank = 5, // eslint-disable-line no-unused-vars
  Services = 6, // eslint-disable-line no-unused-vars
  Documents = 7, // eslint-disable-line no-unused-vars
  ProfileSharing = 8, // eslint-disable-line no-unused-vars
  CompanySpecificClientDetails = 9, // eslint-disable-line no-unused-vars
  CompanyShareholders = 10, // eslint-disable-line no-unused-vars
}

export default CompanyProfileSideBar;

export const companyProfileSideBarToString = (value: CompanyProfileSideBar) => {
  switch (value) {
    case CompanyProfileSideBar.General:
      return "Basic Information";
    case CompanyProfileSideBar.Contacts:
      return "Contacts";
    case CompanyProfileSideBar.Address:
      return "Address Information";
    case CompanyProfileSideBar.Bee:
      return "B-BBEE Details";
    case CompanyProfileSideBar.Bank:
      return "Bank Details";
    case CompanyProfileSideBar.Services:
      return "Products & Services";
    case CompanyProfileSideBar.Documents:
      return "Documents";
    case CompanyProfileSideBar.ProfileSharing:
      return "Share My Profile";
    case CompanyProfileSideBar.CompanySpecificClientDetails:
      return "Company-Specific Client Details"
    case CompanyProfileSideBar.CompanyShareholders:
      return "Company Shareholders"
    default:
      return "Unknown";
  }
};