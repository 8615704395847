/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum ConnectionStatus {
  None = 0,
  InvitationSent = 1,
  Pending = 2,
  RequestCanceled = 3,
  Accepted = 4,
  Declined = 5,
  OnHold = 6,
  ResentInvitation = 7,
  Following = 8,
  Unfollow = 9,
}

export default ConnectionStatus;

export const connectionStatusToString = (value: ConnectionStatus) => {
  switch (value) {
    case ConnectionStatus.None:
      return "None";
    case ConnectionStatus.InvitationSent:
      return "Invite Sent";
    case ConnectionStatus.Pending:
      return "Pending";
    case ConnectionStatus.RequestCanceled:
      return "Request Canceled";
    case ConnectionStatus.Accepted:
      return "Accepted";
    case ConnectionStatus.Declined:
      return "Declined";
    case ConnectionStatus.OnHold:
      return "On Hold";
    case ConnectionStatus.ResentInvitation:
      return "Invite Resent";
    case ConnectionStatus.Following:
      return "Following";
    case ConnectionStatus.Unfollow:
      return "Unfollowed";
    default:
      return "Unknown Connection Status";
  }
};
