// eslint-disable-next-line no-shadow
enum PaymentStatusEnum { // eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  Pending = 1,// eslint-disable-line no-unused-vars
  Success = 2,// eslint-disable-line no-unused-vars
  Failed = 3,// eslint-disable-line no-unused-vars
  Cancelled = 4// eslint-disable-line no-unused-vars
}

export default PaymentStatusEnum;

export const paymentStatusToString = (value: PaymentStatusEnum) => {
  switch (value) {
    case PaymentStatusEnum.None:
      return "None"
    case PaymentStatusEnum.Pending:
      return "Pending"
    case PaymentStatusEnum.Success:
      return "Success"
    case PaymentStatusEnum.Failed:
      return "Failed"
    case PaymentStatusEnum.Cancelled:
      return "Cancelled"
    default:
      return "Unknown";
  }
};

export const paymentStatusColor = (value: PaymentStatusEnum) => {
  switch (value) {
    case PaymentStatusEnum.None:
      return "black"
    case PaymentStatusEnum.Pending:
      return "orange"
    case PaymentStatusEnum.Success:
      return "green"
    case PaymentStatusEnum.Failed:
      return "red"
    case PaymentStatusEnum.Cancelled:
      return "red"
    default:
      return "black";
  }
};