import MutliLevelItemSelector from "../MutliLevelItemSelector";
import ClientSpecificSupplierCategoryMessage from "../../../../http/Configuration/ClientSpecificSupplierCategoryMessage";

interface ClientSpecificSupplierCategoriesSelectorProps {
  categories: Array<ClientSpecificSupplierCategoryMessage>;
  selectedCategories: Array<number>;
  // eslint-disable-next-line no-unused-vars
  showSelectorCallback: (isOpen: boolean) => void;
  isShowing: boolean;
  // eslint-disable-next-line no-unused-vars
  submitSelectionCallback: (selection: Array<number>) => void;
}

const ClientSpecificSupplierCategoriesSelector = ({
  categories,
  selectedCategories,
  showSelectorCallback: showSelectorFunction,
  isShowing,
  submitSelectionCallback,
}: ClientSpecificSupplierCategoriesSelectorProps) =>
  isShowing ? (
    <MutliLevelItemSelector
      options={categories}
      subCategorySelection={selectedCategories}
      isLoadingSearchResults={false}
      handleClose={() => showSelectorFunction(false)}
      onSubmitSelectionCallback={submitSelectionCallback}
      title="SUPPLIER CATEGORIES"
      description="Please select the applicable supplier categories"
    />
  ) : null;

export default ClientSpecificSupplierCategoriesSelector;
