import React from "react";
import { Box } from "@mui/material";

interface BlurredBoxProps {
  blurContent: boolean;
  children: React.ReactNode;
}

const BlurredBox: React.FC<BlurredBoxProps> = ({ blurContent, children }) => {
  const blurredContainerStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const blurredBackgroundStyles = {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: blurContent ? "block": "none",
    backdropFilter: "blur(15px)",
    borderRadius: "8px",
    zIndex: 2,
  };

  const contentBoxStyles = {
    zIndex: 1,
    margin: "20px",
    padding: "20px",
    backgroundColor: blurContent ? "rgba(255, 255, 255, 0.8)" : "transparent", 
  };

  return (
    <Box sx={blurredContainerStyles}>
      <Box sx={blurredBackgroundStyles} />
      <Box sx={contentBoxStyles}>
        {/* Content inside the blurred box */}
        {children}
      </Box>
    </Box>
  );
};

export default BlurredBox;
