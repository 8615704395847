import { Stack, Skeleton, Grid } from "@mui/material";
import { pxToRem } from "../../../util/MUI_Theme/Theme";

const RegistrationSkeleton = (
  { fontSize = 35 }
    :
    // eslint-disable-next-line react/require-default-props
    { fontSize?: number }) => {

  const fontSizeInRem = pxToRem(fontSize);
  return (
    <Grid container spacing={2} rowSpacing={2} sx={{ paddingTop: "40px", paddingLeft: "20px", paddingRight: "20px" }}>
      <Grid xs={12} ml={2}>
        <Skeleton variant="rectangular" height={80} width="50%" />
      </Grid>
      <Grid xs={12} md={4} item>
        <Stack spacing={1} >
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
        </Stack>
      </Grid>
      <Grid xs={12} md={4} item>
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
        </Stack>
      </Grid>
      <Grid xs={12} md={4} item>
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
          <Skeleton variant="text" sx={{ fontSize: fontSizeInRem }} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default RegistrationSkeleton;