import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import AccountAccess from "../../components/AccountPreferences/AccountAccess";
import AccountHeader from "../../components/AccountPreferences/AccountHeader";
import "../../util/Styling/UserProfile.css";
import { ProfileImageModel } from "../../http/Redux/Models/ProfileImageModel";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import WithRequiredPermission from "../../components/WithRequiredPermission";
import PermissionClassification from "../../util/enums/PermissionClassification";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import UserProfileDetails from "../../components/AccountPreferences/UserProfileDetails";

const AccountPreferences = () => {
  const [show] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const profileImage = useAppSelector((state: {
    profileImage: {
      particularProfileImage: ProfileImageModel
    };
  }) => state.profileImage.particularProfileImage);

  const userProfile = useAppSelector((state: {
    userProfile: { particularUserProfile: UserProfileModel };
  }) => state.userProfile.particularUserProfile);

  const title = `${userProfile.name} ${userProfile.surname}'s Profile`;

  return (
    <div>

      <LoadingBackdrop showBackdrop={show} />
      <div className="wrap-no-padding">
        <div className="profile-box">
          <div className="profile-section" id="step-user">
            <Accordion expanded={expanded === "panel1" || expanded !== "panel2"} onChange={handleChange("panel1")}>
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3 className="heading-sub">{userProfile.id === 0 ? "User Profile" : title}</h3>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccountAccess />
                <AccountHeader Image={profileImage as ProfileImageModel} />
                <WithRequiredPermission permission={PermissionClassification.ManageMessaging}>
                  <UserProfileDetails />
                </WithRequiredPermission>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>

  );
}

export default AccountPreferences;