/* eslint-disable react/require-default-props */
import { useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField, Typography, Box, Chip } from "@mui/material";
import debounce from "lodash.debounce";
import { useQueryClient } from "@tanstack/react-query";
import { BaseCompanyInfo } from "../../http/Company/Models/BaseCompanyInfo";
import { GetBaseCompanyInfoViaCompanyId, GetSomeBasicCompanyInfoAsync } from "../../http/Company/company";
import { UserMessage } from "../../http/CompanyUserManagement/Messages/UserMessage";
import { GetCompanyUsersWithId } from "../../http/CompanyUserManagement/userManagementApi";
import { InitializeChatGroup } from "../../http/Messaging/Messaging";
import InLineSpinner from "../LoadingSpinners/InlineSpinner";
import DialogModal from "../Dialogs/DialogModal";
import ButtonGroup from "../../pages/Registration/Sections/ButtonGroup";
import useBaseCompanyInfo from "../../util/hooks/queries/useBaseCompanyInfo";
import queryKeys from "../../util/hooks/queries/queryKeys";



const SendNewMessage = ({
  open,
  handleClose,
  handleRefresh,
  companyName,
  companyId,
}: {
  open: boolean;
  handleClose: () => void;
  handleRefresh: () => void;
  companyName?: string;
  companyId?: number;
}) => {
  const { data: company } = useBaseCompanyInfo();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [loadingReceiving, setLoadingReceiving] = useState(false);
  const [receiving, setReceiving] = useState({} as BaseCompanyInfo);
  const [companies, setCompanies] = useState([] as BaseCompanyInfo[]);
  const [users, setUsers] = useState([] as UserMessage[]);
  const [message, setMessage] = useState("");
  const [selectedAll, setSelectedAll] = useState<Array<string>>([] as Array<string>);
  const [isFromDashboard, setIsFromDashboard] = useState(false);



  const handleOnSearchTextChange = (searchText: string) => {
    setLoading(true);

    GetSomeBasicCompanyInfoAsync(searchText)
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendMessage = async () => {
    setLoading(true);
    selectedAll.forEach((element) => {
      InitializeChatGroup({
        senderCompanyId: company?.companyId as number,
        senderCompanyName: company?.companyRegisteredName as string,
        receiverCompanyId: receiving.companyId as number,
        receiverCompanyName: receiving.companyRegisteredName as string,
        users: element ? [element] : users.map((u: UserMessage) => u.accountId),
        message,
      })
        .then((res) => {
          console.log(res);
          handleRefresh();
          const load = async () => {
            queryClient.refetchQueries({ queryKey: [queryKeys.messaging] });
          }
          load();
        })
        .catch((err) => {
          console.log(err);
        });
    });
    setSelectedAll([]);
    setLoading(false);
    handleClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResults = useMemo(() => debounce(handleOnSearchTextChange, 1000), []);

  const handleCompanySelection = (name: string) => {
    if (name === "") {
      setUsers([]);
    } else {
      debouncedResults(name);
    }
  };

  const handleContactSend = (u: string) => {
    if (selectedAll.find((x) => x === u)) {
      setSelectedAll((prevSelectedAll) => prevSelectedAll.filter((element) => element !== u));
    } else {
      setSelectedAll((prevSelectedAll) => [...prevSelectedAll, u]);
    }
  };

  useEffect(() => {
    debouncedResults.cancel();

    if (companyName !== undefined) {
      setLoadingReceiving(true);
      debouncedResults(companyName as string);

      const set = async () => {
        setUsers(await GetCompanyUsersWithId(companyId as number));
        setIsFromDashboard(true);

        const getCompany = await GetBaseCompanyInfoViaCompanyId(
          companyId?.toString() as string,
          false
        );
        setReceiving(getCompany ?? ({} as BaseCompanyInfo));
        setLoadingReceiving(false);
      };
      set();
    }
  }, [companyId, companyName, users.length, receiving.companyId]);

  return (
    <DialogModal
      isOpen={open}
      onHandleClose={handleClose}
    >
      <Box p={3} style={{ width: "600px", marginTop: 15, marginBottom: 20 }}>
        {loadingReceiving ? (<InLineSpinner />) : (
          <div>
            {!isFromDashboard ? (
              <>
                <Typography sx={{ fontSize: "13px" }}>Select Company:</Typography>
                <Autocomplete
                  id="company-search"
                  disabled={isFromDashboard}
                  clearOnEscape
                  loading={loading}
                  options={companies}
                  getOptionLabel={(option) => option.companyRegisteredName ?? "undefined"}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      onChange={(ev) => {
                        handleCompanySelection(ev.target.value);
                      }}
                    />
                  )}
                  onChange={async (ev, value) => {
                    if (!value) {
                      setUsers([]);
                    } else {
                      setUsers(await GetCompanyUsersWithId(value?.companyId as number));
                      setReceiving(value ?? ({} as BaseCompanyInfo));
                      setCompanies([value ?? ({} as BaseCompanyInfo)]);
                    }
                  }}
                />
              </>
            ) : (
              <Typography sx={{ fontSize: "13px" }}>Sending Message to {companyName}</Typography>
            )}
            {users.length > 0 && (
              <>
                <Typography sx={{ marginTop: "10px", fontSize: "13px" }}>Send To:</Typography>
                <div
                  className="chip-box"
                  style={{
                    height: "100px",
                    width: "100%",
                    overflow: "scroll",
                    border: "1px solid lightgrey",
                    borderRadius: 1,
                    padding: 1,
                  }}
                >
                  <Chip
                    color={selectedAll.length === 0 ? "primary" : "default"}
                    label="All"
                    style={{
                      marginTop: "5px",
                      borderRadius: 3,
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      setSelectedAll([]);
                    }}
                  />

                  {users.map((u) => (
                    <Chip
                      key={u.id}
                      color={selectedAll.find((x) => x === u.accountId) ? "primary" : "default"}
                      label={u.name}
                      style={{
                        marginTop: "5px",
                        borderRadius: 3,
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                      onClick={() => handleContactSend(u.accountId)}
                    />
                  ))}
                </div>
                <Typography sx={{ marginTop: "10px", fontSize: "13px" }}>Message:</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%" }}
                  multiline
                  rows={4}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </>
            )}
            {users.length === 0 && (
              <Box sx={{ marginTop: "10px" }}>
                <Typography>
                  <i>No user details to show.</i>
                </Typography>
              </Box>
            )}
          </div>
        )}
        <br />
        <ButtonGroup
          buttons={[
            {
              label: "SEND MESSAGE",
              align: "right",
              disabled: message === "",
              onClick: handleSendMessage,
              type: "outlined",

            },
          ]}
        />
      </Box>
    </DialogModal>
  );
};

export default SendNewMessage;