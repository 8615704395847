import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompanyWorkCentreHolderModel } from "../Models/CompanyWorkCentreHolderModel";


const State: CompanyWorkCentreHolderModel = {
  dataCompanyId: 0,
  matchCompanyId: 0,
}

const CompanyWorkCentreHolderSlice = createSlice({
  name: "companyWorkCentreHolder",
  initialState: State,
  reducers: {
    setCompanyWorkCentreHolder(state, action: PayloadAction<CompanyWorkCentreHolderModel>) {
      state.dataCompanyId = action.payload.dataCompanyId; // eslint-disable-line no-param-reassign
      state.matchCompanyId = action.payload.matchCompanyId; // eslint-disable-line no-param-reassign
    },
  },
})


export const getSetCompanyHolder = (state: RootState) => state.companyWorkCentreHolder;
export default CompanyWorkCentreHolderSlice;