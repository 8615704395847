import React, { useEffect, useRef, useState } from "react";
import { MenuItem, TextField, Grid, Tooltip, SelectChangeEvent, FormControl, InputLabel, Select, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faFloppyDisk, faPencilSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BankAccountDetailsModel } from "../../../../http/Company/Models/BankAccountDetailsModel";
import "../CompanyPost.css";
import { selectBankNameList } from "../../../../http/Redux/Store/BankNameListSlice";
import { selectbankAccountTypeList } from "../../../../http/Redux/Store/BankAccountTypeListSlice";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { removeUnsavedBankAccFromState, setCompanyBankAccountState } from "../../../../http/Redux/Store/companySlice";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import WithRequiredPermission from "../../../WithRequiredPermission";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";

interface BankAccountProps {
  /* eslint-disable no-unused-vars */
  bankAccountDetails: BankAccountDetailsModel;
  SetDirtyCallback: (isDirty: boolean) => void;
  OnBankSave: (bankDetails: BankAccountDetailsModel) => void;
  OnBankDelete: (bankAccountToDelete: BankAccountDetailsModel, staged: boolean) => Promise<void>;
  /* eslint-disable no-unused-vars */
}

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const BankAccount = ({
  bankAccountDetails,
  SetDirtyCallback,
  OnBankSave,
  OnBankDelete,
}: BankAccountProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const initialLoadCheck = useRef(true);

  const bankNames = useAppSelector(selectBankNameList);
  const bankAccountTypes = useAppSelector(selectbankAccountTypeList);

  const [localBank, setLocalBank] = useState<BankAccountDetailsModel>(
    bankAccountDetails as BankAccountDetailsModel
  );

  const [bankAccountComparison, setBankAccountComparison] =
    useState<BankAccountDetailsModel>({} as BankAccountDetailsModel);
  const [validateField] = useFieldValidation("companyProfile");

  const [edit, setEdit] = useState<boolean>(false);

  const debouncedBankValue = useInputDebounce(localBank);

  // Validation Error State Variables:
  const [bankNameError, setBankNameError] = useState<boolean>(false);
  const [bankAccTypeError, setBankAccTypeError] = useState<boolean>(false);
  const [bankAccNameError, setBankAccNameError] = useState<boolean>(false);
  const [bankAccNumberError, setBankAccNumberError] = useState<boolean>(false);
  const [bankBranchNameError, setBankBranchNameError] = useState<boolean>(false);
  const [bankBranchCodeError, setBankBranchCodeError] = useState<boolean>(false);

  // Cheap and cheerful validation... Need this to be better.
  const validateBankAccount = () => {
    if (
      bankNameError ||
      bankAccTypeError ||
      bankAccNameError ||
      bankAccNumberError ||
      bankBranchNameError ||
      bankBranchCodeError
    )
      return false;

    return true;
  };

  const EnableEdit = () => {
    if (edit) {
      const isValid = validateBankAccount();
      if (!isValid) {
        Swal.fire({
          title: "Validation Errors",
          text: "Please resolve all validation errors, and try again",
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowOutsideClick: false,
        });

        return;
      }

      OnBankSave(localBank);

      SetDirtyCallback(false);
    }

    setEdit(!edit);
  };

  const DeleteThisBankAccount = () => {
    if (edit) {
      Swal.fire({
        title: "Are you sure you want to delete this bank account?",
        icon: "question",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          OnBankDelete(localBank, false);
        }
      });
    }

    setEdit(!edit);
  };

  const cancelEdit = () => {
    setEdit(!edit);
    setLocalBank(bankAccountComparison);

    SetDirtyCallback(false);
  };

  const cancelNewAccount = () => {
    dispatch(removeUnsavedBankAccFromState());
    setEdit(!edit);
    SetDirtyCallback(false);
  };

  const performDirtyCheck = (checkVal: BankAccountDetailsModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(bankAccountComparison)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...localBank, [propName]: event.target.value };
    setLocalBank(newVal);
    performDirtyCheck(newVal);
  };

  const handleSelectValueChange = (event: SelectChangeEvent<number>, propName: string) => {
    const newVal = { ...localBank, [propName]: event.target.value };
    setLocalBank(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      setBankAccountComparison(JSON.parse(JSON.stringify(bankAccountDetails)));
      initialLoadCheck.current = false;
    }
  }, [
    bankAccountDetails.id,
  ]);

  useEffect(() => {
    dispatch(setCompanyBankAccountState(debouncedBankValue));
  },[debouncedBankValue])

  return (
    <>
      <div className="post_second_header">
        <div className="head-left">
          <h3>Account Details:</h3>
        </div>
        <div className="head-right" />
      </div>
      <div className={styles.editSave}>
        <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
          {!edit && (
            <Tooltip title="Edit">
              <FontAwesomeIcon
                icon={faPencilSquare}
                aria-hidden="true"
                id="edit-general-details"
                onClick={EnableEdit}
                className="hover-cursor"
              />
            </Tooltip>
          )}
          {edit && localBank.companyId > 0 && (
            <div className={styles.btnBox}>
              <div className={styles.btnBoxLeft}>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    icon={faTrash}
                    aria-hidden="true"
                    id="edit-delete-bank-acc"
                    onClick={DeleteThisBankAccount}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxCenter}>
                <Tooltip title="Save">
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    aria-hidden="true"
                    id="edit-save-bank-acc"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxRight}>
                <Tooltip title="Cancel Edit">
                  <FontAwesomeIcon
                    icon={faCancel}
                    aria-hidden="true"
                    id="edit-cancel-general"
                    onClick={cancelEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {edit && localBank.companyId === 0 && (
            <div className={styles.btnBox}>
              <div className={styles.btnBoxLeft}>
                <Tooltip title="Save">
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    aria-hidden="true"
                    id="edit-save-bank-acc"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
              <div className={styles.btnBoxRight}>
                <Tooltip title="Cancel">
                  <FontAwesomeIcon
                    icon={faCancel}
                    aria-hidden="true"
                    id="edit-cancel-general"
                    onClick={cancelNewAccount}
                    className="hover-cursor"
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </WithRequiredPermission>
      </div>
      <div className="post_second_info">
        <Grid container className="g-container">
          <Grid item xs={4}>
            <TextField
              select
              fullWidth
              size="small"
              disabled={!edit}
              id="bankName"
              sx={{ width: "98%", marginRight: "2%" }}
              label="Bank Name"
              value={localBank.bankId || 0}
              error={bankNameError}
              helperText={bankNameError && "Please select a bank"}
              onChange={(ev) => {
                handleValueChange(ev, "bankId");
              }}
              onBlur={(ev) => {
                if (localBank.bankId === 0) {
                  setBankNameError(true);
                  return;
                }
                setBankNameError(false);
              }}
            >
              <MenuItem value={0}>Please Select</MenuItem>
              {bankNames.map((bankName) => (
                <MenuItem value={bankName.id}>{bankName.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              disabled={!edit}
              size="small"
              id="accountName"
              sx={{ width: "98%", marginRight: "2%" }}
              label="Bank Account Name"
              value={localBank.accountName || ""}
              error={bankAccNameError}
              helperText={
                bankAccNameError &&
                validateField("Bank Account Name", localBank.accountName ?? "").validationErrorText
              }
              onChange={(ev) => {
                handleValueChange(ev, "accountName");
              }}
              onBlur={(ev) => {
                const invalidField = validateField(
                  "Bank Account Name",
                  localBank.accountName ?? ""
                ).isInvalid;

                if (invalidField) {
                  setBankAccNameError(invalidField);
                  return;
                }

                setBankAccNameError(false);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              sx={{
                width: "98%",
              }}
            >
              <InputLabel id="accountType-label">Account Type</InputLabel>
              <Select
                label="Account Type"
                id="accountType"
                margin="dense"
                displayEmpty
                inputProps={{ style: { fontSize: 14 } }}
                value={localBank?.accountTypeId || 0}
                size="small"
                disabled={!edit}
                onChange={(ev) => {
                  ev.preventDefault();
                  handleSelectValueChange(ev, "accountTypeId");
                }}
                onBlur={(ev) => {
                  if (localBank.accountTypeId === 0) {
                    setBankAccTypeError(true);
                    return;
                  }
                  setBankAccTypeError(false);
                }}
                input={<OutlinedInput label="B-BBEE Sector" />}
              >
                <MenuItem value={0}>
                  <em>Please Select</em>
                </MenuItem>
                {bankAccountTypes.map((accountType) => (
                  <MenuItem value={accountType.id}>{accountType.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className="g-container">
          <Grid item xs={4}>
            <TextField
              type="number"
              disabled={!edit}
              className={classes.input}
              sx={{ width: "98%", marginRight: "2%" }}
              size="small"
              id="accountNumber"
              label="Bank Account Number"
              value={localBank.accountNumber || ""}
              error={bankAccNumberError}
              helperText={
                bankAccNumberError &&
                validateField("Bank Account Number", localBank.accountNumber ?? "")
                  .validationErrorText
              }
              onChange={(ev) => {
                handleValueChange(ev, "accountNumber");
              }}
              onBlur={(ev) => {
                const invalidField = validateField(
                  "Bank Account Number",
                  localBank.accountNumber ?? ""
                ).isInvalid;

                if (invalidField) {
                  setBankAccNumberError(invalidField);
                  return;
                }

                setBankAccNumberError(false);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              disabled={!edit}
              size="small"
              id="branchName"
              sx={{ width: "98%", marginRight: "2%" }}
              label="Branch Name"
              value={localBank.branchName || ""}
              error={bankBranchNameError}
              helperText={
                bankBranchNameError &&
                validateField("Branch Name", localBank.branchName ?? "").validationErrorText
              }
              onChange={(ev) => {
                handleValueChange(ev, "branchName");
              }}
              onBlur={(ev) => {
                const invalidField = validateField(
                  "Branch Name",
                  localBank.branchName ?? ""
                ).isInvalid;

                if (invalidField) {
                  setBankBranchNameError(invalidField);
                  return;
                }

                setBankBranchNameError(false);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              className={classes.input}
              disabled={!edit}
              size="small"
              id="branchCode"
              sx={{ width: "98%", marginRight: "2%" }}
              label="Branch Code"
              value={localBank.branchCode}
              error={bankBranchCodeError}
              helperText={
                bankBranchCodeError &&
                validateField("Branch Code", localBank.branchCode ?? "")
                  .validationErrorText
              }
              onChange={(ev) => {
                handleValueChange(ev, "branchCode");
              }}
              onBlur={(ev) => {
                const invalidField = validateField(
                  "Branch Code",
                  localBank.branchCode ?? ""
                ).isInvalid;

                if (invalidField) {
                  setBankBranchCodeError(invalidField);
                  return;
                }

                setBankBranchCodeError(false);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BankAccount;
