/* eslint-disable no-nested-ternary */
import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material"
import { StepStatus, StepsModel, stepStatusToString } from "./StepsModel";
import { useAppSelector } from "../../app/hooks";
import { getAllOnboardingChecks } from "../../http/Redux/Store/OnBoardingSlice";

const Overview = ({ steps }: { steps: StepsModel[] }) => {
  const validationError = useAppSelector(getAllOnboardingChecks);
  return (

    <Box sx={{ padding: 2, width: "100%", height: "fit-content" }}>
      <Stack sx={{ width: "100%" }} spacing={2}>
        {steps.filter(x => x.status !== 0 && x.status !== 6).map((s) => (
          <Alert severity={s.status === StepStatus.NotStarted ? "info" : s.status === StepStatus.Error ? "error" : "success"}>
            <AlertTitle><strong>{s.label}</strong></AlertTitle>
            <Typography>{stepStatusToString(s.status as number)}</Typography>
            {validationError &&
              <div>
                {validationError?.filter(x => x.designation === s.label).map((m) => (
                  <Typography>{m.error}</Typography>
                ))}
              </div>
            }
          </Alert>
        ))}
      </Stack>
    </Box >

  )

}


export default Overview