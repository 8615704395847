/* Got this one from this Git Gist: https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355 */

import { useEffect, useCallback } from "react";
import useConfirmExit from "./useConfirmExit";

export default function usePrompt(message: string, when = true) {
  useEffect(() => {
    if (when) {
      // eslint-disable-next-line func-names
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
