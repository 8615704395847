import { Card, Grid } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";
import { CompanyComplianceSummary } from "../../../http/Compliance/Models/CompanyComplianceSummary";
import ComplianceSummaryRow from "./ComplianceSummaryRow";
import CompanyComplianceSummarySectionModel from "./ModelsAndMappings/CompanyComplianceSummarySectionModel";
import complianceSummaryTypeMapping, { ComplianceSummaryType } from "./ModelsAndMappings/complianceSummaryTypeMappings";

interface ComplianceSummaryProps {
  companyComplianceSummary: CompanyComplianceSummary | null;
}

const ComplianceSummary = ({ companyComplianceSummary }: ComplianceSummaryProps) => {
  const isDataExpired = useCallback((expirationDate: Dayjs) => {
    const currentDate = dayjs();
    if (expirationDate < currentDate) return true;
    return false;
  }, []);

  const buildAndRenderRow = (complianceSummary: CompanyComplianceSummary | null, complianceType: ComplianceSummaryType, rowLabel: string) => {
    // Get the corresponding mapping fields for our summary data.
    const mapping = complianceSummaryTypeMapping[complianceType];
    const sectionEnabled = complianceSummary ? complianceSummary[mapping.enabled] : false;

    const responseObject: CompanyComplianceSummarySectionModel = {
      documentAttached: "",
      issueDate: null,
      expiryDate: null,
      status: "",
    };

    // If the summary or section is null or disabled respectively, return a N/A default.
    if (!complianceSummary || !sectionEnabled) {
      responseObject.documentAttached = "N/A";
      responseObject.issueDate = null;
      responseObject.expiryDate = null;
      responseObject.status = "N/A";
      return <ComplianceSummaryRow complianceTypeLabel={rowLabel} summarySectionModel={responseObject} />;
    }

    // If the summary is not null and the section is enabled, we can fetch the
    // other values using the mapping object, and process them according to r

    const issueDate = complianceSummary[mapping.issueDate];

    // We check of mapping.expiryDate is falsy or not. If it's falsy/null, we could be dealing with
    // a compliance type that does not expire.
    const expiryDate = mapping.expiryDate ? complianceSummary[mapping.expiryDate] : null;
    const uploaded = complianceSummary[mapping.uploaded];

    // OK. If the document is not uploaded, our row needs to display in a certain way.
    if (!uploaded) {
      responseObject.documentAttached = "No";
      responseObject.issueDate = issueDate ? dayjs(issueDate as string) : null;
      responseObject.expiryDate = expiryDate ? dayjs(expiryDate as string) : null;
      responseObject.status = responseObject.expiryDate && isDataExpired(responseObject.expiryDate) ? "Expired" : "Info Captured";
      return <ComplianceSummaryRow complianceTypeLabel={rowLabel} summarySectionModel={responseObject} />
    }

    responseObject.documentAttached = "Yes";
    responseObject.issueDate = issueDate ? dayjs(issueDate as string) : null;
    responseObject.expiryDate = expiryDate ? dayjs(expiryDate as string) : null;
    responseObject.status = responseObject.expiryDate && isDataExpired(responseObject.expiryDate) ? "Expired" : "Info Captured";
    return <ComplianceSummaryRow complianceTypeLabel={rowLabel} summarySectionModel={responseObject} />
  }

  return (
    <Card
      className="cardMain-CompanyProfile"
      sx={{
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        backdropFilter: "blur(6px)",
        borderRadius: "8px",
      }}
    >
      <div className="cardHeader-compliance">COMPLIANCE SUMMARY</div>
      <Grid
        container
        className="table-header-card "
        sx={{ borderBottom: "1px solid #F0F0F1", borderTop: "1px solid #F0F0F1" }}
      >
        <Grid xs={3} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
          Compliance
        </Grid>
        <Grid xs={3} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
          Document Attached
        </Grid>
        <Grid xs={2} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
          Issue Date
        </Grid>
        <Grid xs={2} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
          Expiry Date
        </Grid>
        <Grid xs={2} sx={{ padding: "10px" }} item>
          Status
        </Grid>
      </Grid>
      {buildAndRenderRow(companyComplianceSummary, "bee", "B-BBEE")}
      {buildAndRenderRow(companyComplianceSummary, "banking", "Banking Details")}
      {buildAndRenderRow(companyComplianceSummary, "coida", "COIDA")}
      {buildAndRenderRow(companyComplianceSummary, "tax", "TAX SARS")}
      {buildAndRenderRow(companyComplianceSummary, "vat", "TAX VAT")}
    </Card>
  );
};

export default ComplianceSummary;
