import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { IsConnectedToCompany } from "../../../http/NetworkAndConnect/Netconn";

const useHasConnectionToCompany = (companyId: number, queryIsEnabled?: boolean) => {
  const query = useQuery({
    queryKey: [queryKeys.hasConnectionToCompany],
    queryFn: async () => IsConnectedToCompany(companyId),
    enabled: queryIsEnabled, // the query will not run when this is false
  })

  return query;
}

export default useHasConnectionToCompany;