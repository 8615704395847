// eslint-disable-next-line no-shadow,
export enum ComplianceStatus {
  NotApplicable = 0, // eslint-disable-line no-unused-vars
  PendingChecks = 1, // eslint-disable-line no-unused-vars
  Passed = 2,// eslint-disable-line no-unused-vars
  Failed = 3// eslint-disable-line no-unused-vars
}

export const ComplianceStatusToString = (value: ComplianceStatus) => {
  switch (value) {
    case ComplianceStatus.NotApplicable:
      return "Not eligible";
    case ComplianceStatus.PendingChecks:
      return "Pending checks";
    case ComplianceStatus.Failed:
      return "Failed checks";
    case ComplianceStatus.Passed:
      return "Compliant";
    default:
      return "Unknown";
  }
}

export const GetComplianceStatusColor = (value: ComplianceStatus) => {
  switch (value) {
    case ComplianceStatus.NotApplicable:
      return "default";
    case ComplianceStatus.PendingChecks:
      return "warning";
    case ComplianceStatus.Failed:
      return "error";
    case ComplianceStatus.Passed:
      return "success"
    default:
      return "default";
  }
}