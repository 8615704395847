import * as React from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomizedSteppers from "../Wizards/CustomizedSteppers";
import useAppLogOut from "../../../util/hooks/useLogOutOfApp";
import ButtonGroup from "../Sections/ButtonGroup";
import DialogModal from "../../../components/Dialogs/DialogModal";

interface StepperSteps {
  title: string,
}

const CompleteLaterPromptModal = ({ isOpen,
  steps,
  activeStep,
  onCloseModalCallback,
}: {
  isOpen: boolean,
  steps: StepperSteps[],
  activeStep: number,
  onCloseModalCallback: () => void,
}) => {

  const [open, setOpen] = React.useState(false);
  const range = activeStep + 1;

  const logoutOfApp = useAppLogOut();
  const handleContinueAccountSetup = () => {
    onCloseModalCallback();
  };
  const handleLogoutAndCompleteLater = () => {
    logoutOfApp();
  };


  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  return (

    <DialogModal
      isOpen={open}
      onHandleClose={() => setOpen(false)}
    >
      <>
        <Box px={12} py={2} >
          <Typography variant="h4">Your progress has been saved.</Typography>
        </Box>
        <Box p={3}>
          <CustomizedSteppers activeStep={activeStep} steps={steps} />
          <Box p={5} style={{ width: "600px", marginTop: 15, marginBottom: 20 }}>
            <Typography sx={{ marginBottom: 2, fontWeight: 500 }}>
            Complete steps <b>{range} - {steps.length}</b> to finish setting up your account:
            </Typography>
            {steps.map((step, index) => {
              if (index >= activeStep) {
                return <Typography sx={{ fontWeight: 500 }} key={index}>{index + 1}. {step.title}</Typography>;
              }
              return null;
            })}
          </Box>
        </Box>
        <ButtonGroup
          px={8}
          buttons={[{
            widthInPx: 290,
            label: "LOG OUT & COMPLETE LATER",
            align: "left",
            type: "primary",
            onClick: handleLogoutAndCompleteLater,
          },
          {
            widthInPx: 290,
            label: "CONTINUE ACCOUNT SET-UP",
            align: "right",
            type: "secondary",
            onClick: handleContinueAccountSetup,
          }]}
        />
      </>
    </DialogModal>

  );
}



export default CompleteLaterPromptModal;