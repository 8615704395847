import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import validationSlice from "./ValidationSlice"
import { RootState } from "../../../app/store"
import { ValidationModel } from "../../Configuration/Validation/ValidationModel";
import ValidationService from "../../ValidationService";

export const validationActions = validationSlice.actions

export const fetchValidations = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: ValidationModel[] = await ValidationService.getAllValidations();
  dispatch(validationActions.setValidation(response))

}
export const fetchParticularValidation = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: ValidationModel = await ValidationService.getParticularValidation(id);
  dispatch(validationActions.setParticularValidation(response))
}