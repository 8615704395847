import { Button } from "@mui/material";
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { CompanyBusinessLocationModel } from "../../../../../http/Company/Models/CompanyBusinessLocationModel";
import getRandomIntBetweenTwoNums from "../../../../../util/functions/rng";

interface EditToolbarProps {
  editModeEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    // eslint-disable-next-line no-unused-vars
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  // eslint-disable-next-line no-unused-vars
  setEdit: (isEditMode: boolean) => void;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const newRowId = getRandomIntBetweenTwoNums(9000000, 9999999);

    const newRow: CompanyBusinessLocationModel = {
      id: newRowId,
      companyId: 0,
      countryId: 0,
      provinceId: 0,
      primaryLocation: false,
      isNew: true,
      isDeleted: false,
    }

    setRows((oldRows) => [...oldRows, newRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRowId]: { mode: GridRowModes.Edit, fieldToFocus: "countryId" },
    }));

    props.setEdit(true);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={props.editModeEnabled}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default EditToolbar;