/* eslint-disable no-nested-ternary */
import { useQueryClient } from "@tanstack/react-query";
import { Box, Grid, Typography } from "@mui/material";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SweetAlert2 from "react-sweetalert2";
import MailIcon from "@mui/icons-material/Mail";
import { PersonAdd } from "@mui/icons-material";
import "../../util/MUI_Theme/Theme.css";
import { toast } from "react-toastify";
import bufferProfile from "../../util/images/filler.png";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import ContactType from "../../util/enums/ContactType";
import formatDate from "../../util/formatter/formatDate";
import AddressType from "../../util/enums/AddressType";
import CompanyProfileService from "../../http/CompanyProfileService";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import SupportingDocuments from "./ProfileComponents/SupportingDocuments";
import PrimaryIcon, {
  BBEIcon,
  GoodsIcon,
  PhysicalIcon,
  PostalIcon,
  RegistrationIcon,
  SecondaryIcon,
  SectorsIcon,
  TaxIcon,
} from "../../util/MUI_Theme/Icons";
import { CompanyProfileImageModel } from "../../http/Redux/Models/CompanyProfileImageModel";
import ConnectionType from "../../util/enums/ConnectionType";
import { ViewProfileFieldItem } from "./ProfileComponents/Redacted";
import ComplianceSummary from "./ProfileComponents/ComplianceSummary";
import useBeeSectors from "../../util/hooks/queries/useBeeSectors";
import useCountries from "../../util/hooks/queries/useCountries";
import useBaseCompanyInfo from "../../util/hooks/queries/useBaseCompanyInfo";
import { BaseCompanyInfo } from "../../http/Company/Models/BaseCompanyInfo";
import useUnspcOptions from "../../util/hooks/queries/useUnspcOptions";
import ConnectionStatus from "../../util/enums/ConnectionStatus";
import {
  createPendingConnectionRequestWithCompany,
  updateConnectionRequest,
} from "../../http/ConnectionRequestService";
import "../../components/Connect/SearchResult/SweetAlert.css";
import BasePage from "./BasePage";
import useCompanyDetails from "../../util/hooks/queries/useCompanyDetails";
import useHasConnectionToCompany from "../../util/hooks/queries/useHasConnectionToCompany";
import useCompanyTypes from "../../util/hooks/queries/useCompanyTypes";
import useBeeTurnoverBands from "../../util/hooks/queries/useBeeTurnoverBands";
import useProvinces from "../../util/hooks/queries/useProvinces";
import SelectedProductAndServicesDisplay from "../../components/Company/CompanyPost/CompanyProductsAndServices/SelectedProductAndServicesDisplay";
import useCompanyUnspscSelection from "../../util/hooks/queries/useCompanyUnspscSelection";
import ProfileStatusCard from "./ProfileStatusCard";
import { responsiveFontSizes } from "../../util/MUI_Theme/Theme";
import { CompanyComplianceSummary } from "../../http/Compliance/Models/CompanyComplianceSummary";
import useCompanyComplianceSummary from "../../util/hooks/queries/useCompanyComplianceSummary";
import AvailableActionsButton from "./Components/AvailableActionsButton";
import SendNewMessage from "../../components/Chat/SendNewMessage";
import useExistingConnectionRequestsWithCompany from "../../util/hooks/queries/useExistingConnectionRequest";
import { CreatePendingConnectionRequestModel } from "../../http/NetworkAndConnect/Models/CreatePendingConnectionRequestModel";
import queryKeys from "../../util/hooks/queries/queryKeys";
import { ViewConnectionRequestModel } from "../../http/Redux/Models/ViewConnectionRequestModel";
import ProfilePDFDownload from "./CompanyProfile/ProfilePDFDownload";
import { DocumentDtoModel } from "../../http/DocumentManagement/Models/DocumentDtoModel";
import { CompanyProfileViewModel } from "../../http/Company/Models/CompanyProfileViewModel";
import useCompanyProfileView from "../../util/hooks/queries/useCompanyProfileView";
import { CompanyProfileSettings } from "../../http/Company/Models/CompanyProfileSettings";
import useCompanyProfileSettings from "../../util/hooks/queries/useCompanyProfileSettings";
import useBanks from "../../util/hooks/queries/useBanks";
import { BankAccountDetailsModel } from "../../http/Company/Models/BankAccountDetailsModel";

const ViewProfile = () => {
  const { companyId: requestedCompanyId } = useParams();
  const { data: baseCompany = {} as BaseCompanyInfo } = useBaseCompanyInfo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const profileCompanyId: number = parseInt(requestedCompanyId ?? "0", 10);
  const viewMyOwnProfile = baseCompany.companyId === profileCompanyId;

  // React Query (from Tanstack : https://tanstack.com/query/latest/docs/framework/react/overview )
  const { isLoading: loadingCountries, data: countries = [] } = useCountries();
  const { isLoading: loadingProvinces, data: provinces } = useProvinces();
  const { isLoading: loadingBeeSectors, data: beeSectors = [] } = useBeeSectors();
  const { isLoading: loadingTurnoverBands, data: beeTurnoverBands = [] } = useBeeTurnoverBands();
  const { isLoading: loadingCompanyTypes, data: companyTypes = [] } = useCompanyTypes();
  const { isLoading: loadingExistingConnectionRequest, data: existingConnectionRequests = [] } =
    useExistingConnectionRequestsWithCompany(profileCompanyId, viewMyOwnProfile);

  const { isLoading: loadingBankList, data: bankList = []} = useBanks();
  const { isLoading: loadingAccountTypeList, data: accountTypeList = []} = useBanks();
  const unspscOptions = useUnspcOptions();
  const unspscSelectionIdList = useCompanyUnspscSelection();

  const runHasConnectionCheck = viewMyOwnProfile === false;
  const { isLoading: loadingConnectionStatus, data: connectionToCurrentCompany = false } =
    useHasConnectionToCompany(profileCompanyId, runHasConnectionCheck);
  const { isLoading: loadingCompanyProfile, data: companyProfile = {} as CompanyViewModel } =
    useCompanyDetails(profileCompanyId);

  const {
    isLoading: loadingViewedCompanyProfileSettings,
    data: viewedCompanyProfileSettings = {} as CompanyProfileSettings,
  } = useCompanyProfileSettings(profileCompanyId);

  const showDetailAllDetails = connectionToCurrentCompany || viewMyOwnProfile;

  const {
    isLoading: loadingCompanyComplianceSummary,
    data: companyComplianceSummary = {} as CompanyComplianceSummary,
  } = useCompanyComplianceSummary(profileCompanyId);

  // YAY!
  const {
    isLoading: loadingCompanyProfileView,
    data: companyProfileView = {} as CompanyProfileViewModel,
  } = useCompanyProfileView(profileCompanyId, showDetailAllDetails);

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [profileImage, setProfileImage] = useState<CompanyProfileImageModel>(
    {} as CompanyProfileImageModel
  );

  const [showSendMessageModel, setShowSendMessageModel] = useState<boolean>(false);
  const [swalPropsChange, setSwalPropsChange] = useState({});

  const isLoading =
    showLoadingSpinner ||
    loadingViewedCompanyProfileSettings ||
    loadingCountries ||
    loadingProvinces ||
    loadingBeeSectors ||
    loadingTurnoverBands ||
    loadingBankList ||
    loadingAccountTypeList ||
    loadingCompanyTypes ||
    unspscOptions.isLoading ||
    loadingCompanyProfile ||
    loadingCompanyProfileView ||
    loadingConnectionStatus ||
    unspscSelectionIdList.isLoading ||
    loadingCompanyComplianceSummary ||
    loadingExistingConnectionRequest;

  const handleNavigateToHome = async () => {
    navigate("/home");
  };

  const sendNewPendingConnectionRequest = (connectionType: ConnectionType) => {
    const loader = async () => {
      try {
        setShowLoadingSpinner(true);
        await createPendingConnectionRequestWithCompany({
          recipientCompanyId: profileCompanyId,
          connectionType,
        } as CreatePendingConnectionRequestModel);
        setShowLoadingSpinner(false);
        toast.success("Connection request sent successfully");
        queryClient.invalidateQueries({
          queryKey: [queryKeys.existingConnectionRequestWithCompany],
        });
      } catch (error) {
        setShowLoadingSpinner(false);
        toast.error("Failed to send connection request");
      }
    };
    loader();
  };

  const handleResponseToConnectionRequest = (
    connectionRequest: ViewConnectionRequestModel,
    connectionStatus: ConnectionStatus
  ) => {
    const loader = async () => {
      try {
        setShowLoadingSpinner(true);
        await updateConnectionRequest({ ...connectionRequest, connectionStatus });
        handleNavigateToHome();
      } catch (error) {
        setShowLoadingSpinner(false);
      }
    };

    loader();
  };

  const [PdfDocuments, setPdfDocuments] = useState(
    companyProfileView.companyDocuments
      ? companyProfileView.companyDocuments.filter((doc) => doc.typeId <= 10000)
      : ([] as Array<DocumentDtoModel>)
  );

  const handlePdfDocument = (doc: DocumentDtoModel[]) => {
    setPdfDocuments(doc);
  };

  useEffect(() => {
    const loaderData = async () => {
      if (companyProfileView.companyDetails?.companyId) {
        const image = await CompanyProfileService.getParticularProfileImage(
          companyProfileView.companyDetails?.companyId,
          false
        );
        setProfileImage(image.data);
      }
    };

    loaderData();
  }, [companyProfileView.companyDetails?.companyId]);

  const handleSendMessageClick = () => {
    setShowSendMessageModel(!showSendMessageModel);
  };

  const handleCloseMessageModel = () => {
    setShowSendMessageModel(false);
  };

  const handleToastMessageSent = () => {
    setSwalPropsChange({
      show: true,
      title: "Message Sent",
      icon: "success",
      timer: Number(2000),
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  const primaryContact = companyProfileView?.companyContacts?.find(
    (x) => x.contactTypeId === ContactType.Primary
  );
  const secondaryContact = companyProfileView?.companyContacts?.find(
    (x) => x.contactTypeId === ContactType.Secondary
  );
  const beeDetails = companyProfileView?.companyBeeDetails;
  const physicalAddress = companyProfileView.companyAddresses?.find(
    (x) => x.addressTypeId === AddressType.Physical
  );
  const postalAddress = companyProfileView.companyAddresses?.find(
    (x) => x.addressTypeId === AddressType.Postal
  );
  const turnoverBand = beeTurnoverBands.find(
    (x) => x.id === companyProfileView.companyBeeDetails?.annualTurnover
  );

  // check if there is a pending request from the company
  const pendingRequestsSentByMe = existingConnectionRequests.filter(
    (x) => x.requestedByCompanyId === baseCompany.companyId
  );
  const pendingRequestsReceived = existingConnectionRequests.filter(
    (x) => x.requestedByCompanyId === profileCompanyId
  );

  const southAfricaCountryId = countries.find((country) => country.name.toLowerCase() === "south africa");
  const bankCountryId = countries.find((country) => country.id === companyProfileView.companyBankAccountDetails?.countryId);
  const isBankForeign = bankCountryId !== southAfricaCountryId;

  const getBankName = (bankAccountDetails: BankAccountDetailsModel | null, isForeign: boolean) => {
    if (!bankAccountDetails) return "";

    if (isForeign) {
      return bankAccountDetails.textBankName;
    }

    return bankList.find((bank) => bank.id === bankAccountDetails.bankId)?.name;
  }

  const getBankAccountType = (bankAccountDetails: BankAccountDetailsModel | null, isForeign: boolean) => {
    if (!bankAccountDetails) return "";

    if (isForeign) {
      return bankAccountDetails.textAccountType;
    }

    return accountTypeList.find((accType) => accType.id === bankAccountDetails.accountTypeId)?.name;
  }

  const getAvailableActions = () => {
    const actionList = [];

    viewMyOwnProfile === false &&
      actionList.push({
        icon: <MailIcon />,
        title: "Start a conversation",
        onClick: () => handleSendMessageClick(),
      });

    // Render supplier actions
    const receivedPendingSupplierRequest = pendingRequestsReceived.find(
      (x) =>
        x.connectionType === ConnectionType.Supplier &&
        x.connectionStatus === ConnectionStatus.Pending
    );
    if (receivedPendingSupplierRequest != null) {
      actionList.push({
        icon: <PersonAdd />,
        title: "Accept connection (as their supplier)",
        onClick: () =>
          handleResponseToConnectionRequest(
            receivedPendingSupplierRequest,
            ConnectionStatus.Accepted
          ),
      });

      actionList.push({
        icon: <PersonAdd />,
        title: "Reject connection (as their supplier)",
        onClick: () =>
          handleResponseToConnectionRequest(
            receivedPendingSupplierRequest,
            ConnectionStatus.Declined
          ),
      });
    } else {
      const hasSentAcceptedOrPendingSupplierRequest =
        pendingRequestsSentByMe.filter(
          (x) =>
            (x.connectionStatus === ConnectionStatus.Accepted ||
              x.connectionStatus === ConnectionStatus.Pending) &&
            x.connectionType === ConnectionType.Supplier
        )?.length > 0;

      actionList.push({
        icon: <PersonAdd />,
        title: "Connect as your supplier",
        disabled: hasSentAcceptedOrPendingSupplierRequest,
        onClick: () => sendNewPendingConnectionRequest(ConnectionType.Supplier),
      });
    }

    // Render buyer actions
    const pendingClientRequestRecieved = pendingRequestsReceived.find(
      (x) => x.connectionType === ConnectionType.Client
    );
    if (
      pendingClientRequestRecieved != null &&
      pendingClientRequestRecieved.connectionStatus !== ConnectionStatus.Accepted
    ) {
      actionList.push({
        icon: <PersonAdd />,
        title: "Accept connection (as thier buyer)",
        onClick: () =>
          handleResponseToConnectionRequest(
            pendingClientRequestRecieved,
            ConnectionStatus.Accepted
          ),
      });

      actionList.push({
        icon: <PersonAdd />,
        title: "Reject connection (as their buyer)",
        onClick: () =>
          handleResponseToConnectionRequest(
            pendingClientRequestRecieved,
            ConnectionStatus.Declined
          ),
      });
    } else {
      const hasAcceptedOrPendingSupplierRequest =
        pendingRequestsSentByMe.find(
          (x) =>
            (x.connectionStatus === ConnectionStatus.Pending ||
              x.connectionStatus === ConnectionStatus.Accepted) &&
            x.connectionType === ConnectionType.Client
        ) != null;

      const otherCompanyIsAlreadyMyBuyer =
        existingConnectionRequests.filter(
          (x) =>
            x.connectionStatus === ConnectionStatus.Accepted &&
            x.requestedByCompanyId === profileCompanyId &&
            x.connectionType === ConnectionType.Supplier
        )?.length > 0;

      actionList.push({
        icon: <PersonAdd />,
        title: "Connect as your buyer",
        disabled: hasAcceptedOrPendingSupplierRequest || otherCompanyIsAlreadyMyBuyer,
        onClick: () => sendNewPendingConnectionRequest(ConnectionType.Client),
      });
    }
    return actionList;
  };

  return (
    <>
      <BasePage ignoreHorizontalPadding>
        <LoadingBackdrop showBackdrop={isLoading} />
        <Box>
          <Box
            className="pageBox-profile"
            p={6}
            sx={{
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              id="content-id"
              p={8}
              style={{
                backgroundColor: "white",
                width: "80vw",
                borderRadius: "12px",
                boxShadow:
                  " 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Grid container rowGap={3} rowSpacing={2} spacing={0.5}>
                <Grid item lg={3} sm={6}>
                  <Box className="profile-companyLogo">
                    <img
                      src={profileImage?.url ? profileImage?.url : bufferProfile}
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.onerror = null; // prevents looping
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = bufferProfile;
                      }}
                      alt="company profile logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} sm={6}>
                  <Box>
                    <Typography variant="pageTitle">{companyProfile.registeredName}</Typography>
                    <Box py={1} sx={{ display: "flex", alignItems: "center" }}>
                      <HelpOutlinedIcon className="profile-icon-blue" />
                      <Typography variant="body2" pt={1}>
                        Request Update Information
                      </Typography>
                    </Box>
                    <Box className="button-box-notification">
                      {viewMyOwnProfile ? (
                        <div
                          style={{ width: "100%" }}
                          onClick={() => navigate("/editCompanyProfile")}
                        >
                          <div style={{ float: "left", width: "10%" }}>
                            <EditNoteIcon />
                          </div>
                          <div style={{ float: "left", width: "90%" }}>
                            <Typography sx={{ paddingLeft: "10px" }}>Edit Information</Typography>
                          </div>
                        </div>
                      ) : (
                        <AvailableActionsButton actionsList={getAvailableActions()} />
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={5} sm={12}>
                  <ProfileStatusCard />
                </Grid>
              </Grid>
              <br />
              <ComplianceSummary companyComplianceSummary={companyComplianceSummary} />
              <br />
              <Grid container rowGap={3} rowSpacing={2}>
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <PrimaryIcon />
                    PRIMARY REPRESENTATIVE
                  </Typography>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Name"
                      value={primaryContact?.name ?? ""}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Email Address"
                      value={primaryContact?.email ?? ""}
                    />
                  </Grid>
                  <Grid>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Contact Number"
                      value={primaryContact?.mobile ?? ""}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <SecondaryIcon /> SECONDARY REPRESENTATIVE
                  </Typography>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Name"
                      value={secondaryContact?.name ?? ""}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Email Address"
                      value={secondaryContact?.email ?? ""}
                    />
                  </Grid>
                  <Grid>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Contact Number"
                      value={secondaryContact?.mobile ?? ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {/* Registration and Tax Information */}
              <Grid container rowGap={3} rowSpacing={2}>
                <>
                  {/* Registration Information */}
                  <Grid item sm={12} md={6}>
                    <Typography
                      sx={{
                        ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                        fontWeight: 700,
                        lineHeight: 1.5,
                      }}
                    >
                      <RegistrationIcon /> REGISTRATION INFORMATION
                    </Typography>
                    <Grid pb={2}>
                      <b>Registered company name:</b> {companyProfile.registeredName}
                    </Grid>
                    <Grid pb={2}>
                      <b>Company registration number:</b> {companyProfile.registrationNumber}
                    </Grid>
                    <Grid pb={2}>
                      <b>Company trading name:</b> {companyProfile.tradingName}
                    </Grid>
                    <Grid pb={2}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Company type"
                        value={
                          companyTypes.find((x) => x.id === companyProfile.companyTypeId)?.name ??
                          "--"
                        }
                        alwayShow
                      />
                    </Grid>
                    <Grid>
                      <b>Holding company name:</b> {companyProfile.holdingCompanyName}
                    </Grid>
                  </Grid>
                  {/* Tax Information */}
                  <Grid item sm={12} md={6}>
                    <Typography
                      sx={{
                        ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                        fontWeight: 700,
                        lineHeight: 1.5,
                      }}
                    >
                      <TaxIcon /> TAX INFORMATION
                    </Typography>
                    <Grid pb={2}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Company VAT number"
                        value={companyProfile.vatNumber ?? "--"}
                      />
                    </Grid>
                    <Grid pb={2}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Tax clearance"
                        value={companyProfile.taxClearanceNumber}
                      />
                    </Grid>
                    <Grid pb={2}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Tax clearance pin expiry date"
                        value={
                          companyProfile?.taxClearanceNumberExpiryDate
                            ? formatDate(companyProfile.taxClearanceNumberExpiryDate)
                            : "n/a"
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <br />
              {/* Addresses */}
              <Grid container rowGap={3} rowSpacing={2}>
                {/* Physical Address */}
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <PhysicalIcon /> PHYSICAL ADDRESS
                  </Typography>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Street Address"
                      value={physicalAddress?.line1 ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Office Block/Complex"
                      value={physicalAddress?.officeBlockOrComplexName ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Unit Number"
                      value={physicalAddress?.unitNumber ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="City"
                      value={physicalAddress?.city ?? "--"}
                      alwayShow
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Province/State"
                      value={
                        provinces?.find((x) => x.id === physicalAddress?.provinceId)?.name ?? "--"
                      }
                      alwayShow
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Country"
                      value={
                        countries.find((c) => c.id === physicalAddress?.countryId)?.name ?? "--"
                      }
                      alwayShow
                    />
                  </Grid>
                  <Grid>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Postal Code"
                      value={physicalAddress?.postalCode ?? "--"}
                      alwayShow
                    />
                  </Grid>
                </Grid>
                {/* Postal Address */}
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <PostalIcon /> POSTAL ADDRESS
                  </Typography>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Street Address"
                      value={postalAddress?.line1 ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Office Block/Complex"
                      value={postalAddress?.officeBlockOrComplexName ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Unit Number"
                      value={postalAddress?.unitNumber ?? "--"}
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="City"
                      value={postalAddress?.city ?? "--"}
                      alwayShow
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Province/State"
                      value={
                        provinces?.find((x) => x.id === postalAddress?.provinceId)?.name ?? "--"
                      }
                      alwayShow
                    />
                  </Grid>
                  <Grid pb={2}>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Country"
                      value={countries.find((c) => c.id === postalAddress?.countryId)?.name ?? "--"}
                      alwayShow
                    />
                  </Grid>
                  <Grid>
                    <ViewProfileFieldItem
                      isConnected={showDetailAllDetails}
                      title="Postal Code"
                      value={postalAddress?.postalCode ?? "--"}
                      alwayShow
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {/* B-BBEE & COIDA Details */}
              <Grid container rowGap={3} rowSpacing={2}>
                {/* B-BBEE Details */}
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <BBEIcon /> B-BBEE STATUS
                  </Typography>
                  <Grid container rowSpacing={2}>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Valid B-BBEE Certificate Affidavit"
                        value={beeDetails?.hasBEECertificate ? "Yes" : "No"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="B-BBEE Certificate Expiry Date"
                        value={
                          beeDetails?.certificateExpiryDate
                            ? formatDate(beeDetails?.certificateExpiryDate)
                            : "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="B-BBEE Sector"
                        value={
                          beeSectors.find(
                            (x) => x.id === companyProfile.companyBeeDetails?.sectorId
                          )?.value ?? "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Annual Turnover"
                        value={turnoverBand?.name ?? "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Black Ownership %"
                        value={companyProfile.companyBeeDetails?.boPercentage.toString(10) ?? "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Black Women Ownership %"
                        value={companyProfile.companyBeeDetails?.bwoPercentage.toString(10) ?? "--"}
                      />
                    </Grid>
                    <Grid>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="B-BBEE Level"
                        value={
                          companyProfile.companyBeeDetails?.certificateLevelId.toString(10) ?? "--"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* COIDA Details */}
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <BBEIcon /> COIDA
                  </Typography>
                  <Grid container rowSpacing={2}>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="COIDA Registered"
                        value={viewedCompanyProfileSettings?.hasCoidaEnabled ? "Yes" : "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Registration Number"
                        value={companyProfileView.companyCoidaDetails?.cfRegistrationNumber || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Certificate Number"
                        value={companyProfileView.companyCoidaDetails?.certificateNumber || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Issue Date"
                        value={
                          companyProfileView.companyCoidaDetails?.dateOfIssue
                            ? formatDate(companyProfileView.companyCoidaDetails?.dateOfIssue)
                            : "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Expiry Date"
                        value={
                          companyProfileView.companyCoidaDetails?.expiryDate
                            ? formatDate(companyProfileView.companyCoidaDetails?.expiryDate)
                            : "--"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {/* Banking Details */}
              <Grid container rowGap={3} rowSpacing={2}>
                <Grid item sm={12} md={6}>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <TaxIcon /> BANK DETAILS
                  </Typography>
                  <Grid container rowSpacing={2}>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Do You Have A Bank Account?"
                        value={
                          viewedCompanyProfileSettings?.hasBankAccountDetailsEnabled ? "Yes" : "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Bank Account Country"
                        value={
                          countries.find(
                            (country) =>
                              country.id === companyProfileView.companyBankAccountDetails?.countryId
                          )?.name || "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Bank Name"
                        value={
                          getBankName(
                            companyProfileView.companyBankAccountDetails,
                            isBankForeign
                          ) || "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Account Number"
                        value={companyProfileView.companyBankAccountDetails?.accountNumber || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Account Type"
                        value={
                          getBankAccountType(
                            companyProfileView.companyBankAccountDetails,
                            isBankForeign
                          ) || "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Branch Code"
                        value={companyProfileView.companyBankAccountDetails?.branchCode || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Account Holder"
                        value={companyProfileView.companyBankAccountDetails?.accountName || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Branch Name"
                        value={companyProfileView.companyBankAccountDetails?.branchName || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="SWIFT Code"
                        value={companyProfileView.companyBankAccountDetails?.swiftCode || "--"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Primary Bank Account"
                        value={
                          companyProfileView.companyBankAccountDetails?.isPrimary
                            ? "Yes"
                            : "No" || "--"
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <ViewProfileFieldItem
                        isConnected={showDetailAllDetails}
                        title="Bank Issue Letter Date"
                        value={
                          companyProfileView.companyBankAccountDetails?.dateOfIssue
                            ? formatDate(companyProfileView.companyBankAccountDetails?.dateOfIssue)
                            : "--"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <br />
              {/* Supporting Documents */}
              <Grid container rowGap={3} rowSpacing={2}>
                <SupportingDocuments
                  companyId={companyProfileView.companyDetails?.companyId}
                  connected={showDetailAllDetails}
                  isRegistration={false}
                  documentList={
                    companyProfileView.companyDocuments
                      ? companyProfileView.companyDocuments.filter((doc) => doc.typeId <= 10000)
                      : ([] as Array<DocumentDtoModel>)
                  }
                  handleDocumentsOnPDF={handlePdfDocument}
                />
              </Grid>
              <br />
              {/* Goods and Services (Trade Description Paragraph) */}
              <Grid container rowGap={3} rowSpacing={2}>
                <Grid xs={12} item>
                  <Typography
                    sx={{
                      ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                      fontWeight: 700,
                      lineHeight: 1.5,
                    }}
                  >
                    <GoodsIcon /> GOODS AND SERVICES
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography>{companyProfile.tradeDescription || ""}</Typography>
                </Grid>
              </Grid>
              <br />
              {/* Placeholder */}
              <Grid container rowGap={3} rowSpacing={2}>
                <Box style={{ width: "100%", height: "fit-content" }}>
                  <Box style={{ float: "right" }}>
                    <Grid item />
                  </Box>
                </Box>
              </Grid>
              <br />
              {/* Products and Services */}
              <Grid item sm={12} md={6}>
                <Typography
                  sx={{
                    ...responsiveFontSizes({ lg: 32, md: 28, sm: 24 }),
                    fontWeight: 700,
                    lineHeight: 1.5,
                  }}
                >
                  <SectorsIcon /> PRODUCT SECTORS/SEGMENTS
                </Typography>
                <Grid>
                  {unspscOptions.isFetched && unspscSelectionIdList.isFetched && (
                    <SelectedProductAndServicesDisplay
                      unspscOptions={unspscOptions?.data ?? { segments: [] }}
                      selectedProductsIdList={unspscSelectionIdList.data ?? []}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <ProfilePDFDownload
          connected={showDetailAllDetails}
          company={companyProfileView}
          companyProfileSettings={viewedCompanyProfileSettings}
          document={PdfDocuments}
          profileImage={profileImage.url || bufferProfile}
          companyComplianceSummary={companyComplianceSummary}
        />
      </BasePage>
      <SendNewMessage
        open={showSendMessageModel}
        handleClose={handleCloseMessageModel}
        handleRefresh={handleToastMessageSent}
        companyId={companyProfile.companyId as number}
        companyName={companyProfile.registeredName as string}
      />
      <SweetAlert2 {...swalPropsChange} />
    </>
  );
};

export default ViewProfile;
