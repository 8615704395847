import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import { SubscriptionFeatureModel } from "../../../http/Redux/Models/SubscriptionFeatureModel";
import SubscriptionService from "../../../http/SubscriptionManagement/SubscriptionApi";



const EditFeature = ({ backToView, selectedFeature }: { backToView: () => void, selectedFeature: SubscriptionFeatureModel }) => {
  const [loading, isLoading] = useState(false);
  const [feature, setFeature] = useState<SubscriptionFeatureModel>(selectedFeature as SubscriptionFeatureModel);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const handleSave = async () => {
    const add: SubscriptionFeatureModel = {} as SubscriptionFeatureModel;
    add.dateModified = new Date();
    add.featureName = feature.featureName;
    add.createdBy = feature.createdBy;
    add.dateCreated = feature.dateCreated;
    add.id = feature.id;
    add.isActive = feature.isActive === undefined ? false : feature.isActive;
    add.modifiedBy = userAccount || null;

    try {
      isLoading(true);
      await SubscriptionService.updateFeature(add);
    }
    catch {
      isLoading(false);
      toast.success("Feature Failed to Update");
    }
    finally {
      isLoading(false);
      toast.success("Feature Updated");
      backToView();
    }
  }

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Users">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={12}>
              <TextField
                required
                label="Name"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                name="firstName"
                value={feature?.featureName ?? ""}
                onChange={(ev) => setFeature({ ...feature, featureName: ev.target.value })}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={feature.isActive}
                  onClick={() => setFeature({ ...feature, isActive: !feature.isActive })} />}
                label="Is Active" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" onClick={() => handleSave()} className="form-add-btn">Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  )
};


export default EditFeature;