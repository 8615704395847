import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import useAllowedPermission from "../../../../util/hooks/useAllowedPermission";
import { CompanyViewModel } from "../../../../http/Company/Models/CompanyViewModel";
import VerificationSection from "../../../WorkCenter/Verification/VerificationSection";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCompanyViewModelState } from "../../../../http/Redux/Store/companySlice";

const CompanyTaxInformationPost = ({
  headerText = "",
  companyData,
  workcentreAgentPath,
  OnSave,
  SetDirtyCallback,
  staged,
  agentTaskId = 0,
}: {
  headerText: string;
  companyData: CompanyViewModel;
  workcentreAgentPath: boolean;
  // eslint-disable-next-line no-unused-vars
  OnSave: (company: CompanyViewModel) => void;

  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;
  staged: boolean;
  // eslint-disable-next-line react/require-default-props
  agentTaskId?: number;
}) => {
  const dispatch = useAppDispatch();

  const baseCompanyInfo = useAppSelector(x => x.baseCompanyInfo);

  const [edit, setEdit] = useState<boolean>(false);
  const [data, setData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [originalData, setOriginalData] = useState<CompanyViewModel>({} as CompanyViewModel);

  // const [certExpDate, setCertExpDate] = useState<Dayjs | null>(null);
  const [vatRegistered, setVatRegistered] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const hasAllowedPermission = useAllowedPermission();
  const initialLoadCheck = useRef(true);
  const [validateField] = useFieldValidation("companyProfile");

  const debouncedCompanyValue = useInputDebounce(data);

  // Validation Error State Variables
  const [vatNumberInvalid, setVatNumberInvalid] = useState<boolean>(false);
  const [clearancePinInvalid, setClearancePinInvalid] = useState<boolean>(false);

  const EnableEdit = () => {
    if (edit) {
      OnSave(data);
      setOriginalData(data);
      SetDirtyCallback(false);
    }
    setEdit(!edit);
  };

  const CancelEdit = () => {
    setEdit(false);
    setData(originalData);
    SetDirtyCallback(false);
  };

  const handleVatSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVatRegistered(event.target.checked);

    if (!event.target.checked) setData({ ...data, vatNumber: "" });
  };

  const vatTextBoxDisableChecker = () => {
    if (edit === false) {
      return true;
    }
    if (edit && vatRegistered === false) {
      return true;
    }

    if (edit && vatRegistered) return false;

    return false;
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const performDirtyCheck = (checkVal: CompanyViewModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(originalData)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    const newVal = { ...data, taxClearanceNumberExpiryDate: newDate?.toDate() as Date };
    setData(newVal);
    performDirtyCheck(newVal);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...data, [propName]: event.target.value };
    setData(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      setData({ ...companyData });
      setOriginalData({ ...companyData });
    }

    if (companyData?.vatNumber) {
      setVatRegistered(true);
    }

    if (companyData?.companyId && data.companyId && (companyData?.companyId === baseCompanyInfo.companyId)) {
      initialLoadCheck.current = false;
    }

  }, [data.companyId, companyData.companyId]);

  useEffect(() => {
    dispatch(setCompanyViewModelState(debouncedCompanyValue));
  }, [debouncedCompanyValue]);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      className="Accord-Hide"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>

      </AccordionSummary>
      <AccordionDetails id="step-tax">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) && workcentreAgentPath && (
            <div className="post__header_top">
              <VerificationSection
                section="TaxInfo"
                agentTaskId={agentTaskId}
                companyId={companyData?.companyId || data.companyId}
                staged={staged}
              />
            </div>)}

          <div className={styles.editSave}>
            <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
              {!edit && (
                <Tooltip title="Edit">
                  <FontAwesomeIcon
                    icon={faPencilSquare}
                    aria-hidden="true"
                    id="edit-general-details"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              )}
              {edit && (
                <div className={styles.btnBox}>
                  <div className={styles.btnBoxLeft}>
                    <Tooltip title="Save">
                      <FontAwesomeIcon
                        icon={faFloppyDisk}
                        aria-hidden="true"
                        id="edit-save-general"
                        onClick={EnableEdit}
                        className="hover-cursor"
                      />
                    </Tooltip>
                  </div>
                  <div className={styles.btnBoxRight}>
                    <Tooltip title="Cancel Edit">
                      <FontAwesomeIcon
                        icon={faCancel}
                        aria-hidden="true"
                        id="edit-cancel-general"
                        onClick={CancelEdit}
                        className="hover-cursor"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </WithRequiredPermission>
          </div>


          <div className={styles.postBody}>
            <div className={styles.postInfo}>
              <Grid container className="g-container">
                <Grid item>
                  <FormGroup sx={{ margin: "10px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!edit}
                          checked={vatRegistered}
                          onChange={(event) => handleVatSwitchChange(event)}
                        />
                      }
                      label="Is your company registered for VAT/Tax?"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container className="g-container">
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={vatTextBoxDisableChecker()}
                    size="small"
                    sx={{ width: "98%", marginRight: "2%" }}
                    id="vat-number"
                    value={data?.vatNumber || ""}
                    label="VAT/Tax Number"
                    error={vatNumberInvalid}
                    helperText={
                      vatNumberInvalid &&
                      validateField("VAT Number", data?.vatNumber ?? "").validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "vatNumber")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "VAT Number",
                        data?.vatNumber ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setVatNumberInvalid(invalidField);
                        return;
                      }

                      setVatNumberInvalid(false);
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    size="small"
                    sx={{ width: "98%", marginRight: "2%" }}
                    id="clearance-pin"
                    value={data?.taxClearanceNumber || ""}
                    label="Tax Clearance PIN"
                    error={clearancePinInvalid}
                    helperText={
                      clearancePinInvalid &&
                      validateField("Clearance PIN", data?.taxClearanceNumber ?? "")
                        .validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "taxClearanceNumber")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Tax Clearance PIN",
                        data?.taxClearanceNumber ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setClearancePinInvalid(invalidField);
                        return;
                      }

                      setClearancePinInvalid(false);
                    }}
                  />
                </Grid>

                <Grid item xs={4} md={4}>
                  <DesktopDatePicker
                    disabled={!edit}
                    disablePast
                    label="Tax Clearance Expiry Date"
                    value={data.taxClearanceNumberExpiryDate != null ? dayjs(data.taxClearanceNumberExpiryDate) : null}
                    onChange={(ev) => {
                      handleDateChange(ev);
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(params) => (
                      <TextField {...params} size="small" disabled={!edit} sx={{ width: "100%" }} />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </LocalizationProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyTaxInformationPost;
