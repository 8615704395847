import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PlatformNotificationSettings from "./PlatformSettings";
import ProfileInformation from "./ProfileInformation";

const UserProfile = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (

    <section className="profile-section-profile-notifications" >
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">Information</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileInformation />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">Notification settings</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PlatformNotificationSettings />
        </AccordionDetails>
      </Accordion>
    </section>

  )
}


export default UserProfile;