/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ReactNode } from "react";
import PieChartIcon from "@mui/icons-material/PieChart";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import { Chip } from "@mui/material";
import { OnBoardingRuleSetViewModel } from "../../http/Redux/Models/OnBoardingRuleSetViewModel";


export interface StepsModel {
  id?: number,
  model?: number,
  label?: string,
  description?: string,
  status?: number,
  timeout?: number,
  section: string,
  sets: OnBoardingRuleSetViewModel[],
}


export interface ChangedArtefacts {
  id?: number,
  artefactId?: number,
  value?: any,
}


export interface OnBoardingStepsProps {
  stepDescription: string,
  heading: string,
  companyId: number,
  stepLength: number,
  stepIndex: number,
  pullSection: number,
  set: OnBoardingRuleSetViewModel[],
  steps: StepsModel[];
  handleNext: (status: number) => void,
  handleNavigation: (description: string, length: number, index: number) => void,
  handleBack: () => void,

}

export interface OnBoardingNavigationProps {
  handleNavigation: (description: string, length: number, index: number) => void,
  currentStep: number,
  steps: StepsModel[],
}

export const labelProps: {
  optional?: ReactNode;
  icon?: ReactNode;
  error?: boolean;
  disabled?: boolean;
} = {};

export enum StepStatus {
  Index = 0,
  NotStarted = 1,
  Error = 2,
  Completed = 4,
  Document = 6,
}


export enum RequestStatus {
  None = 0,
  SavedForLater = 1,
  PendingCompletion = 2,
  OnboardingCompleted = 3,
}

export const requestStatusToString = (value: RequestStatus) => {
  switch (value) {
    case RequestStatus.None:
      return "None"
    case RequestStatus.PendingCompletion:
      return "Request Sent Pending Completion"
    case RequestStatus.OnboardingCompleted:
      return "Onboarding Completed"
    case RequestStatus.SavedForLater:
      return "Request Not Completed"
    default:
      return "Unknown";
  }
};

export const GetStepState = (index: number, status: number, step: number) => {

  if (index === 0 && status === StepStatus.Index) {
    return <PieChartIcon sx={{ color: "#1976d2" }} />
  }

  if (status === StepStatus.NotStarted) {
    return (
      <Chip label={step} sx={{ "& .MuiChip-label": { padding: 0 }, backgroundColor: "#1976d2", width: 20, height: 20, padding: 0, color: "white", marginLeft: "2px" }} />

    )
  }

  if (status === StepStatus.Error) {
    return (
      <ErrorIcon sx={{ color: "red" }} />
    )
  }

  if (status === StepStatus.Document) {
    return (
      <DescriptionIcon sx={{ color: "#1976d2" }} />
    )
  }


  if (status === StepStatus.Completed) {
    return <CheckCircleIcon sx={{ color: "green" }} />
  }

  return <Chip label={step} sx={{ "& .MuiChip-label": { padding: 0 }, backgroundColor: "#1976d2", width: 25, height: 25, padding: 0, color: "white", marginLeft: "2px" }} />

}


export enum StepModelAttribute {
  Company = 1,
  Contact = 2,
  Address = 3,
  BBEEE = 4,
  Shareholders = 5,
  Custom = 6,
}


export enum Artefacts {
  registeredName = 1,
  registrationNumber = 2,
  tradingName = 3,
  vatNumber = 4,
  companyTypeId = 5,
  countryId = 6,
  incomeTaxNumber = 8,
  taxClearanceNumber = 9,
  tradeDescription = 10,
}
export const ArtefactToString = (value: Artefacts) => {
  switch (value) {
    case Artefacts.registeredName:
      return "registeredName"
    case Artefacts.registrationNumber:
      return "registrationNumber"
    case Artefacts.tradingName:
      return "tradingName"
    case Artefacts.vatNumber:
      return "vatNumber"
    case Artefacts.companyTypeId:
      return "companyTypeId"
    case Artefacts.incomeTaxNumber:
      return "incomeTaxNumber"
    case Artefacts.taxClearanceNumber:
      return "taxClearanceNumber"
    case Artefacts.tradeDescription:
      return "tradeDescription"
    default:
      return "Unknown";
  }
};

export enum ArtefactTypes {
  Text = 1,
  Dropdown = 2,
  Document = 3,
  Number = 4,
}

export const stepStatusToString = (value: StepStatus) => {
  switch (value) {
    case StepStatus.NotStarted:
      return "Not Started"
    case StepStatus.Error:
      return "Validation Failed"
    case StepStatus.Completed:
      return "Completed"
    default:
      return "Unknown";
  }
};





