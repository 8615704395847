import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Grid } from "@mui/material";
import { FC, ReactNode } from "react";



const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) => <Slide direction="up" ref={ref} {...props} />);

interface DialogModalProps{
  isOpen:boolean,
  onHandleClose: () => void,
  children: ReactNode,
}

const DialogModal: FC<DialogModalProps> = ({ isOpen, onHandleClose, children }) => (
  <Dialog
    maxWidth="lg"
    open={isOpen}
    scroll="body"
    onClose={onHandleClose}
    TransitionComponent={Transition}
  >
    <Grid container justifyContent="flex-end">
      <Grid item xs={0.5} py={1}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onHandleClose}
          aria-label="close"
        >
          <CloseIcon className="color-primary" />
        </IconButton>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  </Dialog>
)



export default DialogModal;