import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyBankAccounts } from "../../../http/Company/bankingDetails";
import { BankAccountDetailsModel } from "../../../http/Company/Models/BankAccountDetailsModel";

const useCompanyBankingDetails = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyBankingDetails, companyId],
    queryFn: async () => (await GetCompanyBankAccounts(companyId, false)) as Array<BankAccountDetailsModel>,
    staleTime: 600000,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyBankingDetails;