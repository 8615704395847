import React, { useEffect, useMemo, useState } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { BBEIcon } from "../../../../util/MUI_Theme/Icons";
import { CoidaDetails } from "../../../../http/Company/Models/CoidaDetails";
import TextField from "../Controls/TextField";

import DatePicker from "../Controls/DatePicker";
import formatDate from "../../../../util/formatter/formatDate";

interface CoidaInfoProps {
  coidaDetailsModel: CoidaDetails;
  sectionEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  updateSectionEnabled: (fieldName: string, value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateCoidaDetailsModel: (updatedModel: CoidaDetails) => void;
}

const COIDA_ENABLED_FIELD_NAME = "hasCoidaEnabled";

const CoidaInfo = ({
  coidaDetailsModel,
  sectionEnabled,
  updateSectionEnabled,
  updateCoidaDetailsModel,
}: CoidaInfoProps) => {
  const DEFAULT_OBJECT: CoidaDetails = useMemo(
    () => ({
      id: coidaDetailsModel.id || 0,
      cfRegistrationNumber: null,
      certificateNumber: null,
      dateOfIssue: null,
      expiryDate: null,
      companyId: coidaDetailsModel.companyId,
      createdBy: "00000000-0000-0000-0000-000000000000",
      dateCreated: "",
      modifiedBy: "00000000-0000-0000-0000-000000000000",
      dateModified: "",
      isDeleted: false,
      deletedBy: null,
      dateDeleted: null,
    }),
    [coidaDetailsModel.companyId, coidaDetailsModel.id]
  );

  const [issueDate, setIssueDate] = useState<Dayjs | null>(null);
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(null);

  const handleCoidaRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      updateCoidaDetailsModel(DEFAULT_OBJECT);
      updateSectionEnabled(COIDA_ENABLED_FIELD_NAME, false);
      setIssueDate(null);
      setExpiryDate(null);
      return;
    }

    updateSectionEnabled(COIDA_ENABLED_FIELD_NAME, true);
  };

  const handleIssueDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...coidaDetailsModel,
        dateOfIssue: null,
      } as CoidaDetails;
      setIssueDate(null);
      updateCoidaDetailsModel(newVal);
      return;
    }

    const newVal = {
      ...coidaDetailsModel,
      dateOfIssue: formatDate(selectedDate.toDate().toString()),
    } as CoidaDetails;
    setIssueDate(selectedDate);
    updateCoidaDetailsModel(newVal);
  };

  const handleExpiryDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...coidaDetailsModel,
        expiryDate: null,
      } as CoidaDetails;
      setExpiryDate(selectedDate);
      setExpiryDate(null);
      updateCoidaDetailsModel(newVal);
      return;
    }

    const newVal = {
      ...coidaDetailsModel,
      expiryDate: formatDate(selectedDate.toDate().toString()),
    } as CoidaDetails;
    setExpiryDate(selectedDate);
    updateCoidaDetailsModel(newVal);
  };

  useEffect(() => {
    setIssueDate(dayjs(coidaDetailsModel.dateOfIssue));
    setExpiryDate(dayjs(coidaDetailsModel.expiryDate));
  }, [coidaDetailsModel.dateOfIssue, coidaDetailsModel.expiryDate, coidaDetailsModel.id]);


  return (
    <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
      <Grid item xs={12} className="profile-view-labels">
        <BBEIcon /> COIDA INFORMATION
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <div className="text-label-box">
            <span className="text-label-astrid">*</span>
            Are you Registered for COIDA ?
          </div>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={sectionEnabled ? "yes" : "no"}
            onChange={handleCoidaRadioBtnChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="CF Registration Number"
            value={coidaDetailsModel.cfRegistrationNumber || ""}
            onChange={(e) => {
              const newVal = {
                ...coidaDetailsModel,
                cfRegistrationNumber: e.target.value,
              } as CoidaDetails;
              updateCoidaDetailsModel(newVal);
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Certificate Number"
            value={coidaDetailsModel?.certificateNumber || ""}
            onChange={(e) => {
              const newVal = {
                ...coidaDetailsModel,
                certificateNumber: e.target.value,
              } as CoidaDetails;
              updateCoidaDetailsModel(newVal);
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disabled={!sectionEnabled}
            disableFuture
            value={issueDate}
            title="Issue Date"
            width="90%"
            setDatePicker={handleIssueDateChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disabled={!sectionEnabled}
            disablePast
            value={expiryDate}
            title="Expiry Date"
            width="90%"
            setDatePicker={handleExpiryDateChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoidaInfo;
