import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { MessagingSlice, NotificationSlice } from "./NotificationsSlice";
import { NotificationModel } from "../../Notifications/Models/NotificationModel";
import { GetNotificationsByRecipientAccountId } from "../../Notifications/Notification";
import { RootState } from "../../../app/store";
import { GetChatList } from "../../Messaging/Messaging";
import { ChatSummaryModel } from "../../Messaging/Models/ChatSummaryModel";

export const NotificationActions = NotificationSlice.actions;
export const ChatActions = MessagingSlice.actions;

export const fetchRead = (id: string, isRead: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<NotificationModel> = await (GetNotificationsByRecipientAccountId(id, isRead));
  dispatch(NotificationActions.setReadNotification(apiResponse));
}


export const fetchUnread = (id: string, isRead: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<NotificationModel> = await (GetNotificationsByRecipientAccountId(id, isRead));
  dispatch(NotificationActions.setUnreadNotification(apiResponse));
}

export const fetchMessaging = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<ChatSummaryModel> = await (GetChatList(companyId));
  dispatch(ChatActions.setMessaging(apiResponse));
}