import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyConnectionsStats } from "../../../http/Company/Dashboard";
  
const useCompanyConnectionStats = () => {
  const query = useQuery({
    queryKey: [queryKeys.dashboardStats],
    queryFn: async () => (await GetCompanyConnectionsStats()).data,
    staleTime: 300000, // 5 minutes
  })
  
  return query;
}
  
export default useCompanyConnectionStats;