
import Joyride, { ACTIONS, BeaconRenderProps, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useMount, useSetState } from "react-use";
import { Box, IconButton, keyframes } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useAccount, useMsal } from "@azure/msal-react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Zen from "../../util/images/zen-blinkie.gif"
import Blinky1 from "../../util/images/BlinkyTourOne.png";
import Blinky2 from "../../util/images/BlinkyTourTwo.png";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import PromptStatus from "../../util/enums/PromptStatus";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import UserPromptActivityService from "../../http/UserPromptActivityService";
import { fetchUserPromptActivity } from "../../http/Redux/Store/UserPromptActivityActions";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { stringToType } from "../../util/enums/PopupTypes";






interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.3);
  }
`;
const BeaconButton = styled.button`
animation: ${pulse} 1s ease-in-out infinite;
background-image: url(${Zen});
background-size: cover;
background-repeat:no-repeat;
background-position: center;
border:0px;
background-color:transparent;
height: 6rem;
width: 6rem;
`;
const BeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>((props, ref) => <BeaconButton className="tour-btn" ref={ref} {...props} />);


const Tour = ({ pageSteps, runs, callBack, page }: {
  pageSteps: [],
  runs: boolean,
  // eslint-disable-next-line no-unused-vars
  callBack: (value: number) => void,
  page: string,
}) => {
  const [{ run, stepIndex, steps }, setState] = useSetState<State>({
    run: runs,
    stepIndex: 0,
    steps: pageSteps,
  });
  const prompts = useAppSelector(getUserPromptActivity);
  const dispatch = useAppDispatch();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { accounts } = useMsal();
  const [tourImage, setTourImage] = useState(Blinky1);
  const account = useAccount(accounts[0] || {});
  const companyId = account?.idTokenClaims?.extension_companyid as number;


  useMount(() => {
    setState({ run: true });
  });

  const handleClickReset = () => {
    setState({ run: true, stepIndex: 0 });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      // Update state to advance the tour
      setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) || action === "close") {
      // Need to set our running state to false, so we can restart if we click start again.
      callBack(1);
      if (prompts.filter((x) => x.popupPromptId === UserPopupActivities.Tour && x.status === PromptStatus.Show && x.seenPopupId === stringToType(page))[0]) {

        setIsLoadingSave(true);
        const loaderPrompt = async () => {
          const newModel: UserPromptActivityModel = {
            id: prompts.filter((x) => x.popupPromptId === UserPopupActivities.Tour && x.status === PromptStatus.Show)[0].id,
            accountId: prompts.filter((x) => x.popupPromptId === UserPopupActivities.Tour && x.status === PromptStatus.Show)[0].accountId,
            popupPromptId: UserPopupActivities.Tour,
            seenPopupId: stringToType(page) as number,
            status: PromptStatus.Closed,
            dateCreated: new Date(),
            dateModified: new Date(),
          };
          await UserPromptActivityService.updateUserPromptActivityPopup(newModel).finally(() => {
            dispatch(fetchUserPromptActivity(newModel.accountId as string, companyId));
            setIsLoadingSave(false);
          });
        };

        loaderPrompt();
      }
      setState({ run: false });
    }

  };


  useEffect(() => {

    if (page === "Dashboard" && runs !== true) {
      setState({ run: false });
    }
    else if (page === "Dashboard" && runs) {
      setState({ run: true });
    }

  }, [setState, pageSteps.length, prompts.length, steps.length, callBack.length, runs]);


  return (
    <>
      <LoadingBackdrop showBackdrop={isLoadingSave} />
      <Joyride
        callback={handleJoyrideCallback}
        beaconComponent={BeaconComponent}
        continuous
        run={run}
        scrollOffset={100}
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            primaryColor: "#435cd2",
            textColor: "#000",
          },
        }}
      />
      {!run && (
        <Box mt="xl" sx={{ width: "100%", textAlign: "right", marginRight: "20px", marginBottom: "10px" }}>
          <IconButton className="icon-tour-btn" onMouseEnter={() => setTourImage(Blinky2)} onMouseLeave={() => setTourImage(Blinky1)} onClick={handleClickReset}>
            <img style={{ width: "100%" }} src={tourImage} alt="" />
          </IconButton>
        </Box>
      )}
    </>
  );
}

export default Tour;
