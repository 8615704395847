import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "./Helpers/httpRequestHelpers";
import { CompanyVerificationModel } from "./Redux/Models/CompanyVerificationModel";
import { CompaniesRequiringVerificationModel } from "./Redux/Models/CompaniesRequiringVerificationModel";
import { AddCompanyVerificationModel } from "./Verification/AddCompanyVerifcationModel";
import { AddRequestVerificationModel } from "./Verification/AddVerificationRequestModel";
import { CompaniesCountModel } from "./Redux/Models/CompaniesCountModel";
import { CompanyRequestModel } from "./Redux/Models/CompanyRequestModel";
import { CompanyValidationConfigModel } from "./Redux/Models/CompanyValidationConfigModel";
import { CompanyValidationRankingModel } from "./Redux/Models/CompanyValidationRankingModel";
import { AgentTaskModel } from "./Company/Models/AgentTaskModel";
import { ManagedCompaniesModel } from "./Redux/Models/ManagedCompaniesModel";
import { DataManagementPartnerModel } from "./Redux/Models/DataManagementPartnerModel";
import { PartnerManagedCompaniesModel } from "./Redux/Models/PartnerManagedCompaniesModel";
import { SupplierMatchViewModel } from "./Redux/Models/SupplierMatchViewModel";
import { AgentSearchListModel } from "./Redux/Models/AgentSearchListModel";
import { AgentTaskListGridModel } from "./Redux/Models/AgentTaskListGridModel";
import { SupplierModel } from "./Company/Models/SupplierModel";



const apiScopes = protectedResources.companyVerificationWebApi.scopes;
const baseUrl = protectedResources.companyVerificationWebApi.endpoint;


export default {
  async getAllCompanyVerifications(id: number, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/Verification/${id}?staged=${staged}`;
    const result = (await (makeGetRequestAsync<Array<CompanyVerificationModel>>(apiScopes, url)));
    return result.data;

  },
  async getAllCompaniesRequiringVerification(offset: number, rows: number, search: string, staged: boolean, companyId: number) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/Required?offset=${offset}&rows=${rows}&search=${search}&staged=${staged}&companyId=${companyId}`;
    return (await (makeGetRequestAsync<Array<CompaniesRequiringVerificationModel>>(apiScopes, url))).data
  },
  async getAllUnmanagedCompaniesRequiringVerification(offset: number, rows: number, search: string, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/Unmanaged?offset=${offset}&rows=${rows}&search=${search}&staged=${staged}`;
    return (await (makeGetRequestAsync<Array<CompaniesRequiringVerificationModel>>(apiScopes, url))).data
  },
  async getCompaniesCount(staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/Count?staged=${staged}`;
    const count = (await (makeGetRequestAsync<number>(apiScopes, url))).data;

    const CompaniesCountReturn: CompaniesCountModel = { numberOfCompanies: count };
    return CompaniesCountReturn;
  },
  async getCompanyRequest(companyId: number, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerificationRequest/Request/${companyId}?staged=${staged}`;
    return (await (makeGetRequestAsync<CompanyRequestModel>(apiScopes, url))).data;
  },
  async addCompanyVerification(verification: AddCompanyVerificationModel, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/Verification?staged=${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, verification))).status;

  },
  async addRequestVerification(verification: AddRequestVerificationModel, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerificationRequest/Request?staged=${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, verification))).status;

  },
  async updateAgentTask(task: AgentTaskModel, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList?staged=${staged}`;
    return (await (makePatchRequestAsync(apiScopes, url, task))).status;

  },
  async addAgentTask(task: AgentTaskModel, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList?staged=${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, task))).data;

  },
  async getAllCompanyValidationConfig(companyId: number, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/CompanyValidationConfig?companyId=${companyId}&staged=${staged}`;
    return (await (makeGetRequestAsync<Array<CompanyValidationConfigModel>>(apiScopes, url))).data;
  },
  async getAllCompanyValidationRankings(companyId: number, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/CompanyValidationRank?companyId=${companyId}&staged=${staged}`;
    return (await (makeGetRequestAsync<Array<CompanyValidationRankingModel>>(apiScopes, url))).data;
  },
  async getManagedCompaniesByPartnerId(companyId: number, staged: boolean) {
    const url = `${baseUrl}/api/CompanyVerification/PartnerManagedCompanies/${companyId}?staged=${staged}`;
    return (await (makeGetRequestAsync<Array<ManagedCompaniesModel>>(apiScopes, url))).data;
  },
  async getAllDMP() {
    const url = `${baseUrl}/api/CompanyVerification/DataManagementPartner/GetAllDMP`;
    return (await makeGetRequestAsync<DataManagementPartnerModel[]>(apiScopes, url)).data;
  },
  async addDataManagementPartner(companyId: number) {
    const url = `${baseUrl}/api/CompanyVerification/DataManagementPartner/AddDMP?companyId=${companyId}`;
    return (await makePostRequestAsync(apiScopes, url)).data;
  },
  async updateDataManagementPartner(partner: DataManagementPartnerModel) {
    const url = `${baseUrl}/api/CompanyVerification/DataManagementPartner/UpdateDMP`;
    return (await makePatchRequestAsync(apiScopes, url, partner)).data;
  },
  async addDataManagementClient(managedCompany: PartnerManagedCompaniesModel) {
    const url = `${baseUrl}/api/CompanyVerification/PartnerManagedCompanies`;
    return (await makePostRequestAsync(apiScopes, url, managedCompany)).data;
  },
  async getTotalSuppliers(companyId: number) {
    const url = `${baseUrl}/api/CompanyVerification/PartnerManagedCompanies/Suppliers/${companyId}`;
    return (await (makeGetRequestAsync<number>(apiScopes, url))).data;
  },
  async getSupplierMatch(companyId: number) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/SupplierMatch?companyId=${companyId}`;
    return (await (makeGetRequestAsync<SupplierMatchViewModel[]>(apiScopes, url))).data;
  },
  async getAgentTaskList(userId: number) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/MyTasks?userId=${userId}`;
    return (await (makeGetRequestAsync<AgentTaskListGridModel[]>(apiScopes, url))).data;
  },
  async getAllAgentTaskLists() {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/MyTasks/All`;
    return (await (makeGetRequestAsync<AgentTaskListGridModel[]>(apiScopes, url))).data;
  },
  async getAgentSearchList(search: string) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/SearchResults?search=${search}`;
    return (await (makeGetRequestAsync<AgentSearchListModel[]>(apiScopes, url))).data;
  },
  async acceptMatch(managedCompany: SupplierMatchViewModel) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/AcceptMatch`;
    return (await makePostRequestAsync(apiScopes, url, managedCompany)).data;
  },
  async createMaster(managedCompany: SupplierMatchViewModel) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/CreateMaster`;
    return (await makePostRequestAsync(apiScopes, url, managedCompany)).data;
  },
  async createStaged(managedCompany: SupplierMatchViewModel) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/CreateStaged`;
    return (await makePostRequestAsync(apiScopes, url, managedCompany)).data;
  },
  async updateUploadStaged(temp: string, companyId: number, model: SupplierModel) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/UpdateStagingUpload?tempCompanyId=${temp}&companyId=${companyId}`;
    return (await makePostRequestAsync(apiScopes, url, model)).data;
  },

  async removeClientFromPartner(clientId: number, partnerId: number) {
    const url = `${baseUrl}/api/CompanyVerification/PartnerManagedCompanies/RemoveClientFromPartner?clientId=${clientId}&partnerId=${partnerId}`;
    return (await makePostRequestAsync(apiScopes, url)).data;
  },
  async rematch(managedCompany: SupplierMatchViewModel) {
    const url = `${baseUrl}/api/CompanyVerification/AgentTaskList/Rematch`;
    return (await makePostRequestAsync(apiScopes, url, managedCompany)).data;
  },

}

