import { Box, Chip, Slide, Step, StepButton, Stepper, Typography, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";
import InputIcon from "@mui/icons-material/Input";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PermissionModel } from "../../http/Redux/Models/PermissionModel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { platformHeadingsToString } from "../../util/enums/PlatformTabHeadings";
import { fetchPermissions } from "../../http/Redux/Store/PermissionActions";
import AllFieldsView from "../../components/Configuration/AllFieldsView";
import AllFieldValidationsView from "../../components/Configuration/AllFieldValidationsView";
import AllPermissionsView from "../../components/Configuration/AllPermissionsView";



interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const GetStepState = (index: number) => {

  if (index === 0) {
    return <InputIcon sx={{ color: "#1976d2" }} />
  }
  if (index === 1) {
    return <RuleFolderIcon sx={{ color: "#1976d2" }} />
  }
  if (index === 2) {
    return <ReceiptLongIcon sx={{ color: "#1976d2" }} />
  }


  return <Chip label={index} sx={{ "& .MuiChip-label": { padding: 0 }, backgroundColor: "#1976d2", width: 25, height: 25, padding: 0, color: "white", marginLeft: "2px" }} />

}


const PlatFormBase = () => {

  const theme = useTheme();
  const [value, setValue] = useState<number>(0);
  const [subHeading, setSubHeading] = useState("");
  const allPermissions = useAppSelector((state: { permission: { allPermissions: Array<PermissionModel> }; }) => state.permission.allPermissions);
  const dispatch = useAppDispatch();


  const steps = [
    {
      id: 0,
      label: "Data Fields",
      description: "Only able to toggle required State",
      timeout: 1000,
    },
    {
      id: 1,
      label: "Field Validations",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
      timeout: 1200,
    },
    {
      id: 2,
      label: "Permissions",
      description: "The System Permissions linked to Subscriptions and Fields",
      timeout: 1400,
    },
  ];


  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
  };


  const handleChangeIndex = (index: number) => {
    setValue(index);
  };



  const refreshPermissions = () => {
    const loader = async () => {
      dispatch(fetchPermissions());
    }
    loader();
  }



  const handleSetSubHeading = (newSub: string) => {
    setSubHeading(newSub);
  }


  return (

    <div className="config-header-box">
      <div className="config-sub-box">
        <div className="config-sub-box-left">
          <Stepper activeStep={value} orientation="vertical" sx={{ marginTop: "10px", marginBottom: "10px" }}>
            {steps.map((step) => (
              <Slide
                direction="right"
                in
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...({ timeout: step.timeout })}
              >
                <Step disabled={false} onClick={() => {
                  handleChangeTab(step.id)
                }}>
                  <StepButton
                    disabled={false}
                    className="stepButton"
                    sx={{ fontWeight: 900, borderRight: step.id === value ? "2px solid #435cd2" : "" }}
                    // eslint-disable-next-line no-nested-ternary
                    icon={GetStepState(step.id)}
                    key={step.id}>
                    {step.label}
                  </StepButton>
                </Step>
              </Slide>
            )
            )}
          </Stepper>
        </div>
        <div className="config-sub-box-right">
          <div style={{ width: "100%", height: "80px", paddingLeft: "20px", paddingTop: "10px" }}>
            <Typography>
              <h3 className="heading-sub">{platformHeadingsToString(value)}</h3>
              {subHeading.length > 0 &&
                <>
                  <div style={{ float: "left", marginLeft: "-5px", color: " #435cd2" }}>
                    <KeyboardArrowRightIcon />
                  </div>
                  <div style={{ float: "left" }}>
                    <Typography>
                      {subHeading}
                    </Typography>
                  </div>
                </>
              }
            </Typography>
          </div>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AllFieldsView />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AllFieldValidationsView handleSetSubHeading={handleSetSubHeading} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <AllPermissionsView allPermissions={allPermissions} handleRefresh={refreshPermissions} />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>

  )
};


export default PlatFormBase;