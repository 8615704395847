import { Box } from "@mui/material";
import { ReactNode } from "react";

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <div style={{ height: "fit-content", borderTopLeftRadius: 0 }}>
          <Box sx={{ borderTopLeftRadius: 0 }}>
            {children}
          </Box>
        </div>}
    </div>
  );
};

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
})


export default CustomTabPanel;