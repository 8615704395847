/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum BeeRatingStatus {
  none = 0,
  ratingPending = 1,
  rated = 2,
  ratingExpiring = 3,
  ratingExpired = 4,
  declined = 5,
  captured = 6,
  followup = 7,
}

const beeRatingStatusToString = (status: BeeRatingStatus): string => {
  switch (status) {
    case BeeRatingStatus.none:
      return "None";
    case BeeRatingStatus.ratingPending:
      return "Rating Pending";
    case BeeRatingStatus.rated:
      return "Rated";
    case BeeRatingStatus.ratingExpiring:
      return "Rating Expiring";
    case BeeRatingStatus.ratingExpired:
      return "Rating Expired";
    case BeeRatingStatus.declined:
      return "Declined";
    case BeeRatingStatus.captured:
      return "Captured";
    case BeeRatingStatus.followup:
      return "Follow-up";
    default:
      return "Unknown";
  }
};

const getBeeRatingStatusColor = (ratingStatus: BeeRatingStatus) => {
  switch (ratingStatus) {
    case BeeRatingStatus.none:
      return "default";
    case BeeRatingStatus.captured:
    case BeeRatingStatus.followup:
    case BeeRatingStatus.ratingExpiring:
    case BeeRatingStatus.ratingPending:
      return "warning";
    case BeeRatingStatus.declined:
    case BeeRatingStatus.ratingExpired:
      return "error";
    case BeeRatingStatus.rated:
      return "success";
    default:
      return "default";
  }
}

export { BeeRatingStatus, beeRatingStatusToString, getBeeRatingStatusColor };