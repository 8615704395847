import { TextField, InputAdornment } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarMonthOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { Dayjs } from "dayjs";

const CustomDatePicker = ({
  setDateSelector,
  width,
  value,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  format = "YYYY-MM-DD",
}: // eslint-disable-next-line no-unused-vars, react/require-default-props
{
  // eslint-disable-next-line no-unused-vars
  setDateSelector: (selectedDate: Dayjs | null) => void;
  width: string;
  value: Dayjs | null;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  disablePast?: boolean;
  // eslint-disable-next-line react/require-default-props
  disableFuture?: boolean;
  // eslint-disable-next-line react/require-default-props
  format?: string;
}) => {
  const [open, setOpen] = useState(false);

  const handleAdornmentClick = () => {
    setOpen(true);
  };

  const handleCloseDatePicker = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disablePast={disablePast}
        disableFuture={disableFuture}
        open={open}
        inputFormat={format}
        disabled={disabled}
        onClose={handleCloseDatePicker}
        value={value}
        onChange={(d) => setDateSelector(d as Dayjs)}
        renderInput={(params) => (
          <TextField
            sx={{ width }}
            onClick={handleAdornmentClick}
            {...params}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              (value === null || !value.isValid() || value.toString().trim().length === 0) &&
              !disabled
            }
            helperText={
              (value === null || !value.isValid() || value.toString().trim().length === 0) &&
              !disabled &&
              "This field is required!"
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
