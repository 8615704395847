/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import RequestStatus from "./RequestStatus";

// eslint-disable-next-line no-shadow
enum AgentTaskStatusTypes {
  Unassigned = 0,
  Assigned = 1,
  InProgress = 2,
  FollowUp = 3,
  Completed = 4,
  Abandoned = 5,
  NotStarted = 6,
}


const AgentTaskStatusTypesToString = (statusType: AgentTaskStatusTypes): string => {
  switch (statusType) {
    case AgentTaskStatusTypes.Unassigned:
      return "Unassigned";
    case AgentTaskStatusTypes.Assigned:
      return "Assigned";
    case AgentTaskStatusTypes.InProgress:
      return "In Progress";
    case AgentTaskStatusTypes.FollowUp:
      return "Follow-Up";
    case AgentTaskStatusTypes.Completed:
      return "Completed";
    case AgentTaskStatusTypes.Abandoned:
      return "Abandoned";
    default:
      return "Unknown";
  }
};


const RequestToAgentTaskStatusType = (statusType: RequestStatus): AgentTaskStatusTypes => {
  switch (statusType) {
    case RequestStatus.NotStarted:
      return AgentTaskStatusTypes.NotStarted
    case RequestStatus.Completed:
      return AgentTaskStatusTypes.Completed
    case RequestStatus.Inprogress:
      return AgentTaskStatusTypes.InProgress
    case RequestStatus.Followup:
      return AgentTaskStatusTypes.FollowUp
    default:
      return AgentTaskStatusTypes.Assigned;
  }
};

export { AgentTaskStatusTypes, AgentTaskStatusTypesToString, RequestToAgentTaskStatusType };
