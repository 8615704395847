// eslint-disable-next-line no-shadow
enum NotificationSeverity { // eslint-disable-line no-unused-vars
  Information = 1,// eslint-disable-line no-unused-vars
  Success = 2,// eslint-disable-line no-unused-vars
  Warning = 3,// eslint-disable-line no-unused-vars
  Error = 4,// eslint-disable-line no-unused-vars
}

export default NotificationSeverity;

export const notificationSeverityToString = (value: NotificationSeverity) => {
  switch (value) {
    case NotificationSeverity.Information:
      return "info";
    case NotificationSeverity.Success:
      return "success";
    case NotificationSeverity.Warning:
      return "warning";
    case NotificationSeverity.Error:
      return "error";
    default:
      return undefined;
  }
};