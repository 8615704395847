import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { HasSubscriptionArrayModel, HasSubscriptionModel } from "../../SubscriptionManagement/Models/HasSubscriptionModel";
import { FeaturePermissionModel, SubscriptionFeaturePermissionsArrayModel } from "../Models/FeaturePermissionModel";
import { SubscriptionFeatureArrayModel, SubscriptionFeatureModel } from "../Models/SubscriptionFeatureModel";
import { SubscriptionPlanArrayModel, SubscriptionPlanModel } from "../Models/SubscriptionPlanModel";
import { SubscriptionProductAddonsArrayModel, SubscriptionProductAddonsModel } from "../Models/SubscriptionProductAddonsModel";
import { SubscriptionProductFeaturesArrayModel, SubscriptionProductFeaturesModel } from "../Models/SubscriptionProductFeaturesModel";
import { SubscriptionProductArrayModel, SubscriptionProductModel } from "../Models/SubscriptionProductModel";
import { SubscriptionProductPlansArrayModel, SubscriptionProductPlansModel } from "../Models/SubscriptionProductPlansModel";
import { SubscriptionVoucherArrayModel, SubscriptionVoucherModel } from "../Models/SubscriptionVoucherModel";
import { SubscriptionVoucherPlansArrayModel, SubscriptionVoucherPlansModel } from "../Models/SubscriptionVoucherPlansModel";
import { CompanySubscription, CurrentActiveSubscriptionArrayModel } from "../../SubscriptionManagement/Models/CompanySubscription";
import { SubscriptionBilling } from "../../SubscriptionManagement/Models/SubcriptionBilling";

const initialProductState: SubscriptionProductArrayModel = {
  allViewSubscriptionProduct: [],
  particularSubscriptionProduct: {
    id: 0,
    productName: "",
    isActive: false,
    validStart: new Date,
    validEnd: new Date,
    displayInWidget: 0,
    baseSubscription: false,
    redirectURL: "",
    isAddon: false,
    addonType: 0,
    dateCreated: new Date,
    createdBy: null,
    modifiedBy: null,
    dateModifed: new Date,
  },
}

export const subscriptionProductSlice = createSlice({
  name: "subscriptionProducts",
  initialState: initialProductState,
  reducers: {
    setProducts(state, action: PayloadAction<SubscriptionProductModel[]>) {
      state.allViewSubscriptionProduct = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularProduct(state, action: PayloadAction<SubscriptionProductModel>) {
      state.particularSubscriptionProduct = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllProducts = (state: RootState) => state.subscriptionProducts.allViewSubscriptionProduct;


const initialProductFeaturesState: SubscriptionProductFeaturesArrayModel = {
  allViewSubscriptionProductFeatures: [],
  particularSubscriptionProductFeatures: {
    productId: 0,
    productFeatureId: 0,
    productName: "",
    productIsActive: false,
    validStart: new Date,
    validEnd: new Date,
    displayInWidget: 0,
    baseSubscription: false,
    rediretURL: "",
    isAddon: 0,
    addonType: 0,
    featureId: 0,
    featureName: "",
    featureIsActive: false,
  },
}

export const subscriptionProductFeaturesSlice = createSlice({
  name: "subscriptionFeatures",
  initialState: initialProductFeaturesState,
  reducers: {
    setProductFeatures(state, action: PayloadAction<SubscriptionProductFeaturesModel[]>) {
      state.allViewSubscriptionProductFeatures = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularProductFeature(state, action: PayloadAction<SubscriptionProductFeaturesModel>) {
      state.particularSubscriptionProductFeatures = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllProductFeatures = (state: RootState) => state.subscriptionProductFeatures.allViewSubscriptionProductFeatures;


const initialFeatureState: SubscriptionFeatureArrayModel = {
  allViewSubscriptionFeature: [],
}
export const subscriptionFeatureSlice = createSlice({
  name: "subscriptionFeatures",
  initialState: initialFeatureState,
  reducers: {
    setFeatures(state, action: PayloadAction<SubscriptionFeatureModel[]>) {
      state.allViewSubscriptionFeature = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllFeatures = (state: RootState) => state.subscriptionFeatures.allViewSubscriptionFeature;

const initialPlanState: SubscriptionPlanArrayModel = {
  subscriptionPlans: [],
}

export const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState: initialPlanState,
  reducers: {
    setPlans(state, action: PayloadAction<SubscriptionPlanModel[]>) {
      state.subscriptionPlans = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllPlans = (state: RootState) => state.subscriptionPlans.subscriptionPlans;


const initialProductPlansState: SubscriptionProductPlansArrayModel = {
  allViewSubscriptionProductPlans: [],
  particularSubscriptionProductPlans: {
    productId: 0,
    productPlanId: 0,
    productName: "",
    productIsActive: false,
    validStart: new Date,
    validEnd: new Date,
    displayInWidget: 0,
    baseSubscription: false,
    rediretURL: "",
    isAddon: 0,
    addonType: 0,
    planId: 0,
    planCode: "",
    description: "",
    planIsActive: false,
    amount: 0,
    currency: 0,
    billEvery: 0,
    billInterval: 0,
    billingCycle: 0,
    trialDays: 0,
  },
}

export const subscriptionProductPlansSlice = createSlice({
  name: "subscriptionProductPlans",
  initialState: initialProductPlansState,
  reducers: {
    setProductPlans(state, action: PayloadAction<SubscriptionProductPlansModel[]>) {
      state.allViewSubscriptionProductPlans = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularProductPlans(state, action: PayloadAction<SubscriptionProductPlansModel>) {
      state.particularSubscriptionProductPlans = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllProductPlans = (state: RootState) => state.subscriptionproductPlans.allViewSubscriptionProductPlans;




const initialVoucherState: SubscriptionVoucherArrayModel = {
  allViewSubscriptionVoucher: [],
  particularSubscriptionVoucher: {
    id: 0,
    name: "",
    code: "",
    isActive: false,
    discount: "",
    discountType: 0,
    redemptionType: 0,
    associatedPlans: 0,
    validStart: new Date,
    validEnd: new Date,
    maximumSubscriptionRedemptions: 0,
    dateCreated: new Date,
    createdBy: null,
    modifiedBy: null,
    dateModified: new Date,
  },
}
export const subscriptionVoucherSlice = createSlice({
  name: "subscriptionVoucher",
  initialState: initialVoucherState,
  reducers: {
    setVoucher(state, action: PayloadAction<SubscriptionVoucherModel[]>) {
      state.allViewSubscriptionVoucher = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularVoucher(state, action: PayloadAction<SubscriptionVoucherModel>) {
      state.particularSubscriptionVoucher = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});
export const getAllVoucher = (state: RootState) => state.subscriptionVoucher.allViewSubscriptionVoucher;



const initialVoucherPlansState: SubscriptionVoucherPlansArrayModel = {
  allViewSubscriptionVoucherPlans: [],
  particularSubscriptionVoucherPlans: {
    productId: 0,
    productName: "",
    productIsActive: false,
    productValidStart: new Date,
    productValidEnd: new Date,
    displayInWidget: 0,
    baseSubscription: false,
    rediretURL: "",
    isAddon: false,
    addonType: 0,
    voucherId: 0,
    voucherName: "",
    code: "",
    discount: "",
    discountType: 0,
    redemptionType: 0,
    associatedPlans: 0,
    voucherValidStart: new Date,
    voucherValidEnd: new Date,
    maximumSubscriptionRedemptions: 0,
    planId: 0,
    planCode: "",
    description: "",
    planIsActive: false,
    amount: 0,
    currency: 0,
    billEvery: 0,
    billInterval: 0,
    billingCycle: 0,
    trialDays: 0,
  },
}

export const subscriptionVoucherPlansSlice = createSlice({
  name: "subscriptionVoucherPlans",
  initialState: initialVoucherPlansState,
  reducers: {
    setVoucherPlans(state, action: PayloadAction<SubscriptionVoucherPlansModel[]>) {
      state.allViewSubscriptionVoucherPlans = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularVoucherPlan(state, action: PayloadAction<SubscriptionVoucherPlansModel>) {
      state.particularSubscriptionVoucherPlans = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});
export const getAllVoucherPlans = (state: RootState) => state.subscriptionVoucherPlans.allViewSubscriptionVoucherPlans;



const initialProductAddonsState: SubscriptionProductAddonsArrayModel = {
  allViewSubscriptionProductAddons: [],
  particularSubscriptionProductAddons: {
    productId: 0,
    productAddonId: 0,
    productName: "",
    productIsActive: false,
    validStart: new Date(),
    validEnd: new Date(),
    displayInWidget: 0,
    baseSubscription: false,
    rediretURL: "",
    isAddon: false,
    addonId: 0,
    addonName: "",
    addonIsActive: false,
  },
}

export const subscriptionProductAddonsSlice = createSlice({
  name: "subscriptionProductAddons",
  initialState: initialProductAddonsState,
  reducers: {
    setProductAddons(state, action: PayloadAction<SubscriptionProductAddonsModel[]>) {
      state.allViewSubscriptionProductAddons = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularVoucherPlan(state, action: PayloadAction<SubscriptionProductAddonsModel>) {
      state.particularSubscriptionProductAddons = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});
export const getAllProductAddons = (state: RootState) => state.subscriptionProductAddons.allViewSubscriptionProductAddons;


const initialFeaturePermissionsState: SubscriptionFeaturePermissionsArrayModel = {
  allViewSubscriptionFeaturePermissions: [],
}

export const subscriptionFeaturePermissionsSlice = createSlice({
  name: "subscriptionFeaturePermissions",
  initialState: initialFeaturePermissionsState,
  reducers: {
    setFeaturePermissions(state, action: PayloadAction<FeaturePermissionModel[]>) {
      state.allViewSubscriptionFeaturePermissions = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});
export const getAllFeaturePermissions = (state: RootState) => state.subscriptionFeaturePermissions.allViewSubscriptionFeaturePermissions;

const initialHasSubscriptionsState: HasSubscriptionArrayModel = {
  allHasSubscriptions: [],
}

export const hasSubscriptionsSlice = createSlice({
  name: "hasSubscriptions",
  initialState: initialHasSubscriptionsState,
  reducers: {
    setHasSubscriptions(state, action: PayloadAction<HasSubscriptionModel[]>) {
      state.allHasSubscriptions = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});


export const getHasSubscriptions = (state: RootState) => state.hasSubscriptions.allHasSubscriptions;


const initialCurrentActiveSubscriptionState: CurrentActiveSubscriptionArrayModel = {
  particularCurrentSubscription: {
    id: 0,
    companyId: 0,
    planId: 0,
    voucherId: 0,
    subscriptionBilling: {} as SubscriptionBilling,
    productAddOns: [],
    isCancelled: false,
    dateCancelled: new Date(),
  },
}

export const currentActiveSubscriptionsSlice = createSlice({
  name: "currentActiveSubscription",
  initialState: initialCurrentActiveSubscriptionState,
  reducers: {
    setParticularCurrentSubscription(state, action: PayloadAction<CompanySubscription>) {
      state.particularCurrentSubscription = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});


export const getCurrentSubscription = (state: RootState) => state.currentActiveSubscription.particularCurrentSubscription;