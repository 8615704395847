import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Button } from "@mui/material";

import Swal from "sweetalert2";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { protectedResources } from "../../authConfig";
import {
  GetBaseCompanyInfoViaUserId,
  GetCompanyDataById,
  SaveCompanyChanges,
  SaveEntireCompany,
} from "../../http/Company/company";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  removeBankAccFromState,
  removeContactFromState,
  resetCompanyState,
  selectCompany,
  setCompanyAddressState,
  setCompanyBankAccountState,
  setCompanyBeeDetailsState,
  setCompanyContactState,
  setCompanyViewModelState,
} from "../../http/Redux/Store/companySlice";

// CSS
import CompanyProfileSidePanel from "../../components/Company/CompanyProfileSidePanel/CompanyProfileSidePanel";
import CompanyHeadingPost from "../../components/Company/CompanyPost/CompanyHeadingPost";
import CompanyAdminUserPost from "../../components/Company/CompanyPost/CompanyDetails/CompanyAdminUserPost";
import CompanyDetailsPost from "../../components/Company/CompanyPost/CompanyDetails/CompanyDetailsPost";
import CompanyTaxInformationPost from "../../components/Company/CompanyPost/CompanyDetails/CompanyTaxInformationPost";
import CompanyGeneralContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanyGeneralContactPost";
import CompanyPrimaryContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanyPrimaryContactPost";
import CompanySecondaryContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanySecondaryContactPost";
import CompanyDocumentsPost from "../../components/Company/CompanyPost/CompanyDocuments/CompanyDocumentsPost";
import CompanyBankingDetailsPost from "../../components/Company/CompanyPost/CompanyBankDetails/CompanyBankingDetailsPost";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import { ContactModel } from "../../http/Company/Models/ContactModel";
import CompanyBEEDetails from "../../components/Company/CompanyPost/CompanyBEE/CompanyBEEDetails";
import { AddNewContact, SaveContactChanges, DeleteContact } from "../../http/Company/Contact";
import { AddNewBeeDetails, SaveBeeDetailChanges } from "../../http/Company/beeDetailsApi";
import { BeeDetailsModel } from "../../http/Company/Models/BeeDetailsModel";
import { AddressModel } from "../../http/Company/Models/AddressModel";
import { BankAccountDetailsModel } from "../../http/Company/Models/BankAccountDetailsModel";
import {
  AddNewBankAccount,
  DeleteBankAccount,
  UpdateBankAccount,
} from "../../http/Company/bankingDetails";
import CompanyAddressDetailsPost from "../../components/Company/CompanyPost/CompanyAddress/CompanyAddressDetailsPost";
import AddressType from "../../util/enums/AddressType";
import SaveAddressChanges from "../../http/Company/address";
import UnsavedChangeDialog from "./UnsavedChangeDialog";
import usePrompt from "../../util/hooks/usePrompt";
import PublicProfileSharing from "../Company/PublicProfileSharing";
import {
  selectBaseCompanyInfo,
  setBaseCompanyInfoState,
} from "../../http/Redux/Store/baseCompanyInfoSlice";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import CompanyProductsAndServicesPost from "../../components/Company/CompanyPost/CompanyProductsAndServices/CompanyProductsAndServicesPost";
import { CompanyVerificationModel } from "../../http/Redux/Models/CompanyVerificationModel";
import ContactType from "../../util/enums/ContactType";
import { selectBeeSectorList } from "../../http/Redux/Store/BeeSectorListSlice";
import { fetchBeeSectors } from "../../http/Redux/Store/BeeSectorListActions";
import styles from "./CompanyProfile.module.scss";
import { selectBankNameList } from "../../http/Redux/Store/BankNameListSlice";
import { selectbankAccountTypeList } from "../../http/Redux/Store/BankAccountTypeListSlice";
import { fetchBankAccountTypes } from "../../http/Redux/Store/BankAccountTypeListActions";
import { fetchBankNames } from "../../http/Redux/Store/BankNameListActions";
import { selectUserCompanyList } from "../../http/Redux/Store/UserCompanyListSlice";
import { fetchUserCompanies } from "../../http/Redux/Store/UserCompanyListActions";
import EditCompanyProfile from "../../components/Company/EditCompanyProfile";
import { CompanyProfileImageModel } from "../../http/Redux/Models/CompanyProfileImageModel";
import bufferProfile from "../../util/images/filler.png";
import PDF from "../../components/PDF/PDF";
import Popup from "../../components/Popups/Popup";
import { UnspscSegment } from "../../http/Configuration/UnspscSegment";
import { setUnspscOptionsState } from "../../http/Redux/Store/unspscOptionsSlice";
import getUnspscOptions, {
  getCompanyUnspscSelection,
  updateUnspscSelection,
} from "../../http/Configuration/configurationApi";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import ActivityStatus from "../../util/enums/ActivityStatus";
import { getUserWorkCentre } from "../../http/Redux/Store/UserWorkCentreSlice";
import { UserWorkCentreModel } from "../../http/Redux/Models/UserWorkCentreModel";
import UserWorkCentreService from "../../http/UserWorkCentreService";
import { fetchUserWorkCentre } from "../../http/Redux/Store/UserWorkCentreActions";
import { fetchCurrentCompanyProfileCompletionSummary } from "../../http/Redux/Store/baseCompanyInfoActions";
import { CompanySubscription } from "../../http/SubscriptionManagement/Models/CompanySubscription";
import { getCurrentSubscription } from "../../http/Redux/Store/SubscriptionSlice";
import { fetchCurrentSubscription } from "../../http/Redux/Store/SubscriptionActions";
import CompanyExtendedDetails from "../../components/Company/CompanyPost/CompanyDetails/CompanyExtendedDetails";
import { BaseCompanyInfo } from "../../http/Company/Models/BaseCompanyInfo";
import Shareholders from "../../components/Company/CompanyPost/CompanyShareholders/CompanyShareholders";
import CompanyBusinessLocations from "../../components/Company/CompanyPost/CompanyDetails/BusinessLocations/CompanyBusinessLocations";

const CompanyProfile = () => {
  const company = useAppSelector(selectCompany);
  const baseCompanyInfo: BaseCompanyInfo = useAppSelector(selectBaseCompanyInfo);
  const beeSectors = useAppSelector(selectBeeSectorList);
  const bankNames = useAppSelector(selectBankNameList);
  const bankAccountTypes = useAppSelector(selectbankAccountTypeList);

  const profileImage = useAppSelector(
    (state: {
      companyProfileImage: {
        particularCompanyProfileImage: CompanyProfileImageModel;
      };
    }) => state.companyProfileImage.particularCompanyProfileImage
  );

  const userCompanies = useAppSelector(selectUserCompanyList);

  const { pagesectionid, evaluatingCompanyId, evaluatedCompanyId } = useParams();

  const [companyComparer, setCompanyComparer] = useState<CompanyViewModel>({} as CompanyViewModel);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAdId = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  // Cheap hack to show the correct section.
  // Will fix this to use Redux when a demo isn't looming FFS.
  // 1 = Details, 2 = Contacts, 3 = Address, etc
  const [pageSection, setPageSection] = useState<number>(1);

  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const [, toggleBeeSections] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [redirectPageNo, setRedirectPageNo] = useState<number>(0);
  const [onlyBank] = useState<CompanyVerificationModel>();
  const [stagedValue] = useState(false);
  const [, setBeeStatus] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [expiryDateString, setExpiryDateString] = useState<string>("");
  const [companyUnspscSelection, setCompanyUnspscSelection] = useState<number[]>([]);
  const [products, setProducts] = useState<(UnspscSegment | null)[]>();
  const unspscOptions = useAppSelector((state) => state.unspscOptions);
  const userPrompts: UserPromptActivityModel[] = useAppSelector(getUserPromptActivity);
  const getCurrentActiveSubscription = useAppSelector(getCurrentSubscription);
  const { segments } = unspscOptions;
  const list = useAppSelector(getUserWorkCentre);
  const dispatch = useAppDispatch();
  const [currentSubscription, setCurrentSububscription] = useState<CompanySubscription>(
    {} as CompanySubscription
  );

  const GetCurrentSubscription = useCallback(() => {
    if (!getCurrentActiveSubscription.planId) {
      const loader = async () => {
        dispatch(fetchCurrentSubscription());
        setCurrentSububscription(getCurrentActiveSubscription);
      };

      loader();
    } else {
      setCurrentSububscription(getCurrentActiveSubscription);
    }
  }, []);

  const PageLoadLogic = useCallback(() => {
    if (company.companyBeeDetails?.id !== undefined || company.companyBeeDetails?.id > 0)
      toggleBeeSections(true);
  }, [company.companyBeeDetails?.id]);

  const HandleSameAsPrimaryContactSwitch = (copyValues: boolean, contactToUpdate: ContactModel) => {
    const contactBeingUpdated = { ...contactToUpdate };

    if (copyValues) {
      const primaryContact = company.companyContacts.find(
        (contact) => contact.contactTypeId === ContactType.Primary
      ) as ContactModel;

      // Update specific fields (leaving out addressId, etc)
      contactBeingUpdated.name = primaryContact?.name;
      contactBeingUpdated.email = primaryContact?.email;
      contactBeingUpdated.landLine = primaryContact?.landLine;
      contactBeingUpdated.mobile = primaryContact?.mobile;
      contactBeingUpdated.fax = primaryContact?.fax;
      contactBeingUpdated.website = primaryContact?.website;
      contactBeingUpdated.idNumber = primaryContact?.idNumber;
      contactBeingUpdated.username = primaryContact?.username;
      contactBeingUpdated.designation = primaryContact?.designation ?? "";
      contactBeingUpdated.isDeleted = primaryContact?.isDeleted;

      dispatch(setCompanyContactState(contactBeingUpdated));
      setIsDirty(true);
      return contactBeingUpdated;
    }

    contactBeingUpdated.name = "";
    contactBeingUpdated.email = "";
    contactBeingUpdated.landLine = "";
    contactBeingUpdated.mobile = "";
    contactBeingUpdated.fax = "";
    contactBeingUpdated.website = "";
    contactBeingUpdated.idNumber = "";
    contactBeingUpdated.username = "";
    contactBeingUpdated.designation = "";
    contactBeingUpdated.isDeleted = false;
    contactBeingUpdated.companyId = 0;

    dispatch(setCompanyContactState(contactBeingUpdated));
    if (contactBeingUpdated.id === 0) setIsDirty(false);

    return contactBeingUpdated;
  };

  /*
   * Used by the address components to determine if "Same As Physical" has been selected
   */
  const HandleSameAsPhysicalSwitch = (copyValues: boolean, addressToUpdate: AddressModel) => {
    const addressBeingUpdated = { ...addressToUpdate };

    if (copyValues) {
      const physicalAddress = company.companyAddresses.find(
        (addr) => addr.addressTypeId === AddressType.Physical
      ) as AddressModel;

      // Update specific fields (leaving out addressId, etc)
      addressBeingUpdated.countryId = physicalAddress?.countryId;
      addressBeingUpdated.provinceId = physicalAddress?.provinceId;
      addressBeingUpdated.line1 = physicalAddress?.line1;
      addressBeingUpdated.line2 = physicalAddress?.line2;
      addressBeingUpdated.suburb = physicalAddress?.suburb;
      addressBeingUpdated.city = physicalAddress?.city;
      addressBeingUpdated.postalCode = physicalAddress?.postalCode;

      dispatch(setCompanyAddressState(addressBeingUpdated));
      setIsDirty(true);
      return addressBeingUpdated;
    }

    dispatch(setCompanyAddressState(addressBeingUpdated));
    if (addressBeingUpdated.addressId === 0) setIsDirty(false);
    return addressBeingUpdated;
  };

  /*
   * Save details captured/changed for company.
   */
  const SaveAllCompanyDetails = async (
    companyDetails: CompanyViewModel,
    selectedUnspscCodes?: Array<number>
  ) => {
    if (JSON.stringify(companyComparer) === JSON.stringify(companyDetails)) {
      setIsDirty(false);
      return;
    }

    try {
      await SaveEntireCompany(companyDetails, stagedValue);
      setCompanyComparer(JSON.parse(JSON.stringify(companyDetails)));

      if (selectedUnspscCodes && selectedUnspscCodes.length > 0) {
        await updateUnspscSelection(selectedUnspscCodes);
      }

      dispatch(fetchCurrentCompanyProfileCompletionSummary());
      setIsDirty(false);
      toast.success("Company data has been saved successfully.");
    } catch (error) {
      setIsDirty(true);
      toast.error(
        "An error occurred when saving the company data. Please try again, or contact support."
      );
      throw error;
    }

    if (list.find((x) => x.targetUrl === "/companyProfile" && !x.isRead)) {
      if (!Object.values(company).some((value) => value === undefined)) {
        const loader = async () => {
          const record = list?.find((x) => x.targetUrl === "/companyProfile");
          const update: UserWorkCentreModel = {} as UserWorkCentreModel;
          update.dateModified = new Date();
          update.severity = "success";
          update.isRead = true;
          update.accountId = record?.accountId;
          update.companyId = record?.companyId;
          update.dateCreated = record?.dateCreated;
          update.action = record?.action;
          update.description = record?.description as string;
          update.isDeleted = record?.isDeleted;
          update.roleId = record?.roleId;
          update.targetUrl = record?.targetUrl as string;
          update.userId = record?.userId;
          update.id = record?.id;

          await UserWorkCentreService.Update(update);
          dispatch(fetchUserWorkCentre(userAdId as string));
        };

        loader();
      }
    }
  };

  /*
   * Save details captured/changed for company.
   */
  const SaveCompanyDetails = (
    companyDetails: CompanyViewModel,
    selectedUnspscCodes?: Array<number>
  ) => {
    if (JSON.stringify(companyComparer) === JSON.stringify(companyDetails)) {
      setIsDirty(false);
      return;
    }

    if (account && inProgress === "none") {
      let token = "";

      instance
        .acquireTokenSilent({
          scopes: protectedResources.companyApi.scopes,
          account,
        })
        .then(async (response) => {
          token = response.accessToken;
          await SaveCompanyChanges(token, companyDetails, stagedValue);
          setCompanyComparer(JSON.parse(JSON.stringify(companyDetails)));

          if (selectedUnspscCodes && selectedUnspscCodes.length > 0) {
            await updateUnspscSelection(selectedUnspscCodes);
          }

          dispatch(fetchCurrentCompanyProfileCompletionSummary());
          setIsDirty(false);
          toast.success("Company data has been saved successfully.");
        })
        .catch((error) => {
          setIsDirty(true);
          toast.error(
            "An error occurred when saving the company data. Please try again, or contact support."
          );
          throw error;
        });
    }

    if (list.find((x) => x.targetUrl === "/companyProfile" && !x.isRead)) {
      if (!Object.values(company).some((value) => value === undefined)) {
        const loader = async () => {
          const record = list?.find((x) => x.targetUrl === "/companyProfile");
          const update: UserWorkCentreModel = {} as UserWorkCentreModel;
          update.dateModified = new Date();
          update.severity = "success";
          update.isRead = true;
          update.accountId = record?.accountId;
          update.companyId = record?.companyId;
          update.dateCreated = record?.dateCreated;
          update.action = record?.action;
          update.description = record?.description as string;
          update.isDeleted = record?.isDeleted;
          update.roleId = record?.roleId;
          update.targetUrl = record?.targetUrl as string;
          update.userId = record?.userId;
          update.id = record?.id;

          await UserWorkCentreService.Update(update);
          dispatch(fetchUserWorkCentre(userAdId as string));
        };

        loader();
      }
    }
  };

  /*
   * Save details captured/changed for a specific contact.
   */
  const SaveContactDetails = (contactDetails: ContactModel) => {
    const contactToSave = JSON.parse(JSON.stringify(contactDetails)); // Makes a deep copy. Perhaps a better way exists?

    if (account && inProgress === "none") {
      let token = "";

      instance
        .acquireTokenSilent({
          scopes: protectedResources.companyApi.scopes,
          account,
        })
        .then(async (response) => {
          token = response.accessToken;

          // If a new secondary contact is set up, these will be empty:
          if (contactToSave.companyId === undefined || contactToSave.companyId === 0) {
            contactToSave.companyId = company.companyId;

            // If the contactTypeId is 0, then save as a secondary, otherwise, save as General.
            if (contactToSave.contactTypeId === 0)
              contactToSave.contactTypeId = ContactType.Secondary;

            const result = await AddNewContact(token, contactToSave, stagedValue);

            contactToSave.id = parseInt(result.data, 10);
            dispatch(setCompanyContactState(contactToSave));
            dispatch(removeContactFromState(0)); // Probably a MUCH better way to handle this.
            toast.success("Contact data saved successfully.");
          } else {
            await SaveContactChanges(token, contactToSave, stagedValue);
            dispatch(setCompanyContactState(contactToSave));
            toast.success("Contact data saved successfully.");
          }

          dispatch(fetchCurrentCompanyProfileCompletionSummary());
          setIsDirty(false);
        })
        .catch((error) => {
          toast.error(
            "An error occurred when saving the contact data. Please try again, or contact support."
          );
          throw error;
        });
    }
  };

  /*
   * Save details captured/changed for a specific address.
   */
  const SaveAddressDetails = async (addressDetails: AddressModel) => {
    const addressToSave = addressDetails;

    try {
      await SaveAddressChanges(addressToSave, stagedValue);
      dispatch(setCompanyAddressState(addressToSave));

      dispatch(fetchCurrentCompanyProfileCompletionSummary());
      setIsDirty(false);
      toast.success("Address data saved successfully.");
    } catch (exception) {
      toast.error(
        "An error occurred when saving the address data. Please try again, or contact support."
      );
    }
  };

  /*
   * Save B-BBEE details for a specific company
   */
  const SaveBeeDetails = async (beeDetailsInput: BeeDetailsModel) => {
    const beeDetailsToSave: BeeDetailsModel = JSON.parse(JSON.stringify(beeDetailsInput));

    try {
      // If new BEE details are being captured:
      if (!beeDetailsToSave.companyId) {
        beeDetailsToSave.companyId = company.companyId;
        if (!beeDetailsToSave.hasBEECertificate) beeDetailsToSave.hasBEECertificate = false;
        const result = await AddNewBeeDetails(beeDetailsToSave, stagedValue);
        beeDetailsToSave.id = parseInt(result.data, 10);
        dispatch(setCompanyBeeDetailsState(beeDetailsToSave));
      } else {
        if (!beeDetailsToSave.hasBEECertificate) beeDetailsToSave.hasBEECertificate = false;
        await SaveBeeDetailChanges(beeDetailsToSave, stagedValue);
        dispatch(setCompanyBeeDetailsState(beeDetailsToSave));
      }

      dispatch(fetchCurrentCompanyProfileCompletionSummary());
      setIsDirty(false);
      toast.success("B-BBEE data has been saved successfully.");
    } catch (error) {
      toast.error(
        "An error occurred when saving the B-BBEE data. Please try again, or contact support."
      );
      throw error;
    }
  };

  /*
   * Save Bank Account details for a specific company
   */
  const SaveBankAccountDetails = async (bankAccountDetails: BankAccountDetailsModel) => {
    const bankAccountDetailsToSave: BankAccountDetailsModel = JSON.parse(
      JSON.stringify(bankAccountDetails)
    );
    try {
      // If new Bank account details are being captured:
      if (
        bankAccountDetailsToSave.companyId === undefined ||
        bankAccountDetailsToSave.companyId === 0
      ) {
        bankAccountDetailsToSave.companyId = company.companyId;
        const result = await AddNewBankAccount(bankAccountDetailsToSave, stagedValue);
        bankAccountDetailsToSave.id = result.data;
        dispatch(setCompanyBankAccountState(bankAccountDetailsToSave));
        dispatch(removeBankAccFromState(0));
        toast.success("New bank account saved successfully.");
      } else {
        await UpdateBankAccount(bankAccountDetailsToSave, stagedValue);
        dispatch(setCompanyBankAccountState(bankAccountDetailsToSave));
        toast.success("Bank account data saved successfully.");
      }

      dispatch(fetchCurrentCompanyProfileCompletionSummary());
      setIsDirty(false);
    } catch (error) {
      toast.error(
        "An error occurred when saving bank account data. Please try again, or contact support"
      );
      throw error;
    }
  };


  /*
   * Deletes a contact record
   */
  const DeleteContactRecord = async (contact: ContactModel, stagedVal: boolean) => {
    const contactToDelete: ContactModel = JSON.parse(JSON.stringify(contact));

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this contact?",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const result = await DeleteContact(contactToDelete, stagedVal);
        if (result === 200 || result === 204) {
          dispatch(removeContactFromState(contactToDelete.id));
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((response) => {
        if (response.isConfirmed) {
          toast.success("Contact deleted successfully.");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occurred when deleting the contact. Please try again, or contact support."
        );
        throw error;
      });
  };

  /*
   * Deletes a bank record
   */
  const DeleteBankAccountRecord = async (
    bankAccount: BankAccountDetailsModel,
    stagedVal: boolean
  ) => {
    const bankAccountToDelete: BankAccountDetailsModel = JSON.parse(JSON.stringify(bankAccount));

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this bank account record?",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const result = await DeleteBankAccount(bankAccountToDelete, stagedVal);
        if (result === 200 || result === 204) {
          dispatch(removeBankAccFromState(bankAccountToDelete.id));
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((response) => {
        if (response.isConfirmed) {
          toast.success("Bank account deleted successfully.");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occurred when deleting the bank account. Please try again, or contact support."
        );
        throw error;
      });
  };

  const handleDialogOpen = () => setDialogOpen(true);

  // eslint-disable-next-line no-unused-vars
  const handleDialogClose = (pageNumber?: number) => {
    setDialogOpen(false);

    if (pageNumber && pageNumber > 0) {
      setPageSection(redirectPageNo);
    }
  };

  const revertChanges = () => {
    dispatch(setCompanyViewModelState(companyComparer));
    setIsDirty(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageSection === pageNumber) return;

    if (isDirty) {
      setRedirectPageNo(pageNumber);
      handleDialogOpen();
      return;
    }

    setPageSection(pageNumber);
  };

  const companyLoadCheck = (companyToCheck: CompanyViewModel) => {
    // If the company is null, undefined or has no company ID, return true (yes, requires loading).
    if (
      companyToCheck === null ||
      companyToCheck === undefined ||
      (companyToCheck.companyId || 0) === 0
    )
      return true;

    if (companyToCheck.companyId !== baseCompanyInfo.companyId) {
      dispatch(resetCompanyState());
      return true;
    }

    if (companyToCheck.bankAccounts.length === 0 && companyToCheck.companyAddresses.length === 0)
      return true;

    return false;
  };

  const loadCompanyData = useCallback(async () => {
    if (
      baseCompanyInfo === undefined ||
      baseCompanyInfo === null ||
      baseCompanyInfo.companyId === undefined
    ) {
      const apiResponse = await GetBaseCompanyInfoViaUserId(userAdId as string);
      dispatch(setBaseCompanyInfoState(apiResponse));
    }

    if (company.companyId !== baseCompanyInfo.companyId) {
      dispatch(resetCompanyState());
    }

    dispatch(fetchCurrentCompanyProfileCompletionSummary());

    const companyIdValue = baseCompanyInfo.companyId.toString();
    const doesCompanyNeedToLoad = companyLoadCheck(company);

    if (doesCompanyNeedToLoad) {
      try {
        const result = await GetCompanyDataById(companyIdValue, stagedValue);
        dispatch(setCompanyViewModelState(result.data));
      } finally {
        setBackdropVisible(false);
      }
    }

    if (beeSectors === null || beeSectors === undefined || beeSectors.length === 0)
      dispatch(fetchBeeSectors());

    // These may need to move to the bank post component, but preloading these won't hurt.
    if (bankNames === null || bankNames === undefined || bankNames.length === 0)
      dispatch(fetchBankNames());

    if (
      bankAccountTypes === null ||
      bankAccountTypes === undefined ||
      bankAccountTypes.length === 0
    )
      dispatch(fetchBankAccountTypes());

    if (userCompanies === null || userCompanies === undefined || userCompanies.length === 0)
      dispatch(fetchUserCompanies());

    setCompanyComparer(JSON.parse(JSON.stringify(company)));
    PageLoadLogic();

    if (company.companyBeeDetails) {
      setBeeStatus(
        company?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          company?.companyBeeDetails?.certificateExpiryDate !== null &&
          company?.companyBeeDetails?.certificateExpiryDate?.length > 0 &&
          new Date(company.companyBeeDetails.certificateExpiryDate) > new Date()
      );
      setExpiryDateString(
        company?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          company?.companyBeeDetails?.certificateExpiryDate !== null &&
          company?.companyBeeDetails?.certificateExpiryDate?.length > 0
          ? new Date(company?.companyBeeDetails?.certificateExpiryDate)
            .toLocaleDateString()
            .replace(/\//g, "-")
          : ""
      );
    } else {
      setBeeStatus(false);
    }
  }, [company.companyId, baseCompanyInfo.companyId]);

  const loadUnspscSelections = useCallback(async () => {
    setBackdropVisible(true);
    if (companyUnspscSelection === null) {
      const result = await getUnspscOptions();
      dispatch(setUnspscOptionsState(result));

      const companySelection = await getCompanyUnspscSelection();
      setCompanyUnspscSelection(companySelection);
    }
    if (products === null) {
      const selectedItems = segments.map((seg) => {
        const segmentFamilies = seg.families.filter((x) => companyUnspscSelection.includes(x.id));
        if (segmentFamilies.length > 0) {
          return { ...seg, families: segmentFamilies } as UnspscSegment;
        }
        return null;
      });

      setProducts(selectedItems);
    }
    setBackdropVisible(false);
  }, [company.companyId, baseCompanyInfo.companyId]);

  useEffect(() => {
    setBackdropVisible(true);
    loadCompanyData();
    loadUnspscSelections();
    GetCurrentSubscription();

    if (pagesectionid) setPageSection(parseInt(pagesectionid, 10));

    if (
      userPrompts.find(
        (x) => x.popupPromptId === UserPopupActivities.Profile && x.status === ActivityStatus.Show
      )
    ) {
      setPopupOpen(true);
    }
  }, [
    company?.companyId,
    userPrompts.length,
    baseCompanyInfo.companyId,
    popupOpen,
    backdropVisible,
    pageSection,
  ]);

  usePrompt("Unsaved changes will be lost", isDirty);

  return (
    <>
      <LoadingBackdrop showBackdrop={company.companyId === undefined} />

      <div className="wrap-no-padding">
        <Button
          onClick={async () => {
            const doc = (
              <PDF
                selectedCompany={company}
                profile={profileImage?.url || ""}
                expiryDateString={expiryDateString}
                products={products as (UnspscSegment | null)[]}
              />
            );
            const asPdf = pdf([] as any); // {} is important, throws without an argument
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            saveAs(blob, "document.pdf");
          }}
        >
          Download PDF
        </Button>
        <div className={styles.companyProfileBox}>
          <div className={styles.companyProfileSideBar}>
            <CompanyProfileSidePanel
              currentSection={pageSection}
              setSection={handlePageChange}
              showForWorkcenter={false}
            />
          </div>
          <div className={styles.companyProfileContent}>
            <Box className={styles.pageLayout}>
              <Box className={styles.box} id="step-company">
                <div className={styles.companyViewBox}>
                  <div className={styles.companyViewRight}>
                    <div className={styles.imageBox}>
                      <div className="card-image">
                        <img
                          src={profileImage.url !== null ? profileImage.url : bufferProfile}
                          alt=""
                          className={styles.companyProfileImage}
                        />
                      </div>
                      <EditCompanyProfile staged={stagedValue} />
                    </div>
                  </div>
                  <div className={styles.companyViewLeft}>
                    <h2>{company.registeredName}</h2>
                    <h3>{company.tradingName}</h3>
                  </div>
                </div>
              </Box>

              {pageSection === 1 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                    agentPathId={0}
                  />

                  <CompanyAdminUserPost
                    headerText="Company Administrator"
                    adminEmail={company.adminUserEmail || "N/A"}
                    adminMobileNo={company.adminUserMobile || "N/A"}
                    adminName={company.adminUserName || "N/A"}
                    adminRole={company.adminUserRole || "N/A"}
                  />
                  <CompanyDetailsPost
                    headerText="General Company Details"
                    companyData={company}
                    workcentreAgentPath={false}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                  />

                  <CompanyTaxInformationPost
                    headerText="Tax Information"
                    companyData={company}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />

                  <CompanyExtendedDetails
                    headerText="Extended Details"
                    companyId={baseCompanyInfo.companyId}
                  />

                  <CompanyBusinessLocations
                    companyId={company.companyId}
                    headerText="Company Business Locations"
                    useStaged={false}
                  />
                </div>
              )}
              {pageSection === 2 && (
                <div className="navigation-content" id="step-contact">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyPrimaryContactPost
                    headerText="Primary Account Holder"
                    contactData={
                      company.companyContacts.find(
                        (c: ContactModel) => c.contactTypeId === ContactType.Primary
                      ) || ({} as ContactModel)
                    }
                    SetDirtyCallback={setIsDirty}
                    OnContactSave={SaveContactDetails}
                  />
                  {currentSubscription && (
                    <>
                      <CompanyGeneralContactPost
                        headerText="General Company Contact"
                        contactData={
                          company.companyContacts.find(
                            (c: ContactModel) => c.contactTypeId === ContactType.GeneralCompany
                          ) || ({} as ContactModel)
                        }
                        SetDirtyCallback={setIsDirty}
                        OnCopyPrimary={HandleSameAsPrimaryContactSwitch}
                        OnContactSave={SaveContactDetails}
                        staged={stagedValue}
                        workcentreAgentPath={false}
                      />
                      <CompanySecondaryContactPost
                        contacts={company.companyContacts.filter(
                          (c: ContactModel) => c.contactTypeId === ContactType.Secondary
                        )}
                        SetDirtyCallback={setIsDirty}
                        OnContactSave={SaveContactDetails}
                        OnContactDelete={DeleteContactRecord}
                      />
                    </>
                  )}
                </div>
              )}
              {pageSection === 3 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyAddressDetailsPost
                    addresses={company.companyAddresses}
                    SetDirtyCallback={setIsDirty}
                    OnSaveAddress={SaveAddressDetails}
                    OnCopyPhysical={HandleSameAsPhysicalSwitch}
                  />
                </div>
              )}
              {pageSection === 4 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyBEEDetails
                    beeData={company.companyBeeDetails}
                    SetDirtyCallback={setIsDirty}
                    OnBeeSave={SaveBeeDetails}
                    sectorList={beeSectors}
                    showLoadingBackdrop={setBackdropVisible}
                  />
                </div>
              )}
              {pageSection === 10 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                    agentPathId={0}
                  />

                  <Shareholders staged={stagedValue} companyId={company.companyId} />
                </div>
              )}
              {pageSection === 5 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyBankingDetailsPost
                    headerText="Company Bank Accounts (Optional)"
                    bankAccounts={company.bankAccounts}
                    OnBankSave={SaveBankAccountDetails}
                    SetDirtyCallback={setIsDirty}
                    OnBankDelete={DeleteBankAccountRecord}
                    VerificationData={onlyBank as CompanyVerificationModel}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                </div>
              )}
              {pageSection === 6 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyProductsAndServicesPost
                    headerText="Products & Services"
                    company={company as CompanyViewModel}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                </div>
              )}
              {pageSection === 7 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    agentPathId={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                  />
                  <CompanyDocumentsPost evaluatingCompanyId={Number(evaluatingCompanyId)} evaluatedCompanyId={Number(evaluatedCompanyId)} />
                </div>
              )}
              {pageSection === 8 && (
                <div className="navigation-content">
                  <PublicProfileSharing />
                </div>
              )}
            </Box>
          </div>
        </div>

        <div className="body">
          <div className="content-box step-ten-up">
            <div className="profile-navigation" />
          </div>
        </div>
        <UnsavedChangeDialog
          openDialog={dialogOpen}
          onCloseOrCancel={() => {
            handleDialogClose();
          }}
          onDiscardChanges={() => {
            revertChanges();
            redirectPageNo > 0 ? handleDialogClose(redirectPageNo) : handleDialogClose();
          }}
          onSaveChanges={() => {
            SaveAllCompanyDetails({ ...company });
            redirectPageNo > 0 ? handleDialogClose(redirectPageNo) : handleDialogClose();
          }}
        />
      </div>
      {popupOpen && (
        <Popup
          page="Profile"
          call={popupOpen}
          id={
            userPrompts.find((x) => x.popupPromptId === UserPopupActivities.Profile)?.id as number
          }
        />
      )}
    </>
  );
};

export default CompanyProfile;
