import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"; 
import SupplierUploadInvitationStatus, { supplierUploadInvitationStatusToString } from "../../../util/enums/SupplierUploadInvitationStatus";

interface Props {
  statusFilterValue: string;
  setStatusFilterValue: React.Dispatch<React.SetStateAction<string>>;
}

const UploadedConnectionsStatusFilter: React.FC<Props> = ({ statusFilterValue, setStatusFilterValue }) => (
  <FormControl size="small" sx={{ width: "100%" }}>
    <InputLabel>Filter By Status</InputLabel>
    <Select
      value={statusFilterValue}
      label="Status"
      onChange={(ev: SelectChangeEvent) => setStatusFilterValue(ev.target.value)}
    >
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      <MenuItem value={SupplierUploadInvitationStatus.UploadedButNotSent}>
        {supplierUploadInvitationStatusToString(SupplierUploadInvitationStatus.UploadedButNotSent)}
      </MenuItem>
      <MenuItem value={SupplierUploadInvitationStatus.InvalidEmail}>
        {supplierUploadInvitationStatusToString(SupplierUploadInvitationStatus.InvalidEmail)}
      </MenuItem>
      <MenuItem value={SupplierUploadInvitationStatus.EmailSent}>
        {supplierUploadInvitationStatusToString(SupplierUploadInvitationStatus.EmailSent)}
      </MenuItem>
      <MenuItem value={SupplierUploadInvitationStatus.Registered}>
        {supplierUploadInvitationStatusToString(SupplierUploadInvitationStatus.Registered)}
      </MenuItem>
    </Select>
  </FormControl>
);

export default UploadedConnectionsStatusFilter;
