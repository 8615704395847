import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "./Helpers/httpRequestHelpers";
import { UserWorkCentrePromptMessageModel } from "./Messaging/Models/UserWorkCentre";
import { JourneyCompletionModel } from "./Redux/Models/JourneyCompletionModel";
import { PopupQuestionsModel } from "./Redux/Models/PopupQuestionsModel";
import { UserPopupActivityModel } from "./Redux/Models/UserPopupActivityModel";
import { UserPromptActivityModel } from "./Redux/Models/UserPromptActivityModel";
import { UserPromptQuestionResponseModel } from "./Redux/Models/UserPromptQuestionResponseModel";
import { UserPromptResponseModel } from "./Redux/Models/UserPromptResponseModel";
import { UserPromptSectorResponseModel } from "./Redux/Models/UserPromptSectorResponseModel";

const apiScopes = protectedResources.userManagementWebApi.scopes;
const baseUrl = protectedResources.userManagementWebApi.endpoint;

export default {

  async UserPromptActivity(accountId: string, companyId: number) {

    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/${accountId}/${companyId}`;
    return (await makeGetRequestAsync<UserPromptActivityModel[]>(apiScopes, url)).data;
  },
  async UserPopupActivity(accountId: string) {

    const url = `${baseUrl}api/UserManagement/Popups/${accountId}`;
    return (await makeGetRequestAsync<UserPopupActivityModel[]>(apiScopes, url)).data;
  },
  async UserPromptActivityStatus(accountId: string) {

    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/${accountId}`;
    return (await makeGetRequestAsync<UserPromptActivityModel[]>(apiScopes, url)).status;
  },
  async UserJourneyCompletion(accountId: string) {

    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/JourneyCompletion/${accountId}`;
    return (await makeGetRequestAsync<JourneyCompletionModel>(apiScopes, url)).data;
  },
  async updateUserPromptActivity(userPromptActivity: UserWorkCentrePromptMessageModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptActivity`;
    return (await (makePatchRequestAsync(apiScopes, url, userPromptActivity))).status;
  },

  async updateUserPromptActivityPopup(userPromptActivity: UserPromptActivityModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/Popup`;
    return (await (makePatchRequestAsync(apiScopes, url, userPromptActivity))).status;
  },
  async addUserPromptActivity(userPromptActivity: UserPromptActivityModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptActivity`;
    return (await (makePostRequestAsync(apiScopes, url, userPromptActivity))).data;
  },

  async addUserPromptResponse(userPromptResponse: UserPromptResponseModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptResponse`;
    return (await (makePostRequestAsync(apiScopes, url, userPromptResponse))).status;
  },

  async addUserPromptSectorResponse(userPromptSectorResponse: UserPromptSectorResponseModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptResponse/Sector/Post`;
    return (await (makePostRequestAsync(apiScopes, url, userPromptSectorResponse))).status;
  },

  async addUserPromptQuestionResponse(userPromptQuestionResponse: UserPromptQuestionResponseModel) {
    const url = `${baseUrl}/api/UserManagement/UserPromptResponse/Question/Post`;
    return (await (makePostRequestAsync(apiScopes, url, userPromptQuestionResponse))).status;
  },
  async getAllPopupQuestions(accountId: string) {
    const url = `${baseUrl}/api/UserManagement/Popups/${accountId}`;
    return (await makeGetRequestAsync<PopupQuestionsModel[]>(apiScopes, url)).data;
  },
  async updateUserPopupActivity(popups: UserPopupActivityModel) {
    const url = `${baseUrl}/api/UserManagement/Popups`;
    return (await (makePatchRequestAsync(apiScopes, url, popups))).status;
  },
  async addUserPopupActivity(popups: UserPopupActivityModel) {
    const url = `${baseUrl}/api/UserManagement/Popups`;
    return (await (makePostRequestAsync(apiScopes, url, popups))).status;
  },
  async AddRating(rating: number) {
    const url = `${baseUrl}/api/UserManagement/Popups/${rating}`;
    return (await makePostRequestAsync(apiScopes, url)).data;
  },
  async AddManagedUserPrompt() {
    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/ManagedUserPrompt`;
    return (await makePostRequestAsync(apiScopes, url)).status;
  },
  async getUserPromptCount() {
    const url = `${baseUrl}/api/UserManagement/UserPromptActivity/UserPromptCount`;
    return (await makeGetRequestAsync(apiScopes, url)).data;
  },

}