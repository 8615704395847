import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompanyShareholdersArrayModel, CompanyShareholdersModel } from "../Models/CompanyShareholders";



const initialCompanyShareholderState: CompanyShareholdersArrayModel = {
  allCompanyShareholders: [],
}

const CompanyShareholderSlice = createSlice({
  name: "companyShareholders",
  initialState: initialCompanyShareholderState,
  reducers: {
    setAllCompanyExtendedDetail(state, action: PayloadAction<CompanyShareholdersModel[]>) {
      state.allCompanyShareholders = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export default CompanyShareholderSlice;


export const getCompanyShareholders = (state: RootState) => state.companyShareholders.allCompanyShareholders;