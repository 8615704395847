import React, { useCallback, useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Card,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Divider,
  Button,
  Typography,
} from "@mui/material";

import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/FilterList";

import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import BasePage from "./BasePage";
import { useAppSelector } from "../../app/hooks";
import { PerformSearch } from "../../http/NetworkAndConnect/Netconn";
import { ConnectionSearchModel } from "../../http/NetworkAndConnect/Models/ConnectionSearchModel";

import FilterDropdown from "./SearchComponents/FilterDropdown";
import { SearchModel } from "../../http/NetworkAndConnect/Models/SearchModel";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import SearchTable from "./SearchComponents/SearchTable";
import NetconnSearchType from "../../util/enums/NetconnSearchType";
import OrderByType from "../../util/enums/OrderByType";
import { OrderByModel } from "../../http/NetworkAndConnect/Models/OrderByModel";
import useProvinces from "../../util/hooks/queries/useProvinces";
import useBeeLevels from "../../util/hooks/queries/useBeeLevels";
import { CompanyNetworkConnections } from "../../http/NetworkAndConnect/Models/CompanyNetworkConnections";
import useCompanyNetwork from "../../util/hooks/queries/useCompanyNetwork";
import useBaseCompanyInfo from "../../util/hooks/queries/useBaseCompanyInfo";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultSearchOrderObject: OrderByModel = {
  fieldName: "RegisteredName",
  orderByDirection: OrderByType.Ascending,
};


const VirtualMarketPlace = () => {
  const navigate = useNavigate();

  const baseCompanyInfo = useAppSelector((x) => x.baseCompanyInfo);
  const { data: provincesList = [] } = useProvinces();
  const { data: beeLevels = [] } = useBeeLevels();

  const blackOwnershipRanges = [
    { id: 1, value: "0% - 29%" },
    { id: 2, value: "30% - 50%" },
    { id: 3, value: "51% - 99%" },
    { id: 4, value: "100%" },
  ];

  const blackWomenOwnershipRanges = [
    { id: 1, value: "0% - 29%" },
    { id: 2, value: "30% - 50%" },
    { id: 3, value: "51% - 99%" },
    { id: 4, value: "100%" },
  ];

  // State for the search text the user enters.
  const [searchText, setSearchText] = useState<string>("");

  const [selectedSortOrder, setSelectedSortOrder] =
    useState<OrderByModel>(defaultSearchOrderObject);

  // Model for the search criteria.
  const [connectionSearchModel, setConnectionSearchModel] = useState<ConnectionSearchModel>({
    beeLevelIds: [] as Array<number>,
    provinceIds: [] as Array<number>,
    unspscSegmentIds: [] as Array<number>,
    unspscFamilyIds: [] as Array<number>,
    bOPercRanges: [] as Array<string>,
    bWOPercRanges: [] as Array<string>,
    searchOrderBys: [selectedSortOrder] as Array<OrderByModel>,
  } as ConnectionSearchModel);

  // State for the results:
  const [searchModelList, setSearchModelList] = useState<SearchModel[]>([] as SearchModel[]);

  // These two are "special" as in potato.
  const [selectedBlackOwnershipRanges, setSelectedBlackOwnershipRanges] = useState<Array<number>>(
    [] as Array<number>
  );
  const [selectedBlackWomenOwnershipRanges, setSelectedBlackWomenOwnershipRanges] = useState<
    Array<number>
  >([] as Array<number>);

  // Pagination/Table state:
  const [totalRecords, setTotalRecords] = useState(0);
  const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRecords, setSelectedRecords] = useState<Array<number>>([] as Array<number>);

  const [showAdvancedSeach, setShowAdvancedSeach] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const numberOfPages = Math.round(totalRecords / numberOfRecordsToShow);

  const [networkData, setNetworkData] = useState<CompanyNetworkConnections>({} as CompanyNetworkConnections);
  const companyData = useBaseCompanyInfo();
  const { data : companyNetwork} = useCompanyNetwork(companyData.data?.companyId ?? 0);

  const selectSpecificRecord = (recordCompanyId: number | null) => {
    if (recordCompanyId === null) return;

    if (!selectedRecords.includes(recordCompanyId)) {
      setSelectedRecords([...selectedRecords, recordCompanyId]);
      return;
    }

    const filteredValues = selectedRecords.filter((record) => record !== recordCompanyId);
    setSelectedRecords(filteredValues);
  };

  const selectAllRecords = (selectedCompanyIds: Array<number>) => {
    if (selectedCompanyIds.length < searchModelList.length) {
      const allCompanies = searchModelList.map((model) => model.companyId);
      setSelectedRecords(allCompanies as Array<number>);
      return;
    }

    setSelectedRecords([]);
  };

  const handleSelectRecordsChange = (newRowCount: number) => {
    setNumberOfRecordsToShow(newRowCount);
  };

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleNavigationToViewProfile = (companyId: number) => {
    navigate(`/viewprofile/${companyId}`);
  };

  // I leave this here for the advanced filters.
  // eslint-disable-next-line no-unused-vars

  const handleSelectChange = (event: SelectChangeEvent<number[]>, propName: string) => {
    const localCopy = JSON.parse(JSON.stringify(connectionSearchModel));
    const newVal: ConnectionSearchModel = { ...localCopy, [propName]: event.target.value };
    setConnectionSearchModel(newVal);
  };

  const handleBlackOwnershipChange = (event: SelectChangeEvent<number[]>) => {
    const newSelection = event.target.value as Array<number>;
    setSelectedBlackOwnershipRanges([...newSelection]);
  };

  const handleBlackWOwnershipChange = (event: SelectChangeEvent<number[]>) => {
    const newSelection = event.target.value as Array<number>;
    setSelectedBlackWomenOwnershipRanges([...newSelection]);
  };

  const executeSearch = async (searchQueryText = "") => {
    setIsLoading(true);
    const localSearchObject = JSON.parse(JSON.stringify(connectionSearchModel));

    localSearchObject.companyId = baseCompanyInfo.companyId;
    localSearchObject.searchQuery = searchQueryText;
    localSearchObject.searchPageNumber = currentPage - 1;
    localSearchObject.searchRowNumber = numberOfRecordsToShow;
    localSearchObject.searchOrderBys = [selectedSortOrder];

    if (selectedBlackOwnershipRanges.length > 0)
      localSearchObject.bOPercRanges = blackOwnershipRanges
        .filter((bor) => selectedBlackOwnershipRanges.includes(bor.id))
        .map((result) => result.value);

    if (selectedBlackWomenOwnershipRanges.length > 0)
      localSearchObject.bWOPercRanges = blackWomenOwnershipRanges
        .filter((bor) => selectedBlackWomenOwnershipRanges.includes(bor.id))
        .map((result) => result.value);
    try {
      const results = await PerformSearch(localSearchObject);
      setSearchModelList(results);
      setTotalRecords(results.length > 0 ? results[0].totalRows : 0);
      setNumberOfRecordsToShow(localSearchObject.searchRowNumber as number);
      setIsLoading(false);
    } catch (error) {
      Swal.fire("Error", "Something went wrong fetching data. Please try again.", "error");
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    await executeSearch(searchText);

    if (!showSearch) setShowSearch(true);
  };

  const clearSearchText = async () => {
    setSearchText("");
  };

  const handleClear = () => {
    setShowAdvancedSeach(false);
    clearSearchText();

    // Clear basic filters:
    setConnectionSearchModel({
      ...connectionSearchModel,
      provinceIds: [],
      unspscSegmentIds: [],
      unspscFamilyIds: [],
      beeLevelIds: [],
    });
    setSelectedBlackOwnershipRanges([] as Array<number>);
    setSelectedBlackWomenOwnershipRanges([] as Array<number>);
    setShowSearch(false);
  };

  const displayMinimumSearchAlert = () => {
    Swal.fire({
      title: "Minimum Search Requirement",
      text: "Please ensure you have entered 3 or more characters",
      icon: "warning",
    });
  };

  const handleCompanyNameSearchTextChange = useCallback((searchTextValue: string) => {
    setSearchText(searchTextValue);
  }, []);

  const handleKeyDownEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchText.length < 3) {
        displayMinimumSearchAlert();
        return;
      }

      await handleSearch();
    }
  };

  const handleOrderChange = (newSortOrder: string) => {
    const newSortDirection: OrderByType =
      newSortOrder.toLowerCase() === "a-z" ? OrderByType.Ascending : OrderByType.Descending;

    const newSortOrderObject = { ...selectedSortOrder, orderByDirection: newSortDirection };

    setSelectedSortOrder(newSortOrderObject);
    setConnectionSearchModel({ ...connectionSearchModel, searchOrderBys: [newSortOrderObject] });
  };

  const loadCompanyNetworkConnection = () => {
    if(companyNetwork)
      setNetworkData(companyNetwork);
  }

  useEffect(() => {
    loadCompanyNetworkConnection()
  }, [searchModelList.length]);

  useEffect(() => {
    executeSearch(searchText);
  }, [currentPage, numberOfRecordsToShow, selectedSortOrder.orderByDirection]);

  return (
    <>
      <LoadingBackdrop showBackdrop={isLoading} />
      <BasePage>
        <div className="pageBox">
          <Grid container>
            <Grid xs={12}>
              <Typography variant="h3">FIND COMPANIES</Typography>
            </Grid>
            <Grid xs={12}>
              <Card className="cardBanner">
                <h3>Explore the Virtual Marketplace to find and connect with other companies.</h3>
              </Card>
              <Card className="cardSearch">
                <TextField
                  value={searchText}
                  onChange={(e) => handleCompanyNameSearchTextChange(e.target.value)}
                  onKeyDown={handleKeyDownEvent}
                  placeholder="Search By Company, Product Or Service Description"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="icon-primaryActive" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              </Card>
              <Card className="cardAdvanced">
                <Grid container sx={{ padding: "20px" }}>
                  <Grid item xs={12}>
                    <FilterIcon className="icon-primaryActive" style={{ float: "left" }} />
                    <h3 style={{ float: "left", marginBottom: 10 }}>Search By</h3>
                    <br />
                  </Grid>
                </Grid>

                <Grid container key="FilterContainer">
                  <Grid item container xs={12} sx={{ paddingLeft: "20px" }} key="BasicFiltersRow1">
                    <Grid xs={6}>
                      <FilterDropdown
                        label="Provinces"
                        lookupList={provincesList.map((province) => ({
                          id: province.id,
                          value: province.name,
                        }))}
                        selectedOptionsList={
                          connectionSearchModel.provinceIds || ([] as Array<number>)
                        }
                        menuProps={MenuProps}
                        changeHandleCallback={(ev) => handleSelectChange(ev, "provinceIds")}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FilterDropdown
                        label="B-BBEE Level"
                        lookupList={beeLevels}
                        selectedOptionsList={
                          connectionSearchModel.beeLevelIds || ([] as Array<number>)
                        }
                        menuProps={MenuProps}
                        changeHandleCallback={(ev) => handleSelectChange(ev, "beeLevelIds")}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ marginTop: "20px", marginBottom: "40px", paddingLeft: "20px" }}
                    key="BasicFiltersRow2"
                  >
                    <Grid xs={6}>
                      <FilterDropdown
                        label="Black Ownership"
                        lookupList={blackOwnershipRanges}
                        selectedOptionsList={selectedBlackOwnershipRanges}
                        menuProps={MenuProps}
                        changeHandleCallback={handleBlackOwnershipChange}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FilterDropdown
                        label="Black Women Ownership"
                        lookupList={blackWomenOwnershipRanges}
                        selectedOptionsList={selectedBlackWomenOwnershipRanges}
                        menuProps={MenuProps}
                        changeHandleCallback={handleBlackWOwnershipChange}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container className="hr-container" xs={12} spacing={2}>
                    <Grid xs={12}>
                      <div style={{ width: "100%" }}>
                        <div style={{ width: "78.5%", float: "left" }}>
                          <button
                            type="button"
                            className="btn-custom-table-actions-adv"
                            onClick={handleSearch}
                          >
                            SEARCH
                          </button>

                          <button
                            type="button"
                            onClick={handleClear}
                            className="btn-custom-table-actions-adv-clear"
                          >
                            CLEAR
                          </button>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12}>
                      {showAdvancedSeach && (
                        <Button variant="contained" sx={{ marginLeft: "40px" }}>
                          EXPORT SUPPLIER LIST
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {showSearch && (
              <Grid container sx={{ marginTop: 3 }} spacing={2}>
                <SearchTable
                  tableSearchType={NetconnSearchType.None}
                  menuProps={undefined}
                  totalRecordCount={totalRecords}
                  dataSet={searchModelList}
                  currentPage={currentPage}
                  numberOfRows={numberOfRecordsToShow}
                  numberOfPages={numberOfPages}
                  selectedRows={selectedRecords}
                  changeMainTableSort={handleOrderChange}
                  changeCurrentPage={handlePageChange}
                  changePageRowNumber={handleSelectRecordsChange}
                  selectSpecificRow={selectSpecificRecord}
                  selectAllRows={selectAllRecords}
                  navigateToProfile={handleNavigationToViewProfile}
                  networkConnections={networkData}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </BasePage>
    </>
  );
};

export default VirtualMarketPlace;
