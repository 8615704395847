import { useMsal, useAccount } from "@azure/msal-react";

const useCurrentUserId = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  return userAccount;
};

export default useCurrentUserId;