import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { CompanyExtendedDetailsModel } from "../Redux/Models/CompanyExtendedDetailsModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default {
  async getCompanyExtendedDetailsModel(companyId: number, staged: boolean) {

    const url = `${baseUrl}/api/Company/GetCompanyExtendedDetails/${companyId}/${staged}`;
    return (await makeGetRequestAsync<CompanyExtendedDetailsModel>(apiScopes, url)).data;
  },
  async update(company: CompanyExtendedDetailsModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/UpdateCompanyExtendedDetails/${staged}`;
    return (await (makePatchRequestAsync(apiScopes, url, company))).status;
  },
  async create(company: CompanyExtendedDetailsModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/AddCompanyExtendedDetails/${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, company))).status;
  },
}