import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import SettingsIcon from "@mui/icons-material/Settings";
import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";
import { ConnectionRequestModel } from "../../../http/Redux/Models/ConnectionRequestModel";
import ConnectionRequestType from "../../../util/enums/ConnectionRequestType";
import ConnectionStatus from "../../../util/enums/ConnectionStatus";
import ConnectionType from "../../../util/enums/ConnectionType";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import { useAppSelector } from "../../../app/hooks";
import { selectBaseCompanyInfo } from "../../../http/Redux/Store/baseCompanyInfoSlice";
import "../SearchResult/SweetAlert.css";
import ConnectionRequestService from "../../../http/ConnectionRequestService";
import { ViewConnectionRequestModel } from "../../../http/Redux/Models/ViewConnectionRequestModel";
import ConnectRuleDialog from "../ConnectRuleDialog/ConnectRuleDialog";
import { UserProfileModel } from "../../../http/Redux/Models/UserProfileModel";
import { BaseCompanyInfo } from "../../../http/Company/Models/BaseCompanyInfo";
import SendNewMessage from "../../Chat/SendNewMessage";

interface ListRequestActionProps {
  data: ViewConnectionRequestModel,
  // eslint-disable-next-line react/require-default-props
  isMyRequests?: boolean,
  requestType: number, // The type of request (client, supplier, or follow)
  connectionStatus: number, // If the connection is pending or not, etc.
  update: () => void,
}

// eslint-disable-next-line no-unused-vars
const ListRequestActions = ({ data, isMyRequests = false, requestType, connectionStatus, update }: ListRequestActionProps) => {
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);

  const [swalPropsChange, setSwalPropsChange] = useState({});

  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [isDeclined, setIsDeclined] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [invite, setInvite] = useState(true);

  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const userProfile: UserProfileModel = useAppSelector(getUserProfile);
  const company: BaseCompanyInfo = useAppSelector(selectBaseCompanyInfo);

  // Rule Dialog State Nonsense:
  const [showRuleDialog, setShowRuleDialog] = useState<boolean>(false);
  const [evaluatingCompId, setEvaluatingCompId] = useState<number>(0);
  const [evaluatedCompId, setEvaluatedCompId] = useState<number>(0);

  const [showSendMessageModel, setShowSendMessageModel] = useState<boolean>(false);

  const handleSendMessageClick = () => {
    setShowSendMessageModel(!showSendMessageModel);
  }
  const handleCloseMessageModel = () => {
    setShowSendMessageModel(false);
  }

  const handleToastMessageSent = () => {
    setSwalPropsChange({
      show: true,
      title: "Message Sent",
      icon: "success",
      timer: Number(2000),
      showCancelButton: false,
      showConfirmButton: false,

    });
  }

  const handleRuleEvaluationClick = () => {
    setEvaluatingCompId(data.requestedByCompanyId); // This ID will be the company you are approaching as a supplier.
    setEvaluatedCompId(company.companyId);          // This would be your company's ID.
    setShowRuleDialog(true);
  };

  const handleUnfollowClick = async () => {
    const connectionRequest = {
      id: data.id,
      requestedByCompanyId: data.requestedByCompanyId,
      companyId: data.companyId,
      invitedContactEmail: data.invitedContactEmail,
      invitedCompanyName: data.invitedCompanyName,
      invitedContactName: data.invitedContactName,
      requestedByUserId: data.requestedByUserId,
      requestType: ConnectionRequestType.Follow,
      connectionType: ConnectionType.Follow,
      connectionStatus: ConnectionStatus.Unfollow,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      modifiedBy: userProfile.accountId || null,
      dateModified: new Date(),
    } as ConnectionRequestModel;

    setSwalPropsChange({
      show: true,
      icon: "info",
      title: "Loading",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: true,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
        Swal.showLoading(confirmButton);
      },
    });

    const response = await ConnectionRequestService.updateConnectionRequest(connectionRequest);
    update();
    Swal.close();
    if (response === 200 || response === 204) {
      setAnchorEl(null);
      setSwalPropsChange({
        show: true,
        title: "Unfollowed",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      setSwalPropsChange({
        show: true,
        title: "An error occurred",
        text: "Please try again later, or contact support.",
        icon: "error",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleCancelBuyRequestClick = async () => {
    const connectionRequest = {
      id: data.id,
      requestedByCompanyId: data.requestedByCompanyId,
      companyId: data.companyId,
      invitedContactEmail: data.invitedContactEmail,
      invitedCompanyName: data.invitedCompanyName,
      invitedContactName: data.invitedContactName,
      requestedByUserId: data.requestedByUserId,
      requestType: ConnectionRequestType.Client,
      connectionType: ConnectionType.Client,
      connectionStatus: ConnectionStatus.RequestCanceled,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      modifiedBy: userProfile.accountId || null,
      dateModified: new Date(),
    } as ConnectionRequestModel;

    setSwalPropsChange({
      show: true,
      icon: "info",
      title: "Loading",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
        Swal.showLoading(confirmButton);
      },
    });

    const response = await ConnectionRequestService.updateConnectionRequest(connectionRequest);
    update();
    Swal.close();

    if (response === 200 || response === 204) {
      setAnchorEl(null);
      setSwalPropsChange({
        show: true,
        title: "Buyer Request Canceled",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      setSwalPropsChange({
        show: true,
        title: "An error occurred",
        text: "Please try again later, or contact support.",
        icon: "error",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleDeclineBuyRequestClick = async () => {
    const connectionRequest = {
      id: data.id,
      requestedByCompanyId: data.requestedByCompanyId,
      companyId: data.companyId,
      invitedContactEmail: data.invitedContactEmail,
      invitedCompanyName: data.invitedCompanyName,
      invitedContactName: data.invitedContactName,
      requestedByUserId: data.requestedByUserId,
      requestType: ConnectionRequestType.Client,
      connectionType: ConnectionType.Client,
      connectionStatus: ConnectionStatus.Declined,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      modifiedBy: userProfile.accountId || null,
      dateModified: new Date(),
    } as ConnectionRequestModel;

    setSwalPropsChange({
      show: true,
      title: "Loading",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: true,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
        Swal.showLoading(confirmButton);
      },
    });

    const response = await ConnectionRequestService.updateConnectionRequest(connectionRequest);
    Swal.close();

    if (response === 200 || response === 204) {
      setAnchorEl(null);
      setSwalPropsChange({
        show: true,
        title: "Buyer Request Declined",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      setSwalPropsChange({
        show: true,
        title: "An error occurred",
        text: "Please try again later, or contact support.",
        icon: "error",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleCancelSupplyRequestClick = async () => {
    const connectionRequest = {
      id: data.id,
      requestedByCompanyId: data.requestedByCompanyId,
      companyId: data.companyId,
      invitedContactEmail: data.invitedContactEmail,
      invitedCompanyName: data.invitedCompanyName,
      invitedContactName: data.invitedContactName,
      requestedByUserId: data.requestedByUserId,
      requestType: ConnectionRequestType.Supplier,
      connectionType: ConnectionType.Supplier,
      connectionStatus: ConnectionStatus.RequestCanceled,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      modifiedBy: userProfile.accountId || null,
      dateModified: new Date,
    } as ConnectionRequestModel;

    setSwalPropsChange({
      show: true,
      title: "Loading",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: true,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
        Swal.showLoading(confirmButton);
      },
    });

    const response = await ConnectionRequestService.updateConnectionRequest(connectionRequest);
    Swal.close();

    if (response === 200 || response === 204) {
      setAnchorEl(null);
      setSwalPropsChange({
        show: true,
        title: "Supplier Request Cancelled",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      setSwalPropsChange({
        show: true,
        title: "An error occurred",
        text: "Please try again later, or contact support.",
        icon: "error",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleDeclineSupplyRequestClick = async () => {

    const connectionRequest = {
      id: data.id,
      requestedByCompanyId: data.requestedByCompanyId,
      companyId: data.companyId,
      invitedContactEmail: data.invitedContactEmail,
      invitedCompanyName: data.invitedCompanyName,
      invitedContactName: data.invitedContactName,
      requestedByUserId: data.requestedByUserId,
      requestType: ConnectionRequestType.Supplier,
      connectionType: ConnectionType.Supplier,
      connectionStatus: ConnectionStatus.Declined,
      createdBy: data.createdBy,
      dateCreated: data.dateCreated,
      modifiedBy: userProfile.accountId || null,
      dateModified: new Date,
    } as ConnectionRequestModel;

    setSwalPropsChange({
      show: true,
      title: "Loading",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: true,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
        Swal.showLoading(confirmButton);
      },
    });

    const response = await ConnectionRequestService.updateConnectionRequest(connectionRequest);
    Swal.close();

    if (response === 200 || response === 204) {
      setAnchorEl(null);
      setSwalPropsChange({
        show: true,
        title: "Supplier Request Declined",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      setSwalPropsChange({
        show: true,
        title: "An error occurred",
        text: "Please try again later, or contact support.",
        icon: "error",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleClickConnect = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseConnect = () => {
    setAnchorEl(null);
  };

  const handleConnectionRuleDialogClose = () => {
    setShowRuleDialog(false);
  };

  const followMenuOptions = () => (
    <MenuItem onClick={handleUnfollowClick}>
      Unfollow
    </MenuItem>
  );

  const clientMenuOptions = () => {
    if (isMyRequests) {
      return [
        <MenuItem onClick={handleSendMessageClick}>Start a conversation</MenuItem>,
        <MenuItem onClick={handleCancelBuyRequestClick}>Cancel Buyer Request</MenuItem>,               
      ];
    }

    return [
      <MenuItem onClick={handleSendMessageClick}>Start a conversation</MenuItem>,
      <MenuItem onClick={handleRuleEvaluationClick}>Accept</MenuItem>,      
      <MenuItem onClick={handleDeclineBuyRequestClick}>Decline Buyer Request</MenuItem>,
    ];
  };

  // eslint-disable-next-line consistent-return
  const supplierMenuOptions = () => {
    if (isMyRequests) {      
      return [
        <MenuItem onClick={handleSendMessageClick}>Start a conversation</MenuItem>,
        <MenuItem onClick={handleCancelSupplyRequestClick}>Cancel Supplier Request</MenuItem>,               
      ];
    }

    return <MenuItem onClick={handleDeclineSupplyRequestClick}>Decline Supplier Request</MenuItem>;
  };

  // eslint-disable-next-line consistent-return
  const renderMenuItems = (connectionType: number) => {
    if (connectionType === ConnectionRequestType.Follow)
      return followMenuOptions();

    if (connectionType === ConnectionRequestType.Client)
      return clientMenuOptions();

    if (connectionType === ConnectionRequestType.Supplier)
      return supplierMenuOptions();
  }

  useEffect(() => {
    setIsAccepted(data.connectionStatus === ConnectionStatus.Accepted);
    setIsCancelled(data.connectionStatus === ConnectionStatus.RequestCanceled);
    setIsDeclined(data.connectionStatus === ConnectionStatus.Declined);

  }, [requestType, data.companyId, data.connectionStatus]);

  return (
    <div>
      {(data.connectionStatus === ConnectionStatus.Pending || data.connectionStatus === ConnectionStatus.OnHold) && (
        <>
          <Tooltip title="Connection Actions">
            <IconButton
              disabled={isAccepted || isDeclined || isCancelled}
              className="icon-use"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                handleClickConnect(event);
              }}>
              <SettingsIcon className={isAccepted || isDeclined || isCancelled ? "cl-gray" : "cl-blue"} />
            </IconButton>
          </Tooltip>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={opens}
            onClose={() => {
              handleCloseConnect();
            }}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ marginTop: 5 }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {renderMenuItems(requestType)}
          </Menu>
        </>
      )}
      <SendNewMessage
        open={showSendMessageModel}
        handleClose={handleCloseMessageModel}
        handleRefresh={handleToastMessageSent}
        companyId={data.companyId as number}
        companyName={data.companyName as string} />
      <SweetAlert2 {...swalPropsChange} />
      <ConnectRuleDialog
        showDialog={showRuleDialog}
        handleCloseCallback={handleConnectionRuleDialogClose}
        evaluatingCompanyId={evaluatingCompId}
        evaluatedCompanyId={evaluatedCompId}
        connectionTypeId={ConnectionType.Client}
        connectionRequestId={data.id}
        isBuyerRequest // This can be removed when connection types are set up properly
      />
    </div>
  );
};

export default ListRequestActions;