import { useMutation, useQueryClient } from "@tanstack/react-query"
import queryKeys from "../queries/queryKeys";
import { AddNewBeeDetails, DeleteBeeDetails, SaveBeeDetailChanges } from "../../../http/Company/beeDetailsApi";
import { BeeDetailsModel } from "../../../http/Company/Models/BeeDetailsModel";
import { ComplianceMutatorProps } from "./Interfaces/ComplianceMutatorProps";



const useCompanyBeeDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({mutationMode, mutationDetails, staged = false} : ComplianceMutatorProps) => {
      const beeObject = mutationDetails as BeeDetailsModel;

      if (mutationMode === "add") {
        await AddNewBeeDetails(beeObject, staged);
        return;
      }

      if (mutationMode === "update") {
        await SaveBeeDetailChanges(beeObject, staged);
        return;
      }

      await DeleteBeeDetails(beeObject, staged);
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyBeeDetails] });

      // Snapshot the previous value
      const currentBeeDetails = queryClient.getQueryData([queryKeys.companyBeeDetails]);

      // Return a context object with the snapshotted value
      return { currentBeeDetails }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyBeeDetails], context?.currentBeeDetails);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyBeeDetails] });
    },
  })
}

export default useCompanyBeeDetailsMutation;