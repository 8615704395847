import {
  useQuery,
} from "@tanstack/react-query"
import { useAccount, useMsal } from "@azure/msal-react";
import queryKeys from "./queryKeys";
import { GetBaseCompanyInfoViaCompanyId } from "../../../http/Company/company";

const useBaseCompanyInfo = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const query = useQuery({
    queryKey: [queryKeys.baseCompanyInfo, account?.idTokenClaims?.extension_companyid],
    queryFn: () => GetBaseCompanyInfoViaCompanyId(
      account?.idTokenClaims?.extension_companyid as string,
      false
    ),
    staleTime: 600000,
  })

  return query;
}

export default useBaseCompanyInfo;