// eslint-disable-next-line no-shadow
enum OverallComplianceStatus { // eslint-disable-line no-unused-vars
  None = 0, // eslint-disable-line no-unused-vars
  PendingChecks = 1, // eslint-disable-line no-unused-vars
  Passed = 2, // eslint-disable-line no-unused-vars
  Failed = 3, // eslint-disable-line no-unused-vars
}


export default OverallComplianceStatus;

export const overallComplianceStatusToString = (value: OverallComplianceStatus) => {
  switch (value) {
    case OverallComplianceStatus.None:
      return "None";
    case OverallComplianceStatus.PendingChecks:
      return "Pending Checks";
    case OverallComplianceStatus.Passed:
      return "Passed";
    case OverallComplianceStatus.Failed:
      return "Failed";
    default:
      return "None";
  }
};