/* eslint-disable no-console */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ArtefactToString, ChangedArtefacts, OnBoardingStepsProps, StepModelAttribute } from "./StepsModel";
import Overview from "./Overview";
import GetSetState from "./SetsModel";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import Documents from "./Documents/Documents";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCompany } from "../../http/Redux/Store/companySlice";

import { fetchOnboardingChecks } from "../../http/Redux/OnBoardingActions";
import OnBoardingServices from "../../http/OnBoardingServices";
import { DocumentDtoModel } from "../../http/DocumentManagement/Models/DocumentDtoModel";
import { getCompanyDocumentsWithTypesOnBoardingAsync } from "../../http/DocumentManagement/DocumentManagement";


const OnBoardingSteps = ({ stepDescription, stepLength, stepIndex, pullSection, handleBack, handleNext, set, steps, heading, companyId, handleNavigation }: OnBoardingStepsProps) => {
  const [setup, getSetup] = useState(false);
  const [list, setList] = useState<ChangedArtefacts[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState<Array<number>>([]);
  const [files, setFiles] = useState<Array<DocumentDtoModel>>([] as Array<DocumentDtoModel>);
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);

  const handleChange = (values: ChangedArtefacts) => {
    const newList = [...list, values];
    setList(newList);
  }

  const handleValidationContinue = () => {
    const loader = async () => {
      setIsLoading(true);

      if (pullSection === StepModelAttribute.Company) {

        const Company: CompanyViewModel = {} as CompanyViewModel;

        set.forEach(element => {
          const artefactId = element.artefactId as number;
          if (!list.find(x => x.artefactId === artefactId)) {
            (Company as any)[ArtefactToString(artefactId)] = (company as any)[ArtefactToString(artefactId)];
          }
        });

        list.forEach(element => {
          (Company as any)[ArtefactToString(element.artefactId as number)] = element.value;
        });

        const result = await OnBoardingServices.validate(Company, heading);
        dispatch(fetchOnboardingChecks(Company, heading));

        if (result.length !== 0) {
          handleNext(2);
        }
        else {

          handleNext(4);
        }
        setList([]);
        setIsLoading(false);
      }
    }
    loader();
  }

  const resetDocuments = async () => {
    setIsLoading(true);
    const companyFiles = (await getCompanyDocumentsWithTypesOnBoardingAsync(company.companyId, types).finally(() => {
      setIsLoading(false);
    }));
    setIsLoading(false);
    setFiles(companyFiles);
  }


  useEffect(() => {
    if (set) {
      getSetup(true);

      set.filter(x => x.documentType > 0).forEach(element => {
        if (!types.includes(element.documentType)) {
          setTypes(prevTypes => [...prevTypes, element.documentType]);
        }
      });
      if (files.length === 0 && types.length > 0) {
        setIsLoading(true);
        const loader = async () => {
          const companyFiles = (await getCompanyDocumentsWithTypesOnBoardingAsync(company.companyId, types).finally(() => {
            setIsLoading(false);
          }));
          setFiles(companyFiles);
        }
        loader();
      }
    }

  }, [set, list.length, pullSection, company.companyId, types.length, companyId, files.length])


  return (
    <Box sx={{ mb: 2, width: "100%", padding: "10px", paddingRight: "30px" }}>
      <LoadingBackdrop showBackdrop={isLoading} />
      <h3>{heading}</h3>
      <Typography>{stepDescription}</Typography>
      <div className="hr-line" />


      <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
        {setup &&
          <Grid container>
            {set?.map((sets) => (

              <Grid xs={4} sx={{ marginBottom: 2 }}>
                <GetSetState Set={sets} handleChange={handleChange} handleNavigation={handleNavigation} steps={steps} company={company} files={files} />
              </Grid>

            ))}
          </Grid>
        }
        {stepIndex === 0 &&
          <Overview steps={steps} />
        }
        {stepIndex === stepLength - 1 &&
          <Documents companyId={company.companyId} files={files} resetDocuments={resetDocuments} />
        }
      </Box>
      <div className="hr-line" />
      {stepIndex !== 0 && stepIndex !== stepLength - 1 &&
        <div style={{ float: "right" }}>
          <Button
            variant="contained"
            onClick={() => {
              handleValidationContinue();
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            {stepIndex === stepLength - 2 ? "Submit" : "Continue"}
          </Button>
          {stepIndex !== stepLength - 1 &&
            <Button
              disabled={stepIndex === 0}
              variant="contained"
              onClick={() => handleBack()}
              sx={{ mt: 1, mr: 1 }}>
              Save & Continue Later
            </Button>
          }
        </div>
      }
    </Box>

  )
}

export default OnBoardingSteps;