import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid, SelectChangeEvent } from "@mui/material";
import { CompanyRegistrationStepOneProps, IRegistrationStepPageComponent, RegistrationStepResponse } from "./IRegistrationStepComponent";
import { PhysicalIcon, PostalIcon, RegistrationIcon } from "../../../util/MUI_Theme/Icons";
import TextField from "../CustomComponents/Controls/TextField";
import RegistrationSkeleton from "../Sections/RegistrationSkeleton";
import { GetCompanyDataById, SaveEntireCompany } from "../../../http/Company/company";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import AddressType from "../../../util/enums/AddressType";
import { AddressModel } from "../../../http/Company/Models/AddressModel";
import DropDownList from "../CustomComponents/DropDownList";
import extractFieldData from "../Utils/extractFieldDataHelper";
import useCompanyInformationRegValidation from "../../../util/hooks/validation/useCompanyRegistrationValidation";
import CompanyViewModelProps from "../CustomComponents/Models/companyViewModelProps";
import AddressModelProps from "../CustomComponents/Models/addressModelProps";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import useCountries from "../../../util/hooks/queries/useCountries";
import useCompanyTypes from "../../../util/hooks/queries/useCompanyTypes";
import useProvinces from "../../../util/hooks/queries/useProvinces";


// eslint-disable-next-line no-unused-vars
const CompanyRegistrationStepOne = forwardRef<IRegistrationStepPageComponent, CompanyRegistrationStepOneProps>(({ title }, ref) => {

  const [companyData, setCompanyData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [isLoading, setIsLoading] = useState(true);
  const [postalAddressSameAsPhysical, setPostalAddressSameAsPhysical] = useState(false);

  // useQuery
  const { isLoading: loadingCountries, data: countries = [] } = useCountries();
  const { isLoading: loadingProvinces, data: provinces = [] } = useProvinces();
  const { isLoading: loadingCompanyTypes, data: companyTypes = [] } = useCompanyTypes();
  const baseCompanyInfo = useBaseCompanyInfo();

  // customHooks - validation
  const defaultCountry = countries?.find(x => x.isDefault);
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const validateCompanyData = useCompanyInformationRegValidation(companyData, defaultCountry?.id ?? 0);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const companyDetails = await GetCompanyDataById(baseCompanyInfo?.data?.companyId ?? 0, false);
      setCompanyData(companyDetails.data);
      await new Promise(resolve => setTimeout(resolve, 2000)); // eslint-disable-line no-promise-executor-return
      setIsLoading(false);
    };
    if (baseCompanyInfo?.data?.companyId)
      loadData();
  }, [baseCompanyInfo?.data])




  const onSubmitHandler = async () => {
    try {
      const validationResults = validateCompanyData();
      if (validationResults.isInvalid) {
        setInvalidFields(validationResults.invalidFields);
        return {
          submittedSuccessfully: false,
          errorMessages: validationResults.validationErrors,
        } as RegistrationStepResponse;
      }

      await SaveEntireCompany(companyData, false);
      return {
        submittedSuccessfully: true,
        errorMessages: [],
      }
    }
    catch (error) {
      const response: RegistrationStepResponse = {
        submittedSuccessfully: true,
        errorMessages: ["Something went wrong! BadRequest"],
      };
      return response;
    }
  };

  const onSaveAndContinueHandler = async () => {
    await SaveEntireCompany(companyData, false);
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };
  };

  // Expose the methods and properties useImperativeHandle (the will be accessible form the ref)
  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));

  const handleDataUpdate = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
    const { fieldName, fieldValue } = extractFieldData(ev);
    if (fieldName)
      setCompanyData((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  }

  const physicalAddress = companyData.companyAddresses ?
    companyData.companyAddresses.find(x => x.addressTypeId === AddressType.Physical) :
    {} as unknown as AddressModel;

  const postalAddress = companyData.companyAddresses ?
    companyData.companyAddresses.find(x => x.addressTypeId === AddressType.Postal) :
    {} as unknown as AddressModel;

  const handleSetPostalAddressCheckbox = (isChecked: boolean) => {

    const addresses = [...companyData.companyAddresses];
    const postalAddressIndex = addresses.findIndex(x => x.addressTypeId === AddressType.Postal) ?? 0;

    if (companyData.companyAddresses == null || companyData.companyAddresses.length <= 0
      || postalAddressIndex <= 0
    ) return;

    setPostalAddressSameAsPhysical(isChecked);
    if (isChecked === true) {
      addresses[postalAddressIndex] = {
        ...physicalAddress,
        addressId: postalAddress?.addressId ?? 0,
        addressTypeId: AddressType.Postal,
      } as AddressModel;
      setCompanyData((prevState) => ({ ...prevState, companyAddresses: addresses }));

    } else {
      setCompanyData((prevState) => ({ ...prevState, companyAddresses: [physicalAddress, { addressTypeId: AddressType.Postal }] as AddressModel[] }));
    }
  };

  const handleAddressUpdate = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>, addressType: AddressType) => {
    setPostalAddressSameAsPhysical(false); // reset the check box

    const { fieldName, fieldValue } = extractFieldData(ev);

    if (fieldName && companyData.companyAddresses) {
      const addresses = [...companyData.companyAddresses];
      const addressIndex = companyData.companyAddresses.findIndex(x => x.addressTypeId === addressType);
      if (addressIndex !== -1) {
        const updatedAddress = { ...addresses[addressIndex], [fieldName]: fieldValue };
        addresses[addressIndex] = updatedAddress;
        setCompanyData((prevState) => ({ ...prevState, companyAddresses: addresses }));
      } else {

        const updatedAddress = { addressTypeId: addressType, [fieldName]: fieldValue };
        addresses[addressIndex] = updatedAddress as unknown as AddressModel;
        setCompanyData((prevState) => ({ ...prevState, companyAddresses: addresses }));
      }
    }
  };
  const showSkeleton = isLoading || loadingCompanyTypes || loadingCountries || loadingProvinces;

  return (
    showSkeleton ? <RegistrationSkeleton /> :
      <Box mx={0} px={0}>
        {/* Basic Company Information Section */}
        <Grid container sx={{ marginLeft: "20px", marginTop: "20px" }}>
          <Grid item xs={12} className="profile-view-labels">
            <RegistrationIcon />
            {title}
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={CompanyViewModelProps.registeredName}
                label="registered company Name"
                value={companyData.registeredName}
                onChange={handleDataUpdate}
                error={invalidFields.includes(CompanyViewModelProps.registeredName)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={CompanyViewModelProps.registrationNumber}
                label="company registration number"
                value={companyData.registrationNumber}
                onChange={handleDataUpdate}
                error={invalidFields.includes(CompanyViewModelProps.registrationNumber)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DropDownList
                required
                name={CompanyViewModelProps.companyTypeId}
                label="company type"
                value={companyData.companyTypeId}
                onChange={handleDataUpdate}
                listItems={companyTypes.map(x => ({ itemId: x.id, displayName: x.name }))}
                error={invalidFields.includes(CompanyViewModelProps.companyTypeId)}
              />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={4}>
              <TextField
                name={CompanyViewModelProps.tradingName}
                label="company trading name"
                value={companyData.tradingName}
                onChange={handleDataUpdate}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name={CompanyViewModelProps.holdingCompanyName}
                label="holding company name"
                value={companyData.holdingCompanyName}
                onChange={handleDataUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Addresses Physical Section */}
        <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
          <Grid item xs={12} className="profile-view-labels">
            <PhysicalIcon /> PHYSICAL ADDRESS
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.line1}
                label="street address line 1"
                value={physicalAddress?.line1 ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                error={invalidFields.includes(`physicalAddress:${AddressModelProps.line1}`)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.line2}
                label="street address line 2"
                value={physicalAddress?.line2 ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                error={invalidFields.includes(`physicalAddress:${AddressModelProps.line2}`)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name={AddressModelProps.officeBlockOrComplexName}
                label="office Block/Complex Name"
                value={physicalAddress?.officeBlockOrComplexName ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name={AddressModelProps.unitNumber}
                label="unit number"
                value={physicalAddress?.unitNumber ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
              />
            </Grid>

            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.city}
                label="city"
                value={physicalAddress?.city ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                error={invalidFields.includes("physicalAddress:city")}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <DropDownList
                required
                name={AddressModelProps.countryId}
                label="country"
                value={physicalAddress?.countryId ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                listItems={countries.map(x => ({ itemId: x.id, displayName: x.name }))}
                error={invalidFields.includes(`physicalAddress:${AddressModelProps.countryId}`)}
              />
            </Grid>
            {
              physicalAddress?.countryId === defaultCountry?.id &&
              <Grid xs={12} md={4}>
                <DropDownList
                  required
                  name={AddressModelProps.provinceId}
                  label="province/State"
                  value={physicalAddress?.provinceId ?? ""}
                  listItems={provinces.map(x => ({ itemId: x.id, displayName: x.name }))}
                  onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                  error={invalidFields.includes(`physicalAddress:${AddressModelProps.provinceId}`)}
                />
              </Grid>
            }
            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.postalCode}
                label="postal Code"
                value={physicalAddress?.postalCode ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Physical)}
                error={invalidFields.includes(`physicalAddress:${AddressModelProps.postalCode}`)}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Addresses Postal Section */}
        <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
          <Grid item xs={12} className="profile-view-labels">
            <PostalIcon /> POSTAL ADDRESS
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="control-label-checkbox"
              label="Postal Address is the same as physical address"
              control={
                <Checkbox
                  checked={postalAddressSameAsPhysical}
                  onChange={(ev) => handleSetPostalAddressCheckbox(ev.target.checked)} />
              }
            />
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={4}>
              <TextField
                name="line1"
                required
                label="street address line 1"
                value={postalAddress?.line1 ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                error={invalidFields.includes("postalAddress:line1")}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name="line2"
                required
                label="street address line 2"
                value={postalAddress?.line2 ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                error={invalidFields.includes("postalAddress:line2")}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name={AddressModelProps.officeBlockOrComplexName}
                label="office Block/Complex Name"
                value={postalAddress?.officeBlockOrComplexName ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                name={AddressModelProps.unitNumber}
                label="unit number"
                value={postalAddress?.unitNumber ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
              />
            </Grid>

            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.city}
                label="city"
                value={postalAddress?.city ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                error={invalidFields.includes("postalAddress:city")}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <DropDownList
                required
                name={AddressModelProps.countryId}
                label="country"
                value={postalAddress?.countryId ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                listItems={countries.map(x => ({ itemId: x.id, displayName: x.name }))}
                error={invalidFields.includes(`postalAddress:${AddressModelProps.countryId}`)}
              />
            </Grid>
            {
              postalAddress?.countryId === defaultCountry?.id &&
              <Grid xs={12} md={4}>
                <DropDownList
                  required
                  name={AddressModelProps.provinceId}
                  label="province/State"
                  value={postalAddress?.provinceId ?? ""}
                  onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                  listItems={provinces.map(x => ({ itemId: x.id, displayName: x.name }))}
                  error={invalidFields.includes(`postalAddress:${AddressModelProps.provinceId}`)}
                />
              </Grid>
            }
            <Grid xs={12} md={4}>
              <TextField
                required
                defaultErrorBehaviour
                name={AddressModelProps.postalCode}
                label="postal Code"
                value={postalAddress?.postalCode ?? ""}
                onChange={(ev) => handleAddressUpdate(ev, AddressType.Postal)}
                error={invalidFields.includes(`postalAddress:${AddressModelProps.postalCode}`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
  )
});

export default CompanyRegistrationStepOne;