import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NetconnSearchView from "../NetconnSearchView";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import ConnectionRequest from "./ConnectionRequest";
import { MyConnections, MyConnectionsStringToTabs } from "../../../util/enums/MyConnectionsTab";
import BasePage from "../../../pages/NewUI/BasePage";
import UploadedConnections from "../../../pages/Network/InvitedCompanies/UploadedConnections";

interface NetworkConnectionsProps {
  // eslint-disable-next-line react/require-default-props
  tabToLoad?: MyConnections,

  // eslint-disable-next-line no-unused-vars
  backdropControl: (show: boolean) => void
}

// a11yProps are 0 based, but section IDs are 1 based.
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const NetworkConnections = ({ tabToLoad = MyConnections.Clients, backdropControl }: NetworkConnectionsProps) => {
  const [value, setValue] = useState<number>(0);
  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {

    setValue(MyConnectionsStringToTabs(tabToLoad));
  }, []);

  return (
    <BasePage>
      <Box>
        <Typography variant="h3">My Network</Typography>
        <AppBar className="app-bar connect" position="static">
          <Tabs
            value={value}
            onChange={(_ev, val: number) => handleChangeTab(val)}
            orientation="horizontal"
            indicatorColor="secondary"
            textColor="inherit"
            aria-label=""
          >
            <Tab label="My Clients" {...a11yProps(0)} className="app-bar-tabs" />
            <Tab label="My Suppliers" {...a11yProps(1)} className="app-bar-tabs" />
            <Tab label="Connection Requests" {...a11yProps(2)} className="app-bar-tabs" />
            <Tab label="Invited Companies" {...a11yProps(3)} className="app-bar-tabs" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <NetconnSearchView searchType={NetconnSearchType.Client} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NetconnSearchView searchType={NetconnSearchType.Supplier} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConnectionRequest tab={tabToLoad} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <UploadedConnections />
        </TabPanel>
      </Box>
    </BasePage>
  );
};
export default NetworkConnections;
