// eslint-disable-next-line no-shadow
enum DataSourceTypes { // eslint-disable-line no-unused-vars
  Platform = 1,// eslint-disable-line no-unused-vars
  Staged = 2,// eslint-disable-line no-unused-vars
}

export default DataSourceTypes;

export const DataSourceTypesToString = (value: string) => {
  switch (value) {
    case "Staged":
      return 2
    case "Stage":
      return 2
    case "Platform":
      return 1
    default:
      return 0;
  }
};