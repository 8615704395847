import { BottomNavigation, Grid, Link } from "@mui/material";

const Footer = () => (
  <BottomNavigation showLabels className="page-footer">
    <Grid container>
      <Grid xs={4} sx={{ color: "white", margin: "auto", paddingLeft: "20px" }}>
        <Link className="link" href="https://b1link.com/Home/TermsAndConditions" sx={{ color: "white" }}>Terms & Conditions</Link>
      </Grid>
      <Grid xs={4} className="grid-content">
        <div className="div">
          <div style={{ float: "left", width: "fit-content" }}>B1LINK &reg;</div>
          <div style={{ height: 15, borderRight: "2px solid white", width: "2px", float: "left", marginRight: 10, marginLeft: 10 }} />
          <div style={{ float: "left", width: "fit-content" }}> All Rights Reserved 2023</div>
        </div>
      </Grid>
      <Grid xs={4} />
    </Grid>
  </BottomNavigation>
);

export default Footer;