import Fuse from "fuse.js"

const options = {
  isCaseSensitive: false,
  // includeScore: false,
  shouldSort: true,
  includeMatches: true,
  // findAllMatches: false,
  minMatchCharLength: 1,
  // location: 0,
  threshold: 0.4,
  // distance: 100,
  // useExtendedSearch: false,
  ignoreLocation: true,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
};

export type FuzzySearchProps<TObject> = {
  list: Array<TObject>,
  searchText: string,
  keys: Array<string>
}

const FuzzySearch = <TObject>({
  list,
  searchText,
  keys,
// eslint-disable-next-line no-undef
}: FuzzySearchProps<TObject>
) => {
  const fuse = new Fuse(list, {...options,keys});
  const result = fuse.search(searchText).map((res) => res.item);
  return result;
}

export default FuzzySearch;
