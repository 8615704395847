import { Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import SearchHistory from "./SearchHistory";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSearchHistory } from "../../http/Redux/Store/UserProfileSlice";
import { SearchHistoryModel } from "../../http/Redux/Models/SearchHistoryModel";
import { fetchSearchHistory } from "../../http/Redux/Store/UserProfileActions";


const AccountAccess = () => {
  const [open, setOpen] = useState(false);
  const history: SearchHistoryModel[] = useAppSelector(getSearchHistory);
  const handleClose = () => setOpen(false);
  const passwordRedirectUrl = process.env.REACT_APP_B2C_PASSWORDCHANGE_LINK as string;
  const dispatch = useAppDispatch();

  const handleOpenHistory = async () => {
    setOpen(true);
  };


  const redirectToPasswordChange = () => {
    window.location.replace(passwordRedirectUrl);
  }

  useEffect(() => {
    dispatch(fetchSearchHistory());
  }, [])


  return (
    <>
      <section className="profile-section-profile-account-access step-one-userprofile">
        <Button variant="contained" className="account-access-btn" onClick={handleOpenHistory} id="step-search">Search History</Button>
        <Button variant="contained" className="account-access-btn" onClick={redirectToPasswordChange} id="step-password">Change Password</Button>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SearchHistory history={history as SearchHistoryModel[]} />
      </Modal>
    </>
  )
};

export default AccountAccess;