import { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { useAppSelector } from "../../../app/hooks";
import { selectBaseCompanyInfo } from "../../../http/Redux/Store/baseCompanyInfoSlice";
import { BaseCompanyInfo } from "../../../http/Company/Models/BaseCompanyInfo";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import SearchDataGrid from "./SearchDataGrid";
import MainFilter from "../../Filter/MainFilter";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import SortOrderController from "../../Filter/SortOrderController";
import { OrderByModel } from "../../../http/NetworkAndConnect/Models/OrderByModel";
import { SearchModel } from "../../../http/NetworkAndConnect/Models/SearchModel";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import { DownloadSearchResultsExportAsync } from "../../../http/NetworkAndConnect/Netconn";
import UserProfileService from "../../../http/UserProfileService";
import { SearchHistoryModel } from "../../../http/Redux/Models/SearchHistoryModel";
// eslint-disable-next-line no-unused-vars
import useAllowedPermission, { useHasSubscriptionPermission } from "../../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";

export interface SearchResultModel {
  name: string;
  id: number;
  reg: string;
  trading: string;
  tradeDescription: string;
  location: string;
  linked: boolean;
  bee: string;
  province: string;
  boPercentage: number;
  bwoPercentage: number;
  rating: number;
  vendorNumber: string;
  createdDate: Date | null,
  cipc: string | null,
  sars: string | null,
  bbbee: string | null,
  beeRatingStatusId: number | null,
  overallCompliance: string,
  totalRows: number;
}

interface SearchViewProps {
  // eslint-disable-next-line react/require-default-props
  useReduxForSearchText?: boolean;
  // eslint-disable-next-line react/require-default-props
  searchText?: string;
  // eslint-disable-next-line react/require-default-props
  typeOfSearch?: NetconnSearchType;

  // eslint-disable-next-line no-unused-vars
  searchCallback: (
    // eslint-disable-next-line no-unused-vars
    searchModel: ConnectionSearchModel,
    // eslint-disable-next-line no-unused-vars
    searchType?: NetconnSearchType
  ) => Promise<SearchModel[]>;
}

const SearchView = ({
  useReduxForSearchText = false,
  searchText = "",
  typeOfSearch = NetconnSearchType.None,
  searchCallback,
}: SearchViewProps) => {
  const reduxSearchTerm: string = useAppSelector((x) => x.searchBarText);
  const searchTerm: string = useReduxForSearchText ? reduxSearchTerm.replace(/\s+/g, "%") : searchText.replace(/\s+/g, "%");

  const [rowsConnectNet, setRowsConnectNet] = useState<Array<SearchResultModel>>(
    [] as SearchResultModel[]
  );

  const companyData: BaseCompanyInfo = useAppSelector(selectBaseCompanyInfo);
  // const hasRequiredPermission = useAllowedPermission();
  const hasSubscriptionPermission = useHasSubscriptionPermission();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);

  const [filterModel, setFilterModel] = useState<ConnectionSearchModel>({
    companyId: companyData.companyId,
    searchQuery: searchTerm,
    searchPageNumber: paginationModel.page,
    searchRowNumber: paginationModel.pageSize,
  } as ConnectionSearchModel);

  const updateMainFilterModel = (updatedModel: ConnectionSearchModel, clearModel?: boolean) => {
    const modelToUpdate = updatedModel;
    modelToUpdate.searchPageNumber = 0;
    modelToUpdate.searchRowNumber = paginationModel.pageSize;

    setPaginationModel({ ...paginationModel, page: 0 });

    setRowsConnectNet([] as SearchResultModel[]);

    if (clearModel) {
      setFilterModel({ ...modelToUpdate });
      return;
    }

    setFilterModel({ ...filterModel, ...modelToUpdate });
  };

  const updatePaginationModel = (newPagingModel: GridPaginationModel) => {
    if (JSON.stringify(paginationModel) !== JSON.stringify(newPagingModel))
      setPaginationModel(newPagingModel);

    setFilterModel({ ...filterModel, searchRowNumber: newPagingModel.pageSize, searchPageNumber: newPagingModel.page });
  }

  const updateGridSort = (sortOrderArray: Array<OrderByModel>) => {
    const updatedFilterModel = { ...filterModel };
    updatedFilterModel.searchOrderBys = sortOrderArray;
    updatedFilterModel.searchPageNumber = 0;
    setPaginationModel({ ...paginationModel, page: 0 });
    setFilterModel(updatedFilterModel);
  };

  const FetchSearchResults = async (filterModelObject: ConnectionSearchModel, searchType: NetconnSearchType) => {
    const resultArray: Array<SearchResultModel> = [] as Array<SearchResultModel>;

    const companiesFromApi: Array<SearchModel> = await searchCallback(filterModelObject, searchType).finally(
      () => {
        setShowLoadingSpinner(false);
      }
    );

    companiesFromApi.forEach((element) => {
      const newModel: SearchResultModel = {
        name: `${element.registeredName} - ${element.complianceStatus} - ${element.city}` ?? "",
        id: element.companyId ?? 0,
        reg: element.registrationNumber ?? "",
        trading: element.tradingName ?? "",
        tradeDescription: element.tradeDescription ?? "",
        location: "Connect",
        linked:
          typeOfSearch === NetconnSearchType.Client ||
          typeOfSearch === NetconnSearchType.Supplier ||
          typeOfSearch === NetconnSearchType.Both,
        bee: element.beeLevel ?? "",
        province: element.province ?? "",
        boPercentage: element.bOPercentage ?? 0,
        bwoPercentage: element.bWOPercentage ?? 0,
        rating: element.rating ?? 0,
        vendorNumber: element.vendorNumber ?? "",
        createdDate: element.createdDate,
        cipc: element.cipc ?? "",
        sars: element.sars ?? "",
        bbbee: element.bbbee ?? "",
        beeRatingStatusId: element.beeRatingStatusId ?? 0,
        overallCompliance: element.complianceStatus ?? "None",
        totalRows: element.totalRows,
      };

      resultArray.push(newModel);
    });

    return resultArray;
  }

  const handleReload = async () => {
    setShowLoadingSpinner(true);
    const results = await FetchSearchResults(filterModel, typeOfSearch);
    setRowsConnectNet(results);
    setShowLoadingSpinner(false);
  }


  useEffect(() => {
    // Update the filter model search query if the search term has changed/doesn't match
    if (filterModel.searchQuery !== searchTerm) {
      setFilterModel({ ...filterModel, searchQuery: searchTerm, searchPageNumber: 0 });
      setPaginationModel({ ...paginationModel, page: 0 });
      const loader = async () => {
        const results = await FetchSearchResults(filterModel, typeOfSearch);
        const search: SearchHistoryModel = {} as SearchHistoryModel;
        search.searchTerm = searchText;
        search.searchResult = results.length;
        await UserProfileService.AddSearchHistory(search);
      };
      loader();
    }
  }, [searchTerm]);


  useEffect(() => {
    if (companyData.companyId !== undefined) {
      const loader = async () => {
        setShowLoadingSpinner(true);

        if (filterModel.searchQuery !== searchTerm) {
          setFilterModel({ ...filterModel, searchQuery: searchTerm, searchPageNumber: 0 });
          setPaginationModel({ ...paginationModel, page: 0 });
        }

        const createModelResult: SearchResultModel[] = await FetchSearchResults(filterModel, typeOfSearch);
        const filteredResults: SearchResultModel[] = createModelResult;
        setRowsConnectNet(filteredResults);
      };

      loader();
    }
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    companyData.companyId,
    JSON.stringify(filterModel),
    filterModel.searchOrderBys,
  ]);


  useEffect(() => {
    // Update the filter model search query if the search term has changed/doesn't match
    if (filterModel.searchQuery !== searchTerm) {
      setFilterModel({ ...filterModel, searchQuery: searchTerm, searchPageNumber: 0 });
      setPaginationModel({ ...paginationModel, page: 0 });

      const loader = async () => {
        const search: SearchHistoryModel = {} as SearchHistoryModel;
        search.searchTerm = searchTerm;
        search.searchResult = rowsConnectNet.length > 0 ? rowsConnectNet[1].totalRows : 0;
        await UserProfileService.AddSearchHistory(search);
      };
      loader();

    }
  }, [searchTerm]);

  const downloadSearchResultExcelExport = () => {
    const loader = async () => {
      try {
        setShowLoadingSpinner(true);
        await DownloadSearchResultsExportAsync(filterModel, typeOfSearch);
        setShowLoadingSpinner(false);
      } catch {
        setShowLoadingSpinner(false);
        toast.error("Something went wrong while attempting to download your export, please try again or contact your admin")
      }
    }
    loader();
  }
  const showExcelExportButton = rowsConnectNet.length > 0 &&
    (typeOfSearch === NetconnSearchType.Client || typeOfSearch === NetconnSearchType.Supplier);

  return (
    <Grid container>
      <LoadingBackdrop showBackdrop={showLoadingSpinner} />
      <Grid item xs={2}>
        <MainFilter updateMainSearchModel={updateMainFilterModel} searchType={typeOfSearch} />
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", mb: 1 }}>
          <Box>
            {showExcelExportButton &&
              <Box sx={{ paddingTop: "7px" }}>
                <Button onClick={downloadSearchResultExcelExport} variant="contained">
                  Export to Excel
                </Button>
              </Box>
            }
          </Box>
          <Box>
            <SortOrderController UpdateSortOrder={updateGridSort} />
          </Box>
        </Box>
        <SearchDataGrid
          searchResults={rowsConnectNet || ([] as SearchResultModel[])}
          paginationModel={paginationModel}
          searchType={typeOfSearch}
          hasIBuyPermission={hasSubscriptionPermission(PermissionClassification.iBuySpecific)}
          updatePaginationModel={updatePaginationModel}
          reloadCallback={handleReload}
        />
      </Grid>
    </Grid>
  );
};

export default SearchView;
