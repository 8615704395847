import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DeleteMultipleSupplierUploads,
  DeleteSupplierUploadInvitation,
  GetSuppliersByCompanyId as GetUploadedStagingSuppliers,
  ResendMultipleSupplierInvites,
  UpdateAndResendSupplierInvite,
  downloadSupplierUploadsExcelExport,
} from "../../../http/Company/cleanApi";
import { SupplierModel } from "../../../http/Company/Models/SupplierModel";
import FormDialog from "../../../components/Dialogs/FormDialog";
import LoadingOverlay from "../../../components/UI/LoadingOverlay";
import useFieldValidation from "../../../util/hooks/useFieldValidation";
import InLineSpinner from "../../../components/LoadingSpinners/InlineSpinner";
import ConnectionType from "../../../util/enums/ConnectionType";
import SupplierUploadInvitationStatus, {
} from "../../../util/enums/SupplierUploadInvitationStatus";
import getSupplierUploadColumnConfig from "./UploadedSupplierTableColumnConfig";
import AddNewConnectionButton from "../../NewUI/AddNewConnectionButton";
import RegistrationSkeleton from "../../Registration/Sections/RegistrationSkeleton";
import UploadedConnectionsStatusFilter from "./UploadedConnectionsStatusFilter";
import { DownloadIcon } from "../../../util/MUI_Theme/Icons";
import { colorPrimaryActive } from "../../../util/MUI_Theme/Theme";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const UploadedConnectionsTable = ({connectionType}:{connectionType: ConnectionType}) => {
  const [, setIsLoading] = useState(false)
  const [connectionInvites, setConnectionInvites] = useState<Array<SupplierModel>>([] as Array<SupplierModel>);
  const [showResendInvitationModal, setShowResendInvitationModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierModel>({} as SupplierModel);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [validateField] = useFieldValidation("uploaded-suppliers");
  const [fileIsDownloading, setFileIsDownloading] = useState(false);


  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);

      try {
        const suppliersData = await GetUploadedStagingSuppliers(connectionType, false);
        setConnectionInvites(suppliersData.data);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    loader();
  }, [connectionType, connectionInvites.length]);

  const submitAndResendSupplierInvite = async () => {
    // validate email address
    if (!emailRegex.test(selectedSupplier.contactEmail)) {
      toast.error("Invalid email address");
      setShowResendInvitationModal(false);
      return;
    }

    // update supplier state in redux
    const newSuppliersState = connectionInvites.map((obj) => {
      if (obj.tempCompanyId === selectedSupplier.tempCompanyId) {
        return { ...obj, ...selectedSupplier };
      }
      return obj;
    });

    try {
      setShowLoadingSpinner(true);
      await UpdateAndResendSupplierInvite(selectedSupplier);
      setShowLoadingSpinner(false);
      setConnectionInvites(newSuppliersState);
      setShowResendInvitationModal(false);
    } catch (error) {
      setShowLoadingSpinner(false);
    }
  };

  const editSupplierInvite = (rowData:SupplierModel) => {
    setSelectedSupplier(rowData);
    setShowResendInvitationModal(true);
  };

  const deleteSupplierInvite = (tempCompanyId: string, registrationName: string) => {
    Swal.fire({
      title: `Are you sure you want to delete this supplier upload invite (${registrationName})?`,
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          await DeleteSupplierUploadInvitation(tempCompanyId)
          // remove from local state
          setConnectionInvites((prevState) => prevState.filter(x => x.tempCompanyId !== tempCompanyId));
          setIsLoading(false);
          toast.success(`Successfully deleted your supplier upload ${registrationName}`);
        }
        catch {
          toast.error("Something went wrong while attempting to delete record");
          setIsLoading(false);
        }
      }
    });
  };

  const deleteMultipleSupplierInvites = () => {
    Swal.fire({
      title: "Are you sure you want to delete the selected supplier uploads?",
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          await DeleteMultipleSupplierUploads(selectionModel);
          // remove from local state
          setConnectionInvites((prevState) => prevState.filter(x => !selectionModel.includes(x.tempCompanyId)));
          setSelectionModel([]);
          setIsLoading(false);
          toast.success("Successfully deleted your selected uploads");
        }
        catch {
          toast.error("Something went wrong while attempting to delete record(s)");
          setIsLoading(false);
        }
      }
    });
  };

  const downloadExcelExport = () => {
    const loader = async () => {
      try {
        setFileIsDownloading(true);
        await downloadSupplierUploadsExcelExport(connectionType, statusFilterValue);
        setFileIsDownloading(false);
      } catch (error) {
        setFileIsDownloading(false);
        toast.error("Something went wrong while attempting to download file, please try again");
      }
    }
    loader();
  };

  const resendSelectedInvites = () =>
  {
    const loader = async () => {
      try {
        setIsLoading(true);
        await ResendMultipleSupplierInvites(selectionModel as Array<string>)

        // update supplier invites status
        setConnectionInvites((prevState) => {
          const updateSuppliers = prevState.map((currentSupplier) => {
            if(selectionModel.includes(currentSupplier.tempCompanyId))
              return {...currentSupplier,invitationStatus: SupplierUploadInvitationStatus.EmailSent}
            return currentSupplier;
          })
          return updateSuppliers;
        })

        setIsLoading(false);
        setSelectionModel([]);
        toast.success("Invites resent successfully");
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong while to resent your invites, please try again");
      }
    }
    loader();
  }

  const filteredSuppliers = () => {
    if (statusFilterValue.length <= 0) {
      return connectionInvites;
    }

    const selectedStatusValue = parseInt(statusFilterValue, 10);
    return connectionInvites.filter(x => x.invitationStatus === selectedStatusValue);
  }

  // eslint-disable-next-line no-unused-vars
  const invitationDialog = <FormDialog
    title="Update and resend invitation"
    handleClose={() => setShowResendInvitationModal(false)}
    handleSubmit={submitAndResendSupplierInvite}
    submitButtonText="Update and submit invite"
  >
    {showLoadingSpinner ? (
      <RegistrationSkeleton />
    ) : (
      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.registeredName || ""}
              label="Company Registration Name"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  registeredName: ev.target.value,
                }))
              }
              error={
                validateField("Company Registration Name", selectedSupplier?.registeredName)
                  .isInvalid
              }
              helperText={
                validateField("Company Registration Name", selectedSupplier?.registeredName)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.registrationNumber || ""}
              label="Registration Number"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  registrationNumber: ev.target.value,
                }))
              }
              error={
                validateField("Registration Number", selectedSupplier?.registrationNumber)
                  .isInvalid
              }
              helperText={
                validateField("Registration Number", selectedSupplier?.registrationNumber)
                  .validationErrorText
              }
            />
          </Grid>
          {connectionType === ConnectionType.Supplier && (
            <Grid item xs={6}>
              <TextField
                type="text"
                sx={{ width: "100%" }}
                value={selectedSupplier?.clientVendorNumber || ""}
                label="Vendor Number"
                size="small"
                onChange={(ev) =>
                  setSelectedSupplier((prevState) => ({
                    ...prevState,
                    clientVendorNumber: ev.target.value,
                  }))
                }
                error={
                  validateField("Vendor Number", selectedSupplier?.clientVendorNumber)
                    .isInvalid
                }
                helperText={
                  validateField("Vendor Number", selectedSupplier?.clientVendorNumber)
                    .validationErrorText
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactName || ""}
              label="Contact Person Name"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactName: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Name", selectedSupplier?.contactName).isInvalid
              }
              helperText={
                validateField("Contact Person Name", selectedSupplier?.contactName)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactSurname || ""}
              label="Contact Person Surname"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactSurname: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Surname", selectedSupplier?.contactSurname)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Surname", selectedSupplier?.contactSurname)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactPhone || ""}
              label="Contact Person Phone"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactPhone: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Phone", selectedSupplier?.contactPhone)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Phone", selectedSupplier?.contactPhone)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactEmail || ""}
              label="Contact Person Email"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactEmail: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Email", selectedSupplier?.contactEmail)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Email", selectedSupplier?.contactEmail)
                  .validationErrorText
              }
            />
          </Grid>
        </Grid>
      </Box>
    )}
  </FormDialog>;
  const hasSelectedTablesRows = selectionModel.length > 0;
  return (
    <>
      {showResendInvitationModal && (invitationDialog)}
      <LoadingOverlay active={showLoadingSpinner}>
        <Grid container py={3} spacing={3}>
          <Grid item xs={12} md={4}>
            <UploadedConnectionsStatusFilter
              statusFilterValue={statusFilterValue}
              setStatusFilterValue={setStatusFilterValue}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Box>
                <Button
                  sx={{ backgroundColor: colorPrimaryActive, color: "white" }}
                  variant="contained"
                  onClick={downloadExcelExport}
                  disabled={fileIsDownloading}
                  startIcon={<DownloadIcon fontSize="small" />}
                >
                  <Typography variant="button" p={1}>
                    EXPORT TO EXCEL
                  </Typography>
                </Button>
              </Box>
              <Box>
                <AddNewConnectionButton padding={1} connectionType={connectionType}>
                  {`ADD NEW ${connectionType === ConnectionType.Client ? "CLIENTS" : "SUPPLIERS"}`}
                </AddNewConnectionButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {fileIsDownloading && <InLineSpinner message="downloading excel export..." />} 

        
        {
          hasSelectedTablesRows &&
                  <Grid my={2} container spacing={0.5}>
                    <Grid item>
                      <Button
                        size="medium"
                        variant="contained"
                        color="success"
                        onClick={resendSelectedInvites}
                      >
                        <ReplayIcon /> Resend invites
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="medium"
                        variant="contained"
                        color="error"
                        onClick={deleteMultipleSupplierInvites}
                      >
                        <DeleteIcon /> Delete invites
                      </Button>
                    </Grid>
                  </Grid>
        }
        <Box height={pageSize * 62.8} sx={{ width: "100%" }}>
          <DataGrid
          // configure row & columns
            getRowId={(row: SupplierModel) => row.tempCompanyId}
            rows={filteredSuppliers() ?? []}
            columns={getSupplierUploadColumnConfig(deleteSupplierInvite, editSupplierInvite)}

            // configure pagination
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}

            // configure multli-selection
            checkboxSelection
            isRowSelectable={(params: GridRowParams) =>
              params.row.invitationStatus !== SupplierUploadInvitationStatus.Registered &&
                      params.row.invitationStatus !== SupplierUploadInvitationStatus.InvalidEmail
            }
            selectionModel={selectionModel}
            onSelectionModelChange={(selection) => setSelectionModel(selection as string[])}
            disableSelectionOnClick
          />
        </Box>
      </LoadingOverlay>
    </>
  );
};

export default UploadedConnectionsTable;
