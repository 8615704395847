import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UserWorkCentreModel, UserWorkCentreModelArray } from "../Models/UserWorkCentreModel";


const initialUserWorkCentreState: UserWorkCentreModelArray = {
  allUserWorkCentre: [],
}


export const userWorkCentreSlice = createSlice({
  name: "userWorkCentre",
  initialState: initialUserWorkCentreState,
  reducers: {
    setUserWorkCentre(state, action: PayloadAction<UserWorkCentreModel[]>) {
      state.allUserWorkCentre = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


export const getUserWorkCentre = (state: RootState) => state.userWorkCentre.allUserWorkCentre;