import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetActiveMunicipalities } from "../../Company/Municipality";
import { MunicipalityModel } from "../Models/MunicipalityModel";
import municipalityListSlice from "./MunicipalitySlice";

export const MunicipalityListActions = municipalityListSlice.actions;

export const fetchActiveMunicipalities = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<MunicipalityModel> = (await GetActiveMunicipalities()).data;
  dispatch(MunicipalityListActions.setMunicipalityListState(apiResponse));
}