/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAccount, useMsal } from "@azure/msal-react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import StarIcon from "@mui/icons-material/Star";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { Box, IconButton, Tooltip, styled } from "@mui/material";
import BasePage from "../BasePage";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import { GetBaseCompanyInfoViaCompanyId, GetCompanyDataById, GetUserCompanies, SetDefaultUserCompany } from "../../http/Company/company";
import { UserCompanyModel } from "../../http/Redux/Models/UserCompanyModel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCurrentCompanyId, setCompanyViewModelState } from "../../http/Redux/Store/companySlice";
import { setBaseCompanyInfoState } from "../../http/Redux/Store/baseCompanyInfoSlice";
import { protectedResources } from "../../authConfig";




const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
)


const CompanySwitchMain = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentCompanyId = useAppSelector(getCurrentCompanyId);
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAdId = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const userProfile = useAppSelector((state) => state.userProfile);
  const NavigateHome = () => navigate("/home");

  const [userCompanies, setUserCompanies] = useState<Array<UserCompanyModel>>(
    [] as Array<UserCompanyModel>
  );

  const [showBackDrop, setShowBackDrop] = useState<boolean>(false);
  const [activeCompany, setActiveCompany] = useState<number>(0);

  const handleRefresh = async () => {
    setShowBackDrop(true);
    const userCompaniesFromApi = await GetUserCompanies();
    const parsedUserCompanies = [...userCompaniesFromApi] as Array<UserCompanyModel>;

    setUserCompanies(parsedUserCompanies);
    setActiveCompany(currentCompanyId);
    setShowBackDrop(false);
  }


  const HandleCompanySwitch = async (eventValue: number) => {
    setShowBackDrop(true);

    const companyId = eventValue;
    const mainResult = await GetCompanyDataById(companyId, false);
    const baseResult = await GetBaseCompanyInfoViaCompanyId(companyId, false);

    dispatch(setCompanyViewModelState(mainResult.data));
    dispatch(setBaseCompanyInfoState(baseResult));

    NavigateHome();
  };

  const HandleSetCompanyAsDefault = async (eventValue: number) => {

    const companyId = eventValue;


    if (account && inProgress === "none") {
      let token = "";

      setShowBackDrop(true);

      instance
        .acquireTokenSilent({
          scopes: protectedResources.companyApi.scopes,
          account,
        })
        .then(async (response) => {
          token = response.accessToken;
          await SetDefaultUserCompany(token, companyId.toString(), userAdId || "");
          handleRefresh();
        })
        .catch((error) => {
          setShowBackDrop(false);
          throw error;
        });
    }
  };



  useEffect(() => {


    const loader = async () => {
      if (Number(userProfile.particularUserProfile.companyCount) <= 1) return;

      setShowBackDrop(true);
      const userCompaniesFromApi = await GetUserCompanies();
      const parsedUserCompanies = [...userCompaniesFromApi] as Array<UserCompanyModel>;

      setUserCompanies(parsedUserCompanies);
      setActiveCompany(currentCompanyId);
    };

    loader();
    setShowBackDrop(false);

  }, []);

  const columns: GridColDef[] = [
    {
      field: "defaultCompany",
      headerName: "",
      width: 20,
      renderCell: (params) => (
        <div style={{ marginTop: 1 }}>
          {params.value &&
            <StarIcon sx={{ color: "gold" }} />
          }
        </div>
      ),
    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div>{params.value}</div>
      ),

    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 220,
      renderCell: (params) => (
        <div>{params.value}</div>
      ),

    },
    {
      field: "tradingName",
      headerName: "Trading Name",
      flex: 1,
      minWidth: 180,
    },

    {
      field: "companyId",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <div>
          {params.value !== activeCompany && (
            <Tooltip title="Switch to Company">
              <IconButton
                className="default-btn-switchCompany"
                onClick={() => HandleCompanySwitch(params.value)}
              >
                <CompareArrowsIcon />
              </IconButton>
            </Tooltip>
          )}
          {!params.row.defaultCompany && (
            <Tooltip title="Set as Default Company">
              <IconButton
                className="default-btn-switch"
                onClick={() => HandleSetCompanyAsDefault(params.value)}
              >
                <StarIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <BasePage pageHeader="Switch Company" pageHeaderSub="Sign in to one of your other companies">
      <LoadingBackdrop showBackdrop={showBackDrop} />
      <Box sx={{ width: "97.8%", height: "400px", marginLeft: "20px" }} className="complianceBox">
        <DataGridPro
          rows={userCompanies ?? []}
          columns={columns}
          disableRowSelectionOnClick
          getRowId={(row: UserCompanyModel) => row.companyId}
          pagination
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }} />
      </Box>
    </BasePage>
  );
}


export default CompanySwitchMain;