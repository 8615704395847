import { ProfileImageModel } from "../../http/Redux/Models/ProfileImageModel";
import EditProfileImage from "./EditProfileImage";
import bufferProfile from "../../util/images/filler.png";




const AccountHeader = ({ Image }: { Image: ProfileImageModel }) => (

  <section className="profile-section-profile-image">

    <div className="sidebar__top_home" id="accountHeader">
      <div className="card-image">
        <img src={Image.url !== null ? Image.url : bufferProfile} alt="" className="profile-section-profile-image-big" />
      </div>
      <EditProfileImage />
    </div>
  </section>
);

export default AccountHeader;


