/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum CompanyTypes {
  None = 0,
  PrivateCompany = 1,
  ClosedCorporation = 2,
  PublicListedCompany = 3,
  PublicNonListedCompany = 4,
  SoleProprietary = 5,
  NonProfitOrganisation = 6,
  Partnership = 7,
  BranchofForeignCompany = 8,
  Trust = 9,
  StateOwnedEntity = 10,
  HigherEducationInstitute = 11,
  Limitedbyguarantee = 12,
  PublicBenefitOrganisations = 13,
  GovernmentDepartmentorAgency = 14,
  Employee = 15,
  ForeignEntity = 16,
  Incorporation = 17,
  Cooperative = 18,
  IncorporatedJointVenture = 19,
  CloseCorporation = 20,
  ForeignCompany = 21,
}

export default CompanyTypes;

export const CompanyTypeToString = (value: CompanyTypes) => {
  switch (value) {
    case CompanyTypes.PrivateCompany:
      return "Private Company";
    case CompanyTypes.ClosedCorporation:
      return "Closed Corporation";
    case CompanyTypes.PublicListedCompany:
      return "Public Listed Company";
    case CompanyTypes.PublicNonListedCompany:
      return "Public Non-Listed Company";
    case CompanyTypes.SoleProprietary:
      return "Sole Proprietary";
    case CompanyTypes.NonProfitOrganisation:
      return "Non-Profit Organisation";
    case CompanyTypes.Partnership:
      return "Partnership";
    case CompanyTypes.BranchofForeignCompany:
      return "Branch of Foreign Company";
    case CompanyTypes.Trust:
      return "Trust";
    case CompanyTypes.StateOwnedEntity:
      return "State Owned Entity";
    case CompanyTypes.HigherEducationInstitute:
      return "Higher Education Institute";
    case CompanyTypes.Limitedbyguarantee:
      return "Limited by guarantee";
    case CompanyTypes.PublicBenefitOrganisations:
      return "Public Benefit Organisations";
    case CompanyTypes.GovernmentDepartmentorAgency:
      return "Government Departmentor Agency";
    case CompanyTypes.Employee:
      return "Employee";
    case CompanyTypes.ForeignEntity:
      return "ForeignEntity";
    case CompanyTypes.Incorporation:
      return "Incorporation";
    case CompanyTypes.Cooperative:
      return "Cooperative";
    case CompanyTypes.IncorporatedJointVenture:
      return "IncorporatedJointVenture";
    case CompanyTypes.CloseCorporation:
      return "CloseCorporation";
    case CompanyTypes.ForeignCompany:
      return "ForeignCompany";

    default:
      return "Unknown Type";
  }
};
