import { Chip, Grid, IconButton, ListItemButton, Paper, TablePagination, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import "../../../Connect/SearchResult/SweetAlert.css";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DiscountIcon from "@mui/icons-material/Discount";
import { useAppSelector } from "../../../../app/hooks";
import { SubscriptionVoucherModel } from "../../../../http/Redux/Models/SubscriptionVoucherModel";
import { getAllVoucher, getHasSubscriptions } from "../../../../http/Redux/Store/SubscriptionSlice";
import SubscriptionService from "../../../../http/SubscriptionManagement/SubscriptionApi";
import { HasSubscriptionModel } from "../../../../http/SubscriptionManagement/Models/HasSubscriptionModel";
import styles from "../../../../pages/Configuration/PlatformBase.module.scss";

const useStyles = makeStyles({
  paper: {
    width: "100%",
    height: "100%",
    marginTop: "2%",
    padding: "10px",
  },
  search:
  {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  row:
  {
    width: "100%",
  },
  rowAction:
  {
    width: "100%",
    marginLeft: "-10px",
  },
  icons:
  {
    fontSize: "1rem",
  },
  table:
  {
    width: "100%",
  },
  page:
  {
    width: "100%",
  },
});

export interface SearchProps {
  // eslint-disable-next-line no-unused-vars
  changeToEdit: (status: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  dataForEdit: (data: SubscriptionVoucherModel) => void;
}

const VoucherList = ({ changeToEdit, dataForEdit }: SearchProps) => {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Array<SubscriptionVoucherModel>>([] as Array<SubscriptionVoucherModel>);
  const vouchersList: SubscriptionVoucherModel[] = useAppSelector(getAllVoucher);
  const hasSubscriptions: HasSubscriptionModel[] = useAppSelector(getHasSubscriptions);
  const [selectedVoucher, setSelectedVoucher] = useState(0);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleItemClick = (record: SubscriptionVoucherModel) => {
    changeToEdit(true);
    dataForEdit(record);
  };

  const handleDeleteVoucher = (voucher: SubscriptionVoucherModel) => {

    const loader = async () => {

      const stateDeleteVoucher = await SubscriptionService.deleteVoucher(voucher);
      setSelectedVoucher(stateDeleteVoucher);

    };


    loader();

  };

  useEffect(() => {
    setRows(vouchersList);
    if (selectedVoucher === 200) {
      setSwalPropsChange({
        show: true,
        title: "Deleted",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });
    }
  }, [vouchersList.length, dataForEdit, changeToEdit]);

  return (




    <div className="validation-box">

      {(rowsPerPage > 0
        ? rows.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
        : rows
      ).map((record) => (
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={4} className="grid-list-box">
            <Grid item xs={12}>

              <div className={styles.closeBtnSub}>
                {hasSubscriptions.some(x => x.voucherId === record.id) ? (
                  <p />
                ) : (
                  <IconButton onClick={() => handleDeleteVoucher(record)}><CancelIcon className={styles.deleteIconBtn} /></IconButton>
                )}
              </div>
              <ListItemButton onClick={() => handleItemClick(record)}>
                <div className={classes.row}>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th><Tooltip title="Voucher Name" placement="left-start"><TextFieldsIcon className="icons" /></Tooltip></th>
                        <td><h3>{record.name}</h3></td>
                      </tr>
                      <tr>
                        <th><Tooltip title="Discount Type" placement="left-start"><DiscountIcon className="icons" /></Tooltip></th>
                        <td><Chip label={record.discountType === 0 ? "FLAT" : "PCT"} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ListItemButton>

            </Grid>
          </Grid>
        </Paper>


      ))}

      <br />

      <Grid container>

        <TablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          className={classes.page} />

      </Grid>
      <SweetAlert2 {...swalPropsChange} />
    </div>




  )
};

export default VoucherList;