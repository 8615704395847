import { FieldModel } from "../../http/Redux/Models/FieldModel";
import { ValidationModel } from "../../http/Configuration/Validation/ValidationModel";
import { useAppSelector } from "../../app/hooks";
import { getFields } from "../../http/Redux/Store/FieldSlice";
import { getFieldValidations } from "../../http/Redux/Store/ValidationSlice";

interface ValidationFieldResponse {
  isInvalid: boolean,
  validationErrorText: string,
}

const useFieldValidation = (currentPageName : string ) => {
  const fieldDefinitions: FieldModel[] = useAppSelector(getFields);
  const fieldValidationRules: ValidationModel[] = useAppSelector(getFieldValidations);
  let invalidFields: string[] = [];

  const validateCurrentField = (
    fieldName: string,
    fieldValue: string | number,
    countryId?: number,
    companyTypeId?: number): ValidationFieldResponse => {

    const fieldDefinition = fieldDefinitions.find(x => x.name === fieldName && x.page === currentPageName);
    let validationRule: ValidationModel | undefined;

    // get validation rule : see company registration number : https://github.com/tiaanduplessis/sa-company-registration-number-regex/blob/master/index.js
    if(countryId && companyTypeId)  validationRule = fieldValidationRules.find(x => x.fieldId === fieldDefinition?.id &&
                                                                                    x.countryId === countryId &&
                                                                                    x.companyTypeId === companyTypeId);
    else validationRule= fieldValidationRules.find(x => x.fieldId === fieldDefinition?.id);

    if (fieldDefinition == null || validationRule == null || fieldValue == null) return { isInvalid: false, validationErrorText: "" };

    const min = validationRule.minLength as number;
    const max = validationRule.maxLength as number;
    const fValue = fieldValue.toString();
    // mininimum text length
    if (min > 0 && fValue.length < min)
    {
      invalidFields.push(fieldName);
      return { isInvalid: true, validationErrorText: "Input does not meet minimum length requirement" }
    }
    // maximum text length
    if (max > 0 && fValue.length > max)
    {
      invalidFields.push(fieldName);
      return { isInvalid: true, validationErrorText: "Input exceeds the maximum length required" }
    }

    // regex check
    if (validationRule.pattern && !fValue.match(validationRule.pattern))
    {
      return { isInvalid: true, validationErrorText: validationRule.defaultMessage ?? "Invalid input" }
    }

    // valid result
    invalidFields = invalidFields.filter(x => x === fieldName);
    return { isInvalid: false, validationErrorText: "" };
  }
  return [validateCurrentField, invalidFields.length > 0 ] as const;
}
export default useFieldValidation;
