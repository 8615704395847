import Swal from "sweetalert2";
import ConnectionType from "../enums/ConnectionType";
import saveSupplierVendorNumber, {
  doesVendorNumberExist,
} from "../../http/Company/CompanySpecificSupplierDetails";
import { VendorNumberDto } from "../../http/Company/Models/VendorNumberDto";
import { showErrorSwal, showLoadingSwal, showSuccessSwal } from "./swalDialogHelpers";
import { ConnectionRequestModel } from "../../http/Redux/Models/ConnectionRequestModel";
import ConnectionRequestService from "../../http/ConnectionRequestService";
import ConnectionStatus from "../enums/ConnectionStatus";
import ConnectionRequestType from "../enums/ConnectionRequestType";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import { NotificationMessage } from "../../http/Notifications/Models/NotificationMessage";
import { AddNewNotification } from "../../http/Notifications/Notification";

const createBuyerConnectionRequest = async (
  clientCompanyId: number,
  invitedCompanyId: number,
  userProfileId: number | undefined,
  userAdId: string | undefined,
  invitedContactEmail = "",
  invitedCompanyName = "",
  invitedContactName = ""
) => {
  const connectionRequest = {
    requestedByCompanyId: clientCompanyId,
    companyId: invitedCompanyId,
    invitedContactEmail,
    invitedCompanyName,
    invitedContactName,
    requestedByUserId: userProfileId || 0,
    requestType: ConnectionRequestType.Client,
    connectionType: ConnectionType.Client,
    connectionStatus: ConnectionStatus.Pending,
    createdBy: userAdId || null,
    dateCreated: new Date(),
    modifiedBy: null,
    dateModified: new Date(),
  } as ConnectionRequestModel;

  await ConnectionRequestService.addConnectionRequest(connectionRequest);
};

const setupClientConnectionRequest = (
  clientCompanyId: number,
  supplierCompanyId: number,
  connectionType: ConnectionType,
  userProfileObject: UserProfileModel | undefined,
  captureVendorNumber: boolean,
  notificationMsg?: NotificationMessage
) => {

  /* eslint-disable-next-line arrow-body-style */
  const sendNotification = (notificationMessage: NotificationMessage) => {
    return AddNewNotification(notificationMessage)
      .then(() => {
        showSuccessSwal(
          "Notification sent successfully."
        );
      })
      .catch((error) => {
        showErrorSwal(
          "Failed to send notification.",
          "Please try again, or contact support"
        );
        throw error;
      });
  };

  // This is the meat and veg of the iBuy check:
  if (captureVendorNumber) {
    Swal.fire({
      title: "Please enter a unique vendor number for this supplier (Optional)",
      input: "text",
      inputPlaceholder: "Enter a vendor number...",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Next",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      preConfirm: async (vendorNumberText) => {
        if (vendorNumberText.trim().length > 0) {
          const vendorNumberCheck = await doesVendorNumberExist(
            supplierCompanyId,
            vendorNumberText.trim(),
            false
          );
          const successCodes = [200, 204];
          if (!successCodes.includes(vendorNumberCheck.status)) {
            Swal.showValidationMessage(vendorNumberCheck.data as string);
          }

          return vendorNumberText.trim();
        }

        return vendorNumberText.trim();
      },
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resultVal = result.value;

        const vendorNumDto = {
          clientCompanyId,
          supplierCompanyId,
          vendorNumber: resultVal,
          userId: userProfileObject?.accountId,
        } as VendorNumberDto;

        try {
          showLoadingSwal();
          if (notificationMsg) {
            await sendNotification(notificationMsg);
          }
          await saveSupplierVendorNumber(vendorNumDto, false);
          await createBuyerConnectionRequest(clientCompanyId, supplierCompanyId, userProfileObject?.id, userProfileObject?.accountId);
        } catch (error) {
          showErrorSwal(
            "An error occurred sending the buyer request",
            "Please try again, or contact support"
          );
        }
      }
    });
  } else {
    showLoadingSwal();
    createBuyerConnectionRequest(clientCompanyId, supplierCompanyId, userProfileObject?.id, userProfileObject?.accountId);
  }
};

export default setupClientConnectionRequest;