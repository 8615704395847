/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum ConnectionRequestType {
  None = 0,
  Client = 1,
  Supplier = 2,
  Follow = 3,
  Invite = 4,
}

export default ConnectionRequestType;

export const connectionRequestTypeToString = (value: ConnectionRequestType) => {
  switch (value) {
    case ConnectionRequestType.None:
      return "None";
    case ConnectionRequestType.Client:
      return "Client";
    case ConnectionRequestType.Supplier:
      return "Supplier";
    case ConnectionRequestType.Follow:
      return "Follow";
    case ConnectionRequestType.Invite:
      return "Invite";
    default:
      return "Unknown Request Type";
  }
};