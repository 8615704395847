
import { Page, Text, Document, StyleSheet, Image, View } from "@react-pdf/renderer";
import Blink from "../../util/images/b1link.png";
import Zero from "../../util/images/Ratings/Zero.png";
import CrossIcon from "../../util/images/x.png";
import CheckIcon from "../../util/images/check.png";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import ContactType from "../../util/enums/ContactType";
import { UnspscSegment } from "../../http/Configuration/UnspscSegment";
import { CompanyTypeToString } from "../../util/enums/CompanyTypes";
import AddressType from "../../util/enums/AddressType";

const styles = StyleSheet.create({
  body: {
    border: 1,
    padding: 5,
    borderColor: "gray",
    borderStyle: "solid",
  },
  doc: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  title: {
    fontSize: 15,
    textAlign: "left",
    color: "white",
    marginTop: 60,
    marginLeft: 150,
  },
  title2: {
    fontSize: 15,
    marginTop: 1,
    color: "white",
    marginLeft: 150,
    textAlign: "left",
  },
  image: {
    marginVertical: 30,
    marginLeft: 30,
    position: "absolute",
    borderRadius: "100%",
    width: "20%",
    backgroundColor: "white",
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  fixedTextTop: {
    position: "absolute",
    fontSize: 10,
    bottom: 80,
    left: 10,
    flexGrow: 1,
    right: 10,
    textAlign: "left",
  },
  fixedTextMiddle: {
    position: "absolute",
    fontSize: 10,
    bottom: 65,
    left: 10,
    flexGrow: 1,
    right: 10,
    textAlign: "left",
  },
  fixedTextLast: {
    position: "absolute",
    fontSize: 10,
    bottom: 50,
    left: 10,
    right: 10,
    flexGrow: 1,
    textAlign: "left",
  },

  imageFooter: {
    width: "20%",
    position: "absolute",
    bottom: 110,
    left: 10,
    textAlign: "left",
  },
  sectionFixed: {
    position: "absolute",
    width: "100%",
    zIndex: -1,
    backgroundColor: "#435cd2",
    height: "110pt",
    marginBottom: 100,
  },
  headerSection: {
    width: "100%",
    height: 180,

  },
  subSectionHeading:
  {
    padding: 10,
    fontSize: 12,
    color: "white",
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: "#435cd2",
  },
  subSectionText: {
    fontSize: 10,
    paddingLeft: 10,
  },
  imageRating: {
    width: "20%",
  },
  subtext: {
    marginBottom: 1,
    marginTop: 1,
    width: "25%",
    fontSize: 10,
    textAlign: "center",
  },
  sectionrow: {
    flexDirection: "row",
    width: "100%",
    marginTop: 3,
  },
  sectionflex: {
    padding: 10,
    flexGrow: 1,
  },
  imageicon: {
    alignSelf: "center",
    width: "20pt",
    height: "20pt",
  },
});
const PDF = ({
  selectedCompany,
  profile,
  expiryDateString,
  products,
}: {
  selectedCompany: CompanyViewModel;
  profile: string;
  expiryDateString: string;
  products: (UnspscSegment | null)[];
}) => {
  const generalContact = selectedCompany?.companyContacts?.filter(
    (x) => x.contactTypeId === ContactType.GeneralCompany
  )[0];
  const filteredProducts = products?.filter((x) => x != null) ?? null;
  // eslint-disable-next-line no-unused-vars
  let counter = 0;
  return (
    <Document style={styles.doc}>
      <Page style={styles.body} wrap>
        <View style={styles.headerSection}>
          <View style={styles.sectionFixed} />
          <Image
            src={profile === "" ? profile : "../../util/images/filler.png"}
            style={styles.image}
          />
          <Text style={styles.title}>{selectedCompany.registeredName}</Text>
          <Text style={styles.title2}>{selectedCompany.tradingName}</Text>
        </View>
        <View style={styles.subSectionHeading}>
          <Text>Company Details</Text>
        </View>

        <Text style={styles.subSectionText}>
          Company Type: {CompanyTypeToString(selectedCompany.companyTypeId) || ""}
        </Text>
        <Text style={styles.subSectionText}>
          Company Registration Number: {selectedCompany.registrationNumber || ""}
        </Text>
        <Text style={styles.subSectionText}>Vat Registered: {selectedCompany.vatNumber || ""}</Text>
        <Text style={styles.subSectionText}>
          B-BBEE:
          {`Black Women Owned: ${selectedCompany?.companyBeeDetails?.bwoPercentage || 0}%`}
          <br />
          {`Black Owned: ${selectedCompany?.companyBeeDetails?.boPercentage || 0}%`}
          <br />
          {`Expiry Date:${expiryDateString || ""}`}
        </Text>
        <Text style={styles.subSectionText}>
          Company Contact Number: {generalContact ? generalContact.mobile : ""}
        </Text>
        <Text style={styles.subSectionText}>
          Company Email: {generalContact ? generalContact.email : ""}
        </Text>

        <View style={styles.subSectionHeading}>
          <Text>Address</Text>
        </View>

        {selectedCompany?.companyAddresses ? (
          selectedCompany?.companyAddresses
            .filter((x) => x.addressTypeId === AddressType.Physical)
            ?.map((records) => (
              <>
                <Text style={styles.subSectionText}>{records.line1}</Text>
                <Text style={styles.subSectionText}>{records.line2}</Text>
                <Text style={styles.subSectionText}>{records.city}</Text>
                <Text style={styles.subSectionText}>{records.suburb}</Text>
              </>
            ))
        ) : (
          <Text style={styles.subSectionText}>No address set.</Text>
        )}

        <View style={styles.subSectionHeading}>
          <Text>Rating</Text>
        </View>
        <Image src={Zero} style={styles.imageRating} />
        <View style={styles.subSectionHeading}>
          <Text>Compliance</Text>
        </View>
        <View style={styles.sectionrow}>
          <Text style={styles.subtext}>Company Registered Name</Text>
          <Text style={styles.subtext}>CIPC Business Status</Text>
          <Text style={styles.subtext}>SARS Validation</Text>
          <Text style={styles.subtext}>B-BBBEE Validation</Text>
        </View>
        <View style={styles.sectionrow}>
          <View style={styles.sectionflex}>
            <Image src={CheckIcon} style={styles.imageicon} />
          </View>
          <View style={styles.sectionflex}>
            <Image src={CheckIcon} style={styles.imageicon} />
          </View>
          <View style={styles.sectionflex}>
            <Image src={CheckIcon} style={styles.imageicon} />
          </View>
          <View style={styles.sectionflex}>
            <Image src={CrossIcon} style={styles.imageicon} />
          </View>
        </View>
        <View style={styles.sectionrow}>
          <Text style={styles.subtext}>Failed</Text>
          <Text style={styles.subtext}>Passed</Text>
          <Text style={styles.subtext}>Passed</Text>
          <Text style={styles.subtext}>Passed</Text>
        </View>
        <View style={styles.subSectionHeading}>
          <Text>Products & Services</Text>
        </View>
        <Text style={styles.subSectionText}>
          {selectedCompany.tradeDescription || "No Products & Services set."}
        </Text>
        {filteredProducts &&
          filteredProducts.map((records) =>
            records?.families.map((item, index) => {
              // eslint-disable-next-line no-plusplus
              counter++;
              if (counter === 7) {
                return (
                  <View key={index} break>
                    <Text style={styles.subSectionText}>{item.name}</Text>
                  </View>
                );
              }
              if (counter < 7 || counter > 7) {
                return (
                  <Text key={index} style={styles.subSectionText}>
                    {item.name}
                  </Text>
                );
              }
              return null;
            })
          )}

        <Image src={Blink} style={styles.imageFooter} fixed />
        <Text
          style={styles.fixedTextTop}
          render={() =>
            "Please note that this B1 LINK document does not constitute a B-BBEE Certificate. B1SA has validated that the B-BBEE certification of this company is compliant with the requirements of the Codes of Good Practice."
          }
          fixed
        />
        <Text
          style={styles.fixedTextMiddle}
          render={() =>
            "B1 LINK is a product offering of B1SA, a proud member of the Signa Group. Visit www.b1link.com"
          }
          fixed
        />
        <Text
          style={styles.fixedTextLast}
          render={() => "For Future information, Kindly contact B1SA on +27 11 455 0033."}
          fixed
        />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};


export default PDF;