import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { getCompanyUnspscSelection } from "../../../http/Configuration/configurationApi";

const useCompanyUnspscSelection = () => {
  const query = useQuery({
    queryKey: [queryKeys.companyUnspscSelection],
    queryFn: getCompanyUnspscSelection,
  })

  return query;
}

export default useCompanyUnspscSelection;