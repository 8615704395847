import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import {
  GetSuppliersByCompanyId as GetUploadedStagingSuppliers,
  UpdateAndResendSupplierInvite,
} from "../../../http/Company/cleanApi";
import { SupplierModel } from "../../../http/Company/Models/SupplierModel";
import FormDialog from "../../../components/Dialogs/FormDialog";
import useFieldValidation from "../../../util/hooks/useFieldValidation";
import InLineSpinner from "../../../components/LoadingSpinners/InlineSpinner";
import ConnectionType from "../../../util/enums/ConnectionType";
import IosSwitchButton from "../../../components/CustomComponents/IosSwitchButton";
import UploadedConnectionsTable from "./UploadedConnectionTable";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const UploadedConnections = () => {
  const [connectionType, setConnectionType] = useState(ConnectionType.Supplier);
  const [showSuppliers, setShowSuppliers] = useState(false);
  const [suppliers, setSuppliers] = useState<Array<SupplierModel>>([] as Array<SupplierModel>);
  const [backdropVisible, setBackdropVisible] = useState<boolean>(true);
  const [showResendInvitationModal, setShowResendInvitationModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierModel>({} as SupplierModel);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [validateField] = useFieldValidation("uploaded-suppliers");


  useEffect(() => {
    const loader = async () => {
      setBackdropVisible(true);

      try {
        const suppliersData = await GetUploadedStagingSuppliers(connectionType, false);
        setSuppliers(suppliersData.data);
        setBackdropVisible(false);
      } catch {
        setBackdropVisible(false);
      }
    };

    loader();
  }, [connectionType, suppliers.length]);

  const submitAndResendSupplierInvite = async () => {
    // validate email address
    if (!emailRegex.test(selectedSupplier.contactEmail)) {
      toast.error("Invalid email address");
      setShowResendInvitationModal(false);
      return;
    }

    // update supplier state in redux
    const newSuppliersState = suppliers.map((obj) => {
      if (obj.tempCompanyId === selectedSupplier.tempCompanyId) {
        return { ...obj, ...selectedSupplier };
      }
      return obj;
    });

    try {
      setShowLoadingSpinner(true);
      await UpdateAndResendSupplierInvite(selectedSupplier);
      setShowLoadingSpinner(false);
      setSuppliers(newSuppliersState);
      setShowResendInvitationModal(false);
    } catch (error) {
      setShowLoadingSpinner(false);
    }
  };


  const invitationDialog = <FormDialog
    title="Update and resend invitation"
    handleClose={() => setShowResendInvitationModal(false)}
    handleSubmit={submitAndResendSupplierInvite}
    submitButtonText="Update and submit invite"
  >
    {showLoadingSpinner ? (
      <InLineSpinner />
    ) : (
      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.registeredName || ""}
              label="Company Registration Name"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  registeredName: ev.target.value,
                }))
              }
              error={
                validateField("Company Registration Name", selectedSupplier?.registeredName)
                  .isInvalid
              }
              helperText={
                validateField("Company Registration Name", selectedSupplier?.registeredName)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.registrationNumber || ""}
              label="Registration Number"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  registrationNumber: ev.target.value,
                }))
              }
              error={
                validateField("Registration Number", selectedSupplier?.registrationNumber)
                  .isInvalid
              }
              helperText={
                validateField("Registration Number", selectedSupplier?.registrationNumber)
                  .validationErrorText
              }
            />
          </Grid>
          {connectionType === ConnectionType.Supplier && (
            <Grid item xs={6}>
              <TextField
                type="text"
                sx={{ width: "100%" }}
                value={selectedSupplier?.clientVendorNumber || ""}
                label="Vendor Number"
                size="small"
                onChange={(ev) =>
                  setSelectedSupplier((prevState) => ({
                    ...prevState,
                    clientVendorNumber: ev.target.value,
                  }))
                }
                error={
                  validateField("Vendor Number", selectedSupplier?.clientVendorNumber)
                    .isInvalid
                }
                helperText={
                  validateField("Vendor Number", selectedSupplier?.clientVendorNumber)
                    .validationErrorText
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactName || ""}
              label="Contact Person Name"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactName: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Name", selectedSupplier?.contactName).isInvalid
              }
              helperText={
                validateField("Contact Person Name", selectedSupplier?.contactName)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactSurname || ""}
              label="Contact Person Surname"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactSurname: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Surname", selectedSupplier?.contactSurname)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Surname", selectedSupplier?.contactSurname)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactPhone || ""}
              label="Contact Person Phone"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactPhone: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Phone", selectedSupplier?.contactPhone)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Phone", selectedSupplier?.contactPhone)
                  .validationErrorText
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              sx={{ width: "100%" }}
              value={selectedSupplier?.contactEmail || ""}
              label="Contact Person Email"
              size="small"
              onChange={(ev) =>
                setSelectedSupplier((prevState) => ({
                  ...prevState,
                  contactEmail: ev.target.value,
                }))
              }
              error={
                validateField("Contact Person Email", selectedSupplier?.contactEmail)
                  .isInvalid
              }
              helperText={
                validateField("Contact Person Email", selectedSupplier?.contactEmail)
                  .validationErrorText
              }
            />
          </Grid>
        </Grid>
      </Box>
    )}
  </FormDialog>;

  const connectionTypeString = showSuppliers ? "Supplier" : "Client";
  return (
    <>
      <LoadingBackdrop showBackdrop={backdropVisible} />
      {showResendInvitationModal && (invitationDialog)}
      <Accordion id="completion" expanded>
        <Box p={3}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Box>
              <Typography variant="h4">{`${connectionTypeString} invitations`}</Typography>
              <Typography variant="body1">
                The companies you&apos;ve extended invitations to join as your <b>{connectionTypeString.toLocaleLowerCase()}s</b> on the platform.
              </Typography>
            </Box>
            <Box>
              <IosSwitchButton
                checkedLabel="Switch to client invitations"
                uncheckedLabel="Switch to supplier invitations"
                onChangeCallback={(showSupplierChecked) => {
                  setShowSuppliers(showSupplierChecked);
                  setConnectionType(showSupplierChecked ? ConnectionType.Supplier : ConnectionType.Client)
                }
                }
              />
            </Box>
          </Box>
          <AccordionDetails>
            <UploadedConnectionsTable connectionType={connectionType}/>
          </AccordionDetails>
        </Box>
      </Accordion>
    </>
  );
};

export default UploadedConnections;
