import React, { KeyboardEvent, useCallback, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { InputBase, Paper } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../../Connect.module.scss";

export interface SearchProps {
  // eslint-disable-next-line no-unused-vars
  searchQuery: (searchText: string) => void;

  // eslint-disable-next-line react/require-default-props
  preventSubmitOnEnter?: boolean;
}

const SearchBar = ({ searchQuery, preventSubmitOnEnter = false }: SearchProps) => {
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isHidden] = useState(true);

  const fireMinimumSearchCharWarning = useCallback(() => {
    Swal.fire({
      title: "Minimum Search Requirement",
      text: "Please refine your search by entering at least 3 characters. You can search by company name, product or service description, supply category, or other company specific details.",
      icon: "warning",
      allowOutsideClick: false,
      allowEnterKey: false,
    });
  }, []);

  const handleChange = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setLocalSearchTerm(value);
  };

  const handleCancel = () => {
    setLocalSearchTerm("");
    searchQuery("");
  };

  const doSearch = () => {
    const searchTerm = localSearchTerm;

    if (searchTerm.length <= 2) {
      fireMinimumSearchCharWarning();
      return;
    }

    searchQuery(searchTerm);
  }

  const supressEnter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter")
      doSearch();
  }


  return (
    <Paper className={styles.paperSearchBox}>
      <form
        onSubmit={(event) => {
          if (preventSubmitOnEnter) supressEnter(event);
        }}
      >
        <div>
          <InputBase
            id="search-bar"
            value={localSearchTerm}
            className="text-main-search"
            onInput={handleChange}
            onKeyDown={handleKeydown}
            placeholder="Search..."
            size="small"
          />

          <IconButton
            type="submit"
            aria-label="search"
            sx={{ minWidth: "50px" }}
            onClick={doSearch}
            className="switch-to-advanced-btn"
          >
            {isHidden ? (
              <ManageSearchIcon style={{ fill: "#435cd2" }} />
            ) : (
              <CloseIcon style={{ fill: "blue" }} onClick={handleCancel} />
            )}
          </IconButton>
        </div>
      </form>
    </Paper>
  );
};

export default SearchBar;
