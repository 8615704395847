import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";

import {
  Chip,
  TextField,
} from "@mui/material";








const TagsInput = ({ ...props }) => {
  const { selectedTags, placeholder, tags, ...other } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [emailValid, setEmailValid] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateEmail = (email) => emailRegex.test(email);


  useEffect(() => {
    setSelectedItem(tags);

  }, [tags]);

  useEffect(() => {
    selectedTags(selectedItem);
  }, [selectedItem, selectedTags]);


  useEffect(() => {
    setSelectedItem(tags);

  }, [tags]);

  const handleKeyDown = (event) => {
    if (event.key === ";") {
      if (validateEmail(event.target.value)) {
        setEmailValid(false);
        const newSelectedItem = [...selectedItem];
        const duplicatedValues = newSelectedItem.indexOf(
          event.target.value.trim()
        );

        if (duplicatedValues !== -1) {
          setInputValue("");
          return;
        }
        if (!event.target.value.replace(/\s/g, "").length) return;

        newSelectedItem.push(event.target.value.trim().replace(";", ""));
        setSelectedItem(newSelectedItem);
        setInputValue("");
      }
    }
    else {
      setEmailValid(true);
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }
  const handleChange = (item) => {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
  };

  const handleInputChange = (event) => {


    setInputValue(event.target.value);

  }
  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({ getInputProps }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
        });
        return (
          <div>
            <TextField
              error={emailValid}
              InputProps={{
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    error={emailValid}
                    label={item}
                    className="chip"
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus,
              }}
              {...other}
              {...inputProps}
            />
          </div>
        );
      }}
    </Downshift>
  );
}

TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};


export default TagsInput;