import { ContactModel } from "../../../http/Company/Models/ContactModel";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import ContactModelProps from "../../../pages/Registration/CustomComponents/Models/contactModelProps";
import ContactType from "../../enums/ContactType";
import useValidation from "./useValidation";

const useCompanyContactValidation = (companyData : CompanyViewModel) => {

  const primaryContact = companyData.companyContacts ?
    companyData.companyContacts.find(x => x.contactTypeId === ContactType.Primary) :
    {} as unknown as ContactModel;



  // validation
  const [validateFields] = useValidation(
    [
      // Contact information
      {
        displayName: "Full name and Surname",
        fieldName: ContactModelProps.name,
        fieldValue: primaryContact?.name,
        required: true,
      },
      // {
      //   displayName: "Email Address",
      //   fieldName: ContactModelProps.email,
      //   fieldValue: primaryContact?.email,
      //   required: true,
      // },
      {
        displayName: "Contact Number",
        fieldName: ContactModelProps.mobile,
        fieldValue: primaryContact?.mobile,
        required: true,
      },      
    ]);
  return validateFields

}

export default useCompanyContactValidation;