import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "./Dashboard.module.scss";
import { AdminDashboardSummaryMessage } from "../../http/Company/Models/AdminDashboardSummary";
import { GetAdminDashboardSummary } from "../../http/Company/adminDashboardApi";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import { ComplianceVerificationTypeEnum } from "../../http/Compliance/ComplianceVerificationTypesEnum";
import BasePage from "../BasePage";

const AdminDashboard = () => {
  const [summaryData, setSummaryData] = useState<AdminDashboardSummaryMessage>();
  const [dataIsLoading, setDataIsLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      try {
        setDataIsLoading(true);
        const data = await GetAdminDashboardSummary();
        setSummaryData(data);
        setDataIsLoading(false);
      } catch {
        setSummaryData({} as AdminDashboardSummaryMessage);
        setDataIsLoading(false);
        toast.error("Something went wrong while attempting to retreive data");
      }
    }

    loader();
  }, [])

  const redTextStyle = {
    color: "red",
    fontStyle: "italic",
  };

  return (
    <BasePage pageHeader="Platform Admin Dashboard">
      <LoadingBackdrop showBackdrop={dataIsLoading} />
      <Grid container sx={{ padding: "20px" }}>
        <Grid xs={12}>
          <table className={styles.table}>
            <tr>
              <th>Total Companies</th>
              <th>Directory Companies</th>
              <th>Manual Companies</th>
              <th>Migrated Companies</th>
              <th>Active Users</th>
              <th>Fully Registered</th>
              <th>Registration in Progress</th>
              <th>Registrations Pending</th>
            </tr>
            <tr>
              <td>{summaryData?.totalCompanies}</td>
              <td>{summaryData?.totalCompanies}</td>
              <td><b style={redTextStyle}>n/a</b></td>
              <th>{summaryData?.migratedCompanies}</th>
              <td>{summaryData?.activeUsers}</td>
              <td>{summaryData?.registeredCompanies}</td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
            </tr>
          </table>
        </Grid>
        <Grid xs={12}>
          <Grid container>
            <Grid xs={2}>
              <table className={styles.table}>
                <tr>
                  <th>South African</th>
                  <th>Foreign</th>
                </tr>
                <tr>
                  <td>{summaryData?.southAfricanCompanies}</td>
                  <td>{summaryData?.foreignCompanies}</td>
                </tr>
              </table>
            </Grid>
            <Grid xs={10}>
              <table className={styles.table}>
                <tr>
                  <th>Total Packages</th>
                  {
                    summaryData?.subscriptionsSummaries.map((ss) =>
                      <th>{ss.name}</th>
                    )
                  }
                </tr>
                <tr>
                  <th>Total</th>
                  {
                    summaryData?.subscriptionsSummaries.map((ss) =>
                      <td>{ss.count}</td>
                    )
                  }
                </tr>
                <tr>
                  <th>Paid</th>
                  {
                    summaryData?.subscriptionsSummaries.map((ss) =>
                      <td>{ss.count}</td>
                    )
                  }
                </tr>
                <tr>
                  <th>Sponsored</th>
                  {
                    summaryData?.subscriptionsSummaries.map(() =>
                      <td><b style={redTextStyle}>n/a</b></td>
                    )
                  }
                </tr>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <table className={styles.table}>
            <tr>
              <th>Valid B-BBEE</th>
              <th>Invalid B-BBEE</th>
              {
                summaryData?.beeClassificationSummaries.map((bc) =>
                  <th>{bc.classificationDescription}</th>
                )
              }
              <th>Expired B-BBEE</th>
              <th>Validation Pending</th>
              <th>Unrated</th>
            </tr>
            <tr>
              <td>{summaryData?.beeDetailSummaries.validatedRecords}</td>
              <td>{summaryData?.beeDetailSummaries.inValidRecords}</td>
              {
                summaryData?.beeClassificationSummaries.map((bc) =>
                  <td>{bc.count}</td>
                )
              }
              <td>{summaryData?.beeDetailSummaries.expiredRecords}</td>
              <td>{summaryData?.beeDetailSummaries.pendingValidationRecords}</td>
              <td>{summaryData?.beeDetailSummaries.unratedCompanies}</td>
            </tr>
          </table>
        </Grid>
        <Grid xs={12}>
          <table className={styles.table}>
            <tr>
              <th>CIPC Valid</th>
              <th>CIPC Invalid</th>
              <th>CIPC Pending</th>
              <th>SARS Valid</th>
              <th>SARS Invalid</th>
              <th>SARS Pending</th>
              <th>Valid TCC</th>
            </tr>
            <tr>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.CIPC)?.passedRecords
                }
              </td>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.CIPC)?.failedRecords
                }
              </td>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.CIPC)?.pendingRecords
                }
              </td>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.SARS)?.passedRecords
                }
              </td>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.SARS)?.failedRecords
                }
              </td>
              <td>
                {
                  summaryData?.complianceSummaries
                    .find(x => x.verificationTypeId === ComplianceVerificationTypeEnum.SARS)?.pendingRecords
                }
              </td>
              <td><b style={redTextStyle}>n/a</b></td>
            </tr>
          </table>
        </Grid>
        <Grid xs={12}>
          <table className={styles.table}>
            <tr>
              <th>Average PP</th>
              <th>Average Profile</th>
              <th>Average Rating</th>
              <th>Total eRFx</th>
              <th>Live eRFx</th>
              <th>Active Bidders</th>
              <th>Awarded Value</th>
            </tr>
            <tr>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
            </tr>
          </table>
        </Grid>
        <Grid xs={12}>
          <table className={styles.table}>
            <tr>
              <th>Payments Declined</th>
              <th>Declined Value</th>
              <th>Upgraded Packages</th>
              <th>Downgraded Packages</th>
              <th>Cancelled Subscriptions</th>
              <th>Connections</th>
            </tr>
            <tr>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
              <td><b style={redTextStyle}>n/a</b></td>
            </tr>
          </table>
        </Grid>
      </Grid>
    </BasePage>

  )
};

export default AdminDashboard;