import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { AgentSearchListModel } from "../../../../http/Redux/Models/AgentSearchListModel";
import CompanyVerificationService from "../../../../http/CompanyVerificationService";
import { SupplierMatchViewModel } from "../../../../http/Redux/Models/SupplierMatchViewModel";
import LoadingBackdrop from "../../../../components/Backdrop/LoadingBackdrop";
import "../../../../components/Connect/SearchResult/SweetAlert.css";

const AcceptMatch = ({ data, supplierRow }: { data: AgentSearchListModel, supplierRow: SupplierMatchViewModel }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accept, setAccepted] = useState(false);
  const [swalPropsChange, setSwalPropsChange] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAcceptMatch = () => {
    setIsLoading(true);
    setOpen(false);
    const handle = async () => {
      await CompanyVerificationService.acceptMatch(supplierRow);
    };

    handle();
    setAccepted(true);
    setIsLoading(false);

  }

  useEffect(() => {

    if (accept) {
      setSwalPropsChange({
        show: true,
        title: "Accepted Match",
        icon: "success",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,

      });
      setAccepted(false);
    }

  }, [accept, open]);

  return (
    <Box>
      <Grid container>
        <Grid xs={4} sx={{ marginRight: 2 }}>
          <IconButton onClick={handleClickOpen}>
            <CheckCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Accept Match
        </DialogTitle>
        <DialogContent>
          <LoadingBackdrop showBackdrop={isLoading} />
          <Box sx={{ padding: 2 }}>
            <Grid xs={12} container>
              <Grid xs={6}>Registered Name</Grid>
              <Grid xs={6}>{data.registeredName}</Grid>
              <Grid xs={6}>Registration No</Grid>
              <Grid xs={6}>{data.registrationNumber}</Grid>
              <Grid xs={6}>Vat No</Grid>
              <Grid xs={6}>{data.vATNumber}</Grid>
              <Grid xs={6}>Trading Name</Grid>
              <Grid xs={6}>{data.tradingName}</Grid>
            </Grid>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleAcceptMatch} autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <SweetAlert2 {...swalPropsChange} />
    </Box>

  )
}

export default AcceptMatch;