import { Box, Button, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import SweetAlert2 from "react-sweetalert2";
import "../../../Connect/SearchResult/SweetAlert.css";
import { SubscriptionVoucherModel } from "../../../../http/Redux/Models/SubscriptionVoucherModel";
import SubscriptionService from "../../../../http/SubscriptionManagement/SubscriptionApi";
import RedemptionType from "../../../../util/enums/RedemptionType";
import styles from "../../../../pages/Configuration/PlatformBase.module.scss";

const AddVoucher = ({ data }: { data: SubscriptionVoucherModel }) => {
  const [editable, setEditable] = useState<SubscriptionVoucherModel>({} as SubscriptionVoucherModel);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const [isAdded, setIsAdded] = useState(0);
  const [isActiveDiscountType, setIsActiveDiscountType] = useState(false);
  const [isActiveForever, setIsActiveForever] = useState(false);
  const [isActiveLimited, setIsActiveLimited] = useState(false);
  const [isActiveOnetime, setIsActiveOnetime] = useState(false);
  const [redemptionType, setRedemptionType] = useState<number>();

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const handleClickSave = () => {
    const addVouchers: SubscriptionVoucherModel = editable;
    addVouchers.modifiedBy = userAccount || null;
    addVouchers.dateModified = new Date();
    addVouchers.redemptionType = redemptionType || 0;
    addVouchers.discountType = Number(isActiveDiscountType);

    const loader = async () => {

      const state = await SubscriptionService.updateVoucher(addVouchers);
      setIsAdded(state);
    };
    loader();
  };

  const handleSwitchOnClickDiscountType = () => {
    if (isActiveDiscountType) {
      setIsActiveDiscountType(false);
    }
    else {
      setIsActiveDiscountType(true);
    }

  };

  const handleSwitchForever = () => {
    if (isActiveForever) {
      setIsActiveForever(false);

    }
    else {
      setIsActiveForever(true);
      setIsActiveOnetime(false);
      setRedemptionType(RedemptionType.Forever)
    }

  }

  const handleSwitchLimited = () => {
    if (isActiveLimited) {
      setIsActiveLimited(false);

    }
    else {
      setIsActiveLimited(true);
      setIsActiveOnetime(false);
      setIsActiveForever(false);
      setRedemptionType(RedemptionType.Limited)
    }

  }

  const handleSwitchOnetime = () => {
    if (isActiveOnetime) {
      setIsActiveOnetime(false);

    }
    else {
      setIsActiveOnetime(true);
      setIsActiveForever(false);
      setRedemptionType(RedemptionType.Onetime)
    }

  }


  useEffect(() => {


    if (editable.id === undefined) {
      setEditable(data);
      if (data.redemptionType === RedemptionType.Forever) {
        setIsActiveForever(true);
        setRedemptionType(RedemptionType.Forever);
      }
      if (data.redemptionType === RedemptionType.Onetime) {
        setIsActiveOnetime(true);
        setRedemptionType(RedemptionType.Onetime);
      }
      if (data.redemptionType === RedemptionType.Limited) {
        setIsActiveLimited(true);
        setRedemptionType(RedemptionType.Limited);
      }
      if (data.discountType === 1) {
        setIsActiveDiscountType(true);
      }

    }



    if (isAdded === 200) {

      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });


    }
    // eslint-disable-next-line max-len
  }, [data.id, editable.id, isAdded, timeVal]);

  return (
    <>
      <Box role="presentation" className="add-panel-box-product">
        <div>
          <TextField value={editable.name}
            onChange={(ev) => { setEditable({ ...editable, name: ev.target.value }); }}
            margin="dense"
            id="name"
            InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
            label="Voucher Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined" />
          <TextField value={editable.code}
            onChange={(ev) => { setEditable({ ...editable, code: ev.target.value }); }}
            margin="dense"
            id="name"
            InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
            label="Code"
            size="small"
            type="text"
            fullWidth
            variant="outlined" />

          <TextField value={editable.discount}
            onChange={(ev) => { setEditable({ ...editable, discount: ev.target.value }); }}
            inputProps={{
              min: 0,
              max: 100,
            }}
            margin="dense"
            id="discount"
            InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
            label="Discount"
            size="small"
            type="number"
            fullWidth
            variant="outlined" />
          <div className={styles.activeValidationSwitchBox}>
            <div className="active-validation-switch-text-left">
              <p>FLAT</p>
            </div>
            <div className="active-validation-switch-middle">
              <Switch checked={isActiveDiscountType} onClick={handleSwitchOnClickDiscountType} size="small" className="switch" />
            </div>
            <div className="active-validation-switch-text-right">
              <p>PCT</p>
            </div>
          </div>
          <div className="date-box">
            <div className="start-date">
              <TextField
                onChange={(ev) => { setEditable({ ...editable, validStart: new Date(ev.target.value) }); }}
                margin="dense"
                id="start"
                InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
                label="Valid Start"
                size="small"
                type="date"
                defaultValue={data.validStart}
                fullWidth
                variant="outlined" />
            </div>
            <div className="end-date">
              <TextField
                onChange={(ev) => { setEditable({ ...editable, validEnd: new Date(ev.target.value) }); }}
                margin="dense"
                id="end"
                InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
                label="Valid End"
                size="small"
                type="date"
                defaultValue="2022-05-24"
                fullWidth
                variant="outlined" />
            </div>
          </div>
          <TextField value={editable.maximumSubscriptionRedemptions}
            onChange={(ev) => { setEditable({ ...editable, maximumSubscriptionRedemptions: Number(ev.target.value) }); }}
            margin="dense"
            id="maximumSubscriptionRedemptions"
            InputLabelProps={{ style: { fontSize: 14 }, shrink: true }}
            label="Maximum Subscription Redemptions"
            size="small"
            type="number"
            fullWidth
            variant="outlined" />
          <div>
            <p>Redemption Type</p>
            <FormControlLabel control={<Switch checked={isActiveOnetime} onClick={handleSwitchOnetime} />} label="One Time" />
            <FormControlLabel control={<Switch checked={isActiveLimited} onClick={handleSwitchLimited} />} label="Limited" />
            <FormControlLabel control={<Switch checked={isActiveForever} onClick={handleSwitchForever} />} label="Forever" />
          </div>

          <SweetAlert2 {...swalPropsChange} />
        </div>
      </Box>

      <div className="btn-box-sub-bottom">
        <Button variant="outlined" className="form-edit-btn-sub" onClick={() => { handleClickSave() }}>Save</Button>
      </div>


    </>
  )
};

export default AddVoucher;