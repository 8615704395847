import { makePatchRequestAsync, makePostRequestAsync } from "../../Helpers/httpRequestHelpers";
import { protectedResources } from "../../../authConfig";
import { permissions } from "./Permissions";

const baseurl = protectedResources.userManagementWebApi.endpoint;
const scopes = protectedResources.userManagementWebApi.scopes;

const UpdatePermission = async (permissionEntry: permissions) => {
  const url = `${baseurl}/api/UserManagement/Permissions`
  const result = await makePatchRequestAsync(scopes, url, permissionEntry)
  return result
}

export const AddPermission = async (permissionEntry: permissions) => {
  const url = `${baseurl}/api/UserManagement/Permissions`
  const result = await makePostRequestAsync(scopes, url, permissionEntry)
  return result
}

export default UpdatePermission;