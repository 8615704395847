/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import "./css/Panels.css";
import TemporaryDrawer from "../../../pages/Compliance/TemporaryDrawer";
import { ModulePermissionSelectionMessage } from "../../../http/CompanyUserManagement/Messages/ModulePermissionSelectionMessage";
import { EditRoleMessage } from "../../../http/CompanyUserManagement/Messages/EditRoleMessage";
import { RoleMessage } from "../../../http/CompanyUserManagement/Messages/RoleMessage";
import { useAppSelector } from "../../../app/hooks";
import { DeleteRoleAsync, UpdateRoleAsync } from "../../../http/CompanyUserManagement/userManagementApi";
import InLineSpinner from "../../LoadingSpinners/InlineSpinner";
import FormDialog from "../../Dialogs/FormDialog";

const EditRoleDrawer = (
  {
    onClose,
    role,
    onDeleteCallback,
  }:
  {
     onClose: () => void,
     role: RoleMessage,
     onDeleteCallback: (roleId: number) => void,// eslint-disable-line no-unused-vars
  }) => {
  const [roleDetails, setRoleDetails] = useState<EditRoleMessage>({} as EditRoleMessage);
  const [isSavingData,setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [permissionSelection, setPermissionSelection] = useState<ModulePermissionSelectionMessage[]>([]);
  const modulePermissions = useAppSelector((redux) => redux.modulePermissions?.modulePermissionsList);

  useEffect(() => {
    // initialize the selected permissions state
    const rolePermissionIds = role.permissions?.map(x => x.id);
    const newPermissionSelection = modulePermissions.map(mp => (
      {
        moduleId: mp.id,
        selectedPermissionIds: mp.permissions.filter((perm) => rolePermissionIds?.includes(perm.id)).map(y => y.id) ?? [],
      }
    ));
    setPermissionSelection(newPermissionSelection);

    setRoleDetails({ ...role, rolePermissions: newPermissionSelection } as unknown as EditRoleMessage);
  }, [modulePermissions, role])

  const onHandlePermissionChange = (event: SelectChangeEvent<number[]>, moduleId: number) => {

    const selectedPermissionIds = event.target.value as unknown as number[];
    setPermissionSelection((prevState) => prevState.map((obj) => {
      if (obj.moduleId === moduleId) {
        return { ...obj, selectedPermissionIds}
      }
      return obj;
    }))

    const newPermissionSelection = modulePermissions.map(mp => (
      {
        moduleId: mp.id,
        selectedPermissionIds: mp.permissions.filter((perm) => selectedPermissionIds?.includes(perm.id)).map(y => y.id) ?? [],
      }
    ));
    setRoleDetails({ ...roleDetails, rolePermissions: newPermissionSelection } as unknown as EditRoleMessage);
  };

  const onSaveHandler = async () => {
    try {
      setIsSaving(true);
      await UpdateRoleAsync(roleDetails);
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
      throw error;
    }
  }

  const onDeleteHandler = async () => {
    try {
      setShowDeleteModal(false)
      setIsSaving(true);
      await DeleteRoleAsync(roleDetails.id);
      onDeleteCallback(role.id);
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
      throw error;
    }
  }

  return (
    <TemporaryDrawer
      showDrawer
      onCloseDrawerCallback={onClose}
    >
      <>
        <Box className="add-panel-close-box">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box role="presentation" className="add-role-panel-box">
          <div className="add-panel-image">
            <BuildCircleIcon className="add-panel-image-icon" />
          </div>
          <h3 className="add-panel-heading">Edit Role : {role?.name}</h3>
          <div className="hr-line" />
          {
            isSavingData ? <InLineSpinner /> :
              <>
                {
                  (!role.isPlatformRole || role.isDefaultSytemRole)  &&
                  <Box>
                    <Stack justifyContent="end" direction="row">
                      <DeleteIcon color="error" onClick={() => { setShowDeleteModal(true); }} />
                    </Stack>
                  </Box>
                }
                <TextField
                  margin="dense"
                  id="rolename"
                  value={roleDetails.name}
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  disabled={role.isPlatformRole || role.isDefaultSytemRole || isSavingData}
                  label="Role Name"
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(ev) => { setRoleDetails({ ...roleDetails, name: ev.target.value }) }}
                />
                <TextField
                  margin="dense"
                  id="description"
                  value={roleDetails.description}
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  disabled={role.isPlatformRole || role.isDefaultSytemRole || isSavingData}
                  label="Description"
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(ev) => { setRoleDetails({ ...roleDetails, description: ev.target.value }) }}
                />


                <div className="hr-line" />
                <br />
                <h6>Role Permissions</h6>
                {modulePermissions.map(module =>
                  <FormControl 
                    disabled={role.isPlatformRole || role.isDefaultSytemRole} 
                    sx={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
                  >
                    <InputLabel sx={{ fontSize: "14px" }} id="demo-multiple-checkbox-dash-label">{module.name}</InputLabel>
                    <Select
                      label={module.name}
                      id="demo-multiple-checkbox-dash"
                      multiple
                      margin="dense"
                      inputProps={{ style: { fontSize: 14 } }}
                      value={permissionSelection?.find((z) => z.moduleId === module.id)?.selectedPermissionIds || [] as Array<number>}
                      size="small"
                      onChange={(ev) => onHandlePermissionChange(ev, module.id)}
                      input={<OutlinedInput label={module.name} />}
                      renderValue={(selected) => module.permissions?.filter((item) => selected.includes(item.id))?.map(x => x.description).join(",")}
                    >
                      {module.permissions.map((permission) => (
                        <MenuItem key={permission.id} value={permission.id}>
                          <Checkbox
                            checked={permissionSelection?.find((z) => z.moduleId === module.id)?.selectedPermissionIds?.includes(permission.id)} />
                          <ListItemText primary={permission.description} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <Box className="add-panel-close-box">
                  <div className="hr-line" />
                  <Button variant="outlined" onClick={() => onSaveHandler()}>Save</Button>
                </Box>
              </>
          }
          {
            showDeleteModal &&
            <FormDialog
              submitButtonText="Proceed"
              closeButtonText="Cancel"
              handleClose={() => setShowDeleteModal(false)}
              handleSubmit={() => onDeleteHandler()}
              title={`DELETE ROLE: ${role.name}`}
              description="Are you sure that you want to delete the following role ?"
            />
          }

        </Box></>
    </TemporaryDrawer>
  );
};

export default EditRoleDrawer;


