/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BeeLevelModel } from "../../Company/Models/BeeLevelModel";

export interface BeeLevelListState {
  beeLevelList: Array<BeeLevelModel>;
}

const beeLevelListSlice = createSlice({
  name: "beeLevelList",
  initialState: [] as Array<BeeLevelModel>,
  reducers: {
    setBeeLevelListState: (
      state,
      action: PayloadAction<Array<BeeLevelModel>>
    ) => action.payload,
  },
});

export const selectBeeLevelList = (state: RootState) => state.beeLevelList;
export default beeLevelListSlice;
