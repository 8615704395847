import { protectedResources } from "../../authConfig";
import { makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { AddressModel } from "./Models/AddressModel";

const baseUrl = protectedResources.companyApi.endpoint;
const apiScopes = protectedResources.companyApi.scopes;

export default async function SaveAddressChanges(address: AddressModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Address/UpdateAddress/${staged}`;

  const response = (await makePostRequestAsync(apiScopes, url, address)).status;
  return response;
}