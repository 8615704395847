/* eslint-disable no-param-reassign */
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Card, CardContent, Box, Grid, Rating, Tabs, Tab, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import BasePage from "./NewUI/BasePage";
import "../util/Styling/BaseStyling.css";
import bufferProfile from "../util/images/filler.png";
import NotificationBar from "../components/Dashboard/NotificationCards";
import Insight from "../components/Dashboard/Insights";
import DashboardSupplierBuyerView from "./NewUI/SupplierBuyerDashboardView";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { CompanyProfileImageModel } from "../http/Redux/Models/CompanyProfileImageModel";
import { selectBaseCompanyInfo } from "../http/Redux/Store/baseCompanyInfoSlice";
import { GetCompanyRatingAsync } from "../http/Company/CompanyRating";
import { fetchCurrentCompanyProfileCompletionSummary } from "../http/Redux/Store/baseCompanyInfoActions";
import {
  getCompletedProfileSectionCount,
  getValidProfileSectionsCount,
} from "../util/functions/profileCompletionHelpers";
import { CompanyProfileCompleteSummaryMessage } from "../http/Company/Messages/companyProfileCompletionSummaryMessage";
import {
  ComplianceStatus,
} from "../http/Compliance/ComplianceStatus";
import { getMyComplianceResults } from "../http/Compliance/complianceApi";
import InLineSpinner from "../components/LoadingSpinners/InlineSpinner";
import { NotificationModel } from "../http/Notifications/Models/NotificationModel";
import { fetchUnread } from "../http/Redux/Store/NotificationActions";
import { getUnreadNotifications } from "../http/Redux/Store/NotificationsSlice";
import LoadingBackdrop from "../components/Backdrop/LoadingBackdrop";
import { responsiveFontSizes } from "../util/MUI_Theme/Theme";
// NEW UI

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Card sx={{ minHeight: "50vh", maxHeight: "60vh", borderTopLeftRadius: 0, overflowY: "hidden" }}>
          <Box sx={{ minHeight: "50vh", maxHeight: "60vh", borderTopLeftRadius: 0, overflowY: "scroll" }}>
            {children}
          </Box>
        </Card>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DashboardHome = () => {
  const { accounts } = useMsal();
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const accountId = accounts[0].homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const dispatch = useAppDispatch();
  const baseCompanyInfo = useAppSelector(selectBaseCompanyInfo);
  const profileImage: CompanyProfileImageModel = useAppSelector(
    (state: {
      companyProfileImage: {
        particularCompanyProfileImage: CompanyProfileImageModel;
      };
    }) => state.companyProfileImage.particularCompanyProfileImage
  );

  const [value, setValue] = useState(0);
  const [companyRating, setCompanyRating] = useState<number>(0);
  const [complianceStatus, setComplianceStatus] = useState<ComplianceStatus | undefined>(undefined);
  const [loadingProfileStats, setLoadingProfileStats] = useState<boolean>(false);
  const unread = useAppSelector(getUnreadNotifications);
  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const [unreadNotifications, setUnreadNotifications] = useState<Array<NotificationModel>>(
    [] as Array<NotificationModel>
  );



  const handleRefresh = (refresh: boolean) => {

    if (refresh) {
      setBackdropVisible(true);
      const refreshNotifications = async () => {

        dispatch(fetchUnread(accountId, false));
        setUnreadNotifications(unread);
        setBackdropVisible(false);

      };
      refreshNotifications();
    }
  }

  const processCompletionPercentage = useCallback(
    (completionSummary: CompanyProfileCompleteSummaryMessage) => {
      // Get the entries, minus bank details, since those are optional.
      const validSectionsCount = getValidProfileSectionsCount(completionSummary);
      const completedSectionsCount = getCompletedProfileSectionCount(completionSummary);
      const overallCompletionPerc = (completedSectionsCount / validSectionsCount) * 100;
      return overallCompletionPerc.toFixed(2);
    },
    []
  );

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchAndSetComplianceRating = useCallback(async () => {
    if (!complianceStatus) {
      let status: ComplianceStatus | undefined;

      try {
        const result = await getMyComplianceResults();
        status = result[0].status || ComplianceStatus.NotApplicable;
      } catch (error) {
        status = ComplianceStatus.NotApplicable;
      }

      switch (status) {
        case ComplianceStatus.Failed:
          setComplianceStatus(ComplianceStatus.Failed);
          break;
        case ComplianceStatus.PendingChecks:
          setComplianceStatus(ComplianceStatus.PendingChecks);
          break;
        case ComplianceStatus.Passed:
          setComplianceStatus(ComplianceStatus.Passed);
          break;
        default:
          setComplianceStatus(ComplianceStatus.NotApplicable);
          break;
      }
    }
  }, [complianceStatus]);

  const fetchAndSetCompanyRating = async (companyId: number) => {
    const companyRatingResult = await GetCompanyRatingAsync(companyId);
    setCompanyRating(companyRatingResult);
  };

  const fetchAndSetProfileCompletionSummary = useCallback(async () => {
    dispatch(fetchCurrentCompanyProfileCompletionSummary);
  }, [dispatch]);


  const fetchProfileStatusData = useCallback(async () => {
    await fetchAndSetProfileCompletionSummary();

    if (baseCompanyInfo.companyId) await fetchAndSetCompanyRating(baseCompanyInfo.companyId);

    await fetchAndSetComplianceRating();

    // set isLoading state to false.
    setLoadingProfileStats(false);
  }, [
    baseCompanyInfo.companyId,
    fetchAndSetComplianceRating,
    fetchAndSetProfileCompletionSummary,
  ]);

  useEffect(() => {
    setLoadingProfileStats(true);
    fetchProfileStatusData();

    setBackdropVisible(true);
    const refreshNotifications = async () => {

      dispatch(fetchUnread(accountId, false));
      setUnreadNotifications(unread);
      setBackdropVisible(false);

    };
    refreshNotifications();
  }, [fetchProfileStatusData, accountId, dispatch, unread.length]);

  return (
    <BasePage>
      <LoadingBackdrop showBackdrop={backdropVisible} />
      <Box className="pageBox">
        <Grid container spacing={1.5} justifyContent="center" alignItems="center">
          <Grid item sm={6} md={2}>
            <div className="companyLogo">
              <img
                src={profileImage.url ? profileImage.url : bufferProfile}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = bufferProfile;
                }}
                alt=""
                style={{ width: "100%", borderRadius: "100%", height: "100%" }}
              />
            </div>
          </Grid>
          <Grid sm={6} md={10} item>
            <Typography sx={{ ...responsiveFontSizes({ lg: 45, md: 40, sm: 28 }), textTransform: "uppercase" }}>
              {baseCompanyInfo.companyRegisteredName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} pt={3}>
          <Grid item xs={12}>
            <Typography sx={{ ...responsiveFontSizes({ lg: 35, md: 32, sm: 28 }) }}>DASHBOARD</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", borderBottomColor: "transparent" }} className="tabs-dashboard">
                <Tabs value={value} onChange={handleChange} aria-label="dashboard stat tabs">
                  <Tab sx={{ minWidth: "33%" }} label="LATEST ACTIVITY" {...a11yProps(0)} />
                  <Tab sx={{ minWidth: "33%" }} label="INSIGHTS" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container>
                  {unreadNotifications.map((notification) => (
                    <NotificationBar
                      notification={notification}
                      handleRefresh={handleRefresh}
                      date={notification.dateCreated as Date}
                      heading={notification.entryName} />
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Insight />
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className="cardMainProfile">
              <CardContent>
                <div className="cardHeader">PROFILE STATUS</div>
                <br />
                {loadingProfileStats ? (
                  <InLineSpinner />
                ) : (
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <b>Completion:</b>
                      {` ${processCompletionPercentage(baseCompanyInfo.profileCompletionSummary)}%`}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <strong style={{ float: "left" }}>My Overall Rating:</strong>
                      <div style={{ paddingLeft: "5px", paddingTop: "1px", float: "left" }} >
                        <Rating value={companyRating} />
                      </div>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <DashboardSupplierBuyerView />
      </Box>
    </BasePage>
  );
};

export default DashboardHome;
