import React from "react";
import { Avatar, Checkbox, Grid, ListItemText, MenuItem, MenuProps, Select, SelectChangeEvent } from "@mui/material";


interface FilterDropdownProps {
  label: string,
  lookupList: Array<{id: number, value: string} | {id: number, name: string}>,
  selectedOptionsList: Array<number> | undefined,
  menuProps: Partial<MenuProps> | undefined,

  // eslint-disable-next-line no-unused-vars
  changeHandleCallback: (event: SelectChangeEvent<number[]>) => void,
}

const FilterDropdown = ({
  label,
  lookupList,
  selectedOptionsList,
  menuProps,
  changeHandleCallback,
}: FilterDropdownProps) => (

  <Select
    displayEmpty
    multiple
    value={selectedOptionsList || ([] as Array<number>)}
    variant="outlined"
    sx={{ width: "93%" }}
    onChange={changeHandleCallback}
    renderValue={(selected) => (
      <Grid container xs={12}>
        <Grid item xs={11}>
          <div>{label}</div>
        </Grid>
        {(selectedOptionsList && selectedOptionsList.length > 0) && (
          <Grid item xs={1}>
            <Avatar sx={{ bgcolor: "#000000", color: "#ffffff", height: 24, width: 24 }}>
              {lookupList?.filter((lookupVal) => selected.includes(lookupVal.id)).length}
            </Avatar>
          </Grid>
        )}
      </Grid>
    )}
    MenuProps={menuProps}
    inputProps={{ "aria-label": "Without label" }}
  >
    {lookupList.map((lookupVal) => (
      <MenuItem key={lookupVal.id} value={lookupVal.id}>
        <Checkbox checked={selectedOptionsList?.includes(lookupVal.id)} />
        <ListItemText primary={("value" in lookupVal ? lookupVal.value : lookupVal.name)} />
      </MenuItem>
    ))}
  </Select>
);

export default FilterDropdown;