import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Slide, StepButton } from "@mui/material";


import { GetStepState, OnBoardingNavigationProps } from "./StepsModel";


const OnBoardingNavigation = ({ currentStep, handleNavigation, steps }: OnBoardingNavigationProps) => {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (activeStep !== currentStep) {
      setActiveStep(currentStep);
    }
  }, [activeStep, currentStep])


  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Slide
            direction="right"
            in
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: "0 0 0" }}
            {...({ timeout: step.timeout })}
          >
            <Step disabled={false} onClick={() => {
              setActiveStep(index);
              handleNavigation(step.description as string, steps.length, index);
            }}>
              <StepButton
                sx={{ fontWeight: 900 }}
                icon={GetStepState(index, step.status as number, step.id as number)}
                key={step.label}>
                {step.label}
              </StepButton>
            </Step>
          </Slide>
        )
        )}
      </Stepper>
    </Box>
  );
};

export default OnBoardingNavigation;
