import { Grid, InputBase, IconButton, styled, Box, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import SweetAlert2 from "react-sweetalert2";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import LiquidGraph from "./LiquidGraph";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import formatDate from "../../../util/formatter/formatDate";
import { AgentTaskStatusTypes, AgentTaskStatusTypesToString } from "../../../util/enums/AgentTaskStatusTypes";
import { AgentTaskAttentionTypes } from "../../../util/enums/AgentTaskAttentionTypes";
import TaskListAbandon from "./TaskListAbandon";
import { UserProfileModel } from "../../../http/Redux/Models/UserProfileModel";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";
import ViewMatch from "./MatchModelViews/ViewTaskMatch";



const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);


const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
)




const TaskList = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [agentTaskList, setAgentTaskList] = useState<AgentTaskListGridModel[]>([] as AgentTaskListGridModel[]);
  const [isLoading, setIsLoading] = useState(false);
  const user: UserProfileModel = useAppSelector(getUserProfile);
  const [swalPropsChangeSearch, setSwalPropsChangeSearch] = useState({});
  const [totalAssignedTask, setTotalAssignedTask] = useState(0);
  const [totalInpogress, setTotalInpogress] = useState(0);
  const [totalHighPriority, setTotalHighPriority] = useState(0);
  const [totalMediumPriority, setTotalMediumPriority] = useState(0);
  const [unmatchedSuppliers, setUnmatchedSuppliers] = useState(0);
  const [viewMatch, setviewMatch] = useState(false);
  const [selectedUploadMatch, setSelectedUploadMatch] = useState<string>("");
  const [selectedClientId, setSelectedClientId] = useState(0);

  const handleAbandoned = (rowId: number) => {

    const filteredRows = agentTaskList.filter(
      (row) => !(row.agentTaskId === rowId)
    );
    setAgentTaskList(filteredRows);
    setIsLoading(false);
    toast.success("Returned to Pool");
  }


  interface ViewCellProps {
    row: AgentTaskListGridModel;
    stage: boolean;
  }


  // eslint-disable-next-line react/no-unstable-nested-components
  const ViewCell: React.FC<ViewCellProps> = ({ row, stage }) => {
    const navigate = useNavigate();

    const redirectToStagedView = (clientId: number, supplierId: number, status: number, staged: boolean, agentTaskId: number) => {
      const template = `/workcentre/companyProfile/${clientId}/${supplierId}/${status}/${staged}/${agentTaskId}`;
      navigate(template);
    };

    const setViewMatchProps = () => {
      setSelectedClientId(row.clientId);
      setviewMatch(true);
      setSelectedUploadMatch(row.registeredName);
    }


    return (
      <div>
        {row.pageSource === PageSourceTypes.DataMgmt && (
          <Tooltip title="View Company Details">
            <IconButton size="small" onClick={() => { redirectToStagedView(row.clientId, row.companyId, row.statusId, stage, row.agentTaskId) }}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        )}
        {row.pageSource === PageSourceTypes.MatchTable && (
          <Tooltip title="View Match & Clean">
            <IconButton size="small" onClick={setViewMatchProps}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        )}
        <TaskListAbandon data={row} handleAbandon={handleAbandoned} />

      </div>

    );

  }

  const columns: GridColDef[] = [
    {
      field: "dataSource",
      headerName: "Source",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.pageSource === PageSourceTypes.DataMgmt ? "Supplier Management" : "Match & Clean"}
        </div>
      ),
    },
    {
      field: "client",
      headerName: "Client Registered Name",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 220,

    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 220,

    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      sortable: false,
      flex: 1,
      minWidth: 190,
    },
    {
      field: "statusId",
      headerName: "Status",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div>
          {params.value === AgentTaskStatusTypes.Assigned ? "Not Started" : AgentTaskStatusTypesToString(params.value)}
        </div>
      ),
    },
    {
      field: "certificateStatus",
      headerName: "Certificate Status",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "certificateStatusPeriod",
      headerName: "Certificate Status (Period)",
      flex: 1,
      minWidth: 190,
    },
    {
      field: "certificateExpiry",
      headerName: "Certificate Expiry",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "ratingStatus",
      headerName: "Rating Status",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "requiresAttention",
      headerName: "Attention Required",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "daysSinceValidation",
      headerName: "Since Validation",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.value === null ? "No Validation" : params.value}
        </div>
      ),
    },
    {
      field: "daysSinceAssigned",
      headerName: "Since Assigned",
      flex: 1,
      minWidth: 180,
    },

    {
      field: "dateCreated",
      headerName: "Date Assigned",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          {formatDate(params.value)}
        </div>
      ),

    },
    {
      field: "dateModified",
      headerName: "Date Modified",
      headerClassName: "custom-header",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          {formatDate(params.value) === "1970-01-01" ? "" : formatDate(params.value)}
        </div>
      ),

    },
    {
      field: "companyId",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell row={params.row as AgentTaskListGridModel} stage={false} />
      ),
    },



  ];

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      const data = await CompanyVerificationService.getAgentTaskList(user.id as number);
      setTotalAssignedTask(data.length);
      setTotalInpogress(data.filter(x => x.statusId === AgentTaskStatusTypes.InProgress).length);
      setTotalHighPriority(data.filter(x => x.requiresAttentionId === AgentTaskAttentionTypes.Urgent).length);
      setTotalMediumPriority(data.filter(x => x.requiresAttentionId === AgentTaskAttentionTypes.Moderate).length);
      setUnmatchedSuppliers(data.filter(x => x.pageSource === 2).length);
      setAgentTaskList(data);
      setIsLoading(false);
    };

    loader();
  }, [viewMatch, selectedUploadMatch, selectedClientId]);

  const searchQuery = async (searchText: string) => {
    setSearchTerm(searchText);

    if (searchText.length >= 3) {
      setIsLoading(true);
      const data = await CompanyVerificationService.getAgentTaskList(user.id as number);
      setAgentTaskList(data);
      setIsLoading(false);

    }
    else {
      setSwalPropsChangeSearch({
        show: true,
        title: "Minimum of 3 Characters",
        icon: "warning",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,

      });
    }
  }

  const onBackClick = () => {
    setviewMatch(false);
    setSelectedUploadMatch("");
    setSelectedClientId(0);
  }

  return (
    <Grid container>
      <LoadingBackdrop showBackdrop={isLoading} />

      {viewMatch ? (
        <Grid xs={12}>
          <Box>
            <ViewMatch clientId={selectedClientId} back={onBackClick} selectedRegisteredName={selectedUploadMatch} />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid xs={6}>
            <Grid container sx={{ width: "100%", marginBottom: 2 }}>
              <Grid xs={2} sx={{ marginBottom: 1 }}>
                <LiquidGraph Number={totalAssignedTask} />
              </Grid>
              <Grid xs={4} sx={{ marginTop: 1 }}>Assigned tasks</Grid>
              <Grid xs={2}>
                <LiquidGraph Number={totalInpogress} />
              </Grid>
              <Grid xs={4} sx={{ marginTop: 1 }}>In Progress</Grid>
              <Grid xs={2} sx={{ marginBottom: 1 }}>
                <LiquidGraph Number={totalHighPriority} />
              </Grid>
              <Grid xs={4} sx={{ marginTop: 1 }}>High priority</Grid>
              <Grid xs={2}>
                <LiquidGraph Number={totalMediumPriority} />
              </Grid>
              <Grid xs={4} sx={{ marginTop: 1 }}>Medium Priority</Grid>
              <Grid xs={2} sx={{ marginBottom: 1 }}>
                <LiquidGraph Number={unmatchedSuppliers} />
              </Grid>
              <Grid xs={4} sx={{ marginTop: 1 }}>Unmatched Suppliers </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} />
          <Grid xs={12}>
            <div className="agent-list-box-small">
              <div className="agent-list-search-switch">

                <div className="search-components-wrap" style={{ border: "1px solid rgba(0, 0, 0, 0.116)", borderRadius: "3px" }}>
                  <InputBase
                    id="search-bar"

                    value={searchTerm}
                    className="text-main-search"
                    onInput={(ev: any) => setSearchTerm(ev.target.value)}
                    sx={{ width: "96%", border: "0px" }}
                    placeholder="Search..."
                    size="small"
                  />

                  <IconButton
                    type="submit"
                    aria-label="search"
                    sx={{ width: "4%" }}
                    onClick={() => searchQuery(searchTerm)}
                    className="switch-to-advanced-btn">

                    <SearchIcon style={{ fill: "#435cd2" }} />
                  </IconButton>
                </div>

              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGridPro
                rows={agentTaskList ?? []}
                columns={columns}
                disableRowSelectionOnClick
                pagination
                slots={{
                  toolbar: CustomToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row: AgentTaskListGridModel) => row.agentTaskId} />
            </div>
          </Grid>
        </>
      )}
      <SweetAlert2 {...swalPropsChangeSearch} />
    </Grid >
  )
};

export default TaskList;