import React from "react";
import { Grid } from "@mui/material";
import CompanyComplianceSummarySectionModel from "./ModelsAndMappings/CompanyComplianceSummarySectionModel";
import formatDate from "../../../util/formatter/formatDate";

interface ComplianceSummaryRowProps {
  complianceTypeLabel: string;
  summarySectionModel: CompanyComplianceSummarySectionModel;
}

const ComplianceSummaryRow = ({
  complianceTypeLabel,
  summarySectionModel,
}: ComplianceSummaryRowProps) => (
  <Grid container sx={{ borderBottom: "1px solid #F0F0F1" }}>
    <Grid xs={3} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
      {complianceTypeLabel}
    </Grid>
    <Grid xs={3} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
      {summarySectionModel.documentAttached || "N/A"}
    </Grid>
    <Grid xs={2} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
      {summarySectionModel.issueDate ? formatDate(summarySectionModel.issueDate.toDate()) : "N/A"}
    </Grid>
    <Grid xs={2} item sx={{ borderRight: "1px solid #F0F0F1", padding: "10px" }}>
      {summarySectionModel.expiryDate ? formatDate(summarySectionModel.expiryDate.toDate()) : "N/A"}
    </Grid>
    <Grid xs={2} item sx={{ padding: "10px" }}>
      {summarySectionModel.status || "N/A"}
    </Grid>
  </Grid>
);

export default ComplianceSummaryRow;
