import { ValidationModel } from "./Configuration/Validation/ValidationModel";
import { protectedResources } from "../authConfig";
import makeGetRequestAsync from "../http/Helpers/httpRequestHelpers";




const apiScopes = protectedResources.configurationWebApi.scopes;
const baseUrl = protectedResources.configurationWebApi.endpoint;


export default {
  async getAllValidations() {
    const url = `${baseUrl}/api/Configuration/Validation/GetAllValidation`;
    return (await makeGetRequestAsync<ValidationModel[]>(apiScopes, url)).data;
  },
  async getParticularValidation(id: number) {
    const url = `${baseUrl}/api/Configuration/Validation/GetSingleValidation/${id}`;
    return (await makeGetRequestAsync<ValidationModel>(apiScopes, url)).data;
  },

}