import { ReactNode, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import useAllowedPermission from "../util/hooks/useAllowedPermission";
import PermissionClassification from "../util/enums/PermissionClassification";
import FormDialog from "./Dialogs/FormDialog";

const WithRequiredPermission = (
  {
    children,
    permission,
    hideIfMissingPermission = false,
    useFlex = false,
  }: {
  children: ReactNode,
  permission: PermissionClassification
  hideIfMissingPermission?: boolean, // eslint-disable-line react/require-default-props
  useFlex?: boolean,// eslint-disable-line react/require-default-props
}
) => {
  const hasPermission = useAllowedPermission();
  const [showErroDialog, setShowErroDialog] = useState(false);

  const activeStyling : SxProps<Theme> = {
    opacity: 1,
    display: useFlex ? "flex" : "inline",
  }

  const disableStyling: SxProps<Theme> = {
    opacity: 0.5,
    display: useFlex ? "flex" : "inline",
    pointerEvents: "none",
  }
  const hideComponent = hideIfMissingPermission && !hasPermission(permission);
  const renderResult =
      hasPermission(permission) ?
        <Box
          component="div"
          sx={activeStyling}>
          {children}
        </Box>
        :
        <Box
          onClick={() => {setShowErroDialog(true)}}
          component="div">
          <Box
            component="div"
            sx={disableStyling}>
            {children}
          </Box>
        </Box>
  return(
    <Box>
      {!hideComponent &&
      <>
        {showErroDialog &&
          <FormDialog
            title="ACTION NOT ALLOWED"
            description="You do not have the required permission(s) to perform this action.Please contact your system administrator for more details"
            handleClose={() => { setShowErroDialog(false) }}
            handleSubmit={() => { setShowErroDialog(false) }}
            maxWidth="sm"
          />}
        {renderResult}
      </>
      }
    </Box>
  )
}

export default WithRequiredPermission;