import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";

interface YesNoRadioButtonProps {
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  valueCondition: "yes" | "no";
  // eslint-disable-next-line no-unused-vars
  onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const YesNoRadioButton = ({ required, disabled, title, valueCondition, onToggle }: YesNoRadioButtonProps) => (
  <FormControl disabled={disabled}>
    <div className="text-label-box">
      {required && <span className="text-label-astrid">*</span>}
      {title}
    </div>
    <RadioGroup

      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={valueCondition}
      onChange={onToggle}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
);

export default YesNoRadioButton;
