import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import FieldSlice from "./FieldSlice"
import { RootState } from "../../../app/store"
import { FieldModel } from "../Models/FieldModel";
import FieldService from "../../FieldService";

export const fieldActions = FieldSlice.actions

export const fetchFields = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: FieldModel[] = await FieldService.getAllFields();
  dispatch(fieldActions.setField(response))
}
export const fetchParticularField = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: FieldModel = await FieldService.getParticularField(id);
  dispatch(fieldActions.setParticularField(response))
}