/* eslint-disable react/no-unused-prop-types */
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { BeeDetailsModel } from "../../../../http/Company/Models/BeeDetailsModel";
import { BeeLevelModel } from "../../../../http/Company/Models/BeeLevelModel";
import { SectorModel } from "../../../../http/Company/Models/SectorModel";
import { BBEIcon } from "../../../../util/MUI_Theme/Icons";
import TextField from "../Controls/TextField";
import DropdownField from "../Controls/DropdownField";
import DatePicker from "../Controls/DatePicker";
import { TurnoverModel } from "../../../../http/Company/Models/TurnoverModel";
import { GetSectorTurnoverBands, GetValidBeeLevels } from "../../../../http/Company/beeDetailsApi";
import LoadingFieldSpinner from "../Controls/LoadingFieldSpinner";
import formatDate from "../../../../util/formatter/formatDate";

interface BeeDetailsProps {
  beeDetailsModel: BeeDetailsModel;
  sectorsList: Array<SectorModel>;
  levelsList: Array<BeeLevelModel>;
  sectionEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  updateSectionEnabled: (fieldName: string, value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateDataModel: (updatedModel: BeeDetailsModel) => void;
}

const BEE_ENABLED_FIELD_NAME = "hasBeeEnabled";

const BeeInfo = ({
  beeDetailsModel,
  sectorsList,
  levelsList,
  sectionEnabled,
  updateSectionEnabled,
  updateDataModel,
}: BeeDetailsProps) => {
  const DEFAULT_OBJECT: BeeDetailsModel = useMemo(
    () => ({
      id: beeDetailsModel.id || 0,
      sectorId: 0,
      annualTurnover: 0,
      boPercentage: 0,
      bwoPercentage: 0,
      certificateLevelId: 0,
      certificateExpiryDate: null,
      isDeleted: false,
      companyId: beeDetailsModel.companyId,
      isCurrent: true,
      validationStatus: 0,
      validationDateTime: null,
      hasBEECertificate: null,
      certificateDocId: null,
      ratingSourceId: null,
      verificationAgencyId: null,
      vas: null,
      localServiceProvider: null,
      localGoodsManufacturer: null,
      isBlackOwned: null,
      isBlackWomanOwned: null,
      isFlowThrough: null,
      blackDesignatedGroup: null,
      blackYouth: null,
      blackDisabled: null,
      blackUnemployed: null,
      blackRural: null,
      blackMilitaryVet: null,
      farmWorkers: null,
      legacyBDG: null,
      certificateIssueDate: null,
    }),
    [beeDetailsModel.companyId, beeDetailsModel.id]
  );

  const [certIssueDate, setCertIssueDate] = useState<Dayjs | null>(null);
  const [certExpiryDate, setCertExpiryDate] = useState<Dayjs | null>(null);
  const [turnoverBands, setTurnoverBands] = useState<Array<TurnoverModel>>(
    [] as Array<TurnoverModel>
  );
  const [validBeeLevelsList, setValidBeeLevelsList] = useState<Array<BeeLevelModel>>(
    [] as Array<BeeLevelModel>
  );

  // Loading state
  const [turnoverBandsLoading, setTurnoverBandsLoading] = useState<boolean>(false);
  const [beeLevelsLoading, setBeeLevelsLoading] = useState<boolean>(false);

  const handleBeeRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      updateSectionEnabled(BEE_ENABLED_FIELD_NAME, false);
      updateDataModel(DEFAULT_OBJECT);

      setCertIssueDate(null);
      setCertExpiryDate(null);
      setTurnoverBands([] as Array<TurnoverModel>);
      setValidBeeLevelsList([] as Array<BeeLevelModel>);
      return;
    }

    updateSectionEnabled(BEE_ENABLED_FIELD_NAME, true);
  };

  const handleBeeIssueDateValueChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) return;

    const newVal = {
      ...beeDetailsModel,
      certificateIssueDate: formatDate(selectedDate.toDate().toString()),
    } as BeeDetailsModel;
    setCertIssueDate(selectedDate);
    updateDataModel(newVal);
  };

  const handleBeeExpiryDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      setCertExpiryDate(null);
      return;
    }

    const newVal = {
      ...beeDetailsModel,
      certificateExpiryDate: formatDate(selectedDate.toDate().toString()),
    } as BeeDetailsModel;
    setCertExpiryDate(selectedDate);
    updateDataModel(newVal);
  };

  const percentageChecker = (value: string, min = 0, max = 100) => {
    if (+value < min) return min.toString();

    if (+value > max) return max.toString();

    return value.toString();
  };

  const handleNumericValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    let { value } = event.target;

    value = percentageChecker(value);

    if (value.startsWith("0") && value.length > 1) value = value.substring(1);

    if (!value) value = "0";

    let newVal;

    const decimalRegex = /^(\d+(\.\d*)?)?$/;
    if (decimalRegex.test(value)) {
      newVal = { ...beeDetailsModel, [propName]: value };
      updateDataModel(newVal);
    }
  };

  const getTurnoverBandsForSector = useCallback(async (sectorIdVal: number) => {
    try {
      setTurnoverBandsLoading(true);

      if (!sectorIdVal) {
        setTurnoverBands([] as Array<TurnoverModel>);
        return;
      }

      const turnoverBandApiResults = await GetSectorTurnoverBands(sectorIdVal);
      setTurnoverBands(turnoverBandApiResults.data);
    } finally {
      setTurnoverBandsLoading(false);
    }
  }, []);

  const handleSectorChange = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const eventVal = Number(event.target.value);

    if (eventVal === 0) return;

    await getTurnoverBandsForSector(eventVal);
    updateDataModel({
      ...beeDetailsModel,
      sectorId: eventVal,
      annualTurnover: 0,
      certificateLevelId: 0,
    });
  };

  const processValidBeeLevels = useCallback(
    async (selectedSectorId: number, selectedTurnoverBandId: number, setBoPercentage: number) => {
      try {
        if (!selectedSectorId || !selectedTurnoverBandId)
          setValidBeeLevelsList([] as Array<BeeLevelModel>);

        if (setBoPercentage === null || setBoPercentage === undefined || setBoPercentage < 0)
          return;

        setBeeLevelsLoading(true);

        const validBeeLevels = await GetValidBeeLevels(
          selectedSectorId,
          selectedTurnoverBandId,
          setBoPercentage || 0
        );

        const currentLevels = [...levelsList];

        // Take the beeLevels object and filter here, returning the MenuItems.
        const validLevels: Array<BeeLevelModel> = currentLevels.filter((level) =>
          validBeeLevels.includes(level.id)
        );

        setValidBeeLevelsList(validLevels);
      } catch (error) {
        setValidBeeLevelsList([] as Array<BeeLevelModel>);
      } finally {
        setBeeLevelsLoading(false);
      }
    },
    [levelsList]
  );

  const evaluateBaseBeeData = useCallback(
    (beeData: BeeDetailsModel) => {
      const localCopy = { ...beeData };
      const selectedSector = localCopy.sectorId;
      const selectedAnnualTurnoverBand = localCopy.annualTurnover;
      const boPercentage = localCopy.boPercentage || 0;

      if (!selectedSector || !selectedAnnualTurnoverBand) return;

      processValidBeeLevels(selectedSector, selectedAnnualTurnoverBand, boPercentage);
    },
    [processValidBeeLevels]
  );

  useEffect(() => {
    setCertExpiryDate(dayjs(beeDetailsModel.certificateExpiryDate));
    setCertIssueDate(dayjs(beeDetailsModel.certificateIssueDate));

    if (beeDetailsModel.sectorId !== 0) {
      getTurnoverBandsForSector(beeDetailsModel.sectorId);
    }
  }, [
    beeDetailsModel.certificateExpiryDate,
    beeDetailsModel.certificateIssueDate,
    beeDetailsModel.id,
    beeDetailsModel.sectorId,
    getTurnoverBandsForSector,
  ]);

  useEffect(() => {
    evaluateBaseBeeData(beeDetailsModel);
  }, [beeDetailsModel.sectorId, beeDetailsModel.annualTurnover, evaluateBaseBeeData]);



  return (

    <Grid container sx={{ marginLeft: "20px", marginTop: "20px" }}>
      <Grid item xs={12} className="profile-view-labels">
        <BBEIcon /> B-BBEE STATUS
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <div className="text-label-box">
            <span className="text-label-astrid">*</span>
            Does company have B-BBEE Certificate/Affidavit?
          </div>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={sectionEnabled ? "yes" : "no"}
            onChange={handleBeeRadioBtnChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disableFuture
            disabled={!sectionEnabled}
            value={certIssueDate}
            title="Issue Date of Certificate"
            width="90%"
            setDatePicker={handleBeeIssueDateValueChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disablePast
            disabled={!sectionEnabled}
            value={certExpiryDate}
            title="Expiry Date of Certificate"
            width="93%"
            setDatePicker={handleBeeExpiryDateChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DropdownField
            required
            label="B-BBEE Sector"
            disabled={!sectionEnabled}
            value={beeDetailsModel.sectorId || 0}
            onChange={(e) => {
              e.preventDefault();
              handleSectorChange(e);
              const newVal = {
                ...beeDetailsModel,
                sectorId: Number(e.target.value),
              } as BeeDetailsModel;
              updateDataModel(newVal);
            }}
          >
            <MenuItem key={0} value={0} disabled>
              Please Select...
            </MenuItem>
            {sectorsList.map((sector) => (
              <MenuItem key={sector.id} value={sector.id}>
                {sector.value}
              </MenuItem>
            ))}
          </DropdownField>
        </Grid>
        {turnoverBandsLoading ? (
          <LoadingFieldSpinner required title="Annual Turnover" />
        ) : (
          <Grid xs={12} md={4}>
            <DropdownField
              required
              label="Annual Turnover"
              disabled={!sectionEnabled}
              value={beeDetailsModel.annualTurnover || 0}
              onChange={(e) => {
                const newVal = {
                  ...beeDetailsModel,
                  annualTurnover: Number(e.target.value),
                } as BeeDetailsModel;
                updateDataModel(newVal);
              }}
            >
              <MenuItem key={0} value={0} disabled>
                Please Select...
              </MenuItem>
              {turnoverBands.map((band) => (
                <MenuItem
                  key={band.id}
                  value={band.id}
                >{`${band.beeClassificationName} | ${band.name}`}</MenuItem>
              ))}
            </DropdownField>
          </Grid>
        )}
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            inputProps={{
              pattern: "[0-9]{3}.?[0-9]{2}",
              inputMode: "decimal",
              maxLength: 6,
            }}
            label="Black-Owned Percentage"
            value={beeDetailsModel?.boPercentage || 0}
            error={beeDetailsModel?.boPercentage < 0.0 || beeDetailsModel?.boPercentage > 100.0}
            helperText={
              (beeDetailsModel?.boPercentage < 0.0 &&
                "Value must be greater than or equal to 0.") ||
              (beeDetailsModel?.boPercentage > 100.0 && "Value must be less than or equal to 100.")
            }
            onChange={(e) => {
              handleNumericValueChange(e, "boPercentage");
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            inputProps={{
              pattern: "[0-9]{3}.?[0-9]{2}",
              inputMode: "decimal",
              maxLength: 6,
            }}
            label="Black Women-Owned Percentage"
            value={beeDetailsModel?.bwoPercentage || 0}
            error={
              beeDetailsModel?.bwoPercentage < 0.0 ||
              beeDetailsModel?.bwoPercentage > 100 ||
              beeDetailsModel?.bwoPercentage > beeDetailsModel?.boPercentage
            }
            helperText={
              (beeDetailsModel?.bwoPercentage < 0.0 &&
                "Value must be greater than or equal to 0.") ||
              (beeDetailsModel?.bwoPercentage > 100 &&
                "Value must be less than or equal to 100.") ||
              (beeDetailsModel?.bwoPercentage > beeDetailsModel?.boPercentage &&
                "Black Women Owned Percentage cannot exceed Black Owned Percentage.")
            }
            onChange={(e) => {
              handleNumericValueChange(e, "bwoPercentage");
            }}
          />
        </Grid>

        {beeLevelsLoading ? (
          <LoadingFieldSpinner required title="B-BBEE Level" />
        ) : (
          <Grid xs={12} md={4}>
            <DropdownField
              required
              label="B-BBEE Level"
              disabled={!sectionEnabled}
              value={beeDetailsModel?.certificateLevelId || 0}
              onChange={(e) => {
                const newVal = {
                  ...beeDetailsModel,
                  certificateLevelId: Number(e.target.value),
                } as BeeDetailsModel;
                updateDataModel(newVal);
              }}
            >
              <MenuItem key={0} value={0} disabled>
                Please Select...
              </MenuItem>
              {validBeeLevelsList.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.value}
                </MenuItem>
              ))}
            </DropdownField>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BeeInfo;
