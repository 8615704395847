import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";

export interface UnsavedDialogProps {
  openDialog: boolean;
  onCloseOrCancel: () => void;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
}

const UnsavedChangeDialog = ({
  openDialog,
  onCloseOrCancel,
  onDiscardChanges,
  onSaveChanges,
}: UnsavedDialogProps) => (
  <Dialog
    open={openDialog}
    onClose={(_event, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      onCloseOrCancel();
    }}
  >
    <DialogTitle>Unsaved Changes Detected</DialogTitle>
    <DialogContent>
      <DialogContentText id="unsaved-changes-notification">
        There are unsaved changes in this section that will be lost if you leave.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" className="modal-btn-cancel" onClick={onCloseOrCancel}>Cancel</Button>
      <Button variant="contained" className="modal-btn-discard" endIcon={<DeleteOutlineIcon />} onClick={onDiscardChanges}>Discard Changes</Button>
      <Button variant="contained" className="modal-btn-save" endIcon={<SaveIcon />} onClick={onSaveChanges}>Save Changes</Button>
    </DialogActions>
  </Dialog>
);

export default UnsavedChangeDialog;
