import {
  // Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  styled,
  Box,
} from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import DownloadIcon from "@mui/icons-material/Download";
import { DocumentDtoModel } from "../../../../http/DocumentManagement/Models/DocumentDtoModel";

import "./Documents.css";


interface DocumentUploaderProps {
  filesList: Array<DocumentDtoModel>;

  // eslint-disable-next-line no-unused-vars
  dropzoneOnChange: (filesToUpload: Array<File>) => void;

  // eslint-disable-next-line no-unused-vars
  docRowSaveOnClick: (url: string, fileName: string) => void;

  // eslint-disable-next-line no-unused-vars
  showBackdrop: (display: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  setDocument: (doc: DocumentDtoModel) => void;

  // eslint-disable-next-line no-unused-vars
  showDeleteDialog: (displayDeleteDialog: boolean) => void;
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const DocumentUploader = ({
  filesList,
  dropzoneOnChange,
  docRowSaveOnClick,
  showBackdrop,
  setDocument,
  showDeleteDialog,
}: DocumentUploaderProps) => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };




  const HandleFileUpload = (filesToUpload: Array<File>) => {
    if (filesToUpload.length === 0) return;

    showBackdrop(true);
    dropzoneOnChange(filesToUpload);
    setOpen(false);
  };

  const GetRandomNumber = (max: number) => Math.floor(Math.random() * max);

  return (
    <Accordion expanded className="Accord-Hide" id="step-document">
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">Documents</h3>
        </Typography>


      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
            <div>
              <Button variant="contained" onClick={handleClickOpen}>
                Upload Document
              </Button>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                  Upload Document
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Box sx={{ width: "300px" }}>
                    <DropzoneArea
                      filesLimit={1}
                      showAlerts={false}
                      dropzoneText="Drop File"
                      showPreviews={false}
                      showFileNames
                      showPreviewsInDropzone={false}
                      onChange={(files) => {
                        HandleFileUpload(files);
                      }}
                    />
                  </Box>

                </DialogContent>
              </BootstrapDialog>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table className="doc-table">
                <TableHead className="doc-table-head">
                  <TableRow>
                    <TableCell className="doc-table-heading-cell" />
                    <TableCell
                      className="doc-table-heading-cell"
                      sx={{ fontSize: "15px !important" }}
                      align="center"
                    >
                      Document Type
                    </TableCell>
                    <TableCell
                      className="doc-table-heading-cell"
                      sx={{ fontSize: "15px !important" }}
                      align="center"
                    >
                      Document File Name
                    </TableCell>
                    <TableCell
                      className="doc-table-heading-cell"
                      sx={{ fontSize: "15px !important" }}
                      align="center"
                    >
                      Expiry Date
                    </TableCell>
                    <TableCell
                      className="doc-table-heading-cell"
                      sx={{ fontSize: "14px !important" }}
                      align="center"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filesList
                    .filter((file) => !file.nonStandardUpload)
                    .map((file) => (
                      <TableRow
                        key={
                          file.id === "00000000-0000-0000-0000-000000000000"
                            ? GetRandomNumber(10000000)
                            : file.id
                        }
                      >
                        <TableCell className="doc-table-content-cell" align="center">
                          {file.id === "00000000-0000-0000-0000-000000000000" && (
                            <Tooltip title="Document not uploaded">
                              <WarningIcon sx={{ color: "#ff0000" }} />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          className="doc-table-content-cell"
                          align="left"
                          sx={{ fontSize: "13px; !important" }}
                        >
                          {file.documentTypeName}
                        </TableCell>
                        <TableCell
                          className="doc-table-content-cell"
                          align="center"
                          sx={{ fontSize: "13px; !important" }}
                        >
                          {file.fileName}
                        </TableCell>
                        <TableCell
                          className="doc-table-content-cell"
                          align="center"
                          sx={{ fontSize: "13px; !important" }}
                        >
                          {file.expiryDate ? `${file.expiryDate.toString().split("T")[0]}` : "N/A"}
                        </TableCell>
                        <TableCell className="doc-table-content-cell">
                          <Grid container xs={12} justifyContent="center">
                            <Grid item xs={3}>
                              <Tooltip title="Download">
                                <IconButton
                                  id="download-file-button"
                                  disabled={!file.companyId}
                                  onClick={() => docRowSaveOnClick(file.documentUrl, file.fileName)}
                                >
                                  <DownloadIcon className="doc-table-content-icon" />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                              <Tooltip title="Delete File">
                                <IconButton
                                  id="delete-file-button"
                                  disabled={!file.companyId}
                                  onClick={() => {
                                    setDocument(file);
                                    showDeleteDialog(true);
                                  }}
                                >
                                  <DeleteIcon className="doc-table-content-icon" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DocumentUploader;
