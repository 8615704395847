const CompanyViewModelProps = {
  holdingCompanyName: "holdingCompanyName",
  registrationNumber: "registrationNumber",
  vatNumber: "vatNumber",
  registeredName: "registeredName",
  tradingName: "tradingName",
  tradeDescription: "tradeDescription",
  companyTypeId: "companyTypeId",
  incomeTaxNumber: "incomeTaxNumber",
  taxClearanceNumber: "taxClearanceNumber",
  countryId: "countryId",
  city: "city",
};

export default CompanyViewModelProps;