/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Container, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";


interface Base {
  // eslint-disable-next-line react/require-default-props
  pageHeader?: string,
  // eslint-disable-next-line react/require-default-props
  pageHeaderSub?: string,
  children: any
}
// Remove mt: 13, paddingLeft: 3 on Raymond's updates 

const BasePage = ({ pageHeaderSub = "", pageHeader = "", children }: Base) => (

  <Container maxWidth={false} disableGutters>
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", mb: 3, mt: 12, paddingLeft: 3, paddingRight: 3 }}>
      <Typography>
        <h3 className="heading-sub" id="side-bar-main">{pageHeader}</h3>
        {pageHeaderSub.length > 0 &&
          <>
            <div style={{ float: "left", marginLeft: "-5px", color: " #435cd2" }}>
              <KeyboardArrowRightIcon />
            </div>
            <div style={{ float: "left" }}>
              <Typography>
                {pageHeaderSub}
              </Typography>
            </div>
          </>}
      </Typography>
    </Box>
    <Box>
      {children}
    </Box>
  </Container>


)
export default BasePage;
