import { protectedResources } from "../../authConfig";

import makeGetRequestAsync from "../Helpers/httpRequestHelpers";

import { DashboardModel } from "./Models/DashboardModel";
import { DashboardBEEComplianceModel } from "./Models/DashboardBEEComplianceModel";
import { DashboardConnectionModel } from "./Models/DashboardConnectionModel";
import { SupplierLocationsModel } from "./Models/SupplierLocationsModel";
import { BusinessSectorsModel } from "./Models/BusinessSectorsModel";
import { DashboardSummariesMessage } from "./Messages/Dashboard/DashboardSummariesMessage";
import { DashboardSupplierStatusSummary } from "./Models/DashboardSupplierStatusSummary";
import { DashboardInsightsSupplierBySegmentSummary } from "./Models/InsightsSuppliersBySegmentsSummary";
import { ExistingAndPotentialSupplierCounts } from "./Models/ExistingAndPotentialSupplierCounts";
import DashboardStatsCardModel from "../../pages/NewUI/DashboardComponents/DashboardStatsCardModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;
/*
 *  Retrieves the company connection stats
 */
export async function GetCompanyConnectionsStats() {
  const url = `${baseUrl}/api/Company/Dashboard/myConnections`;
  return makeGetRequestAsync<DashboardStatsCardModel>(apiScopes, url);
}
/*
 *  Retrieves the total existing suppliers based on a passed company ID
 */
export async function GetTotalExistingSuppliers(companyId: number) {
  const url = `${baseUrl}/api/Company/Dashboard/GetTotalExistingSuppliers/${companyId}`;
  return makeGetRequestAsync(apiScopes, url);
}

/*
 *  Retrieves the potential suppliers based on a passed company ID
 */
export async function GetPotentialSuppliers(companyId: number) {
  const url = `${baseUrl}/api/Company/Dashboard/GetTotalPotentialSuppliers/${companyId}`;
  return makeGetRequestAsync<DashboardModel>(apiScopes, url);
}

/*
 *  Retrieves all the BEE compliances based on a passed company ID
 */
export async function GetBEECompliances(companyId: number) {
  const url = `${baseUrl}/api/Company/Dashboard/GetBEECompliances/${companyId}`;
  return makeGetRequestAsync<Array<DashboardBEEComplianceModel>>(apiScopes, url);
}

/*
 *  Retrieves all the connections
 */
export async function GetConnections(companyId: number) {
  const url = `${baseUrl}/api/Company/Dashboard/GetConnections?companyId=${companyId}`;
  return makeGetRequestAsync<Array<DashboardConnectionModel>>(apiScopes, url);
}

export async function GetSupplierLocations(companyId: number) {
  const url = `${baseUrl}/api/Company/Dashboard/GetSupplierLocations/${companyId}`;
  return makeGetRequestAsync<Array<SupplierLocationsModel>>(apiScopes, url);
}

export async function GetBusinessSectors() {
  const url = `${baseUrl}/api/Company/Dashboard/GetBusinessSectors`;
  return makeGetRequestAsync<Array<BusinessSectorsModel>>(apiScopes, url);
}

export const GetDashboardSummariesAsync = async () => {
  const url = `${baseUrl}/api/Company/Dashboard/Summaries`;
  return (await makeGetRequestAsync<DashboardSummariesMessage>(apiScopes, url)).data;
}

export const GetCompanySupplierStatusSummary = async () => {
  const url = `${baseUrl}/api/Company/Dashboard/CompanySupplierStatusSummary`;
  return (await makeGetRequestAsync<DashboardSupplierStatusSummary>(apiScopes, url)).data;
}

export const GetInsightsSupplierBySegmentSummary = async () => {
  const url = `${baseUrl}/api/Company/Dashboard/SuppliersBySegmentSummary`;
  return (await makeGetRequestAsync<Array<DashboardInsightsSupplierBySegmentSummary>>(apiScopes, url)).data;
}

export const GetExistingAndPotentialSupplierCounts = async () => {
  const url = `${baseUrl}/api/Company/Dashboard/ExistingAndPotentialSupplierCounts`;
  return (await makeGetRequestAsync<ExistingAndPotentialSupplierCounts>(apiScopes, url)).data;
}