// eslint-disable-next-line no-shadow
enum VisabilityType { // eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  Company = 1,// eslint-disable-line no-unused-vars
  Connect = 2,// eslint-disable-line no-unused-vars
  Platform = 3,// eslint-disable-line no-unused-vars
}

export default VisabilityType;

export const visabilityTypeToString = (value: VisabilityType) => {
  switch (value) {
    case VisabilityType.Platform:
      return "Platform"
    case VisabilityType.Company:
      return "Company"
    case VisabilityType.Connect:
      return "Connect"
    default:
      return "Unknown";
  }
};