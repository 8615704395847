import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ClientSpecificService from "../../http/ClientSpecificService";
import { useAppSelector } from "../../app/hooks";
import { selectBaseCompanyInfo } from "../../http/Redux/Store/baseCompanyInfoSlice";

import { ClientSpecificModel } from "../../http/Redux/Models/ClientSpecificModel";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";

export interface ClientSpecificDataProps {
  supplierCompanyId: number;
  clientId: number;
  onClose: () => void;
}

const ClientSpecificData = (props: ClientSpecificDataProps) => {
  const company = useAppSelector(selectBaseCompanyInfo);

  const [clientDetails, setClientDetails] = useState({} as ClientSpecificModel);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      const value = await ClientSpecificService.getClientSpecificDetail(props.clientId ?? 0, props.supplierCompanyId);
      setClientDetails(value);
      setIsLoading(false);
    };

    loader();
  }, [company.companyId, props.supplierCompanyId, setClientDetails]);

  const handleSave = async () => {
    setIsLoading(true);
    const details = {
      ...clientDetails,
      clientCompanyId: company.companyId,
      supplierCompanyId: props.supplierCompanyId,
      excludeFromSpend: clientDetails.excludeFromSpend ?? false,
      mySupplierNumber: clientDetails.mySupplierNumber ?? "",
      myRegisteredName: clientDetails.myRegisteredName ?? "",
      myTradingAs: clientDetails.myTradingAs ?? "",
      myIntegrationIdentifier: clientDetails.myIntegrationIdentifier ?? "",
      notes: clientDetails.notes ?? "",
      partnerManagedSupplier: clientDetails.partnerManagedSupplier ?? false,
    } as ClientSpecificModel;
    await ClientSpecificService.addUpdateClientSpecificDetail(details);
    setIsLoading(false);
  };

  return (
    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      <Dialog open={!isLoading} maxWidth="sm">
        <DialogTitle>Client Specific Data</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            <Switch
              checked={clientDetails.excludeFromSpend}
              onChange={(e) => setClientDetails({ ...clientDetails, excludeFromSpend: e.target.checked })}
            />
            Exclude From Spend
          </Typography>
          <TextField
            label="My Supplier Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.mySupplierNumber}
            onChange={(e) => setClientDetails({ ...clientDetails, mySupplierNumber: e.target.value })}
          />
          <TextField
            label="Known as / Registered Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.myRegisteredName}
            onChange={(e) => setClientDetails({ ...clientDetails, myRegisteredName: e.target.value })}
          />
          <TextField
            label="Trading as"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.myTradingAs}
            onChange={(e) => setClientDetails({ ...clientDetails, myTradingAs: e.target.value })}
          />
          <TextField
            label="ERP Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.myIntegrationIdentifier}
            onChange={(e) => setClientDetails({ ...clientDetails, myIntegrationIdentifier: e.target.value })}
          />
          <Typography variant="h6">
            <Switch
              checked={clientDetails.partnerManagedSupplier}
              onChange={(e) => setClientDetails({ ...clientDetails, partnerManagedSupplier: e.target.checked })}
            />
            Managed by B1SA
          </Typography>
          <TextField
            label="Notes"
            variant="outlined"
            fullWidth
            multiline
            maxRows={5}
            margin="normal"
            value={clientDetails.notes}
            onChange={(e) => setClientDetails({ ...clientDetails, notes: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            title="Save"
            onClick={handleSave}
          >Save</Button>
          <Button
            title="Cancel"
            onClick={props.onClose}
          >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClientSpecificData;
