/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum SearchModelProperties {
  CompanyId = "companyId",
  GetMyClients = "getMyClients",
  GetMySuppliers = "getMySuppliers",
  GetPotentialConnections = "getPotentialConnections",
  GetCompaniesFollowed = "getCompaniesFollowed",
  ParentCompanyAccountId = "parentCompanyAccountId",
  RegistrationNumber = "registrationNumber",
  RegisteredName = "registeredName",
  TradingName = "tradingName",
  HoldingCompanyName = "holdingCompanyName",
  VatNumber = "vatNumber",
  TradeDescription = "tradeDescription",
  CompanyTypeIds = "companyTypeIds",
  AddressTypeIds = "addressTypeIds",
  PhysicalAddressStreetAddr = "physicalAddressStreetAddr",
  City = "city",
  CountryIds = "countryIds",
  ProvinceIds = "provinceIds",
  Email = "email",
  Mobile = "Contact Number",
  Website = "website",
  Landline = "landline",
  Fax = "fax",
  ContactTypeIds = "contactTypeIds",
  BeeLevelIds = "beeLevelIds",
  BeeSectorIds = "beeSectorIds",
  BeeClassificationIds = "beeClassificationIds",
  BOPercentage = "bOPercentage",
  BWOPercentage = "bWOPercentage",
  ComplianceStatus = "complianceStatus",
  ComplianceStatusIds = "complianceStatusIds",
  ComplianceStatusesCIPC = "complianceStatusesCIPC",
  ComplianceStatusesSARS = "complianceStatusesSARS",
  ComplianceStatusesBEE = "complianceStatusesBEE",
  UnspscSegmentIds = "unspscSegmentIds",
  UnspscFamilyIds = "unspscFamilyIds",
  rating = "rating",
  SearchOrderBys = "searchOrderBys",
  SearchQuery = "searchQuery",
  SearchPageNumber = "searchPageNumber",
}

export default SearchModelProperties;