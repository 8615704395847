import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataManagementPartnerArrayModel, DataManagementPartnerModel } from "../Models/DataManagementPartnerModel";
import { RootState } from "../../../app/store";
import { ManagedCompaniesArrayModel, ManagedCompaniesModel } from "../Models/ManagedCompaniesModel";

// Data Management Partner 
const initialDataManagementPartnerState: DataManagementPartnerArrayModel = {
  allDataManagementPartners: [],
}

export const dataManagementPartnerSlice = createSlice({
  name: "dataManagementPartner",
  initialState: initialDataManagementPartnerState,
  reducers: {
    setDataManagementPartner(state, action: PayloadAction<DataManagementPartnerModel[]>) {
      state.allDataManagementPartners = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});

export const getAllDataManagementPartners = (state: RootState) => state.dataManagementPartner.allDataManagementPartners;

// Data Management Partner Clients 
const initialDataManagementPartnerClientState: ManagedCompaniesArrayModel = {
  allManagedCompanies: [],
}

export const dataManagementPartnerClientSlice = createSlice({
  name: "dataManagementPartnerClient",
  initialState: initialDataManagementPartnerClientState,
  reducers: {
    setDataManagementPartnerClient(state, action: PayloadAction<ManagedCompaniesModel[]>) {
      state.allManagedCompanies = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});


export const getAllDataManagementPartnerClients = (state: RootState) => state.dataManagementPartnerClient.allManagedCompanies;