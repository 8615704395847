import { addMultipleConnectionClientDocTypes } from "../../../http/DocumentManagement/DocumentManagement";
import { AddClientDocTypesConnectionDto } from "../../../http/NetworkAndConnect/Dtos/AddClientDocTypesConnectionDto";
import { ConnectionRuleModel } from "../../../http/NetworkAndConnect/Models/ConnectionRuleModel";
import { DocumentRuleModel } from "../../../http/NetworkAndConnect/Models/DocumentRuleModel";

/* Functions/Logic */
export const checkAgainstRulesetAttributes = (
  evaluationObject: ConnectionRuleModel,
  attributeNameArray: Array<string>,
  documentNameArray: Array<string>,
  evaluationValues: Array<string>
) => {
  const successAttrs: Array<string> = [] as Array<string>;
  const failedAttrs: Array<string> = [] as Array<string>;

  evaluationValues.forEach((evaluationVal) => {
    if (
      evaluationVal !== "CompanyId" &&
      !documentNameArray.includes(evaluationVal)
    ) {
      const check = attributeNameArray.includes(evaluationVal);

      if (check && evaluationObject.evaluation[evaluationVal].toString() === "Y") {
        successAttrs.push(evaluationVal);
      } else {
        failedAttrs.push(evaluationVal);
      }
    }
  });

  return [successAttrs, failedAttrs];
};

export const checkAgainstRulesetDocuments = (
  evaluationObject: ConnectionRuleModel,
  attributeNameArray: Array<string>,
  documentNameArray: Array<string>,
  evaluationValues: Array<string>
) => {
  const successDocs: Array<string> = [] as Array<string>;
  const failedDocs: Array<string> = [] as Array<string>;

  evaluationValues.forEach((evaluationVal) => {
    if (
      evaluationVal !== "CompanyId" &&
      evaluationVal !== "RegisteredName" &&
      !attributeNameArray.includes(evaluationVal)
    ) {
      const check = documentNameArray.includes(evaluationVal);

      if (check && evaluationObject.evaluation[evaluationVal].toString() === "Y") {
        successDocs.push(evaluationVal);
      } else {
        failedDocs.push(evaluationVal);
      }
    }
  });

  return [successDocs, failedDocs];
};

const getMissingDocTypeId = (docName: string, docArray: Array<DocumentRuleModel>) => {
  const foundDoc = docArray.find((doc) => doc.name === docName);
  if (!foundDoc)
    return false;

  return foundDoc;
}

export const checkMissingCustomDocs = (evaluationObject: ConnectionRuleModel) => {
  const missingCustomDocTypeIds: Array<number> = [] as Array<number>;

  const evalDocuments = evaluationObject.documents.filter((doc) => {
    if (doc.companyId === 0)
      return false;

    return true;
  }).map((filteredDoc) => filteredDoc);

  Object.keys(evaluationObject.evaluation).forEach((key) => {
    const result = getMissingDocTypeId(key, evalDocuments);

    if (result)
      missingCustomDocTypeIds.push(result.id);
  });

  return missingCustomDocTypeIds;
}

export const AddConnectionDocuments = async (
  missingDocumentTypes: Array<number>,
  fromCompanyId: number,
  toCompanyId: number,
  userId: string | undefined) => {
  if (missingDocumentTypes.length <= 0)
    return;

  const connectionDocumentObject = {} as AddClientDocTypesConnectionDto;
  connectionDocumentObject.documentTypeIds = missingDocumentTypes;
  connectionDocumentObject.fromCompanyId = fromCompanyId;
  connectionDocumentObject.toCompanyId = toCompanyId;
  connectionDocumentObject.userId = userId;

  try {
    await addMultipleConnectionClientDocTypes(connectionDocumentObject);
  }
  catch(error) {

    // eslint-disable-next-line no-console
    console.error(error); // Change this when you know what to switch it with.
  }
}

