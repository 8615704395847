import { Grid, IconButton, TextField, Button, Tooltip, Box, Chip } from "@mui/material";
import { useState } from "react";
// import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { RoleMessage } from "../../http/CompanyUserManagement/Messages/RoleMessage";
import { UserInvitationMessage } from "../../http/CompanyUserManagement/Messages/UserInvitationMessage";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import MultiItemSelector from "../MultiItemSelector/MultiItemSelector";
import { useAppSelector } from "../../app/hooks";
import SendCompanyUserInvitation from "../../http/CompanyUserManagement/userManagementApi";

interface PropsInviteUser {
  roles: RoleMessage[],
  // eslint-disable-next-line no-unused-vars
  addNewUserInvitationCallback: (newUserInvitation: UserInvitationMessage) => void,
  setUserIdNull: () => void,
  backToView: () => void,
}



const InviteUser = ({ roles, addNewUserInvitationCallback, setUserIdNull, backToView }: PropsInviteUser) => {
  const [loading, isLoading] = useState(false);
  const [userMessage, setUserMessage] = useState<UserInvitationMessage>({} as UserInvitationMessage);
  const [textIsNotEditable, setTextIsNotEditable] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [showRoles, setShowRoles] = useState(false);
  const currentCompany = useAppSelector(state => state.baseCompanyInfo)



  const handleSaveData = () => {
    // validate data
    if (userMessage == null ||
      userMessage.firstName == null || userMessage.lastName == null || userMessage.email == null ||
      selectedRoles.length <= 0 ||
      (userMessage.firstName?.length <= 0 || userMessage.lastName.length <= 0 || userMessage.email.length <= 0)) {
      Swal.fire({
        title: "Warning",
        text: "Please ensure that all mandatory fields are completed",
        showCancelButton: false,
        icon: "warning",
      });
      return;
    }

    const saveData = async () => {
      try {
        isLoading(true);
        setTextIsNotEditable(true);
        const newUserInvitation = await SendCompanyUserInvitation(
          { ...userMessage, companyId: currentCompany?.companyId, roleIdList: selectedRoles }
        );




        addNewUserInvitationCallback({ ...newUserInvitation, roleIdList: [...selectedRoles] });
        setUserMessage({} as UserInvitationMessage);
        setSelectedRoles([]);
        setUserIdNull();
        isLoading(false);
        setTextIsNotEditable(false);
        backToView();
      } catch {
        isLoading(false);
        setTextIsNotEditable(false);
        Swal.fire({
          title: "Error",
          text: "Something went wrong while attempting to add your user, please try again or contact your administrator",
          showCancelButton: false,
          icon: "error",
        });
      }
    }
    saveData();
  };

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Users">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                disabled={textIsNotEditable}
                label="Name"
                size="small"
                type="text"
                sx={{ width: "98%" }}
                variant="outlined"
                name="firstName"
                value={userMessage?.firstName ?? ""}
                onChange={(ev) => setUserMessage({ ...userMessage, firstName: ev.target.value })}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                disabled={textIsNotEditable}
                size="small"
                label="Surname"
                type="text"
                fullWidth
                variant="outlined"
                name="lastName"
                value={userMessage?.lastName ?? ""}
                onChange={(ev) => setUserMessage({ ...userMessage, lastName: ev.target.value })}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <TextField
                autoFocus
                required
                size="small"
                disabled={textIsNotEditable}
                label="Email"
                type="email"
                sx={{ width: "98%" }}
                variant="outlined"
                name="email"
                value={userMessage?.email ?? ""}
                onChange={(ev) => setUserMessage({ ...userMessage, email: ev.target.value })}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Contact number"
                disabled={textIsNotEditable}
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                name="mobileNumber"
                value={userMessage?.mobileNumber ?? ""}
                onChange={(ev) => setUserMessage({ ...userMessage, mobileNumber: ev.target.value })}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <Tooltip title="Select User Roles">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={() => setShowRoles(true)}>
                  <AddIcon /> Select Roles
                </Button>
              </Tooltip>
              {showRoles &&
                <MultiItemSelector
                  title="User Roles"
                  description="Please select a list of roles that will apply to this user"
                  hasSearchInput
                  onSubmitSelectionCallback={(permissionIdList) => { setSelectedRoles(permissionIdList); }}
                  handleClose={() => setShowRoles(false)}
                  optionsList={roles.map((x) => ({ id: x.id, displayValue: x.name }))}
                  currentSelectionArray={selectedRoles}
                />
              }
            </Grid>
            <Grid xs={6}>
              <Box> {roles.filter(r => selectedRoles.includes(r.id)).map((d) => (
                <Chip
                  key={d.id}
                  label={d.name}
                  sx={{ marginRight: 2, marginTop: 2 }}
                  className="chip-colored"
                  size="small"
                />
              ))}</Box>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" className="form-add-btn" onClick={() => handleSaveData()}>Send</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

};

export default InviteUser;

