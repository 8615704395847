import { Box, Button, Container, Modal, Slide, Typography } from "@mui/material";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import GetBlobSasToken from "../../http/SAS/sasToken";

const style = {
  position: "absolute",
  top: "25%",
  left: "25%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const storageAccountURL = process.env.REACT_APP_STORAGE_ACCOUNT_URL;

interface VendorSpendDataUploadProps {
  companyId: number;
  userId: string;
}

const VendorSpendDataUpload = ({ companyId, userId }: VendorSpendDataUploadProps) => {
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [showUploader, setShowUploader] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [uploadFiles, setUploadFiles] = useState<Array<File>>([] as Array<File>);

  const ResetUploadComponents = () => {
    setUploadFiles([] as Array<File>);
  };

  const handleShowUploader = () => {
    setShowUploader(!showUploader);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDownloadTemplate = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    const templateFilename = "vendor_spend_data_template.xlsx";

    axios
      .get(`${storageAccountURL}/templates/vendor_spend_data_template.xlsx?v=${Math.floor(Math.random() * 1000) + 1}`, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = templateFilename;
        a.click();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error downloading template",
          text: `Unable to download template\n${error}`,
          allowEnterKey: false,
          allowEscapeKey: true,
          allowOutsideClick: false,
          showCloseButton: true,
        });
      });
  };

  const SetFilesToUpload = async (filesToUpload: File[]) => {
    if (filesToUpload.length === 0) return;

    setUploadFiles(filesToUpload);
  };

  const PerformFileUpload = async () => {
    if (uploadFiles.length === 0) return;

    setShowBackdrop(true);

    const customFilename = `${uuidv4()}.xlsx`;
    const account = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
    const sas = await GetBlobSasToken();

    const sasUrl = `https://${account}.blob.core.windows.net/?${sas}`;

    const blobServiceClient = new BlobServiceClient(sasUrl);
    const blobContainerName = process.env.REACT_APP_VENDORSPEND_CONTAINER_NAME as string;

    const containerClient = blobServiceClient.getContainerClient(blobContainerName);
    const blobClient = containerClient.getBlockBlobClient(customFilename);

    // We use this to set the metadata for our file.
    const uploadOptions = {
      metadata: {
        companyid: companyId.toString(),
        userid: userId.toString(),
      },
    };

    try {
      await blobClient.uploadData(uploadFiles[0], uploadOptions);

      Swal.fire({
        title: "File uploaded successfully",
        text: "Vendor Spend Data file uploaded successfully.",
        allowEnterKey: false,
        allowEscapeKey: true,
        allowOutsideClick: false,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error during upload",
        html: `<p>Oops... Something went wrong:</p><p>${error}</p><p>Please contact B1 Support for assistance.</p>`,
        allowEnterKey: false,
        allowEscapeKey: true,
        allowOutsideClick: false,
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        ResetUploadComponents();

        setShowUploader(false);

        setShowBackdrop(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setShowBackdrop(true);
    setShowBackdrop(false);
  }, [showUploader]);

  return (
    <>
      <LoadingBackdrop showBackdrop={showBackdrop} />
      <div className="wrap-no-padding">
        <Container sx={{ pt: 3 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Vendor Spend Upload
            </Typography>
            <Button onClick={handleShowUploader} variant="contained">
              Upload Now
            </Button>
          </Box>
        </Container>
      </div>

      <Modal
        open={showUploader}
        onClose={handleShowUploader}
        aria-labelledby="modal-upload-vendorspenddata"
        aria-describedby="modal-upload-vendorspenddata"
      >
        <Slide direction="left" in={showUploader}>
          <Box sx={style}>
            <Box
              id="modal-upload-vendorspenddata-header"
              sx={{ typography: "h6", textTransform: "uppercase", textAlign: "center", mb: 2 }}
            >
              Upload your Vendor Spend Data
            </Box>

            <Box
              id="modal-upload-vendorspenddata-download-template"
              sx={{ typography: "subtitle1", mb: 3 }}
            >
              Please{" "}
              <Button color="primary" onClick={handleDownloadTemplate}>
                <u>click here</u>
              </Button>{" "}
              to download the excel template with the mandatory and optional data attributes.
            </Box>

            <Box id="modal-upload-vendorspenddata-dropzone" sx={{ mt: 2 }}>
              <DropzoneArea
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ]}
                filesLimit={1}
                showAlerts
                showPreviewsInDropzone
                onChange={(files) => {
                  SetFilesToUpload(files);
                }}
              />
              <Button
                color="primary"
                onClick={PerformFileUpload}
                variant="contained"
                sx={{ marginTop: "10px" }}
              >
                Upload File
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default VendorSpendDataUpload;
