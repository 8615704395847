// eslint-disable-next-line no-shadow
enum PromptStatus { // eslint-disable-line no-unused-vars
  Closed = 0,// eslint-disable-line no-unused-vars
  Declined = 1,// eslint-disable-line no-unused-vars
  Completed = 2,// eslint-disable-line no-unused-vars
  Later = 3,// eslint-disable-line no-unused-vars
  Show = 4,// eslint-disable-line no-unused-vars
}

export default PromptStatus;

export const promptStatusToString = (value: PromptStatus) => {
  switch (value) {
    case PromptStatus.Closed:
      return "Closed"
    case PromptStatus.Declined:
      return "Declined"
    case PromptStatus.Completed:
      return "Completed"
    case PromptStatus.Later:
      return "Later"
    case PromptStatus.Show:
      return "Show"
    default:
      return "Unknown";
  }
};