/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UnspscOptionsMessage } from "../../Configuration/UnspscOptionsMessage";

export interface UnspscOptionsState {
  unspscOptions: UnspscOptionsMessage;
}

export const unspscOptionsSlice = createSlice({
  name: "unspscOptions",
  initialState: {} as UnspscOptionsMessage,
  reducers: {
    setUnspscOptionsState: (
      state,
      action: PayloadAction<UnspscOptionsMessage>
    ) => {
      state.families = action.payload.families;
      state.segments = action.payload.segments;
    },
  },
});

export const { setUnspscOptionsState } = unspscOptionsSlice.actions;

export const selectUnspscOptions = (state: RootState) => state.unspscOptions;
export default unspscOptionsSlice.reducer;
