import React, { } from "react";
import { AddressModel } from "../../../../http/Company/Models/AddressModel";
import Address from "./Address";

export interface CompanyAddressDetailsProps {
  addresses: Array<AddressModel>;

  // eslint-disable-next-line no-unused-vars
  OnSaveAddress: (addressToSave: AddressModel) => void;

  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  OnCopyPhysical: (update: boolean, addressToUpdate: AddressModel) => AddressModel;
}

const CompanyAddressDetailsPost = ({ addresses, OnSaveAddress, SetDirtyCallback, OnCopyPhysical }: CompanyAddressDetailsProps) => (
  <div>
    {addresses.map((address) => (
      <Address
        key={address.addressId}
        addressDetails={address}
        OnSaveAddress={OnSaveAddress}
        SetDirtyCallback={SetDirtyCallback}
        OnCopyPhysical={OnCopyPhysical}
      />
    ))}
  </div>
);

export default CompanyAddressDetailsPost;
