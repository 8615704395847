import { AxiosResponse } from "axios";
import { SubCheckResult } from "./SubCheckResult";
import { ComplianceVerificationTypeEnum } from "./ComplianceVerificationTypesEnum";
import { protectedResources } from "../../authConfig";
import { ProcureCheckResultMessage } from "./ProcureCheckResultMessage";
import makeGetRequestAsync, { makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { OverallComplianceVerificationTypeStatusMessage } from "./overallComplianceVerificationTypeStatusMessage";
import { InoxicoCompanyAdditionalSearchResults, InxoicoCompanySearchResults } from "./CompanySearchResults";
import { CompanyAdditionalSearchModel, CompanySearchModel } from "./CompanySearchModel";

const baseUrl = `${protectedResources.complianceWebApi.endpoint}/api/compliance`;
const apiScopes = protectedResources.complianceWebApi.scopes;
const cipcBaseurl = `${protectedResources.complianceWebApi.endpoint}/api/compliance/Cipc`;

/**
 * Attaches a given access token to a Wep API call. Returns information about the user
 */
export const getCompanyComplianceResults = async (companyId: number) => {
  const url = `${baseUrl}/${companyId}`;
  const result = (await makeGetRequestAsync<AxiosResponse<Array<ProcureCheckResultMessage>>>(apiScopes, url));
  return result.data as unknown as ProcureCheckResultMessage[];
}

export const getMyComplianceResults = async () => {
  const url = `${baseUrl}`;
  const result = (await makeGetRequestAsync<AxiosResponse<Array<OverallComplianceVerificationTypeStatusMessage>>>(apiScopes, url));
  return result.data as unknown as OverallComplianceVerificationTypeStatusMessage[];
}

export const getComplianceVerificationTypeDetails = async (companyId: number,
  verficationType: ComplianceVerificationTypeEnum) => {

  const url = `${baseUrl}/${companyId}/details?verificationType=${verficationType}`;
  return (await makeGetRequestAsync<Array<SubCheckResult>>(apiScopes, url)).data;
}

export const getMyComplianceVerificationTypeDetails = async (verficationType: ComplianceVerificationTypeEnum) => {

  const url = `${baseUrl}/details?verificationType=${verficationType}`;
  const result = (await makeGetRequestAsync<Array<SubCheckResult>>(apiScopes, url))
  return result.data;
}

export const getInoxicoCompanyDetails = async (companySearch: CompanySearchModel) => {
  const url = `${cipcBaseurl}/searchCompany`;
  const result = (await makePostRequestAsync(apiScopes,url, companySearch));
  return result.data as unknown as InxoicoCompanySearchResults;
}

export const getInoxicoAdditionalCompanyDetails = async (companyAdditionalSearch: CompanyAdditionalSearchModel) => {
  const url = `${cipcBaseurl}/GetCompany`;
  const result = (await makePostRequestAsync(apiScopes,url, companyAdditionalSearch));
  return result.data as unknown as InoxicoCompanyAdditionalSearchResults;
}