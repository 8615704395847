/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum SearchViewFields {
  CompanyId = "companyid",
  ParentCompanyAccountId = "parentcompanyaccountid",
  HoldingCompanyName = "holdingcompanyname",
  RegistrationNumber = "RegistrationNumber",
  RegisteredName = "RegisteredName",
  VatNumber = "VatNumber",
  TradingName = "TradingName",
  TradeDescription = "TradeDescription",
  Country = "Country",
  CompanyType = "CompanyType",
  BOPercentage = "bopercentage",
  BWOPercentage = "bwopercentage",
  BEELevelId = "BEELevelId",
  BEELevel = "BEELevel",
  BEESectorId = "BEESectorId",
  BEESector = "BEESector",
  ContactTypeId = "ContactTypeId",
  Email = "Email",
  Mobile = "Contact Number",
  Website = "Website",
  Landline = "Landline",
  Fax = "Fax",
  ContactPerson = "ContactPerson",
  ComplianceStatus = "ComplianceStatus",
  AddressTypeId = "AddressTypeId",
  PhysicalAddressStreetAddr = "PhysicalAddressStreetAddr",
  City = "City",
  Province = "Province",
  ProvinceId = "ProvinceId",
  countryid = "countryid",
  companytypeid = "companytypeid",
  CreatedDate = "CreatedDate",
  UnspscFamilyId = "UnspscFamilyId",
  FamilyName = "FamilyName",
  FamilyCode = "FamilyCode",
  UnspscSegmentId = "UnspscSegmentId",
  SegmentName = "SegmentName",
  SegmentCode = "SegmentCode",
  Rating = "Rating",
}

export default SearchViewFields;