import * as yup from "yup";

const newConnectionInvitationsValidationSchema = yup.array().of(
  yup.object().shape({
    fullName: yup.string().test(
      "fullName",
      "Full name must contain both a name and a surname",
      (value) => {// Custom validation function to check if the fullName contains both a name and a surname
        if (!value) return false; // Return false if value is empty or undefined
        const parts = value.trim().split(/\s+/); // Split the full name by spaces
        return parts.length >= 2; // Check if there are at least two parts (name and surname)
      }
    ).required("Full name is required"),
    emailAddress: yup.string().email("Email must be a valid email").required("Email is required"),
    registeredCompanyName: yup.string().required("Registered company name is required"),
  })
);

export default newConnectionInvitationsValidationSchema;