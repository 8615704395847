import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyVerificationModel, CompanyVerificationArrayModel } from "../Models/CompanyVerificationModel";
import { CompanyRequestModel, CompanyRequestArrayModel } from "../Models/CompanyRequestModel";
import { RootState } from "../../../app/store";
import { CompanyValidationConfigArrayModel, CompanyValidationConfigModel } from "../Models/CompanyValidationConfigModel";
import { CompanyValidationRankingArrayModel, CompanyValidationRankingModel } from "../Models/CompanyValidationRankingModel";
import formatDate from "../../../util/formatter/formatDate";


const initialCompanyVerificationState: CompanyVerificationArrayModel = {
  allCompanyVerifications: [],
}

const initialCompanyValidationConfigState: CompanyValidationConfigArrayModel = {
  allCompanyValidationConfigs: [],
}

const initialCompanyValidationRankingState: CompanyValidationRankingArrayModel = {
  allCompanyValidationRankings: [],
}

const initialCompanyRequestState: CompanyRequestArrayModel = {
  allCompanyRequests: [],
  particularCompanyRequest: {
    status: 0,
    requestDate: formatDate(new Date),
    requestType: 0,
    agentName: "",
  },
}





export const companyValidationRankingSlice = createSlice({
  name: "companyValidationRankings",
  initialState: initialCompanyValidationRankingState,
  reducers: {
    setCompanyValidationRanking(state, action: PayloadAction<CompanyValidationRankingModel[]>) {
      state.allCompanyValidationRankings = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const companyValidationConfigSlice = createSlice({
  name: "companyValidationConfigs",
  initialState: initialCompanyValidationConfigState,
  reducers: {
    setCompanyValidationConfig(state, action: PayloadAction<CompanyValidationConfigModel[]>) {
      state.allCompanyValidationConfigs = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const companyVerificationSlice = createSlice({
  name: "companyVerifications",
  initialState: initialCompanyVerificationState,
  reducers: {
    setCompanyVerification(state, action: PayloadAction<CompanyVerificationModel[]>) {
      state.allCompanyVerifications = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const companyRequestSlice = createSlice({
  name: "companyRequests",
  initialState: initialCompanyRequestState,
  reducers: {
    setCompanyRequest(state, action: PayloadAction<CompanyRequestModel[]>) {
      state.allCompanyRequests = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularCompanyRequest(state, action: PayloadAction<CompanyRequestModel>) {
      state.particularCompanyRequest = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


export const getCompanyRequest = (state: RootState) => state.companyRequests.particularCompanyRequest;
export const getCompanyVerifications = (state: RootState) => state.companyVerifications.allCompanyVerifications;
export const getCompanyValidationConfig = (state: RootState) => state.companyValidationConfigs.allCompanyValidationConfigs;
export const getCompanyValidationRankings = (state: RootState) => state.companyValidationRankings.allCompanyValidationRankings;

