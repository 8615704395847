// eslint-disable-next-line no-shadow
enum OrderByType { // eslint-disable-line no-unused-vars
  Ascending = 0,// eslint-disable-line no-unused-vars
  Descending = 1,// eslint-disable-line no-unused-vars
}

export default OrderByType;

export const orderByTypeToString = (value: OrderByType) => {
  switch (value) {
    case OrderByType.Ascending:
      return "Ascending"
    case OrderByType.Descending:
      return "Descending"
    default:
      return "Unknown";
  }
};