import axios, { AxiosRequestConfig } from "axios";
import { ContactModel } from "./Models/ContactModel";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePostRequestAsync } from "../Helpers/httpRequestHelpers";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export async function SaveContactChanges(accessToken: string, contact: ContactModel, staged: boolean) {
  const bearer = `Bearer ${accessToken}`

  const config: AxiosRequestConfig = {
    headers: {
      "Authorization": `${bearer}`,
      "Content-Type": "application/json",
    },
  }
  const url = `${baseUrl}/api/Company/Contacts/UpdateContact/${staged}`;
  return axios.post(url, contact, config) as Promise<void>;
}

export async function AddNewContact(accessToken: string, contact: ContactModel, staged: boolean) {

  const bearer = `Bearer ${accessToken}`

  const config: AxiosRequestConfig = {
    headers: {
      "Authorization": `${bearer}`,
      "Content-Type": "application/json",
    },
  }
  const url = `${protectedResources.companyApi.endpoint}/api/Company/Contacts/AddContact/${staged}`;
  const rawReturnValue = await axios.post(url, contact, config);
  return rawReturnValue;
}

export async function AddNewContactDetails(contact: ContactModel, staged: boolean) {
  const url = `${protectedResources.companyApi.endpoint}/api/Company/Contacts/AddContact/${staged}`;
  const response = await makePostRequestAsync(apiScopes, url,contact);
  return response;
}

export async function DeleteContact(contactToDelete: ContactModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Contacts/DeleteContact/${staged}`;
  const response = await (await makePostRequestAsync(apiScopes, url, contactToDelete)).status;
  return response;
}

export async function GetContactsForCompany(companyId: number, staged: boolean) {
  const url = `${baseUrl}/api/Company/Contacts/GetContactsForCompany/${companyId}/${staged}`;
  const response = await (await makeGetRequestAsync(apiScopes, url));
  return response;
}
