import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import ConnectionRequestService from "../../../http/ConnectionRequestService";
import { ViewConnectionRequestModel } from "../../../http/Redux/Models/ViewConnectionRequestModel";

const useCompanyConnectionRequests = (companyId: number) => {
  const query = useQuery({
    queryKey: [queryKeys.connectionRequests, companyId],
    queryFn: async () => (await ConnectionRequestService.getViewConnectionRequests(companyId)) as Array<ViewConnectionRequestModel>,
    staleTime: 600000,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyConnectionRequests;