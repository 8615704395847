/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AppBar, Tab, Tabs } from "@mui/material";

// eslint-disable-next-line no-shadow
enum CompanyProfileSideBarEnum { // eslint-disable-line no-unused-vars
  RegistrationInformation = 0, // eslint-disable-line no-unused-vars
  Contacts = 1, // eslint-disable-line no-unused-vars
  Address = 2, // eslint-disable-line no-unused-vars
  Services = 4, // eslint-disable-line no-unused-vars
  Documents = 3, // eslint-disable-line no-unused-vars
  ProfileSharing = 5, // eslint-disable-line no-unused-vars
}


interface Props {
  currentSection: number;
  setSection: (pageNumber: number) => void; // eslint-disable-line no-unused-vars
}

// a11yProps are 0 based, but section IDs are 1 based.
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


const CompanyProfileSidePanel = ({ currentSection, setSection }: Props) => {
  const handleChangeTab = (newValue: number) => {
    setSection(newValue);
  };

  return (

    <AppBar className="app-bar" sx={{ width: "100%" }} position="static">
      <Tabs
        value={currentSection}
        onChange={(_ev, val: number) => handleChangeTab(val)}
        orientation="vertical"
        indicatorColor="secondary"
        textColor="inherit"
        aria-label=""
      >
        <Tab
          label="Company Information"
          {...a11yProps(CompanyProfileSideBarEnum.RegistrationInformation)}
          value={CompanyProfileSideBarEnum.RegistrationInformation}
          className="app-bar-tabs"
        />
        <Tab
          label="Company Contact Details"
          {...a11yProps(CompanyProfileSideBarEnum.Contacts)}
          value={CompanyProfileSideBarEnum.Contacts}
          className="app-bar-tabs"
        />
        <Tab
          label="Compliance Information"
          {...a11yProps(CompanyProfileSideBarEnum.Address)}
          value={CompanyProfileSideBarEnum.Address}
          className="app-bar-tabs"
        />
        <Tab
          label="Supporting Documentation"
          {...a11yProps(CompanyProfileSideBarEnum.Documents)}
          value={CompanyProfileSideBarEnum.Documents}
          className="app-bar-tabs"
        />
        <Tab
          label="Goods & Services"
          {...a11yProps(CompanyProfileSideBarEnum.Services)}
          value={CompanyProfileSideBarEnum.Services}
          className="app-bar-tabs"
        />
        <Tab
          label="Profile"
          {...a11yProps(CompanyProfileSideBarEnum.ProfileSharing)}
          value={CompanyProfileSideBarEnum.ProfileSharing}
          className="app-bar-tabs"
        />
      </Tabs>
    </AppBar>

  );
};

export default CompanyProfileSidePanel;