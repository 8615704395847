import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState, useRef } from "react";
import { AddressModel } from "../../../../http/Company/Models/AddressModel";
import AddressType from "../../../../util/enums/AddressType";
import "../CompanyPost.css";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ProvinceModel } from "../../../../http/Company/Models/ProvinceModel";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyAddressState } from "../../../../http/Redux/Store/companySlice";

export interface AddressProps {
  addressDetails: AddressModel;

  // eslint-disable-next-line no-unused-vars
  OnSaveAddress: (addressToUpdate: AddressModel) => void;

  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  OnCopyPhysical: (update: boolean, addressToUpdate: AddressModel) => AddressModel;
}

const Address = ({
  addressDetails,
  OnSaveAddress,
  SetDirtyCallback, // eslint-disable-line no-unused-vars
  OnCopyPhysical,
}: AddressProps) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector((x) => x.countryList);
  const provinces = useAppSelector((x) => x.provinceList);
  const municipalities = useAppSelector((x) => x.municipalityList);

  const [edit, setEdit] = useState<boolean>(false);
  const [sameAsPhysical, setSameAsPhysical] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [address, setAddress] = useState<AddressModel>(addressDetails);
  const [addressComparer, setAddressComparer] = useState<AddressModel>({} as AddressModel);
  const [filteredProvinces, setFilteredProvince] = useState<ProvinceModel[]>([]);
  const [validateField] = useFieldValidation("companyProfile");

  const debouncedAddressValue = useInputDebounce(address);

  const [line1Invalid, setLine1Invalid] = useState<boolean>(false);
  const [line2Invalid, setLine2Invalid] = useState<boolean>(false);
  const [suburbInvalid, setSuburbInvalid] = useState<boolean>(false);
  const [cityInvalid, setCityInvalid] = useState<boolean>(false);
  const [zipCodeInvalid, setzipCodeInvalid] = useState<boolean>(false);
  const [municipalityInvalid, setMunicipalityInvalid] = useState<boolean>(false);
  const initialLoadCheck = useRef(true);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const performDirtyCheck = (checkVal: AddressModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(addressComparer)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const HandleCountryChange = (countryIdParam: string, initialLoad = false) => {
    if (countryIdParam === address.countryId.toString() && !initialLoad) return;

    const selectedCountryId = parseInt(countryIdParam, 10);

    const newList = provinces.filter((x) => x.countryId === selectedCountryId);
    setFilteredProvince(newList);

    let newVal;
    if (initialLoad)
      newVal = { ...address, countryId: selectedCountryId};
    else
      newVal = { ...address, countryId: selectedCountryId, provinceId: 0, municipalityId: 0 };
    setAddress(newVal);

    if (!initialLoad) performDirtyCheck(newVal);
  };

  const handleCopyPhysical = (addressToUpdate: AddressModel, isSameAsPrimary: boolean) => {
    if (isSameAsPrimary === false) {
      setAddress(addressComparer);
      SetDirtyCallback(false);
      return;
    }

    const updatedAddress = OnCopyPhysical(true, addressToUpdate);
    setAddress(updatedAddress);
    SetDirtyCallback(true);
  };

  const EnableEdit = () => {
    if (edit) {
      OnSaveAddress(address);
      SetDirtyCallback(false);
    }

    setEdit(!edit);
  };

  const handleSwitchOnClick = () => {
    setSameAsPhysical((state) => !state);
  };

  const CancelEdit = () => {
    setEdit(false);
    SetDirtyCallback(false);
  };

  const countryCheck = () => {
    if (!edit) return true; // Disable if edit is false.

    if (address.addressTypeId !== AddressType.Physical) return true; // Disable if address isn't physical/company registered address

    if (!address?.countryId || address?.countryId > 0) return true; // If country ID is not undefined or 0, disable.

    return false; // Allow edit
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...address, [propName]: event.target.value };
    setAddress(newVal);
    performDirtyCheck(newVal);
  };

  const handleProvinceSelectChange = (event: SelectChangeEvent<number>) => {
    const newVal = { ...address, "provinceId": +event.target.value, municipalityId: 0 };
    setAddress(newVal);
    performDirtyCheck(newVal);
  }

  const municipalityCheck = () => {
    if (!edit) return true; // Disable if edit is false.

    if (!address?.countryId || address?.countryId <= 0) return true; // If country ID is not undefined or 0, disable.

    if (!address?.provinceId || address?.provinceId <= 0) return true;

    return false; // Allow edit
  };

  const renderMunicipalityDropdown = () => {
    const saCountryId = countries.find((sa) => sa.isDefault)?.id;
    if (address?.countryId !== saCountryId) return null;

    if (address.addressTypeId !== AddressType.Physical) return null;

    return (
      <Grid item xs={4}>
        <TextField
          type="text"
          select
          sx={{ width: "100%", marginRight: "2%" }}
          disabled={municipalityCheck()}
          id="municipality"
          label="Municipality"
          value={address?.municipalityId || ""}
          size="small"
          onChange={(ev) => handleValueChange(ev, "municipalityId")}
          error={municipalityInvalid}
          helperText={
            municipalityInvalid &&
            validateField("Municipality", address?.municipalityId).validationErrorText
          }
          onBlur={() => {
            const invalidField = validateField("Municipality", address?.municipalityId).isInvalid;

            if (invalidField) {
              setMunicipalityInvalid(true);
            }

            setMunicipalityInvalid(false);
          }}
        >
          <MenuItem value={0}>Please Select</MenuItem>
          {municipalities.filter((muni) => muni.provinceId === address?.provinceId).map((municipality) => (
            <MenuItem key={municipality.municipalityId} value={municipality.municipalityId}>
              {municipality.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      setAddressComparer(addressDetails);
      HandleCountryChange(address.countryId.toString(), initialLoadCheck.current);
      initialLoadCheck.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails.addressId, address.addressId, countries.length, provinces.length, municipalities.length]);

  useEffect(() => {
    if (edit) handleCopyPhysical(address, sameAsPhysical);
  }, [sameAsPhysical]);

  useEffect(() => {
    dispatch(setCompanyAddressState(debouncedAddressValue));
  }, [debouncedAddressValue]);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      id="step-address"
      onChange={handleChange("panel1")}
      className={
        address.addressTypeId === AddressType.Physical ? styles.firstAccord : "Accord-Hide"
      }
    >
      <AccordionSummary
        expandIcon={
          address.addressTypeId !== AddressType.Physical ? (
            <ExpandMoreIcon className="expand-icon-white" />
          ) : (
            ""
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">
            {address.addressTypeId === AddressType.Physical ? "Physical Address" : "Postal Address"}
          </h3>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.editSave}>
          <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-general-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <div className={styles.btnBox}>
                <div className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={EnableEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
                <div className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </WithRequiredPermission>
        </div>
        <div className={styles.postBody}>
          <div className={styles.postInfo}>
            {address.addressTypeId !== AddressType.Physical && (
              <Grid container className="g-container">
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sameAsPhysical}
                          onClick={handleSwitchOnClick}
                          disabled={!edit}
                        />
                      }
                      label="Same as Physical?"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            )}

            <Grid container className="g-container">
              <Grid item xs={6}>
                <FormControl
                  sx={{ width: "98%", marginTop: "8px", marginBottom: "5px", marginRight: "2%" }}
                >
                  <InputLabel sx={{ fontSize: "14px" }} id="address-country-label">
                    Country
                  </InputLabel>
                  <Select
                    label="Country"
                    id="address-country"
                    margin="dense"
                    inputProps={{ style: { fontSize: 14 } }}
                    value={address?.countryId || 0}
                    size="small"
                    disabled={countryCheck()}
                    onChange={(ev) => {
                      ev.preventDefault();
                      HandleCountryChange(ev.target.value.toString());
                    }}
                    input={<OutlinedInput label="Country" />}
                  >
                    <MenuItem value={0}>Please Select</MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {filteredProvinces.length > 0 && (
                  <FormControl
                    sx={{
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "5px",
                    }}
                  >
                    <InputLabel sx={{ fontSize: "14px" }} id="address-province-label">
                      Province
                    </InputLabel>
                    <Select
                      label="Province"
                      id="address-province"
                      margin="dense"
                      inputProps={{ style: { fontSize: 14 } }}
                      value={address?.provinceId || 0}
                      size="small"
                      disabled={!edit}
                      onChange={(ev) => {
                        handleProvinceSelectChange(ev);
                      }
                      }
                      input={<OutlinedInput label="Province" />}
                    >
                      <MenuItem value={0}>Please Select</MenuItem>
                      {filteredProvinces.map((province) => (
                        <MenuItem key={province.id} value={province.id}>
                          {province.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container className="g-container">
              <Grid item xs={4}>
                <TextField
                  type="text"
                  sx={{ width: "98%", marginRight: "2%" }}
                  disabled={!edit}
                  id="address-line-1"
                  label="Address Line 1"
                  value={address?.line1 || ""}
                  size="small"
                  onChange={(ev) => handleValueChange(ev, "line1")}
                  error={line1Invalid}
                  helperText={
                    line1Invalid &&
                    validateField("Address Line 1", address?.line1).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField("Address Line 1", address?.line1).isInvalid;

                    if (invalidField) {
                      setLine1Invalid(true);
                    }

                    setLine1Invalid(false);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  sx={{ width: "98%", marginRight: "2%" }}
                  disabled={!edit}
                  id="address-line-2"
                  label="Address Line 2 (optional)"
                  value={address?.line2 || ""}
                  size="small"
                  onChange={(ev) => handleValueChange(ev, "line2")}
                  error={line2Invalid}
                  helperText={
                    line2Invalid &&
                    validateField("Address Line 2", address?.line2).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField("Address Line 2", address?.line1).isInvalid;

                    if (invalidField) {
                      setLine2Invalid(true);
                    }

                    setLine2Invalid(false);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  disabled={!edit}
                  id="address-suburb"
                  label="Suburb"
                  value={address?.suburb || ""}
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={(ev) => handleValueChange(ev, "suburb")}
                  error={suburbInvalid}
                  helperText={
                    suburbInvalid && validateField("Suburb", address?.suburb).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField("Suburb", address?.suburb).isInvalid;

                    if (invalidField) {
                      setSuburbInvalid(true);
                    }

                    setSuburbInvalid(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="g-container">
              <Grid item xs={4}>
                <TextField
                  type="text"
                  disabled={!edit}
                  id="address-city"
                  label="City"
                  value={address?.city || ""}
                  size="small"
                  sx={{ width: "98%", marginRight: "2%" }}
                  onChange={(ev) => handleValueChange(ev, "city")}
                  error={cityInvalid}
                  helperText={
                    cityInvalid && validateField("City", address?.city).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField("City", address?.city).isInvalid;

                    if (invalidField) {
                      setCityInvalid(true);
                    }

                    setCityInvalid(false);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  disabled={!edit}
                  id="address-code"
                  label="Postal/Zip Code"
                  value={address?.postalCode || ""}
                  size="small"
                  sx={{ width: "98%", marginRight: "2%" }}
                  onChange={(ev) => handleValueChange(ev, "postalCode")}
                  error={zipCodeInvalid}
                  helperText={
                    zipCodeInvalid &&
                    validateField("Postal/Zip Code", address?.postalCode).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField(
                      "Postal/Zip Code",
                      address?.postalCode
                    ).isInvalid;

                    if (invalidField) {
                      setzipCodeInvalid(true);
                    }

                    setzipCodeInvalid(false);
                  }}
                />
              </Grid>
              {
                renderMunicipalityDropdown()
              }
            </Grid>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Address;
