import { useEffect, useState } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import OnBoardingNavigation from "../../components/OnBoarding/OnBoardingNavigation";
import BasePage from "../BasePage";
import OnBoardingSteps from "../../components/OnBoarding/OnBoardingSteps";
import { StepsModel } from "../../components/OnBoarding/StepsModel";
import { OnBoardingRulesViewModel } from "../../http/Redux/Models/OnboardingRulesViewModel";
import OnBoardingServices from "../../http/OnBoardingServices";
import OnBoardingStepperSkeleton from "../../components/OnBoarding/OnBoardingStepperSkeleton";
import { setCompanyViewModelState } from "../../http/Redux/Store/companySlice";
import { GetCompanyDataById } from "../../http/Company/company";
import { useAppDispatch } from "../../app/hooks";

const OnBoardingMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, getSteps] = useState<StepsModel[]>([]);
  const [stepDescription, setStepDescription] = useState("");
  const [stepLength, setStepLength] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [companyName, getCompanyName] = useState("");
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const { companyId } = useParams();
  const account = useAccount(accounts[0] || {});

  const handleNavigation = (description: string, length: number, index: number) => {
    setStepDescription(description);
    setStepLength(length);
    setStepIndex(index);
    setActiveStep(index);


  }


  useEffect(() => {

    if (steps.length === 0) {
      const loader = async () => {
        setIsLoading(true);
        const rules: OnBoardingRulesViewModel[] = (await OnBoardingServices.getCompanyOnboardingRules(239272)).data;
        const lastTimeout = rules.length > 0 ? rules[rules.length - 1].timeout : 0;
        // Add the "Overview" step as the first step
        const updatedSteps: StepsModel[] = [
          {
            id: 0,
            label: "Overview",
            model: 0,
            description: "",
            status: 0,
            section: "Overview",
            timeout: 1000,
            sets: [],
          },
          ...rules.map((rule: OnBoardingRulesViewModel) => ({
            id: rule.displayOrder, // Assuming displayOrder is unique for each rule
            model: rule.model,
            label: rule.label,
            description: rule.description,
            status: 1,
            section: rule.ruleName,
            timeout: rule.timeout,
            sets: rule.ruleSet,
          })),
          // Add the "Documents" step as the last step
          {
            id: rules.length + 1,
            label: "Documents",
            model: 0,
            description: "Required Company Documents",
            status: 6,
            section: "Last",
            timeout: lastTimeout + 200,
            sets: [],
          },
        ];
        getCompanyName(rules[0].registeredName);
        getSteps(updatedSteps);

        // Get company details full 

        const result = await GetCompanyDataById(account?.idTokenClaims?.extension_companyid as number, false);
        dispatch(setCompanyViewModelState(result.data));
      }
      loader().finally(() => {
        setIsLoading(false);
      });

    }

  }, [stepDescription, activeStep, stepIndex, stepLength, steps.length, isLoading])

  const handleNext = (status: number) => {
    if (status > 0) {
      steps[activeStep].status = status;
      getSteps(steps);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStepDescription(steps[activeStep + 1].description as string);
    setStepLength(steps.length);
    setStepIndex(activeStep + 1);


  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStepDescription(steps[activeStep - 1].description as string);
    setStepLength(steps.length);
    setStepIndex(activeStep - 1);
  };


  return (
    <>
      <LoadingBackdrop showBackdrop={isLoading} />
      <BasePage pageHeader="OnBoarding Request" pageHeaderSub={companyName}>
        <Grid container>
          <Grid xs={2} sx={{ paddingLeft: "20px" }}>
            {isLoading ? (
              <OnBoardingStepperSkeleton />
            ) : (
              <OnBoardingNavigation
                currentStep={activeStep}
                steps={steps}
                handleNavigation={handleNavigation} />
            )}
          </Grid>
          <Grid xs={10} sx={{ paddingLeft: "10px" }}>
            {isLoading ? (
              <Box sx={{ mb: 2, width: "100%", padding: "10px" }}>
                <Skeleton variant="rounded" sx={{ width: "98%", marginBottom: 2 }} height={50} />
                <Skeleton variant="rounded" sx={{ width: "98%" }} height={400} />
              </Box>

            ) : (
              <OnBoardingSteps
                stepDescription={stepDescription}
                stepLength={stepLength}
                companyId={Number(companyId)}
                pullSection={steps[stepIndex]?.model as number}
                set={steps[stepIndex]?.sets}
                heading={steps[stepIndex]?.label as string}
                steps={steps}
                stepIndex={stepIndex}
                handleNavigation={handleNavigation}
                handleNext={handleNext}
                handleBack={handleBack} />
            )}

          </Grid>
        </Grid>
      </BasePage>
    </>
  )
}


export default OnBoardingMain;
