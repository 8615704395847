import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CreateOrUpdateCompanyRegistrationStatus } from "../../../http/Company/CompanyRegistration";
import { UpsertCompanyRegistrationMessage } from "../../../http/Company/Models/upsertCompanyRegistrationMessage";
import queryKeys from "../queries/queryKeys";
import { CompanyRegistrationMessage } from "../../../http/Company/Models/companyRegistrationMessage";

// Read more : https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
const useCompanyRegistrationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (message: UpsertCompanyRegistrationMessage) => CreateOrUpdateCompanyRegistrationStatus({
      id: message.id,
      lastCompletedStep: message.lastCompletedStep,
      registrationCompleted: message.registrationCompleted,
    }) ,
    // When mutate is called:
    onMutate: async (newCompanyRegistrationDetails) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyRegistrationInfo] })

      // Snapshot the previous value
      const previousCompanyRegistrationData = queryClient.getQueryData([queryKeys.companyRegistrationInfo])

      // Optimistically update to the new value
      queryClient.setQueryData([queryKeys.companyRegistrationInfo], (old: CompanyRegistrationMessage) => ({...old,newCompanyRegistrationDetails}))

      // Return a context object with the snapshotted value
      return { previousCompanyRegistrationData }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyRegistrationInfo], context?.previousCompanyRegistrationData)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyRegistrationInfo] })
    },
  })
}

export default useCompanyRegistrationMutation;