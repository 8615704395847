import { AddressModel } from "../../../http/Company/Models/AddressModel";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import companyDataProps from "../../../pages/Registration/CustomComponents/Models/companyViewModelProps";
import AddressType from "../../enums/AddressType";
import useValidation from "./useValidation";

const useCompanyInformationRegValidation = (companyData : CompanyViewModel, defaultCountryId: number) => {
  const physicalAddress = companyData.companyAddresses ?
    companyData.companyAddresses.find(x => x.addressTypeId === AddressType.Physical) :
    {} as unknown as AddressModel;

  const postalAddress = companyData.companyAddresses ?
    companyData.companyAddresses.find(x => x.addressTypeId === AddressType.Postal) :
    {} as unknown as AddressModel;
    
  // validation
  const [validateFields] = useValidation(
    [
      // Registration information
      {
        displayName: "Registered company name",
        fieldName: companyDataProps.registeredName,
        fieldValue: companyData.registeredName,
        required: true,
      },
      {
        displayName: "Company registration number",
        fieldName: companyDataProps.registrationNumber,
        fieldValue: companyData.registrationNumber,
        required: true,
      },
      {
        displayName: "Company type",
        fieldName: companyDataProps.companyTypeId,
        fieldValue: companyData.companyTypeId,
        required: true,
      },

      // Phycial Address
      {
        displayName: "Physical Address - Street address line 1",
        fieldName: "physicalAddress:line1",
        fieldValue: physicalAddress?.line1,
        required: true,
      },
      {
        displayName: "Physical Address - Street address line 2",
        fieldName: "physicalAddress:line2",
        fieldValue: physicalAddress?.line2,
        required: true,
      },
      {
        displayName: "Physical Address - City",
        fieldName: "physicalAddress:city",
        fieldValue: physicalAddress?.city,
        required: true,
      },
      {
        displayName: "Physical Address - Country",
        fieldName: "physicalAddress:countryId",
        fieldValue: physicalAddress?.countryId,
        required: true,
      },
      {
        displayName: "Physical Address - Provice",
        fieldName: "physicalAddress:provinceId",
        fieldValue: physicalAddress?.provinceId,
        required: physicalAddress?.countryId === defaultCountryId,
      },
      {
        displayName: "Physical Address - Postal Code",
        fieldName: "physicalAddress:postalCode",
        fieldValue: physicalAddress?.postalCode,
        required: true,
      },

      // Postal Address
      {
        displayName: "Postal Address - Street address line 1",
        fieldName: "postalAddress:line1",
        fieldValue: postalAddress?.line1,
        required: true,
      },
      {
        displayName: "Postal Address - Street address line 2",
        fieldName: "postalAddress:line2",
        fieldValue: postalAddress?.line2,
        required: true,
      },
      {
        displayName: "Postal Address - City",
        fieldName: "postalAddress:city",
        fieldValue: postalAddress?.city,
        required: true,
      },
      {
        displayName: "Postal Address - Country",
        fieldName: "postalAddress:countryId",
        fieldValue: postalAddress?.countryId,
        required: true,
      },
      {
        displayName: "Postal Address - Provice",
        fieldName: "postalAddress:provinceId",
        fieldValue: postalAddress?.provinceId,
        required: postalAddress?.countryId === defaultCountryId,
      },{
        displayName: "Postal Address - Postal Code",
        fieldName: "postalAddress:postalCode",
        fieldValue: postalAddress?.postalCode,
        required: true,
      },

    ]);
  return validateFields
}

export default useCompanyInformationRegValidation;