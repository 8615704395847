import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ComplianceVerificationTypeEnum } from "../../http/Compliance/ComplianceVerificationTypesEnum";
import SubCheckResultStatus from "../../util/enums/SubCheckResultStatus";
import { SubCheckResult } from "../../http/Compliance/SubCheckResult";
import { getMyComplianceVerificationTypeDetails } from "../../http/Compliance/complianceApi";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";

const ViewList = ({ verificationTypeId }: { verificationTypeId: number }) => {
  const [subCheckResults, setSubCheckResults] = useState<Array<SubCheckResult> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loader = async () => {
      if (verificationTypeId) {
        try {
          setIsLoading(true);
          const result = await getMyComplianceVerificationTypeDetails(
            verificationTypeId as unknown as ComplianceVerificationTypeEnum);
          setSubCheckResults(result);

        } catch (error) {
          setIsLoading(false);
          toast.error("Something went wrong: could not load compliance result details");
        }
      }
    }
    loader();
    setIsLoading(false);
  }, [verificationTypeId, isLoading]);

  return (

    <Box
      sx={{ width: "100%" }}
      role="presentation"
    >
      <LoadingBackdrop showBackdrop={isLoading} />
      {
        (subCheckResults == null || subCheckResults?.length <= 0) ? (
          <List>

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={<i>No results found</i>} />
              </ListItemButton>
            </ListItem>

          </List>
        )
          : (
            <>
              <List>
                {subCheckResults?.filter(x => x.status === SubCheckResultStatus.Failed).map((check, index) => (
                  <ListItem key={`valid-${index}`} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <CloseIcon color="warning" />
                      </ListItemIcon>
                      <ListItemText primary={check.message} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <List>
                {subCheckResults?.filter(x => x.status === SubCheckResultStatus.Passed).map((check, index) => (
                  <ListItem key={`valid-${index}`} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary={check.message} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>)
      }
    </Box>

  )
};


export default ViewList;