import React from "react";
// import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import SearchView from "../../components/Header/Search/SearchView";
import styles from "./Search.module.scss";
import { ConnectionSearchModel } from "../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { PerformSearch } from "../../http/NetworkAndConnect/Netconn";

const DirectorySearch = () => {
  const executeSearch = async (searchModel: ConnectionSearchModel) => {
    const results = await PerformSearch(searchModel);
    return results;
  }

  return (
    <div className="wrap-no-padding">
      <div className={styles.searchBoxWrap}>
        <div className={styles.resultWrap}>
          <Accordion expanded>
            <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography sx={{ width: "100%", flexShrink: 0 }}>
                <h3 className="heading-sub">Directory Search</h3>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordnetconnectlist}>
              <SearchView
                useReduxForSearchText
                searchCallback={executeSearch}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default DirectorySearch;
