/* eslint-disable no-unused-vars */
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Grid } from "@mui/material";
import { IRegistrationStepPageComponent, RegistrationStepResponse } from "./IRegistrationStepComponent";
import { SecondaryIcon, PrimaryIcon } from "../../../util/MUI_Theme/Icons";
import TextField from "../CustomComponents/Controls/TextField";
import { GetCompanyDataById, SaveEntireCompany } from "../../../http/Company/company";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import ContactType from "../../../util/enums/ContactType";
import { ContactModel } from "../../../http/Company/Models/ContactModel";
import { AddNewContactDetails } from "../../../http/Company/Contact";
import useCompanyContactValidation from "../../../util/hooks/validation/useCompanyContactValidation";
import ContactModelProps from "../CustomComponents/Models/contactModelProps";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import RegistrationSkeleton from "../Sections/RegistrationSkeleton";

const CompanyContactStepTwo = forwardRef<IRegistrationStepPageComponent, unknown>((_props, ref) => {

  const [companyData, setCompanyData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [isLoading, setIsLoading] = useState(true);

  const { data: baseCompanyInfo } = useBaseCompanyInfo();

  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const validateCompanyData = useCompanyContactValidation(companyData);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const companyDetails = await GetCompanyDataById(baseCompanyInfo?.companyId ?? 0, false);
      setCompanyData(companyDetails.data);
      await new Promise(resolve => setTimeout(resolve, 2000)); // eslint-disable-line no-promise-executor-return
      setIsLoading(false);
    };
    if (baseCompanyInfo?.companyId)
      loadData();
  }, [baseCompanyInfo?.companyId])

  const onSubmitHandler = async () => {
    try {

      const validationResults = validateCompanyData();
      if (validationResults.isInvalid) {
        setInvalidFields(validationResults.invalidFields);
        return {
          submittedSuccessfully: false,
          errorMessages: validationResults.validationErrors,
        } as RegistrationStepResponse;
      }
      const newContact = companyData.companyContacts.find(x => x.id == null) as unknown as ContactModel;

      if (newContact) {
        newContact.companyId = baseCompanyInfo?.companyId ?? 0;
        await AddNewContactDetails(newContact, false);
      } else {
        await SaveEntireCompany(companyData, false);
      }

      const response: RegistrationStepResponse = {
        submittedSuccessfully: true,
        errorMessages: [],
      };
      return response;

    } catch (error) {
      const response: RegistrationStepResponse = {
        submittedSuccessfully: false, // Correct this to indicate failure
        errorMessages: ["Something went wrong! BadRequest"],
      };
      return response;
    }
  };

  const onSaveAndContinueHandler = async () => {
    await SaveEntireCompany(companyData, false);
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };
  };

  // Expose the methods and properties useImperativeHandle (the will be accessible form the ref)
  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));

  const handleContactUpdate = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, contactType: ContactType) => {
    const fieldName = ev.currentTarget.name;
    const fieldValue = ev.currentTarget.value;

    if (fieldName && companyData.companyContacts) {
      const contacts = [...companyData.companyContacts];
      const contactIndex = companyData.companyContacts.findIndex(x => x.contactTypeId === contactType);
      if (contactIndex !== -1) {

        const updatedContact = { ...contacts[contactIndex], [fieldName]: fieldValue };
        contacts[contactIndex] = updatedContact;
        setCompanyData((prevState) => ({ ...prevState, companyContacts: contacts }));
      }
      else {
        const newContact = { contactTypeId: contactType } as ContactModel;
        const updatedContact = { ...newContact, [fieldName]: fieldValue };
        contacts.push(updatedContact);
        setCompanyData((prevState) => ({ ...prevState, companyContacts: contacts }));
      }
    }
  }

  const primaryContact = companyData.companyContacts ?
    companyData.companyContacts.find(x => x.contactTypeId === ContactType.Primary) :
    {} as unknown as ContactModel;

  const secondaryContact = companyData.companyContacts ?
    companyData.companyContacts.find(x => x.contactTypeId === ContactType.Secondary) :
    {} as unknown as ContactModel;


  return (
    isLoading ? <RegistrationSkeleton /> :
      <Box mx={0} px={0}>
        {/* Primary Company Representative Section */}
        <Grid container sx={{ marginLeft: "20px", marginTop: "20px" }}>
          <Grid item xs={12} className="profile-view-labels">
            <PrimaryIcon />
            PRIMARY COMPANY REPRESENTATIVE
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={4}>
              <TextField
                disabled
                name="primaryContactFullName"
                label="Full name and Surname"
                value={primaryContact?.name ?? ""}
                error={invalidFields.includes(ContactModelProps.name)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                disabled
                label="Email Address"
                name="primaryContactEmail"
                value={primaryContact?.email ?? ""}
                error={invalidFields.includes(ContactModelProps.email)}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                required
                name="mobile"
                label="Contact Number"
                value={primaryContact?.mobile ?? ""}
                onChange={(ev) => {
                  handleContactUpdate(ev, ContactType.Primary);
                }}
                error={invalidFields.includes(ContactModelProps.mobile)}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Secondary Company Representative Section */}
        <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
          <Grid item xs={12} className="profile-view-labels">
            <SecondaryIcon />
            SECONDARY COMPANY REPRESENTATIVE - Optional
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={4}>
              <TextField
                required={false}
                name="name"
                placeholder="Enter secondary company representative name"
                label="Full name and Surname"
                value={secondaryContact?.name ?? ""}
                onChange={(ev) => handleContactUpdate(ev, ContactType.Secondary)} />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                required={false}
                name="email"
                placeholder="Enter secondary company representative email"
                label="Email Address"
                value={secondaryContact?.email ?? ""}
                onChange={(ev) => handleContactUpdate(ev, ContactType.Secondary)} />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                required={false}
                placeholder="Enter secondary company representative number"
                name="mobile"
                label="Contact Number"
                value={secondaryContact?.mobile ?? ""}
                onChange={(ev) => handleContactUpdate(ev, ContactType.Secondary)} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
  )
});

export default CompanyContactStepTwo;