/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum NetconnSearchType {
  None = 0,
  Client = 1,
  Supplier = 2,
  Both = 3,
  Suggested = 4,
  Potential = 5,
  RequestReceived = 6,
  RequestMade = 7,
  Invite = 8,
}

export default NetconnSearchType;

export const netconnSearchTypeToString = (value: NetconnSearchType) => {
  switch (value) {
    case NetconnSearchType.None:
      return "None";
    case NetconnSearchType.Client:
      return "Client";
    case NetconnSearchType.Supplier:
      return "Supplier";
    case NetconnSearchType.Both:
      return "Both";
    case NetconnSearchType.Suggested:
      return "Suggested";
    case NetconnSearchType.Potential:
      return "Potential";
    default:
      return "Unknown Search Type";
  }
};
