import { protectedResources } from "../../authConfig";
import { NewConnectionInvitationMessage } from "../../pages/NewUI/NewConnectionInvitations/InvitationsListComponent";
import ConnectionType, { connectionTypeToString } from "../../util/enums/ConnectionType";
import makeGetRequestAsync, { downloadFileAsync,
  makeDeleteRequestNoBodyAsync, makePUTRequestAsync, makePostRequestAsync,
} from "../Helpers/httpRequestHelpers";
import { SupplierInviteModel } from "./Models/SupplierInviteModel";
import { SupplierModel } from "./Models/SupplierModel";

const apiScopes = protectedResources.cleanWebApi.scopes;
const baseUrl = protectedResources.cleanWebApi.endpoint;

/*
 *  Retrieves all suppliers
 */
export async function GetAllSuppliers() {
  const url = `${baseUrl}/api/Clean/Supplier`;
  return makeGetRequestAsync<Array<SupplierModel>>(apiScopes, url);
}

/*
 *  Retrieves all suppliers based on a passed companyId
 */
export async function GetSuppliersByCompanyId(connectionType: ConnectionType, processedStatus: boolean) {
  const url = `${baseUrl}/api/Clean/Supplier/GetSuppliersByCompanyId/${connectionType}/${processedStatus}`;
  return makeGetRequestAsync<Array<SupplierModel>>(apiScopes, url);
}

/*
 *  Retrieves a supplier based on a passed tempCompanyId
 */
export async function GetSupplierByTempCompanyId(tempCompanyId: string) {
  const url = `${baseUrl}/api/Clean/Supplier/GetSupplierByTempCompanyId/${tempCompanyId}`;
  return makeGetRequestAsync<SupplierModel>(apiScopes, url);
}

/*
 *  Resend a supplier invite
 */
export async function UpdateAndResendSupplierInvite(data: SupplierModel) {
  const url = `${baseUrl}/api/Clean/Supplier/Invitation`;
  return makePUTRequestAsync<void,SupplierModel>(apiScopes, url,data);
}

/*
 *  Delete a supplier invite
 */
export async function DeleteSupplierUploadInvitation(tempCompanyId: string) {
  const url = `${baseUrl}/api/Clean/Supplier/${tempCompanyId}`;
  return makeDeleteRequestNoBodyAsync(apiScopes,url);
}

/*
 *  Delete many supplier invites
 */
export async function DeleteMultipleSupplierUploads(tempCompanyIdList: Array<string>) {
  const url = `${baseUrl}/api/Clean/Supplier/DeleteMultiple`;
  return makePostRequestAsync<void,Array<string>>(apiScopes, url, tempCompanyIdList);
}

/*
 *  download excel extract
 */
export async function downloadSupplierUploadsExcelExport(
  connectionType: ConnectionType,
  status : string
) {
  const downloadUrl = `${baseUrl}/api/Clean/Supplier/export/${connectionType}?status=${status}`;
  await downloadFileAsync(apiScopes,
    downloadUrl,
    `${connectionTypeToString(connectionType)}_list_download.xlsx`);
}

/*
 *  Resend a supplier invite
 */
export async function ResendMultipleSupplierInvites(data: Array<string>) {
  const url = `${baseUrl}/api/Clean/Invitation/ResendMultiple`;
  return makePostRequestAsync<void, Array<string>>(apiScopes, url, data);
}

export async function SendManualSupplierInvitation(data: SupplierInviteModel) {
  const url = `${baseUrl}/api/Clean/Invitation`;
  const result = await makePostRequestAsync<void, SupplierInviteModel>(apiScopes, url, data);

  return result.status;
}

/*
 *  Send new connection request invitation emails
 */
export async function SendNewConnectionRequests(data: NewConnectionInvitationMessage[]) {
  const url = `${baseUrl}/api/Clean/Invitation/NewConnectionRequests`;
  const result = await makePostRequestAsync<void, NewConnectionInvitationMessage[]>(apiScopes, url, data);
  return result.status;
}