// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";

export function ConvertToStartCase(value: string) {
  return _.startCase(value);
}

// Inserts a space between two words in a Pascal-cased string.
export function InsertSpaceBetweenWords(value: string) {
  const spaced =  value.replace(/([a-z])([A-Z])/, "$1 $2");
  return spaced;
}

