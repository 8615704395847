import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import Vouchers from "./Vouchers";
import AddVoucher from "./AddVoucher";
import { useAppDispatch } from "../../../../app/hooks";
import { fetchVouchers } from "../../../../http/Redux/Store/SubscriptionActions";
import styles from "../../../../pages/Configuration/PlatformBase.module.scss";

const Subscriptions = () => {
  const dispatch = useAppDispatch();

  const [isVoucher, setIsVoucher] = useState(true);
  const [isAddVoucher, setIsAddVoucher] = useState(false);
  const [isVoucherBtn, setIsVoucherBtn] = useState(true);
  const [isAddVoucherBtn, setIsAddVoucherBtn] = useState(false);

  const EditIsOpenOnVouchers = useCallback(
    (bool: boolean) => {
      if (bool) {
        setIsVoucherBtn(false);
        setIsAddVoucherBtn(false);
      } else {
        setIsVoucherBtn(true);
      }
    },
    [isAddVoucherBtn]
  );

  const handleAddVoucher = () => {
    setIsAddVoucher(true);
    setIsVoucher(false);
    setIsAddVoucherBtn(true);
    setIsVoucherBtn(false);
  };

  const handleVoucherList = () => {
    setIsAddVoucher(false);
    setIsVoucher(true);
    setIsAddVoucherBtn(false);
    setIsVoucherBtn(true);
  };

  useEffect(() => {
    dispatch(fetchVouchers());
  }, []);

  return (
    <div>
      <div className="tab-section-sub">
        <div className={styles.btnBoxSub}>
          <div className={isVoucherBtn ? "active" : "not-active"}>
            <Tooltip title="Add New Voucher">
              <Button variant="contained" className="form-edit-btn-sub" onClick={handleAddVoucher}>
                <AddIcon />
                <span>Add Voucher</span>
              </Button>
            </Tooltip>
          </div>
          <div className={isAddVoucherBtn ? "active" : "not-active"}>
            <Tooltip title="View All Vouchers">
              <Button variant="contained" className="form-edit-btn-sub" onClick={handleVoucherList}>
                <ListIcon />
                <span>View Voucher</span>
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className={isVoucher ? "active" : "not-active"}>
          <Vouchers editIsOpenOnVouchers={EditIsOpenOnVouchers} />
        </div>
        <div className={isAddVoucher ? "active" : "not-active"}>
          <AddVoucher />
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
