import React, { useEffect, useState, useRef, useCallback } from "react";
import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  MenuItem,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CompanyViewModel } from "../../../../http/Company/Models/CompanyViewModel";
import "../CompanyPost.css";
import VerificationSection from "../../../WorkCenter/Verification/VerificationSection";
import WithRequiredPermission from "../../../WithRequiredPermission";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import useAllowedPermission from "../../../../util/hooks/useAllowedPermission";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import useSubscriptionPermissionCheck from "../../../../util/hooks/useSubscriptionPermissionCheck";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyViewModelState } from "../../../../http/Redux/Store/companySlice";
import { ComplianceStatus } from "../../../../http/Compliance/ComplianceStatus";
import { getMyComplianceResults } from "../../../../http/Compliance/complianceApi";
import { ComplianceVerificationTypeEnum } from "../../../../http/Compliance/ComplianceVerificationTypesEnum";
import useCompanyTypes from "../../../../util/hooks/queries/useCompanyTypes";

interface CompanyDetailPostProps {
  // eslint-disable-next-line react/require-default-props
  headerText?: string;
  companyData: CompanyViewModel | undefined;
  workcentreAgentPath: boolean;
  // eslint-disable-next-line react/require-default-props
  agentTaskId?: number;
  // eslint-disable-next-line no-unused-vars
  OnSave: (companyToSave: CompanyViewModel) => void;

  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;
  staged: boolean;
}

const CompanyDetailsPost = ({
  headerText = "",
  companyData,
  workcentreAgentPath,
  agentTaskId = 0,
  OnSave,
  SetDirtyCallback,
  staged,
}: CompanyDetailPostProps) => {
  const dispatch = useAppDispatch();

  const { data: countries } = useCompanyTypes();
  const { data: companyTypes } = useCompanyTypes();
  const baseCompanyInfo = useAppSelector(x => x.baseCompanyInfo);
  const initialLoadCheck = useRef(true);
  const [data, setData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [originalData, setOriginalData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [edit, setEdit] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const hasAllowedPermission = useAllowedPermission();
  const hasAllowedSubscriptionPermission = useSubscriptionPermissionCheck();
  const [validateField] = useFieldValidation("companyProfile");
  const [complianceStatus, setComplianceStatus] = useState(ComplianceStatus.NotApplicable);
  const [complianceHasRun, setComplianceHasRun] = useState<boolean>(false);

  const debouncedCompanyValue = useInputDebounce(data);

  // Validation Error State Variables
  const [regNameInvalid, setRegNameInvalid] = useState<boolean>(false);
  const [tradingNameInvalid, setTradingNameInvalid] = useState<boolean>(false);
  const [holdingCompanyInvalid, setHoldingCompanyInvalid] = useState<boolean>(false);
  const [compRegNoInvalid, setCompRegNoInvalid] = useState<boolean>(false);
  const [compTypeInvalid, setCompTypeInvalid] = useState<boolean>(false);
  const [countryInvalid, setCountryInvalid] = useState<boolean>(false);

  const togglePanelExpansion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const EnableEdit = () => {
    if (edit) {
      OnSave(data);
      setOriginalData(data);
      SetDirtyCallback(false);
    }
    setEdit(!edit);
  };

  const subscriptionHasCompliance = () => {
    const subCheck = hasAllowedSubscriptionPermission([
      PermissionClassification.ViewComplianceDetail,
      PermissionClassification.ViewComplianceManagementDashboard,
      PermissionClassification.ViewMyComplianceStatus,
      PermissionClassification.ViewMyComplianceDetail,
    ]);

    return subCheck;
  }

  // This function is a tad confusing (even for the author).
  // Returning true DISABLES the fields. Returning false allows edits.
  const forceDisableField = useCallback(() => {
    // 1. Check if the subscription has compliance permissions loaded:
    const subscriptionHasCompliancePermission = subscriptionHasCompliance();

    // 2. Allow the edit if no compliance permissions are detected on the subscription.
    if (!subscriptionHasCompliancePermission) return false;

    // 3. If permissions are detected, get the overall compliance status:
    const companyComplianceStatus = complianceStatus;

    // 4. The field should only be disabled if compliance is passed
    const isAllowedToEditFields = companyComplianceStatus !== ComplianceStatus.Passed;
    return !isAllowedToEditFields
  }, [edit, complianceStatus]);

  const performDirtyCheck = (checkVal: CompanyViewModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(originalData)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...data, [propName]: event.target.value };
    setData(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    const loadComplianceData = async () => {
      try {
        const complianceData = await getMyComplianceResults();
        const cipcData = complianceData.find(
          (x) => x.verificationType === ComplianceVerificationTypeEnum.CIPC
        );
        setComplianceStatus(cipcData?.status ?? ComplianceStatus.NotApplicable);
        setComplianceHasRun(true);
      } catch {
        setComplianceStatus(ComplianceStatus.NotApplicable);
      }
    };

    if (initialLoadCheck.current) {
      setData(companyData?.companyId ? { ...companyData } : {} as CompanyViewModel);
      setOriginalData(companyData?.companyId ? { ...companyData } : {} as CompanyViewModel);

      if (!complianceHasRun)
        loadComplianceData();
    }

    // Turn the initial check off when company objects are not null/undefined.
    if (companyData?.companyId && data.companyId && (companyData?.companyId === baseCompanyInfo.companyId)) {
      initialLoadCheck.current = false;
    }

  }, [companyData?.companyId, data.companyId, data.registrationNumber, staged]);

  useEffect(() => {
    dispatch(setCompanyViewModelState(debouncedCompanyValue));
  }, [debouncedCompanyValue]);

  const CancelEdit = () => {
    setEdit(false);
    setData(originalData);
    SetDirtyCallback(false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={togglePanelExpansion("panel1")}
      className="Accord-Hide"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        className="Accord-heading"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) && workcentreAgentPath && (
          <div className="post__header_top">
            <VerificationSection
              section="CompanyDetails"
              companyId={companyData?.companyId || data.companyId}
              staged={staged}
              agentTaskId={agentTaskId}
            />
          </div>
        )}

        <div className={styles.editSave}>
          <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-general-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <div className={styles.btnBox}>
                <div className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={EnableEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
                <div className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </WithRequiredPermission>
        </div>


        <div className="post__body">
          <div className="post__company__info">
            <Grid container className="g-container">
              <Grid item xs={12}>
                <Tooltip title={forceDisableField() ? "Already verified by CIPC checks" : ""}>
                  <TextField
                    type="text"
                    disabled={!edit || forceDisableField()}
                    sx={{ width: "100%" }}
                    value={data.registeredName || ""}
                    label="Registered Company Name"
                    size="small"
                    onChange={(ev) => {
                      const newVal = { ...data, registeredName: ev.target.value };
                      setData(newVal);
                      performDirtyCheck(newVal);
                    }}
                    error={regNameInvalid}
                    helperText={
                      regNameInvalid &&
                      validateField("Registered Company Name", data.registeredName)
                        .validationErrorText
                    }
                    onBlur={() => {
                      const invalidField = validateField(
                        "Registered Company Name",
                        data.registeredName
                      ).isInvalid;

                      if (invalidField) {
                        setRegNameInvalid(invalidField);
                        return;
                      }

                      setRegNameInvalid(false);
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container className="g-container">
              <Grid container item xs={12}>
                <TextField
                  type="text"
                  disabled={!edit}
                  sx={{ width: "100%" }}
                  id="trading-company-name"
                  value={data.tradingName || ""}
                  label="Trading Name"
                  size="small"
                  onChange={(ev) => handleValueChange(ev, "tradingName")}
                  error={tradingNameInvalid}
                  helperText={
                    tradingNameInvalid &&
                    validateField("Trading Name", data.tradingName).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField("Trading Name", data.tradingName).isInvalid;

                    if (invalidField) {
                      setTradingNameInvalid(invalidField);
                      return;
                    }

                    setTradingNameInvalid(false);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className="g-container">
              <Grid item xs={12}>
                <TextField
                  disabled={!edit}
                  sx={{ width: "100%" }}
                  value={data.holdingCompanyName || ""}
                  label="Holding Company (optional)"
                  size="small"
                  onChange={(ev) => handleValueChange(ev, "holdingCompanyName")}
                  error={holdingCompanyInvalid}
                  helperText={
                    holdingCompanyInvalid &&
                    validateField(
                      "Holding Company Name",
                      data.holdingCompanyName,
                      data.countryId,
                      data.companyTypeId
                    ).validationErrorText
                  }
                  onBlur={() => {
                    const invalidField = validateField(
                      "Holding Company Name",
                      data.holdingCompanyName,
                      data.countryId,
                      data.companyTypeId
                    ).isInvalid;

                    if (invalidField) {
                      setHoldingCompanyInvalid(invalidField);
                      return;
                    }

                    setHoldingCompanyInvalid(false);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className="g-container">
              <Grid item xs={4}>
                <Tooltip title={forceDisableField() ? "Already verified by CIPC checks" : ""}>
                  <TextField
                    label="Company Registration Number"
                    sx={{ width: "98%", marginRight: "2%" }}
                    disabled={!edit || forceDisableField()}
                    value={data.registrationNumber || ""}
                    size="small"
                    onChange={(ev) => {
                      const newVal = { ...data, registrationNumber: ev.target.value };
                      setData(newVal);
                      performDirtyCheck(newVal);
                    }}
                    error={compRegNoInvalid}
                    helperText={
                      compRegNoInvalid &&
                      validateField(
                        "Company Registration",
                        data.registrationNumber,
                        data.countryId,
                        data.companyTypeId
                      ).validationErrorText
                    }
                    onBlur={() => {
                      const invalidField = validateField(
                        "Company Registration",
                        data.registrationNumber,
                        data.countryId,
                        data.companyTypeId
                      ).isInvalid;

                      if (invalidField) {
                        setCompRegNoInvalid(invalidField);
                        return;
                      }
                      setCompRegNoInvalid(false);
                    }}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={4}>
                <Tooltip title={forceDisableField() ? "Already verified by CIPC checks" : ""}>
                  <TextField
                    label="Company Type"
                    select
                    sx={{ width: "98%", marginRight: "2%" }}
                    disabled={!edit || forceDisableField()}
                    value={data.companyTypeId || 0}
                    size="small"
                    error={compTypeInvalid}
                    helperText={compTypeInvalid && ""}
                    onChange={(ev) => {
                      const newVal = { ...data, companyTypeId: parseInt(ev.target.value, 10) };
                      setData(newVal);
                      performDirtyCheck(newVal);
                    }}
                    onBlur={() => {
                      const invalidField = false;

                      if (invalidField) {
                        setCompTypeInvalid(invalidField);
                        return;
                      }
                      setCompTypeInvalid(invalidField);
                    }}
                  >
                    <MenuItem value={0}>Please Select</MenuItem>
                    {companyTypes?.map((companyType) => (
                      <MenuItem key={companyType.id} value={companyType.id}>
                        {companyType.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>

              <Grid item xs={4} md={4}>
                <Tooltip title={forceDisableField() ? "Already verified by CIPC checks" : ""}>
                  <TextField
                    select
                    sx={{ width: "100%" }}
                    label="Country of Origin"
                    value={data.countryId || 0}
                    size="small"
                    disabled={!edit || forceDisableField()}
                    error={countryInvalid}
                    helperText={countryInvalid && ""}
                    onChange={(ev) => {
                      const newVal = {
                        ...data,
                        countryId: parseInt(ev.target.value, 10),
                      };
                      setData(newVal);
                      performDirtyCheck(newVal);
                    }}
                    onBlur={() => {
                      const invalidField = false;

                      if (invalidField) {
                        setCountryInvalid(invalidField);
                        return;
                      }

                      setCountryInvalid(false);
                    }}
                  >
                    <MenuItem value={0}>Please Select</MenuItem>
                    {countries?.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default CompanyDetailsPost;
