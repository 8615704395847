import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Rating,
  ListItem,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useState } from "react";
import { useNavigate } from "react-router";
import styles from "../Tours/Tour.module.scss";
import { PopupQuestionsModel } from "../../http/Redux/Models/PopupQuestionsModel";
import Hi from "../../util/images/Hi.gif";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import { useAppSelector } from "../../app/hooks";
import { selectCompany } from "../../http/Redux/Store/companySlice";
import { CompanyTypeToString } from "../../util/enums/CompanyTypes";
import UserPromptActivityService from "../../http/UserPromptActivityService";

const Dialog = ({ data, handleClose }: { data: PopupQuestionsModel, handleClose: () => void }) => {
  const company = useAppSelector(selectCompany);
  const [rating, setRating] = useState(0);
  const array: Array<string> = data.description.split(",");
  const navigate = useNavigate();

  const handleRateOnClick = () => {
    const loader = async () => {
      await UserPromptActivityService.AddRating(rating);
    };

    loader();
  };

  const handleComponents = (type: number) => {
    switch (type) {
      case UserPopupActivities.Recommendation:
        return (
          <Grid xs={12}>
            <br />
            <Typography className={styles.tourText}>
              {data.description
                .replace("(type)", CompanyTypeToString(company.companyTypeId))
                .replace("(package)", "B2B")}
            </Typography>
          </Grid>
        );
      case UserPopupActivities.Info:
        return (
          <Grid xs={12}>
            <br />
            <Typography className={styles.tourText}>{data.description}</Typography>
          </Grid>
        );
      case UserPopupActivities.NewFeatures:
        return (
          <Grid container>
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
              {array.map((record) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WorkspacePremiumIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={record} />
                </ListItem>
              ))}
            </List>
          </Grid>
        );
      case UserPopupActivities.Completion:
        return (
          <Grid xs={12}>
            <br />
            <Typography className={styles.tourText}>{data.description}</Typography>
          </Grid>
        );
      case UserPopupActivities.Offer:
        return (
          <Grid xs={12}>
            <br />
            <Typography className={styles.tourText}>{data.description}</Typography>
          </Grid>
        );
      case UserPopupActivities.Invite:
        return (
          <Grid xs={12}>
            <br />
            <Typography className={styles.tourText}>{data.description}</Typography>
          </Grid>
        );
      case UserPopupActivities.Rate:
        return (
          <>
            <Rating
              name="no-value"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue || 0);
              }}
            />
            <TextField
              margin="dense"
              id="name"
              label="Rating"
              size="small"
              multiline
              type="textarea"
              fullWidth
              variant="outlined"
            />
            <Button onClick={() => handleRateOnClick()}>Rate</Button>
          </>
        );
      default:
        return <Grid />;
    }
  };

  const handleRedirect = () => {
    handleClose();
    navigate(data.buttonUrl);
  }

  return (
    <Box className={styles.box}>
      <Grid xs={12}>
        <div className={styles.imageSmallBox}>
          <img className={styles.tourSmallImage} src={Hi} alt="" />
        </div>
      </Grid>
      <Grid container>
        <Grid xs={12}>
          <Typography variant="h5" className={styles.tourHeading}>
            {data.title}
          </Typography>
        </Grid>
        <Grid xs={12}>{handleComponents(data.popupType)}</Grid>
        <Grid xs={12}>
          {data.buttonText !== null && (
            <div>
              {
                data.buttonText.includes("/") ? (
                  <>
                    <Button variant="contained" className={styles.questionBtns} onClick={handleRedirect}>
                      {data.buttonText.split("/")[0]}
                    </Button>
                    <Button variant="contained" className={styles.questionBtns} onClick={handleClose}>
                      {data.buttonText.split("/")[1]}
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" className={styles.questionBtns} onClick={handleClose}>
                    {data.buttonText}
                  </Button>
                )
              }
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dialog;
