import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";


const LoadingBackdrop = ({
  showBackdrop: toggleBackdrop = false,
}: {
  showBackdrop: boolean
}) => (
  <Backdrop sx={{ color: "#fff", zIndex: 1401 }} open={toggleBackdrop} >
    <CircularProgress sx={{ color: "#fff" }} />
  </Backdrop >
)

export default LoadingBackdrop;