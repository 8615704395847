import { AppBar, Box, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ViewUsers from "../../components/CompanyUsers/Sections/ViewUsers";
import ViewRoles from "../../components/CompanyUsers/Sections/ViewRoles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GetCompanyRoles } from "../../http/CompanyUserManagement/userManagementApi";
import { RoleMessage } from "../../http/CompanyUserManagement/Messages/RoleMessage";
import WithRequiredPermission from "../../components/WithRequiredPermission";
import PermissionClassification from "../../util/enums/PermissionClassification";
import { GetCompanyDataById } from "../../http/Company/company";
import { setCompanyViewModelState } from "../../http/Redux/Store/companySlice";
import BasePage from "../BasePage";


interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyUserManagementView = () => {
  const dispatch = useAppDispatch();
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [roles, setRoles] = useState<RoleMessage[]>([]);
  const baseCompanyInfo = useAppSelector(x => x.baseCompanyInfo);
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const loader = async () => {

      if (baseCompanyInfo.companyId === undefined) {
        const result = await GetCompanyDataById(baseCompanyInfo.companyId, false);
        dispatch(setCompanyViewModelState(result.data));
      }

      // roles
      setIsLoadingRoles(true);
      const result = await GetCompanyRoles(baseCompanyInfo.companyId);
      setRoles(result);
      setIsLoadingRoles(false);
    }

    loader();
  }, [baseCompanyInfo.companyId, dispatch])

  return (

    <BasePage pageHeader="User Management">
      <WithRequiredPermission permission={PermissionClassification.EditManageUsers}>
        {isLoadingRoles && (
          <Skeleton variant="rectangular" width="98%" height={500} style={{ marginLeft: "20px" }} />
        )}
        {!isLoadingRoles && (
          <Box className="platformConfigurationBox">
            <AppBar className="app-bar connect" position="static">
              <Tabs
                value={value}
                onChange={(_ev, val: number) => handleChange(val)}
                orientation="horizontal"
                indicatorColor="secondary"
                textColor="inherit"
                aria-label=""
              >
                <Tab label="Users" {...a11yProps(0)} className="app-bar-tabs" />
                <Tab label="Roles" {...a11yProps(1)} className="app-bar-tabs" />
              </Tabs>
            </AppBar>
            <CustomTabPanel value={value} index={0}>
              <ViewUsers roles={roles} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ViewRoles
                roles={roles}
                createNewRoleCallback={(role) => {
                  setRoles((prevState) => [role, ...prevState]);
                }}
              />
            </CustomTabPanel>
          </Box>
        )}
      </WithRequiredPermission>
    </BasePage>
  );

};

export default CompanyUserManagementView;

