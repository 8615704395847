import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePostRequestAsync } from "./Helpers/httpRequestHelpers";
import { ClientSpecificModel } from "./Redux/Models/ClientSpecificModel";



const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default {

  async getClientSpecificDetail(clientCompanyId: number, supplierCompanyId: number) {
    const url = `${baseUrl}/api/Company/ClientSpecific/ClientSpecificDetailCurrent/${clientCompanyId}/${supplierCompanyId}`;
    return (await makeGetRequestAsync<ClientSpecificModel>(apiScopes, url)).data;
  },
  async addUpdateClientSpecificDetail(details: ClientSpecificModel) {
    const url = `${baseUrl}/api/Company/ClientSpecific/AddUpdateCSCDetail`;
    return (await (makePostRequestAsync(apiScopes, url, details))).data;
  },

}