/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ConnectionModel } from "../../Connect/Models/ConnectionModel";

export interface CompanyPotentialConnectionsState {
  companyPotentialConnections: Array<ConnectionModel>;
}

const connectSlice = createSlice({
  name: "companyPotentialConnections",
  initialState: [] as Array<ConnectionModel>,
  reducers: {
    setCompanyPotentialConnections(state, action: PayloadAction<Array<ConnectionModel>>) {
      return action.payload;
    },
  },
})

export default connectSlice;
export const getCompanyPotentialConnections = (state: RootState) => state.companyNetworkConnections.clients;
