import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import CompanySearchView from "../CompanySearchView";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


export interface CompanyDialogProps {
  // eslint-disable-next-line no-unused-vars
  companyDetails: CompanyViewModel;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  handleCloseCallback?: () => void;
  show: boolean;
}



const CustomizedDialogs = ({ companyDetails, show, handleCloseCallback }: CompanyDialogProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    handleCloseCallback && handleCloseCallback();
    setOpen(false);
  };

  React.useEffect(() => {

    if (show && !open) {
      setOpen(true);
    }


  }, [show, companyDetails, open]);



  return (
    <div>
      <BootstrapDialog
        onClick={handleClose}
        aria-labelledby="customized-dialog-title"
        className="dialog-container"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, height: 50 }}>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CompanySearchView isOpen={open} selectedCompany={companyDetails} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}


export default CustomizedDialogs;



