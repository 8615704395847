const queryKeys = {
  baseCompanyInfo: "baseCompanyInfo",
  messaging: "messaging",
  user: "user",
  companyRegistrationInfo: "companyRegistrationInfo",
  companyUnspscSelection: "companyUnspscSelection",
  companyDetails: "companyDetails",
  hasConnectionToCompany: "hasConnectionToCompany",
  companyDocuments: "companyDocuments",
  companyProfileSettings: "companyProfileSettings",
  companyBeeDetails: "companyBeeDetails",
  companyCoidaDetails: "companyCoidaDetails",
  companyTaxInformation: "companyTaxInformation",
  companyBankingDetails: "companyBankingDetails",
  receivedConnectionRequests: "receivedConnectionRequests",
  existingConnectionRequestWithCompany: "existingConnectionRequestWithCompany",
  searchChatMessages: "searchChatMessages",
  companyProfileView: "companyProfileView",
  connectionRequests: "connectionRequests",

  // static data
  beeLevels: "beeLevels",
  beeTurnoverBands: "beeTurnoverBands",
  beeSectorTurnoverBands: "beeSectorTurnoverBands",
  companyTypes: "companyTypes",
  countries: "countries",
  documentTypes: "documentTypes",
  provinces: "provinces",
  unspscOptions: "unspscOptions",
  beeSectors: "beeSectors",
  bankList: "bankList",
  bankAccountTypes: "bankAccountTypes",
  dashboardStats: "dashboardStats",
  companyComplianceSummary: "companyComplianceSummary",
  userProfile: "userProfile",
};

export default queryKeys;
