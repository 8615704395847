import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { getExistingConnectionRequestsWithCompany } from "../../../http/ConnectionRequestService";

const useExistingConnectionRequestsWithCompany = (companyId: number, disableQuery?: boolean) => {
  const query = useQuery({
    queryKey: [queryKeys.existingConnectionRequestWithCompany, companyId],
    queryFn: () => getExistingConnectionRequestsWithCompany(companyId),
    enabled: disableQuery !== true,
  })

  return query;
}

export default useExistingConnectionRequestsWithCompany;