import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import React from "react";

const DropdownField = (props: TextFieldProps) => (
  <>
    <div className="text-label-box">
      {props.required && <span className="text-label-astrid">*</span>}
      {props.label}
    </div>
    <MUITextField
      {...props}
      select
      sx={{ width: "90%" }}
      variant="outlined"
      error={props.required && !props.value && !props.disabled}
      helperText={props.helperText ?? ((props.required && !props.value && !props.disabled) && "This field is required!")}
    >
      {props.children}
    </MUITextField>
  </>
);

export default DropdownField