import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "../http/Helpers/httpRequestHelpers";
import { ViewConnectionRequestModel } from "./Redux/Models/ViewConnectionRequestModel";
import { ConnectionRequestModel } from "./Redux/Models/ConnectionRequestModel";
import ConnectionStatus from "../util/enums/ConnectionStatus";
import { CreatePendingConnectionRequestModel } from "./NetworkAndConnect/Models/CreatePendingConnectionRequestModel";


const apiScopes = protectedResources.netconnWebApi.scopes;
const baseUrl = protectedResources.netconnWebApi.endpoint;


export const getViewConnectionRequestsForCompany = async (companyId: number) => {
  const url = `${baseUrl}/api/netconn/ConnectionRequest/Requests/${companyId}`;
  return (await makeGetRequestAsync<ViewConnectionRequestModel[]>(apiScopes, url)).data;
};

export const updateConnectionRequest = async (connectionRequest: ConnectionRequestModel) => {
  const url = `${baseUrl}/api/netconn/ConnectionRequest`;
  return (await (makePatchRequestAsync(apiScopes, url, connectionRequest))).status;
}

export const getExistingConnectionRequestsWithCompany = async (companyId: number) => {
  const url = `${baseUrl}/api/netconn/ConnectionRequest/existingConnectionRequestsWithCompany/${companyId}`;
  return (await makeGetRequestAsync<ViewConnectionRequestModel[]>(apiScopes, url)).data;
} 

export const createPendingConnectionRequestWithCompany = async (message: CreatePendingConnectionRequestModel) => {
  const url = `${baseUrl}/api/netconn/ConnectionRequest/createPendingConnectionRequestWithCompany`;
  return (await makePostRequestAsync(apiScopes, url,message)).data;
} 

export default {
  async getViewConnectionRequests(companyId: number) {
    const url = `${baseUrl}/api/netconn/ConnectionRequest/${companyId}`;
    return (await makeGetRequestAsync<ViewConnectionRequestModel[]>(apiScopes, url)).data;
  },
  async getViewConnectionRequestsForCompany(companyId: number) {
    const url = `${baseUrl}/api/netconn/ConnectionRequest/Requests/${companyId}`;
    return (await makeGetRequestAsync<ViewConnectionRequestModel[]>(apiScopes, url)).data;
  },
  async addConnectionRequest(connectionRequest: ConnectionRequestModel) {
    const url = `${baseUrl}/api/netconn/ConnectionRequest`;
    return (await (makePostRequestAsync(apiScopes, url, connectionRequest))).status;
  },
  async updateConnectionRequest(connectionRequest: ConnectionRequestModel) {
    const url = `${baseUrl}/api/netconn/ConnectionRequest`;
    return (await (makePatchRequestAsync(apiScopes, url, connectionRequest))).status;
  },
  async updateConnectionRequestStatus(connectionRequestId: number, newStatus: ConnectionStatus) {
    const url = `${baseUrl}/api/netconn/ConnectionRequest/UpdateConnectionRequestStatus/${connectionRequestId}/${newStatus}`;
    return (await makePostRequestAsync(apiScopes, url)).status;
  },
}