const BankModelProps = {
  bankId: "bankId",
  textBankName: "textBankName",
  accountTypeId: "accountTypeId",
  textAccountType: "textAccountType",
  accountName: "accountName",
  accountNumber: "accountNumber",
  branchName: "branchName",
  swiftCode: "swiftCode",
  branchCode: "branchCode",
  companyId: "companyId",
  countryId: "countryId",
  dateOfIssue: "dateOfIssue",
  isPrimary: "isPrimary",
  isDeleted: "isDeleted",
};

export default BankModelProps;