import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MessageIcon from "@mui/icons-material/Textsms";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ConnectIcon from "@mui/icons-material/ElectricalServices";
import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import { ConnectionModel } from "../../../http/Connect/Models/ConnectionModel";
import { getCompaniesImFollowing, getCompanyNetworkClients, getCompanyNetworkSuppliers } from "../../../http/Redux/Store/NetworkSlice";
import { InitializeChatGroup } from "../../../http/Messaging/Messaging";
import { fetchCompanyNetwork } from "../../../http/Redux/Store/NetworkAndConnectActions";
import "../../Connect/SearchResult/SweetAlert.css";
import styles from "../../../pages/Search/Search.module.scss";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import { GetCompanyDataById } from "../../../http/Company/company";
import CompanyDialog from "./CompanyDialog";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import { SearchResultModel } from "./SearchView";
import LoadingMessageGif from "../../../util/images/dotsDown.gif";
import { GetCompanyUsersWithId } from "../../../http/CompanyUserManagement/userManagementApi";
import { UserMessage } from "../../../http/CompanyUserManagement/Messages/UserMessage";
import saveSupplierVendorNumber, {
  doesVendorNumberExist,
} from "../../../http/Company/CompanySpecificSupplierDetails";
import { VendorNumberDto } from "../../../http/Company/Models/VendorNumberDto";

import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { DeleteConnectionDto } from "../../../http/NetworkAndConnect/Dtos/DeleteConnectionDto";
import ConnectionType from "../../../util/enums/ConnectionType";
import { DeleteConnection } from "../../../http/NetworkAndConnect/Netconn";
import { useHasSubscriptionPermission } from "../../../util/hooks/useAllowedPermission";
import AvailableActionsButton from "../../../pages/NewUI/Components/AvailableActionsButton";
import { getCompanyConnectionRequests } from "../../../http/Redux/Store/ConnectionRequestSlice";
import { ViewConnectionRequestModel } from "../../../http/Redux/Models/ViewConnectionRequestModel";
import setupClientConnectionRequest from "../../../util/functions/connectionRequestHelpers";
import ConnectRuleDialog from "../../Connect/ConnectRuleDialog/ConnectRuleDialog";

interface ConnectToolsProps {
  searchResult: SearchResultModel;

  // eslint-disable-next-line react/require-default-props
  connectionType?: NetconnSearchType;

  // eslint-disable-next-line no-unused-vars, react/require-default-props
  reloadCallback: (filterModel: ConnectionSearchModel, searchType: NetconnSearchType) => void;
}

const ConnectTools = ({
  searchResult,
  connectionType = NetconnSearchType.None,
  reloadCallback,
}: ConnectToolsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const companyNetworkClients: Array<ConnectionModel> =
    useAppSelector(getCompanyNetworkClients) || ([] as Array<ConnectionModel>);

  const hasSubscriptionPermission = useHasSubscriptionPermission();

  const [swalPropsMessage, setSwalPropsMessage] = useState({});
  const [show, setShow] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [isLoading, setLoading] = useState(false);
  const myCompany = useAppSelector((x) => x.baseCompanyInfo);
  const userProfile = useAppSelector((x) => x.userProfile.particularUserProfile);

  const clientList = useAppSelector(getCompanyNetworkClients) || ([] as Array<ConnectionModel>);
  const supplierList = useAppSelector(getCompanyNetworkSuppliers) || ([] as Array<ConnectionModel>);
  const followingList = useAppSelector(getCompaniesImFollowing) || ([] as Array<number>);

  const [isClient, setIsClient] = useState<boolean>();
  const [isSupplier, setIsSupplier] = useState<boolean>();

  
  const connectionRequests: ViewConnectionRequestModel[] = useAppSelector(
    getCompanyConnectionRequests
  );

  const hasIbuyPerm = hasSubscriptionPermission(PermissionClassification.iBuySpecific);

  // Rule Dialog State Nonsense:
  const [showRuleDialog, setShowRuleDialog] = useState<boolean>(false);
  const [evaluatingCompId, setEvaluatingCompId] = useState<number>(0);
  const [evaluatedCompId, setEvaluatedCompId] = useState<number>(0);
  const [reload] = useState(false);
  
  const handleSupplyClick = (companyId: number) => {

    setEvaluatingCompId(companyId); // This ID will be the company you are approaching as a supplier.
    setEvaluatedCompId(company.companyId); // This would be your company's ID.

    Swal.fire({
      title: "Loading",
      showConfirmButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });

    setShowRuleDialog(true);
  };

  const handleBuyClick = (companyId: number) => {
    Swal.fire({
      title: "Connect as a Buyer",
      text: "Are you sure you want to connect with this company as a buyer?",
      icon: "question",
      focusConfirm: false,
      showCancelButton: true,
      showCloseButton: true,
      showLoaderOnConfirm: true,
    }).then(async (reply) => {
      if (reply.isConfirmed) {
        await setupClientConnectionRequest(
          company.companyId,
          companyId,
          ConnectionType.Client,
          userProfile,
          hasIbuyPerm
        );
        setTimeout(reloadCallback, 1500);
      }
    });
  };

  const handleConnectionRuleDialogClose = () => {
    setShowRuleDialog(false);
  };

  const handleMessageClick = (name: string) => {
    Swal.fire({
      html: `<p><b>Send Message to:</b> ${name}</p>`,
      focusConfirm: false,
      confirmButtonText: "Send",
      input: "textarea",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.value) {
        Swal.fire({
          title: "Sending...",
          imageUrl: LoadingMessageGif,
          imageWidth: 250,
          imageHeight: 250,
          imageAlt: "Custom image",
          showConfirmButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        try {
          // Initialize chat group request - first cheack if it has a Chat group already otherwise just send
          await InitializeChatGroup({
            senderCompanyId: myCompany.companyId,
            senderCompanyName: myCompany.companyRegisteredName,
            receiverCompanyId: searchResult.id,
            receiverCompanyName: searchResult.name,
            users: (
              await GetCompanyUsersWithId(searchResult.id)
            ).map((u: UserMessage) => u.accountId),
            message: result.value,
          });

          // Close the dialog
          Swal.close();
          // Redirect to chat page
          navigate("/messaging");
        } catch (error) {
          setSwalPropsMessage({
            title: "Error",
            text: `Unable to send message: ${error}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        Swal.close();
      }
    });
  };

  const handleCompanyClick = (companyId: number) => {
    const loader = async () => {
      setLoading(true);
      const getCompanyDetails = await GetCompanyDataById(companyId.toString(), false).finally(
        () => {
          setShow(true);
          setLoading(false);
        }
      );
      if (getCompanyDetails.status === 200) {
        setCompany(getCompanyDetails.data);
      }
    };
    loader();
  };

  const reloadSupplierData = () => {
    const filterModel = {
      companyId: myCompany.companyId,
      searchQuery: "",
      searchPageNumber: 0,
      searchRowNumber: 10, // TODO: Please make this a setting somewhere. PRETTY PLEASE?
    } as ConnectionSearchModel;

    // Refresh the network object in Redux Toolkit:
    dispatch(fetchCompanyNetwork(myCompany.companyId));
    reloadCallback(filterModel, connectionType);
  };

  const showLoadingSwal = useCallback((titleText?: string | undefined) => {
    Swal.fire({
      title: titleText || "Loading",
      showConfirmButton: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
    Swal.showLoading(confirmButton);
  }, []);

  const showErrorSwal = useCallback((titleText: string, messageText: string) => {
    Swal.close(); // Close any open modals.
    Swal.fire({
      title: titleText,
      text: messageText,
      icon: "error",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }, []);

  const showSuccessSwal = useCallback((titleText?: string | undefined) => {
    Swal.close(); // Close any loading/open dialogs.

    Swal.fire({
      title: titleText || "Operation completed successfully",
      icon: "success",
      timer: Number(timeVal),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }, [timeVal]);

  const handleEditSupplierDetailsClick = (searchResultObject: SearchResultModel) => {
    Swal.fire({
      title: "Please edit your supplier data below",
      html: `<label for="vendorNumberValue">Vendor Number</label>
      <input type="text" id="vendorNumberValue"
             class="swal2-input less-margin-text-input"
             placeholder="Vendor Number"
             value="${searchResultObject.vendorNumber}"
        />`,
      customClass: {
        htmlContainer: "supplier-detail-swal-html-container",
      },
      confirmButtonText: "Save",
      preConfirm: async () => {
        const vendorNumberText =
          (Swal.getPopup()?.querySelector("#vendorNumberValue") as HTMLInputElement).value ?? "";

        if (vendorNumberText.trim().length === 0)
          Swal.showValidationMessage("Please enter a vendor number");

        // If the user is clearing the vendor number, or adding/editing an existing one:
        if (vendorNumberText.trim().length > 0) {
          const vendorNumberCheck = await doesVendorNumberExist(
            searchResultObject.id,
            vendorNumberText.trim(), false
          );
          const successCodes = [200, 204];
          if (!successCodes.includes(vendorNumberCheck.status)) {
            Swal.showValidationMessage(vendorNumberCheck.data as string);
          }

          return vendorNumberText.trim();
        }

        return vendorNumberText.trim();
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowEnterKey: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resultVal = result.value;

        const vendorNumDto = {
          clientCompanyId: myCompany.companyId,
          supplierCompanyId: searchResultObject.id,
          vendorNumber: resultVal ?? "",
          userId: userProfile.accountId,
        } as VendorNumberDto;

        try {
          showLoadingSwal();
          await saveSupplierVendorNumber(vendorNumDto, false);
          showSuccessSwal("Supplier Detail changes saved successfully.");
          window.setTimeout(reloadSupplierData, 1500);
        } catch (error) {
          showErrorSwal(
            "An error occurred saving your changes",
            "Please try again, or contact support."
          );
        }
      }
    });
  };

  const handleDisconnectClick = (
    searchResultObject: SearchResultModel,
    signedInCompanyId: number,
    searchTypeVal: NetconnSearchType
  ) => {
    Swal.fire({
      title: `Do you want to disconnect from this ${searchTypeVal === NetconnSearchType.Client ? "client" : "supplier"}?`,
      text: "This will remove the relationship and any client-specific data.",
      icon: "warning",
      showCancelButton: true,
      allowEnterKey: false,
      allowEscapeKey: true,
      allowOutsideClick: false,
    }).then((firstConfirm) => {
      if (firstConfirm.isConfirmed) {
        Swal.fire({
          title: "Final warning",
          text: "Are you 100% sure you wish to continue with this disconnection? This cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          allowEnterKey: false,
          allowEscapeKey: true,
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              const deleteModel: DeleteConnectionDto = {
                // If this is a client connection disconnection, the search result company ID is the "From/Buyer" company.
                fromCompanyId:
                  searchTypeVal === NetconnSearchType.Client
                    ? searchResultObject.id
                    : signedInCompanyId,

                companyNameBeingDisconnected: searchResultObject.name,

                // If this is a client connection disconnection, the signed-in company ID is the "To/Supplier" company.
                toCompanyId:
                  searchTypeVal === NetconnSearchType.Client
                    ? signedInCompanyId
                    : searchResultObject.id,
                connectionType:
                  searchTypeVal === NetconnSearchType.Client
                    ? ConnectionType.Client
                    : ConnectionType.Supplier,
                userId: userProfile.accountId || "",
              };

              const response: AxiosResponse = await DeleteConnection(deleteModel);

              return response;
            } catch (error) {
              const axiosError = error as AxiosError;

              let errorMessage;
              if (!axiosError || !axiosError.response || !axiosError.response.data)
                errorMessage = "An unexpected error occurred. Please try again or contact support";
              else
                errorMessage = axiosError.response.data as string;

              Swal.showValidationMessage(errorMessage);

              return null;
            }
          },
        }).then(async (secondConfirm) => {
          if (secondConfirm.isConfirmed) {
            toast.success(
              `You have successfully disconnected from this ${searchTypeVal === NetconnSearchType.Client ? "client" : "supplier"
              }`, { toastId: "disconnect-success" }
            );
          }
          window.setTimeout(reloadSupplierData, 1500);
        });
      }
    });
  };
  const getAvailableActions = () => {
    const actionList = [];
    
    if(isClient){
      actionList.push({
        icon: <ConnectIcon />,
        title: "I want to buy from this company",
        onClick: () => handleBuyClick(searchResult.id),
      });
    }

    if(isSupplier){
      actionList.push({
        icon: <ConnectIcon />,
        title: "I want to supply this company",
        onClick: () => handleSupplyClick(searchResult.id),
      });
    }

    actionList.push({
      icon: <MessageIcon />,
      title: "Message",
      onClick: () => handleMessageClick(searchResult.name),
    });

    if (hasSubscriptionPermission(PermissionClassification.iBuySpecific) && connectionType === NetconnSearchType.Supplier) {
      actionList.push({
        icon: <ModeEditIcon />,
        title: "Edit Supplier Details",
        onClick: () =>  handleEditSupplierDetailsClick(searchResult),
      });
    }
    actionList.push({
      icon: <SearchIcon />,
      title: "View Company",
      onClick: () => handleCompanyClick(searchResult.id),
    });
    if (connectionType === NetconnSearchType.Supplier || connectionType === NetconnSearchType.Client) {
      actionList.push({
        icon: <LinkOffIcon />,
        title: "Disconnect from this company",
        onClick: () =>  handleDisconnectClick(searchResult, myCompany.companyId, connectionType),
      });
    }
    return actionList;

  }

  useEffect(() => {
    const isCompanyAClient = clientList.map((x) => x.companyId).includes(searchResult.id);
    const isCompanyASupplier = supplierList.map((x) => x.companyId).includes(searchResult.id);

    setIsClient(isCompanyAClient);
    setIsSupplier(isCompanyASupplier);

    if (companyNetworkClients === undefined) {
      dispatch(fetchCompanyNetwork(searchResult.id));
    }
    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, Number(timeVal));
    }
  }, [companyNetworkClients.length, dispatch, searchResult.id, isLoading, company.companyId, connectionRequests, followingList, timeVal, reload]);


  return (
    <Box className={styles.connectionBox}>
      <LoadingBackdrop showBackdrop={isLoading} />
      <SweetAlert2
        {...swalPropsMessage}
        didOpen={() => {
          // run when swal is opened...
        }}
        didClose={() => {
          // run when swaBuyerl is closed...
        }}
        onConfirm={() => {
          window.location.reload();
        }}
      />
      <AvailableActionsButton
        actionsList={getAvailableActions()}
      />
      <CompanyDialog
        companyDetails={company}
        show={show}
        handleCloseCallback={() => setShow(false)}
      />

      <ConnectRuleDialog
        showDialog={showRuleDialog}
        handleCloseCallback={handleConnectionRuleDialogClose}
        evaluatingCompanyId={evaluatingCompId}
        evaluatedCompanyId={evaluatedCompId}
        connectionTypeId={ConnectionType.Client}
        connectionRequestId={0}
      />
    </Box>
  );
};

export default ConnectTools;
