import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModulePermissions } from "../../CompanyUserManagement/Messages/Module";
import { RootState } from "../../../app/store";

export interface ModulePermissionsState {
  modulePermissionsList: ModulePermissions[];
}

const modulePermissionsSlice = createSlice({
  name: "modulePermissions",
  initialState: {} as ModulePermissionsState,
  reducers: {
    setModulePermissionState(state, action: PayloadAction<ModulePermissions[]>) {
      state.modulePermissionsList = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const { setModulePermissionState } = modulePermissionsSlice.actions;
export const selectModulePermissions = (state: RootState) => state.modulePermissions;
export default modulePermissionsSlice;