import { CompanyBusinessLocationModel } from "./Models/CompanyBusinessLocationModel";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makeDeleteRequestNoBodyAsync, makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export async function GetCompanyBusinessLocations(companyId: number, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyBusinessLocations/GetCompanyBusinessLocations/${companyId}/${staged}`;
  const response = (await makeGetRequestAsync<Array<CompanyBusinessLocationModel>>(apiScopes, url));
  return response;
}

export async function GetSpecificCompanyBusinessLocation(companyLocationId: number, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyBusinessLocations/GetSpecificCompanyBusinessLocation/${companyLocationId}/${staged}`;
  const response = (await makeGetRequestAsync<CompanyBusinessLocationModel>(apiScopes, url));
  return response;
}

export async function AddCompanyBusinessLocation(locationObject: CompanyBusinessLocationModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyBusinessLocations/AddCompanyBusinessLocation/${staged}`;
  const response = (await makePostRequestAsync(apiScopes, url, locationObject));
  return response;
}

export async function UpdateCompanyBusinessLocation(locationObject: CompanyBusinessLocationModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyBusinessLocations/UpdateCompanyBusinessLocation/${staged}`;
  const response = (await makePatchRequestAsync(apiScopes, url, locationObject));
  return response;
}

export async function DeleteCompanyBusinessLocation(companyLocationId: number, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyBusinessLocations/DeleteCompanyBusinessLocation/${companyLocationId}/${staged}`;
  const response = (await makeDeleteRequestNoBodyAsync(apiScopes, url)).status;
  return response;
}
