/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CountryModel } from "../../Company/Models/CountryModel";

export interface CountryListState {
  countryList: Array<CountryModel>;
}

const countryListSlice = createSlice({
  name: "countryList",
  initialState: [] as Array<CountryModel>,
  reducers: {
    setCountryListState: (
      state,
      action: PayloadAction<Array<CountryModel>>
    ) => action.payload,
  },
});

export const selectCountryList = (state: RootState) => state.countryList;
export default countryListSlice;
