import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { userProfileSlice, profileImageSlice, userRoleSlice, searchHistorySlice } from "./UserProfileSlice"
import { RootState } from "../../../app/store"
import { ProfileImageModel } from "../Models/ProfileImageModel";
import { UserProfileModel } from "../Models/UserProfileModel";
import UserProfileService from "../../UserProfileService";
import { UserRoleModel } from "../Models/UserRoleModel";
import { SearchHistoryModel } from "../Models/SearchHistoryModel";

export const profileActions = userProfileSlice.actions
export const profileImageActions = profileImageSlice.actions
export const userRoleActions = userRoleSlice.actions
export const searchHistory = searchHistorySlice.actions

export const fetchParticularProfileImage = (accountId: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: ProfileImageModel = await (await UserProfileService.getParticularProfileImage(accountId)).data;
  dispatch(profileImageActions.setParticularProfileImage(response))
}

export const fetchParticularUserProfile = (accountId: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: UserProfileModel = await (await UserProfileService.getParticularUserProfile(accountId)).data;
  dispatch(profileActions.setParticularUserProfile(response))
}
export const fetchParticularUserRole = (userId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: UserRoleModel = await (await UserProfileService.getUserRole(userId)).data;
  dispatch(userRoleActions.setParticularUserRole(response))
}
export const fetchSearchHistory = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: SearchHistoryModel[] = await (await UserProfileService.getSearchHistory()).data;
  dispatch(searchHistory.setSearchHistory(response))
}