/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum VerificationStatus {
  None = 0,
  Declined = 1,
  Verified = 2,
  NotVerified = 3,
  Inprogress = 4,
  Assigned = 5,
}

export default VerificationStatus;

export const verificationStatusToString = (value: VerificationStatus) => {
  switch (value) {
    case VerificationStatus.None:
      return "Unassigned"
    case VerificationStatus.Declined:
      return "Declined"
    case VerificationStatus.Verified:
      return "Verified"
    case VerificationStatus.NotVerified:
      return "Not Verified"
    case VerificationStatus.Inprogress:
      return "In progress"
    case VerificationStatus.Assigned:
      return "Assigned"
    default:
      return "Unknown";
  }
};