/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import "./CompanyProfileSidePanel.css";
import { AppBar, Tab, Tabs, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CompanyProfileSideBar from "../../../util/enums/CompanyProfileSideBar";
import { useAppSelector } from "../../../app/hooks";
import useSouthAfricaSpecificFeature from "../../../util/hooks/useSouthAfricaSpecificFeature";
import useAllowedPermission from "../../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";

interface Props {
  currentSection: number;
  setSection: (pageNumber: number) => void; // eslint-disable-line no-unused-vars
  showForWorkcenter: boolean;
}

// a11yProps are 0 based, but section IDs are 1 based.
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// eslint-disable-next-line no-unused-vars
const ProfileSection = ({ title, isValid }: { title: string; isValid: boolean }) =>
  isValid ? (
    <> {title}</>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "#D63D36",
        fontWeight: "900",
      }}
    >
      <Tooltip title="Section Incomplete" placement="top-end">
        <InfoOutlinedIcon htmlColor="#D63D36" sx={{ marginRight: "5px" }} />
      </Tooltip>{" "}
      {title}
    </div>
  );
const CompanyProfileSidePanel = ({ currentSection, setSection, showForWorkcenter }: Props) => {
  const companyCompletionSummary = useAppSelector(
    (x) => x.baseCompanyInfo.profileCompletionSummary
  );
  const handleChangeTab = (newValue: number) => {
    setSection(newValue);
  };

  const hasPermission = useAllowedPermission();
  const countrySpecificCheck = useSouthAfricaSpecificFeature();

  return (
    <div className="sidebar-company-profile">
      <div className="app-bar-wrap">
        <AppBar className="app-bar" position="static">
          <Tabs
            value={currentSection}
            onChange={(_ev, val: number) => handleChangeTab(val)}
            orientation="vertical"
            indicatorColor="secondary"
            textColor="inherit"
            aria-label=""
          >
            <Tab
              label={<ProfileSection title="General" isValid={companyCompletionSummary.general} />}
              {...a11yProps(CompanyProfileSideBar.General)}
              value={CompanyProfileSideBar.General}
              className="app-bar-tabs"
            />
            <Tab
              label={
                <ProfileSection title="Contacts" isValid={companyCompletionSummary.contacts} />
              }
              {...a11yProps(CompanyProfileSideBar.Contacts)}
              value={CompanyProfileSideBar.Contacts}
              className="app-bar-tabs"
            />
            <Tab
              label={
                <ProfileSection
                  title="Company Registered Address"
                  isValid={companyCompletionSummary.addresses}
                />
              }
              {...a11yProps(CompanyProfileSideBar.Address)}
              value={CompanyProfileSideBar.Address}
              className="app-bar-tabs"
            />
            {countrySpecificCheck && (
              <Tab
                label={
                  <ProfileSection
                    title="B-BBEE Details (Optional)"
                    isValid={companyCompletionSummary.beeDetails}
                  />
                }
                {...a11yProps(CompanyProfileSideBar.Bee)}
                value={CompanyProfileSideBar.Bee}
                className="app-bar-tabs"
              />
            )}
            <Tab
              label={
                <ProfileSection
                  title="Company Shareholders"
                  isValid
                />
              }
              {...a11yProps(CompanyProfileSideBar.CompanyShareholders)}
              value={CompanyProfileSideBar.CompanyShareholders}
              className="app-bar-tabs"
            />
            <Tab
              label={
                <ProfileSection
                  title="Bank Details (Optional)"
                  isValid={companyCompletionSummary.bankDetails}
                />
              }
              {...a11yProps(CompanyProfileSideBar.Bank)}
              value={CompanyProfileSideBar.Bank}
              className="app-bar-tabs"
            />
            <Tab
              label={
                <ProfileSection
                  title="Products & Services"
                  isValid={companyCompletionSummary.productsAndService}
                />
              }
              {...a11yProps(CompanyProfileSideBar.Services)}
              value={CompanyProfileSideBar.Services}
              className="app-bar-tabs"
            />
            <Tab
              label={
                <ProfileSection title="Documents" isValid={companyCompletionSummary.documents} />
              }
              {...a11yProps(CompanyProfileSideBar.Documents)}
              value={CompanyProfileSideBar.Documents}
              className="app-bar-tabs"
            />
            {!showForWorkcenter && (
              <Tab
                label="Share My Profile"
                {...a11yProps(CompanyProfileSideBar.ProfileSharing)}
                value={CompanyProfileSideBar.ProfileSharing}
                className="app-bar-tabs"
              />
            )}
            {(showForWorkcenter && hasPermission(PermissionClassification.ViewAgentWorkCentre)) && (
              <Tab
                label={<ProfileSection title="Company-Specific Client Details" isValid />}
                {...a11yProps(CompanyProfileSideBar.CompanySpecificClientDetails)}
                value={CompanyProfileSideBar.CompanySpecificClientDetails}
                className="app-bar-tabs"
              />
            )}
          </Tabs>
        </AppBar>
      </div>
    </div>
  );
};

export default CompanyProfileSidePanel;
