
import { FieldModel } from "./Redux/Models/FieldModel";
import { protectedResources } from "../authConfig";
import makeGetRequestAsync from "../http/Helpers/httpRequestHelpers";



const apiScopes = protectedResources.configurationWebApi.scopes;
const baseUrl = protectedResources.configurationWebApi.endpoint;


export default {
  async getAllFields() {

    const url = `${baseUrl}/api/Configuration/Fields/GetAllFields`;
    return (await makeGetRequestAsync<FieldModel[]>(apiScopes, url)).data;

  },
  async getParticularField(id: number) {

    const url = `${baseUrl}/api/Configuration/Fields/GetSingleField/${id}`;
    return (await makeGetRequestAsync<FieldModel>(apiScopes, url)).data;

  },
}