import { CompanyComplianceSummary } from "../../../../http/Compliance/Models/CompanyComplianceSummary";

export type ComplianceSummaryType = "bee" | "coida" | "banking" | "tax" | "vat";
type ComplianceSummaryTypeKeys = keyof CompanyComplianceSummary;

const complianceSummaryTypeMapping: Record<ComplianceSummaryType, {
  enabled: ComplianceSummaryTypeKeys,
  issueDate: ComplianceSummaryTypeKeys,
  expiryDate: ComplianceSummaryTypeKeys | null,
  uploaded: ComplianceSummaryTypeKeys
}> = {
  bee: {
    enabled: "hasBeeEnabled",
    issueDate: "beeCertificateIssueDate",
    expiryDate: "beeCertificateExpiryDate",
    uploaded: "beeCertificateUploaded",
  },
  banking: {
    enabled: "hasBankAccountDetailsEnabled",
    issueDate: "bankLetterIssueDate",
    expiryDate: null, // No expiry date for bank details
    uploaded: "bankLetterUploaded",
  },
  coida: {
    enabled: "hasCoidaEnabled",
    issueDate: "coidaCertificateIssueDate",
    expiryDate: "coidaCertificateExpiryDate",
    uploaded: "coidaCertificateUploaded",
  },
  tax: {
    enabled: "hasTaxEnabled",
    issueDate: "taxClearancePinIssueDate",
    expiryDate: "taxClearancePinExpiryDate",
    uploaded: "taxClearanceCertificateUploaded",
  },
  vat: {
    enabled: "hasVatEnabled",
    issueDate: "vatRegistrationDate",
    expiryDate: null, // No expiration date for VAT registration cert.
    uploaded: "vatCertificateUploaded",
  },
}

export default complianceSummaryTypeMapping;