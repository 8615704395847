/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BeeRatingLookupModel } from "../Models/BeeRatingLookupModel";

/* -- Rating Statuses -- */

export interface BeeRatingStatusListState {
  beeRatingStatusList: Array<BeeRatingLookupModel>;
}

export const beeRatingStatusesSlice = createSlice({
  name: "beeRatingStatusList",
  initialState: [] as Array<BeeRatingLookupModel>,
  reducers: {
    setBeeRatingStatusListState: (
      state,
      action: PayloadAction<Array<BeeRatingLookupModel>>
    ) => action.payload,
  },
});

/* -- Rating Sources -- */

export interface BeeRatingSourceListState {
  beeRatingSourceList: Array<BeeRatingLookupModel>;
}

export const beeRatingSourcesSlice = createSlice({
  name: "beeRatingSourceList",
  initialState: [] as Array<BeeRatingLookupModel>,
  reducers: {
    setBeeRatingSourcesListState: (
      state,
      action: PayloadAction<Array<BeeRatingLookupModel>>
    ) => action.payload,
  },
});

/* -- Verification Agencies -- */

export interface BeeRatingVerificationAgencyList {
  beeRatingVerificationAgencyList: Array<BeeRatingLookupModel>;
}

export const beeRatingVerificationAgenciesSlice = createSlice({
  name: "beeRatingVerificationAgencyList",
  initialState: [] as Array<BeeRatingLookupModel>,
  reducers: {
    setBeeRatingsVerificationAgencyListState: (
      state,
      action: PayloadAction<Array<BeeRatingLookupModel>>
    ) => action.payload,
  },
});

/* -- State Selector Functions -- */
export const selectBeeRatingStatusList = (state: RootState) => state.beeRatingStatusList;
export const selectBeeRatingSourceList = (state: RootState) => state.beeRatingSourceList;
export const selectBeeVerificationAgencyList = (state: RootState) => state.beeRatingVerificationAgencyList;

