import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Button, Tooltip } from "@mui/material";
import SupplierUploadInvitationStatus, 
{ getSupplierUploadInvitionStatusComponent } from "../../../util/enums/SupplierUploadInvitationStatus";
import { SupplierModel } from "../../../http/Company/Models/SupplierModel";
import { formatDateTime } from "../../../util/formatter/formatDate";

const formatEmailAddress = (inviteStatus: SupplierUploadInvitationStatus, emailAddress: string) => {
  if (inviteStatus === SupplierUploadInvitationStatus.InvalidEmail) {
    return <Box sx={{ bgcolor: "warning.main", color: "warning.contrastText", p: 0.5 }}>{emailAddress}</Box>;
  }
  return <Box>{emailAddress}</Box>;
}

const getSupplierUploadColumnConfig = (
  onDeleteInviteCallback: (rowId: string, supplierRegName: string) => void, // eslint-disable-line no-unused-vars
  onEditInviteCallback: (rowData: SupplierModel) => void// eslint-disable-line no-unused-vars
)
  : GridColDef[] => ([
  {
    field: "uploadDateTime",
    headerName: "Date Uploaded",
    sortable: true,
    minWidth: 200,
    valueGetter: (params: GridValueGetterParams<SupplierModel>) => 
      formatDateTime(params.row.uploadDateTime),
  },
  {
    field: "registeredName",
    headerName: "Company Name",
    sortable: true,
    minWidth: 200,
  },
  {
    field: "registrationNumber",
    headerName: "Registration Number",
    sortable: false,
    minWidth: 200,
  },
  {
    field: "clientVendorNumber",
    headerName: "Vendor Number",
    sortable: true,
    minWidth: 200,
  },
  {
    field: "fullName",
    headerName: "Contact Name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 200,
    valueGetter: (params: GridValueGetterParams<SupplierModel>) =>
      `${params.row.contactName || ""} ${params.row.contactSurname || ""}`,
  },
  {
    field: "contactPhone",
    headerName: "Contact Phone",
    sortable: false,
    minWidth: 200,
  }, 
  {
    field: "email",
    headerName: "Contact Email",
    sortable: false,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<SupplierModel>) =>
      formatEmailAddress(params.row.invitationStatus, params.row.contactEmail),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    align: "center",
    minWidth: 75,
    renderCell: (params: GridRenderCellParams) =>
      getSupplierUploadInvitionStatusComponent(params.row.invitationStatus),
  },
  {
    field: "sentEmail",
    headerName: "Edit Invite",
    sortable: false,
    minWidth: 75,
    renderCell: (params: GridRenderCellParams<SupplierModel>) =>
      params.row.invitationStatus !== SupplierUploadInvitationStatus.Registered ?
        <Button onClick={() => onEditInviteCallback(params.row)}>
          <MailIcon />
        </Button>
        :
        <Tooltip title="The company has already registered">
          <span>
            <Button disabled>
              <MailIcon />
            </Button>
          </span>
        </Tooltip>
    ,
  },
  {
    field: "deleteEmail",
    headerName: "Delete invite",
    sortable: false,
    minWidth: 75,
    renderCell: (params: GridRenderCellParams<SupplierModel>) =>
      params.row.invitationStatus !== SupplierUploadInvitationStatus.Registered ?
        <Button onClick={() => onDeleteInviteCallback(params.row.tempCompanyId, params.row.registeredName)}>
          <DeleteIcon color="error" style={{ cursor: "pointer"}} />
        </Button>
        :
        <Tooltip title="The company has already registered">
          <span>
            <Button disabled><DeleteIcon color="disabled" /></Button>
          </span>
        </Tooltip>
    ,
  },
]);

export default getSupplierUploadColumnConfig;