const formatDate = (date: string | Date) => {
  const dt = new Date(date);
  const year = dt.getFullYear();
  const month = (`0${  dt.getMonth() + 1}`).slice(-2);
  const day = (`0${  dt.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
}

export default formatDate;

export const formatDateTime = (date: string | Date) => {
  if (typeof date === "string" && date.length === 0) return "";

  const dt = new Date(date);
  const year = dt.getFullYear();
  const month = (`0${dt.getMonth() + 1}`).slice(-2);
  const day = (`0${dt.getDate()}`).slice(-2);
  const hours = (`0${dt.getHours()}`).slice(-2);
  const minutes = (`0${dt.getMinutes()}`).slice(-2);
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}