import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";

const baseUrl = protectedResources.companyApi.endpoint;
const apiScopes = protectedResources.companyApi.scopes;

export default async function GetBlobSasToken() {
  const url = `${baseUrl}/api/Company/SasToken`;
  const response = await (await makeGetRequestAsync<string>(apiScopes, url)).data;
  return response;
}