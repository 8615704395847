/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { SectorModel } from "../../Company/Models/SectorModel";

export interface BeeSectorListState {
  beeSectorList: Array<SectorModel>;
}

const beeSectorListSlice = createSlice({
  name: "beeSectorList",
  initialState: [] as Array<SectorModel>,
  reducers: {
    setBeeSectorListState: (
      state,
      action: PayloadAction<Array<SectorModel>>
    ) => action.payload,
  },
});



export const selectBeeSectorList = (state: RootState) => state.beeSectorList;
export default beeSectorListSlice;
