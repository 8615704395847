import {  MenuItem,  Select, SelectProps} from "@mui/material"

interface DropDownListProps extends SelectProps{
  listItems: {itemId:number|string, displayName: string}[]
}

const DropDownList = (props: DropDownListProps) => {
  const title = typeof props.label === "string" ?
    props.label.charAt(0).toUpperCase() + props.label.slice(1)
    : props.label;

  return (
    <>
      <div className="text-label-box">
        {props.required &&
          <span className="text-label-astrid">*</span>
        }
        {title}
      </div >
      <Select
        {...props}
        label={null}
        placeholder={props.placeholder ?? `Please select ${props.label}`}
        variant="outlined"
        sx={{ width: "90%" }}
        defaultValue={0} // Set the desired item ID as default
      >
        <MenuItem value={0} disabled>{props.placeholder ?? `Please select ${props.label}`}</MenuItem>
        {
          props.listItems.map((li) => (
            <MenuItem value={li.itemId}>{li.displayName}</MenuItem>
          ))
        }
      </Select>
    </>
  )
}

export default DropDownList;