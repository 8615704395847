import { Button, Grid, IconButton, Typography } from "@mui/material";
import CrossIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router";
import { NotificationModel } from "../../http/Notifications/Models/NotificationModel";
import { MarkNotificationAsRead } from "../../http/Notifications/Notification";
import { getMonthInWords, getTimeWithAMPM } from "../../pages/NewUI/DateTimeLayout";
import { colorLightGrey, colorPrimaryActive, colorPrimaryGrey, pxToRem, responsiveFontSizes } from "../../util/MUI_Theme/Theme";
import { getNotificationColor } from "../../util/enums/NotificationEntityTypes";


interface NotificationBarProps {
  heading: string;
  date: Date;
  notification: NotificationModel;
  // eslint-disable-next-line react/require-default-props
  displaySection?: "dashboard" | "navigationBar",
  // eslint-disable-next-line no-unused-vars
  handleRefresh: (refresh: boolean) => void;
}

const NotificationBar = ({heading, date, displaySection="dashboard", notification, handleRefresh }: NotificationBarProps) => {
  const notificationDate = new Date(date);
  const month = getMonthInWords(notificationDate);
  const day = notificationDate.getDate();
  const time = getTimeWithAMPM(notificationDate);
  const navigate = useNavigate()

  const handleMarkAsRead = async () => {

    await MarkNotificationAsRead({
      id: notification.id,
      notificationSeverityId: notification.notificationSeverityId,
      notificationEntityId: notification.notificationEntityId,
      recipientAccountId: notification.recipientAccountId,
      senderAccountId: notification.senderAccountId,
      message: notification.message,
      isRead: true,
    }).finally(() => {
      handleRefresh(true);
      toast.success("Notification marked as read.");
    });
  };

  const notificationColor = getNotificationColor(notification.notificationEntityId);
  const isConnectionRequest = heading === "New Supplier" || heading === "New Client";
  const headingText = isConnectionRequest ? "New Invitation Request" : heading;
  const showPersonIcon = isConnectionRequest && displaySection === "dashboard";
  const smallerView = displaySection === "navigationBar" ;
  return (
    <Grid item xs={12} lg={smallerView ? 12 : 6} p={2}>
      <Grid container minHeight={pxToRem(90)} sx={{ borderColor: colorLightGrey, borderRadius: "15px", borderWidth: "2px", borderStyle: "solid" }}>
        <Grid item xs={0.75} m={0} p={0}
          sx={{ backgroundColor: notificationColor, borderTopLeftRadius: "12px", borderBottomLeftRadius:"12px"}}
          color={colorPrimaryActive}
        />
        <Grid item xs={10} pl={2} py={2}>
          <Grid container>
            <Grid item md={smallerView ? 7: 8} xs={12}>
              <Typography variant="caption" color={colorPrimaryActive}>
                {
                  showPersonIcon && <PersonAddIcon style={{ verticalAlign: "middle", marginRight: "5px" }} />
                }
                {headingText}
              </Typography>
            </Grid>
            <Grid item md={smallerView ? 5 : 4} xs={12}>
              <Typography sx={{ ...responsiveFontSizes({ lg: 15, md: 12, sm: 11 }) }} color={colorPrimaryGrey}>
                {`${month} ${day} ${time}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="labelSmall" color={colorPrimaryGrey}>
                {notification.message}
              </Typography>
            </Grid>
            {
              notification.senderCompanyId != null && notification.senderCompanyId > 0 &&
              <Grid py={0.5} item xs={12}>
                <Button
                  id="accept-button"
                  onClick={() => navigate(notification.redirectUrl ?? `/viewprofile/${notification.senderCompanyId ?? 0}`)}
                  variant="contained"
                  sx={{ ...responsiveFontSizes({ lg: 16, md: 15, sm: 15 }) }}
                >
                  {notification.buttonTitle || "View Profile"}
                </Button>
              </Grid> 
            }
          </Grid>
        </Grid>
        <Grid item xs={1.25} px={0}>
          <IconButton onClick={handleMarkAsRead}>
            <CrossIcon sx={{color:colorPrimaryActive}}/>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}




export default NotificationBar;