import { useCallback, useEffect, useState } from "react";
import { Button, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import "../Connect/SearchResult/SweetAlert.css";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import UserService from "../../http/UserProfileService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserProfile } from "../../http/Redux/Store/UserProfileSlice";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import MarketingType from "../../util/enums/MarketingType";
import NotificationType from "../../util/enums/NotificationType";
import VisabilityType from "../../util/enums/VisabilityType";
import { fetchParticularUserProfile } from "../../http/Redux/Store/UserProfileActions";

const PlatformNotificationSettings = () => {

  const userProfile: UserProfileModel = useAppSelector(getUserProfile);
  const [editable, setEditable] = useState<UserProfileModel>(userProfile || {} as UserProfileModel);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const [isActiveApp, setIsActiveApp] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);

  const [isActiveCompanyVisibility, setActiveCompanyVisibility] = useState(false);
  const [isActiveConnectVisibility, setActiveConnectVisibility] = useState(false);
  const [isActivePlatformVisibility, setActivePlatformVisibility] = useState(false);
  const [isDisabledCompany, setIsDisabledCompany] = useState(false);
  const [isDisabledConnect, setIsDisabledConnect] = useState(false);
  const [isDisabledPlatform, setIsDisabledPlatform] = useState(false);
  const [isActiveEmailMarketing, setIsActiveEmailMarketing] = useState(false);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState(0);
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  const handleChangeSettings = useCallback(() => {
    switch (editable.notificationType) {
      case NotificationType.Both:
        setIsActiveApp(true);
        setIsActiveEmail(true);
        break;
      case NotificationType.Email:
        setIsActiveApp(false);
        setIsActiveEmail(true);
        break;
      default:
        setIsActiveApp(true);
        setIsActiveEmail(false);
        break;
    }

    switch (editable.visibilityType) {
      case VisabilityType.Platform:
        setActivePlatformVisibility(true);
        setActiveCompanyVisibility(false);
        setActiveConnectVisibility(false);
        setIsDisabledCompany(true);
        setIsDisabledPlatform(false);
        setIsDisabledConnect(true);
        break;
      case VisabilityType.Connect:
        setActiveConnectVisibility(true);
        setActivePlatformVisibility(false);
        setActiveCompanyVisibility(false);
        setIsDisabledCompany(true);
        setIsDisabledConnect(false);
        setIsDisabledPlatform(true);
        break;
      case VisabilityType.Company:
        setActiveCompanyVisibility(true);
        setActivePlatformVisibility(false);
        setActiveConnectVisibility(false);
        setIsDisabledCompany(false);
        setIsDisabledConnect(true);
        setIsDisabledPlatform(true);
        break;
      default:
        setIsDisabledCompany(false);
        setIsDisabledConnect(false);
        break;
    }

    switch (editable.marketingType) {
      case MarketingType.Email:
        setIsActiveEmailMarketing(true);
        break;
      default:
        setIsActiveEmailMarketing(false);
        break;
    }

    setIsSaveButtonActive(false);
  }, [editable.marketingType, editable.notificationType, editable.visibilityType]);

  useEffect(() => {

    if (userProfile) {
      setEditable(userProfile);
      handleChangeSettings();
    }

    if (updatedInfo === 200) {
      setShow(false);
      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });
    }

  }, [userProfile.id, updatedInfo, timeVal]);

  const handleSwitchOnClickApp = () => {
    if (isActiveApp) {
      setIsActiveApp(false);
      if (isActiveEmail) {
        setEditable({ ...editable, notificationType: NotificationType.Email });
      }
      else {
        setEditable({ ...editable, notificationType: NotificationType.None });
      }
    }
    else {
      setIsActiveApp(true);
      if (isActiveEmail) {
        setEditable({ ...editable, notificationType: NotificationType.Both });
      }
      else {
        setEditable({ ...editable, notificationType: NotificationType.App });
      }
    }
    setIsSaveButtonActive(true);
  }
  const handleSwitchOnClickEmail = () => {
    if (isActiveEmail) {
      setIsActiveEmail(false);
      if (isActiveApp) {
        setEditable({ ...editable, notificationType: NotificationType.App });
      }
      else {
        setEditable({ ...editable, notificationType: NotificationType.None });
      }
    }
    else {
      setIsActiveEmail(true);
      if (isActiveApp) {
        setEditable({ ...editable, notificationType: NotificationType.Both });
      }
      else {
        setEditable({ ...editable, notificationType: NotificationType.Email });
      }
    }
    setIsSaveButtonActive(true);




  }

  const handleSwitchOnClickEmailMarketing = () => {
    if (isActiveEmailMarketing) {
      setIsActiveEmailMarketing(false);
    }
    else {
      setIsActiveEmailMarketing(true);
      setEditable({ ...editable, marketingType: MarketingType.Email });

    }
    setIsSaveButtonActive(true);
  }



  const handleSwitchOnClickCompany = () => {
    if (isActiveCompanyVisibility) {
      setActiveCompanyVisibility(false);
      setIsDisabledPlatform(false);
      setIsDisabledConnect(false);

    }
    else {
      setActiveCompanyVisibility(true);
      setIsDisabledPlatform(true);
      setIsDisabledConnect(true);
      setEditable({ ...editable, visibilityType: VisabilityType.Company });
    }
    setIsSaveButtonActive(true);


  }
  const handleSwitchOnClickConnect = () => {
    if (isActiveConnectVisibility) {
      setActiveConnectVisibility(false);
      setIsDisabledPlatform(false);
      setIsDisabledCompany(false);
    }
    else {
      setActiveConnectVisibility(true);
      setIsDisabledPlatform(true);
      setIsDisabledCompany(true);
      setEditable({ ...editable, visibilityType: VisabilityType.Connect });
    }
    setIsSaveButtonActive(true);

  }
  const handleSwitchOnClickPlatform = () => {

    if (isActivePlatformVisibility) {
      setIsDisabledCompany(false);
      setIsDisabledConnect(false);
      setActivePlatformVisibility(false);
    }
    else {
      setIsDisabledCompany(true);
      setIsDisabledConnect(true);
      setActivePlatformVisibility(true);
      setEditable({ ...editable, visibilityType: VisabilityType.Platform });
    }
    setIsSaveButtonActive(true);

  }

  const handleSaveSettingsOnClick = () => {


    setShow(true);
    const loader = async () => {
      const userResponse: number = await UserService.updateUser(editable);
      dispatch(fetchParticularUserProfile(userProfile.accountId as string));
      setUpdatedInfo(userResponse);
    };
    loader();
  }

  return (

    <>
      <LoadingBackdrop showBackdrop={show} />
      <div>
        <Grid container>
          <Grid xs={6}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={isActiveApp} onClick={handleSwitchOnClickApp} />} label="System Notifications" />
              <FormControlLabel control={<Switch checked={isActiveEmail} onClick={handleSwitchOnClickEmail} />} label="Email" />
              <FormControlLabel control={<Switch checked={isActiveEmailMarketing} onClick={handleSwitchOnClickEmailMarketing} />} label="Email Marketing" />
            </FormGroup>
          </Grid>
          <Grid xs={6}>
            <div>Account Visibility</div>
            <FormGroup>
              <FormControlLabel control={<Switch checked={isActiveCompanyVisibility} disabled={isDisabledCompany}
                onClick={handleSwitchOnClickCompany} />} label="People in my company" />
              <FormControlLabel control={<Switch checked={isActiveConnectVisibility} disabled={isDisabledConnect}
                onClick={handleSwitchOnClickConnect} />} label="People in my connections" />
              <FormControlLabel control={<Switch checked={isActivePlatformVisibility} disabled={isDisabledPlatform}
                onClick={handleSwitchOnClickPlatform} />} label="Anyone on the platform" />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
      <div className={isSaveButtonActive ? "active" : "not-active"}>
        <Button variant="contained" className="save-account save-btns" onClick={handleSaveSettingsOnClick}>Save</Button>
      </div>
      <SweetAlert2 {...swalPropsChange} />
    </>


  )
};
export default PlatformNotificationSettings;

