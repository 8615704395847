import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import DashboardStatsCardModel, { DashboardStatsHeadings } from "./DashboardStatsCardModel";
import InLineSpinner from "../../../components/LoadingSpinners/InlineSpinner";
import useCompanyConnectionStats from "../../../util/hooks/queries/useCompanyConnectionStats";
import { colorPrimaryActive } from "../../../util/MUI_Theme/Theme";

interface DashboardStatCardProps {
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>
}

const DashboardStatsCard: FC<DashboardStatCardProps> = (props) => {
  const { isLoading: loadingdashboardStats, data: dashboardStats } = useCompanyConnectionStats();

  const navigate = useNavigate();

  return (
    <Grid container rowSpacing={3} sx={{ paddingBottom: 4 }} py={4} {...props}>
      {DashboardStatsHeadings.map((heading) => (
        <Grid
          key={heading.ref}
          xs={2.2}
          item
          sx={{
            textAlign: "center",
            color: colorPrimaryActive,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigate(heading.url)}
        >
          {loadingdashboardStats ? (<InLineSpinner />) : (
            <>
              <Typography>{heading.title}</Typography>
              <br />
              <Typography variant="pageTitle">{dashboardStats?.[heading.ref as keyof DashboardStatsCardModel]}</Typography>
            </>
          )}
        </Grid>
      ))}
    </Grid>

  )
}

export default DashboardStatsCard;