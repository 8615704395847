import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import ClientSpecificSupplierCategoriesSelector from "./ClientSpecificSupplierCategoriesSelector";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import InLineSpinner from "../../../LoadingSpinners/InlineSpinner";
import {
  getClientSpecificCategories,
  getSupplierCategoriesSelection,
  updateClientSpecificSubCategorySelectionAsync,
} from "../../../../http/Configuration/configurationApi";
import ClientSpecificSupplierCategoryMessage from "../../../../http/Configuration/ClientSpecificSupplierCategoryMessage";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";

interface ClientSpecificSupplierCategoriesProps {
  clientCompanyId: number;
  supplierCompanyId: number;
}

const ClientSpecificSupplierCategories = ({
  clientCompanyId,
  supplierCompanyId,
}: ClientSpecificSupplierCategoriesProps) => {
  const [expanded, setExpanded] = useState<string | false>("categorySelector");
  const [edit, setEdit] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [openSelector, setOpenSelector] = useState<boolean>(false);
  const [clientSpecificCategories, setClientSpecificCategories] = useState<
    Array<ClientSpecificSupplierCategoryMessage>
  >([] as Array<ClientSpecificSupplierCategoryMessage>);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Array<number>>(
    [] as Array<number>
  );

  // eslint-disable-next-line no-unused-vars
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);

  const handleAccordionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadCategoriesAndSelection = useCallback(async () => {
    try {
      // to do: add the buyer Id based on the work center buyer
      const fetchedCategoryData = await getClientSpecificCategories(clientCompanyId);
      const fetchedCategorySelection = await getSupplierCategoriesSelection(
        clientCompanyId,
        supplierCompanyId
      );
      setClientSpecificCategories(fetchedCategoryData);
      setSelectedCategoryIds(fetchedCategorySelection);
    } catch (error) {
      toast.error(
        "Unable to load categories at this time, please try again or contact your administator."
      );
    } finally {
      setLoadingCategories(false);
    }
  }, []);

  const onSubmitHandler = async (selection: number[]) => {
    try {
      setLoadingCategories(true);
      // update the api supplierCompanyId and buyerCompanyId
      await updateClientSpecificSubCategorySelectionAsync(
        clientCompanyId,
        supplierCompanyId,
        selection
      );

      setSelectedCategoryIds([...selection]);

      toast.success("Selected categories have been saved.");
    } catch {
      toast.error(
        "An error occurred when saving your selection. Please try again or contact your administator."
      );
    } finally {
      setLoadingCategories(false);
    }
  };

  const handleCategoryDelete = useCallback(
    async (categoryIdToDelete: number) => {
      const currentSelection = [...selectedCategoryIds];
      const filtered = currentSelection.filter(
        (currentCatId) => currentCatId !== categoryIdToDelete
      );

      try {
        // update the api supplierCompanyId and buyerCompanyId
        await updateClientSpecificSubCategorySelectionAsync(
          clientCompanyId,
          supplierCompanyId,
          filtered
        );

        setSelectedCategoryIds(filtered);

        toast.success("The category has been successfully removed.");
      } catch {
        // reset the selection.
        setSelectedCategoryIds(currentSelection);
        toast.error(
          "An error occurred when deleting the category. Please try again or contact your administator."
        );
      }
    },
    [clientCompanyId, supplierCompanyId, selectedCategoryIds]
  );

  const renderSelectedItemChips = () => {
    const selectedCategoryObjects = clientSpecificCategories
      .map((cat) => {
        const categories = cat.subCategories.filter((x) => selectedCategoryIds.includes(x.id));

        if (categories.length > 0) {
          return { ...cat, categoryObjects: categories };
        }

        return null;
      })
      .filter((x) => x != null);

    return selectedCategoryObjects.map((item) => (
      <Grid xs={12}>
        <Box sx={{ width: "100%" }}>
          <Typography>
            <b>{item?.name}</b>
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}
        >
          {item?.categoryObjects.map((f) => (
            <Chip
              sx={{ fontWeight: "bold" }}
              key={f.id}
              label={f.name}
              disabled={!edit}
              onDelete={() => handleCategoryDelete(f.id)}
            />
          ))}
        </Box>
      </Grid>
    ));
  };

  const EnableEdit = async () => {
    if (edit) {
      setEdit(false);
    }

    setEdit(!edit);
  };

  const CancelEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    setLoadingCategories(true);
    loadCategoriesAndSelection();
  }, [clientSpecificCategories.length, loadCategoriesAndSelection]);

  return (
    <Accordion
      expanded={expanded === "categorySelector"}
      id="categorySelectorAccordion"
      onChange={handleAccordionChange("categorySelector")}
      // className={styles.firstAccord}
      sx={{margin: "0px"}}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">Company-Specific Supplier Categories</h3>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="h6"
          sx={{ width: "50%", pb: "10px", textDecoration: "underline", float: "left" }}
        >
          Select which categories/services this supplier provides:
        </Typography>
        <Box className={styles.editSave} sx={{ width: "50% !important", float: "right" }}>
          <WithRequiredPermission permission={PermissionClassification.ViewAgentWorkCentre}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-supplier-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <Tooltip title="Cancel Edit">
                <FontAwesomeIcon
                  icon={faCancel}
                  aria-hidden="true"
                  id="edit-cancel-supplier"
                  onClick={CancelEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
          </WithRequiredPermission>
        </Box>
        <div className={styles.postBody}>
          <div className={styles.postInfo}>
            {loadingCategories ? (
              <InLineSpinner message="Loading..." />
            ) : (
              <Grid id="productServControlGrid" container rowSpacing={3}>
                <Grid container item xs={12} sx={{ m: 1 }}>
                  <Box sx={{ width: "100%" }}>
                    {renderSelectedItemChips()}
                    <Button
                      variant="outlined"
                      disabled={!edit}
                      onClick={() => setOpenSelector(true)}
                    >
                      SELECT CATEGORIES/SERVICES
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <ClientSpecificSupplierCategoriesSelector
                    showSelectorCallback={setOpenSelector}
                    isShowing={openSelector}
                    categories={clientSpecificCategories}
                    selectedCategories={selectedCategoryIds}
                    submitSelectionCallback={onSubmitHandler}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ClientSpecificSupplierCategories;
