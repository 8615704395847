import React, { useEffect, useMemo, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { BBEIcon } from "../../../../util/MUI_Theme/Icons";
import { BankAccountDetailsModel } from "../../../../http/Company/Models/BankAccountDetailsModel";
import DropdownField from "../Controls/DropdownField";
import { CountryModel } from "../../../../http/Company/Models/CountryModel";
import TextField from "../Controls/TextField";
import YesNoRadioButton from "../Controls/YesNoRadioButton";
import { Bank } from "../../../../http/Company/Models/Bank";
import useBankAccountTypes from "../../../../util/hooks/queries/useBankAccountTypes";
import { BankAccountTypeModel } from "../../../../http/Company/Models/BankAccountTypeModel";
import formatDate from "../../../../util/formatter/formatDate";
import BankModelProps from "../Models/bankModelProps";
import DatePicker from "../Controls/DatePicker";

interface BankingInfoProps {
  bankingDetailsModel: BankAccountDetailsModel;
  bankList: Array<Bank>;
  countryList: Array<CountryModel>;
  southAfricanCountryId: number | null;
  companyName: string;
  invalidFields: Array<string>;
  sectionEnabled: boolean,
  // eslint-disable-next-line no-unused-vars
  updateSectionEnabled: (fieldName: string, value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateBankingDetailsModel: (updatedModel: BankAccountDetailsModel) => void;
  // eslint-disable-next-line no-unused-vars
  setForeignBankFlag: (isForeignBank: boolean) => void;
}

const BANKING_ENABLED_FIELD_NAME = "hasBankAccountDetailsEnabled";

const BankingInfo = ({
  bankingDetailsModel,
  bankList,
  countryList,
  southAfricanCountryId,
  companyName,
  invalidFields,
  sectionEnabled,
  updateSectionEnabled,
  updateBankingDetailsModel,
  setForeignBankFlag,
}: BankingInfoProps) => {
  const DEFAULT_OBJECT: BankAccountDetailsModel = useMemo(
    () => ({
      id: bankingDetailsModel.id || 0,
      bankId: 0,
      accountTypeId: 0,
      accountName: bankingDetailsModel.accountName || null,
      accountNumber: null,
      branchName: null,
      swiftCode: null,
      branchCode: null,
      isDeleted: false,
      companyId: bankingDetailsModel.companyId,
      countryId: null,
      isPrimary: false,
      dateOfIssue: null,
      createdBy: "00000000-0000-0000-0000-000000000000",
      dateCreated: formatDate(new Date()),
      modifiedBy: "00000000-0000-0000-0000-000000000000",
      dateModified: formatDate(new Date()),
    }),
    [bankingDetailsModel.accountName, bankingDetailsModel.companyId, bankingDetailsModel.id]
  );

  const { data: bankAccountTypes = [] as Array<BankAccountTypeModel> } = useBankAccountTypes();

  const [isPrimaryBankAccount, setIsPrimaryBankAccount] = useState<boolean>(
    bankingDetailsModel.isPrimary || false
  );
  const [bankLetterIssueDate, setBankLetterIssueDate] = useState<Dayjs | null>(null);

  const handleBankAccountRadioBtnToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      updateBankingDetailsModel(DEFAULT_OBJECT);
      updateSectionEnabled(BANKING_ENABLED_FIELD_NAME, false);
      setBankLetterIssueDate(null);
      return;
    }

    updateSectionEnabled(BANKING_ENABLED_FIELD_NAME, true);
  };

  const handlePrimaryBankAccountToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      setIsPrimaryBankAccount(false);
      const bankDetails = { ...bankingDetailsModel, isPrimary: false };
      updateBankingDetailsModel(bankDetails);
      return;
    }

    setIsPrimaryBankAccount(true);
    const bankDetails = { ...bankingDetailsModel, isPrimary: true };
    updateBankingDetailsModel(bankDetails);
  };

  const handleForeignCountrySelection = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryId = Number(event.target.value);

    if (selectedCountryId === southAfricanCountryId) {
      setForeignBankFlag(false);
      return;
    }

    setForeignBankFlag(true);
  };

  const handleBankLetterIssueDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...bankingDetailsModel,
        dateOfIssue: null,
      } as BankAccountDetailsModel;
      setBankLetterIssueDate(null);
      updateBankingDetailsModel(newVal);
      return;
    }

    const newVal = {
      ...bankingDetailsModel,
      dateOfIssue: formatDate(selectedDate.toDate().toString()),
    } as BankAccountDetailsModel;
    setBankLetterIssueDate(selectedDate);
    updateBankingDetailsModel(newVal);
  };

  const setBankValues = (bankIdToUse: number) => {
    // Don't do SWIFT for foreign banks.
    if (bankingDetailsModel.countryId !== southAfricanCountryId) return;

    // Same bank, don't do a change
    if (bankingDetailsModel.bankId === bankIdToUse) return;

    const selectedBank = bankList.find((bank) => bank.id === bankIdToUse);

    const newVal = {
      ...bankingDetailsModel,
      bankId: bankIdToUse,
      branchCode: selectedBank?.universalBranchCode,
      branchName: selectedBank?.universalBranchCode ? "Universal" : null,
      swiftCode: selectedBank?.swiftCode || null,
    };

    updateBankingDetailsModel(newVal);
  };

  const renderStandardFields = (bankModel: BankAccountDetailsModel) => (
    <>
      <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
        <Grid item xs={4}>
          <DropdownField
            required
            label="Bank Name"
            disabled={!sectionEnabled}
            value={bankModel.bankId || 0}
            onChange={(e) => {
              setBankValues(Number(e.target.value));
            }}
            error={invalidFields.includes(BankModelProps.bankId)}
          >
            <MenuItem key={0} value={0} disabled>
              Please Select...
            </MenuItem>
            {bankList.map((bank) => (
              <MenuItem key={bank.id} value={bank.id}>
                {bank.name}
              </MenuItem>
            ))}
          </DropdownField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Branch Code"
            value={bankModel?.branchCode || ""}
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                branchCode: e.target.value,
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.branchCode)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Account Number"
            inputProps={{ maxLength: 15 }}
            value={bankModel?.accountNumber || ""}
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                accountNumber: e.target.value,
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.accountNumber)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
        <Grid item xs={4}>
          <DropdownField
            required
            label="Account Type"
            disabled={!sectionEnabled}
            value={bankModel.accountTypeId || 0}
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                accountTypeId: Number(e.target.value),
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.accountTypeId)}
          >
            <MenuItem key={0} value={0} disabled>
              Please Select...
            </MenuItem>
            {bankAccountTypes.map((accType) => (
              <MenuItem key={accType.id} value={accType.id}>
                {accType.name}
              </MenuItem>
            ))}
          </DropdownField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Account Holder"
            value={bankModel?.accountName || ""}
            helperText={
              bankModel.accountName !== companyName
                ? "Account Holder Name Does Not Match Company Name"
                : ""
            }
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                accountName: e.target.value,
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.accountName)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Branch Name"
            inputProps={{ maxLength: 15 }}
            value={bankModel?.branchName || ""}
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                branchName: e.target.value,
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.branchName)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
        <Grid item xs={4}>
          <TextField
            required={bankingDetailsModel.countryId !== southAfricanCountryId}
            disabled={!sectionEnabled}
            label="SWIFT Code"
            value={bankModel?.swiftCode || ""}
            onChange={(e) => {
              const newVal = {
                ...bankModel,
                swiftCode: e.target.value,
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.swiftCode)}
          />
        </Grid>
        <Grid item xs={4}>
          <YesNoRadioButton
            required
            disabled={!sectionEnabled}
            title="Primary Bank Account"
            valueCondition={isPrimaryBankAccount ? "yes" : "no"}
            onToggle={handlePrimaryBankAccountToggle}
          />
        </Grid>
        <Grid xs={4}>
          <DatePicker
            required
            disableFuture
            disabled={!sectionEnabled}
            value={bankLetterIssueDate}
            title="Bank Letter Date of Issue"
            width="93%"
            setDatePicker={handleBankLetterIssueDateChange}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderForeignFields = (bankModel: BankAccountDetailsModel) => (
    <>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Bank Name"
            inputProps={{ maxLength: 15 }}
            value={bankModel.textBankName || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, textBankName: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.textBankName)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Account Number"
            inputProps={{ maxLength: 15 }}
            value={bankModel.accountNumber || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, accountNumber: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.accountNumber)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Account Type"
            inputProps={{ maxLength: 15 }}
            value={bankModel.textAccountType || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, textAccountType: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.textAccountType)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Branch Code"
            inputProps={{ maxLength: 15 }}
            value={bankModel.branchCode || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, branchCode: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.branchCode)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Account Holder"
            value={bankModel.accountName || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, accountName: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.accountName)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Branch Name"
            inputProps={{ maxLength: 15 }}
            value={bankModel.branchName || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, branchName: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.branchName)}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="SWIFT code"
            inputProps={{ maxLength: 15 }}
            value={bankModel.swiftCode || ""}
            onChange={(e) => {
              const newVal = { ...bankModel, swiftCode: e.target.value };
              updateBankingDetailsModel(newVal);
            }}
            error={invalidFields.includes(BankModelProps.swiftCode)}
          />
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <YesNoRadioButton
            required
            disabled={!sectionEnabled}
            title="Primary Bank Account"
            valueCondition={isPrimaryBankAccount ? "yes" : "no"}
            onToggle={handlePrimaryBankAccountToggle}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disableFuture
            disabled={!sectionEnabled}
            value={bankLetterIssueDate}
            title="Bank Letter Date of Issue"
            width="90%"
            setDatePicker={handleBankLetterIssueDateChange}
          />
        </Grid>
      </Grid>
    </>
  );

  useEffect(() => {
    setBankLetterIssueDate(dayjs(bankingDetailsModel.dateOfIssue));
  }, [bankingDetailsModel.dateOfIssue]);

  return (
    <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
      <Grid item xs={12} className="profile-view-labels">
        <BBEIcon /> BANKING INFORMATION
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <YesNoRadioButton
            key={sectionEnabled?.toString() || "false"}
            title="Do You Have A Bank Account ?"
            valueCondition={sectionEnabled ? "yes" : "no"}
            onToggle={handleBankAccountRadioBtnToggle}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DropdownField
            required
            label="Bank Account Country"
            disabled={!sectionEnabled}
            value={bankingDetailsModel.countryId || 0}
            onChange={(e) => {
              const newVal = {
                ...bankingDetailsModel,
                countryId: Number(e.target.value),
              } as BankAccountDetailsModel;
              updateBankingDetailsModel(newVal);
              handleForeignCountrySelection(e);
            }}
          >
            <MenuItem value={0} disabled>
              Please Select...
            </MenuItem>
            {countryList.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </DropdownField>
        </Grid>
      </Grid>
      {bankingDetailsModel?.countryId !== southAfricanCountryId
        ? renderForeignFields(bankingDetailsModel)
        : renderStandardFields(bankingDetailsModel)}
    </Grid>
  );
};

export default BankingInfo;
