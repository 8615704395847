import { Button, Divider, Grid, IconButton } from "@mui/material";
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";
import { ValidationError } from "yup";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "../../Registration/CustomComponents/Controls/TextField";
import { colorLightGrey } from "../../../util/MUI_Theme/Theme";
import extractFieldData from "../../Registration/Utils/extractFieldDataHelper";
import newConnectionInvitationsValidationSchema from "./newConnectionInvitationsValidationSchema";
import ConnectionType from "../../../util/enums/ConnectionType";

export interface NewConnectionInvitationMessage {
  fullName: string;
  emailAddress: string;
  registeredCompanyName: string;
  messageText: string;
  connectionType: ConnectionType;
}

export interface InvitationListProps {
  defaultData: NewConnectionInvitationMessage[];
  canAddMore?: boolean;  // eslint-disable-line react/require-default-props
  uniqueKey: string;
}

export interface InvitationListPropsComponent {
  validate: () => Promise<boolean>;
  internalData: NewConnectionInvitationMessage[];
}

const InvitationsListComponent = forwardRef<InvitationListPropsComponent, InvitationListProps>((props, ref) => {
  const [internalConnectionRequests, setInternalConnectionRequests] = useState<NewConnectionInvitationMessage[]>(props.defaultData);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const onAddNewConnectionClick = () => {
    const newList = [...internalConnectionRequests, {} as NewConnectionInvitationMessage];
    setInternalConnectionRequests(newList);
  }

  const handleConnectionRequestOnChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { fieldName, fieldValue } = extractFieldData(ev);
    const updatedConnectionRequests = [...internalConnectionRequests];
    updatedConnectionRequests[index] = {
      ...internalConnectionRequests[index],
      [fieldName]: fieldValue,
    }
    setInternalConnectionRequests(updatedConnectionRequests);
  }

  const handleRemoveConnection = (index: number) => {
    const updatedConnectionRequests = internalConnectionRequests.filter((_, i) => i !== index);
    setInternalConnectionRequests(updatedConnectionRequests);
  }

  const validateData = async () => {
    try {
      await newConnectionInvitationsValidationSchema.validate(internalConnectionRequests, { abortEarly: false });
      return true;
    } catch (error) {
      const errors: { [key: string]: string } = {};
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          if (err.path != null) errors[err.path] = err.message;
        });
      }
      setValidationErrors(errors);
      return false;
    }
  }

  useImperativeHandle(ref, () => ({
    validate: validateData,
    internalData: internalConnectionRequests,
  }));

  return (
    <>
      {
        internalConnectionRequests.map((x, index) =>
          <div key={`${props.uniqueKey}-${index}`}>
            {
              index > 0 &&
              <>
                <br />
                <Divider sx={{ borderColor: colorLightGrey, borderWidth: "thin", minWidth: "100%" }} />
              </>
            }
            <Grid container px={10} py={2} my={2}>
              {index > 0 && (
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IconButton onClick={() => handleRemoveConnection(index)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="fullName"
                    value={x.fullName}
                    onChange={(ev) => handleConnectionRequestOnChange(ev, index)}
                    defaultErrorBehaviour
                    label="Primary Company Representative Full Name"
                    placeholder="Enter Company Representative Full Name"
                    sx={{ width: "93%" }}
                    error={validationErrors[`[${index}].fullName`] != null}
                    helperText={validationErrors[`[${index}].fullName`]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="emailAddress"
                    value={x.emailAddress}
                    onChange={(ev) => handleConnectionRequestOnChange(ev, index)}
                    defaultErrorBehaviour
                    label="Primary Company Representative Email Address"
                    placeholder="Company Representative Email Address"
                    sx={{ width: "93%" }}
                    error={validationErrors[`[${index}].emailAddress`] != null}
                    helperText={validationErrors[`[${index}].emailAddress`]}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="registeredCompanyName"
                    value={x.registeredCompanyName}
                    onChange={(ev) => handleConnectionRequestOnChange(ev, index)}
                    defaultErrorBehaviour
                    label="Registered Company Name"
                    placeholder="Registered Company Name"
                    sx={{ width: "93%" }}
                    error={validationErrors[`[${index}].registeredCompanyName`] != null}
                    helperText={validationErrors[`[${index}].registeredCompanyName`]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
      }
      {
        props.canAddMore &&
        <Grid container py={2} px={10}>
          <Button
            variant="contained"
            onClick={(ev) => {
              ev.preventDefault();
              onAddNewConnectionClick();
            }}
          >
            ADD MORE
          </Button>
        </Grid>
      }
    </>
  );
});

export default InvitationsListComponent;