/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BaseCompanyInfo } from "../../Company/Models/BaseCompanyInfo";
import { CompanyProfileCompleteSummaryMessage } from "../../Company/Messages/companyProfileCompletionSummaryMessage";

export interface BaseCompanyInfoState {
  baseCompanyInfo: BaseCompanyInfo;
}
const initialProfileCompletionSummary: CompanyProfileCompleteSummaryMessage = {
  general: false,
  contacts: false,
  addresses: false,
  beeDetails: false,
  bankDetails: false,
  documents: false,
  productsAndService: false,
  shareholders: false,
};

export const baseCompanyInfoSlice = createSlice({
  name: "baseCompanyInfo",
  initialState: {
    subscriptionPermissionsList: [],
    profileCompletionSummary: initialProfileCompletionSummary,
  } as unknown as BaseCompanyInfo,
  reducers: {
    setBaseCompanyInfoState: (
      state,
      action: PayloadAction<BaseCompanyInfo>
    ) => {
      state.companyId = action.payload.companyId;
      state.companyRegisteredName = action.payload.companyRegisteredName;
      state.companyRegistrationNumber = action.payload.companyRegistrationNumber;
      state.countryId = action.payload.countryId;
    },
    setSubscriptionPermissionsList: (
      state,
      action: PayloadAction<number[]>
    ) => {
      state.subscriptionPermissionsList = action.payload ?? []
    },
    setCompanyProfileCompletionSummary: (
      state,
      action: PayloadAction<CompanyProfileCompleteSummaryMessage>
    ) => {
      state.profileCompletionSummary = action.payload
    },
  },
});

export const { setBaseCompanyInfoState,
  setSubscriptionPermissionsList,
  setCompanyProfileCompletionSummary } = baseCompanyInfoSlice.actions;

export const selectBaseCompanyInfo = (state: RootState): BaseCompanyInfo =>
  state.baseCompanyInfo;
export const getCurrentCompanyId = (state: RootState) => state.baseCompanyInfo.companyId;
export default baseCompanyInfoSlice.reducer;
