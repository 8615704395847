import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, Remove } from "@mui/icons-material";

import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  Button,
  ListItemText,
  TextField,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { FilterOptionList } from "../../../util/genericInterfaces/FilterOptionList";
import { useAppSelector } from "../../../app/hooks";
import { selectCountryList } from "../../../http/Redux/Store/CountryListSlice";

interface MultiSelectFilterProps {
  filterName: string;

  filterOptionsList: Array<FilterOptionList>;

  // eslint-disable-next-line no-unused-vars
  updateFilterModel: (
    // eslint-disable-next-line no-unused-vars
    updateValue: Array<number>,

    // eslint-disable-next-line no-unused-vars
    propertyToUpdate: keyof ConnectionSearchModel
  ) => void;

  // eslint-disable-next-line react/no-unused-prop-types
  searchModelProperty: keyof ConnectionSearchModel;

  // eslint-disable-next-line react/require-default-props
  forceFilterSearchBox?: boolean;

  // eslint-disable-next-line no-unused-vars, react/require-default-props
  callBack?: (disable: boolean) => void;
}

// eslint-disable-next-line no-unused-vars
const MultiSelectFilter = ({
  filterName,
  filterOptionsList,
  updateFilterModel,
  searchModelProperty,
  forceFilterSearchBox = false,
  callBack,
}: MultiSelectFilterProps) => {
  const countries = useAppSelector(selectCountryList);
  const saValue = countries?.filter((x) => x.name.toLowerCase() === "south africa")[0]?.id;

  const [openFilterBox, setOpenFilterBox] = useState<boolean>(false);
  const [selectedFilterOptionIds, setSelectedFilterOptionIds] = useState<Array<number>>(
    [] as Array<number>
  );
  const [filteredValues, setFilteredValues] = useState<Array<FilterOptionList>>(filterOptionsList);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelectedVals = [...selectedFilterOptionIds];

    if (!event.target.checked) {
      const filteredVals = currentSelectedVals.filter(
        (x) => x !== parseInt(event.target.value, 10)
      );
      setSelectedFilterOptionIds(filteredVals);

      updateFilterModel(filteredVals, searchModelProperty);
      return;
    }

    const updatedVals = [...currentSelectedVals, parseInt(event.target.value, 10)];
    setSelectedFilterOptionIds(updatedVals);
    updateFilterModel(updatedVals, searchModelProperty);
  };

  const handleClear = () => {
    const emptyNumberArray = [] as Array<number>;
    setSelectedFilterOptionIds(emptyNumberArray);
    setSearchValue("");
    setFilteredValues(filterOptionsList);
    updateFilterModel(emptyNumberArray, searchModelProperty);
  };

  const handleFilterOptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setSearchValue(value);

    // If the searchbox is empty, or receives an empty string, use the original.
    if (!value || value.trim() === "") {
      setFilteredValues(filterOptionsList);
    }

    const filtered = filterOptionsList.filter((options) => options.name?.includes(value));
    setFilteredValues(filtered);
  };

  const handleClearClick = () => setSearchValue("");

  // The only reason this useEffect does a thing is to handle the South Africa-Province thing
  useEffect(() => {
    if (filterName.toLowerCase() !== "countries") return;

    if (
      selectedFilterOptionIds.length === 0 ||
      (saValue &&
        selectedFilterOptionIds.length === 1 &&
        selectedFilterOptionIds.find((x) => x === saValue)) ||
      (saValue && selectedFilterOptionIds.length > 1 && selectedFilterOptionIds.includes(saValue))
    ) {
      callBack?.(true);
    } else {
      callBack?.(false);
    }

    if (
      saValue &&
      selectedFilterOptionIds.length === 1 &&
      selectedFilterOptionIds.find((x) => x === saValue)
    ) {
      updateFilterModel(selectedFilterOptionIds, searchModelProperty);
      setOpenFilterBox(true);
    }
  }, [selectedFilterOptionIds.length]);

  return (
    <>
      <ListItemButton onClick={() => setOpenFilterBox((old) => !old)}>
        <ListItemText primary={filterName} />
        {(selectedFilterOptionIds.length > 0 || searchValue.length > 0) && (
          <Button variant="text" onClick={handleClear}>
            Clear
          </Button>
        )}
        {openFilterBox ? (
          <Remove className="cl-lightGray" />
        ) : (
          <KeyboardArrowDown className="cl-lightGray" />
        )}
      </ListItemButton>
      <Collapse in={openFilterBox} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <FormControl fullWidth>
            <Box className="advanced-filter-box">
              <FormGroup>
                {/* Thought 15 was a decent minimum for hiding or displaying this search filter. */}
                {(filterOptionsList.length > 15 || forceFilterSearchBox) && (
                  <TextField
                    id="search-bar"
                    value={searchValue}
                    className="text"
                    onChange={handleFilterOptionChange}
                    placeholder="Search..."
                    inputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{ visbility: searchValue ? "visible" : "hidden" }}
                          onClick={handleClearClick}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      border: "none",
                      margin: "0px",
                      "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
                    }}
                    variant="outlined"
                    size="small"
                  />
                )}
                {filteredValues.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={`${option.id}-${option?.name ? option.name : option.value}`}
                        onChange={handleCheckboxChanged}
                        value={option.id}
                        checked={selectedFilterOptionIds.includes(option.id)}
                      />
                    }
                    label={option?.name ? option.name : option.value}
                  />
                ))}
              </FormGroup>
            </Box>
          </FormControl>
        </List>
      </Collapse>
    </>
  );
};

export default MultiSelectFilter;
