import { useEffect, useState, useCallback } from "react";
import { DataGridPro, DataGridProProps, GridPaginationModel } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { SearchResultModel } from "./SearchView";
import useSearchColumnConfig from "./useSearchColumnConfig";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import SearchDataGridDetailPanel from "./SearchDataGridDetailPanel";

export interface Props {
  searchResults: SearchResultModel[];
  paginationModel: GridPaginationModel;
  searchType: NetconnSearchType;
  hasIBuyPermission: boolean;
  // eslint-disable-next-line no-unused-vars
  updatePaginationModel: (pagingModel: GridPaginationModel) => void;
  // eslint-disable-next-line no-unused-vars, react/require-default-props
  reloadCallback: () => void;
}

const SearchDataGrid = ({
  searchResults,
  paginationModel,
  searchType,
  hasIBuyPermission,
  updatePaginationModel,
  reloadCallback,
}: Props) => {

  const [visibleSearchResult, setVisibleSearchResults] =
    useState<SearchResultModel[]>(searchResults);
  const columns = useSearchColumnConfig(reloadCallback, searchType);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(
    ({ row }) => <SearchDataGridDetailPanel row={row} />,
    [hasIBuyPermission]
  );

  const getDetailPanelHeight = useCallback<NonNullable<DataGridProProps["getDetailPanelHeight"]>>(
    () => "auto" as const,
    []
  );

  useEffect(() => {
    setVisibleSearchResults(searchResults);
  }, [searchResults]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <DataGridPro
        className="gridclass"
        rows={visibleSearchResult || ([] as SearchResultModel[])}
        rowCount={visibleSearchResult[0]?.totalRows || 0}
        initialState={{
          columns: {
            columnVisibilityModel: {
              reg: false,
            },
          },
        }}
        columns={columns}
        rowHeight={120}
        disableVirtualization
        disableColumnFilter
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={updatePaginationModel}
        pageSizeOptions={[5, 10, 25, 100]}
        getDetailPanelHeight={
          (searchType === NetconnSearchType.Supplier) && hasIBuyPermission
            ? getDetailPanelHeight
            : undefined
        }
        getDetailPanelContent={
          (searchType === NetconnSearchType.Supplier) && hasIBuyPermission
            ? getDetailPanelContent
            : undefined
        }
        getRowId={(row: SearchResultModel) => `${row.name}-${row.id}`}
      />
    </Box>
  );
};

export default SearchDataGrid;
