import React from "react";
import { Grid } from "@mui/material";
import InLineSpinner from "../../../../components/LoadingSpinners/InlineSpinner";

interface LoadingFieldSpinnerProps {
  required: boolean,
  title: string,
}

const LoadingFieldSpinner = ({ ...props }: LoadingFieldSpinnerProps) => (
  <>
    <div className="text-label-box">
      {props.required && <span className="text-label-astrid">*</span>}
      {props.title}
    </div>
    <Grid xs={10}>
      <InLineSpinner />
    </Grid>
  </>
);

export default LoadingFieldSpinner;