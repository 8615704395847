/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Box, Typography, Button } from "@mui/material";
import { TaskAltOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PaymentStatusEnum, { paymentStatusColor, paymentStatusToString } from "../../http/SubscriptionManagement/Enums/PaymentStatusEnum";
import { CancelSubscription as CancelBillingSubscription } from "../../http/Billing/BillingApi";
import BasicDialog from "../Dialogs/BasicDialog";
import InLineSpinner from "../LoadingSpinners/InlineSpinner";
import { cancelOngoingSubscription as CancelOngoingSubscriptionPackage, getIsFree } from "../../http/SubscriptionManagement/SubscriptionApi";
import formatDate from "../../util/formatter/formatDate";
import { RemoveMyCompanyFromThePlatform } from "../../http/Company/company";
import useAppLogOut from "../../util/hooks/useLogOutOfApp";
import MyLoader from "../UI/LoadingOverlay";
import delay from "../../util/functions/delay";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import ActivityStatus from "../../util/enums/ActivityStatus";
import UserPromptActivityService from "../../http/UserPromptActivityService";
import { fetchUserPromptActivity } from "../../http/Redux/Store/UserPromptActivityActions";
import PromptStatus from "../../util/enums/PromptStatus";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SubscriptionBillingHistoryTable from "./BillingHistoryTable";
import { CompanySubscriptionMessage } from "../../http/SubscriptionManagement/Models/CompanySubscription";

const ActiveSubscriptionCard = (
  {
    planName,
    subscription,
    showSubscriptionViewCallback,
  }:
    {
      planName: string,
      subscription: CompanySubscriptionMessage,
      showSubscriptionViewCallback: () => void,
    }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUnsubscribe, setIsLoadingUnsubscribe] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUnsubscribeEntirelyModal, setShowUnsubscribeEntirelyModal] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(subscription);
  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(subscription.isCancelled);
  const [isFree, setIsFree] = useState(false);
  const dispatch = useAppDispatch();
  const userPrompt = useAppSelector(getUserPromptActivity);
  // eslint-disable-next-line no-unused-vars
  const logOut = useAppLogOut();

  const AddMessage = async () => {
    const newModel: UserPromptActivityModel = {
      id: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.id,
      accountId: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.accountId,
      popupPromptId: UserPopupActivities.Message,
      status: PromptStatus.Show,
      seenPopupId: 0,
      dateCreated: new Date(),
      dateModified: new Date(),
    };
    await UserPromptActivityService.updateUserPromptActivity(newModel).finally(() => {
      dispatch(fetchUserPromptActivity(userPrompt[0].accountId as string, currentSubscription.companyId));
    });
  }
  const onCancelSubscriptionClick = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      setIsLoading(true);
      if (currentSubscription?.subscriptionBilling != null && currentSubscription?.subscriptionBilling?.value > 0) {
        await CancelBillingSubscription();
      }
      const cancelledSubscription = await CancelOngoingSubscriptionPackage();
      setCurrentSubscription(cancelledSubscription as CompanySubscriptionMessage);
      setSubscriptionIsCancelled(true);
      setShowCancelModal(false);

      await AddMessage();

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  }

  const onUnsubscribeMeEntirelyClick = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      setShowUnsubscribeEntirelyModal(false)
      setIsLoadingUnsubscribe(true);
      toast.success("Okay. We’ve removed you from the B1 LINK directory, and are sorry to see you go. You’re welcome back anytime.");
      await delay(1000);
      await CancelOngoingSubscriptionPackage();
      if (currentSubscription.subscriptionBilling?.paymentStatus === PaymentStatusEnum.Success) {
        await CancelBillingSubscription();
      }
      await RemoveMyCompanyFromThePlatform();
      logOut();
      setIsLoadingUnsubscribe(false);
    } catch (err) {
      setIsLoadingUnsubscribe(false);
      throw err;
    }
  }
  const isPendingDowngrade = currentSubscription?.isPendingDowngrade === true;
  const getPendingDowngradeTooltipText = () => {
    if (isPendingDowngrade) {
      return `Pending downgrade to ${currentSubscription?.newPlanAfterDowngrade?.planCode} package on 
                ${formatDate(currentSubscription?.effectiveCancellationDate ?? "")}`
    }
    return ""
  }

  const renderCurrentPackageInfo = () => (
    <span>
      <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", opacity: isPendingDowngrade ? 0.5 : 1 }} color="textSecondary">
        Price: R{currentSubscription.subscriptionBilling?.value}
      </Typography>
      {currentSubscription.subscriptionBilling?.value !== 0 && (
        <>
          <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", opacity: isPendingDowngrade ? 0.5 : 1 }}
            color="textSecondary">
            Payment Status:
            <Typography sx={{ marginLeft: "3px" }} color={paymentStatusColor(currentSubscription.subscriptionBilling?.paymentStatus ?? 0)} style={{ opacity: 1 }}>
              {paymentStatusToString(currentSubscription.subscriptionBilling?.paymentStatus ?? 0)}
            </Typography>
          </Typography>
          <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", opacity: isPendingDowngrade ? 0.5 : 1 }}
            color="textSecondary">
            Expected Payment Date: {currentSubscription.subscriptionBilling?.payDate
              ? formatDate(currentSubscription.subscriptionBilling?.payDate)
              : "N/A"}
          </Typography>
        </>
      )}
    </span>
  );


  useEffect(() => {
    const loader = async () => {
      try {
        setIsLoading(true);
        const data = await getIsFree(subscription.companyId);
        setIsFree(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    loader();
  }, [])



  return (
    <>
      {isLoading && <InLineSpinner />}
      <BasicDialog
        openCloseSwitch={showCancelModal}
        dialogTitle="Cancel Subscription"
        dialogText="You are about to cancel your current subscription.
          You will no longer have access to the feature once you have done so.. Do you want to continue?"
        onOkClick={onCancelSubscriptionClick}
        onClose={() => setShowCancelModal(false)}
        okButtonText="Cancel subscription"
      />
      <MyLoader active={isLoadingUnsubscribe}>
        <BasicDialog
          openCloseSwitch={showUnsubscribeEntirelyModal}
          dialogTitle="Unsubscribe entirely from the platform"
          dialogText={
            <>
              <Typography gutterBottom>
                <b>Cancel subscription package?</b>
              </Typography>
              <br />
              <Typography gutterBottom>
                Cancelling your
                subscription may impact B1 LINK supply chain reporting for any of your connected clients on the platform.
              </Typography>
              <br />
              {!isFree &&
                <Typography gutterBottom>
                  Why not rather downgrade to a FREE package, which allows us to continue servicing your clients needs?
                </Typography>
              }
              <br />
              <Typography gutterBottom>
                <b>Please consider this carefully</b> before proceeding with the unsubscribe process.
              </Typography>
            </>
          }
          onOkClick={onUnsubscribeMeEntirelyClick}
          onClose={() => setShowUnsubscribeEntirelyModal(false)}
          okButtonText="Cancel subscription"
          hideSecondaryButton={isFree}
          secondaryButtonText="Downgrade to free"
          onSecondaryClick={() => {
            toast.success(`Thanks for staying with us.
            We’ve downgraded you to the B1 LINK FREE package.\n \n You can upgrade again at any time.`)
            delay(1000);
            setShowUnsubscribeEntirelyModal(false);
            onCancelSubscriptionClick();
          }}
        />
      </MyLoader>
      <Box sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Box>
            <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "440px" }} color="textSecondary">
              Current Subscription Package: <b style={{ marginLeft: "3px" }}> {planName}</b>
            </Typography>
            {renderCurrentPackageInfo()}
            {isPendingDowngrade === true &&
              <Typography color="orange" style={{ display: "inline", marginTop: "-40px" }}>
                {getPendingDowngradeTooltipText()}
              </Typography>
            }
            {
              currentSubscription.isCancelled &&
              <>
                <div className="hr-line" />
                <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} color="textSecondary">
                  Subscription Status: <Typography color="red"> CANCELLED</Typography>
                </Typography>
                <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} color="textSecondary">
                  Cancellation Date: {formatDate(currentSubscription.dateCancelled)}
                </Typography>
              </>
            }
          </Box>
          {
            subscription?.productAddOns.length > 0 &&
            <Box>
              <Typography variant="h6" color="textSecondary" component="span">Add on products:</Typography>
              {
                currentSubscription.productAddOns
                  .map(x => (
                    <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", fontSize: 12 }}
                      color="textSecondary"
                      variant="inherit"
                      component="p">
                      <TaskAltOutlined fontSize="small" /> {x.productName}
                    </Typography>
                  ))
              }
            </Box>
          }
          <Box sx={{ marginTop: "13px", marginBottom: "13px", float: "right" }}>
            {subscriptionIsCancelled ?
              <Button
                variant="contained"
                color="primary"
                onClick={showSubscriptionViewCallback}
              >
                Purchase new subscription
              </Button> :
              <Grid container>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "13px" }}
                    onClick={showSubscriptionViewCallback}
                    disabled={isLoadingUnsubscribe || isLoading}
                  >
                    View Available Packages
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setShowUnsubscribeEntirelyModal(true)}
                    disabled={isLoadingUnsubscribe || isLoading}
                  >
                    Unsubscribe me entirely
                  </Button>
                </Grid>
              </Grid>
            }
          </Box>
          <Box sx={{ width: "100%", float: "left" }}>
            <SubscriptionBillingHistoryTable />
          </Box>
        </Grid>
      </Box>
    </>
  )
};

export default ActiveSubscriptionCard;