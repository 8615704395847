import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetAllBeeRatingLookupLists } from "../../Company/CompanyBeeRatingLookups";
import { BeeRatingLookupListModel } from "../Models/BeeRatingLookupListModel";
import { BeeRatingLookupModel } from "../Models/BeeRatingLookupModel";
import { beeRatingSourcesSlice, beeRatingStatusesSlice, beeRatingVerificationAgenciesSlice } from "./BeeRatingsLookupSlice";

export const beeRatingStatusListActions = beeRatingStatusesSlice.actions;
export const beeRatingSourceListActions = beeRatingSourcesSlice.actions;
export const beeRatingVerificationAgentListActions = beeRatingVerificationAgenciesSlice.actions;

export const fetchAllBeeRatingLookupLists = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: BeeRatingLookupListModel = await GetAllBeeRatingLookupLists();
  dispatch(
    beeRatingStatusListActions.setBeeRatingStatusListState(
      apiResponse.beeRatingStatuses || [] as Array<BeeRatingLookupModel>
    )
  );

  dispatch(
    beeRatingSourceListActions.setBeeRatingSourcesListState(
      apiResponse.beeRatingSources || [] as Array<BeeRatingLookupModel>
    )
  );

  dispatch(
    beeRatingVerificationAgentListActions.setBeeRatingsVerificationAgencyListState(
      apiResponse.beeRatingVerificationAgencies  || [] as Array<BeeRatingLookupModel>
    )
  );
}