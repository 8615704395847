import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { packageMatrixSlice, userTourActivitySlice } from "./UserTourActivitySlice"
import { RootState } from "../../../app/store"
import { UserTourActivityModel } from "../Models/UserTourActivityModel";
import UserTourActivityService from "../../UserTourActivityService";
import { PackageMatrixModel } from "../Models/PackageMatrixModel";
import SubscriptionApi from "../../SubscriptionManagement/SubscriptionApi";


export const userTourActivityActions = userTourActivitySlice.actions


export const fetchUserTourActivity = (accountId: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: UserTourActivityModel[] = await UserTourActivityService.getAllUserTourActivity(accountId);
  dispatch(userTourActivityActions.setUserTourActivity(response))
}


export const packageMatrixActions = packageMatrixSlice.actions

export const fetchPackageMatrices = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: PackageMatrixModel[] = await SubscriptionApi.getPackageMatrices();
  dispatch(packageMatrixActions.setPackageMatrices(response))
}
