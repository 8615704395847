import { Grid, TextField, Typography, Box, Button, Chip } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { IRegistrationStepPageComponent } from "./IRegistrationStepComponent";
import GlobalProductServicesSelector from "../../../components/Company/CompanyPost/CompanyProductsAndServices/GlobalProductAndServicesSelector";
import { UnspscSegment } from "../../../http/Configuration/UnspscSegment";
import useUnspcOptions from "../../../util/hooks/queries/useUnspcOptions";
import useCompanyUnspscSelection from "../../../util/hooks/queries/useCompanyUnspscSelection";
import RegistrationSkeleton from "../Sections/RegistrationSkeleton";
import { updateUnspscSelection } from "../../../http/Configuration/configurationApi";
import queryKeys from "../../../util/hooks/queries/queryKeys";
import useGoodsAndServiceValidation from "../../../util/hooks/validation/useGoodAndServiceValidation";
import { ValidationResponse } from "../../../util/hooks/validation/useValidation";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import { GetCompanyDataById, SaveEntireCompany } from "../../../http/Company/company";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";


const GoodsAndServicesStepFive = forwardRef<IRegistrationStepPageComponent, unknown>((_props, ref) => {
  const { isLoading: isLoadingUnspscOptions, isFetched, data: unspscOptions = { families: [], segments: [] } } = useUnspcOptions();
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);
  const { isLoading: loadingUnspscSelection, data: previousSelection = [] } = useCompanyUnspscSelection()
  const [updatedSelection, setUpdatedSelection] = useState<number[]>([]);
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const [validationResults, setValidationResults] = useState<ValidationResponse>({} as ValidationResponse)
  const validationGoodAndService = useGoodsAndServiceValidation(updatedSelection, description);

  const descriptionCharLimit = 1000;
  const [companyData, setCompanyData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [isLoading, setIsLoading] = useState(true);
  const baseCompanyInfo = useBaseCompanyInfo();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const companyDetails = await GetCompanyDataById(baseCompanyInfo?.data?.companyId ?? 0, false);
      setCompanyData(companyDetails.data);
      setDescription(companyDetails.data.tradeDescription);
      await new Promise(resolve => setTimeout(resolve, 2000)); // eslint-disable-line no-promise-executor-return
      setIsLoading(false);
    };
    if (baseCompanyInfo?.data?.companyId)
      loadData();
    setUpdatedSelection(previousSelection)
  }, [])

  const onSubmitHandler = async () => {
    const validation = validationGoodAndService();
    setValidationResults(validation);
    const validationReponse = {
      submittedSuccessfully: validation.isInvalid === false,
      errorMessages: validation.validationErrors,
    }


    if (validationReponse.submittedSuccessfully && updatedSelection !== previousSelection) {
      await updateUnspscSelection(updatedSelection);
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyUnspscSelection] }); // forces a refetch of companyUnspscSelection 
    }

    const updateCompanyDate = { ...companyData, tradeDescription: description }
    await SaveEntireCompany(updateCompanyDate, false);
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    }
  };

  const onSaveAndContinueHandler = async () => {
    const updateCompanyDate = { ...companyData, tradeDescription: description }
    await SaveEntireCompany(updateCompanyDate, false);
    await updateUnspscSelection(updatedSelection);
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };

  };

  // Expose the methods and properties useImperativeHandle (they will be accessible form the ref)
  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));

  // array/object mapping
  const renderSelectedItemChips = () => {
    const selectedItems = unspscOptions.segments.map((seg) => {
      const segmentFamilies = seg.families.filter((x) => updatedSelection.includes(x.id));
      if (segmentFamilies.length > 0) {
        return { ...seg, families: segmentFamilies } as UnspscSegment;
      }
      return null;
    });

    return selectedItems
      .filter((x) => x != null)
      .map((item) => (
        <Grid xs={12}>
          <Box sx={{ width: "100%" }}>
            <Typography>
              <b>{item?.name}</b>
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}
          >
            {item?.families.map((f) => (
              <Chip
                sx={{ fontWeight: "bold" }}
                key={f.id}
                label={f.name}
                onDelete={() => setUpdatedSelection(prevState => prevState.filter((x) => x !== f.id))}
              />
            ))}
          </Box>
        </Grid>
      ));
  };

  const showSkeleton = isLoading || loadingUnspscSelection || isLoadingUnspscOptions;

  return (
    showSkeleton ? <RegistrationSkeleton /> :
      <Grid container xs={12} sx={{ marginLeft: "20px", marginTop: "20px" }}>
        {showEditCategoriesModal && (
          <Grid xs={12}>
            <GlobalProductServicesSelector
              companyUnspscSelection={updatedSelection || [] as Array<number>}
              handleClose={() => setShowEditCategoriesModal(false)}
              onSubmitSelectionCallback={(selection) => {
                setUpdatedSelection(selection);
              }}
            />
          </Grid>
        )}

        <Grid xs={12} className="profile-view-labels">
          SELECT YOUR COMPANY PRODUCT SEGMENTS
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            {isFetched && updatedSelection?.length > 0 && renderSelectedItemChips()}
            <Button
              variant="outlined"
              onClick={() => setShowEditCategoriesModal(true)}
            >
              SELECT PRODUCT/SERVICES
            </Button>
          </Box>
          {/* <TextField
          value={searchText}
          onChange={(e) => handleSearchTextChange(e.target.value)}
          placeholder="Search By Company, Product Or Service Description"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="icon-primaryActive" />
              </InputAdornment>
            ),
          }}
          className="text-search-border-bottom"
          sx={{ width: "100%" }}
        /> */}
        </Grid>
        <Grid item xs={12}>
          <div className="text-label-box">
            <span className="text-label-astrid">*</span>
            Describe your services that your company offers
          </div >
          <TextField multiline rows={6} sx={{ width: "97%" }}
            name="description"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            error={validationResults?.invalidFields?.includes("description")}
          />
          <Typography sx={{ float: "right", marginTop: "10px", marginRight: "45px" }}>Characters left <span className="primary-color"> {descriptionCharLimit - (description?.length ?? 0)}</span></Typography>
        </Grid>
      </Grid >
  );
});

export default GoodsAndServicesStepFive;
