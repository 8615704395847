import LoadingOverlay from "react-loading-overlay-ts"
import styles from "../Backdrop/backdrop.module.scss"
import blinkie from "../../util/images/go-blinkie-go.gif"

const MyLoader = ({ active, children }: { active: boolean, children: any }) => (
  <LoadingOverlay
    active={active}
    classNamePrefix="MyLoader_"
    spinner={<div id={styles.loader}> <img src={blinkie} alt="" id={styles.box} /></div>}>
    {children}
  </LoadingOverlay>
)

export default MyLoader;