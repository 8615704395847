import { protectedResources } from "../authConfig";
import { CompanyViewModel } from "./Company/Models/CompanyViewModel";
import makeGetRequestAsync, { makePUTRequestAsync, makePatchRequestAsync, makePostRequestAsync } from "./Helpers/httpRequestHelpers";
import { ArtefactModel } from "./Redux/Models/ArtefactsModel";
import { CompanyCategoryModel } from "./Redux/Models/CompanyCategoryModel";
import { CompanySupplierTypeContractingTier } from "./Redux/Models/ContractingTierModel";
import { SpecificTypeDictionaryEntryModel, WorkFlowModel } from "./Redux/Models/DictionaryEntryModel";
import { ErrorResponseModel } from "./Redux/Models/ErrorResponseModel";
import { OnBoardingRuleSetViewModel } from "./Redux/Models/OnBoardingRuleSetViewModel";
import { OnboardingConfigurationSetModel } from "./Redux/Models/OnboardingConfigurationSetModel";
import { OnboardingRequestModel } from "./Redux/Models/OnboardingRequestModel";
import { OnBoardingRulesViewModel } from "./Redux/Models/OnboardingRulesViewModel";
import { CompanySupplierTypePaymentTerms } from "./Redux/Models/PaymentTermsModel";


const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default {
  async getCompanyOnboardingRequests(companyId: number) {

    const url = `${baseUrl}/api/Company/OnboardingRequest/GetCompanyOnboardingRequests/${companyId}`;
    return (await makeGetRequestAsync<OnboardingRequestModel[]>(apiScopes, url)).data;
  },
  async getCompanyOnboardingRules(companyId: number) {

    const url = `${baseUrl}/api/Company/OnboardingRules?companyId=${companyId}`;
    return makeGetRequestAsync<OnBoardingRulesViewModel[]>(apiScopes, url);
  },
  async getCompanyOnboardingRuleSet(companyId: number, name: string) {

    const url = `${baseUrl}/api/Company/OnboardingRules/GetCompanyOnBoardingRuleSet?companyId=${companyId}&name=${name}`;
    return makeGetRequestAsync<OnBoardingRuleSetViewModel[]>(apiScopes, url);
  },
  async validate(company: CompanyViewModel, section: string) {

    const url = `${baseUrl}/api/Company/OnboardingRules/${section}`;
    return (await makePostRequestAsync(apiScopes, url, company)).data as ErrorResponseModel[];
  },
  async getOnboardingConfigurationSet(companyId: number) {

    const url = `${baseUrl}/api/Company/OnboardingRules/GetCompanyOnboardingConfigSet?companyId=${companyId}`;
    return (await makeGetRequestAsync<OnboardingConfigurationSetModel[]>(apiScopes, url)).data;
  },
  async getBusinessUnits() {

    const url = `${baseUrl}/api/Company/CompanySpecificTypeDictionary/GetCompanyBusinessUnits`;
    return (await makeGetRequestAsync<SpecificTypeDictionaryEntryModel[]>(apiScopes, url)).data;
  },
  async getCompanyCategoryManagers() {

    const url = `${baseUrl}/api/Company/CompanyUser/GetCompanyCategoryManagers`;
    return (await makeGetRequestAsync<CompanyCategoryModel[]>(apiScopes, url)).data;
  },
  async getSupplierTypes() {

    const url = `${baseUrl}/api/Company/CompanySpecificTypeDictionary/GetCompanySupplierTypes`;
    return (await makeGetRequestAsync<SpecificTypeDictionaryEntryModel[]>(apiScopes, url)).data;
  },
  async getContractingTiers() {

    const url = `${baseUrl}/api/Company/CompanySpecificTypeDictionary/GetCompanyContractingTiers`;
    return (await makeGetRequestAsync<SpecificTypeDictionaryEntryModel[]>(apiScopes, url)).data;
  },
  async getContractingTiersByType(supplierTypeId: number) {

    const url = `${baseUrl}/api/Company/CompanySpecificTypeContractingTier/GetCompanyContractingTiersForSupplierType/${supplierTypeId}`;
    return (await makeGetRequestAsync<CompanySupplierTypeContractingTier[]>(apiScopes, url)).data;
  },
  async getContractingAgreementsByType(contractingTierId: number) {

    const url = `${baseUrl}/api/Company/CompanySpecificTypeContractingTier/GetContractingTierContracts/${contractingTierId}`;
    return (await makeGetRequestAsync<SpecificTypeDictionaryEntryModel[]>(apiScopes, url)).data;
  },
  async getPaymentTypes(supplierTypeId: number) {

    const url = `${baseUrl}/api/Company/CompanySpecificTypePaymentTerms/GetCompanyPaymentTermsForSupplierType/${supplierTypeId}`;
    return (await makeGetRequestAsync<CompanySupplierTypePaymentTerms[]>(apiScopes, url)).data;
  },
  async getWorkflowApprovals(supplierTypeId: number) {

    const url = `${baseUrl}/api/OnboardingWorkflowApprovalSet/GetCompanyOnboardingWorkflowSets/${supplierTypeId}`;
    return (await makeGetRequestAsync<WorkFlowModel[]>(apiScopes, url)).data;
  },
  async addOnboardingRequest(request: OnboardingRequestModel) {

    const url = `${baseUrl}/api/Company/OnboardingRequest/AddNewOnboardingRequest`;
    return (await makePUTRequestAsync(apiScopes, url, request)).data;
  },
  async updateOnboardingRequest(request: OnboardingRequestModel) {

    const url = `${baseUrl}/api/Company/OnboardingRequest/UpdateOnboardingRequest`;
    return (await makePatchRequestAsync(apiScopes, url, request)).data;
  },
  async updateSubCategories(id: number, selection: Array<number>) {

    const url = `${baseUrl}/api/Company/OnboardingSupplierSubCategory/UpdateOnboardingSupplierSubCategories/${id}`;
    return (await makePatchRequestAsync(apiScopes, url, selection)).data;
  },
  async getArtefacts() {

    const url = `${baseUrl}/api/Company/OnboardingRules/Artefacts`;
    return (await makeGetRequestAsync<ArtefactModel[]>(apiScopes, url)).data;
  },

}