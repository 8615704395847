import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { getExclusionReasons } from "../../Company/CompanySpecificSupplierDetails";
import { ExclusionReasonModel } from "../../Company/Models/ExclusionReasonModel";
import exclusionReasonListSlice from "./ExclusionReasonListSlice";

export const ExclusionReasonListActions = exclusionReasonListSlice.actions;

export const fetchExclusionReasons = (staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<ExclusionReasonModel> = (await getExclusionReasons(staged));
  dispatch(ExclusionReasonListActions.setExclusionReasonListState(apiResponse));
}