import { BankAccountDetailsModel } from "../../../http/Company/Models/BankAccountDetailsModel";
import BankModelProps from "../../../pages/Registration/CustomComponents/Models/bankModelProps";

import useValidation from "./useValidation";

const useCompanyBankingValidation = (bankDetails: BankAccountDetailsModel, isForeign: boolean) => {

  // validation
  const [validateFields] = useValidation(
    [
      {
        displayName: "Bank Name",
        fieldName: isForeign ? BankModelProps.textBankName : BankModelProps.bankId,
        fieldValue: isForeign ? bankDetails?.textBankName : bankDetails?.bankId,
        required: true,
      },
      {
        displayName: "Branch Code",
        fieldName: BankModelProps.branchCode,
        fieldValue: bankDetails?.branchCode,
        required: true,
      },
      {
        displayName: "Account Number",
        fieldName: BankModelProps.accountNumber,
        fieldValue: bankDetails?.accountNumber,
        required: true,
      },
      {
        displayName: "Account Type",
        fieldName: isForeign ? BankModelProps.textAccountType : BankModelProps.accountTypeId,
        fieldValue: isForeign ? bankDetails?.textAccountType : bankDetails.accountTypeId,
        required: true,
      },
      {
        displayName: "Account Holder",
        fieldName: BankModelProps.accountName,
        fieldValue: bankDetails?.accountName,
        required: true,
      },
      {
        displayName: "Branch Name",
        fieldName: BankModelProps.branchName,
        fieldValue: bankDetails?.branchName,
        required: true,
      },
      {
        displayName: "SWIFT Code",
        fieldName: BankModelProps.swiftCode,
        fieldValue: bankDetails?.swiftCode,
        required: false,
      },
      {
        displayName: "Primary Bank Account",
        fieldName: BankModelProps.isPrimary,
        fieldValue: bankDetails?.isPrimary,
        required: true,
      },
      {
        displayName: "Bank Letter Date of Issue",
        fieldName: BankModelProps.dateOfIssue,
        fieldValue: bankDetails?.dateOfIssue,
        required: true,
      },
    ]);
  return validateFields

}

export default useCompanyBankingValidation;