/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum SectionVerificationStatus {
  Validated = 2,
  NotValidated = 3,
}

export default SectionVerificationStatus;

export const sectionValidationStatusToString = (value: SectionVerificationStatus) => {
  switch (value) {
    case SectionVerificationStatus.Validated:
      return "Validated"
    case SectionVerificationStatus.NotValidated:
      return "Not Validated"

    default:
      return "Unknown";
  }
};