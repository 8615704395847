import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { unspscOptionsSlice } from "../http/Redux/Store/unspscOptionsSlice";
import userReducer from "../http/Redux/Store/userSlice";
import companyReducer, {
  companyProfileSlice,
  companyProfileImageSlice,
} from "../http/Redux/Store/companySlice";
import validationSlice from "../http/Redux/Store/ValidationSlice";
import fieldSlice from "../http/Redux/Store/FieldSlice";
import permissionSlice from "../http/Redux/Store/PermissionSlice";
import baseCompanyInfoReducer from "../http/Redux/Store/baseCompanyInfoSlice";
import countryListReducer from "../http/Redux/Store/CountryListSlice";
import {
  userProfileSlice,
  profileImageSlice,
  userRoleSlice,
  searchHistorySlice,
} from "../http/Redux/Store/UserProfileSlice";
import companiesRequiringVerificationSlice from "../http/Redux/Store/CompaniesRequiringVerificationSlice";
import {
  companyVerificationSlice,
  companyRequestSlice,
  companyValidationConfigSlice,
  companyValidationRankingSlice,
} from "../http/Redux/Store/CompanyVerificationSlice";
import companiesCountSlice from "../http/Redux/Store/CompaniesCountSlice";
import documentTypeListSlice from "../http/Redux/Store/DocumentTypesSlice";
import {
  connectionRequestSlice,
  viewConnectionRequestSlice,
  viewConnectionRequestForCompanySlice,
} from "../http/Redux/Store/ConnectionRequestSlice";
import {
  currentActiveSubscriptionsSlice,
  hasSubscriptionsSlice,
  subscriptionFeaturePermissionsSlice,
  subscriptionFeatureSlice,
  subscriptionPlanSlice,
  subscriptionProductAddonsSlice,
  subscriptionProductFeaturesSlice,
  subscriptionProductPlansSlice,
  subscriptionProductSlice,
  subscriptionVoucherPlansSlice,
  subscriptionVoucherSlice,
} from "../http/Redux/Store/SubscriptionSlice";
import networkSlice from "../http/Redux/Store/NetworkSlice";
import connectSlice from "../http/Redux/Store/ConnectSlice";
import { packageMatrixSlice, userTourActivitySlice } from "../http/Redux/Store/UserTourActivitySlice";
import { userPromptActivitySlice } from "../http/Redux/Store/UserPromptActivitySlice";
import modulePermissionsSlice from "../http/Redux/Store/ModulePermissionSlice";
import companyBeeGroupSlice from "../http/Redux/Store/CompanyBeeGroupSlice";
import beeSectorListSlice from "../http/Redux/Store/BeeSectorListSlice";
import provinceListSlice from "../http/Redux/Store/ProvinceListSlice";
import bankAccountListSlice from "../http/Redux/Store/BankAccountTypeListSlice";
import bankNameListSlice from "../http/Redux/Store/BankNameListSlice";
import userCompanyListSlice from "../http/Redux/Store/UserCompanyListSlice";
import beeLevelListSlice from "../http/Redux/Store/BeeLevelListSlice";
import unspscBasicFamiliesListSlice from "../http/Redux/Store/unspscBasicFamiliesListSlice";
import unspscBasicSegmentsListSlice from "../http/Redux/Store/unspscBasicSegmentsListSlice";
import SearchBarSlice from "../http/Redux/Store/SearchBarSlice";

import supplierLocationsSlice from "../http/Redux/Store/DashboardSlice";
import networkSearchSlice from "../http/Redux/Store/NetworkSearchSlice";
import baseSubscriptionInfoSlice from "../http/Redux/Store/BaseSubscriptionInfoSlice";
import { userWorkCentreSlice } from "../http/Redux/Store/UserWorkCentreSlice";
import { beeRatingSourcesSlice, beeRatingStatusesSlice, beeRatingVerificationAgenciesSlice } from "../http/Redux/Store/BeeRatingsLookupSlice";
import exclusionReasonListSlice from "../http/Redux/Store/ExclusionReasonListSlice";
import { dataManagementPartnerSlice, dataManagementPartnerClientSlice } from "../http/Redux/Store/DataManagementPartnerSlice";
import CompanyWorkCentreHolderSlice from "../http/Redux/Store/CompanyWorkCentreHolderSlice";
import municipalityListSlice from "../http/Redux/Store/MunicipalitySlice";
import CompanyExtendedDetailSlice from "../http/Redux/Store/CompanyExtendedDetailsSlice";
import { BusinessUnitSlice, CompanyCategorySlice, ContractingTierSlice, 
  OnboardingCheckSlice, OnboardingConfigurationSetSlice, OnboardingRequestSlice, SupplierTypeSlice } from "../http/Redux/Store/OnBoardingSlice";
import CompanyShareholderSlice from "../http/Redux/Store/CompanyShareholdersSlice";
import { MessagingSlice, NotificationSlice } from "../http/Redux/Store/NotificationsSlice";

export const store = configureStore({
  reducer: {
    bankAccountTypeList: bankAccountListSlice.reducer,
    bankNameList: bankNameListSlice.reducer,
    baseCompanyInfo: baseCompanyInfoReducer,
    beeLevelList: beeLevelListSlice.reducer,
    beeSectorList: beeSectorListSlice.reducer,
    beeRatingStatusList: beeRatingStatusesSlice.reducer,
    beeRatingSourceList: beeRatingSourcesSlice.reducer,
    beeRatingVerificationAgencyList: beeRatingVerificationAgenciesSlice.reducer,
    companiesRequiringVerification: companiesRequiringVerificationSlice.reducer,
    company: companyReducer,
    companyBeeGroup: companyBeeGroupSlice.reducer,
    companyCount: companiesCountSlice.reducer,
    companyNetworkConnections: networkSlice.reducer,
    companyPotentialConnections: connectSlice.reducer,
    companyProfile: companyProfileSlice.reducer,
    companyProfileImage: companyProfileImageSlice.reducer,
    companyRequests: companyRequestSlice.reducer,
    companyValidationConfigs: companyValidationConfigSlice.reducer,
    companyValidationRankings: companyValidationRankingSlice.reducer,
    companyVerifications: companyVerificationSlice.reducer,
    connectionRequest: connectionRequestSlice.reducer,
    countryList: countryListReducer.reducer,
    municipalityList: municipalityListSlice.reducer,
    documentTypeList: documentTypeListSlice.reducer,
    exclusionReasonList: exclusionReasonListSlice.reducer,
    hasSubscriptions: hasSubscriptionsSlice.reducer,
    modulePermissions: modulePermissionsSlice.reducer,
    networkSearch: networkSearchSlice.reducer,
    permission: permissionSlice.reducer,
    profileImage: profileImageSlice.reducer,
    provinceList: provinceListSlice.reducer,
    searchBarText: SearchBarSlice.reducer,
    subscriptionFeaturePermissions: subscriptionFeaturePermissionsSlice.reducer,
    subscriptionFeatures: subscriptionFeatureSlice.reducer,
    subscriptionPlans: subscriptionPlanSlice.reducer,
    subscriptionProductAddons: subscriptionProductAddonsSlice.reducer,
    subscriptionProductFeatures: subscriptionProductFeaturesSlice.reducer,
    subscriptionProducts: subscriptionProductSlice.reducer,
    subscriptionVoucher: subscriptionVoucherSlice.reducer,
    subscriptionVoucherPlans: subscriptionVoucherPlansSlice.reducer,
    subscriptionproductPlans: subscriptionProductPlansSlice.reducer,
    baseSubscriptionInfo: baseSubscriptionInfoSlice.reducer,
    supplierLocationsList: supplierLocationsSlice.reducer,
    unspscBasicFamiliesList: unspscBasicFamiliesListSlice.reducer,
    unspscBasicSegmentsList: unspscBasicSegmentsListSlice.reducer,
    unspscOptions: unspscOptionsSlice.reducer,
    user: userReducer,
    userCompanyList: userCompanyListSlice.reducer,
    userProfile: userProfileSlice.reducer,
    userPromptActivity: userPromptActivitySlice.reducer,
    userRole: userRoleSlice.reducer,
    userTourActivity: userTourActivitySlice.reducer,
    validation: validationSlice.reducer,
    validationFields: fieldSlice.reducer,
    viewConnectionRequest: viewConnectionRequestSlice.reducer,
    viewConnectionRequestForCompany: viewConnectionRequestForCompanySlice.reducer,
    userWorkCentre: userWorkCentreSlice.reducer,
    currentActiveSubscription: currentActiveSubscriptionsSlice.reducer,
    searchHistory: searchHistorySlice.reducer,
    dataManagementPartner: dataManagementPartnerSlice.reducer,
    dataManagementPartnerClient: dataManagementPartnerClientSlice.reducer,
    companyWorkCentreHolder: CompanyWorkCentreHolderSlice.reducer,
    packageMatrix: packageMatrixSlice.reducer,
    onboardingCheck: OnboardingCheckSlice.reducer,
    onboardingConfigurationSet: OnboardingConfigurationSetSlice.reducer,
    companyExtendedDetails: CompanyExtendedDetailSlice.reducer,
    companyShareholders: CompanyShareholderSlice.reducer,
    businessUnit: BusinessUnitSlice.reducer,
    companyCategory: CompanyCategorySlice.reducer,
    supplierType: SupplierTypeSlice.reducer,
    contractingTier: ContractingTierSlice.reducer,
    onboardingRequest: OnboardingRequestSlice.reducer,
    notification: NotificationSlice.reducer,
    messaging: MessagingSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
