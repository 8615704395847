import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesCountModel } from "../Models/CompaniesCountModel";
import { RootState } from "../../../app/store";


const State: CompaniesCountModel = {
  numberOfCompanies: 0,
}

const CompaniesCountSlice = createSlice({
  name: "companyCount",
  initialState: State,
  reducers: {
    setParticularCompaniesCount(state, action: PayloadAction<CompaniesCountModel>) {
      state.numberOfCompanies = action.payload.numberOfCompanies; // eslint-disable-line no-param-reassign
    },
  },
})

export const getCompanyCount = (state: RootState) => state.companyCount.numberOfCompanies;
export default CompaniesCountSlice;