/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import SearchView from "../../components/Header/Search/SearchView";
import { ConnectionSearchModel } from "../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { PerformNetworkSearch } from "../../http/NetworkAndConnect/Netconn";
import NetconnSearchType from "../../util/enums/NetconnSearchType";
import SearchBar from "./SearchResult/Sections/SearchBar";
import Popup from "../Popups/Popup";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import ActivityStatus from "../../util/enums/ActivityStatus";


interface NetconnSearchViewProps {
  // eslint-disable-next-line react/require-default-props
  searchType?: NetconnSearchType;


}

const NetconnSearchView = ({ searchType }: NetconnSearchViewProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [popupOpen, setPopupOpen] = useState(false);
  const userPrompts: UserPromptActivityModel[] = useAppSelector(getUserPromptActivity);


  const executeSearch = async (
    searchModel: ConnectionSearchModel,
    typeOfSearch?: NetconnSearchType
  ) => {
    const results = await PerformNetworkSearch(searchModel, searchType ?? NetconnSearchType.None);
    return results.searchResults;
  };



  useEffect(() => {


    if (userPrompts.find(x => x.popupPromptId === UserPopupActivities.Network && x.status === ActivityStatus.Show)) {
      setPopupOpen(true);

    }
  }, [popupOpen, userPrompts.length]);

  return (
    <>
      <SearchBar searchQuery={setSearchText} preventSubmitOnEnter />
      <SearchView searchText={searchText} typeOfSearch={searchType} searchCallback={executeSearch} />
      {popupOpen && (
        <Popup page="Network" call={popupOpen} id={userPrompts.find(x => x.popupPromptId === UserPopupActivities.Network)?.id as number} />
      )}
    </>
  );
};

export default NetconnSearchView;
