
import React, { ChangeEvent, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import SweetAlert2 from "react-sweetalert2";
import "../Connect/SearchResult/SweetAlert.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import UserService from "../../http/UserProfileService";
import { useAppSelector } from "../../app/hooks";
import { getUserProfile } from "../../http/Redux/Store/UserProfileSlice";
import bufferProfile from "../../util/images/filler.png";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) => <Slide direction="up" ref={ref} {...props} />);


const EditProfileImage = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const userProfile: UserProfileModel = useAppSelector(getUserProfile);
  const [editable, setEditable] = useState<UserProfileModel>(userProfile || {} as UserProfileModel);
  const [file, setFile] = useState<FileList | null>(null);
  const [profileFileName, setProfileFileName] = useState("");
  const [status, setStatus] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState("");
  const [updatedImage, setUpdatedImage] = useState(0);
  const [updatedInfo, setUpdatedInfo] = useState(0);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const [show, setShow] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    setStatus(""); // Reset status
    event.preventDefault();
    const formData = new FormData();
    if (file === null || file === undefined || file.length === 0) return;
    formData.append("File", file[0]);
    formData.append("FileName", file[0].name);

    const loader = async () => {
      setShow(true);
      const imageResponse = await UserService.uploadImage(editable.accountId, formData, userProfile?.profileFileName || "Old");
      setUpdatedImage(imageResponse);
      const userResponse = await UserService.updateUser(editable);
      setUpdatedInfo(userResponse);
    };

    loader();
    setShowDrawer(false);
  };

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {

    setImage("");
    const fileSelected = event.target.files![0];
    if (fileSelected.size < 1024 * 1024) {
      setFile(event.target.files);
      setHasImage(true);
    }
    else {
      setSwalPropsChange({
        show: true,
        title: "File to big must be less then 1MB",
        icon: "error",
        timer: Number(3000),
        showCancelButton: false,
        showConfirmButton: false,

      });
    }



  }

  const handleClose = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    if (file === null || file === undefined || file.length === 0) return;
    setProfileFileName(file[0].name);
    setImage(URL.createObjectURL(file[0]));

    setEditable({
      ...editable,
      profileFileName,
      id: userProfile.id,
      name: userProfile.name,
      surname: userProfile.surname,
      mobile: userProfile.mobile,
      email: userProfile.email,
      accountId: userProfile.accountId,
      dateCreated: userProfile.dateCreated,
      dateModified: userProfile.dateModified,
      modifiedBy: userProfile.modifiedBy,
      createdBy: userProfile.createdBy,
      defaultCompany: userProfile.defaultCompany,
      notificationType: userProfile.notificationType,
      visibilityType: userProfile.visibilityType,
      accountStatus: userProfile.accountStatus,
      companyId: userProfile.companyId,
      isActive: userProfile.isActive,
    });



    if (updatedImage === 200 && updatedInfo === 200) {
      setShow(false);
      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }


    // eslint-disable-next-line max-len
  }, [show, editable.id, file?.length, profileFileName, userProfile.id, image.length, userProfile.name, userProfile.surname, userProfile.mobile, userProfile.email, userProfile.accountId, userProfile.dateCreated, userProfile.dateModified, userProfile.modifiedBy, userProfile.createdBy, userProfile.defaultCompany, userProfile.notificationType, userProfile.visibilityType, userProfile.accountStatus, userProfile.companyId, userProfile.isActive, updatedImage, updatedInfo]
  );



  return (


    <div>
      <LoadingBackdrop showBackdrop={show} />
      <div className="profile-section-profile-image-small-wrap">
        <Tooltip title="Edit User Avatar">
          <EditIcon className="profile-section-profile-image-small" id="step-profile" onClick={() => setShowDrawer(true)} />
        </Tooltip>
      </div>
      <Dialog
        open={showDrawer}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{ width: "500px", margin: "auto" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="heading-sub-3">
          Edit User Profile
          <div className="profile-image-edit-header-line" />
          <span className="small-note">* update button will only appear when you select a new image.</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="form-image-btn">
              <div className="form-image-wrap">
                <div className="form-image-search-btn-wrap">
                  <Tooltip title="Select Image">
                    <Button variant="outlined" component="label" sx={{ marginRight: 1 }} className="search-image-btn">
                      <ImageSearchIcon />
                      <input type="file" hidden onChange={(e) => handleSelectFile(e)} />
                    </Button>
                  </Tooltip>
                </div>
                <div className="form-image-search-text-wrap">
                  <TextField
                    value={profileFileName}
                    margin="dense"
                    id="name"
                    aria-readonly
                    disabled
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    label="Selected Image (png & jpg)"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined" />
                </div>
              </div>
            </div>
            <div className="form-upload-image">
              <img src={image?.length !== 0 ? image : bufferProfile} alt="" className="preview-image" />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={hasImage ? "active" : "not-active"}>
            <Button type="submit" onClick={handleSubmit} variant="contained" className="form-add-btn-img">
              Update Profile
            </Button>
            {status ? <h1>{status}</h1> : null}
          </div>
        </DialogActions>
      </Dialog>
      <SweetAlert2 {...swalPropsChange} />
    </div>

  )
};

export default EditProfileImage;