import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "../http/Helpers/httpRequestHelpers";
import { UserJourneyQuestionsModel } from "./Redux/Models/UserJourneyQuestionsModel";
import { UserTourActivityModel } from "./Redux/Models/UserTourActivityModel";



const apiScopes = protectedResources.userManagementWebApi.scopes;
const baseUrl = protectedResources.userManagementWebApi.endpoint;

export default {

  async getAllUserTourActivity(accountId: string) {

    const url = `${baseUrl}/api/UserManagement/UserTourActivity/${accountId}`;
    return (await makeGetRequestAsync<UserTourActivityModel[]>(apiScopes, url)).data;
  },
  async updateUserTourActivity(userTourActivity: UserTourActivityModel) {
    const url = `${baseUrl}/api/UserManagement/UserTourActivity`;
    return (await (makePatchRequestAsync(apiScopes, url, userTourActivity))).status;
  },
  async addUserTourActivity(userTourActivity: UserTourActivityModel) {
    const url = `${baseUrl}/api/UserManagement/UserTourActivity`;
    return (await (makePostRequestAsync(apiScopes, url, userTourActivity))).data;
  },
  async getAllUserJourneyQuestions() {

    const url = `${baseUrl}/api/UserManagement/UserPromptResponse/Questions`;
    return (await makeGetRequestAsync<UserJourneyQuestionsModel[]>(apiScopes, url)).data;
  },
}