import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetAllTurnoverBands } from "../../../http/Company/beeDetailsApi";

const useBeeTurnoverBands = () => {
  const query = useQuery({
    queryKey: [queryKeys.beeTurnoverBands],
    queryFn: async () => (await GetAllTurnoverBands()).data,
    staleTime: 600000, // 10 minutes
  })

  return query;
}

export default useBeeTurnoverBands;