/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { MunicipalityModel } from "../Models/MunicipalityModel";

export interface MunicipalityListState {
  countryList: Array<MunicipalityModel>;
}

const municipalityListSlice = createSlice({
  name: "municipalityList",
  initialState: [] as Array<MunicipalityModel>,
  reducers: {
    setMunicipalityListState: (
      state,
      action: PayloadAction<Array<MunicipalityModel>>
    ) => action.payload,
  },
});

export const setMunicipalityListState = (state: RootState) => state.municipalityList;
export default municipalityListSlice;
