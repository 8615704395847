import React, { useEffect, useRef, useState } from "react";
import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TextField,
  Grid,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContactModel } from "../../../../http/Company/Models/ContactModel";
import "../CompanyPost.css";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import ContactType from "../../../../util/enums/ContactType";
import { useAppDispatch } from "../../../../app/hooks";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyContactState } from "../../../../http/Redux/Store/companySlice";

const CompanyPrimaryContactPost = ({
  headerText = "",
  contactData,
  SetDirtyCallback,
  OnContactSave,
}: {
  headerText: string;
  contactData: ContactModel;

  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  OnContactSave: (contactDetails: ContactModel) => void;
}) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);
  const [contact, setContact] = useState<ContactModel>({} as ContactModel);
  const [contactComparison, setContactComparison] = useState<ContactModel>({} as ContactModel);

  const initialLoadCheck = useRef(true);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [validateField] = useFieldValidation("companyProfile");

  const debouncedContactValue = useInputDebounce(contact);

  // Validation Error State variables
  const [contactNameInvalid, setContactNameInvalid] = useState<boolean>(false);
  const [contactDesignationInvalid, setContactDesignationInvalid] = useState<boolean>(false);
  const [contactMobileInvalid, setContactMobileInvalid] = useState<boolean>(false);
  const [contactEmailInvalid, setContactEmailInvalid] = useState<boolean>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CancelEdit = () => {
    setEdit(false);
    setContact(contactComparison);
    SetDirtyCallback(false);
  };

  const EnableEdit = () => {
    if (edit) {
      OnContactSave(contact);
      SetDirtyCallback(false);
      setContactComparison(contact);
      setEdit(false);
      return;
    }

    setEdit(!edit);
  };

  const performDirtyCheck = (checkVal: ContactModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(contactComparison)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...contact, [propName]: event.target.value };
    setContact(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      const contactProp = { ...contactData };
      contactProp.contactTypeId = ContactType.Primary;
      setContact(contactProp);
      setContactComparison(JSON.parse(JSON.stringify(contactProp)));
      initialLoadCheck.current = false;
    }

  }, [contactData.id, contact.designation]);

  useEffect(() => {
    if (initialLoadCheck.current) {
      const contactProp = { ...contactData };
      contactProp.contactTypeId = ContactType.Primary;
      setContact(contactProp);
      setContactComparison(JSON.parse(JSON.stringify(contactProp)));
      initialLoadCheck.current = false;
    }
  }, [contactData]);

  useEffect(() => {
    dispatch(setCompanyContactState(debouncedContactValue));
  }, [debouncedContactValue]);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      className="Accord-Hide"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="Accord-heading"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>

      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.editSave}>
          <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
            {!edit && (
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  icon={faPencilSquare}
                  aria-hidden="true"
                  id="edit-general-details"
                  onClick={EnableEdit}
                  className="hover-cursor"
                />
              </Tooltip>
            )}
            {edit && (
              <div className={styles.btnBox}>
                <div className={styles.btnBoxLeft}>
                  <Tooltip title="Save">
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      aria-hidden="true"
                      id="edit-save-general"
                      onClick={EnableEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
                <div className={styles.btnBoxRight}>
                  <Tooltip title="Cancel Edit">
                    <FontAwesomeIcon
                      icon={faCancel}
                      aria-hidden="true"
                      id="edit-cancel-general"
                      onClick={CancelEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </WithRequiredPermission>
        </div>

        <div className={styles.postBody}>
          <div className={styles.postInfo}>
            <Grid container className="g-container">
              <Grid item xs={6}>
                <TextField
                  type="text"
                  sx={{ width: "98%", marginRight: "2%" }}
                  disabled={!edit}
                  id="primary-contact-name"
                  label="Contact Name"
                  error={contactNameInvalid}
                  helperText={
                    contactNameInvalid &&
                    validateField("Contact Name", contact?.name ?? "").validationErrorText
                  }
                  value={contact?.name || ""}
                  size="small"
                  onChange={(ev) => handleValueChange(ev, "name")}
                  onBlur={() => {
                    const invalidField = validateField(
                      "Contact Name",
                      contact?.name ?? ""
                    ).isInvalid;

                    if (invalidField) {
                      setContactNameInvalid(invalidField);
                      return;
                    }

                    setContactNameInvalid(false);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  <TextField
                    id="contact-designation"
                    disabled={!edit}
                    label="Designation"
                    sx={{ width: "98%", marginRight: "2%" }}
                    size="small"
                    fullWidth
                    value={contact?.designation || ""}
                    error={contactDesignationInvalid}
                    helperText={
                      contactDesignationInvalid &&
                      validateField("Contact Designation", contact?.designation ?? "")
                        .validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "designation")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Contact Designation",
                        contact?.designation ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactDesignationInvalid(invalidField);
                        return;
                      }

                      setContactDesignationInvalid(invalidField);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className="g-container">
              <Grid item xs={6}>
                <TextField
                  type="text"
                  sx={{ width: "98%", marginRight: "2%" }}
                  disabled={!edit}
                  id="primary-contact-mobile"
                  label="Contact Number"
                  value={contact?.mobile || ""}
                  size="small"
                  error={contactMobileInvalid}
                  helperText={
                    contactMobileInvalid &&
                    validateField("Mobile", contact?.mobile ?? "").validationErrorText
                  }
                  onChange={(ev) => handleValueChange(ev, "mobile")}
                  onBlur={() => {
                    const invalidField = validateField("Mobile", contact?.mobile ?? "").isInvalid;

                    if (invalidField) {
                      setContactMobileInvalid(invalidField);
                      return;
                    }

                    setContactMobileInvalid(invalidField);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="text"
                  disabled={!edit}
                  id="primary-contact-email"
                  label="Email"
                  sx={{ width: "98%", marginRight: "2%" }}
                  value={contact?.email || ""}
                  size="small"
                  error={contactEmailInvalid}
                  helperText={
                    contactEmailInvalid &&
                    validateField("Email", contact?.email ?? "").validationErrorText
                  }
                  onChange={(ev) => handleValueChange(ev, "email")}
                  onBlur={() => {
                    const invalidField = validateField("Email", contact?.email ?? "").isInvalid;

                    if (invalidField) {
                      setContactEmailInvalid(invalidField);
                      return;
                    }

                    setContactEmailInvalid(invalidField);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyPrimaryContactPost;
