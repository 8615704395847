// eslint-disable-next-line no-shadow
enum PopupTypes { // eslint-disable-line no-unused-vars
  Profile = 12,// eslint-disable-line no-unused-vars
  Network = 13,// eslint-disable-line no-unused-vars
  Subscriptions = 11,// eslint-disable-line no-unused-vars
}

export default PopupTypes;

export const typesToString = (value: PopupTypes) => {
  switch (value) {
    case PopupTypes.Profile:
      return "Profile"
    case PopupTypes.Network:
      return "Network"
    default:
      return "Unknown";
  }
};

export const stringToType = (value: string) => {
  switch (value) {
    case "Profile":
      return PopupTypes.Profile
    case "Network":
      return PopupTypes.Network
    case "Subscriptions":
      return PopupTypes.Subscriptions
    default:
      return 0;
  }
};


