/* eslint-disable react/require-default-props */
import { CircularProgress, Divider, Grid, Typography } from "@mui/material"
import { MouseEvent } from "react";
import { colorPrimaryActive } from "../../../util/MUI_Theme/Theme";

interface ButtonProps {
  type?: "primary" | "secondary" | "outlined"
  align: "left" | "right",
  label: string,
  disabled?: boolean,
  hidden?: boolean,
  isLoading?: boolean
  widthInPx?: number,
  // eslint-disable-next-line no-unused-vars
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void,
}

interface ButtonGroupProps {
  px?: number,
  buttons?: ButtonProps[],
  buttonSpacing?: number,
}


const ButtonGroup = ({ buttons, px, buttonSpacing }: ButtonGroupProps) => {

  const getButtonClassName = (type?: "primary" | "secondary" | "outlined") =>
    type != null ? `btn-custom-${type}` : "btn-custom-primary";

  const generateButton = (props: ButtonProps) => (
    <Grid item key={props.label}>
      <button
        style={{ minWidth: "200px", width: props.widthInPx }}
        type="button"
        hidden={props.hidden}
        disabled={props.disabled}
        className={getButtonClassName(props.type)}
        onClick={props.onClick}
      >
        {
          props.isLoading &&
          <>
            <CircularProgress size="15px" color={props.type === "primary" ? "secondary" : "primary"} />
            {"   "}
          </>
        }
        <Typography variant="button">
          {props.label}
        </Typography>
      </button>
    </Grid>
  )
  return (
    <>
      <Divider sx={{ borderColor: colorPrimaryActive, borderBottomWidth: "3px" }} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        rowGap={buttonSpacing}
        alignItems="center"
        sx={{
          px: px ?? 4,
          pt: "-4px",
          mt: "-3px",
          pb: 6,
        }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {
              buttons?.filter(x => x.align === "left").map(x => (
                generateButton(x)
              ))
            }
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {
              buttons?.filter(x => x.align === "right").map(x => (
                generateButton(x)
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default ButtonGroup;