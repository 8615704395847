import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makeDeleteRequestNoBodyAsync, makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { CompanyShareholdersModel, CompanyShareholdersSaveModel } from "../Redux/Models/CompanyShareholders";


const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default {
  async getCompanyShareholdersModel(companyId: number, staged: boolean) {

    const url = `${baseUrl}/api/Company/GetCompanyShareholders/${companyId}/${staged}`;
    return (await makeGetRequestAsync<CompanyShareholdersModel[]>(apiScopes, url)).data;
  },
  async update(company: CompanyShareholdersSaveModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/UpdateCompanyShareholders/${staged}`;
    return (await (makePatchRequestAsync(apiScopes, url, company))).status;
  },
  async create(company: CompanyShareholdersSaveModel, staged: boolean) {
    const url = `${baseUrl}/api/Company/AddCompanyShareholders/${staged}`;
    return (await (makePostRequestAsync(apiScopes, url, company))).status;
  },
  async delete(companyId: number, companyShareholderId: number, staged: boolean) {
    const url = `${baseUrl}/api/Company/DeleteCompanyShareholders/${companyId}/${companyShareholderId}/${staged}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
}