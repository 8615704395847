/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BankAccountTypeModel } from "../../Company/Models/BankAccountTypeModel";



export interface BankAccountTypeListState {
  bankAccountTypeList: Array<BankAccountTypeModel>;
}

const bankAccountTypeListSlice = createSlice({
  name: "bankAccountTypeList",
  initialState: [] as Array<BankAccountTypeModel>,
  reducers: {
    setbankAccountTypeListState: (
      state,
      action: PayloadAction<Array<BankAccountTypeModel>>
    ) => action.payload,
  },
});

export const selectbankAccountTypeList = (state: RootState) => state.bankAccountTypeList;
export default bankAccountTypeListSlice;
