// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import {
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuProps,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
  // TextField,
} from "@mui/material";
import FilterIcon from "@material-ui/icons/FilterList";
import SweetAlert2 from "react-sweetalert2";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import { SearchModel } from "../../../http/NetworkAndConnect/Models/SearchModel";
import ConnectionRow from "./ConnectionRow";
import SendNewMessage from "../../../components/Chat/SendNewMessage";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { CompanyNetworkConnections } from "../../../http/NetworkAndConnect/Models/CompanyNetworkConnections";
import { ConnectionModel } from "../../../http/Connect/Models/ConnectionModel";

interface SearchTableProps {
  tableSearchType: NetconnSearchType;
  menuProps: Partial<MenuProps> | undefined;
  totalRecordCount: number;
  dataSet: Array<SearchModel>;
  currentPage: number;
  numberOfRows: number;
  numberOfPages: number;
  selectedRows: Array<number>;
  // eslint-disable-next-line no-unused-vars
  changeMainTableSort: (sortDirection: string) => void;
  // eslint-disable-next-line no-unused-vars
  changeCurrentPage: (pageNumber: number) => void;
  // eslint-disable-next-line no-unused-vars
  changePageRowNumber: (rowNumber: number) => void;
  // eslint-disable-next-line no-unused-vars
  selectSpecificRow: (companyId: number | null) => void;
  // eslint-disable-next-line no-unused-vars
  selectAllRows: (allCompanyIds: Array<number>) => void;
  // eslint-disable-next-line no-unused-vars
  navigateToProfile: (companyId: number) => void;
  networkConnections: CompanyNetworkConnections;
}

const searchTableViewNames = ["Detailed View", "Simple View"];
const searchTableActionNames = ["Start a conversation"];
const searchTableSortOrders = ["A-Z", "Z-A"];

const SearchTable = ({
  tableSearchType,
  menuProps,
  totalRecordCount,
  dataSet,
  currentPage,
  numberOfPages,
  numberOfRows,
  selectedRows,
  changeMainTableSort,
  changeCurrentPage,
  changePageRowNumber,
  navigateToProfile,
  networkConnections,
}: SearchTableProps) => {
  const [tableViewMode, setTableViewMode] = useState<string>(searchTableViewNames[1]);
  const [selectedTableAction, setSelectedTableAction] = useState<string>("");
  const [selectedTableSortOrder, setSelectedTableSortOrder] = useState<string>(searchTableSortOrders[0])
  const [showSendMessageModel, setShowSendMessageModel] = useState<boolean>(false);
  const [closeActions, setCloseActions] = useState<boolean>(false);

  // JANK-O-CLOCK!
  const [companyRecordForMessaging, setCompanyRecordForMessaging] = useState<SearchModel>({} as SearchModel);
  const [swalPropsChange, setSwalPropsChange] = useState({});

  const positionInSearchResults = currentPage === numberOfPages ? totalRecordCount : currentPage * numberOfRows;

  // ----- Table-level functions -----
  const handleViewModeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setTableViewMode(value);
  };

  const handleMainTableActionBtnClick = (selectedCompanyIds: Array<number>) => {
    if (selectedCompanyIds.length === 0) return;

    if (selectedCompanyIds[0] === 0) return;

    // This is here as a placeholder.
    console.log(selectedCompanyIds);
  };

  const handleTableActionSelectionChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedTableAction(value);
  };

  const handleTableSortOrderChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedTableSortOrder(value);
  }

  // ----- Table-level functions -----

  const handleRecordActionBtnClick = (companyId: number | null) => {
    if (!companyId) {
      return;
    }

    navigateToProfile(companyId);
  };

  const handleSendMessageClick = (companyToMessage: SearchModel) => {
    setCompanyRecordForMessaging(companyToMessage);
    setShowSendMessageModel(!showSendMessageModel);
  }

  const handleCloseMessageModel = () => {
    setShowSendMessageModel(false);
  }

  const handleToastMessageSent = () => {
    setCloseActions(true);
  }

  const handleCloseAction = () => {
    setCloseActions(false);
    setSwalPropsChange({
      show: true,
      title: "Message Sent",
      icon: "success",
      timer: Number(2000),
      showCancelButton: false,
      showConfirmButton: false,

    });

  }

  const networkConnectionStatus = (connectionModel: ConnectionModel[], companyId: number) => {
    if (connectionModel) {
      return connectionModel.findIndex(s => s.companyId === companyId) !== -1;
    }
    return false

  }

  return (
    <>
      <Grid container sx={{ marginLeft: 2, paddingY: 2 }}>
        <Grid item xs={9}>
          <Typography variant="caption">
            Total Records {positionInSearchResults} of {totalRecordCount}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">
            Showing {positionInSearchResults} of {totalRecordCount} results
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <select
            className="textfield-rounded"
            value={numberOfRows}
            onChange={(ev) => changePageRowNumber(Number(ev.target.value))}
          >
            {[5, 10, 50, 100].map(x =>
              <option value={x}>
                <Typography variant="caption">
                  {x}
                </Typography>
              </option>)
            }
          </select>
        </Grid>
      </Grid>
      <Grid item className="table-box-my-connection-filters">
        <Card>
          <Grid container>
            <Grid item xs={4}>
              <div style={{ width: "80%", margin: "auto", paddingBottom: 15 }}>
                <Select
                  id="tableViewModeDropdown"
                  displayEmpty
                  value={tableViewMode}
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={handleViewModeChange}
                  className="select-no-border"
                  renderValue={(selected) => selected}
                  MenuProps={menuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {searchTableViewNames.map((viewName) => (
                    <MenuItem key={viewName} value={viewName}>
                      <Checkbox checked={viewName.indexOf(tableViewMode) > -1} />
                      <ListItemText primary={viewName} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ width: "80%", margin: "auto", paddingBottom: 15 }}>
                <Select
                  id="tableAvailableActionsDropdown"
                  displayEmpty
                  value={selectedTableAction || ""}
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={(ev) => {
                    handleTableActionSelectionChange(ev);
                    handleMainTableActionBtnClick(selectedRows);
                  }}
                  className="select-no-border"
                  MenuProps={menuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>Available Actions</MenuItem>
                  {searchTableActionNames.map((actionName) => (
                    <MenuItem key={actionName} value={actionName}>
                      <ListItemText primary={actionName} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ width: "80%", margin: "auto", paddingBottom: 15 }}>
                <Select
                  id="tableSortOrderDropdown"
                  displayEmpty
                  value={selectedTableSortOrder}
                  variant="standard"
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterIcon className="icon-primaryActive" />
                    </InputAdornment>
                  }
                  sx={{ width: "100%" }}
                  onChange={(ev) => {
                    handleTableSortOrderChange(ev);
                    changeMainTableSort(ev.target.value);
                  }}
                  className="select-no-border"
                  renderValue={(selected) => selected}
                  MenuProps={menuProps}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                >
                  {searchTableSortOrders.map((sortOrder) => (
                    <MenuItem key={sortOrder} value={sortOrder}>
                      <Checkbox checked={sortOrder.indexOf(selectedTableSortOrder) > -1} />
                      <ListItemText primary={sortOrder} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className="table-box-mp">
        <Card sx={{ padding: 2 }}>
          <Grid container>
            <Grid xs={2}>
              <b>Registered Name</b>
            </Grid>
            <Grid xs={2}>
              <b>Trading Name</b>
            </Grid>
            <Grid xs={2}>
              <b>Reg No.</b>
            </Grid>
            <Grid xs={2}>
              <b>B-BBEE Status</b>
            </Grid>
            <Grid xs={2}>
              <b>Rating</b>
            </Grid>
            <Grid xs={2}>
              <b>Actions</b>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {dataSet.map((searchResultRecord) => (
        <ConnectionRow
          record={searchResultRecord}
          searchType={tableSearchType}
          handleCloseAction={handleCloseAction}
          viewMode={tableViewMode}
          closeActions={closeActions}
          handleRecordDetailsClick={handleRecordActionBtnClick}
          handleMessagingBtnClick={handleSendMessageClick}
          isSupplier={networkConnectionStatus(networkConnections.suppliers, searchResultRecord.companyId)}
          isBuyer={networkConnectionStatus(networkConnections.clients, searchResultRecord.companyId)}
        />
      ))}
      <br />
      <Pagination
        sx={{ margin: "auto", paddingTop: 2 }}
        className="pagination"
        count={numberOfPages}
        page={currentPage}
        onChange={(ev, value) => changeCurrentPage(value)}
        variant="text"
        color="primary"
      />

      <SendNewMessage
        open={showSendMessageModel}
        handleClose={handleCloseMessageModel}
        handleRefresh={handleToastMessageSent}
        companyId={companyRecordForMessaging.companyId as number}
        companyName={companyRecordForMessaging.registeredName as string}
      />
      <SweetAlert2 {...swalPropsChange} />

    </>
  );
};

export default SearchTable;
