import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { BankAccountDetailsModel } from "../../../../http/Company/Models/BankAccountDetailsModel";
import BankAccount from "./BankAccount";
import "./BI.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCompanyBankAccountState } from "../../../../http/Redux/Store/companySlice";
import VerificationSection from "../../../WorkCenter/Verification/VerificationSection";
import { CompanyVerificationModel } from "../../../../http/Redux/Models/CompanyVerificationModel";

import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import formatDate from "../../../../util/formatter/formatDate";

const CompanyBankingDetailsPost = ({
  headerText = "",
  bankAccounts,
  workcentreAgentPath,
  SetDirtyCallback,
  OnBankSave,
  OnBankDelete,
  VerificationData,
  staged,
  agentTaskId = 0,
}: {
  headerText: string;
  bankAccounts: Array<BankAccountDetailsModel>;
  workcentreAgentPath: boolean;
  staged: boolean;
  /* eslint-disable no-unused-vars */
  SetDirtyCallback: (isDirty: boolean) => void;
  OnBankSave: (bankAccountToUpdate: BankAccountDetailsModel) => void;
  OnBankDelete: (bankAccountToDelete: BankAccountDetailsModel, isStaged: boolean) => Promise<void>;
  /* eslint-disable no-unused-vars */

  VerificationData: CompanyVerificationModel;
  // eslint-disable-next-line react/require-default-props
  agentTaskId?: number;
}) => {
  const [bankAccountControls, setBankAccountControls] = useState<Array<React.ReactNode>>([]);
  const [bankNameWarning, setBankNameWarning] = useState<boolean>(false);
  const companyName = useAppSelector(x => x.company.registeredName);
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.baseCompanyInfo.companyId);

  const checkFirstBankAccountName = () => {
    if (!bankAccounts || bankAccounts.length === 0) return;

    const firstAccount = bankAccounts[0];

    if (firstAccount.accountName !== companyName) {
      Swal.fire({
        title: "Warning",
        text: "The first bank account name differs from your company name.",
        showCancelButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "Continue",
        icon: "warning",
      });
    }
    setBankNameWarning(true);
  }

  const AddBankAccount = (bankAccountName?: string) => {
    const pendingCheck = bankAccounts.find((account) => account.id === 0);

    if (pendingCheck) {
      Swal.fire({
        title: "Warning",
        text: "Only one bank account record can be processed at a time.",
        showCancelButton: false,
        icon: "warning",
      });
      return;
    }

    const initialObject: BankAccountDetailsModel = {
      id: 0,
      bankId: 0,
      accountTypeId: 0,
      accountName: bankAccountName || "",
      accountNumber: "",
      branchName: "",
      branchCode: null,
      isDeleted: false,
      isPrimary: false,
      companyId: 0,
      createdBy: "00000000-0000-0000-0000-000000000000",
      dateCreated: formatDate(new Date()),
      modifiedBy: "00000000-0000-0000-0000-000000000000",
      dateModified: formatDate(new Date()),
    };

    setBankAccountControls([
      ...bankAccountControls,
      <BankAccount
        bankAccountDetails={initialObject}
        SetDirtyCallback={SetDirtyCallback}
        OnBankSave={OnBankSave}
        OnBankDelete={OnBankDelete}
      />,
    ]);

    dispatch(setCompanyBankAccountState(initialObject));
  };

  useEffect(() => {
    if (!bankAccounts || bankAccounts.length === 0)
      AddBankAccount(companyName); // Add a "default" bank form.

    const controls: Array<React.ReactNode> = bankAccounts.map((sc) => (
      <BankAccount
        key={sc.id}
        bankAccountDetails={sc}
        SetDirtyCallback={SetDirtyCallback}
        OnBankSave={OnBankSave}
        OnBankDelete={OnBankDelete}
      />
    ));

    setBankAccountControls(controls);

    if (!bankNameWarning)
      checkFirstBankAccountName();

  }, [bankAccounts.length]);

  return (
    <Accordion expanded className="Accord-Hide" id="step-bank">
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className="Accord-heading">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          <h3 className="heading-sub-3">{headerText}</h3>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {VerificationData && workcentreAgentPath && (
          <div className="post__header_top">
            <VerificationSection
              section="BankingDetails"
              agentTaskId={agentTaskId}
              companyId={companyId}
              staged={staged}
            />
          </div>
        )}

        <div className={styles.postBody}>
          <div>{bankAccountControls}</div>
          <div id="add-box">
            <WithRequiredPermission permission={PermissionClassification.EditBankingDetails}>
              <Button onClick={() => AddBankAccount()} variant="contained" className={styles.btn}>
                <AddCircleIcon className={styles.btnIcon} /> Add Bank Account
              </Button>
            </WithRequiredPermission>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyBankingDetailsPost;
