/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BasicUnspscSegmentModel } from "../Models/BasicUnspscSegmentModel";


export interface UnspscBasicSegmentsListListState {
  unspscBasicSegmentsList: Array<BasicUnspscSegmentModel>;
}

const unspscBasicSegmentsListSlice = createSlice({
  name: "unspscBasicSegmentsList",
  initialState: [] as Array<BasicUnspscSegmentModel>,
  reducers: {
    setunspscBasicSegmentsListState: (
      state,
      action: PayloadAction<Array<BasicUnspscSegmentModel>>
    ) => action.payload,
  },
});

export const selectunspscBasicSegmentsListList = (state: RootState) => state.unspscBasicSegmentsList;
export default unspscBasicSegmentsListSlice;
