import { useAppSelector } from "../../app/hooks";
import { isDevelopmentEnviroment } from "../../authConfig";
import PermissionClassification from "../enums/PermissionClassification";

const allowFullAccess = isDevelopmentEnviroment;
// Specific hook for subscription permission checks
const useSubscriptionPermissionCheck = () => {
  const allowedPermissions = useAppSelector((state) => state.user.allowedPermissions); // get allowed permissions
  const subscriptionPermissionIdList = useAppSelector(
    (state) => state.baseCompanyInfo.subscriptionPermissionsList
  ); // fetch the subscription permissions

  // This is a problem. If the user's allowed perms don't include compliance for whatever reason, this won't work too well.
  // Permissions (and potentially all other user tables and User Management's DB reference) may need to move to the Config DB.
  const subPermissionClassficationList = allowedPermissions.map((ap) =>
    subscriptionPermissionIdList.includes(ap.id) ? ap.classification : 0
  );

  const handleHasPermissionCheckFunction = (permissions: PermissionClassification[]) =>
    allowFullAccess ||
    subPermissionClassficationList?.some((sp) => permissions.includes(sp)); // subscription has the permission
  return handleHasPermissionCheckFunction;
};

export default useSubscriptionPermissionCheck;
