import { useMutation, useQueryClient } from "@tanstack/react-query"
import queryKeys from "../queries/queryKeys";
import { AddOrUpdateCompanyTaxInformation, DeleteCompanyTaxInformation } from "../../../http/Company/CompanyTaxInformation";
import { ComplianceMutatorProps } from "./Interfaces/ComplianceMutatorProps";
import { TaxInformation } from "../../../http/Company/Models/CompanyTaxInformation";

const useCompanyTaxDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({mutationMode, mutationDetails} : ComplianceMutatorProps) => {
      const taxObject = mutationDetails as TaxInformation;

      if (mutationMode === "add" || mutationMode === "update") {
        await AddOrUpdateCompanyTaxInformation(taxObject);
        return;
      }

      await DeleteCompanyTaxInformation(taxObject.companyId);
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyTaxInformation] });

      // Snapshot the previous value
      const currentCompanyTaxInformation = queryClient.getQueryData([queryKeys.companyTaxInformation]);

      // Return a context object with the snapshotted value
      return { currentCompanyTaxInformation }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyTaxInformation], context?.currentCompanyTaxInformation);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyTaxInformation] });
    },
  })
}

export default useCompanyTaxDetailsMutation;