import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { BankAccountDetailsModel } from "./Models/BankAccountDetailsModel";
import { BankAccountTypeModel } from "./Models/BankAccountTypeModel";
import { BankNameModel } from "./Models/BankNameModel";
import { Bank } from "./Models/Bank";

const baseUrl = protectedResources.companyApi.endpoint;
const apiScopes = protectedResources.companyApi.scopes;

export async function GetBankNames() {
  const url = `${baseUrl}/api/Company/Banking/GetBankNames`;
  const response = await (await makeGetRequestAsync<Array<BankNameModel>>(apiScopes, url)).data;
  return response;
}

export async function GetBanks() {
  const url = `${baseUrl}/api/Company/Banking/GetBanks`;
  const response = await (await makeGetRequestAsync<Array<Bank>>(apiScopes, url)).data;
  return response;
}

export async function GetBankAccountTypes() {
  const url = `${baseUrl}/api/Company/Banking/GetBankAccountTypes`;
  const response = await (await makeGetRequestAsync<Array<BankAccountTypeModel>>(apiScopes, url)).data;
  return response;
}

export async function GetCompanyBankAccounts(companyId: number, staged: boolean) {
  const url = `${baseUrl}/api/Company/Banking/GetBankingDetailsForCompany/${companyId}/${staged}`;
  const response = (await makeGetRequestAsync<Array<BankAccountDetailsModel>>(apiScopes, url)).data;
  return response;
}

export async function AddNewBankAccount(bankAccountToAdd: BankAccountDetailsModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Banking/AddBankingDetails/${staged}`;
  const response = await (await makePostRequestAsync<number, BankAccountDetailsModel>(apiScopes, url, bankAccountToAdd));
  return response;
}

export async function UpdateBankAccount(bankAccountToUpdate: BankAccountDetailsModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Banking/UpdateBankingDetails/${staged}`;
  const response = await (await makePostRequestAsync(apiScopes, url, bankAccountToUpdate)).status;
  return response;
}

export async function DeleteBankAccount(bankAccountToDelete: BankAccountDetailsModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Banking/DeleteBankingDetails/${staged}`;
  const response = await (await makePostRequestAsync(apiScopes, url, bankAccountToDelete)).status;
  return response;
}