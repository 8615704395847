import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { SupplierMatchViewModel } from "../../../../http/Redux/Models/SupplierMatchViewModel";
import { SupplierModel } from "../../../../http/Company/Models/SupplierModel";
import LoadingBackdrop from "../../../../components/Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../../../http/CompanyVerificationService";
import "../../../../components/Connect/SearchResult/SweetAlert.css";


// eslint-disable-next-line no-unused-vars
const EditUpload = ({ data, handleShowRematch }: { data: SupplierMatchViewModel, handleShowRematch: (updatedViewModel: SupplierMatchViewModel) => void }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [swalPropsChangeSearch, setSwalPropsChangeSearch] = useState({});
  const [updateModel, setUpdateModel] = useState({} as SupplierModel);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUpdate = async () => {

    setIsLoading(true);
    await CompanyVerificationService.updateUploadStaged(data.uploadTempCompanyId, data.clientCompanyId, updateModel);
    setIsLoading(false);
    setOpen(false);
    setSwalPropsChangeSearch({
      show: true,
      title: "Supplier Successfully Updated",
      icon: "success",
      timer: 2000,
      showCancelButton: false,
      showConfirmButton: false,

    });
    const updateViewModel = {
      uploadClientVendorNumber: updateModel.clientVendorNumber,
      uploadRegisteredName: updateModel.registeredName,
      uploadTradingName: updateModel.tradingName,
      uploadVATNumber: updateModel.vatNumber,
      uploadRegistrationNumber: updateModel.registrationNumber,
      clientCompanyId: updateModel.clientCompanyId,
      uploadTempCompanyId: updateModel.tempCompanyId,
      uploadContactEmail: updateModel.contactEmail,
      uploadContactName: updateModel.contactName,
      uploadContactPhone: updateModel.contactPhone,
    } as SupplierMatchViewModel;

    handleShowRematch(updateViewModel);

  }


  useEffect(() => {

    if (!updateModel.registeredName) {
      const getsupplier: SupplierModel = {} as SupplierModel;
      getsupplier.clientVendorNumber = data.uploadClientVendorNumber;
      getsupplier.contactName = data.uploadContactName;
      getsupplier.contactEmail = data.uploadContactEmail;
      getsupplier.contactPhone = data.uploadContactPhone;
      getsupplier.registeredName = data.uploadRegisteredName;
      getsupplier.tradingName = data.uploadTradingName;
      getsupplier.vatNumber = data.uploadVATNumber;
      getsupplier.registrationNumber = data.uploadRegistrationNumber;
      getsupplier.clientCompanyId = data.clientCompanyId;
      getsupplier.tempCompanyId = data.uploadTempCompanyId;
      setUpdateModel(getsupplier);
    }
  }, [data.uploadRegisteredName])


  return (
    <Box>
      <LoadingBackdrop showBackdrop={isLoading} />
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: 2 }}>
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.registeredName}
              onChange={(e) => { setUpdateModel({ ...updateModel, registeredName: e.target.value }) }}
              size="small"
              label="Registered Name" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.clientVendorNumber}
              onChange={(e) => { setUpdateModel({ ...updateModel, clientVendorNumber: e.target.value }) }}
              size="small"
              label="Supplier Number" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.tradingName}
              onChange={(e) => { setUpdateModel({ ...updateModel, tradingName: e.target.value }) }}
              size="small"
              label="Trading Name" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.registrationNumber}
              size="small"
              onChange={(e) => { setUpdateModel({ ...updateModel, registrationNumber: e.target.value }) }}
              label="Reg No" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.vatNumber}
              size="small"
              onChange={(e) => { setUpdateModel({ ...updateModel, vatNumber: e.target.value }) }}
              label="Vat No" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.contactName}
              onChange={(e) => { setUpdateModel({ ...updateModel, contactName: e.target.value }) }}
              size="small"
              label="Contact Name" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.contactEmail}
              onChange={(e) => { setUpdateModel({ ...updateModel, contactEmail: e.target.value }) }}
              size="small"
              label="Email" />
            <TextField
              sx={{ width: "100%", marginBottom: 2 }}
              value={updateModel.contactPhone}
              onChange={(e) => { setUpdateModel({ ...updateModel, contactPhone: e.target.value }) }}
              size="small"
              label="Contact Number" />


          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleClickUpdate} autoFocus>
            Save
          </Button>
        </DialogActions>

      </Dialog>
      <SweetAlert2 {...swalPropsChangeSearch} />
    </Box>

  )
}

export default EditUpload;