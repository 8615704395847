import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import CrossIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ProfileShareService from "../../http/Redux/ProfileShareService";
import { CompanySharedProfileModel } from "../../http/Redux/Models/CompanySharedProfileModel";
import styles from "./sharestyle.module.scss";
import bufferProfile from "../../util/images/filler.png";
import Zero from "../../util/images/Ratings/Zero.png";
import One from "../../util/images/Ratings/One.png";
import Two from "../../util/images/Ratings/Two.png";
import Three from "../../util/images/Ratings/Three.png";
import Four from "../../util/images/Ratings/Four.png";
import Five from "../../util/images/Ratings/Five.png";
import B1link from "../../util/images/b1link.svg";
import Sections from "../../util/enums/ProfileShareSections";
import "../../util/Styling/Sharing.css";


const Share = () => {
  const { hash } = useParams();
  const [checkHash, setHash] = useState(false);
  const [profile, setProfile] = useState<CompanySharedProfileModel>({} as CompanySharedProfileModel);
  const [general, setGeneral] = useState(false);
  const [address, setAddress] = useState(false);
  const [contact, setContact] = useState(false);
  const [product, setProduct] = useState(false);
  const [bee, setBee] = useState(false);
  const [compliance, setCompliance] = useState(false);
  const [bankDetails, setBankDetails] = useState(false);
  const [companyRating, setCompanyRating] = useState(false);
  const [ratingImage, setRatingImage] = useState("");


  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("https://b1nguat.b2clogin.com");
  };


  useEffect(() => {

    if (!checkHash) {
      const loaderPrompt = async () => {

        const result = await ProfileShareService.GetShare(hash as string).finally(() => {
          setHash(true);
        });
        setProfile(result);
        const section = result.sections.split(",");
        section.forEach((element: any) => {
          if (Number(element) === Sections.General) {
            setGeneral(true);
            setAddress(true);
            setBee(true);

          }
          if (Number(element) === Sections.BankDetails) {
            setBankDetails(true);
          }
          if (Number(element) === Sections.Compliance) {
            setCompliance(true);
          }
          if (Number(element) === Sections.AdditionalContacts) {
            setContact(true);
          }
          if (Number(element) === Sections.CompanyRating) {
            setCompanyRating(true);
            switch (profile.rating) {
              case 0:
                setRatingImage(Zero);
                break;
              case 1:
                setRatingImage(One);
                break;

              case 2:
                setRatingImage(Two);
                break;

              case 3:
                setRatingImage(Three);
                break;

              case 4:
                setRatingImage(Four);
                break;

              case 5:
                setRatingImage(Five);
                break;


              default:
                // Default case if neededa
                break;
            }
          }
          if (Number(element) === Sections.Product) {
            setProduct(true);
          }
        });
      };
      loaderPrompt();
    }
  }, [checkHash, hash, profile, profile.companyId, setProfile]);



  return (
    <body className={styles.body}>
      <div id="top-nav">
        <img src="https://stuatb1linkzanorth001.blob.core.windows.net/signin/B1Link Logo-Blue-01.svg" alt="Logo" id="logo" />
        <nav>
          <div className="nav-div">
            <a href="https://about.b1link.com/">ABOUT B1 LINK</a>
          </div>
        </nav>
      </div>
      <Box sx={{ paddingBottom: 5 }}>
        {checkHash && (
          <Box sx={{ width: "100%", paddingLeft: "25%", paddingRight: "25%", paddingTop: "20px" }}>
            <Box sx={{ marginTop: "100px", marginBottom: 2, borderRadius: "5px", backgroundColor: "white", padding: 2, boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
              <Grid xs={9}>
                Shared company information is limited. To see all avaliable company information such as documents, please sign up for an account or just sign in if you already have an account with us.
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Button variant="contained" onClick={handleRedirect}>SIGN IN/SIGN UP</Button>
              </Grid>
            </Box>
            <Box sx={{ borderRadius: "5px", backgroundColor: "white", padding: 2, boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
              <Box className={styles.box}>
                <div className={styles.companyViewBox}>
                  <div className={styles.companyViewRight}>
                    <div className={styles.imageBox}>
                      <div className="card-image">
                        <img
                          src={profile.file ? `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/profile-images/${profile.file}` : bufferProfile}
                          alt=""
                          className={styles.companyProfileImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.companyViewLeft}>
                    <h2>{profile.registeredName}</h2>
                    <h3>{profile.tradingName}</h3>
                  </div>
                </div>
              </Box>
              {general && (
                <>
                  <Box className={styles.section}>
                    <p className={styles.subtitle}>Company Details</p>
                  </Box>
                  <Box className={styles.sectionflex}>
                    <p className={styles.text}><b>Company Type: </b>{profile.type}</p>
                    <p className={styles.text}><b>Company Registration Number: </b>{profile.registrationNumber || ""}</p>
                    <p className={styles.text}><b>VAT Registration Number:</b> {profile.vatNumber}</p>
                    {bee && (
                      <p className={styles.text}>
                        <Grid container>
                          <Grid xs={1}> <b>B-BBEE: </b></Grid>
                          <Grid xs={9}>
                            <Box>
                              <b>Black Women Owned: </b>{`${profile.bWOPercentage || 0}%,`}
                              <br />
                              <b>Black Owned: </b>{`${profile.bOPercentage || 0}%`}
                              <br />
                              <b>Certificate Expiry Date: </b>{`${profile.CertificateExpiryDate || ""}`}
                            </Box>
                          </Grid>
                        </Grid>
                      </p>
                    )}
                    <p className={styles.text}><b>Company Contact Number:</b> {profile.mobile}</p>
                    <p className={styles.text}><b>Company Email:</b> {profile.email}</p>
                    <p className={styles.text}><b>Company Products & Services Description: </b>{profile.tradeDescription ? profile.tradeDescription : "No product or services description"}</p>
                  </Box>
                  {bankDetails && profile.accountName && (
                    <>
                      <Box className={styles.section}>
                        <p className={styles.subtitle}>Bank Details</p>
                      </Box>
                      <Box className={styles.sectionflex}>
                        <p className={styles.text}><b>Account Name:</b> {profile.accountName}</p>
                        <p className={styles.text}><b>Account Number:</b> {profile.accountName}</p>
                        <p className={styles.text}><b>Branch:</b> {profile.branchName}</p>
                      </Box>
                    </>)}
                  {companyRating && (
                    <>
                      <Box className={styles.section}>
                        <p className={styles.subtitle}>Rating</p>
                      </Box><Box className={styles.sectionflex}>
                        <img src={ratingImage} className={styles.imagerating} alt="" />
                      </Box>
                    </>)}
                </>
              )}
              {contact && (
                <>
                  <Box className={styles.section}>
                    <p className={styles.subtitle}>Additional Contact</p>
                  </Box><Box className={styles.sectionflex}>
                    <p className={styles.text}><b>Contact Name:</b> {profile.contact2}</p>
                    <p className={styles.text}><b>Email:</b> {profile.email2}</p>
                    <p className={styles.text}><b>Contact Number:</b> {profile.mobile2}</p>
                  </Box>
                </>
              )}
              {product && (

                <Box>
                  <Box className={styles.section}>
                    <p className={styles.subtitle}>Products & Services</p>
                  </Box>
                  <Box className={styles.sectionflex}>
                    <p className={styles.text}>{profile.segments}</p>
                  </Box>
                </Box>

              )}
              {address && (
                <Box>
                  {
                    profile.line1 !== null ?
                      <Box>
                        <Box className={styles.section}>
                          <p className={styles.subtitle}>Address</p>
                        </Box>
                        <Box className={styles.sectionflex}>

                          <p className={styles.text}>{profile.line1}</p>
                          <p className={styles.text}>{profile.line2}</p>
                          <p className={styles.text}>{profile.city}</p>

                        </Box>
                      </Box> : ""
                  }
                </Box>
              )}
              {compliance && (
                <>
                  <Box className={styles.section}>
                    <p className={styles.subtitle}>Compliance</p>
                  </Box><div className={styles.sectionDetails}>
                    <Grid container sx={{ textAlign: "center", fontSize: "14px" }}>
                      <Grid xs={3}>Company Registered <br /> Name</Grid>
                      <Grid xs={3}>CIPC Business <br />  Status</Grid>
                      <Grid xs={3}>SARS <br />  Validation</Grid>
                      <Grid xs={3}>B-BBEE <br />  Validation</Grid>
                      <Grid xs={3}><CheckIcon className="cl-green" /></Grid>
                      <Grid xs={3}><CrossIcon className="cl-red" /></Grid>
                      <Grid xs={3}><CrossIcon className="cl-red" /></Grid>
                      {profile.sector ?
                        <Grid xs={3}>
                          {profile.CertificateExpiryDate > new Date() ? <CheckIcon className="cl-green" /> : <CrossIcon className="cl-red" />}
                        </Grid> : <Grid xs={3}><CrossIcon className="cl-red" /></Grid>}
                      <Grid xs={3} className="cl-green">Passed</Grid>
                      <Grid xs={3} className="cl-red">Failed</Grid>
                      <Grid xs={3} className="cl-red">Failed</Grid>
                      {profile.sector ?
                        <Grid xs={3}>
                          {profile.CertificateExpiryDate > new Date() ? <Grid xs={12} className="cl-green">Passed</Grid> : <Grid xs={12} className="cl-red">Failed</Grid>}
                        </Grid> : <Grid xs={3} className="cl-red">Failed</Grid>}
                    </Grid>
                  </div>
                </>
              )}
              <div className="hr-line" />
              <div className={styles.sectionDetailsPrint}>
                <Grid container sx={{ textAlign: "left" }}>
                  <Grid xs={3}>
                    <img src={B1link} alt="" />
                  </Grid>
                  <Grid xs={12}>
                    {profile.level ?
                      "Please note that this B1 LINK document does not constitute a B-BBEE Certificate. B1SA has validated that the B-BBEE certification of this company is compliant with the requirements of the Codes of Good Practice." :
                      "Please note that this B1 LINK document does not constitute a B-BBEE Certificate. B1SA has validated that the B-BBEE certification of this company is not compliant with the requirements of the Codes of Good Practice."}
                    <br />
                    B1 LINK is a product offering of B1SA,a proud member of the Signa Group. Visit <a href="www.b1link.com"> www.b1link.com</a>
                    <br />
                    For  further information, Kindly contact B1SA on +27 11 455 0033.
                  </Grid>
                </Grid>
              </div>
            </Box>


          </Box>
        )}


      </Box>
    </body>
  )

}


export default Share;