import makeGetRequestAsync, { makePUTRequestAsync } from "../Helpers/httpRequestHelpers";
import { UnspscOptionsMessage } from "./UnspscOptionsMessage";
import { protectedResources } from "../../authConfig";
import { BasicUnspscSegmentModel } from "../Redux/Models/BasicUnspscSegmentModel";
import { BasicUnspscFamilyModel } from "../Redux/Models/BasicUnspscFamilyModel";
import ClientSpecificSupplierCategoryMessage from "./ClientSpecificSupplierCategoryMessage";



const apiScopes = protectedResources.configurationWebApi.scopes
const baseUrl = `${protectedResources.configurationWebApi.endpoint}/api/configuration`;

const getUnspscOptions = async () => {
  const url = `${baseUrl}/unspsc`;
  return (await (makeGetRequestAsync<UnspscOptionsMessage>(apiScopes, url))).data
}

export default getUnspscOptions;

export const updateUnspscSelection = async (input: number[]) => {
  const url = `${baseUrl}/unspsc`;
  return (await (makePUTRequestAsync<void, number[]>(apiScopes, url, input))).data
}

export const getCompanyUnspscSelection = async () => {
  const url = `${baseUrl}/unspsc/selection`;
  return (await (makeGetRequestAsync<number[]>(apiScopes, url))).data
}

export const getBasicUnspscSegmentsList = async () => {
  const url = `${baseUrl}/unspsc/BasicSegmentList`;
  const response = (await (makeGetRequestAsync<BasicUnspscSegmentModel[]>(apiScopes, url))).data
  return response;
}

export const getBasicUnspscFamiliesList = async () => {
  const url = `${baseUrl}/unspsc/BasicFamilyList`;
  const response = (await (makeGetRequestAsync<BasicUnspscFamilyModel[]>(apiScopes, url))).data;
  return response;
}

export const searchFamilies = async (searchText: string) => {
  const url = `${baseUrl}/search/unspsc?searchTerm=${searchText}`;
  const response = (await (makeGetRequestAsync<number[]>(apiScopes, url))).data;
  return response;
}
/*
Client specific categories
*/
export const getClientSpecificCategories = async (clientId: number) => {
  const url = `${baseUrl}/ClientSpecificSupplierCategories/${clientId}`;
  return (await (makeGetRequestAsync<ClientSpecificSupplierCategoryMessage[]>(apiScopes, url))).data
}

export const getAllSpecificCategories = async () => {
  const url = `${baseUrl}/ClientSpecificSupplierCategories`;
  return (await (makeGetRequestAsync<ClientSpecificSupplierCategoryMessage[]>(apiScopes, url))).data
}

export const getSupplierCategoriesSelection = async (clientId: number, supplierId: number) => {
  const url = `${baseUrl}/ClientSpecificSupplierCategories/selection?supplierCompanyId=${supplierId}&clientCompanyId=${clientId}`;
  return (await (makeGetRequestAsync<number[]>(apiScopes, url))).data
}

export const updateClientSpecificSubCategorySelectionAsync = async (clientId: number,
  supplierId: number,
  subCatSelection: number[]) => {
  const url = `${baseUrl}/ClientSpecificSupplierCategories?supplierCompanyId=${supplierId}&clientCompanyId=${clientId}`;
  return (await (makePUTRequestAsync<void, number[]>(apiScopes, url, subCatSelection))).data
}