// eslint-disable-next-line no-shadow
enum BillInterval { // eslint-disable-line no-unused-vars
  Month = 0,// eslint-disable-line no-unused-vars
  Week = 1,// eslint-disable-line no-unused-vars
  Annual = 2,// eslint-disable-line no-unused-vars
}

export default BillInterval;

export const BillIntervalToString = (value: BillInterval) => {
  switch (value) {
    case BillInterval.Month:
      return "Month"
    case BillInterval.Week:
      return "Week"
    case BillInterval.Annual:
      return "Annual"
    default:
      return "Unknown";
  }
};