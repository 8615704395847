import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ManageSearchIcon from "@mui/icons-material/Search";
import LoadingBackdrop from "../../../../components/Backdrop/LoadingBackdrop";
import "../../../../components/Connect/SearchResult/SweetAlert.css";

import { AgentTaskModel } from "../../../../http/Company/Models/AgentTaskModel";
import { AgentTaskStatusTypes } from "../../../../util/enums/AgentTaskStatusTypes";
import { useAppSelector } from "../../../../app/hooks";
import { getUserProfile } from "../../../../http/Redux/Store/UserProfileSlice";
import DataSourceTypes from "../../../../util/enums/DataSourceTypes";
import CompanyVerificationService from "../../../../http/CompanyVerificationService";
import { SupplierMatchViewModel } from "../../../../http/Redux/Models/SupplierMatchViewModel";


interface ViewCellProps {
  row: SupplierMatchViewModel;
  stage: boolean;
  Claim: boolean;
  selectedClientId: number;
  // eslint-disable-next-line no-unused-vars
  SetIsClaimed: (IsClaimed: boolean) => void;
  PageSource: number;
  handleViewMatch: () => void;

}

const ViewCell = ({ row, stage, selectedClientId, Claim, SetIsClaimed, PageSource, handleViewMatch }: ViewCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [activeClaim, setActiveClaim] = useState(false);
  const user = useAppSelector(getUserProfile);


  const handleSaveAgentTask = async () => {
    setIsLoading(true);
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = user.id as number;
    model.clientId = selectedClientId;
    model.dataSource = stage ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSource;
    model.uploadTempCompanyId = row.uploadTempCompanyId;
    await CompanyVerificationService.addAgentTask(model, false).finally(() => {
      setIsLoading(false);
      SetIsClaimed(true);
      setActiveClaim(true);
    });
  }

  const handleSaveAgentTaskThenView = async () => {
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = user.id as number;
    model.clientId = selectedClientId;
    model.dataSource = stage ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSource;
    model.uploadTempCompanyId = row.uploadTempCompanyId;
    await CompanyVerificationService.addAgentTask(model, false).finally(() => {
      SetIsClaimed(true);
      setActiveClaim(true);
      handleViewMatch();
    });

  }


  useEffect(() => {
    setActiveClaim(Claim);
  }, [])


  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };



  return (

    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      {row.claimedMatchTask === 1 || activeClaim ? (
        <Tooltip title="Task Is Claimed">
          <IconButton disabled sx={{ ml: 1.5 }}>
            <BookmarkAddedIcon sx={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Claim Actions">
            <IconButton
              onClick={handleClickMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={opens ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={opens ? "true" : undefined}
            >
              <BookmarkIcon />
            </IconButton>
          </Tooltip><Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={opens}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
          >

            <MenuItem onClick={handleSaveAgentTask}>
              <BookmarkAddIcon /> Claim
            </MenuItem>
            <MenuItem onClick={handleSaveAgentTaskThenView}>
              <div>
                <BookmarkIcon />
                <span style={{ width: "20px", marginTop: "10px" }}>
                  <ManageSearchIcon />
                </span>
              </div>
              Claim & View Match & Clean
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );

}

export default ViewCell;