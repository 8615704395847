import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetAllProvinces } from "../../../http/Company/Province";

const useProvinces = () => {
  const query = useQuery({
    queryKey: [queryKeys.provinces],
    queryFn: async () => (await GetAllProvinces()).data,
    staleTime: 600000, 
  })

  return query;
}

export default useProvinces;