import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetBeeLevels } from "../../../http/Company/beeDetailsApi";

const useBeeLevels = () => {
  const query = useQuery({
    queryKey: [queryKeys.beeLevels],
    queryFn: async () => (await GetBeeLevels()).data,
    staleTime: 600000, 
  })

  return query;
}

export default useBeeLevels;