/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Dialog, Grid, IconButton, Switch, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import Hi from "../../util/images/Hi.gif";
import { NewSubscriptionMessage } from "../../http/Billing/NewSubscriptionMessage";
import {
  addPendingUpgradeSubscription, createCompanySubscription,
  downgradeSubscription,
  getActiveCompanySubscription, getUpgradeProrataFee,
} from "../../http/SubscriptionManagement/SubscriptionApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserProfile } from "../../http/Redux/Store/UserProfileSlice";
import { selectBaseCompanyInfo } from "../../http/Redux/Store/baseCompanyInfoSlice";
import { CompanySubscriptionMessage } from "../../http/SubscriptionManagement/Models/CompanySubscription";
import ActiveSubscriptionCard from "./ActiveSubscriptionCard";
import { fetchCurrentSubscription, fetchCurrentSubscriptionPermissions, fetchPlans } from "../../http/Redux/Store/SubscriptionActions";
import { getAllPlans } from "../../http/Redux/Store/SubscriptionSlice";
import BillInterval, { BillIntervalToString } from "../../util/enums/BillInterval";
import { CreateCompanySubscriptionMessage } from "../../http/SubscriptionManagement/Messages/CreateCompanySubscriptionMessage";
import PermissionClassification from "../../util/enums/PermissionClassification";
import WithRequiredPermission from "../WithRequiredPermission";
import RedeemVoucherModal from "./RedeemVoucherModal";
import { VoucherMessage } from "../../http/SubscriptionManagement/Messages/VoucherMessage";
import DiscountTypeEnum from "../../http/SubscriptionManagement/Enums/DiscountTypeEnum";
import styles from "./SubscriptionsView.module.scss";
import {
  CreateNewBilingSubscription as CreateNewBillingSubscription,
  ReplaceSubscription as ReplaceSubscriptionBilling,
} from "../../http/Billing/BillingApi";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import UserPopupActivities from "../../util/enums/UserPopupActivities";
import ActivityStatus from "../../util/enums/ActivityStatus";
import UserPromptActivityService from "../../http/UserPromptActivityService";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import { fetchUserPromptActivity } from "../../http/Redux/Store/UserPromptActivityActions";
import PromptStatus from "../../util/enums/PromptStatus";
import { ReplaceSubscriptionMessage } from "../../http/Billing/ReplaceSubscriptionMessage";
import BasePage from "../../pages/BasePage";
import { fetchBaseSubscriptionInfo } from "../../http/Redux/Store/BaseSubscriptionInfoActions";


const SubscriptionsView = () => {
  const userPrompt = useAppSelector(getUserPromptActivity);
  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>(undefined);
  const [selectedAddonIdsList, setSelectedAddonIdsList] = useState<GridRowId[]>([])
  const [totalCostOfAddOns, setTotalCostOfAddOns] = useState<number>(0);
  const [selectedVoucher, setSelectedVoucher] = useState<VoucherMessage | undefined>(undefined)
  const [currentSubscription, setCurrentCompanySubscription] = useState<CompanySubscriptionMessage | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRedeemVoucherModal, setShowRedeemVoucherModal] = useState(false);
  const [showActiveSubscriptionView, setShowActiveSubscriptionView] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userProfile = useAppSelector(getUserProfile);
  const company = useAppSelector(selectBaseCompanyInfo);
  const subscriptionPlans = useAppSelector(getAllPlans);
  const [isAnnualSubscriptions, setIsMonthly] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [previousUrl, setPreviousUrl] = useState("");
  const selectedPlan = subscriptionPlans.find((x) => x.id === selectedPlanId);

  const confirmSubscriptionUpgradeHandler = useCallback(async (prorataFee: number) => {
    if (selectedPlan != null && selectedPlan.id > 0) {
      try {
        setIsSaving(true);
        const newCompanySubMessage: CreateCompanySubscriptionMessage = {
          planId: selectedPlanId ?? 0,
          productAddOnIdList: selectedAddonIdsList as unknown as number[],
          voucherId: selectedVoucher?.id,
        }
        // API call to subscriptions
        const newCompanySubscription = await addPendingUpgradeSubscription(newCompanySubMessage).finally(() => {
          setIsSaving(false);
        });

        const totalCost = newCompanySubscription?.recurringCostForNewSubscription;
        // API call to the billing service
        if (totalCost != null && totalCost > 0) {
          setIsSaving(true);
          const message: ReplaceSubscriptionMessage = {
            externalSubscriptionId: newCompanySubscription.id,
            oldExternalSubscriptionId: currentSubscription?.id ?? 0,
            itemName: `Upgrade to ${selectedPlan.description} , pro-rata fee`,
            prorata: prorataFee,
            amount: totalCost,
            returnUrl: window.location.href,
            cancelUrl: window.location.href,
          };
          try {
            setIsLoading(true);
            const payfastUrl = await ReplaceSubscriptionBilling(message);
            payfastUrl.length > 0 ? window.location.replace(payfastUrl) :
              toast.success("Subscription upgraded successfully");
          } catch (error) {
            setIsLoading(false);
            toast.error(`An error occured while attempting to complete your subscription upgrade.
            Please try again or contact the administrator if this continues`);
          }
        }
      } catch (error) {
        setIsSaving(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, selectedPlanId]);

  const onUpgradeSubscriptionClick = useCallback(async () => {
    if (selectedPlan != null && selectedPlan.id > 0) {
      try {
        setIsLoading(true);
        const newCompanySubMessage: CreateCompanySubscriptionMessage = {
          planId: selectedPlanId ?? 0,
          productAddOnIdList: selectedAddonIdsList as unknown as number[],
          voucherId: selectedVoucher?.id,
        }
        // API call to subscriptions
        const proRataFee = await getUpgradeProrataFee(newCompanySubMessage);
        Swal.fire({
          title: "Upgrade subscription package",
          html: `We are about to upgrade your subscription to the <b>${selectedPlan.planCode}</b> package. 
          <br/><br/>
          Please note that you will be required to pay a <strong>Pro-rata fee of R${proRataFee}</strong>,
          prior to your next billing date`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Continue",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await confirmSubscriptionUpgradeHandler(proRataFee);
          }
          setIsSaving(false);
        })
      } catch (error) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, selectedPlanId])

  const confirmDowngradeHandler = useCallback(async () => {
    if (selectedPlan != null && selectedPlan.id > 0) {
      try {
        setIsSaving(true);
        const newCompanySubscription = await downgradeSubscription(selectedPlanId ?? 0);
        // API call to the billing service
        setIsSaving(true);
        const message: ReplaceSubscriptionMessage = {
          externalSubscriptionId: newCompanySubscription.id,
          oldExternalSubscriptionId: currentSubscription?.id ?? 0,
          itemName: `Downgrade to ${selectedPlan.description}`,
          amount: newCompanySubscription.subscriptionBilling?.value ?? 0,
          returnUrl: window.location.href,
          cancelUrl: window.location.href,
        };
        try {
          setIsLoading(true);
          const payfastUrl = await ReplaceSubscriptionBilling(message);
          payfastUrl.length > 0 ? window.location.replace(payfastUrl) :
            toast.success("Downgrade package successfully");
        } catch (error) {
          setIsLoading(false);
          toast.error(`An error occured while attempting to complete your subscription downgrade.
            Please try again or contact the administrator if this continues`);
        }
      } catch (error) {
        setIsSaving(false);
      }
    }
  }, [currentSubscription?.id, selectedPlan, selectedPlanId]);


  const showDowngradeConfirmationModal = useCallback(async () => {
    if (selectedPlan != null && selectedPlan.id > 0) {
      try {
        setIsLoading(true);
        // API call to subscriptions
        Swal.fire({
          title: "Downgrade subscription package",
          html: `We are about to downgrade your subscription to a lower package. 
                    <br/><br/>
                    Please note that your new subscription will take place at the end of the billing cycle.`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Continue",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await confirmDowngradeHandler();
          }
          setIsSaving(false);
        })
      } catch (error) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, selectedPlanId])


  const onPurchaseSubscriptionButtonClick = useCallback(async () => {
    const switchingFromExistingSubscription = selectedPlan?.amount != null &&
      currentSubscription != null &&
      currentSubscription?.subscriptionBilling?.value != null;

    const isUpgradeOperation = switchingFromExistingSubscription &&
      selectedPlan.amount > 0 &&
      currentSubscription?.subscriptionBilling?.value != null &&
      selectedPlan.amount > currentSubscription.subscriptionBilling.value;

    const isDowngradeOperation = switchingFromExistingSubscription &&
      currentSubscription?.subscriptionBilling?.value != null &&
      selectedPlan.amount <= currentSubscription.subscriptionBilling.value;


    // upgrade
    if (isUpgradeOperation && currentSubscription?.subscriptionBilling?.value != null &&
      currentSubscription?.subscriptionBilling?.value > 0
    ) {
      await onUpgradeSubscriptionClick();
    }
    // downgrade
    if (isDowngradeOperation) {
      await showDowngradeConfirmationModal()
    }
    // purchase new subscription
    else if (selectedPlan != null && selectedPlan.id > 0) {
      try {
        setIsSaving(true);
        const newCompanySubMessage: CreateCompanySubscriptionMessage = {
          planId: selectedPlanId ?? 0,
          productAddOnIdList: selectedAddonIdsList as unknown as number[],
          voucherId: selectedVoucher?.id,
        }
        // API call to subscriptions
        const newCompanySubscription = await createCompanySubscription(newCompanySubMessage).finally(() => {
          setIsSaving(false);
        });
        const totalCost = newCompanySubscription?.subscriptionBilling?.value;
        // API call to the billing service
        if (totalCost != null && totalCost > 0) {
          setIsSaving(true);
          const message: NewSubscriptionMessage = {
            cellNumber: userProfile.mobile,
            billingInterval: subscriptionPlans.find(x => x.id === selectedPlanId)?.billInterval ?? BillInterval.Month,
            vatNumber: "", // to-do this must be populated
            companyRegistrationNumber: company.companyRegistrationNumber,
            externalSubscriptionId: newCompanySubscription.subscriptionBilling?.companySubscriptionId ?? 0,
            subscriptionName: `${selectedPlan.planCode}${selectedAddonIdsList.length > 0 ? " with Add-on Products" : ""}`,
            price: totalCost,
            returnUrl: window.location.href,
            cancelUrl: window.location.href,
          }

          const payfastUrl = await CreateNewBillingSubscription(message).finally(() => {
            setIsSaving(false);
          });
          window.location.replace(payfastUrl);
        }
      } catch (error) {
        setIsSaving(false);
        toast.error(
          "An error occurred while attempting to create your billing payment. Please try again or contact your admin if this occurs"
        );
      }
    }

    dispatch(fetchBaseSubscriptionInfo());
    dispatch(fetchCurrentSubscription());
    dispatch(fetchCurrentSubscriptionPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, selectedPlanId])
  useEffect(() => {
    const loader = async () => {
      try {
        setIsLoading(true);
        if (subscriptionPlans.length <= 0) {
          dispatch(fetchPlans());
        }

        const data = await getActiveCompanySubscription();
        setCurrentCompanySubscription(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    loader();

    const isClosedSub = userPrompt.find(x => x.popupPromptId === UserPopupActivities.Subscriptions && x.status === ActivityStatus.Closed);
    const isShowMessage = userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message
      && x.status === ActivityStatus.Show && x.seenPopupId !== 1);
    const isShowSub = userPrompt.find(x => x.popupPromptId === UserPopupActivities.Subscriptions
      && x.status === ActivityStatus.Show && x.seenPopupId !== 1);


    if (!isClosedSub || isShowMessage) {
      setPreviousUrl(document.referrer);
    }

    const isIncluded = previousUrl.includes("payfast.co.za");

    if (isShowSub && isIncluded) {
      setIsFirst(true);
      setPopupOpen(true);
    }
    else if (isShowMessage && isIncluded) {
      setPopupOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousUrl, userPrompt.length, isFirst, subscriptionPlans.length, dispatch,
    currentSubscription?.id, currentSubscription?.planId, subscriptionPlans.length,
    showActiveSubscriptionView, onPurchaseSubscriptionButtonClick])

  const handleCloseClick = () => {
    if (isFirst) {
      const loaderPrompt = async () => {
        const newModel: UserPromptActivityModel = {
          id: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Subscriptions && x.status === ActivityStatus.Show)?.id,
          accountId: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Subscriptions && x.status === ActivityStatus.Show)?.accountId,
          popupPromptId: UserPopupActivities.Subscriptions,
          status: PromptStatus.Closed,
          seenPopupId: 1,
          dateCreated: new Date(),
          dateModified: new Date(),
        };

        const newModelMessage: UserPromptActivityModel = {
          id: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.id,
          accountId: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.accountId,
          popupPromptId: UserPopupActivities.Message,
          status: PromptStatus.Show,
          seenPopupId: 1,
          dateCreated: new Date(),
          dateModified: new Date(),
        };
        await UserPromptActivityService.updateUserPromptActivity(newModelMessage);
        await UserPromptActivityService.updateUserPromptActivity(newModel).finally(() => {
          dispatch(fetchUserPromptActivity(userPrompt[0].accountId as string, company.companyId));
          setPopupOpen(false);
          navigate("/home/true");
        });
      };

      loaderPrompt();


    }
    else {

      const loaderPrompt = async () => {
        const newModel: UserPromptActivityModel = {
          id: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.id,
          accountId: userPrompt.find(x => x.popupPromptId === UserPopupActivities.Message && x.status === ActivityStatus.Show)?.accountId,
          popupPromptId: UserPopupActivities.Message,
          status: PromptStatus.Closed,
          seenPopupId: 1,
          dateCreated: new Date(),
          dateModified: new Date(),
        };
        await UserPromptActivityService.updateUserPromptActivity(newModel).finally(() => {
          dispatch(fetchUserPromptActivity(userPrompt[0].accountId as string, company.companyId));
          setPopupOpen(false);
        });
      };

      loaderPrompt();
    }

  }

  return (
    <>
      <LoadingBackdrop showBackdrop={isSaving || isLoading} />
      <BasePage pageHeader="Subscriptions" pageHeaderSub="Manage and change your subscription package.">
        {!isLoading &&
          showRedeemVoucherModal &&
          selectedPlanId &&
          <RedeemVoucherModal
            onCloseCallback={() => setShowRedeemVoucherModal(false)}
            onSubmitCallback={(voucher) => {
              setSelectedVoucher(voucher);
            }}
            selectedPlanId={selectedPlanId} />}
        <div className={styles.subSectionBox}>
          <div className={styles.subSection}>
            <Box sx={{ display: currentSubscription?.id != null ? "none" : "block" }}>
              <Typography variant="subtitle1" component="span">{`${BillIntervalToString(BillInterval.Month)}ly`}</Typography>
              &nbsp; <Switch name="checkbox" color="primary"
                checked={isAnnualSubscriptions}
                onChange={(event) => setIsMonthly(event.target.checked)} /> &nbsp;
              <Typography variant="subtitle1" component="span">{`${BillIntervalToString(BillInterval.Annual)}ly`}</Typography>
            </Box>
            <WithRequiredPermission permission={PermissionClassification.EditMySubscription}>
              {showActiveSubscriptionView && currentSubscription !== undefined ?
                <ActiveSubscriptionCard
                  subscription={currentSubscription}
                  planName={subscriptionPlans.find(x => x.id === currentSubscription.planId)?.planCode ?? ""}
                  showSubscriptionViewCallback={() => setShowActiveSubscriptionView(false)}
                />
                :
                <>
                  <Box p={1}>
                    <Grid container>
                      <Grid xs={3} />
                      <Grid xs={3}>
                        {selectedPlan && (selectedPlan.amount + totalCostOfAddOns > 0) &&
                          <Button
                            sx={{ marginTop: 7, marginLeft: 2, display: "none" }}
                            variant="contained"
                            color="primary"
                            disabled={selectedPlanId == null}
                            onClick={() => setShowRedeemVoucherModal(true)}>
                            REDEEM VOUCHER
                          </Button>}
                      </Grid>
                    </Grid>
                    <Grid xs={3} />
                    {selectedVoucher &&
                      <Box>
                        <Grid container>
                          <Grid xs={12} md={12}>
                            <br />
                            <Alert severity="success">Valid voucher redeemed</Alert>
                          </Grid>
                        </Grid>

                        <Typography variant="body1" color="textSecondary" component="span">
                          Name: {selectedVoucher.name}
                        </Typography>
                        <br />
                        <Typography variant="body1" color="textSecondary" component="span">Discount:
                          {selectedVoucher.discountType === DiscountTypeEnum.FlatRateDiscount ?
                            `R ${selectedVoucher.discount} off` : `${selectedVoucher.discount}% off`}
                        </Typography>
                      </Box>}
                  </Box>
                  {
                    currentSubscription?.subscriptionBilling != null &&
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowActiveSubscriptionView(true)}
                      >
                        View Current Subscription
                      </Button>
                    </Box>
                  }
                  <Box py={2} textAlign="center">
                    <Typography variant="subtitle1" color="textSecondary" paragraph>
                      <b style={{ color: "red" }}>*</b>Limited functionality applies
                      (B-BBEE Compliance and Regulatory Compliance is only applicable to South African registered companies)
                      <b style={{ color: "red" }}>*</b>
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                      {subscriptionPlans.filter(x => x.isActive === true).filter(x => isAnnualSubscriptions ?
                        x.billInterval === BillInterval.Annual :
                        x.billInterval === BillInterval.Month)
                        .map((p) => <SubscriptionPlanCard
                          plan={{
                            ...p,
                            // eslint-disable-next-line no-nested-ternary
                            amount: (selectedVoucher == null || selectedVoucher.associatedPlanId !== p.id) ? p.amount :
                              selectedVoucher.discountType === DiscountTypeEnum.FlatRateDiscount
                                ? (p.amount - parseInt(selectedVoucher.discount, 10))
                                : (p.amount * (100 - parseInt(selectedVoucher.discount, 10)) / 100),
                          }}
                          isSelected={p.id === selectedPlanId}
                          currentSubscription={currentSubscription}
                          onSubmitHandler={onPurchaseSubscriptionButtonClick}
                          isAnnualSubscriptions={isAnnualSubscriptions}
                          selectPlanCallback={(selectedAddOns, planId, costOfAddOns) => {
                            setSelectedPlanId(planId);
                            setSelectedAddonIdsList(selectedAddOns);
                            setTotalCostOfAddOns(costOfAddOns);
                          }} />)}
                    </Grid>
                  </Box>
                </>}
            </WithRequiredPermission>
          </div>
        </div>
        <Dialog open={popupOpen} sx={{ padding: "10px" }}>
          <Box sx={{ textAlign: "right" }}>
            <IconButton sx={{ marginRight: "10px", marginTop: "10px" }} onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ padding: 2, textAlign: "center", width: "400px" }}>
            <Grid xs={12}>
              <img style={{ width: "50%" }} src={Hi} alt="" />
            </Grid>
            <Typography>Thank you for your purchase, please feel free to explore your newly added features.</Typography>
            <br />
            <Button sx={{ width: "100%", marginBottom: "10px" }} variant="contained" onClick={handleCloseClick}>
              {isFirst ? "SHOW ME" : "GOT IT"}
            </Button>
          </Box>
        </Dialog>
      </BasePage>
    </>
  );
}

export default SubscriptionsView;