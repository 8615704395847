/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BasicUnspscSegmentModel } from "../Models/BasicUnspscSegmentModel";


export interface UnspscBasicFamiliesListListState {
  unspscBasicFamiliesList: Array<BasicUnspscSegmentModel>;
}

const unspscBasicFamiliesListSlice = createSlice({
  name: "unspscBasicFamiliesList",
  initialState: [] as Array<BasicUnspscSegmentModel>,
  reducers: {
    setunspscBasicFamiliesListState: (
      state,
      action: PayloadAction<Array<BasicUnspscSegmentModel>>
    ) => action.payload,
  },
});

export const selectunspscBasicFamiliesListList = (state: RootState) => state.unspscBasicFamiliesList;
export default unspscBasicFamiliesListSlice;
