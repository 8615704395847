import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { MunicipalityModel } from "../Redux/Models/MunicipalityModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves all active municipalities
 */
export async function GetActiveMunicipalities() {
  const url = `${baseUrl}/api/Company/Municipality/GetActiveMunicipalities`;
  return makeGetRequestAsync<Array<MunicipalityModel>>(apiScopes, url);
}

/*
 *  Retrieves municipalities that all share a specific province ID.
 */
export async function GetMunicipalitiesByProvinceId(provinceId: number) {
  const url = `${baseUrl}/api/Company/Municipality/GetMunicipalitiesByProvinceId/${provinceId}`;
  return makeGetRequestAsync<Array<MunicipalityModel>>(apiScopes, url);
}

/*
 *  Retrieves a specific municipality based on a passed municipality ID.
 */
export async function GetMunicipalityById(municipalityId: number) {
  const url = `${baseUrl}/api/Company/Municipality/GetMunicipalityById/${municipalityId}`;
  return makeGetRequestAsync<MunicipalityModel>(apiScopes, url);
}
