/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum RequestStatus {
  NotStarted = 0,
  Followup = 1,
  Completed = 3,
  Inprogress = 4,
  Abandoned = 5,
}

export default RequestStatus;

export const requestStatusToString = (value: RequestStatus) => {
  switch (value) {
    case RequestStatus.NotStarted:
      return "Not Started"
    case RequestStatus.Completed:
      return "Completed"
    case RequestStatus.Inprogress:
      return "Inprogress"
    case RequestStatus.Followup:
      return "Follow-up"
    default:
      return "Unknown";
  }
};