// eslint-disable-next-line no-shadow
enum CompanyProfileSections { // eslint-disable-line no-unused-vars
  CompanyDetails = 1,// eslint-disable-line no-unused-vars
  TaxInfo = 2, // eslint-disable-line no-unused-vars
  CompanyContacts = 3, // eslint-disable-line no-unused-vars
  BankingDetails = 4, // eslint-disable-line no-unused-vars
  CompanyService = 5,// eslint-disable-line no-unused-vars

}


export const CompanyProfileSectionsToString = (value: CompanyProfileSections) => {
  switch (value) {
    case CompanyProfileSections.CompanyDetails:
      return "CompanyDetails"
    case CompanyProfileSections.TaxInfo:
      return "TaxInfo"
    case CompanyProfileSections.CompanyContacts:
      return "CompanyContacts"
    case CompanyProfileSections.BankingDetails:
      return "BankingDetails"
    case CompanyProfileSections.CompanyService:
      return "CompanyService"
    default:
      return "Unknown";
  }
};
export const CompanyProfileSectionsToType = (value: string) => {
  switch (value) {
    case "CompanyDetails":
      return 1
    case "TaxInfo":
      return 2
    case "CompanyContacts":
      return 3
    case "BankingDetails":
      return 4
    case "CompanyService":
      return 5
    default:
      return 0;
  }
};

export default CompanyProfileSections;