import { List, ListSubheader } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ConnectionSearchModel } from "../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { fetchBeeLevels } from "../../http/Redux/Store/BeeLevelListActions";
import { fetchCountries } from "../../http/Redux/Store/CountryListActions";
import { fetchProvinces } from "../../http/Redux/Store/ProvinceListActions";
import OverallComplianceStatus from "../../util/enums/OverallComplianceStatus";
import SearchModelProperties from "../../util/enums/SearchModelProperties";
import MultiSelectFilter from "./FilterTypes/MultiSelectFilter";
import { ComplianceTypeModel } from "../../http/Redux/Models/ComplianceTypeModel";
import CompanyRatings from "../../util/enums/CompanyRatings";
import { ConvertToStartCase } from "../../util/functions/textHelpers";
import { CompanyRatingsList } from "../../http/Redux/Models/CompanyRatingsModel";
import SingleOptionSelectFilter from "./FilterTypes/SingleOptionSelectFilter";
import PermissionClassification from "../../util/enums/PermissionClassification";
import WithRequiredPermission from "../WithRequiredPermission";
import NetconnSearchType from "../../util/enums/NetconnSearchType";
import useCompanyTypes from "../../util/hooks/queries/useCompanyTypes";

interface MainFilterProps {
  // eslint-disable-next-line no-unused-vars
  updateMainSearchModel: (searchModel: ConnectionSearchModel) => void;

  // eslint-disable-next-line react/require-default-props
  searchType?: NetconnSearchType;
}

// eslint-disable-next-line no-unused-vars
const MainFilter = ({
  updateMainSearchModel,
  searchType = NetconnSearchType.None,
}: MainFilterProps) => {
  const dispatch = useAppDispatch();

  // Redux state lists
  const baseCompanyInfo = useAppSelector((x) => x.baseCompanyInfo);
  const provinces = useAppSelector((x) => x.provinceList);
  const countries = useAppSelector((x) => x.countryList);
  const beeLevels = useAppSelector((x) => x.beeLevelList);
  const { data: companyTypes } = useCompanyTypes();

  const [isSouthAfricaSelected, setIsSouthAfricaSelected] = useState<boolean>(false);

  // TODO: Make a generic version of the function for the below
  const getOverallComplianceStatusList = () => {
    const complianceEnumVals = Object.entries(OverallComplianceStatus).filter(
      ([key]) => !Number.isNaN(parseInt(key, 10))
    );

    const filterModels = complianceEnumVals.map((complianceEnumValArray) => {
      const model: ComplianceTypeModel = {
        id: parseInt(complianceEnumValArray[0].toString(), 10),
        name: complianceEnumValArray[1].toString(),
      };
      return model;
    });

    return filterModels;
  };

  // TODO: Make a generic version of the function for the below
  const getRatingsList = () => {
    const ratingEnumVals = Object.entries(CompanyRatings).filter(
      ([key]) => !Number.isNaN(parseInt(key, 10))
    );

    const filterModels = ratingEnumVals.map((ratingsEnumValArray) => {
      const model: CompanyRatingsList = {
        id: parseInt(ratingsEnumValArray[0].toString(), 10),
        name: ConvertToStartCase(ratingsEnumValArray[1].toString()),
      };
      return model;
    });

    return filterModels;
  };

  const complianceTypes = getOverallComplianceStatusList();

  const ratingsList = getRatingsList();

  // Local state variables:
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState<ConnectionSearchModel>({
    companyId: baseCompanyInfo.companyId,
  } as ConnectionSearchModel);

  const setValues = <K extends keyof ConnectionSearchModel>(
    key: K,
    value: ConnectionSearchModel[K]
  ) => {
    const modelToUpdate: ConnectionSearchModel = { ...filters };
    modelToUpdate[key] = value;
    return modelToUpdate;
  };

  const updateSearchModelNumArray = (
    updateValue: Array<number>,
    propertyToUpdate: keyof ConnectionSearchModel
  ) => {
    const updatedModel = setValues(propertyToUpdate, updateValue);
    setFilters(updatedModel);
    updateMainSearchModel(updatedModel);
  };

  const updateSearchModelNum = (
    updateValue: number,
    propertyToUpdate: keyof ConnectionSearchModel
  ) => {
    const updatedModel = setValues(propertyToUpdate, updateValue);
    setFilters(updatedModel);
    updateMainSearchModel(updatedModel);
  };

  useEffect(() => {
    const loader = async () => {
      if (!provinces || provinces.length === 0) dispatch(fetchProvinces());

      if (!countries || countries.length === 0) dispatch(fetchCountries());

      if (!beeLevels || beeLevels.length === 0) dispatch(fetchBeeLevels());

    };

    loader();
  }, [isSouthAfricaSelected]);

  return (
    <WithRequiredPermission permission={PermissionClassification.Searchfilters}>
      <List
        sx={{
          borderLeft: "1px solid gainsboro",
          width: "100%",
          maxWidth: 360,
          marginTop: "50px",
          bgcolor: "background.paper",
          paddingRight: "10px",
        }}
        dense
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <h3 className="heading-sub-4">Filters</h3>
          </ListSubheader>
        }
      >
        {countries.length > 0 && (
          <MultiSelectFilter
            filterOptionsList={countries}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.CountryIds}
            filterName="Countries"
            callBack={setIsSouthAfricaSelected}
          />
        )}
        {provinces.length > 0 && isSouthAfricaSelected && (
          <MultiSelectFilter
            forceFilterSearchBox
            filterOptionsList={provinces}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.ProvinceIds}
            filterName="Provinces"
          />
        )}
        {companyTypes && isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={companyTypes ?? []}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.CompanyTypeIds}
            filterName="Company Types"
          />
        )}
        {beeLevels.length > 0 && isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={beeLevels}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.BeeLevelIds}
            filterName="B-BBEE Levels"
          />
        )}
        {ratingsList.length > 0 && (
          <SingleOptionSelectFilter
            filterOptionsList={ratingsList}
            updateFilterModel={updateSearchModelNum}
            searchModelProperty={SearchModelProperties.rating}
            filterName="Ratings"
          />
        )}
        {[NetconnSearchType.Supplier, NetconnSearchType.Client].includes(searchType) &&
          complianceTypes.length > 0 &&
          isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={complianceTypes}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.ComplianceStatusIds}
            filterName="Overall Compliance Status"
          />
        )}
        {searchType === NetconnSearchType.Supplier &&
          complianceTypes.length > 0 &&
          isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={complianceTypes}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.ComplianceStatusesCIPC}
            filterName="CIPC Compliance Status"
          />
        )}
        {searchType === NetconnSearchType.Supplier &&
          complianceTypes.length > 0 &&
          isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={complianceTypes}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.ComplianceStatusesSARS}
            filterName="SARS Compliance Status"
          />
        )}
        {searchType === NetconnSearchType.Supplier &&
          complianceTypes.length > 0 &&
          isSouthAfricaSelected && (
          <MultiSelectFilter
            filterOptionsList={complianceTypes}
            updateFilterModel={updateSearchModelNumArray}
            searchModelProperty={SearchModelProperties.ComplianceStatusesBEE}
            filterName="B-BBEE Status"
          />
        )}
      </List>
    </WithRequiredPermission>
  );
};

export default MainFilter;
