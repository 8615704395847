import { makePostRequestAsync, makePatchRequestAsync } from "../../Helpers/httpRequestHelpers";
import { UpdateValidationModel } from "./UpdateValidationModel";
import { AddValidationModel } from "./AddValidationModel";
import { FieldModel } from "../Fields/FieldModel";
import { protectedResources } from "../../../authConfig";

const baseurl = protectedResources.configurationWebApi.endpoint;
const scopes = protectedResources.configurationWebApi.scopes;

export const UpdateValidation = async (validationEntry: UpdateValidationModel) => {
  const url = `${baseurl}/api/Configuration/Validation/UpdateValidation`
  const result = await makePatchRequestAsync<void, UpdateValidationModel>(scopes, url, validationEntry)
  return result
}

export const AddValidation = async (validationEntry: AddValidationModel) => {
  const url = `${baseurl}/api/Configuration/Validation/AddValidation`;
  const result = await makePostRequestAsync<number, AddValidationModel>(scopes, url, validationEntry);
  return result.data;
}

export const UpdateField = async (fieldEntry: FieldModel) => {
  const url = `${baseurl}/api/Configuration/Fields/UpdateField`;
  const result = await makePatchRequestAsync<void, FieldModel>(scopes, url, fieldEntry);
  return result
}

export const DeleteValidation = async (id: number) => {
  const url = `${baseurl}/api/Configuration/Validation/Delete/${id}`;
  const result = await makePostRequestAsync<void, FieldModel>(scopes, url);
  return result
}
