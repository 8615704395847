import * as React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InviteBase from "./NewConnectionInvitations/InviteBase";
import DialogModal from "../../components/Dialogs/DialogModal";
import ConnectionType from "../../util/enums/ConnectionType";

interface AddNewConnectionButtonProps {
  padding?: number;
  children: React.ReactNode;
  connectionType?: ConnectionType;
}

const AddNewConnectionButton = ({
  padding = 2.5,
  children,
  connectionType = ConnectionType.Supplier, // default to Supplier
}: AddNewConnectionButtonProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        className="btn-black"
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
      >
        <Typography variant="button" p={padding}>
          {children}
        </Typography>
      </Button>
      <DialogModal
        isOpen={open}
        onHandleClose={() => setOpen(false)}
      >
        <InviteBase onHandleClose={() => setOpen(false)} connectionType={connectionType} />
      </DialogModal>
    </React.Fragment>
  );
};

AddNewConnectionButton.defaultProps = {
  padding: 2.5,
  connectionType: ConnectionType.Supplier,
};

export default AddNewConnectionButton;