import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import BasePage from "../BasePage";
import { AuditViewMessage } from "./AuditViewMessage";

import { downloadExcelExport, getAuditEntries } from "../../http/Audit/AuditApi";
import AuditTable from "../../components/Audit/AuditTable";
import EmailHistory from "../../components/Audit/EmailHistory";

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const AuditMain = () => {
  const [auditMessages, setAuditMessages] = useState<AuditViewMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const loader = async () => {
      try {
        setIsLoading(true);
        const result = await getAuditEntries();
        setAuditMessages(result);
        setIsLoading(false);
      }
      catch {
        setIsLoading(false);
      }
    }
    loader();
  }, []);

  const handleFileDownload = async () => {
    try {
      setIsLoading(true);
      await downloadExcelExport();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <BasePage pageHeader="Audit Inspector" pageHeaderSub="View system change logs">
      <LoadingBackdrop showBackdrop={isLoading} />
      <Box className="platformConfigurationBox">
        <AppBar className="app-bar connect" position="static">
          <Tabs
            value={value}
            onChange={(_ev, val: number) => handleChange(val)}
            orientation="horizontal"
            indicatorColor="secondary"
            textColor="inherit"
            aria-label=""
          >
            <Tab label="Audits" {...a11yProps(0)} className="app-bar-tabs" />
            <Tab label="Emails" {...a11yProps(1)} className="app-bar-tabs" />
          </Tabs>
        </AppBar>
        <CustomTabPanel value={value} index={0}>
          {
            auditMessages.length > 0 && (
              <Grid container justifyContent="flex-end">
                <Button onClick={handleFileDownload} variant="contained" sx={{ marginBottom: "7px" }}>
                  Export to Excel
                </Button>
              </Grid>
            )
          }
          <Box sx={{ width: "100%", height: "500px" }}>
            <AuditTable inputRows={auditMessages} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{ width: "100%", height: "500px" }}>
            <EmailHistory />
          </Box>
        </CustomTabPanel>
      </Box>
    </BasePage>
  )
}

export default AuditMain;