/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { SupplierLocationsModel } from "../../Company/Models/SupplierLocationsModel";


export interface SupplierLocationsState {
  supplierLocationsList: Array<SupplierLocationsModel>;
}

const supplierLocationsSlice = createSlice({
  name: "supplierLocationsList",
  initialState: [] as Array<SupplierLocationsModel>,
  reducers: {
    setSupplierLocationsState: (
      state,
      action: PayloadAction<Array<SupplierLocationsModel>>
    ) => action.payload,
  },
});

export const selectSupplierLocationsList = (state: RootState) => state.supplierLocationsList;
export default supplierLocationsSlice;