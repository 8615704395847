import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { getCompanyRequiredDocumentTypes } from "../../DocumentManagement/DocumentManagement";
import { DocumentTypeModel } from "../Models/DocumentTypeModel";
import documentTypeListSlice from "./DocumentTypesSlice";

export const DocumentTypeListActions = documentTypeListSlice.actions;

export const fetchDocumentTypes = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<DocumentTypeModel> = (await getCompanyRequiredDocumentTypes()).data;
  dispatch(DocumentTypeListActions.setDocumentTypeListState(apiResponse));
}