import { useState } from "react";
import { Grid, ListItemButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./css/WorkCenter.css";
import workCenterJson from "./WorkCenterTestItems.json";
import { WorkCenterInterface } from "./interfaces/WorkItems";

const WorkCenterCard = () => {
  const workItems: WorkCenterInterface[] = workCenterJson;
  const [rows] = useState<WorkCenterInterface[]>(workItems);
  const navigate = useNavigate();

  const handleMyWorkItemClick = (value: number) => () => {
    const template = `/workcenterview/${value}`;
    navigate(template);
  }
  return (
    <div>
      {rows.map((record) => (
        <Grid container spacing={4} key={record.id}>
          <Grid item xs={9}>
            <ListItemButton onClick={handleMyWorkItemClick(record.id)}>{record.heading}</ListItemButton>
          </Grid>
          <Grid item xs={1}>
            <p className="sidebar__statNumber">{record.value}</p>
          </Grid>
        </Grid>
      )
      )}
    </div>

  );
};


export default WorkCenterCard;