import { Skeleton } from "@mui/material";

const Redacted = ({ title, width }: { title: string, width: number }) => (
  <div style={{ width: "100%", height: "25px" }}>
    <div style={{ float: "left", width: "fit-content" }}><b>{title} </b></div>
    <div style={{ float: "left", width: "fit-content", paddingTop: 2, paddingLeft: 2 }}>
      <Skeleton variant="rounded" width={width} height={20} animation={false} />
    </div>
  </div>
)

export const ViewProfileFieldItem = ({ isConnected, title, value, alwayShow: dontBlur = false }: 
  // eslint-disable-next-line react/require-default-props
  { title: string, value: string, isConnected?: boolean, alwayShow?: boolean}) => (
  isConnected || dontBlur ?
    <><b>{title}:</b> {value}</> :
    <Redacted title={`${title}:`} width={250} />
)

export default Redacted;
