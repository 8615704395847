import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { NotificationEditModel } from "./Models/NotificationEditModel";
import { NotificationMessage } from "./Models/NotificationMessage";

import { NotificationModel } from "./Models/NotificationModel";

const apiScopes = protectedResources.notificationsWebApi.scopes;
const baseUrl = protectedResources.notificationsWebApi.endpoint;

/*
 *  Retrieves all notifications
 */
export async function GetAllNotifications() {
  const url = `${baseUrl}/api/Notifications/Notification`;
  return makeGetRequestAsync<Array<NotificationModel>>(apiScopes, url);
}

/*
 *  Retrieves a specific notification based on a passed notification ID
 */
export async function GetNotificationById(notificationId: number) {
  const url = `${baseUrl}/api/Notifications/Notification/${notificationId}`;
  return makeGetRequestAsync<NotificationModel>(apiScopes, url);
}

/*
 *  Retrieves all notifications based on a passed recipient account ID
 */
export async function GetNotificationsByRecipientAccountId(recipientAccountId: string, isRead: boolean) {
  const url = `${baseUrl}/api/Notifications/Notification/GetNotificationsByRecipientAccountId/${recipientAccountId}/${isRead}`;
  return (await makeGetRequestAsync<Array<NotificationModel>>(apiScopes, url)).data;
}

/*
 *  Retrieves all notifications based on a passed sender account ID
 */
export async function GetNotificationsBySenderAccountId(senderAccountId: string, isRead: boolean) {
  const url = `${baseUrl}/api/Notifications/Notification/GetNotificationsBySenderAccountId/${senderAccountId}/${isRead}`;
  return makeGetRequestAsync<Array<NotificationModel>>(apiScopes, url);
}

export const MarkNotificationAsRead = async (notification: NotificationEditModel) => {
  const url = `${baseUrl}/api/Notifications/Notification`;
  await makePatchRequestAsync<void, NotificationEditModel>(apiScopes, url, notification);
};

export const AddNewNotification = async (notification: NotificationMessage) => {
  const url = `${baseUrl}/api/Notifications/Notification/AddNotification`;
  await makePostRequestAsync(apiScopes, url, notification);
}
