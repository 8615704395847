import { useMutation, useQueryClient } from "@tanstack/react-query"
import queryKeys from "../queries/queryKeys";
import { AddNewBankAccount, DeleteBankAccount, UpdateBankAccount } from "../../../http/Company/bankingDetails";
import { BankAccountDetailsModel } from "../../../http/Company/Models/BankAccountDetailsModel";
import { ComplianceMutatorProps } from "./Interfaces/ComplianceMutatorProps";

const useCompanyBankingDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({mutationMode, mutationDetails, staged = false} : ComplianceMutatorProps) => {
      const bankObject = mutationDetails as BankAccountDetailsModel;

      if (mutationMode === "add") {
        await AddNewBankAccount(bankObject, staged);
        return;
      }

      if (mutationMode === "update") {
        await UpdateBankAccount(bankObject, staged);
        return;
      }

      await DeleteBankAccount(bankObject, staged);
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyBankingDetails] });

      // Snapshot the previous value
      const currentBankingDetails = queryClient.getQueryData([queryKeys.companyBankingDetails]);

      // Return a context object with the snapshotted value
      return { currentBankingDetails }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyBankingDetails], context?.currentBankingDetails);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyBankingDetails] });
    },
  })
}

export default useCompanyBankingDetailsMutation;