
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAccount, useMsal } from "@azure/msal-react";

import {
  Button,
  Container,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CompanyPublicProfileShareModel } from "../../http/Company/Models/CompanyPublicProfileShareModel";
import TagsInput from "../../util/functions/TagInput";
import { ProfileShares } from "../../http/Redux/Models/ProfileSharesModel";
import { useAppSelector } from "../../app/hooks";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import { AddSharedProfile } from "../../http/Company/company";
import { selectCompany } from "../../http/Redux/Store/companySlice";

const validationSchema = yup.object().shape({
  general: yup.boolean(),
  contacts: yup.boolean(),
  address: yup.boolean(),
  bbbeeDetails: yup.boolean(),
  documents: yup.boolean(),
  productsAndServices: yup.boolean(),
  bankDetails: yup.boolean(),
  compliance: yup.boolean(),
  additionalContacts: yup.boolean(),
  companyRating: yup.boolean(),
  userManagement: yup.boolean(),
  email: yup.boolean(),
  facebook: yup.boolean(),
  linkedin: yup.boolean(),
  whatsapp: yup.boolean(),
});

const PublicProfileSharing = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const companyId = account?.idTokenClaims?.extension_companyid;
  const [selectedItem, setSelectedItem] = useState([]);
  const userProfile: UserProfileModel = useAppSelector((state: {
    userProfile: { particularUserProfile: UserProfileModel };
  }) => state.userProfile.particularUserProfile);



  const company = useAppSelector(selectCompany);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const initialValues: CompanyPublicProfileShareModel = {
    companyId: companyId as number,
    general: true,
    productsAndServices: false,
    compliance: false,
    additionalContacts: false,
    bankDetails: false,
    companyRating: false,
    email: false,
  };

  const generateCode = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let code = "";

    // Generate 5 random letters
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      code += letters[randomIndex];
    }

    // Generate 4 random numbers
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 4; i++) {
      const randomNum = Math.floor(Math.random() * 10);
      code += randomNum;
    }

    return code;
  }



  const { values, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async () => {
      try {

        let list = "1,2,3,4,";

        if (values.productsAndServices) {
          list += "5,";
        }
        if (values.bankDetails) {
          list += "6,";
        }
        if (values.compliance) {
          list += "7,";
        }
        if (values.additionalContacts) {
          list += "8,"
        }
        if (values.companyRating) {
          list += "9,"
        }


        // await AddCompanyPublicProfileShare(values);
        const hash = uuidv4();
        const code = generateCode();
        const newModel: ProfileShares = {
          fromCompanyId: companyId as number,
          toRecipient: selectedItem.map(e => e).join(","),
          transportType: "Email",
          hashToken: hash,
          hashCode: code,
          url: `${process.env.REACT_APP_APPLICATION_URL}/ProfileShare/${code}`,
          expiryDate: new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)),
          isActive: true,
          createdBy: userAccount as string,
          modifedBy: userAccount as string,
          dateCreated: new Date(),
          dateModified: new Date(),
          sections: list,
          fromUserId: userProfile.id as number,
        };
        await AddSharedProfile(newModel, company.registeredName).finally(() => {
          toast.success("Successfully updated share public profile.");
        });


      } catch (error) {
        toast.error("Error updating share public profile.");
      }
    },
  });



  useEffect(() => {

    if (selectedItem.length === 0) {
      setDisableSubmit(true);
    }
    else {
      setDisableSubmit(false);
    }


  }, [companyId, selectedItem.length, setFieldValue]);

  const handleSelecetedTags = (items: []) => {
    setSelectedItem(items);
  }

  return (

    <Container sx={{ pb: 6, pt: 3 }} id="step-public">
      <Typography variant="h5" gutterBottom className="share-public-heading">
        Share My Company Profile
      </Typography>
      <Divider sx={{ my: 2 }} />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid xs={6}>
            <Typography variant="h6" gutterBottom>
              Shared Sections
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="general"
                    id="general"
                    value={values.general}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    checked
                  />
                }
                label="General"
              />
              <ul>
                <li style={{ color: "gray", marginLeft: 45 }}>Logo</li>
                <li style={{ color: "gray", marginLeft: 45 }}>General Contact</li>
                <li style={{ color: "gray", marginLeft: 45 }}>B-BBEE Details</li>
                <li style={{ color: "gray", marginLeft: 45 }}>Products & Services Description</li>
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    name="additionalContacts"
                    id="additionalContacts"
                    value={values.additionalContacts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.additionalContacts}
                  />
                }
                label="Additional Contacts"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="companyRating"
                    id="companyRating"
                    value={values.companyRating}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.companyRating}
                  />
                }
                label="Company Rating"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="bankDetails"
                    id="bankDetails"
                    value={values.bankDetails}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.bankDetails}
                  />
                }
                label="Bank Details"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="compliance"
                    id="compliance"
                    value={values.compliance}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.compliance}
                  />
                }
                label="Compliance"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="productsAndServices"
                    id="productsAndServices"
                    value={values.productsAndServices}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.productsAndServices}
                  />
                }
                label="Products and Services"
              />
            </FormGroup>
          </Grid>
          <Grid xs={6}>
            <Typography variant="h6" gutterBottom>
              Share Via
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    checked
                  />
                }
                label="E-mail"
              />
            </FormGroup>
          </Grid>
          <Grid xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Recipients: <small style={{ fontSize: "14px" }}><i>Use a semicolon <b>(;)</b> to add additional recipients.</i></small>
            </Typography>
            <TagsInput
              selectedTags={handleSelecetedTags}
              fullWidth
              variant="outlined"
              id="tags"
              name="tags"
              placeholder="Add Email"
              label="Recipients"
            />
            <Button type="submit" color="primary" variant="contained" fullWidth sx={{ mt: 3 }} disabled={disableSubmit}>
              SHARE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>

  );
};

export default PublicProfileSharing;
