import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  // MenuItem,
  TextField,
  styled,
} from "@mui/material";
import React from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentDtoModel } from "../../../../http/DocumentManagement/Models/DocumentDtoModel";
import { DocumentTypeModel } from "../../../../http/Redux/Models/DocumentTypeModel";
import DocumentType from "../../../../util/enums/DocumentType";

interface UploadDocumentDialogProps {
  open: boolean;
  documentTypes: Array<DocumentTypeModel>;
  selectedDocumentType: number;
  doesDocExpire: boolean;
  expiryDate: Dayjs | null;
  setShareDoc: boolean;

  // eslint-disable-next-line no-unused-vars
  setOpen: (display: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  handleExpiryDateChange: (newDate: Dayjs | null) => void;

  // eslint-disable-next-line no-unused-vars
  handleCompanyTypeChange: (newCompType: number) => void;

  // eslint-disable-next-line no-unused-vars
  handleSharedChange: (newShareVal: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  handleDoesDocExpireChange: (newDocExpiresVal: boolean) => void;

  confirmCallback: () => void;

  // eslint-disable-next-line no-unused-vars
  clearDocumentDtoArrayCallback: (docDtoArray: Array<DocumentDtoModel>) => void;

  // eslint-disable-next-line no-unused-vars
  clearUploadFileArrayCallback: (fileArray: Array<File>) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const UploadDocumentDialog = (props: UploadDocumentDialogProps) => {
  const {
    open,
    documentTypes,
    selectedDocumentType,
    doesDocExpire,
    expiryDate,
    setShareDoc,
    setOpen,
    handleCompanyTypeChange,
    handleExpiryDateChange,
    handleSharedChange,
    handleDoesDocExpireChange,
    confirmCallback,
    clearDocumentDtoArrayCallback,
    clearUploadFileArrayCallback,
  } = props;

  const HandleCancelLogic = () => {
    handleCompanyTypeChange(0);
    handleExpiryDateChange(null);
    handleSharedChange(false);
    clearDocumentDtoArrayCallback([] as Array<DocumentDtoModel>);
    clearUploadFileArrayCallback([] as Array<File>);
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BootstrapDialog
        onClose={HandleCancelLogic}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={HandleCancelLogic}>
          Upload Document
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DialogContentText>Please enter the following details:</DialogContentText>
          <Grid container xs={12}>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%", marginTop: "8px", marginBottom: "5px" }}>
                  <InputLabel sx={{ fontSize: "14px" }} id="upload-doctype-label">
                    Document Type
                  </InputLabel>
                  <Select
                    label="Document Type"
                    id="upload-doctype-select"
                    margin="dense"
                    inputProps={{ style: { fontSize: 14 } }}
                    value={selectedDocumentType || 0}
                    size="small"
                    onChange={(ev) => {
                      handleCompanyTypeChange(parseInt(ev.target.value.toString(), 10));
                    }}
                    input={<OutlinedInput label="Document Type" />}
                  >
                    <MenuItem value={0}>Please Select</MenuItem>
                    {documentTypes
                      .filter(
                        (docType) =>
                          docType.id !== DocumentType.SupplierList &&
                          docType.id !== DocumentType.ClientList
                      )
                      .map((docType) => (
                        <MenuItem key={docType.id} value={docType.id}>
                          {docType.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesDocExpire}
                        onChange={() => {
                          handleDoesDocExpireChange(!doesDocExpire);
                        }}
                      />
                    }
                    label="Does the document expire?"
                  />
                  <DesktopDatePicker
                    label="Expiry Date"
                    value={expiryDate}
                    disabled={!doesDocExpire}
                    onChange={handleExpiryDateChange}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={setShareDoc}
                        onChange={(ev) => {
                          handleSharedChange(ev.target.checked);
                        }}
                      />
                    }
                    label="Share document publicly?"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={HandleCancelLogic}>
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={selectedDocumentType === 0}
            onClick={() => {
              setOpen(false);
              confirmCallback();
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </LocalizationProvider>
  );
};

export default UploadDocumentDialog;
