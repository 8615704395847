import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { CompanyProfileViewModel } from "./Models/CompanyProfileViewModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export default async function GetCompanyProfileView(companyId: number, isConnected: boolean) {
  const url = `${baseUrl}/api/Company/CompanyProfileView/${companyId}/${isConnected}`;
  return makeGetRequestAsync<CompanyProfileViewModel>(apiScopes, url);
}