import { Grid, IconButton, TextField, Button, Tooltip, Box, Chip } from "@mui/material";
import { useState } from "react";
// import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { RoleMessage } from "../../http/CompanyUserManagement/Messages/RoleMessage";
import { UserInvitationMessage } from "../../http/CompanyUserManagement/Messages/UserInvitationMessage";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import MultiItemSelector from "../MultiItemSelector/MultiItemSelector";
import { useAppDispatch } from "../../app/hooks";
import { UpdateCompanyUserRoles } from "../../http/CompanyUserManagement/userManagementApi";
import fetchCurrentUserPermissions from "../../http/Redux/Store/userActions";

interface PropsEditUser {
  roles: RoleMessage[],
  currentRecord: UserInvitationMessage,
  backToView: () => void,
}



const EditUser = ({ roles, currentRecord, backToView }: PropsEditUser) => {
  const [loading, isLoading] = useState(false);
  const [textIsNotEditable] = useState(true);
  const [selectedRoles, setSelectedRoles] = useState<number[]>(currentRecord.roleIdList);
  const [showRoles, setShowRoles] = useState(false);
  const dispatch = useAppDispatch();


  const handleOnSaveClick = async () => {
    isLoading(true);
    if (!currentRecord?.userId) { return; }
    try {
      await UpdateCompanyUserRoles(
        {
          companyId: currentRecord.companyId,
          userId: currentRecord.userId,
          roleIdList: selectedRoles,
        })

      dispatch(fetchCurrentUserPermissions());


      isLoading(false);
      backToView();
      toast.success("User roles updated successfully");
    } catch (error) {
      toast.error("User roles updated failed");
      isLoading(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Users">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                disabled={textIsNotEditable}
                label="Name"
                size="small"
                type="text"
                sx={{ width: "98%" }}
                variant="outlined"
                name="firstName"
                value={currentRecord.firstName}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                disabled={textIsNotEditable}
                size="small"
                label="Surname"
                type="text"
                fullWidth
                variant="outlined"
                name="lastName"
                value={currentRecord.lastName}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <TextField
                autoFocus
                required
                size="small"
                disabled={textIsNotEditable}
                label="Email"
                type="email"
                sx={{ width: "98%" }}
                variant="outlined"
                name="email"
                value={currentRecord.email}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Contact number"
                disabled={textIsNotEditable}
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                name="mobileNumber"
                value={currentRecord.mobileNumber}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <Tooltip title="Select User Roles">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={() => setShowRoles(true)}>
                  <AddIcon /> Select Roles
                </Button>
              </Tooltip>
              {showRoles &&
                <MultiItemSelector
                  title="User Roles"
                  description="Please select a list of roles that will apply to this user"
                  hasSearchInput
                  onSubmitSelectionCallback={(permissionIdList) => { setSelectedRoles(permissionIdList); }}
                  handleClose={() => setShowRoles(false)}
                  optionsList={roles.map((x) => ({ id: x.id, displayValue: x.name }))}
                  currentSelectionArray={selectedRoles}
                />
              }
            </Grid>
            <Grid xs={6}>
              <Box> {roles.filter(r => selectedRoles.includes(r.id)).map((d) => (
                <Chip
                  key={d.id}
                  label={d.name}
                  sx={{ marginRight: 2, marginTop: 2 }}
                  className="chip-colored"
                  size="small"
                />
              ))}</Box>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" className="form-add-btn" onClick={() => handleOnSaveClick()}>Update</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

};

export default EditUser;

