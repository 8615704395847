import { protectedResources } from "../../authConfig";
import { FeaturePermissionsMessage } from "../../components/Configuration/Subscriptions/FeaturePermissionsMessage";
import makeGetRequestAsync, {
  makeDeleteRequestNoBodyAsync,
  makePUTRequestAsync, makePatchRequestAsync, makePostRequestAsync,
} from "../Helpers/httpRequestHelpers";
import { FeaturePermissionModel } from "../Redux/Models/FeaturePermissionModel";
import { ProductAddon } from "../Redux/Models/ProductAddon";
import { ProductFeatures } from "../Redux/Models/ProductFeatures";
import { ProductPlans } from "../Redux/Models/ProductPlans";
import { SubscriptionFeatureModel } from "../Redux/Models/SubscriptionFeatureModel";
import { SubscriptionPlanModel } from "../Redux/Models/SubscriptionPlanModel";
import { SubscriptionProductAddonsModel } from "../Redux/Models/SubscriptionProductAddonsModel";
import { SubscriptionProductFeaturesModel } from "../Redux/Models/SubscriptionProductFeaturesModel";
import { SubscriptionProductModel } from "../Redux/Models/SubscriptionProductModel";
import { SubscriptionProductPlansModel } from "../Redux/Models/SubscriptionProductPlansModel";
import { SubscriptionVoucherModel } from "../Redux/Models/SubscriptionVoucherModel";
import { SubscriptionVoucherPlansModel } from "../Redux/Models/SubscriptionVoucherPlansModel";
import { CompanySubscription, CompanySubscriptionMessage } from "./Models/CompanySubscription";
import { CreateCompanySubscriptionMessage } from "./Messages/CreateCompanySubscriptionMessage";
import { HasSubscriptionModel } from "./Models/HasSubscriptionModel";
import { VoucherMessage } from "./Messages/VoucherMessage";
import { BaseSubscriptionInfoModel } from "./Models/BaseSubscriptionInfoModel";
import { UpgradeSubscriptionReturnMessage } from "./Messages/UpgradeSubscriptionReturnMessage";
import { SubscriptionBillingHistoryMessage } from "./Messages/SubscriptionBillingHistoryMessage";
import { PackageMatrixModel } from "../Redux/Models/PackageMatrixModel";

const apiScopes = protectedResources.subscriptionWebApi.scopes;
const baseUrl = protectedResources.subscriptionWebApi.endpoint;

export default {
  async getAllProducts() {
    const url = `${baseUrl}/api/Subscription/Product/Products`;
    return (await (makeGetRequestAsync<Array<SubscriptionProductModel>>(apiScopes, url))).data
  },
  async getAllAddons(productId: number) {
    const url = `${baseUrl}/api/Subscription/ProductAddons/${productId}`;
    return (await (makeGetRequestAsync<Array<SubscriptionProductAddonsModel>>(apiScopes, url))).data
  },
  async getPackageMatrices() {

    const url = `${baseUrl}/api/Subscription/Plan/PackageMatrix`;
    return (await makeGetRequestAsync<PackageMatrixModel[]>(apiScopes, url)).data;
  },
  async getAllFeatures() {
    const url = `${baseUrl}/api/Subscription/Feature`;
    return (await (makeGetRequestAsync<Array<SubscriptionFeatureModel>>(apiScopes, url))).data
  },
  async getAllFeaturePermissions(featureId: number) {
    const url = `${baseUrl}/api/Subscription/FeaturePermissions/${featureId}`;
    return (await (makeGetRequestAsync<Array<FeaturePermissionModel>>(apiScopes, url))).data
  },
  async getAllPlans() {
    const url = `${baseUrl}/api/Subscription/Plan`;
    return (await (makeGetRequestAsync<Array<SubscriptionPlanModel>>(apiScopes, url))).data
  },
  async getAllVouchers() {
    const url = `${baseUrl}/api/Subscription/Voucher`;
    return (await (makeGetRequestAsync<Array<SubscriptionVoucherModel>>(apiScopes, url))).data
  },
  async getAllVoucherPlans(id: number) {
    const url = `${baseUrl}/api/Subscription/VoucherPlans/?id=${id}`;
    return (await (makeGetRequestAsync<Array<SubscriptionVoucherPlansModel>>(apiScopes, url))).data
  },
  async getAllProductPlans(id: number) {
    const url = `${baseUrl}/api/Subscription/ProductPlans/?id=${id}`;
    return (await (makeGetRequestAsync<Array<SubscriptionProductPlansModel>>(apiScopes, url))).data
  },
  async getAllProductFeatures(id: number) {
    const url = `${baseUrl}/api/Subscription/ProductFeatures/?id=${id}`;
    return (await (makeGetRequestAsync<Array<SubscriptionProductFeaturesModel>>(apiScopes, url))).data
  },
  async addProduct(subsriptionProduct: SubscriptionProductModel) {
    const url = `${baseUrl}/api/Subscription/Product`;
    return (await (makePostRequestAsync(apiScopes, url, subsriptionProduct))).data;
  },
  async addFeature(subscriptionFeature: SubscriptionFeatureModel) {
    const url = `${baseUrl}/api/Subscription/Feature`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionFeature))).status;
  },
  async addPlan(subscriptionPlan: SubscriptionPlanModel) {
    const url = `${baseUrl}/api/Subscription/Plan`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionPlan))).status;
  },
  async addVoucher(subscriptionVoucher: SubscriptionVoucherModel) {
    const url = `${baseUrl}/api/Subscription/Voucher`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionVoucher))).status;
  },
  async addProductFeatures(subscriptionProductFeatures: ProductFeatures) {
    const url = `${baseUrl}/api/Subscription/ProductFeatures`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionProductFeatures))).status;
  },
  async addProductAddon(subscriptionProductAddon: ProductAddon) {
    const url = `${baseUrl}/api/Subscription/ProductAddons`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionProductAddon))).status;
  },
  async updateFeaturePermissions(message: FeaturePermissionsMessage) {
    const url = `${baseUrl}/api/Subscription/FeaturePermissions`;
    await makePatchRequestAsync(apiScopes, url, message);
  },
  async addProductPlans(subscriptionProductPlans: ProductPlans) {
    const url = `${baseUrl}/api/Subscription/ProductPlans`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionProductPlans))).status;
  },
  async addVoucherPlans(subscriptionVoucherPlans: SubscriptionVoucherPlansModel) {
    const url = `${baseUrl}/api/Subscription/VoucherPlans`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionVoucherPlans))).status;
  },
  async updateProduct(subsriptionProduct: SubscriptionProductModel) {
    const url = `${baseUrl}/api/Subscription/Product`;
    return (await (makePatchRequestAsync(apiScopes, url, subsriptionProduct))).status;
  },
  async updateFeature(subscriptionFeature: SubscriptionFeatureModel) {
    const url = `${baseUrl}/api/Subscription/Feature`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionFeature))).status;
  },
  async updatePlan(subscriptionPlan: SubscriptionPlanModel) {
    const url = `${baseUrl}/api/Subscription/Plan`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionPlan))).status;
  },
  async updateVoucher(subscriptionVoucher: SubscriptionVoucherModel) {
    const url = `${baseUrl}/api/Subscription/Voucher`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionVoucher))).status;
  },
  async updateProductFeatures(subscriptionProductFeatures: SubscriptionProductFeaturesModel) {
    const url = `${baseUrl}/api/Subscription/ProductFeatures`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionProductFeatures))).status;
  },
  async updateProductPlans(subscriptionProductPlans: SubscriptionProductPlansModel) {
    const url = `${baseUrl}/api/Subscription/ProductPlans`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionProductPlans))).status;
  },
  async updateVoucherPlans(subscriptionVoucherPlans: SubscriptionVoucherPlansModel) {
    const url = `${baseUrl}/api/Subscription/VoucherPlans`;
    return (await (makePatchRequestAsync(apiScopes, url, subscriptionVoucherPlans))).status;
  },
  async deleteProduct(subsriptionProduct: SubscriptionProductModel) {
    const url = `${baseUrl}/api/Subscription/Product?id=${subsriptionProduct.id}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteFeature(subscriptionFeature: SubscriptionFeatureModel) {
    const url = `${baseUrl}/api/Subscription/Feature?id=${subscriptionFeature.id}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deletePlan(subscriptionPlan: SubscriptionPlanModel) {
    const url = `${baseUrl}/api/Subscription/Plan`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionPlan))).status;
  },
  async deleteVoucher(subscriptionVoucher: SubscriptionVoucherModel) {
    const url = `${baseUrl}/api/Subscription/Voucher`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionVoucher))).status;
  },
  async deleteProductFeatures(productFeatureId: number) {
    const url = `${baseUrl}/api/Subscription/ProductFeatures?id=${productFeatureId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteAllProductFeatures(productId: number) {
    const url = `${baseUrl}/api/Subscription/ProductFeatures/DeleteAllProductFeatures?id=${productId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteFeaturePermission(Id: number) {
    const url = `${baseUrl}/api/Subscription/FeaturePermissions?id=${Id}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteProductAddons(productAddonId: number) {
    const url = `${baseUrl}/api/Subscription/ProductAddons?id=${productAddonId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteAllProductAddons(productId: number) {
    const url = `${baseUrl}/api/Subscription/ProductAddons/DeleteAllProductAddons?id=${productId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteProductPlans(productPlanId: number) {
    const url = `${baseUrl}/api/Subscription/ProductPlans?id=${productPlanId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteAllProductPlans(productId: number) {
    const url = `${baseUrl}/api/Subscription/ProductPlans/DeleteAllProductPlans?id=${productId}`;
    return (await (makeDeleteRequestNoBodyAsync(apiScopes, url))).status;
  },
  async deleteVoucherPlans(subscriptionVoucherPlans: SubscriptionVoucherPlansModel) {
    const url = `${baseUrl}/api/Subscription/VoucherPlans`;
    return (await (makePostRequestAsync(apiScopes, url, subscriptionVoucherPlans))).status;
  },
  async getHasSubscription() {
    const url = `${baseUrl}/api/subscription/CompanySubscription/HasSubscription`;
    return (((await (makeGetRequestAsync<HasSubscriptionModel[]>(apiScopes, url)))?.data))
  },
}

export const getBaseSubscriptionInfo = async () => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/GetBaseSubscriptionInfo`;
  return (((await (makeGetRequestAsync<BaseSubscriptionInfoModel>(apiScopes, url)))?.data))
}

export const createCompanySubscription = async (data: CreateCompanySubscriptionMessage) => {
  const url = `${baseUrl}/api/subscription/CompanySubscription`;
  return (((await (makePostRequestAsync<CompanySubscription, CreateCompanySubscriptionMessage>(apiScopes, url, data))).data))
};

export const getActiveCompanySubscription = async () => {
  const url = `${baseUrl}/api/subscription/CompanySubscription`;
  return (((await (makeGetRequestAsync<CompanySubscriptionMessage | undefined>(apiScopes, url)))?.data))
};

export const getIsFree = async (companyId: number) => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/IsFree/${companyId}`;
  return (((await (makeGetRequestAsync<boolean>(apiScopes, url)))?.data))
};

export const getAvailableVouchers = async (associatedPlans: number[]) => {
  const queryString = associatedPlans.map(p => `associatedPlans=${p}`).join("&");
  const url = `${baseUrl}/api/subscription/voucher/available?${queryString}`;
  return (((await (makeGetRequestAsync<VoucherMessage[]>(apiScopes, url)))?.data))
};

export const getRedeemableVoucher = async (voucherCode: string, associatedPlans: number[]) => {
  const queryStrArray = associatedPlans.map(p => `associatedPlans=${p}`).join("&");
  const queryString = `voucherCode=${voucherCode}&${queryStrArray}`
  const url = `${baseUrl}/api/subscription/voucher/redeem?${queryString}`;
  return (((await (makeGetRequestAsync<VoucherMessage>(apiScopes, url)))?.data))
};

export const cancelOngoingSubscription = async () => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/cancel`;
  const result = await (makeDeleteRequestNoBodyAsync<CompanySubscription>(apiScopes, url));
  return result.data;
};

export const getCurrentSubscriptionPermissions = async () => {
  const url = `${baseUrl}/api/subscription/FeaturePermissions/currentSubscription`;
  const result = await (makeGetRequestAsync<number[]>(apiScopes, url));
  return result.data;
};

export const getUpgradeProrataFee = async (message: CreateCompanySubscriptionMessage) => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/prorata`;
  const result = await (
    makePostRequestAsync<number, CreateCompanySubscriptionMessage>(apiScopes, url, message)
  );
  return result.data;
};

export const addPendingUpgradeSubscription = async (data: CreateCompanySubscriptionMessage) => {
  const url = `${baseUrl}/api/subscription/CompanySubscription`;
  return (((await (makePUTRequestAsync<UpgradeSubscriptionReturnMessage, CreateCompanySubscriptionMessage>(apiScopes, url, data))).data))
};

export const getSubscriptionBillingHistory = async () => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/history`;
  return (((await (makeGetRequestAsync<SubscriptionBillingHistoryMessage[]>(apiScopes, url))).data))
};

export const signUpToTheFreeTier = async () => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/freeTier`;
  return (await makePostRequestAsync<void, void>(apiScopes, url)).data;
};

export const downgradeSubscription = async (newPlanId: number) => {
  const url = `${baseUrl}/api/subscription/CompanySubscription/downgrade/${newPlanId}`;
  return (await makePUTRequestAsync<CompanySubscription, null>(apiScopes, url, null)).data as CompanySubscription;
};


