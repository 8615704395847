import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useParams } from "react-router-dom";
import BasePage from "../BasePage";
import { responsiveFontSizes } from "../../../util/MUI_Theme/Theme";
import CustomTabPanel, { a11yProps } from "../Components/CustomTabPanel";
import { MyConnections, MyConnectionsStringToTabs, MyConnectionsTabs } from "../../../util/enums/MyConnectionsTab";
import ConnectionContainer from "./ConnectionTab";
import ConnectionRequest from "../../../components/Connect/ConnectionRequests/ConnectionRequest";



const BaseConnections = () => {
  const { tab } = useParams() || "";
  const [value, setValue] = useState(MyConnectionsStringToTabs(tab as MyConnections));
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePage>
      <Grid container sx={{ width: "100%" }} spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ ...responsiveFontSizes({ lg: 35, md: 32, sm: 28 }) }}>MY CONNECTIONS</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%", height: "fit-content" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", borderBottomColor: "transparent" }} className="tabs-dashboard">
              <Tabs value={value} onChange={handleChange} aria-label="dashboard stat tabs">
                <Tab sx={{ minWidth: "10%" }} label="MY CLIENTS" {...a11yProps(0)} />
                <Tab sx={{ minWidth: "10%" }} label="MY SUPPLIERS" {...a11yProps(1)} />
                <Tab sx={{ minWidth: "10%" }} label="CONNECTION REQUESTS" {...a11yProps(2)} />
                <Tab sx={{ minWidth: "10%" }} label="INVITED CONNECTIONS" {...a11yProps(3)} />
              </Tabs>
            </Box>
            {/* My Clients */}
            <CustomTabPanel value={value} index={0}>
              <ConnectionContainer tab={MyConnectionsTabs(value)} />
            </CustomTabPanel>
            {/* My Suppliers */}
            <CustomTabPanel value={value} index={1}>
              <ConnectionContainer tab={MyConnectionsTabs(value)} />
            </CustomTabPanel>
            {/* ConnectionRequests */}
            <CustomTabPanel value={value} index={2}>
              <ConnectionRequest tab={tab as string} />
            </CustomTabPanel>
            {/* Invited Connections */}
            <CustomTabPanel value={value} index={3}>
              <ConnectionContainer tab={MyConnectionsTabs(value)} />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </BasePage>
  )
};


export default BaseConnections;