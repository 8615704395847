import axios, { AxiosError, AxiosResponse } from "axios";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, {
  makeDeleteRequestNoBodyAsync,
  makePostRequestAsync,
  makePUTRequestAsync,
} from "../Helpers/httpRequestHelpers";
import { CompanySpecificSupplierDetailModel } from "./Models/CompanySpecificClientDetailModel";
import { ExclusionReasonModel } from "./Models/ExclusionReasonModel";
import { VendorNumberDto } from "./Models/VendorNumberDto";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export const getCompanySpecificSupplierDetail = async (
  clientCompanyId: number,
  supplierCompanyId: number,
  staged: boolean
) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/GetSpecificSupplierDetailRecord/${clientCompanyId}/${supplierCompanyId}/${staged}`;
  const response: CompanySpecificSupplierDetailModel =
    ((await (await makeGetRequestAsync(apiScopes, url)).data) as unknown as CompanySpecificSupplierDetailModel) ||
    ({} as CompanySpecificSupplierDetailModel);
  return response;
};

export const saveSupplierVendorNumber = async (vendorData: VendorNumberDto, staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/SaveUpdateVendorNumber/${staged}`;
  const result = await makePostRequestAsync<number, VendorNumberDto>(apiScopes, url, vendorData);
  return result.status;
};

export const doesRecordExist = async (clientCompanyId: number, supplierCompanyId: number, staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/DoesRecordAlreadyExist/${clientCompanyId}/${supplierCompanyId}/${staged}`;
  try {
    const result = await makeGetRequestAsync(apiScopes, url);

    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      return axiosError.response as AxiosResponse;
    }

    return {
      data: "An unexpected error occurred. Please try again, or contact support.",
      status: 500,
    } as AxiosResponse;
  }
};

export const doesVendorNumberExist = async (supplierCompanyId: number, vendorNumber: string, staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/DoesVendorNumberAlreadyExist/${supplierCompanyId}/${vendorNumber}/${staged}`;
  try {
    const result = await makeGetRequestAsync(apiScopes, url);

    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      return axiosError.response as AxiosResponse;
    }

    return {
      data: "An unexpected error occurred. Please try again, or contact support.",
      status: 500,
    } as AxiosResponse;
  }
};

export const addCompanySpecificSupplierInfo = async (dataToSave: CompanySpecificSupplierDetailModel, staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/AddCompanySpecificSupplierDetails/${staged}`;
  const result = await makePUTRequestAsync<null, CompanySpecificSupplierDetailModel>(
    apiScopes,
    url,
    dataToSave
  );
  return result.status;
};

export const updateCompanySpecificSupplierInfo = async (
  dataToSave: CompanySpecificSupplierDetailModel,
  staged: boolean
) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/UpdateCompanySpecificSupplierDetails/${staged}`;
  const result = await makePostRequestAsync<null, CompanySpecificSupplierDetailModel>(
    apiScopes,
    url,
    dataToSave
  );
  return result.status;
};

export const deleteCompanySpecificSupplierInfo = async (supplierDetailId: number, staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/DeleteCompanySpecificSupplierDetails/${supplierDetailId}/${staged}`;
  const result = await makeDeleteRequestNoBodyAsync(apiScopes, url);
  return result.status;
};

export const getExclusionReasons = async (staged: boolean) => {
  const url = `${baseUrl}/api/Company/CompanySpecificSupplierDetails/GetExclusionReasons/${staged}`;
  const response: Array<ExclusionReasonModel> = (await (
    await makeGetRequestAsync(apiScopes, url)
  ).data) as unknown as Array<ExclusionReasonModel>;
  return response;
};

export default saveSupplierVendorNumber;
