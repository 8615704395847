import { Button, Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddCompanyVerificationModel } from "../../../http/Verification/AddCompanyVerifcationModel";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import SectionValidationStatus, { sectionValidationStatusToString } from "../../../util/enums/SectionValidationStatus";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useAllowedPermission from "../../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import { CompanyVerificationModel } from "../../../http/Redux/Models/CompanyVerificationModel";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import DataSourceTypes from "../../../util/enums/DataSourceTypes";
import { CompanyProfileSectionsToType } from "../../../util/enums/CompanyProfileSections";
import formatDate from "../../../util/formatter/formatDate";
import { getCompanyVerifications } from "../../../http/Redux/Store/CompanyVerificationSlice";
import { fetchCompanyVerifications } from "../../../http/Redux/Store/CompanyVerificationActions";





const VerificationSection = ({ section, companyId, staged, agentTaskId }: { section: string, companyId: number, staged: boolean, agentTaskId: number }) => {

  // eslint-disable-next-line no-unused-vars
  const [listValueStatus, setListValueStatus] = useState("");
  const [editable] = useState<AddCompanyVerificationModel>({} as AddCompanyVerificationModel);
  const [lastRequest, setLastRequest] = useState<CompanyVerificationModel>({} as CompanyVerificationModel);
  const [comment, setComment] = useState<string>("");
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const dispatch = useAppDispatch();
  const [isAdded, setIsAdded] = useState(0);
  const allCompanyVerifications = useAppSelector(getCompanyVerifications);
  const hasAllowedPermission = useAllowedPermission();
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useAppSelector(getUserProfile);
  const [hasStatusNotSelected, setHasStatusNotSelected] = useState(true);
  const [isNull, setIsNull] = useState(true);
  const [isNotClaimed, setIsNotClaimed] = useState<boolean>(true);

  const handleChangeStatus = (event: string) => {
    setListValueStatus(event);
    setHasStatusNotSelected(false);
  };

  const handleValidate = () => {
    setIsLoading(true);

    const updateRecord: AddCompanyVerificationModel = editable;
    updateRecord.comment = comment;
    updateRecord.pageSection = CompanyProfileSectionsToType(section);
    updateRecord.agentTaskId = agentTaskId;
    updateRecord.validationDate = new Date();
    if (listValueStatus === "2") {
      updateRecord.valid = true;
    }
    else {
      updateRecord.valid = false;
    }


    const loader = async () => {
      const result = await CompanyVerificationService.addCompanyVerification(updateRecord, staged);

      const data = await CompanyVerificationService.getAgentTaskList(userProfile.id as number);
      const foundTask = data.find(x => x.agentTaskId === agentTaskId) as AgentTaskListGridModel;

      const updateTask: AgentTaskModel = {} as AgentTaskModel;
      updateTask.id = agentTaskId;
      updateTask.companyId = foundTask.companyId;
      updateTask.status = AgentTaskStatusTypes.InProgress;
      updateTask.comment = foundTask.comment;
      updateTask.agentCompanyId = foundTask.agentCompanyId;
      updateTask.agentUserId = foundTask.agentUserId;
      updateTask.clientId = foundTask.clientId;
      updateTask.createdBy = foundTask.createdBy;
      updateTask.dateCreated = foundTask.dateCreated;
      updateTask.expectedNextUpdateDate = foundTask.expectedNextUpdateDate;
      updateTask.pageSource = foundTask.pageSource;
      updateTask.dataSource = foundTask.dataSource === "Platform" ? DataSourceTypes.Platform : DataSourceTypes.Staged;
      await CompanyVerificationService.updateAgentTask(updateTask, staged).finally(() => {
        setIsLoading(false);
      });

      if (result !== 0) {
        setIsAdded(200);
      }
      else {
        setIsAdded(500);
      }

    };

    loader();
  };


  useEffect(() => {
    if (agentTaskId === 0) {
      setIsNotClaimed(true);
    }
    else {
      setIsNotClaimed(false);
    }
  }, [agentTaskId])



  useEffect(() => {


    if (allCompanyVerifications.length !== 0) {

      if (allCompanyVerifications.find(x => x.pageSection === CompanyProfileSectionsToType(section))) {
        const topDate: CompanyVerificationModel = allCompanyVerifications.find(x => Math.max.apply(formatDate(x.validationDate)) &&
          x.pageSection === CompanyProfileSectionsToType(section)) as CompanyVerificationModel;
        setIsNull(false);
        setLastRequest(topDate);
      }
    }

    if (isAdded === 200) {

      toast.success(`${section} Completed Successfully`);
      setIsLoading(false);
      setIsAdded(0);
      const loader = async () => {
        dispatch(fetchCompanyVerifications(companyId, staged));
      }
      loader();
    }
    else if (isAdded === 500) {
      toast.error(`${section} Failed to Complete`);
      setIsLoading(false);
      setIsAdded(0);
    }

  }, [timeVal, hasStatusNotSelected, isAdded, section, dispatch, companyId, staged, allCompanyVerifications.length]);


  return (
    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) ?
        <Grid container>
          {!isNotClaimed &&
            <>
              <Grid xs={6}>
                <h3 style={{ color: "rgba(0, 0, 0, 0.54)" }}>Validation Section</h3>
              </Grid>
              <Grid xs={6}>
                {!isNull && (
                  <p className="last-verification">
                    {lastRequest?.valid ? "Valid" : "Not Valid"} updated on {formatDate(lastRequest?.validationDate)} by {lastRequest.agentName}
                  </p>
                )}
              </Grid>
              <div className="hr-line-dashed" />
              <Grid xs={6}>

                <TextField
                  label="Status"
                  select
                  size="small"
                  sx={{ width: "98%" }}
                  onChange={(ev) => handleChangeStatus(ev.target.value)}
                >
                  {Object.keys(SectionValidationStatus).filter(x => x === "2" || x === "3").map((name) => (
                    <MenuItem key={Number(name)} value={Number(name)}>
                      {sectionValidationStatusToString(Number(name))}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>
              <Grid xs={6}>
                <TextField
                  variant="outlined"
                  label="Comment"
                  id="comment"
                  sx={{ width: "100%" }}
                  onChange={(ev) => { setComment(ev.target.value); }}
                  multiline
                  size="small"
                  maxRows={5}
                  type="text"
                  fullWidth />
              </Grid>

              <Grid xs={12} className="verification-grid">
                <Button className="btn-validate" disabled={hasStatusNotSelected} onClick={handleValidate} variant="contained" >Complete Section</Button>
              </Grid>

            </>
          }
        </Grid>
        : ""
      }
    </div>
  )


}

export default VerificationSection;