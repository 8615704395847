/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { colorPrimaryActive, registrationPageGrey } from "../../../util/MUI_Theme/Theme";


interface RegistrationBase {
  title: string,
  stepCount: number,
  children: ReactNode,
  isFinalPage: boolean,
}

const BaseRegistrationPage = ({ title, stepCount,isFinalPage, children }: RegistrationBase) => {
  const displayStepCount = stepCount <= 9 ? `0${stepCount}` : stepCount;
  const nextStep = stepCount + 1;
  const displayNextStepCount = nextStep <= 9 ? `0${nextStep}` : nextStep;
  return (
    <Container disableGutters maxWidth={false} style={{ width: "100%", overflow: "hidden" }}>
      <Grid container py={2} my={3}
        columnSpacing={2}
        alignItems="center"
      >
        <Grid item sm={3} md={2}
          borderRadius="10px"
          display="flex" justifyContent="center"
          sx={{ backgroundColor: registrationPageGrey }}
          py={2}
        >
          <Typography variant="pageTitle" color={colorPrimaryActive}>STEP {displayStepCount}</Typography>
        </Grid>
        <Grid item sm={6} md={8}>
          <Typography variant="largeHeading">{title}</Typography>
        </Grid>
        {
          isFinalPage === false &&
          <Grid item sm={3} md={2} display="flex" justifyContent="space-evenly">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="pageTitle" color={colorPrimaryActive}>
                STEP {displayNextStepCount}
              </Typography>
              <ArrowRightAltIcon color="primary" fontSize="large" />
            </div>
          </Grid>
        }
        
      </Grid>
      <Box mx={5} px={5}>
        {children}
      </Box>
    </Container>
  )
}
export default BaseRegistrationPage;
