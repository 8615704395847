import React, { forwardRef, useImperativeHandle } from "react";
import { Box } from "@mui/material";
import {
  IRegistrationStepPageComponent,
  RegistrationStepResponse,
} from "./IRegistrationStepComponent";
import { DocumentDtoModel } from "../../../http/DocumentManagement/Models/DocumentDtoModel";
import SupportingDocuments from "../../NewUI/ProfileComponents/SupportingDocuments";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import RegistrationSkeleton from "../Sections/RegistrationSkeleton";
import useCompanyDocuments from "../../../util/hooks/queries/useCompanyDocuments";

export interface CompanyProfileSettings {
  id: number;
  companyId: number;
  hasBeeEnabled: boolean;
  hasTaxEnabled: boolean;
  hasVatEnabled: boolean;
  hasCoidaEnabled: boolean;
  hasBankAccountDetailsEnabled: boolean;
  postalSameAsPhysical: boolean;
}

const SupportingDocumentsStepFour = forwardRef<IRegistrationStepPageComponent, unknown>((_props, ref) => {
  const { data: baseCompanyInfo } = useBaseCompanyInfo();

  // If you're registering, you're always looking at your profile.
  const { data: documents = [] as Array<DocumentDtoModel>, isLoading } = useCompanyDocuments(baseCompanyInfo?.companyId || 0, true);

  // const [companyProfileSettings, setCompanyProfileSettings] = useState<CompanyProfileSettings>({
  //   id: 0,
  //   companyId: 0,
  //   hasBeeEnabled: false,
  //   hasTaxEnabled: false,
  //   hasVatEnabled: false,
  //   hasCoidaEnabled: false,
  //   hasBankAccountDetailsEnabled: false,
  //   postalSameAsPhysical: false,
  // } as CompanyProfileSettings);

  // const fetchCompanyProfileSettings = useCallback(async (companyId: number) => {
  //   const results = (await GetCompanyProfileSettingsByCompanyId(companyId)).data;
  //   setCompanyProfileSettings(results);
  // }, []);

  // useEffect(() => {
  //   if (baseCompanyInfo?.companyId) {
  //     fetchCompanyProfileSettings(baseCompanyInfo.companyId);
  //   }
  // }, [baseCompanyInfo, fetchCompanyProfileSettings]);

  // const filterDocuments = () => {
  //   const allowedDocTypes = [1];

  //   if (companyProfileSettings.hasBeeEnabled) allowedDocTypes.push(9);
  //   if (companyProfileSettings.hasTaxEnabled) allowedDocTypes.push(7);
  //   if (companyProfileSettings.hasVatEnabled) allowedDocTypes.push(8);
  //   if (companyProfileSettings.hasCoidaEnabled) allowedDocTypes.push(14);
  //   if (companyProfileSettings.hasBankAccountDetailsEnabled) allowedDocTypes.push(5);

  //   return documents.filter(doc => allowedDocTypes.includes(doc?.docTypeId || 0));
  // };

  const onSubmitHandler = async () => {
    await new Promise(resolve => setTimeout(resolve, 100)); // eslint-disable-line no-promise-executor-return
    const response: RegistrationStepResponse = {
      submittedSuccessfully: true,
      errorMessages: [],
    };

    return response;
  };

  const onSaveAndContinueHandler = async () => {
    await new Promise(resolve => setTimeout(resolve, 100)); // eslint-disable-line no-promise-executor-return
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };
  };

  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));

  if (isLoading) return <RegistrationSkeleton />;

  return (
    <Box sx={{ margin: "20px" }}>
      <SupportingDocuments
        companyId={baseCompanyInfo?.companyId}
        isRegistration
        documentList={documents}
        connected
      />
    </Box>
  );
});

export default SupportingDocumentsStepFour;
