import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { Routes, Route, Navigate } from "react-router-dom";
import Notifications from "./pages/Notifications/NotificationsView";
import { Main } from "./Main";

import "./App.css";
import CompanyProfile from "./pages/Company/CompanyProfile";
import WorkCentreCompanyProfile from "./pages/WorkCenter/WorkCentreCompanyProfile";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  GetBaseCompanyInfoViaCompanyId,
  GetBaseCompanyInfoViaUserId,
} from "./http/Company/company";
import WorkCenterView from "./components/WorkCenter/WorkCenterView";
import CompanyNetworkMain from "./pages/Network/CompanyNetworkMain";
import AccountPreferences from "./pages/AccountPreferences/AccountPreferences";
import { fetchValidations } from "./http/Redux/Store/ValidationActions";
import { fetchFields } from "./http/Redux/Store/FieldActions";
import { fetchPermissions } from "./http/Redux/Store/PermissionActions";
import {
  fetchParticularProfileImage,
  fetchParticularUserProfile,
  fetchParticularUserRole,
} from "./http/Redux/Store/UserProfileActions";
import fetchCurrentUserPermissions from "./http/Redux/Store/userActions";
import {
  selectBaseCompanyInfo,
  setBaseCompanyInfoState,
} from "./http/Redux/Store/baseCompanyInfoSlice";
import { BaseCompanyInfo } from "./http/Company/Models/BaseCompanyInfo";
import {
  fetchParticularCompanyProfile,
  fetchParticularCompanyProfileImage,
} from "./http/Redux/Store/CompanyListActions";
import { fetchCountries } from "./http/Redux/Store/CountryListActions";
import { selectCountryList } from "./http/Redux/Store/CountryListSlice";
import { fetchPackageMatrices, fetchUserTourActivity } from "./http/Redux/Store/UserTourActivityActions";
import { fetchUserPromptActivity } from "./http/Redux/Store/UserPromptActivityActions";
import SubscriptionsView from "./components/Subscriptions/SubscriptionsView";

import {
  fetchCurrentSubscription,
  fetchCurrentSubscriptionPermissions,
  fetchHasSubscriptions,
  fetchPlans,
} from "./http/Redux/Store/SubscriptionActions";
import { getUserProfile } from "./http/Redux/Store/UserProfileSlice";
import ChatView from "./pages/ChatView";
import { fetchCompanyBeeGrouping } from "./http/Redux/Store/CompanyBeeGroupAction";
import AuthenticatePage from "./components/AuthenticatePage";
import PermissionClassification from "./util/enums/PermissionClassification";
import { getUserPromptActivity } from "./http/Redux/Store/UserPromptActivitySlice";
import { UserPromptActivityModel } from "./http/Redux/Models/UserPromptActivityModel";
import PromptStatus from "./util/enums/PromptStatus";
import UserPromptActivityService from "./http/UserPromptActivityService";
import { fetchCompanyCount } from "./http/Redux/Store/CompaniesCountActions";
import DirectorySearch from "./pages/Search/DirectorySearch";
import { fetchProvinces } from "./http/Redux/Store/ProvinceListActions";
import { fetchBeeLevels } from "./http/Redux/Store/BeeLevelListActions";
import { fetchSupplierLocations } from "./http/Redux/Store/DashboardActions";
import Dashboard from "./pages/Admin/AdminDashboard";
import CompanyUserManagementView from "./pages/UserManagement/UserManagement";
import { fetchBaseSubscriptionInfo } from "./http/Redux/Store/BaseSubscriptionInfoActions";
import VendorSpendDetails from "./pages/Company/VendorSpendDetails";
import { fetchUserWorkCentre } from "./http/Redux/Store/UserWorkCentreActions";
import AgentWorkCentre from "./pages/WorkCenter/AgentWorkCentre";
import { fetchCurrentCompanyProfileCompletionSummary } from "./http/Redux/Store/baseCompanyInfoActions";
import OnBoardingMain from "./pages/OnBoarding/OnBoardingMain";
import { fetchCompanyExtendedDetails } from "./http/Redux/Store/CompanyExtendedDetailsActions";
import { fetchActiveMunicipalities } from "./http/Redux/Store/MunicipalityActions";
import { fetchCompanyShareholders } from "./http/Redux/Store/CompanyShareholderActions";
import {
  fetchBusinessUnits,
  fetchCompanyCategory,
  fetchContractingTier,
  fetchOnboardingConfigurationSets,
  fetchSupplierTypes,
} from "./http/Redux/OnBoardingActions";
import DashboardHome from "./pages/DashboardHome";
import ConfigurationSettings from "./pages/Configuration/ConfigurationSettings";
import AuditMain from "./pages/Audit/AuditMain";
import Compliance from "./pages/Compliance/ComplianceMain";
import { fetchMessaging, fetchRead, fetchUnread } from "./http/Redux/Store/NotificationActions";
import CompanySwitchMain from "./pages/CompanySwitcher/CompanySwitchMain";
import DataManagementMain from "./pages/DataManagementPartner/DataManagementMain";
import VirtualMarketPlace from "./pages/NewUI/VirtualMarketPlace";
import ViewProfile from "./pages/NewUI/ViewProfile";
import RegistrationBaseLayout from "./pages/Registration/Sections/RegistrationBaseLayout";
import useCompanyRegistrationInfo from "./util/hooks/queries/useCompanyRegistrationInfo";
import LoadingBackdrop from "./components/Backdrop/LoadingBackdrop";
import useCompanyTypes from "./util/hooks/queries/useCompanyTypes";
import useCurrentUserId from "./util/hooks/useCurrentUserId";
import BaseEditCompanyProfile from "./pages/NewUI/CompanyProfile/BaseEditCompanyProfile";
import ConnectionBase from "./pages/NewUI/MyConnection/ConnectionBase";



const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { accounts } = useMsal();
  const dispatch = useAppDispatch();

  const companyRegistrationStatus = useCompanyRegistrationInfo();

  const account = useAccount(accounts[0] || {});
  const company = useAppSelector(selectBaseCompanyInfo);
  const countries = useAppSelector(selectCountryList);
  const provinces = useAppSelector((x) => x.provinceList);
  const companyTypes = useCompanyTypes();
  const userAccount = useCurrentUserId();
  const profile = useAppSelector(getUserProfile);
  const userPrompts = useAppSelector(getUserPromptActivity);
  const municipalityList = useAppSelector((x) => x.municipalityList);
  const validation = useAppSelector((x) => x.validation);
  const validationFields = useAppSelector((x) => x.validationFields);
  const permission = useAppSelector((x) => x.permission);
  const beeLevelList = useAppSelector(x => x.beeLevelList)

  const [hasProfile, setHasProfile] = useState(false);
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    if (hasProfile) {
      if ((profile.id as number) !== 0) {
        dispatch(fetchParticularUserRole(profile.id as number));
      }
    }
  }, [dispatch, hasProfile, profile.id]);

  useEffect(() => {
    if (company?.companyId != null) {
      return;
    }
    const loader = async () => {
      setIsLoading(true);
      try {
        let apiResponse = {} as BaseCompanyInfo;
        if (account?.idTokenClaims?.extension_companyid) {
          apiResponse = await GetBaseCompanyInfoViaCompanyId(
            account?.idTokenClaims?.extension_companyid as string,
            false
          );
        } else {
          apiResponse = await GetBaseCompanyInfoViaUserId(userAccount as string);
        }

        dispatch(setBaseCompanyInfoState(apiResponse));
        dispatch(fetchPackageMatrices());
        dispatch(fetchCompanyShareholders(account?.idTokenClaims?.extension_companyid as number, false));
        dispatch(fetchCurrentCompanyProfileCompletionSummary());
        dispatch(fetchBaseSubscriptionInfo());
        dispatch(fetchCurrentSubscription());
        dispatch(fetchCurrentSubscriptionPermissions());
        dispatch(fetchOnboardingConfigurationSets(account?.idTokenClaims?.extension_companyid as number));
        dispatch(fetchBusinessUnits());
        dispatch(fetchCompanyCategory());
        dispatch(fetchSupplierTypes());
        dispatch(fetchContractingTier());
        dispatch(fetchPlans());

        if (countries.length === 0) dispatch(fetchCountries());
        if (provinces.length === 0) dispatch(fetchProvinces());
        if (municipalityList.length === 0) dispatch(fetchActiveMunicipalities());

        dispatch(fetchHasSubscriptions());
        dispatch(fetchCompanyBeeGrouping(account?.idTokenClaims?.extension_companyid as number));
        dispatch(fetchSupplierLocations(account?.idTokenClaims?.extension_companyid as number));
        dispatch(fetchUnread(userAccount as string, false));
        dispatch(fetchRead(userAccount as string, true));
        dispatch(fetchMessaging(account?.idTokenClaims?.extension_companyid as number));

        if (validation.allValidations.length === 0) dispatch(fetchValidations());
        if (validationFields.allFields.length === 0) dispatch(fetchFields());
        if (permission.allPermissions.length === 0) dispatch(fetchPermissions());

        dispatch(fetchParticularProfileImage(userAccount as string));
        dispatch(fetchCurrentUserPermissions());
        dispatch(fetchParticularUserProfile(userAccount as string));

        dispatch(fetchCompanyCount(false));

        if (beeLevelList.length === 0) dispatch(fetchBeeLevels());

        dispatch(
          fetchParticularCompanyProfile(
            account?.idTokenClaims?.extension_companyid as number,
            false
          )
        );
        dispatch(
          fetchParticularCompanyProfileImage(
            account?.idTokenClaims?.extension_companyid as number,
            false
          )
        );

        dispatch(fetchUserTourActivity(userAccount as string));
        dispatch(fetchUserWorkCentre(userAccount as string));
        dispatch(fetchUserPromptActivity(userAccount as string, account?.idTokenClaims?.extension_companyid as number));
        dispatch(fetchCompanyExtendedDetails(account?.idTokenClaims?.extension_companyid as number, false));
        if (userPrompts.length !== 0) {
          userPrompts
            .filter((x) => x.status === PromptStatus.Later)
            .forEach((element) => {
              const newModel: UserPromptActivityModel = {
                id: element.id,
                accountId: element.accountId,
                popupPromptId: element.popupPromptId,
                status: PromptStatus.Show,
                dateCreated: element.dateCreated,
                dateModified: new Date(),
              };

              const loaderPrompt = async () => {
                await UserPromptActivityService.updateUserPromptActivityPopup(newModel).finally(
                  () => {
                    setIsLoading(false);
                  }
                );
              };

              loaderPrompt();
            });
        }
      } catch {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    // only load redux data when registration is complete 
    // NOTE : this is to prevent unncessary api calls
    if (companyRegistrationStatus.data?.registrationCompleted) {
      loader();
    }
    setHasProfile(true);
  }, [
    account?.idTokenClaims?.extension_companyid,
    company?.companyId,
    companyTypes.data?.length,
    countries.length,
    dispatch,
    profile.companyId,
    provinces.length,
    userAccount,
    userPrompts.length,
  ]);

  const defaultRoutes = (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Main />} />
      <Route path="/home/:open" element={<Main />} />
      <Route path="/companyProfile" element={<CompanyProfile />} />
      <Route
        path="/audits"
        element={
          <AuthenticatePage permissions={[PermissionClassification.ViewAuditTrail]}>
            <AuditMain />
          </AuthenticatePage>
        }
      />
      <Route path="/network/:tab?" element={<ConnectionBase />} />
      <Route path="/homepage" element={<DashboardHome />} />
      <Route path="/viewprofile/:companyId" element={<ViewProfile />} />
      <Route path="/virtualmarketplace" element={<VirtualMarketPlace />} />
      <Route path="/agent/workcentre" element={<AgentWorkCentre />} />
      <Route path="/OnBoardingApplication/:companyId" element={<OnBoardingMain />} />
      <Route path="/agent/workcentre/:tab/:clientId" element={<AgentWorkCentre />} />
      <Route path="/network/Tour/:fromPage" element={<CompanyNetworkMain />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/usermanagement" element={<CompanyUserManagementView />} />
      <Route path="/vendorspend" element={<VendorSpendDetails />} />
      <Route path="/adminDashboard" element={<Dashboard />} />
      <Route path="/workcenterview/:id" element={<WorkCenterView />} />
      <Route
        path="/workcentre/companyProfile/:clientid/:supplierid/:status/:staged/:agentTaskId"
        element={<WorkCentreCompanyProfile />}
      />
      <Route path="/companyProfile/:pagesectionid" element={<CompanyProfile />} />
      <Route path="/companyProfile/:pagesectionid/:evaluatingCompanyId/:evaluatedCompanyId" element={<CompanyProfile />} />
      <Route path="/companyProfile/Tour/:fromPage" element={<CompanyProfile />} />
      <Route
        path="/compliance"
        element={
          <AuthenticatePage permissions={[PermissionClassification.ViewMyComplianceDetail]}>
            <Compliance />
          </AuthenticatePage>
        }
      />
      <Route path="/editCompanyProfile" element={<BaseEditCompanyProfile />} />
      <Route path="/accountPreferences/:fromPage" element={<AccountPreferences />} />
      <Route path="/accountPreferences" element={<AccountPreferences />} />
      <Route path="/platformConfig" element={<ConfigurationSettings />} />
      <Route path="/companyswitcher" element={<CompanySwitchMain />} />
      <Route path="/dataManagementPartner" element={<DataManagementMain />} />
      <Route path="/mySubscriptions" element={<SubscriptionsView />} />
      <Route path="/messaging" element={<ChatView />} />
      <Route path="/search/:text?" element={<DirectorySearch />} />
      <Route path="/*" element={<Navigate to="/home" />} />
    </Routes>
  );

  const registrationRoutes = (
    <Routes>
      <Route path="/*" element={<Navigate to="/registration" />} />
      <Route path="/registration" element={<RegistrationBaseLayout />} />
      <Route path="/viewprofile/:companyId" element={<ViewProfile />} />
    </Routes>)

  const renderComponents = () => {
    if (companyRegistrationStatus.isFetched) {
      return companyRegistrationStatus.data?.registrationCompleted ? defaultRoutes : registrationRoutes
    } return (
      <LoadingBackdrop showBackdrop />)
  }
  return (
    renderComponents()
  );
};

export default Pages;
