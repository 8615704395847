import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import CompanyExtendedDetailsSlice from "./CompanyExtendedDetailsSlice";
import { RootState } from "../../../app/store";
import { CompanyExtendedDetailsModel } from "../Models/CompanyExtendedDetailsModel";
import ExtendedCompanyDetailsService from "../../Company/ExtendedCompanyDetailsService";

export const companyExtendedDetailsActions = CompanyExtendedDetailsSlice.actions;

export const fetchCompanyExtendedDetails =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyExtendedDetailsModel = await ExtendedCompanyDetailsService.getCompanyExtendedDetailsModel(companyId, staged);
      dispatch(companyExtendedDetailsActions.setParticularCompanyExtendedDetail(response));
    };