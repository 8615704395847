import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makeDeleteRequestNoBodyAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { TaxInformation } from "./Models/CompanyTaxInformation";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export async function GetCompanyTaxInformation(companyId: number) {
  const url = `${baseUrl}/api/Company/CompanyTaxInformation/GetCompanyTaxInformation/${companyId}`;
  return makeGetRequestAsync<TaxInformation>(apiScopes, url);
}

export async function AddOrUpdateCompanyTaxInformation(companyTaxInfo: TaxInformation) {
  const url = `${baseUrl}/api/Company/CompanyTaxInformation/AddOrUpdateCompanyTaxInformation`;
  return makePostRequestAsync<void, TaxInformation>(apiScopes, url, companyTaxInfo);
}

export async function DeleteCompanyTaxInformation(companyId: number) {
  const url = `${baseUrl}/api/Company/CompanyTaxInformation/DeleteCompanyTaxInformation/${companyId}`;
  return makeDeleteRequestNoBodyAsync(apiScopes, url);
}