import { AuditViewMessage } from "../../pages/Audit/AuditViewMessage";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync,{ downloadFileAsync } from "../Helpers/httpRequestHelpers";

const apiScopes = protectedResources.auditWebApi.scopes;
const baseUrl = protectedResources.auditWebApi.endpoint;

/**
 * Attaches a given access token to a Wep API call. Returns information about the user
 */
export const getAuditEntries = async () => {
  const url = `${baseUrl}/api/audits`;
  return (await (makeGetRequestAsync<Array<AuditViewMessage>>(apiScopes, url))).data
}

/**
 * download audit export
 */
export const downloadExcelExport = async () => {
  const downloadUrl = `${protectedResources.auditWebApi.endpoint}/api/audits/export`;
  await downloadFileAsync(apiScopes,downloadUrl);
}