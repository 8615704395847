import { protectedResources } from "../../authConfig";
import { EmailHistoryMessage } from "../../pages/EmailHistoryMessage";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";

const baseurl = protectedResources.mailerWebApi.endpoint;
const scopes = protectedResources.mailerWebApi.scopes;

const GetEmails = async() =>
{
  const url = `${baseurl}/api/Email`
  const result = await makeGetRequestAsync<EmailHistoryMessage[]>(scopes,url);
  return result
}

export default GetEmails;

