import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import CompaniesCountSlice from "./CompaniesCountSlice"
import { RootState } from "../../../app/store"
import { CompaniesCountModel } from "../Models/CompaniesCountModel";
import CompaniesVerificationService from "../../CompanyVerificationService";

export const companiesCountActions = CompaniesCountSlice.actions

export const fetchCompanyCount = (staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: CompaniesCountModel = await CompaniesVerificationService.getCompaniesCount(staged);
  dispatch(companiesCountActions.setParticularCompaniesCount(response))
}