import React, { ChangeEvent, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SweetAlert2 from "react-sweetalert2";
import "../Connect/SearchResult/SweetAlert.css";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useMsal, useAccount } from "@azure/msal-react";
import { CompanyProfileModel } from "../../http/Redux/Models/CompanyProfileModel";
import CompanyProfileService from "../../http/CompanyProfileService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCompanyProfile, getCurrentCompanyId } from "../../http/Redux/Store/companySlice";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import bufferProfile from "../../util/images/filler.png";
import { fetchParticularProfileImage } from "../../http/Redux/Store/UserProfileActions";

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) => <Slide direction="up" ref={ref} {...props} />);

const EditCompanyProfileImage = ({ staged }: { staged: boolean }) => {

  const companyProfile = useAppSelector(getCompanyProfile);
  const companyId = useAppSelector(getCurrentCompanyId);
  const [editable, setEditable] = useState<CompanyProfileModel>(companyProfile || {} as CompanyProfileModel);
  const [file, setFile] = useState<FileList | null>(null);
  const [profileFileName, setProfileFileName] = useState("");
  const [status, setStatus] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState("");
  const [updatedImage, setUpdatedImage] = useState(0);
  const [updatedInfo, setUpdatedInfo] = useState(0);
  const [id, setId] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    setStatus(""); // Reset status
    event.preventDefault();
    const formData = new FormData();
    if (file === null || file === undefined || file.length === 0) return;
    formData.append("File", file[0]);
    formData.append("FileName", file[0].name);
    setEditable({ ...editable, fileName: file[0].name, companyId, id });


    if (companyProfile.id === undefined) {
      const loader = async () => {
        const response = await CompanyProfileService.addProfileDetails(editable, staged);
        setId(response as number);
        setUpdatedInfo(200);
        const addImageStatus = await CompanyProfileService.uploadImage(companyId, formData, editable.fileName);
        setUpdatedImage(addImageStatus);
      };
      loader();
    }
    else {
      const loader = async () => {
        setId(companyProfile.id);
        const response = await CompanyProfileService.updateProfileDetails(editable, staged);
        setUpdatedInfo(200);
        setUpdatedInfo(response);
        const updateImageStatus = await CompanyProfileService.uploadImage(companyId, formData, companyProfile.fileName);
        setUpdatedImage(updateImageStatus);
      };
      loader();
    }

    setShowDrawer(false);
  };

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {

    setImage("");
    const fileSelected = event.target.files![0];
    if (fileSelected.size < 1024 * 1024) {
      setFile(event.target.files);
      setHasImage(true);
    }
    else {
      setSwalPropsChange({
        show: true,
        title: "File to big must be less then 1MB",
        icon: "error",
        timer: Number(3000),
        showCancelButton: false,
        showConfirmButton: false,

      });
    }



  }

  const handleClose = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    if (file === null || file === undefined || file.length === 0) return;
    setProfileFileName(file[0].name);

    setEditable({ ...editable, fileName: file[0].name, companyId, id });
    setImage(URL.createObjectURL(file[0]));

    if (updatedImage === 200 && updatedInfo === 200) {
      setShow(false);
      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });
      dispatch(fetchParticularProfileImage(userAccount as string));

    }


  }, [companyId, editable.id, id, profileFileName, file?.length, image.length, updatedImage, updatedInfo, show, dispatch]
  );

  return (
    <div>
      <LoadingBackdrop showBackdrop={show} />
      <div className="profile-section-profile-image-small-wrap">
        <Tooltip title="Edit Company Logo">
          <EditIcon className="step-one-userprofile profile-section-profile-image-company-small" onClick={() => setShowDrawer(true)} />
        </Tooltip>
      </div>
      <Dialog
        open={showDrawer}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{ width: "500px", margin: "auto" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="heading-sub-3">
          Edit Company Profile
          <div className="profile-image-edit-header-line" />
          <span className="small-note">* update button will only appear when you select a new image.</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="form-image-btn">
              <div className="form-image-wrap">
                <div className="form-image-search-btn-wrap">
                  <Tooltip title="Select Image">
                    <Button variant="outlined" component="label" sx={{ marginRight: 1 }} className="search-image-btn">
                      <ImageSearchIcon />
                      <input type="file" hidden onChange={(e) => handleSelectFile(e)} />
                    </Button>
                  </Tooltip>
                </div>
                <div className="form-image-search-text-wrap">
                  <TextField
                    value={profileFileName}
                    margin="dense"
                    id="name"
                    aria-readonly
                    disabled
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    label="Selected Image (png & jpg)"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined" />
                </div>
              </div>
            </div>
            <div className="form-upload-image">
              <img src={image?.length !== 0 ? image : bufferProfile} alt="" className="preview-image" />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={hasImage ? "active" : "not-active"}>
            <Button type="submit" onClick={handleSubmit} variant="contained" className="form-add-btn-img">
              Update Profile
            </Button>
            {status ? <h1>{status}</h1> : null}
          </div>
        </DialogActions>
      </Dialog>
      <SweetAlert2 {...swalPropsChange} />
    </div>


  )
};

export default EditCompanyProfileImage;