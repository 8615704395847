import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { Tooltip, Box, Grid } from "@mui/material";

import useAllowedPermission, {
  useHasAnyAllowedPermissions,
} from "../../../util/hooks/useAllowedPermission";

import { SearchResultModel } from "./SearchView";
import ConnectTools from "./ConnectTools";

import styles from "../../../pages/Search/Search.module.scss";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import CompanyRating from "../../Ratings/CompanyRating";
import { beeRatingStatusToString } from "../../../util/enums/BeeRatingStatus";


// import { useAppSelector } from "../../../app/hooks";
const useSearchColumnConfig = (
  reloadCallback: () => void,
  searchType?: NetconnSearchType
) => {
  const hasPermission = useAllowedPermission();
  const hasAnyPermission = useHasAnyAllowedPermissions();
  const showBeeAndComplianceDetails = hasAnyPermission([
    PermissionClassification.ViewComplianceManagementDashboard,
    PermissionClassification.ViewComplianceDetail,
  ]);
  let columns: Array<GridColDef> = [] as Array<GridColDef>;
  const standardColumns = [
    {
      field: "name",
      headerName: "Registered Name",
      hideable: true,
      flex: 1,
      minWidth: 260,
      sortable: false,
      renderCell: (params: any) => {
        const rowData = params.row;
        const cellParams = params.value.split("-");

        return (
          <div style={{ width: "100%", height: "fit-content" }}>
            <Grid container>
              <Grid xs={12}>
                <Tooltip title={rowData.tradeDescription}>
                  <div>
                    <p style={{ width: "100%", marginBottom: 2 }}>
                      <h3>{cellParams[0]}</h3>
                    </p>
                  </div>
                </Tooltip>
              </Grid>
              <Grid xs={12}>
                <p>
                  <b>City: </b>
                  {cellParams[2] === "null" ? "" : cellParams[2]}{" "}
                </p>
              </Grid>
            </Grid>
          </div>
        );
      },
    },
    {
      field: "reg",
      headerName: "Registration Number",
      hideable: true,
      sortable: false,
      flex: 1,
      minWidth: 175,
    },
    {
      field: "trading",
      headerName: "Trading Name",
      hideable: true,
      flex: 1,
      minWidth: 145,
      sortable: false,
    },
  ];
  columns = columns.concat(standardColumns);

  if (showBeeAndComplianceDetails) {
    const beeColumns: GridColDef[] = [
      {
        field: "beeRatingStatusId",
        headerName: "B-BBEE Status",
        hideable: true,
        sortable: false,
        flex: 1,
        minWidth: 60,
        valueGetter: (params) => {
          const row = params.row as SearchResultModel;
          return beeRatingStatusToString(row?.beeRatingStatusId ?? 0);
        },
      },
      {
        field: "beeClass",
        headerName: "B-BBEE Class",
        hideable: true,
        sortable: false,
        flex: 1,
        minWidth: 60,
      },
      {
        field: "bee",
        headerName: "B-BBEE Level",
        hideable: true,
        sortable: false,
        flex: 1,
        minWidth: 60,
      },
      {
        field: "bo",
        headerName: "Black Owned %",
        hideable: true,
        minWidth: 30,
        flex: 1,
        sortable: false,
      },
      {
        field: "bwo",
        headerName: "Black Women Owned %",
        hideable: true,
        minWidth: 50,
        flex: 1,
        sortable: false,
      },
    ];

    columns = columns.concat(beeColumns); // Cheeky beast needed to be reassigned before it rendered.
  }

  if (searchType === NetconnSearchType.Supplier) {
    if (showBeeAndComplianceDetails){
      columns.push(
        {
          field: "cipc",
          headerName: "CIPC Compliance",
          hideable: true,
          sortable: false,
          flex: 1,
          minWidth: 60,
        });
      columns.push({
        field: "sars",
        headerName: "SARS Compliance",
        hideable: true,
        sortable: false,
        flex: 1,
        minWidth: 60,
      })
    }
    columns.push({
      field: "vendorNumber",
      headerName: "Vendor Number",
      hideable: true,
      minWidth: 175,
      flex: 1,
      sortable: false,
    });
  }

  if (hasPermission(PermissionClassification.AvailableActions_RateSupplier))
    columns.push({
      field: "rating",
      flex: 1,
      headerName: "Rating",
      hideable: true,
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridCellParams<SearchResultModel>) => {
        const rowData = params.row as SearchResultModel;
        return (
          <CompanyRating
            companyRegisteredName={rowData.name}
            companyId={rowData.id}
            searchType={searchType as number}
            averageCompanyRating={rowData.rating}
          />
        );
      },
    });

  if (
    hasAnyPermission([
      PermissionClassification.AvailableActions_SendMessage,
      PermissionClassification.AccessViewMoreDetails,
      PermissionClassification.GlobalCompanySwitching,
      PermissionClassification.AssignPlatformRoles,
    ])
  )
    columns.push({
      field: "id",
      headerName: "Actions",
      hideable: false,
      minWidth: 300,
      sortable: false,
      flex: 1,
      renderCell: (params: GridCellParams<SearchResultModel>) => {
        const rowData = params.row as SearchResultModel;
        return (
          <Box className={styles.connectionTools}>
            <ConnectTools
              searchResult={rowData}
              connectionType={searchType}
              reloadCallback={reloadCallback}
            />
          </Box>
          
        );
      },
    });

  return columns;
};

export default useSearchColumnConfig;
