import { Box, Container } from "@mui/material";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import BaseRegistrationPage from "./BaseRegistrationPage";
import RegistrationBannerSection from "./RegistrationBannerSection";
import { IRegistrationStepPageComponent } from "../RegistrationSteps/IRegistrationStepComponent";
import ButtonGroup from "./ButtonGroup";
import CompanyRegistrationStepOne from "../RegistrationSteps/CompanyRegistrationStepOne";
import ComplianceInformationStepThree from "../RegistrationSteps/ComplianceInformationStepThree";
import CompanyContactStepTwo from "../RegistrationSteps/CompanyContactStepTwo";
import SupportingDocumentsStepFour from "../RegistrationSteps/SupportingDocumentsStepFour";
import ProfileStepSix from "../RegistrationSteps/ProfileStepSix";
import GoodsAndServicesStepFive from "../RegistrationSteps/GoodsAndServicesStepFive";
import useCompanyRegistrationInfo from "../../../util/hooks/queries/useCompanyRegistrationInfo";
import { CompanyRegistrationMessage } from "../../../http/Company/Models/companyRegistrationMessage";
import CompleteLaterPromptModal from "../CustomComponents/CompleteLaterPromptModal";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import useCompanyRegistrationMutation from "../../../util/hooks/mutations/useCompanyRegistrationMutation";
import CompanyVerificationStepZero from "../RegistrationSteps/CompanyVerificationStepZero";

interface RegistrationPageProps {
  title: string,
  children: ReactNode,
  pageRef: RefObject<IRegistrationStepPageComponent>,
  pageNumber: number,
}

const RegistrationBaseLayout = () => {

  const navigate = useNavigate();
  const baseCompany = useBaseCompanyInfo();

  const [currentStep, setCurrentStep] = useState(0)
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showSaveAndContiueModal, setShowSaveAndContinueConfirmationModal] = useState(false);
  const [loadingSubmission, setLoadingSubmission] = useState(false);

  const { isLoading: loadingCompanyRegInfo, data: companyRegistrationInfo = {} as CompanyRegistrationMessage,
    isFetched: companyRegistrationInfoIsFetched } = useCompanyRegistrationInfo();

  const companyRegistrationMutation = useCompanyRegistrationMutation();

  useEffect(() => {
    if (loadingCompanyRegInfo === false && companyRegistrationInfo.lastCompletedStep)
      setCurrentStep(companyRegistrationInfo.lastCompletedStep + 1)
  }, [
    companyRegistrationInfo,
    loadingCompanyRegInfo,
    companyRegistrationInfoIsFetched,
  ])


  // create refs for each page
  const pageRefs = {
    companyInfo: useRef<IRegistrationStepPageComponent>(null),
    contactDetails: useRef<IRegistrationStepPageComponent>(null),
    compliance: useRef<IRegistrationStepPageComponent>(null),
    documentation: useRef<IRegistrationStepPageComponent>(null),
    goods: useRef<IRegistrationStepPageComponent>(null),
    profile: useRef<IRegistrationStepPageComponent>(null),
  };

  const pages: RegistrationPageProps[] = [
    {
      title: "COMPANY VERIFICATION",
      children: <CompanyVerificationStepZero ref={pageRefs.contactDetails} />,
      pageRef: pageRefs.contactDetails,
      pageNumber: 0,
    },
    {
      title: "COMPANY INFORMATION",
      children: <CompanyRegistrationStepOne title="REGISTRATION INFORMATION" ref={pageRefs.companyInfo} />,
      pageRef: pageRefs.companyInfo,
      pageNumber: 1,
    },
    {
      title: "COMPANY CONTACT DETAILS",
      children: <CompanyContactStepTwo ref={pageRefs.contactDetails} />,
      pageRef: pageRefs.contactDetails,
      pageNumber: 2,
    },
    {
      title: "COMPLIANCE INFORMATION",
      children: <ComplianceInformationStepThree ref={pageRefs.compliance} />,
      pageRef: pageRefs.compliance,
      pageNumber: 3,
    },
    {
      title: "SUPPORTING DOCUMENTATION",
      children: <SupportingDocumentsStepFour ref={pageRefs.documentation} />,
      pageRef: pageRefs.documentation,
      pageNumber: 4,
    },
    {
      title: "GOODS AND SERVICES",
      children: <GoodsAndServicesStepFive ref={pageRefs.goods} />,
      pageRef: pageRefs.goods,
      pageNumber: 5,
    },
    {
      title: "PROFILE",
      children: <ProfileStepSix ref={pageRefs.profile} />,
      pageRef: pageRefs.profile,
      pageNumber: 6,
    },
  ];

  const currentPage = pages.find((_x, index) => index === currentStep);
  const isFinalPage = currentStep + 1 === pages.length;

  const clearErrorMessages = () => errorMessages.length > 0 && setErrorMessages([]);

  const handleNext = async () => {
    setLoadingSubmission(true);
    const currentPageRef = currentPage?.pageRef.current;
    if (currentPageRef) {
      const submissionResponse = await currentPageRef.onSubmit();
      const pageHasErrors = submissionResponse.submittedSuccessfully === false && submissionResponse.errorMessages.length > 0;

      // show error modal
      if (pageHasErrors) {
        setErrorMessages(submissionResponse.errorMessages);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoadingSubmission(false);
        return;
      } clearErrorMessages()

      // increment counter
      if (!pageHasErrors) {
        // API Call : update company registration info
        await companyRegistrationMutation.mutateAsync({
          id: companyRegistrationInfo.id,
          lastCompletedStep: currentStep,
          registrationCompleted: isFinalPage,
        }).then(() => {
          isFinalPage &&
            navigate(`/viewprofile/${baseCompany.data?.companyId}`)
        });

        setCurrentStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setLoadingSubmission(false);
  };

  const handleBack = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
    clearErrorMessages();
  };

  const handleSaveAndContinueLater = async () => {
    setShowSaveAndContinueConfirmationModal(true);
    const currentPageRef = currentPage?.pageRef.current;
    if (currentPageRef) {
      await currentPageRef.onSaveAndContinue();
    }
  };
  return (
    <Container
      sx={{ backgroundColor: "#FFFFFF", minHeight: "80vh" }}
      maxWidth={false} disableGutters
    >
      <RegistrationBannerSection
        steps={pages.map(s => ({ title: s.title }))}
        activeStep={currentStep}
        errorMessages={errorMessages}
      />
      {
        currentPage != null &&
        <BaseRegistrationPage isFinalPage={isFinalPage} title={currentPage.title} stepCount={currentStep + 1}>
          {currentPage.children}
        </BaseRegistrationPage>
      }

      {/* Action Buttons */}
      <Box my={5} py={5}>
        <ButtonGroup
          buttons={[
            {
              label: "back",
              align: "left",
              hidden: currentStep === 0 || currentStep === 1,
              type: "outlined",
              onClick: handleBack,
            },
            {
              label: "save and continue later",
              align: "right",
              type: "secondary",
              isLoading: showSaveAndContiueModal,
              hidden: currentStep === 0,
              onClick: handleSaveAndContinueLater,
            },
            {
              label: isFinalPage ? "save" : `next - ${pages[currentStep + 1].title}`,
              align: "right",
              disabled: loadingSubmission || showSaveAndContiueModal,
              isLoading: loadingSubmission,
              type: "primary",
              hidden: currentStep === 0,
              onClick: handleNext,
            },
            {
              label: "Yes, this is my company",
              align: "right",
              disabled: loadingSubmission || showSaveAndContiueModal,
              isLoading: loadingSubmission,
              type: "primary",
              hidden: currentStep !== 0,
              onClick: handleNext,
            },
          ]}
        />
        <CompleteLaterPromptModal
          isOpen={showSaveAndContiueModal}
          steps={pages.map(s => ({ title: s.title }))}
          activeStep={currentStep}
          onCloseModalCallback={() => setShowSaveAndContinueConfirmationModal(false)}
        />
      </Box>
      {/* <Footer /> */}
    </Container>
  )
}
export default RegistrationBaseLayout;
