/* eslint-disable no-unused-vars */
import { DataGridPro, DataGridProProps, GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Chip, Divider, Grid, IconButton, Paper, Stack, Tooltip, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getAllProducts, getHasSubscriptions } from "../../../http/Redux/Store/SubscriptionSlice";
import SubscriptionService from "../../../http/SubscriptionManagement/SubscriptionApi";
import { SubscriptionProductModel } from "../../../http/Redux/Models/SubscriptionProductModel";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchHasSubscriptions, fetchProductAddons, fetchProductFeatures, fetchProductPlans, fetchProducts } from "../../../http/Redux/Store/SubscriptionActions";
import { HasSubscriptionModel } from "../../../http/SubscriptionManagement/Models/HasSubscriptionModel";
import { SubscriptionProductPlansModel } from "../../../http/Redux/Models/SubscriptionProductPlansModel";
import { SubscriptionProductAddonsModel } from "../../../http/Redux/Models/SubscriptionProductAddonsModel";
import { SubscriptionProductFeaturesModel } from "../../../http/Redux/Models/SubscriptionProductFeaturesModel";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";




interface EditToolbarProps {
  setIsInAddMode: (isInAddMode: boolean) => boolean;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setIsInAddMode } = props;


  const handleClick = () => {
    setIsInAddMode(true);
  };


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Product
      </Button>
    </GridToolbarContainer>
  );
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
)

interface ViewCellProps {
  row: SubscriptionProductModel;
  setIsInEditMode: (isInEditMode: boolean) => void;
  setEditRow: (row: SubscriptionProductModel) => void;
  refreshProducts: () => void;

}

const ViewCell: React.FC<ViewCellProps> = ({ row, setEditRow, setIsInEditMode, refreshProducts }) => {

  const hasSubscriptions: HasSubscriptionModel[] = useAppSelector(getHasSubscriptions);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure you want to delete this product?",
      icon: "warning",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const loader = async () => {
          await SubscriptionService.deleteProduct(row).catch(() => {
            toast.error("Product failed to deleted");
          }).finally(() => {
            toast.success("Product deleted");
            refreshProducts();
          });

        };
        loader();
      }
    });

  }

  const handleEditRow = () => {
    setIsInEditMode(true);
    setEditRow(row);
  }

  return (
    <div>
      <Tooltip title="Edit Product">
        <IconButton size="small" onClick={() => handleEditRow()} className="editableValidationBtn">
          <EditIcon />
        </IconButton>
      </Tooltip>
      {hasSubscriptions.find(x => x.productId === row.id) ? (
        <p />
      ) : (
        <Tooltip title="Delete Product">
          <IconButton size="small" onClick={() => handleDelete()} className="deleteValidationBtn">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

    </div>

  )
};

const DetailPanelContent = ({ row: rowProp }:
  {
    row: SubscriptionProductModel,
  }) => {
  const features = useAppSelector((x) => x.subscriptionProductFeatures.allViewSubscriptionProductFeatures);
  const plans = useAppSelector((x) => x.subscriptionproductPlans.allViewSubscriptionProductPlans);
  const addons = useAppSelector((x) => x.subscriptionProductAddons.allViewSubscriptionProductAddons);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProductFeatures(rowProp.id));
    dispatch(fetchProductPlans(rowProp.id));
    dispatch(fetchProductAddons(rowProp.id));
  }, []);

  return (
    <Stack
      sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "#ECECEC" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <h3>Features</h3>
              <Divider />
            </Grid>

            <Grid xs={12}>
              {features.filter(x => x.featureId !== 0).length !== 0 ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                  {features.filter(x => x.featureId !== 0).map((x: SubscriptionProductFeaturesModel) => (
                    <Chip
                      key={x.featureId}
                      label={x.featureName}
                      className="chip-colored"
                      size="small"
                    />
                  ))}
                </Box>) : (
                <i>None</i>
              )
              }
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1, mt: 2 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <h3>Plans</h3>
              <Divider />
            </Grid>

            <Grid xs={12}>
              {plans.filter(x => x.planId !== 0).length !== 0 ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                  {plans.filter(x => x.planId !== 0).map((x: SubscriptionProductPlansModel) => (
                    <Chip
                      key={x.planId}
                      label={x.planCode}
                      className="chip-colored"
                      size="small"
                    />
                  ))}
                </Box>) : (
                <i>None</i>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1, mt: 2 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <h3>Addons</h3>
              <Divider />
            </Grid>

            <Grid xs={12}>
              {addons.filter(x => x.isAddon).length !== 0 ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                  {addons.filter(x => x.isAddon).map((x: SubscriptionProductAddonsModel) => (
                    <Chip
                      key={x.addonId}
                      label={x.addonName}
                      className="chip-colored"
                      size="small"
                    />
                  ))}
                </Box>) : (<i>None</i>)
              }
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  )
};

const ProductView = ({ handleSetSubHeading }: { handleSetSubHeading: (newSub: string) => void }) => {
  const products: SubscriptionProductModel[] = useAppSelector(getAllProducts);
  const [editableRow, setEditableRow] = useState({} as SubscriptionProductModel);
  const [isAdding, setIsAdding] = useState(false);
  const [isViewing, setIsViewing] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();

  const refreshProducts = () => {
    const loader = async () => {
      dispatch(fetchProducts());
    }
    loader();
  }

  const handleEditRow = (row: SubscriptionProductModel) => {
    setEditableRow(row);
  }

  const setIsInAddMode = (isInAddMode: boolean) => {
    setIsAdding(isInAddMode);
    handleSetSubHeading("Adding New Product");
    setIsEditing(false);
    setIsViewing(false);
  }

  const setIsInEditMode = (isInEditMode: boolean) => {
    setIsEditing(isInEditMode);
    handleSetSubHeading("Editing Product");
    setIsAdding(false);
    setIsViewing(false);
  }


  const backToView = () => {
    setIsAdding(false);
    handleSetSubHeading("");
    setIsEditing(false);
    setIsViewing(true);
    refreshProducts();
  }

  const columns: GridColDef[] = [
    {
      field: "isActive",
      headerName: "Active",
      headerClassName: "custom-header",
      width: 80,
      renderCell: (params) => (
        <div>
          {params.value ? <ToggleOnIcon sx={{ color: "green", marginTop: 1 }} /> : <ToggleOffIcon sx={{ color: "gray", marginTop: 1 }} />}
        </div>
      ),
    },
    {
      field: "isAddon",
      headerName: "Type",
      headerClassName: "custom-header",
      width: 90,
      renderCell: (params) => (
        <div>
          {params.value ? (
            <Chip label="Addon" />
          ) : (
            <Chip label="Product" />
          )}
        </div>
      ),
    },
    {
      field: "productName",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
    },

    {
      field: "id",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell row={params.row as SubscriptionProductModel} setEditRow={handleEditRow} setIsInEditMode={setIsInEditMode} refreshProducts={refreshProducts} />
      ),
    },

  ];

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);

  useEffect(() => {
    const loader = async () => {
      dispatch(fetchProducts());
      dispatch(fetchHasSubscriptions());
    }
    loader();
  }, [])

  return (
    <div>
      {isViewing && (
        <div style={{ height: 500, width: "100%" }}>
          <DataGridPro
            rows={products ?? []}
            columns={columns}
            disableRowSelectionOnClick
            pagination
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            slots={{
              toolbar: EditToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: { setIsInAddMode },
            }} />
        </div>

      )}
      <div style={{ width: "100%" }}>
        {isAdding && (<AddProduct backToView={backToView} />)}
        {isEditing && (<EditProduct
          backToView={backToView}
          selectedProduct={editableRow} />)}
      </div>
    </div>

  )
}

export default ProductView;