import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetBankNames } from "../../Company/bankingDetails";
import { BankNameModel } from "../../Company/Models/BankNameModel";
import bankNameListSlice from "./BankNameListSlice";

export const BankNameListActions = bankNameListSlice.actions;

export const fetchBankNames = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<BankNameModel> = (await GetBankNames());
  dispatch(BankNameListActions.setBankNameListState(apiResponse));
}