import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import EditProfile from "./EditProfile";
import { accountStatusToString } from "../../util/enums/AccountStatus";

// eslint-disable-next-line max-len
const ProfileInformation = () => {
  const userProfile: UserProfileModel = useAppSelector((state: {
    userProfile: { particularUserProfile: UserProfileModel };
  }) => state.userProfile.particularUserProfile);

  const accountStatus = accountStatusToString(userProfile.accountStatus);
  return (


    <>
      <Grid container>
        <Grid xs={6} />
        <Grid xs={6}>
          <EditProfile userProfile={userProfile} />
        </Grid>
      </Grid>
      <div className="card-content-info">
        <p><b>Name:</b> {userProfile.name} {userProfile.surname}</p>
        <br />
        <p><b>Contact Number:</b> {userProfile.mobile}</p>
        <br />
        <p><b>Email:</b> {userProfile.email}</p>
        <br />
        <p><b>Account Status:</b> {accountStatus}</p>
      </div>
    </>


  )
};
export default ProfileInformation;


