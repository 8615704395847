import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetUserCompanies } from "../../Company/company";
import { UserCompanyModel } from "../Models/UserCompanyModel";
import UserCompanyListSlice from "./UserCompanyListSlice";

export const UserCompanyListActions = UserCompanyListSlice.actions;

export const fetchUserCompanies = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<UserCompanyModel> = (await GetUserCompanies());
  dispatch(UserCompanyListActions.setUserCompanyListState(apiResponse));
}