import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetBanks } from "../../../http/Company/bankingDetails";
import { Bank } from "../../../http/Company/Models/Bank";

const useBanks = () => {
  const query = useQuery({
    queryKey: [queryKeys.bankList],
    queryFn: async () => (await GetBanks()) as Array<Bank>,
    staleTime: 600000, 
  })

  return query;
}

export default useBanks;