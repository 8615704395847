import { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Select,
  StyledEngineProvider,
  // MenuItem,
  Tooltip,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import MailIcon from "@mui/icons-material/Mail";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SweetAlert2 from "react-sweetalert2";
// import { Select } from "@material-ui/core";
import { SearchModel } from "../../../http/NetworkAndConnect/Models/SearchModel";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import ConnectionType from "../../../util/enums/ConnectionType";
import { createPendingConnectionRequestWithCompany } from "../../../http/ConnectionRequestService";
import { CreatePendingConnectionRequestModel } from "../../../http/NetworkAndConnect/Models/CreatePendingConnectionRequestModel";
import InLineSpinner from "../../../components/LoadingSpinners/InlineSpinner";


interface SearchRowProps {
  record: SearchModel;
  searchType: NetconnSearchType;
  viewMode: string;
  isChecked: boolean;
  // eslint-disable-next-line no-unused-vars
  onCheckboxToggle: (selectedCompanyId: number | null) => void;

  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  availableActionsList?: Array<string>;
  // eslint-disable-next-line no-unused-vars
  handleRecordDetailsClick: (selectedCompanyId: number | null) => void;
  // eslint-disable-next-line no-unused-vars
  handleMessagingBtnClick: (companyToMessage: SearchModel) => void;
  closeActions: boolean;
  handleCloseAction: () => void;
  isSupplier : boolean;
  isBuyer : boolean;
}

const SearchRow = ({
  record,
  searchType,
  viewMode,
  isChecked,
  onCheckboxToggle,
  // availableActionsList,
  handleRecordDetailsClick,
  handleMessagingBtnClick,
  closeActions,
  handleCloseAction,
  isSupplier,
  isBuyer,
}: SearchRowProps) => {
  const [showAvailableActions, setShowAvailbleActions] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState<any>({});
  const [showBusyOnActionSpinner, setShowBusyOnActionSpinner] = useState(false);

  const toggleOnClickActions = () => {
    setShowAvailbleActions(!showAvailableActions);
  }


  const sendNewPendingConnectionRequest = (connectionType: ConnectionType, companyId: number) => {
    const loader = async () => {
      try {
        setShowBusyOnActionSpinner(true);
        await createPendingConnectionRequestWithCompany({
          recipientCompanyId: companyId,
          connectionType,
        } as CreatePendingConnectionRequestModel);

        setShowBusyOnActionSpinner(false);
        setSwalProps({
          show: true,
          title: "Success",
          text: "Connection request sent successfully.",
          icon: "success",
        });

      } catch (error) {
        setSwalProps({
          show: true,
          title: "Success",
          text: "Error : Failed to send connection request",
          icon: "error",
        });
      }
    }
    loader();
  };

  useEffect(() => {
    if (closeActions === true) {
      setShowAvailbleActions(false);
      handleCloseAction();
    }
  }, [closeActions])

  return (
    <StyledEngineProvider injectFirst>
      <Grid item className="table-box-mp">
        <Card sx={{ padding: 2 }}>
          {viewMode.includes("Detailed") && (
            <Grid container sx={{ marginBottom: 2 }}>
              <Grid xs={2}>
                <p>{record.myRegisteredName}</p>
              </Grid>
              <Grid xs={2}>
                <p>{record.registrationNumber}</p>
              </Grid>
              <Grid xs={2}>
                <p>{record.vendorNumber}</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>{record.beeClassification || "N/A"}</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>No</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>{record.complianceStatus}</p>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid xs={1}>
              <Checkbox
                checked={isChecked}
                onChange={() => onCheckboxToggle(record.companyId)}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              />
            </Grid>
            <Grid xs={7}>
              <h3 className="table-card-title">
                <b>{record.registeredName}</b>
              </h3>
            </Grid>
            <Grid xs={2}>
              {viewMode.includes("Simple") && (
                showBusyOnActionSpinner ? 
                  <InLineSpinner /> : 
                  <button
                    type="button"
                    style={{ width: "200px" }}
                    className="btn-custom-table-top-actions"
                    onClick={() => toggleOnClickActions()}
                  >
                  AVAILABLE ACTIONS
                  </button>
              )}
              {showAvailableActions && (
                <List className="action-search-btn-list">
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleMessagingBtnClick(record)}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <MailIcon />
                      </ListItemIcon>
                      <ListItemText primary="start a conversation" />
                    </ListItemButton>
                  </ListItem>
                  {isSupplier ? 
                    <Tooltip title="This company is already connected as your supplier."
                      placement="top"                      
                    >
                      <ListItem disablePadding>
                        <ListItemButton disabled>
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            <PersonAddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Connect as my Supplier" />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    :
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => {
                        sendNewPendingConnectionRequest(ConnectionType.Supplier, record.companyId);
                        toggleOnClickActions();
                      }}>
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Connect as my Supplier" />
                      </ListItemButton>
                    </ListItem>
                  }
                  {isBuyer ? 
                    <Tooltip title="This company is already connected as your buyer."
                      placement="top"                      
                    >
                      <ListItem disablePadding>
                        <ListItemButton disabled>
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            <PersonAddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Connect as my Client" />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    :
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => {
                        sendNewPendingConnectionRequest(ConnectionType.Client, record.companyId);
                        toggleOnClickActions();
                      }}>
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Connect as my Client" />
                      </ListItemButton>
                    </ListItem>
                  }
                </List>
              )}
            </Grid>
            <Grid xs={2}>
              {!viewMode.includes("Detailed") && (
                <button
                  type="button"
                  className="btn-custom-table-top-simple"
                  onClick={() => handleRecordDetailsClick(record.companyId)}
                >
                  <Grid container>
                    <Grid xs={1}>
                      <EyeIcon />
                    </Grid>
                    <Grid xs={11} className="text-custom-btn">
                      VIEW DETAILS
                    </Grid>
                  </Grid>
                </button>
              )}
            </Grid>
          </Grid>
          <Divider />
          {!viewMode.includes("Detailed") && (
            <Grid container sx={{ marginTop: 2 }}>
              <Grid xs={2}>
                <p>{record.myRegisteredName}</p>
              </Grid>
              <Grid xs={2}>
                <p>{record.registrationNumber}</p>
              </Grid>
              <Grid xs={2}>
                <p>{record.vendorNumber}</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>{record.beeClassification || "N/A"}</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>No</p>
              </Grid>
              <Grid xs={2} sx={{ color: "red" }}>
                <p>{record.complianceStatus}</p>
              </Grid>
            </Grid>
          )}
          {viewMode.includes("Detailed") && (
            <Grid container>
              <Grid xs={4} className="grid-detailed-text">
                <b>{searchType === NetconnSearchType.Supplier ? "Supplier" : "Buyer"} Status:</b>{" "}
                Existing
              </Grid>
              <Grid xs={4} className="grid-detailed-text">
                {searchType === NetconnSearchType.Supplier && (
                  <div>
                    <b>Supplier Type:</b> N/A
                  </div>
                )}
              </Grid>
              <Grid xs={2}>
                {
                  showBusyOnActionSpinner ? 
                    <InLineSpinner /> :
                    <button
                      type="button"
                      style={{width: "200px"}}
                      className="btn-custom-table-actions"
                      onClick={() => toggleOnClickActions()}
                    >
                      AVAILABLE ACTIONS
                    </button>
                }
                {viewMode.includes("Detailed") && showAvailableActions && (
                  <List className="action-btn-list">
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleMessagingBtnClick(record)}>
                        <ListItemIcon>
                          <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="start a conversation" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                )}
              </Grid>
              <Grid xs={2}>
                <button
                  type="button"
                  onClick={() => handleRecordDetailsClick(record.companyId)}
                  className="btn-custom-table-simple"
                >
                  <Grid container>
                    <Grid xs={1}>
                      <EyeIcon />
                    </Grid>
                    <Grid xs={11} className="text-custom-btn">
                      VIEW DETAILS
                    </Grid>
                  </Grid>
                </button>
              </Grid>
              <Grid xs={4}>
                <b>Transactional Status:</b> N/A
              </Grid>
              <Grid xs={4}>
                {searchType === NetconnSearchType.Supplier && (
                  <div>
                    <b>Order Type:</b> N/A
                  </div>
                )}
              </Grid>
              <Grid xs={2} />
              <Grid xs={2} />
              <Grid xs={4} className="grid-detailed-text-last">
                <b>B-BBEE Level:</b> {record.beeLevel || "N/A"}
              </Grid>
              <Grid xs={4} />
              <Grid xs={2} />
              <Grid xs={2} />
            </Grid>
          )}
        </Card>
      </Grid>
      <SweetAlert2 {...swalProps} onConfirm={() => setSwalProps({})} />
    </StyledEngineProvider>
  );
};
export default SearchRow;
