import { useAppSelector } from "../../app/hooks";

// A hook that checks if a company is from the country as per the passed in country name.
const useSouthAfricaSpecificFeature = () => {
  const countryList = useAppSelector(reduxState => reduxState.countryList);
  const companyData = useAppSelector(reduxState => reduxState.company);

  const southAfricaCountryId = countryList.find((sa) => sa.isDefault)?.id || 0;

  if (southAfricaCountryId === 0 || companyData.countryId !== southAfricaCountryId) return false;

  return true;
}

export default useSouthAfricaSpecificFeature;