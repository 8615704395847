import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewConnectionRequestModel, ViewConnectionRequestArrayModel } from "../Models/ViewConnectionRequestModel";
import { ConnectionRequestArrayModel, ConnectionRequestModel } from "../Models/ConnectionRequestModel";
import { RootState } from "../../../app/store";

const initialConnectionRequestState: ConnectionRequestArrayModel = {
  allConnectionRequests: [],
  particularConnectionRequest: {
    id: 0,
    requestedByCompanyId: 0,
    companyId: 0,
    requestedByUserId: 0,
    invitedContactEmail: "",
    invitedCompanyName: "",
    invitedContactName: "",
    requestType: 0,
    connectionType: 0,
    connectionStatus: 0,
    createdBy: "",
    dateCreated: new Date,
    modifiedBy: "",
    dateModified: new Date,
  },
}

const initialViewConnectionRequestState: ViewConnectionRequestArrayModel = {
  allViewConnectionRequests: [],
  particularViewConnectionRequest: {
    companyId: 0,
    companyName: "",
    invitedContactEmail: "",
    invitedCompanyName: "",
    invitedContactName: "",
    requestType: 0,
    id: 0,
    connectionStatus: 0,
    dateCreated: new Date,
    username: "",
    requestedByCompanyId: 0,
    requestedByUserId: 0,
    connectionType: 0,
    createdBy: "",
    modifiedBy: "",
    dateModified: new Date,
  },
}

export const viewConnectionRequestSlice = createSlice({
  name: "viewConnectionRequest",
  initialState: initialViewConnectionRequestState,
  reducers: {
    setViewConnectionRequest(state, action: PayloadAction<ViewConnectionRequestModel[]>) {
      state.allViewConnectionRequests = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularViewConnectionRequest(state, action: PayloadAction<ViewConnectionRequestModel>) {
      state.particularViewConnectionRequest = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const viewConnectionRequestForCompanySlice = createSlice({
  name: "viewConnectionRequestForCompany",
  initialState: initialViewConnectionRequestState,
  reducers: {
    setViewConnectionRequest(state, action: PayloadAction<ViewConnectionRequestModel[]>) {
      state.allViewConnectionRequests = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularViewConnectionRequest(state, action: PayloadAction<ViewConnectionRequestModel>) {
      state.particularViewConnectionRequest = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const connectionRequestSlice = createSlice({
  name: "connectionRequest",
  initialState: initialConnectionRequestState,
  reducers: {
    setConnectionRequest(state, action: PayloadAction<ConnectionRequestModel[]>) {
      state.allConnectionRequests = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularConnectionRequest(state, action: PayloadAction<ConnectionRequestModel>) {
      state.particularConnectionRequest = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const getCompanyConnectionRequests = (state: RootState) => state.viewConnectionRequest.allViewConnectionRequests;
export const getConnectionRequestsForCompany = (state: RootState) => state.viewConnectionRequestForCompany.allViewConnectionRequests; 