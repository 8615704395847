import Swal from "sweetalert2";
import { OrderByModel } from "../../../http/NetworkAndConnect/Models/OrderByModel";
import OrderByType from "../../../util/enums/OrderByType";
import { ViewConnectionRequestModel } from "../../../http/Redux/Models/ViewConnectionRequestModel";
import { SearchModel } from "../../../http/NetworkAndConnect/Models/SearchModel";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 2;


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export const defaultSearchOrderObject: OrderByModel = {
  fieldName: "RegisteredName",
  orderByDirection: OrderByType.Ascending,
};



export const displayMinimumSearchAlert = () => {
  Swal.fire({
    title: "Minimum Search Requirement",
    text: "Please ensure you have entered 3 or more characters",
    icon: "warning",
  });
};


export const formattedResultsRequests = (result: ViewConnectionRequestModel[]) => {

  const formatted: SearchModel[] = result.map((item: ViewConnectionRequestModel) => ({
    companyId: item.companyId,
    registeredName: item.companyName,
    totalRows: result.filter(x => x.connectionType === 1).length,
    parentCompanyAccountId: 0,
    registrationNumber: "",
    tradingName: "",
    holdingCompanyName: "",
    physicalAddressStreetAddr: "",
    vATNumber: "",
    tradeDescription: "",
    companyType: "",
    country: "",
    province: "",
    city: "",
    email: "",
    mobile: "",
    website: "",
    bOPercentage: 0,
    bWOPercentage: 0,
    beeLevel: "",
    complianceStatus: "",
    qualityScore: 0,
    suggestionCreatedDate: new Date(),
    rating: 0,
    vendorNumber: "",
    excludeFromSpend: true,
    myRegisteredName: "",
    myTradingAs: "",
    muIntegrationNumber: 0,
    mySupplierNotes: "",
    partnerManagedSupplier: false,
    createdDate: new Date(),
    cipc: "",
    sars: "",
    bbbee: "",
    beeRatingStatusId: 0,
    beeClassification: "",
  }))

  return formatted;

}



export default MenuProps;


