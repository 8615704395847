import { useState } from "react";
import { Button, Divider, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import Swal from "sweetalert2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../http/CompanyVerificationService";
import { PartnerManagedCompaniesModel } from "../../http/Redux/Models/PartnerManagedCompaniesModel";
import validateDayJsDate from "../../util/functions/dateValidator";

interface selectedCompany {
  name: string,
  companyId: number,
  date: Date,
  total: number,
  partner: number,
}

const EditClient = ({ back, company }: { back: () => void, company: selectedCompany }) => {

  const [backdropLoading, setBackdropLoading] = useState(false);
  const [reportingPeriodDate, setReportingPeriodDate] = useState<Dayjs>(dayjs(company.date));
  const [selectedSuppliers, setSelectedSuppliers] = useState<number>(company.total);



  const handleEditClientDB = () => {
    const loader = async () => {
      const ClientPartner = {} as PartnerManagedCompaniesModel;
      ClientPartner.clientCompanyId = company.companyId;
      ClientPartner.managingPartnerCompanyId = company.partner;
      ClientPartner.suppliers = selectedSuppliers;
      ClientPartner.isActive = true;
      ClientPartner.stagedClient = false;
      ClientPartner.reportingPeriod = reportingPeriodDate.toDate();
      try {

        const validDate =
          reportingPeriodDate && reportingPeriodDate.isValid()
            ? validateDayJsDate(reportingPeriodDate.toISOString().split("T")[0], "YYYY-MM-DD")
            : false;

        if (!validDate) {
          Swal.fire("Validation Error", "Please select a valid Reporting Period Date", "error");
          return;
        }

        ClientPartner.reportingPeriod = reportingPeriodDate.toDate();

        setBackdropLoading(true);
        await CompanyVerificationService.addDataManagementClient(ClientPartner);

        toast.success("Data Management Client data updated successfully!");
        back();
      } catch (error) {
        toast.error(
          "An error occurred saving the Data Management Client. Please try again, or contact support"
        );
        setBackdropLoading(false);
      } finally {
        setBackdropLoading(false);
      }
    };

    loader();
  };



  return (
    <Grid container sx={{ height: "500px", width: "50%" }}>
      <LoadingBackdrop showBackdrop={backdropLoading} />
      <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
        <Tooltip title="Back To Users">
          <IconButton onClick={back}><ArrowBackIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid xs={12}>
        <h3>{company.name} </h3>
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(event) => setSelectedSuppliers(Number(event.target.value) as number)}
          sx={{ width: "98%" }}
          size="small"
          value={selectedSuppliers}
          id="outlined-basic"
          label="Managed (SLA Suppliers)"
          variant="outlined"
          type="number"
        />
      </Grid>
      <Grid xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Reporting Period"
            value={reportingPeriodDate}
            onChange={(ev) => {
              setReportingPeriodDate(ev || dayjs(""));
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: "100%" }} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} sx={{ textAlign: "right" }}>
        <Divider sx={{ marginBottom: 2 }} />
        <Button variant="contained" disabled={reportingPeriodDate.toString() === "Invalid Date" || selectedSuppliers === 0} onClick={handleEditClientDB}>Update Client</Button>
      </Grid>
    </Grid>
  )
}

export default EditClient;