import React, { useEffect, useState } from "react";
import { Box, AppBar, Tabs, Tab } from "@mui/material";
import BasePage from "../BasePage";
import Partner from "./Partners";
import { fetchDataManagementPartners } from "../../http/Redux/Store/DataManagementPartnerActions";
import { useAppDispatch } from "../../app/hooks";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import Clients from "./Clients";



interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const DataManagementMain = () => {
  const [value, setValue] = useState(0);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };


  useEffect(() => {
    const loader = async () => {
      setBackdropLoading(true);
      dispatch(fetchDataManagementPartners());
      setBackdropLoading(false);
    };
    loader();
  }, [])

  return (
    <BasePage pageHeader="Data Management" pageHeaderSub="Manage partners and their clients">
      <LoadingBackdrop showBackdrop={backdropLoading} />
      <Box className="platformConfigurationBox">
        <AppBar className="app-bar connect" position="static">
          <Tabs
            value={value}
            onChange={(_ev, val: number) => handleChange(val)}
            orientation="horizontal"
            indicatorColor="secondary"
            textColor="inherit"
            aria-label=""
          >
            <Tab label="Data Partners" {...a11yProps(0)} className="app-bar-tabs" />
            <Tab label="Partner Clients" {...a11yProps(1)} className="app-bar-tabs" />
          </Tabs>
        </AppBar>
        <CustomTabPanel value={value} index={0}>
          <Partner />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Clients />
        </CustomTabPanel>
      </Box>
    </BasePage>
  )
}

export default DataManagementMain;