import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useNavigate } from "react-router";
import ManageSearchIcon from "@mui/icons-material/Search";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { CompaniesRequiringVerificationModel } from "../../../http/Redux/Models/CompaniesRequiringVerificationModel";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";
import { useAppSelector } from "../../../app/hooks";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import DataSourceTypes from "../../../util/enums/DataSourceTypes";
import CompanyVerificationService from "../../../http/CompanyVerificationService";


interface ViewCellProps {
  row: CompaniesRequiringVerificationModel;
  stage: boolean;
  Claim: boolean;
  selectedClientId: number;
  // eslint-disable-next-line no-unused-vars
  SetIsClaimed: (IsClaimed: boolean) => void;
  PageSource: number,
}

const ViewCell = ({ row, stage, selectedClientId, Claim, SetIsClaimed, PageSource }: ViewCellProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [activeClaim, setActiveClaim] = useState(false);
  const user = useAppSelector(getUserProfile);

  const handleRedirect = (agentTaskCreatedId: number) => {

    const template = `/workcentre/companyProfile/${selectedClientId}/${row.companyId}/${0}/${stage}/${agentTaskCreatedId}`;
    navigate(template);

  }

  const handleSaveAgentTask = async () => {
    setIsLoading(true);
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.companyId = row.companyId;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = user.id as number;
    model.clientId = selectedClientId;
    model.dataSource = stage ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSource;
    await CompanyVerificationService.addAgentTask(model, stage).finally(() => {
      setIsLoading(false);
      SetIsClaimed(true);
      setActiveClaim(true);
    });
  }

  const handleSaveAgentTaskThenView = async () => {
    setIsLoading(true);
    const model: AgentTaskModel = {} as AgentTaskModel;
    model.companyId = row.companyId;
    model.status = AgentTaskStatusTypes.Assigned;
    model.completion = "0";
    model.comment = "";
    model.agentUserId = user.id as number;
    model.clientId = selectedClientId;
    model.dataSource = stage ? DataSourceTypes.Staged : DataSourceTypes.Platform;
    model.pageSource = PageSource;
    const getAgentTaskId = await CompanyVerificationService.addAgentTask(model, stage).finally(() => {
      setIsLoading(false);
      SetIsClaimed(true);
      setActiveClaim(true);
    });
    handleRedirect(getAgentTaskId as number);
  }


  useEffect(() => {
    setActiveClaim(Claim);
  }, [])


  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };



  return (

    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      {row.assignedTo || activeClaim ? (
        <Tooltip title="Task Is Claimed">
          <IconButton disabled sx={{ ml: 1.5 }}>
            <BookmarkAddedIcon sx={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Claim Actions">
            <IconButton
              onClick={handleClickMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={opens ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={opens ? "true" : undefined}
            >
              <BookmarkIcon />
            </IconButton>
          </Tooltip><Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={opens}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
          >

            <MenuItem onClick={handleSaveAgentTask}>
              <BookmarkAddIcon /> Claim
            </MenuItem>
            <MenuItem onClick={handleSaveAgentTaskThenView}>
              <div>
                <BookmarkIcon />
                <span style={{ width: "20px", marginTop: "10px" }}>
                  <ManageSearchIcon />
                </span>
              </div>
              Claim & View Company Profile
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );

}

export default ViewCell;