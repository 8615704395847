import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetAllCountries } from "../../../http/Company/Country";

const useCountries = () => {
  const query = useQuery({
    queryKey: [queryKeys.countries],
    queryFn: async () => (await GetAllCountries()).data,
    staleTime: 600000, 
  })

  return query;
}

export default useCountries;