// eslint-disable-next-line no-shadow
enum MarketingType { // eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  Email = 1,// eslint-disable-line no-unused-vars
}

export default MarketingType;

export const marketingTypeToString = (value: MarketingType) => {
  switch (value) {
    case MarketingType.Email:
      return "Email"

    default:
      return "None";
  }
};