import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../../app/hooks";
import { logout } from "../../http/Redux/Store/userSlice";

const useAppLogOut = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const logoutOfApp = () => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    dispatch(logout());
    instance.logoutRedirect(logoutRequest);
  };

  return logoutOfApp;
}
export default useAppLogOut;