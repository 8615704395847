import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import SweetAlert2 from "react-sweetalert2";
import "../Connect/SearchResult/SweetAlert.css";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, TextField, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import useAllowedPermission from "../../util/hooks/useAllowedPermission";
import { UserProfileModel } from "../../http/Redux/Models/UserProfileModel";
import UserService from "../../http/UserProfileService";
import AccountStatus from "../../util/enums/AccountStatus";
import TemporaryDrawer from "../../pages/Compliance/TemporaryDrawer";
import PermissionClassification from "../../util/enums/PermissionClassification";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import useFieldValidation from "../../util/hooks/useFieldValidation";



const EditProfile = ({ userProfile }: { userProfile: UserProfileModel }) => {
  const [editable, setEditable] = useState<UserProfileModel>(userProfile || {} as UserProfileModel);
  const [accountStatus, setAccountStatus] = useState<number>(0);
  const [isActiveSave, setIsActiveSave] = useState(false);
  const [isActiveAdd, setIsActiveAdd] = useState(true);
  const [isActiveSuspend, setIsActiveSuspend] = useState(true);
  const [isActiveDeactivate, setIsActiveDeactivate] = useState(false);
  const [isActiveResume, setIsActiveResume] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const hasAllowedPermission = useAllowedPermission();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;

  const [validateField] = useFieldValidation("userProfile");

  const handleOnClickSuspend = () => {

    setIsActiveDeactivate(true);
    setIsActiveSuspend(false);
    setIsActiveResume(true);
    setIsActive(false);
    setAccountStatus(AccountStatus.Suspended);
  };
  const handleOnClickResume = () => {

    setIsActiveDeactivate(false);
    setIsActiveSuspend(true);
    setIsActiveResume(false);
    setIsActive(false);
    setAccountStatus(AccountStatus.Active);
  };
  const handleOnClickDeactivate = () => {

    setIsActiveDeactivate(false);
    setIsActiveSuspend(false);
    setIsActiveResume(false);
    setIsActive(true);
    setAccountStatus(AccountStatus.Deactivated);
  };
  const handleOnClickActive = () => {

    setIsActiveDeactivate(false);
    setIsActiveSuspend(true);
    setIsActiveResume(false);
    setIsActive(false);
    setAccountStatus(AccountStatus.Active);
  };



  const handleClickSave = () => {

    const loader = async () => {
      const profile = editable;
      profile.dateModified = new Date;
      profile.modifiedBy = userAccount;
      const userResponse = await UserService.updateUser(editable);
      setUpdatedInfo(userResponse);

    };

    loader();
    setIsActiveSave(!isActiveSave);
    setIsActiveAdd(!isActiveAdd);
    // setShowDrawer(false);
    setIsDisabled(true);
    if (!loading) {
      setLoading(true);
      setShowBackdrop(true);
    }
  };

  useEffect(() => {

    if (editable.name === "") {
      setEditable(userProfile);
    }
    else {
      setEditable({
        ...editable,
        profileFileName: editable.profileFileName,
        id: editable.id,
        name: editable.name,
        surname: editable.surname,
        mobile: editable.mobile,
        email: editable.email,
        accountId: editable.accountId,
        dateCreated: editable.dateCreated,
        dateModified: editable.dateModified,
        modifiedBy: editable.modifiedBy,
        createdBy: editable.createdBy,
        defaultCompany: editable.defaultCompany,
        notificationType: editable.notificationType,
        visibilityType: editable.visibilityType,
        accountStatus,
        companyId: editable.companyId,
        isActive: editable.isActive,
      });
    }

    if (updatedInfo === 200) {
      setIsDisabled(false);
      setLoading(false);
      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });
      setTimeout(() => {
        window.location.reload();
      }, Number(timeVal));
    }

    if (editable.accountStatus === AccountStatus.Suspended) {
      setIsActiveSuspend(false);
      setIsActiveDeactivate(true);
      setIsActiveResume(true);
      setIsActive(false);
    }
    if (editable.accountStatus === AccountStatus.Deactivated) {
      setIsActiveSuspend(false);
      setIsActiveDeactivate(false);
      setIsActiveResume(false);
      setIsActive(true);
    }

  }, [accountStatus, editable.id, updatedInfo, userProfile, editable, timeVal]);

  return (


    <div>
      <Button className="edit-icon-btn" id="step-info" onClick={() => setShowDrawer(true)}><EditIcon /></Button>
      <TemporaryDrawer
        showDrawer={showDrawer}
        onCloseDrawerCallback={() => setShowDrawer(false)}>
        <>
          <Box className="edit-panel-close-box">
            <IconButton onClick={() => setShowDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box role="presentation" className="edit-panel-box">
            <LoadingBackdrop showBackdrop={showBackdrop} />
            <form>
              <div className="heading-sub">Edit User Profile</div>
              <div className="hr-line" />
              <TextField value={editable.name}
                onChange={(ev) => { setEditable({ ...editable, name: ev.target.value }); }}
                margin="dense"
                disabled={isDisabled}
                id="name"
                required
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Name"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                error={validateField("Name", editable?.name ?? "").isInvalid}
                helperText={validateField("Name", editable?.name ?? "").validationErrorText}
              />
              <TextField value={editable.surname}
                onChange={(ev) => { setEditable({ ...editable, surname: ev.target.value }); }}
                margin="dense"
                disabled={isDisabled}
                required
                id="surname"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Surname"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                error={validateField("Surname", editable?.surname ?? "").isInvalid}
                helperText={validateField("Surname", editable?.surname ?? "").validationErrorText}
              />
              <TextField value={editable.mobile}
                onChange={(ev) => { setEditable({ ...editable, mobile: ev.target.value }); }}
                margin="dense"
                disabled={isDisabled}
                id="Contact number"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Contact number"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                error={validateField("Contact number", editable?.mobile ?? "").isInvalid}
                helperText={validateField("Contact number", editable?.mobile ?? "").validationErrorText}
              />
              <TextField value={editable.email}
                onChange={(ev) => { setEditable({ ...editable, email: ev.target.value }); }}
                margin="dense"
                disabled={isDisabled}
                required
                id="email"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Email"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                error={validateField("Email", editable?.email ?? "").isInvalid}
                helperText={validateField("Email", editable?.email ?? "").validationErrorText}
              />

              {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) ? <div className="edit-user-btns-wrap">
                <div className="edit-user-btns-wrap-one">
                  <div className={isActiveResume ? "active" : "not-active"}>
                    <Button onClick={handleOnClickResume} variant="outlined" className="form-add-btn">
                      <span className="button-span-resume"> Resume</span>
                    </Button>
                  </div>
                  <div className={isActive ? "active" : "not-active"}>
                    <Button variant="outlined" onClick={handleOnClickActive} className="form-add-btn">
                      <span className="button-span-resume"> Activate</span>
                    </Button>
                  </div>
                  <div className={isActiveSuspend ? "active" : "not-active"}>
                    <Button onClick={handleOnClickSuspend} variant="outlined" className="form-add-btn">
                      <span className="button-span-suspend">Suspend</span>
                    </Button>
                  </div>
                </div>
                <div className="edit-user-btns-wrap-two">
                  <div className={isActiveDeactivate ? "active" : "not-active"}>
                    <Button variant="outlined" onClick={handleOnClickDeactivate} className="form-add-btn">
                      <span className="button-span-deactivate">Deactivate</span>
                    </Button>
                  </div>

                </div>
              </div> : ""}
              <div className="add-panel-footer">
                <div className="add-panel-save-btn-box">
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      onClick={handleClickSave}
                      disabled={loading}
                    >
                      Save
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "20%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </div>
              </div>
            </form>
          </Box>
        </>
      </TemporaryDrawer>
      <SweetAlert2 {...swalPropsChange} />
    </div>



  )
};

export default EditProfile;