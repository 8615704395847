import {
  useQuery,
} from "@tanstack/react-query"
import { useAccount, useMsal } from "@azure/msal-react";
import queryKeys from "./queryKeys";
import { GetChatList } from "../../../http/Messaging/Messaging";

const useMessaging = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const query = useQuery({
    queryKey: [queryKeys.messaging, account?.idTokenClaims?.extension_companyid],
    queryFn: () => GetChatList(account?.idTokenClaims?.extension_companyid as number),
  })

  return query;
}

export default useMessaging;