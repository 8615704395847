import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { AdminDashboardSummaryMessage } from "./Models/AdminDashboardSummary";

// eslint-disable-next-line import/prefer-default-export
const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves admin dashboard summary
 */
// eslint-disable-next-line import/prefer-default-export
export const GetAdminDashboardSummary = async () => {
  const url = `${baseUrl}/api/Company/AdminDashboard`;
  return (await makeGetRequestAsync<AdminDashboardSummaryMessage>(apiScopes, url)).data;
}

