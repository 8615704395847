import { CompanyProfileCompleteSummaryMessage } from "../../http/Company/Messages/companyProfileCompletionSummaryMessage";
import { ProfileCompletionSection } from "../helperObjects/ProfileCompletionSection";

export const getValidProfileSections = (summaryObject: CompanyProfileCompleteSummaryMessage) => {
  const validSections = Object.entries(summaryObject)
    .filter((entry) => entry[0].toLowerCase() !== "bankdetails")
    .map(
      (section) =>
        ({
          sectionName: section[0],
          sectionComplete: section[1],
        } as ProfileCompletionSection)
    );

  return validSections;
};

export const getValidProfileSectionsCount = (summaryObject: CompanyProfileCompleteSummaryMessage) =>
  Object.entries(summaryObject).filter((entry) => entry[0].toLowerCase() !== "bankdetails").length;

export const getCompletedProfileSectionCount = (
  summaryObject: CompanyProfileCompleteSummaryMessage
) => {
  if (!summaryObject) return 0;

  const valsToProcess = getValidProfileSections(summaryObject);

  const initialVal = 0;
  const completedSectionCount: number = valsToProcess.reduce((accumulator, currentVal) => {
    if (currentVal.sectionComplete) return accumulator + 1;

    return accumulator;
  }, initialVal) as number;

  return completedSectionCount;
};

export const mapSummaryNamesToProperNames = (sectionName: string) => {
  switch (sectionName.toLowerCase()) {
    case "general":
      return "General";
    case "contacts":
      return "Contacts";
    case "addresses":
      return "Company Registered Address";
    case "beedetails":
      return "B-BBEE Details";
    case "documents":
      return "Documentation";
    // case "bankdetails":
    //   return "Bank Details"
    case "productsandservice":
      return "Products & Services";
    default:
      return "";
  }
};
