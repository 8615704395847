import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompanyExtendedDetailsArrayModel, CompanyExtendedDetailsModel } from "../Models/CompanyExtendedDetailsModel";


const initialExtendedDetailsState: CompanyExtendedDetailsArrayModel = {
  particularCompanyExtendedDetails: {
    companyId: 0,
    numberOfEmployees: 0,
    dateCreated: new Date(),
    isActive: true,
    dateModified: new Date(),
    modifiedBy: "",
    createdBy: "",
    deletedBy: "",
    dateDeleted: new Date(),
    isDeleted: false,
  },
}

const CompanyExtendedDetailSlice = createSlice({
  name: "companyExtendedDetails",
  initialState: initialExtendedDetailsState,
  reducers: {
    setParticularCompanyExtendedDetail(state, action: PayloadAction<CompanyExtendedDetailsModel>) {
      state.particularCompanyExtendedDetails = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export default CompanyExtendedDetailSlice;


export const getCompanyExtendedDetails = (state: RootState) => state.companyExtendedDetails.particularCompanyExtendedDetails;