import { useAccount, useMsal } from "@azure/msal-react";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GetBaseCompanyInfoViaCompanyId, GetCompanyAdminUserId, GetCompanyAdminUserInfo, GetCompanyDataById } from "../../../http/Company/company";
import { ConnectionModel } from "../../../http/Connect/Models/ConnectionModel";
import { NewConnectionDto } from "../../../http/NetworkAndConnect/Dtos/NewConnectionDto";
import { BuyerConnectionCompletedMessage } from "../../../http/NetworkAndConnect/Models/BuyerConnectionCompletedMessage";
import { ConnectionRuleModel } from "../../../http/NetworkAndConnect/Models/ConnectionRuleModel";

// eslint-disable-next-line no-unused-vars
import { AddNewConnection, GetRuleEvaluation, SendBuyerConnectionCompleteEmail } from "../../../http/NetworkAndConnect/Netconn";
import { NotificationMessage } from "../../../http/Notifications/Models/NotificationMessage";
import { AddNewNotification } from "../../../http/Notifications/Notification";
import { selectBaseCompanyInfo } from "../../../http/Redux/Store/baseCompanyInfoSlice";
import { selectCountryList } from "../../../http/Redux/Store/CountryListSlice";
import { fetchCompanyNetwork, netconnNetworkConnectionActions } from "../../../http/Redux/Store/NetworkAndConnectActions";
import AddressType from "../../../util/enums/AddressType";
import CompanyProfileSideBar from "../../../util/enums/CompanyProfileSideBar";
import ConnectionType from "../../../util/enums/ConnectionType";
import ContactType from "../../../util/enums/ContactType";
import NotificationEntity from "../../../util/enums/NotificationEntity";
import NotificationSeverity from "../../../util/enums/NotificationSeverity";
import ServiceType from "../../../util/enums/ServiceType";
import LinearProgressWithLabel from "../../UI/LinearProgressWithLabel";
import { AddConnectionDocuments, checkAgainstRulesetAttributes, checkAgainstRulesetDocuments, checkMissingCustomDocs } from "./ConnectionRuleLogic";
import { ConnectionRuleResult } from "./ConnectionRuleResult";
import RuleResultRow from "./RuleResultRow";

interface ConnectRuleDialogProps {
  showDialog: boolean;
  handleCloseCallback: () => void;
  evaluatingCompanyId: number;
  evaluatedCompanyId: number;
  connectionTypeId: ConnectionType;
  connectionRequestId: number;

  // eslint-disable-next-line react/require-default-props
  isBuyerRequest?: boolean;
}

const ConnectRuleDialog = ({
  showDialog,
  handleCloseCallback,
  evaluatingCompanyId,
  evaluatedCompanyId,
  connectionTypeId,
  connectionRequestId,
  isBuyerRequest = false,
}: ConnectRuleDialogProps) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";

  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const countries = useAppSelector(selectCountryList);
  const dispatch = useAppDispatch();
  const companyBaseData = useAppSelector(selectBaseCompanyInfo);

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [, setEvaluationResult] = useState<ConnectionRuleModel>({} as ConnectionRuleModel);

  const fetchEvaluation = async (evaluatingId: number, evaluatedId: number) => {
    const result = await GetRuleEvaluation(evaluatingId, evaluatedId);
    setEvaluationResult(result);
    return result;
  };

  /* State variables */
  const [, setRulePassPercentage] = useState<number>(0);

  const calculateSuccessPercentage = (
    successfulAttrs: number,
    successfulDocuments: number,
    totalRules: number
  ) => ((successfulAttrs + successfulDocuments) / (totalRules)) * 100


  const attributeRedirect = () => {
    MySwal.close();
    navigate("/companyProfile");
  };

  const documentRedirect = async () => {
    const evaluation = await fetchEvaluation(evaluatingCompanyId, evaluatedCompanyId);
    const missingOptionalDocs = checkMissingCustomDocs(evaluation);
    try {
      if (missingOptionalDocs) MySwal.close();

      MySwal.fire({
        title: "Loading",
        showConfirmButton: true,
        didOpen() {
          const confirmButton = MySwal.getConfirmButton() as HTMLButtonElement;
          MySwal.showLoading(confirmButton);
        },
      });

      await AddConnectionDocuments(
        missingOptionalDocs,
        evaluatingCompanyId,
        evaluatedCompanyId,
        userAccount?.toString()
      );

      MySwal.close();
      navigate(`/companyProfile/${CompanyProfileSideBar.Documents}/${evaluatingCompanyId}/${evaluatedCompanyId}`);
    } catch (error) {
      MySwal.fire({
        title: "An error has occurred",
        text: `Unfortunately, an error has occurred: ${error}`,
        showCancelButton: true,
        didClose() {
          handleCloseCallback();
        },
      });
    }
  };

  const getDialogRuleResultElement = (
    passedAttrsArray: Array<string>,
    passedDocsArray: Array<string>,
    failedAttrsArray: Array<string>,
    failedDocsArray: Array<string>,
    ruleSuccessPercentage: number
  ) => (
    <div>
      <h2>
        <u>Passed Rules</u>
      </h2>
      <br />
      <Grid container>
        {passedAttrsArray.map((attr) => (
          <RuleResultRow type="Attribute" name={attr} linkFunction={() => false} />
        ))}
      </Grid>
      <Grid container>
        {passedDocsArray.map((doc) => (
          <RuleResultRow isDoc type="Document" name={doc} linkFunction={() => false} />
        ))}
      </Grid>
      <br />
      <hr />
      <br />
      <h2>
        <u>Failed Rules</u>
      </h2>
      <br />
      <Grid container>
        {failedAttrsArray.map((attr) => (
          <RuleResultRow isFail type="Attribute" name={attr} linkFunction={attributeRedirect} />
        ))}
      </Grid>
      <Grid container>
        {failedDocsArray.map((doc) => (
          <RuleResultRow isFail isDoc type="Document" name={doc} linkFunction={documentRedirect} />
        ))}
      </Grid>
      <hr />
      <br />
      <Grid container sx={{ flexDirection: "column" }}>
        <Grid item>
          <Typography variant="subtitle1">
            Percentage of Rules Passed:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <LinearProgressWithLabel value={ruleSuccessPercentage} />
        </Grid>

      </Grid>
    </div>
  );

  const analyseEvaluationModel = (evaluationModel: ConnectionRuleModel) => {
    // Get the entries from the eval model.
    const evalObjectProperties = Object.keys(evaluationModel.evaluation);

    // Generate a list of attributes and documents from the passed in ruleset metadata.
    const evalAttributeNames = evaluationModel.attributes.map((model) => model.columnName);
    const evalDocumentNames = evaluationModel.documents.map((model) => model.name);

    const [successAttr, failedAttr] = checkAgainstRulesetAttributes(
      evaluationModel,
      evalAttributeNames,
      evalDocumentNames,
      evalObjectProperties
    );
    const [successDocs, failedDocs] = checkAgainstRulesetDocuments(
      evaluationModel,
      evalAttributeNames,
      evalDocumentNames,
      evalObjectProperties
    );

    const successPercentage = calculateSuccessPercentage(
      successAttr.length,
      successDocs.length,
      evaluationModel.attributes.length + evaluationModel.documents.length
    );

    setRulePassPercentage(successPercentage); // Nuke if needed.

    const element = getDialogRuleResultElement(successAttr, successDocs, failedAttr, failedDocs, successPercentage);
    const hasFailures = (failedAttr.length > 0 || failedDocs.length > 0)

    const returnObj = {} as ConnectionRuleResult;
    returnObj.element = element;
    returnObj.hasFailures = hasFailures;
    returnObj.percentage = successPercentage;

    return returnObj
  };

  const createConnection = async (
    fromCompanyId: number,
    toCompanyId: number,
    connectionTypeIdVal: number,
    connectionRequestIdVal: number,
    userId: string | undefined
  ) => {
    const connectionDto: NewConnectionDto = {} as NewConnectionDto;
    connectionDto.fromCompanyId = fromCompanyId;
    connectionDto.toCompanyId = toCompanyId;
    connectionDto.connectionTypeId = connectionTypeIdVal;
    connectionDto.userId = userId || "";
    connectionDto.connectionRequestId = connectionRequestIdVal;

    const result = await AddNewConnection(connectionDto);
    return result;
  };

  const sendConnectionCompleteMail = async (evaluatorCompanyId: number, evaluateeCompanyId: number) => {
    if (!isBuyerRequest) {
      return;
    }

    const fromCompanyData = await GetBaseCompanyInfoViaCompanyId(evaluatorCompanyId, false);
    const toCompanyData = await GetBaseCompanyInfoViaCompanyId(evaluateeCompanyId, false);
    const fromCompanyAdminCall = await (await GetCompanyAdminUserInfo(evaluatorCompanyId)).data;

    // eslint-disable-next-line no-unused-vars
    const buyerConnCompletedObj: BuyerConnectionCompletedMessage = {
      companyId: evaluatorCompanyId,
      companyName: fromCompanyData.companyRegisteredName,
      recipientName: (fromCompanyAdminCall.givenName && fromCompanyAdminCall.surname) ?
        `${fromCompanyAdminCall.givenName} ${fromCompanyAdminCall.surname}` :
        "Valued Customer",
      supplierCompanyName: toCompanyData.companyRegisteredName,
      buyerEmailAddress: fromCompanyAdminCall.mail,
      supplierEmailAddress: "",
    }

    await SendBuyerConnectionCompleteEmail(buyerConnCompletedObj);
  };

  const sendConnectNotification = async (toCompanyId: number) => {
    const sender = userAccount || "";
    const recipient = await GetCompanyAdminUserId(toCompanyId);
    const senderCompanyName: string = companyBaseData.companyRegisteredName;

    if (!sender)
      throw new Error("Unable to send message. Sender not provided.");

    if (!recipient)
      throw new Error("Unable to send message. Recipient not provided.");

    if (recipient === "404") {
      throw new Error("Unable to send message. Recipient was not found in the system.");
    }

    const notificationMsg: NotificationMessage = {
      recipientAccountId: recipient,
      senderAccountId: sender,
      serviceType: ServiceType.NetworkConnect,
      notificationType: NotificationEntity.NetConn_NewConnection,
      notificationSeverityLevel: NotificationSeverity.Success,
      message: `The company "${senderCompanyName}" has connected to your company!`,
      senderCompanyId: null,
      buttonTitle: "",
      redirectUrl: "",
    }

    await AddNewNotification(notificationMsg);
  }

  const buildNewConnectionModel = async (newCompanyId: number) => {
    const newCompanyInfo = await (await GetCompanyDataById(newCompanyId.toString(), false)).data;

    const newConnection: ConnectionModel = {} as ConnectionModel;
    newConnection.companyId = evaluatedCompanyId;
    newConnection.registrationNumber = newCompanyInfo.registrationNumber;
    newConnection.registeredName = newCompanyInfo.registeredName;
    newConnection.tradingName = newCompanyInfo.tradingName;
    newConnection.holdingCompanyName = newCompanyInfo.holdingCompanyName;

    const physicalAddress = newCompanyInfo.companyAddresses.find((a) => a.addressTypeId === AddressType.Physical);
    const primaryContact = newCompanyInfo.companyContacts.find((c) => c.contactTypeId === ContactType.Primary);

    newConnection.physicalAddressStreetAddr = `${physicalAddress?.line1} ${physicalAddress?.line2}`;
    newConnection.city = physicalAddress?.city || "";
    newConnection.country = countries.find((c) => c.id === newCompanyInfo.countryId)?.name || "";
    newConnection.email = primaryContact?.email || "";
    newConnection.mobile = primaryContact?.mobile || "";
    newConnection.website = primaryContact?.website || "";
    newConnection.beeCertificateLevel = `${newCompanyInfo.companyBeeDetails?.certificateLevelId}` || "";
    newConnection.complianceStatus = "";

    return newConnection;
  }

  useEffect(() => {
    if (showDialog) {
      const handleShow = () => {
        MySwal.fire({
          title: "Connection Rule Check",
          text: "Rules for connecting to this company need to be checked. Please click the Check button to proceed.",
          showCancelButton: true,
          confirmButtonText: "Check",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            let resultArray: ConnectionRuleResult = {} as ConnectionRuleResult;
            try {
              const apiResult = await fetchEvaluation(evaluatingCompanyId, evaluatedCompanyId);
              resultArray = analyseEvaluationModel(apiResult);
            } catch (error) {
              MySwal.showValidationMessage(`Request has failed: ${error}`);
            }

            return resultArray;
          },
          didClose() {
            handleCloseCallback();
          },
          allowOutsideClick: () => !MySwal.isLoading(),
        }).then((result) => {
          try {
            if (result.isConfirmed) {
              MySwal.fire({
                title: "Evaluation Result:",
                html: result.value?.element,
                showCancelButton: true,
                width: "800px",
                showConfirmButton: !result.value?.hasFailures,
                showLoaderOnConfirm: true,
                confirmButtonText: "Connect",
                didClose() {
                  handleCloseCallback();
                },
                preConfirm: async () => {
                  let connResult = 0;

                  connResult = await createConnection(
                    evaluatingCompanyId,
                    evaluatedCompanyId,
                    connectionTypeId,
                    connectionRequestId,
                    userAccount
                  );

                  const newConn = await buildNewConnectionModel(evaluatingCompanyId);

                  if (connectionTypeId === ConnectionType.Client) {
                    dispatch(netconnNetworkConnectionActions.addCompanyClientConnection(newConn));
                  }
                  else {
                    dispatch(netconnNetworkConnectionActions.addCompanySupplierConnection(newConn));
                  }

                  return connResult;
                },
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEscapeKey: false,
              }).then(async (operationResult) => {

                if (operationResult.isDismissed)
                  return;

                const successCodes = [200, 204];
                if (!successCodes.includes(operationResult.value as number)) {
                  MySwal.fire({
                    title: "Connection Failed",
                    text: "The connection failed to create. Please try again later, or contact support.",
                    icon: "error",
                    showConfirmButton: false,
                    showCloseButton: true,
                    didClose() {
                      handleCloseCallback();
                    },
                  });
                  return;
                }

                MySwal.fire({
                  title: "Connection Completed",
                  text: "The connection has been made!",
                  icon: "success",
                  showConfirmButton: true,
                  confirmButtonText: "Close",
                  showCloseButton: true,
                  allowEnterKey: false,
                  allowEscapeKey: false,
                  allowOutsideClick() {
                    return false;
                  },
                }).then(async () => {
                  try {
                    await sendConnectNotification(evaluatingCompanyId);
                    await sendConnectionCompleteMail(evaluatingCompanyId, evaluatedCompanyId);

                    // Refresh the network connections:
                    dispatch(fetchCompanyNetwork(companyBaseData.companyId));
                    handleCloseCallback();
                  }
                  catch (notificationError) {
                    toast.error("An error occurred during connection processing. Please try again, or contact support");
                  }
                });
              });
            }
          } catch (error) {
            MySwal.fire({
              title: "An error has occurred",
              text: `Unfortunately, an error has occurred: ${error}`,
              showCancelButton: true,
              didClose() {
                handleCloseCallback();
              },
            });
          }
        });
      };

      handleShow();
    }
  }, [showDialog]);

  return <SweetAlert2 />;
};

export default ConnectRuleDialog;
