import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PendingIcon from "@mui/icons-material/Pending";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import styles from "../../pages/Search/Search.module.scss";
import ContactType from "../../util/enums/ContactType";
import B1link from "../../util/images/b1link.svg";
import CompanyProfileService from "../../http/CompanyProfileService";
import { CompanyProfileImageModel } from "../../http/Redux/Models/CompanyProfileImageModel";
import bufferProfile from "../../util/images/filler.png";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import Quixote from "../PDF/PDF";
import { UnspscSegment } from "../../http/Configuration/UnspscSegment";
import { setUnspscOptionsState } from "../../http/Redux/Store/unspscOptionsSlice";
import getUnspscOptions, {
  getCompanyUnspscSelection,
} from "../../http/Configuration/configurationApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import AddressType from "../../util/enums/AddressType";
import { ComplianceDetailsModel } from "../../http/Company/Models/ComplianceDetailsModel";
import { ComplianceVerificationTypeEnum } from "../../http/Compliance/ComplianceVerificationTypesEnum";
import useAllowedPermission, {
  useHasAnyAllowedPermissions,
} from "../../util/hooks/useAllowedPermission";
import PermissionClassification from "../../util/enums/PermissionClassification";
import useCompanyTypes from "../../util/hooks/queries/useCompanyTypes";

export interface SearchProps {
  isOpen: boolean;
  selectedCompany: CompanyViewModel;
}

const CompanySearchView = ({ isOpen, selectedCompany }: SearchProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const [beeStatus, setBeeStatus] = useState<boolean>(false);
  const generalContact = selectedCompany?.companyContacts?.filter(
    (x) => x.contactTypeId === ContactType.GeneralCompany
  )[0];
  const [expiryDateString, setExpiryDateString] = useState<string>("");
  const [profileImage, setProfileImage] = useState<CompanyProfileImageModel>({
    url: bufferProfile,
  } as CompanyProfileImageModel);
  const [companyUnspscSelection, setCompanyUnspscSelection] = useState<number[]>([]);
  const [products, setProducts] = useState<(UnspscSegment | null)[]>();
  const unspscOptions = useAppSelector((state) => state.unspscOptions);
  const { data: companyTypesList } = useCompanyTypes();
  const [companyTypeName, setCompanyTypeName] = useState("");
  const dispatch = useAppDispatch();
  const hasPermission = useAllowedPermission();
  const hasAnyPermission = useHasAnyAllowedPermissions();

  // Evaluates the compliance status of a selected company, and returns a React object for the CompanySearchView to display
  const evaluateComplianceStatus = (
    companyComplianceDetails: Array<ComplianceDetailsModel> | null,
    title: string,
    complianceType: ComplianceVerificationTypeEnum = ComplianceVerificationTypeEnum.None
    // eslint-disable-next-line consistent-return
  ) => {
    if (!companyComplianceDetails || companyComplianceDetails.length === 0) {
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <QuestionMarkIcon className="cl-gray" />
          </Grid>
          <Grid xs={12} className="cl-gray">
            N/A
          </Grid>
        </>
      );
    }

    const complianceStatus = companyComplianceDetails.find(
      (complianceDetails) => complianceDetails.verificationTypeId === complianceType
    );

    if (!complianceStatus)
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <QuestionMarkIcon className="cl-gray" />
          </Grid>
          <Grid xs={12} className="cl-gray">
            N/A
          </Grid>
        </>
      );

    if (complianceStatus.failedChecks > 0)
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <CloseIcon className="cl-red" />
          </Grid>
          <Grid xs={12} className="cl-red">
            Failed
          </Grid>
        </>
      );

    if (complianceStatus.pendingChecks > 0)
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <HourglassTopIcon className="cl-yellow" />
          </Grid>
          <Grid xs={12}>Pending</Grid>
        </>
      );

    if (complianceStatus.outstandingChecks > 0)
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <PendingIcon className="cl-orange" />
          </Grid>
          <Grid xs={12}>Outstanding</Grid>
        </>
      );

    if (complianceStatus.passedChecks === complianceStatus.totalChecks)
      return (
        <>
          <Grid xs={12}>{title}</Grid>
          <Grid xs={12}>
            <CheckIcon className="cl-green" />
          </Grid>
          <Grid xs={12} className="cl-green">
            Passed
          </Grid>
        </>
      );
  };

  const loadSelection = useCallback(async () => {
    if (!unspscOptions || Object.keys(unspscOptions).length === 0) {
      const result = await getUnspscOptions();
      dispatch(setUnspscOptionsState(result));
    }

    if (!companyUnspscSelection || companyUnspscSelection.length === 0) {
      const companySelection = await getCompanyUnspscSelection();
      setCompanyUnspscSelection(companySelection);
    }

    if (selectedCompany.companyTypeId !== 0) {
      const name = companyTypesList?.find(
        (companyType) => companyType.id === selectedCompany.companyTypeId
      )?.name;
      setCompanyTypeName(name || "N/A");
    }

    const selectedItems = unspscOptions.segments.map((seg) => {
      const segmentFamilies = seg.families.filter((x) => companyUnspscSelection.includes(x.id));
      if (segmentFamilies.length > 0) {
        return { ...seg, families: segmentFamilies } as UnspscSegment;
      }
      return null;
    });

    setProducts(selectedItems);
  }, []);

  const loader = useCallback(async () => {
    setBackdropVisible(true);
    if (selectedCompany.companyBeeDetails) {
      setBeeStatus(
        selectedCompany?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate !== null &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate?.length > 0 &&
          new Date(selectedCompany?.companyBeeDetails?.certificateExpiryDate) > new Date()
      );
      setExpiryDateString(
        selectedCompany?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate !== null &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate?.length > 0
          ? new Date(selectedCompany?.companyBeeDetails?.certificateExpiryDate)
            .toLocaleDateString()
            .replace(/\//g, "-")
          : ""
      );
    } else {
      setBeeStatus(false);
    }

    const companyProfileImage = await CompanyProfileService.getParticularProfileImage(
      selectedCompany.companyId,
      false
    );

    setProfileImage(companyProfileImage.data);
    if (
      companyProfileImage.data.url === "" ||
      companyProfileImage.data.url === null ||
      companyProfileImage.data.url === undefined ||
      companyProfileImage.status === 500 ||
      companyProfileImage.status === 404
    ) {
      setProfileImage({ ...profileImage, url: bufferProfile });
    }

    await loadSelection();
  }, []);

  useEffect(() => {
    let loadData = true;

    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (loadData) {
      try {
        loader();
      } finally {
        setBackdropVisible(false);
      }
    }

    return () => {
      loadData = false;
    };
  }, [unspscOptions?.segments?.length, companyUnspscSelection?.length]);

  const beeValidation = () => (
    <Grid container item xs={3} alignContent="space-between">
      <Grid xs={12}>
        B-BBEE <br /> Validation
      </Grid>
      {selectedCompany.companyBeeDetails ? (
        <Grid xs={12}>
          {selectedCompany?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate !== null &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate?.length > 0 &&
          new Date(selectedCompany?.companyBeeDetails?.certificateExpiryDate) > new Date() ? (
              <CheckIcon className="cl-green" />
            ) : (
              <CloseIcon className="cl-red" />
            )}
        </Grid>
      ) : (
        <Grid xs={12}>
          <CloseIcon className="cl-red" />
        </Grid>
      )}
      {selectedCompany.companyBeeDetails ? (
        <Grid xs={12}>
          {selectedCompany?.companyBeeDetails?.certificateExpiryDate !== undefined &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate !== null &&
          selectedCompany?.companyBeeDetails?.certificateExpiryDate?.length > 0 &&
          new Date(selectedCompany?.companyBeeDetails?.certificateExpiryDate) > new Date() ? (
              <Grid xs={12} className="cl-green">
                Passed
              </Grid>
            ) : (
              <Grid xs={12} className="cl-red">
                Failed
              </Grid>
            )}
        </Grid>
      ) : (
        <Grid xs={12} className="cl-red">
          Failed
        </Grid>
      )}
    </Grid>
  );
  return (
    <>
      <LoadingBackdrop showBackdrop={backdropVisible} />
      <div className={open ? "active" : "not-active"}>
        <Button
          onClick={async () => {
            const doc = (
              <Quixote
                selectedCompany={selectedCompany}
                profile={profileImage.url as string}
                expiryDateString={expiryDateString}
                products={products as (UnspscSegment | null)[]}
              />
            );
            const asPdf = pdf([] as any); // {} is important, throws without an argument
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            saveAs(blob, "document.pdf");
          }}
        >
          Download PDF
        </Button>
        <Box className={styles.box}>
          <div className={styles.companyViewBox}>
            <div className={styles.companyViewRight}>
              <img src={profileImage.url} alt="" className={styles.companyProfileImage} />
            </div>
            <div className={styles.companyViewLeft}>
              <h2>{selectedCompany.registeredName}</h2>
              <h3>{selectedCompany.tradingName}</h3>
            </div>
          </div>
          <div className={styles.companyDetailsHeader}>
            <h3>Company Details</h3>
          </div>
          <div className={styles.sectionDetailsJoined}>
            <div className={styles.sectionDetailsFirstLeft}>
              <Grid container>
                <Grid xs={6}>Company Type</Grid>
                <Grid xs={6}>{companyTypeName}</Grid>
                <Grid xs={6}>Company Registration Number</Grid>
                <Grid xs={6}>{selectedCompany.registrationNumber || ""}</Grid>
                <Grid xs={6}>Vat Registration Number</Grid>
                <Grid xs={6}>{selectedCompany.vatNumber || ""}</Grid>
                <Grid xs={6}>B-BBEE</Grid>
                <Grid xs={6}>
                  {`Black Women Owned: ${selectedCompany?.companyBeeDetails?.bwoPercentage || 0}%`}
                  <br />
                  {`Black Owned: ${selectedCompany?.companyBeeDetails?.boPercentage || 0}%`}
                  <br />
                  {`Expiry Date:${expiryDateString || ""}`}
                </Grid>
                <Grid xs={6}>Company Contact Number</Grid>
                <Grid xs={6}>{generalContact ? generalContact.mobile : ""}</Grid>
                <Grid xs={6}>Company Email</Grid>
                <Grid xs={6}>{generalContact ? generalContact.email : ""}</Grid>
              </Grid>
            </div>
            <div className={styles.sectionDetailsFirstRight}>
              <Typography component="legend">Rating</Typography>
              <Rating name="read-only" value={0} readOnly />
            </div>
          </div>
          <div className={styles.companyaddressHeader}>
            <div className={styles.companyaddressHeaderLeft}>
              <h3>Physical</h3>
            </div>
            <div className={styles.companyaddressHeaderRight}>
              <h3>Postal</h3>
            </div>
          </div>
          <div className={styles.sectionDetailsJoined}>
            {selectedCompany?.companyAddresses && (
              <>
                <div className={styles.sectionDetailsLeft}>
                  <Grid container>
                    <Grid xs={12}>
                      {selectedCompany?.companyAddresses?.length !== 0 ? (
                        selectedCompany?.companyAddresses
                          .filter((x) => x.addressTypeId === AddressType.Physical)
                          ?.map((records) => (
                            <>
                              <Grid xs={12}>{records.line1}</Grid>
                              <Grid xs={12}>{records.line2}</Grid>
                              <Grid xs={12}>{records.city}</Grid>
                            </>
                          ))
                      ) : (
                        <Typography sx={{ color: "gray" }}>No address set.</Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.sectionDetailsRight}>
                  <Grid container>
                    <Grid xs={12}>
                      {selectedCompany?.companyAddresses?.length !== 0 ? (
                        selectedCompany?.companyAddresses
                          .filter((x) => x.addressTypeId === AddressType.Postal)
                          .map((records) => (
                            <>
                              <Grid xs={12}>{records.line1}</Grid>
                              <Grid xs={12}>{records.line2}</Grid>
                              <Grid xs={12}>{records.city}</Grid>
                              <Grid xs={12}>{records.postalCode}</Grid>
                            </>
                          ))
                      ) : (
                        <Typography sx={{ color: "gray" }}>No postal address set.</Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
          <div className={styles.companyServiceHeader}>
            <h3>Service Description</h3>
          </div>
          <div className={styles.sectionDetails}>
            <Grid container>
              <Grid xs={12}>
                {selectedCompany.tradeDescription || (
                  <Typography sx={{ color: "gray" }}>No description set.</Typography>
                )}
              </Grid>
            </Grid>
          </div>
          {hasAnyPermission([
            PermissionClassification.ViewComplianceDetail,
            PermissionClassification.CanViewBEEDetails,
          ]) && (
            <>
              <div className={styles.companyServiceHeader}>
                <h3>Compliance</h3>
              </div>
              <div className={styles.sectionDetails}>
                <Grid container sx={{ textAlign: "center", fontSize: "14px" }}>
                  {hasAnyPermission([
                    PermissionClassification.ViewComplianceDetail,
                    PermissionClassification.ViewComplianceManagementDashboard,
                  ]) && (
                    <>
                      <Grid container item xs={3} alignContent="space-between">
                        {evaluateComplianceStatus(
                          selectedCompany?.complianceDetails,
                          "Company Registered Name",
                          ComplianceVerificationTypeEnum.CIPC
                        )}
                      </Grid>
                      <Grid container item xs={3} alignContent="space-between">
                        {evaluateComplianceStatus(
                          selectedCompany?.complianceDetails,
                          "CIPC Business Status",
                          ComplianceVerificationTypeEnum.CIPC
                        )}
                      </Grid>
                      <Grid container item xs={3} alignContent="space-between">
                        {evaluateComplianceStatus(
                          selectedCompany?.complianceDetails,
                          "SARS Validation",
                          ComplianceVerificationTypeEnum.SARS
                        )}
                      </Grid>
                    </>
                  )}
                  {hasPermission(PermissionClassification.CanViewBEEDetails) && beeValidation()}
                </Grid>
              </div>
            </>
          )}

          <div className={styles.sectionDetailsPrint}>
            <Grid container sx={{ textAlign: "left" }}>
              <Grid xs={12}>
                {beeStatus
                  ? "Please note that this B1 LINK document does not constitute a B-BBBEE Certificate. " +
                    "B1SA has validated that the B-BBEE certification of this company is compliant " +
                    "with the requirements of the Codes of Good Practice."
                  : "Please note that this B1 LINK document does not constitute a B-BBBEE Certificate. " +
                    "B1SA has validated that the B-BBEE certification of this company is not compliant " +
                    "with the requirements of the Codes of Good Practice."}
                <br />
                <br />
                For Future information, Kindly contact B1SA on +27 11 455 0033.
                <br />
                <br />
              </Grid>
              <Grid xs={3}>
                <img src={B1link} alt="" />
              </Grid>
              <Grid xs={9}>
                B1 LINK is a product offering of B1SA,a proud member of the Signa Group. Visit
                <a href="www.b1link.com"> www.b1link.com</a>
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
    </>
  );
};

export default CompanySearchView;
