import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import CompanyProfileService from "../../CompanyProfileService";
import { CompanyProfileImageModel } from "../Models/CompanyProfileImageModel";
import { CompanyProfileModel } from "../Models/CompanyProfileModel";
import { companyProfileImageSlice, companyProfileSlice } from "./companySlice";

export const companyProfileActions = companyProfileSlice.actions
export const companyProfileImageActions = companyProfileImageSlice.actions


export const fetchParticularCompanyProfileImage =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyProfileImageModel = await (
        await CompanyProfileService.getParticularProfileImage(companyId, staged)
      ).data;
      dispatch(companyProfileImageActions.setParticularProfileImage(response));
    };

export const fetchParticularCompanyProfile =
  (companyId: number, staged: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      const response: CompanyProfileModel = await (
        await CompanyProfileService.getParticularCompanyProfile(companyId, staged)
      ).data;
      dispatch(companyProfileActions.setParticularCompanyProfile(response));
    };