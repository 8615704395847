import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import { ContactModel } from "../../../../http/Company/Models/ContactModel";
import VerificationSection from "../../../WorkCenter/Verification/VerificationSection";
import PermissionClassification from "../../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../../WithRequiredPermission";
import ContactType from "../../../../util/enums/ContactType";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";
import useAllowedPermission from "../../../../util/hooks/useAllowedPermission";
import useFieldValidation from "../../../../util/hooks/useFieldValidation";
import { useAppDispatch } from "../../../../app/hooks";
import useInputDebounce from "../../../../util/hooks/useInputDebounce";
import { setCompanyContactState } from "../../../../http/Redux/Store/companySlice";

const CompanyGeneralContactPost = ({
  headerText = "",
  contactData,
  workcentreAgentPath,
  SetDirtyCallback,
  OnContactSave,
  OnCopyPrimary,
  staged,
  agentTaskId = 0,
}: {
  headerText: string;
  contactData: ContactModel;
  workcentreAgentPath: boolean;
  // eslint-disable-next-line no-unused-vars
  SetDirtyCallback: (isDirty: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  OnContactSave: (contactDetails: ContactModel) => void;

  // eslint-disable-next-line no-unused-vars
  OnCopyPrimary: (update: boolean, contactToUpdate: ContactModel) => ContactModel;

  staged: boolean;
  // eslint-disable-next-line react/require-default-props
  agentTaskId?: number;
}) => {
  const dispatch = useAppDispatch();
  const initialLoadCheck = useRef(true);

  const [edit, setEdit] = useState<boolean>(false);
  const [contact, setContact] = useState<ContactModel>({} as ContactModel);
  const [contactComparison, setContactComparison] = useState<ContactModel>({
    id: 0,
    name: "",
    email: "",
    landLine: "",
    mobile: "",
    fax: "",
    website: "",
    idNumber: "",
    username: "",
    contactTypeId: ContactType.GeneralCompany,
    designation: "",
    isDeleted: false,
    companyId: 0,
  } as ContactModel);

  const [sameAsPrimary, setSameAsPrimary] = useState<boolean>(false);
  const hasAllowedPermission = useAllowedPermission();
  const [validateField] = useFieldValidation("companyProfile");

  const debouncedContactValue = useInputDebounce(contact);

  // Validation Error State Variables
  const [contactNameInvalid, setContactNameInvalid] = useState<boolean>(false);
  const [contactEmailInvalid, setContactEmailInvalid] = useState<boolean>(false);
  const [contactCompPhoneInvalid, setContactCompPhoneInvalid] = useState<boolean>(false);
  const [contactFaxInvalid, setContactFaxInvalid] = useState<boolean>(false);
  const [contactWebsiteInvalid, setContactWebsiteInvalid] = useState<boolean>(false);
  const [contactDesignationInvalid, setContactDesignationInvalid] = useState<boolean>(false);
  const [contactNumberInvalid, setContactNumberInvalid] = useState<boolean>(false);

  const CancelEdit = () => {
    setEdit(false);
    SetDirtyCallback(false);
    setSameAsPrimary(false);
    setContact(contactComparison);
  };

  const handleCopyPrimary = (contactToUpdate: ContactModel, isSameAsPrimary: boolean) => {
    if (isSameAsPrimary === false) {
      setContact(contactComparison); // reset the values
      SetDirtyCallback(false); // Force a save or discard changes
      return;
    }

    const updatedContact = OnCopyPrimary(true, contactToUpdate);

    setContact(updatedContact);
  };

  const handleSwitchOnClick = () => {
    setSameAsPrimary((stateVal) => !stateVal);
  };

  const EnableEdit = () => {
    if (edit) {
      OnContactSave(contact);
      setContactComparison(contact);
      SetDirtyCallback(false);
      setEdit(false);
      return;
    }

    setEdit(!edit);
  };

  const performDirtyCheck = (checkVal: ContactModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(contactComparison)) {
      SetDirtyCallback(true);
      return;
    }

    SetDirtyCallback(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...contact, [propName]: event.target.value };
    setContact(newVal);
    performDirtyCheck(newVal);
  };

  useEffect(() => {
    if (initialLoadCheck.current) {
      if (!contactData.id) {
        setContact(contactComparison);
        initialLoadCheck.current = false;
        return;
      }

      setContact(contactData);
      setContactComparison(JSON.parse(JSON.stringify(contactData)));
      initialLoadCheck.current = false;
    }


  }, [contactData]);

  useEffect(() => {
    if (edit) handleCopyPrimary(contact, sameAsPrimary);

  }, [sameAsPrimary])

  useEffect(() => {
    dispatch(setCompanyContactState(debouncedContactValue));
  }, [debouncedContactValue]);

  return (
    <Box>
      <Accordion expanded className="Accord-Hide">
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="Accord-heading"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">{headerText}</h3>
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          {hasAllowedPermission(PermissionClassification.ViewAgentWorkCentre) && workcentreAgentPath && (
            <div className="post__header_top">
              <VerificationSection
                agentTaskId={agentTaskId}
                section="CompanyContacts"
                companyId={contactData.companyId}
                staged={staged}
              />
            </div>
          )}
          <div className={styles.editSave}>
            <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
              {!edit && (
                <Tooltip title="Edit">
                  <FontAwesomeIcon
                    icon={faPencilSquare}
                    aria-hidden="true"
                    id="edit-general-details"
                    onClick={EnableEdit}
                    className="hover-cursor"
                  />
                </Tooltip>
              )}
              {edit && (
                <div className={styles.btnBox}>
                  <div className={styles.btnBoxLeft}>
                    <Tooltip title="Save">
                      <FontAwesomeIcon
                        icon={faFloppyDisk}
                        aria-hidden="true"
                        id="edit-save-general"
                        onClick={EnableEdit}
                        className="hover-cursor"
                      />
                    </Tooltip>
                  </div>
                  <div className={styles.btnBoxRight}>
                    <Tooltip title="Cancel Edit">
                      <FontAwesomeIcon
                        icon={faCancel}
                        aria-hidden="true"
                        id="edit-cancel-general"
                        onClick={CancelEdit}
                        className="hover-cursor"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </WithRequiredPermission>
          </div>



          <div className={styles.postBody}>
            <div className={styles.postInfo}>
              {contact.contactTypeId !== ContactType.Primary && (
                <Grid container className="g-container">
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sameAsPrimary}
                            onClick={handleSwitchOnClick}
                            disabled={!edit}
                          />
                        }
                        label="Same as Primary Contact?"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              )}

              <Grid container className="g-container">
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    sx={{ width: "98%", marginRight: "2%" }}
                    disabled={!edit}
                    id="company-contact-name"
                    label="Contact Name"
                    value={contact?.name || ""}
                    size="small"
                    error={contactNameInvalid}
                    helperText={
                      contactNameInvalid &&
                      validateField("Contact Name", contact?.name ?? "").validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "name")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Contact Name",
                        contact?.name ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactNameInvalid(invalidField);
                        return;
                      }

                      setContactNameInvalid(false);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    sx={{ width: "98%", marginRight: "2%" }}
                    id="company-contact-email"
                    label="Email"
                    value={contact?.email || ""}
                    size="small"
                    error={contactEmailInvalid}
                    helperText={
                      contactEmailInvalid &&
                      validateField("Email", contact?.email ?? "").validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "email")}
                    onBlur={() => {
                      const invalidField = validateField("Email", contact?.email ?? "").isInvalid;

                      if (invalidField) {
                        setContactEmailInvalid(invalidField);
                        return;
                      }

                      setContactEmailInvalid(false);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    sx={{ width: "98%", marginRight: "1%" }}
                    id="company-contact-mobile"
                    label="Company Phone"
                    value={contact?.landLine || ""}
                    size="small"
                    error={contactCompPhoneInvalid}
                    helperText={
                      contactCompPhoneInvalid &&
                      validateField("Company Phone", contact?.landLine ?? "").validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "landLine")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Company Phone",
                        contact?.landLine ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactCompPhoneInvalid(invalidField);
                        return;
                      }

                      setContactCompPhoneInvalid(false);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container className="g-container">
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    id="company-contact-fax"
                    label="Company Fax"
                    value={contact?.fax || ""}
                    size="small"
                    error={contactFaxInvalid}
                    helperText={
                      contactFaxInvalid &&
                      validateField("Company Fax", contact?.fax ?? "").validationErrorText
                    }
                    sx={{ width: "98%", marginRight: "2%" }}
                    onChange={(ev) => handleValueChange(ev, "fax")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Company Fax",
                        contact?.fax ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactFaxInvalid(invalidField);
                        return;
                      }

                      setContactFaxInvalid(false);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    sx={{ width: "98%", marginRight: "2%" }}
                    id="company-contact-website"
                    label="Company Website"
                    value={contact?.website || ""}
                    size="small"
                    error={contactWebsiteInvalid}
                    helperText={
                      contactWebsiteInvalid &&
                      validateField("Company Website", contact?.website ?? "").validationErrorText
                    }
                    onChange={(ev) => handleValueChange(ev, "website")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Company Website",
                        contact?.website ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactWebsiteInvalid(invalidField);
                        return;
                      }

                      setContactWebsiteInvalid(false);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    <TextField
                      id="contact-designation"
                      disabled={!edit}
                      label="Designation"
                      sx={{ width: "98%", marginRight: "2%" }}
                      size="small"
                      fullWidth
                      value={contact?.designation || ""}
                      error={contactDesignationInvalid}
                      helperText={
                        contactDesignationInvalid &&
                        validateField("Contact Designation", contact?.designation || "")
                          .validationErrorText
                      }
                      onChange={(ev) => {
                        handleValueChange(ev, "designation");
                      }}
                      onBlur={() => {
                        const invalidField = validateField(
                          "Contact Designation",
                          contact?.designation ?? ""
                        ).isInvalid;

                        if (invalidField) {
                          setContactDesignationInvalid(invalidField);
                          return;
                        }

                        setContactDesignationInvalid(false);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className="g-container">
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    disabled={!edit}
                    id="company-contact-mobile"
                    label="Contact Number"
                    value={contact?.mobile || ""}
                    size="small"
                    error={contactNumberInvalid}
                    helperText={
                      contactNumberInvalid &&
                      validateField("Contact Number", contact?.mobile ?? "").validationErrorText
                    }
                    sx={{ width: "98%", marginRight: "2%" }}
                    onChange={(ev) => handleValueChange(ev, "mobile")}
                    onBlur={() => {
                      const invalidField = validateField(
                        "Contact Number",
                        contact?.mobile ?? ""
                      ).isInvalid;

                      if (invalidField) {
                        setContactNumberInvalid(invalidField);
                        return;
                      }
                      setContactNumberInvalid(false);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanyGeneralContactPost;
