/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompanyBeeGroupModel } from "../../Company/Models/CompanyBeeGroupModel";


export interface CompanyBeeGroupState {
  companyBeeGroup: Array<CompanyBeeGroupModel>;
}

const companyBeeGroupSlice = createSlice({
  name: "companyBeeGroup",
  initialState: [] as Array<CompanyBeeGroupModel>,
  reducers: {
    setCompanyBeeGroup(state, action: PayloadAction<Array<CompanyBeeGroupModel>>) {
      return action.payload;
    },
    addNewCompanyToBeeGroup(state, action: PayloadAction<CompanyBeeGroupModel>) {
      state.push(action.payload);
    },
    removeCompanyFromBeeGroup(state, action: PayloadAction<CompanyBeeGroupModel>) {
      const beeGroup = state;

      if (beeGroup)
        state = state.filter((company) => company.companyId !== action.payload.companyId);
    },
  },
})

export default companyBeeGroupSlice;
export const getCompanyBeeGroup = (state: RootState) => state.companyBeeGroup;
