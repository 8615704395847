import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import ManageSearchIcon from "@mui/icons-material/Search";
import { DataGridPro, GridColDef, GridRowSelectionModel, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { InputBase, IconButton, Grid, TextField, MenuItem, Button, styled } from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import { SupplierMatchViewModel } from "../../../http/Redux/Models/SupplierMatchViewModel";
import CompanyVerificationService from "../../../http/CompanyVerificationService";
import ViewMatch from "./MatchModelViews/ViewMatch";
import { selectBaseCompanyInfo } from "../../../http/Redux/Store/baseCompanyInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ManagedCompaniesModel } from "../../../http/Redux/Models/ManagedCompaniesModel";
import AcceptMatch from "./MatchModelViews/AcceptMatch";
import { AgentSearchListModel } from "../../../http/Redux/Models/AgentSearchListModel";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import { AgentTaskStatusTypes } from "../../../util/enums/AgentTaskStatusTypes";
import { AgentTaskModel } from "../../../http/Company/Models/AgentTaskModel";
import { getUserProfile } from "../../../http/Redux/Store/UserProfileSlice";
import PageSourceTypes from "../../../util/enums/PageSourceTypes";
import { fetchCompanyWorkCentreHolder } from "../../../http/Redux/Store/CompanyWorkCentreHolderActions";
import { getSetCompanyHolder } from "../../../http/Redux/Store/CompanyWorkCentreHolderSlice";
import MatchClaimActions from "./MatchModelViews/MatchClaimActions";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import { useHasAnyAllowedPermissions } from "../../../util/hooks/useAllowedPermission";
import SupervisorClaimMatch from "../AgentSupervisor/SupervisorClaimMatch";
import { UserMessage } from "../../../http/CompanyUserManagement/Messages/UserMessage";
import { AgentTaskListGridModel } from "../../../http/Redux/Models/AgentTaskListGridModel";
import UserProfileService from "../../../http/UserProfileService";
import DataSourceTypes from "../../../util/enums/DataSourceTypes";





const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => (
  <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }}>No Rows</Box>
  </StyledGridOverlay>
)

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);


interface ViewCellProps {
  row: SupplierMatchViewModel;
  // eslint-disable-next-line no-unused-vars
  setSelectedMatch: (row: SupplierMatchViewModel) => void;
  // eslint-disable-next-line no-unused-vars
  handleSaveAgentTask: (clientCompanyId: number) => void;
  // eslint-disable-next-line no-unused-vars
  setClaimed: (claim: boolean) => void;
  allTasks: AgentTaskListGridModel[];
  users: UserMessage[];
}


const ViewCell: React.FC<ViewCellProps> = ({ row, setSelectedMatch, handleSaveAgentTask, setClaimed, allTasks, users }) => {

  const isRowMatch = (!row.matchOnRegName && !row.matchOnRegNo && !row.matchOnTradeName && !row.matchOnVATNo)
  const sub: AgentSearchListModel = {} as AgentSearchListModel;
  // const [activeClaim, setActiveClaim] = useState(false);
  const hasAllowedPermission = useHasAnyAllowedPermissions();
  const user = useAppSelector(getUserProfile);

  sub.registrationNumber = row.matchedRegistrationNumber;
  sub.registeredName = row.matchedRegisteredName
  sub.vATNumber = row.matchedVATNumber;
  sub.tradingName = row.matchedTradingName;
  sub.tradeDescription = row.matchedTradeDescription;
  sub.holdingCompanyName = row.matchedHoldingCompanyName;
  sub.companyId = row.clientCompanyId;

  const handleClaim = (isClaimed: boolean) => {
    setClaimed(isClaimed);
    // setActiveClaim(isClaimed);
  }

  const handleViewMatch = () => {
    setSelectedMatch(row);
    handleSaveAgentTask(row.clientCompanyId);
  }


  return (
    <div>
      <Grid container>
        <Grid xs={2} sx={{ marginRight: 2 }}>
          {!isRowMatch && (
            <AcceptMatch data={sub} supplierRow={row} />
          )}
        </Grid>
        <Grid xs={2} sx={{ marginRight: 2 }}>
          <MatchClaimActions
            row={row}
            stage
            Claim={row.claimedMatchTask === 1}
            selectedClientId={row.clientCompanyId}
            PageSource={PageSourceTypes.MatchTable}
            handleViewMatch={handleViewMatch}
            SetIsClaimed={handleClaim} />
        </Grid>
        <Grid xs={2}>
          {!hasAllowedPermission([PermissionClassification.SuperAgent]) && (
            <IconButton>
              <ManageSearchIcon onClick={() => { setSelectedMatch(row); }} />
            </IconButton>
          )}
          {hasAllowedPermission([PermissionClassification.SuperAgent]) && (
            <div>
              <SupervisorClaimMatch users={users} allTasks={allTasks} row={row} stage selectedClientId={row.clientCompanyId}
                userId={user.id as number} handleViewMatch={handleViewMatch} />
            </div>
          )}
        </Grid>
      </Grid>

    </div>
  )
};



const DataGridProDemo = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [supplierMatchView, setSupplierMatchView] = useState<SupplierMatchViewModel[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<SupplierMatchViewModel>();
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectBaseCompanyInfo);
  const [allManagedCompanies, setAllManagedCompanies] = useState<ManagedCompaniesModel[]>([] as ManagedCompaniesModel[]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [selectionVisable, setSelectionVisable] = useState(false);
  const [supplierAllMatchView, setSupplierAllMatchView] = useState<SupplierMatchViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [swalPropsChangeSearch, setSwalPropsChangeSearch] = useState({});
  const user = useAppSelector(getUserProfile);
  const [claimed, setClaimed] = useState(false);
  const getCurrentlySelectedClient = useAppSelector(getSetCompanyHolder);
  const [users, setUsers] = useState<UserMessage[]>([] as UserMessage[]);
  const [allTasks, setAllTasks] = useState<AgentTaskListGridModel[]>([] as AgentTaskListGridModel[]);

  const setTheClaimed = (claim: boolean) => {
    setClaimed(claim);
  };

  const handleSelectedMatchRow = (row: SupplierMatchViewModel) => {
    setSelectedMatch(row);
  }
  const handleSaveAgentTask = async (clientCompanyId: number) => {

    const data = await CompanyVerificationService.getSupplierMatch(clientCompanyId);
    const updatedSupplierMatchView = data.map((rows, index) => ({
      ...rows,
      uniqueNumber: index + 1,
    }));
    setSupplierMatchView(updatedSupplierMatchView);
  };

  const columns: GridColDef[] = [
    {
      field: "uploadCompanyType",
      headerClassName: "wrapTheHeader",
      headerName: "Upload Company Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "uploadRegistrationNumber",
      headerName: "Upload Registration Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "uploadVATNumber",
      headerName: "Upload VAT Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "uploadRegisteredName",
      headerName: "Upload Registered Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "uploadTradingName",
      headerName: "Upload Trading Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "uploadHoldingCompanyName",
      headerName: "Upload Holding Company Name",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "uploadTradeDescription",
      headerName: "Upload Trade Description",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "uploadClientVendorNumber",
      headerName: "Upload Client Vendor Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "uploadContactEmail",
      headerName: "Upload Contact Email",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "uploadContactPhone",
      headerName: "Upload Contact Phone",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "uploadContactName",
      headerName: "Upload Contact Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "matchOnRegNo",
      headerName: "Registration No Match",
      flex: 1,
      minWidth: 180,
      cellClassName: (params) => (params.value ? "highlightGreen" : "highlightRed"),
      renderCell: (params) => (
        <div style={{ color: "white", fontWeight: 600, margin: "auto" }}>
          {params.value ? <CheckCircleIcon /> : <CloseIcon />}
        </div>
      ),
    },
    {
      field: "matchOnVATNo",
      headerName: "VAT No Match",
      flex: 1,
      minWidth: 150,
      cellClassName: (params) => (params.value ? "highlightGreen" : "highlightRed"),
      renderCell: (params) => (
        <div style={{ color: "white", fontWeight: 600, margin: "auto" }}>
          {params.value ? <CheckCircleIcon /> : <CloseIcon />}
        </div>
      ),
    },
    {
      field: "matchOnRegName",
      headerName: "Registered Name Match",
      flex: 1,
      minWidth: 200,
      cellClassName: (params) => (params.value ? "highlightGreen" : "highlightRed"),
      renderCell: (params) => (
        <div style={{ color: "white", fontWeight: 600, margin: "auto" }}>
          {params.value ? <CheckCircleIcon /> : <CloseIcon />}
        </div>
      ),
    },
    {
      field: "matchOnTradeName",
      headerName: "Trade Name Match",
      flex: 1,
      minWidth: 180,
      cellClassName: (params) => (params.value ? "highlightGreen" : "highlightRed"),
      renderCell: (params) => (
        <div style={{ color: "white", fontWeight: 600, margin: "auto" }}>
          {params.value ? <CheckCircleIcon /> : <CloseIcon />}
        </div>
      ),
    },
    {
      field: "matchedRegistrationNumber",
      headerName: "Matched Registration Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "matchedVATNumber",
      headerName: "Matched VAT Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "matchedRegisteredName",
      headerName: "Matched Registered Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "matchedHoldingCompanyName",
      headerName: "Matched Holding Company Name",
      flex: 1,
      minWidth: 220,
    },

    {
      field: "matchedTradingName",
      headerName: "Matched Trading Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "matchedTradeDescription",
      headerName: "Matched Trade Description",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "createdDateTime",
      headerName: "Created Date Time",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "modifiedDateTime",
      headerName: "Modified Date Time",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "uniqueNumber",
      headerName: "Actions",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell
          handleSaveAgentTask={handleSaveAgentTask}
          row={params.row as SupplierMatchViewModel}
          setSelectedMatch={handleSelectedMatchRow}
          setClaimed={setTheClaimed}
          allTasks={allTasks}
          users={users} />
      ),
    },
  ];



  const searchQuery = async (searchText: string) => {
    setSearchTerm(searchText);
    if (searchText.length >= 3) {
      setIsLoading(true);
      const data = await CompanyVerificationService.getManagedCompaniesByPartnerId(company.companyId, false);
      setAllManagedCompanies(data);
      setIsLoading(false);

    }
    else {
      setSwalPropsChangeSearch({
        show: true,
        title: "Minimum of 3 Characters",
        icon: "warning",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,

      });
    }
  };

  const handleCancel = () => {
    searchQuery("");
  };

  const handleSelectionModelChange = (params: GridRowSelectionModel) => {
    if (params.length !== 0) {
      setSelectedRowIds(params);
      setSelectionVisable(true);
    }
    else {
      setSelectionVisable(false);
    }
  };

  const handleClientChange = async (value: string) => {
    const changedClientId = Number(value);
    setSelectedClientId(changedClientId);
    if (changedClientId !== 0) {
      const loader = async () => {
        if (changedClientId > 0) {
          setIsLoading(true);
          const data = await CompanyVerificationService.getSupplierMatch(changedClientId);
          const updatedSupplierMatchView = data.map((row, index) => ({
            ...row,
            uniqueNumber: index + 1,
          }));
          setSupplierMatchView(updatedSupplierMatchView);
          setIsLoading(false);
          dispatch(fetchCompanyWorkCentreHolder(getCurrentlySelectedClient.dataCompanyId, changedClientId));
        }
      };
      loader();

    }
  }

  useEffect(() => {
    if (getCurrentlySelectedClient.matchCompanyId > 0) {
      setSelectedClientId(getCurrentlySelectedClient.matchCompanyId);
      handleClientChange(getCurrentlySelectedClient.matchCompanyId.toString());
    }

  }, []);


  useEffect(() => {
    if (company.companyId !== undefined) {
      const load = async () => {
        const data = await CompanyVerificationService.getAllAgentTaskLists();

        const userData = (await UserProfileService.getAllCompanyUsers(company.companyId)).data;
        setUsers(userData);
        setAllTasks(data);
      };

      load();
    }
  }, [users.length, company.companyId])

  useEffect(() => {
    if (company.companyId !== undefined && allManagedCompanies.length === 0) {
      const loader = async () => {
        setIsLoading(true);
        const data = await CompanyVerificationService.getManagedCompaniesByPartnerId(company.companyId, false);
        setAllManagedCompanies(data);
      };

      loader();
      setIsLoading(false);

      if (claimed) {
        toast.success("Added to My Tasks");
      }
    }
  }, [selectedRowIds.length, selectedMatch?.clientCompanyId, open, supplierAllMatchView.length, selectionVisable, claimed]);

  const onBackClick = () => {
    setOpen(false);
    setSelectedMatch({} as SupplierMatchViewModel);
  }



  const handleAllClickOpen = () => {
    setIsLoading(true);
    const supplierMatches: SupplierMatchViewModel[] = [];
    selectedRowIds.forEach(element => {
      if (supplierMatchView.find(x => x.uniqueNumber === element && x.matchedRegisteredName !== null)) {
        supplierMatches.push(supplierMatchView.find(x => x.uniqueNumber === element) as SupplierMatchViewModel);
      }
    });

    if (supplierMatches.length === 0) {
      toast.error("Selected suppliers have no matches")
    }

    supplierMatches.forEach(element => {
      const handleAcceptMatch = async () => {
        await CompanyVerificationService.acceptMatch(element);
      }
      handleAcceptMatch();
    });
    setSupplierAllMatchView(supplierMatches);
    const updatedSupplierMatchView = supplierMatchView.filter(x => !selectedRowIds.includes(x.uniqueNumber));
    setSupplierMatchView(updatedSupplierMatchView);
    setIsLoading(false);
  }

  const handleAllClickClaimOpen = () => {
    setIsLoading(true);
    selectedRowIds.forEach(async (element) => {
      if (supplierMatchView?.find((x) => x.uniqueNumber === element)) {
        const lis = supplierMatchView?.find((x) => x.uniqueNumber === element);
        const model: AgentTaskModel = {} as AgentTaskModel;
        model.companyId = lis?.matchToCompanyId as number;
        model.status = AgentTaskStatusTypes.Assigned;
        model.completion = "0";
        model.comment = "";
        model.clientId = selectedClientId;
        model.agentUserId = user.id as number;
        model.dataSource = DataSourceTypes.Staged;
        model.pageSource = PageSourceTypes.MatchTable;
        model.uploadTempCompanyId = lis?.uploadTempCompanyId as string;
        await CompanyVerificationService.addAgentTask(model, false).finally(() => {
          setIsLoading(false);
          toast.success("Match & Clean Task Claimed");
        });
      }

      const updatedSupplierMatchViewClaimed = supplierMatchView.filter(x => selectedRowIds.includes(x.uniqueNumber)).map((rows) => ({ ...rows, claimedMatchTask: 1 }));
      setSupplierMatchView([...supplierMatchView, ...updatedSupplierMatchViewClaimed]);
    });
    setIsLoading(false);
  };


  return (
    <>
      <LoadingBackdrop showBackdrop={isLoading} />
      {!selectedMatch?.clientCompanyId && (
        <Grid container>
          <Grid xs={3} sx={{ marginBottom: 2 }}>
            <TextField
              id="company"
              select
              value={selectedClientId}
              sx={{ width: "100%", marginBottom: "10px" }}
              label="Select Client"
              onChange={(e) => handleClientChange(e.target.value)}
              size="small">
              {allManagedCompanies.map((record) => (
                <MenuItem value={record.companyId}>{record.registeredName}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid xs={12}>
            <Box sx={{ height: 820, width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div className="agent-list-box-small">
                  <div className="agent-list-search-switch">
                    <div
                      className="search-components-wrap"
                      style={{ border: "1px solid rgba(0, 0, 0, 0.116)", borderRadius: "3px" }}
                    >
                      <InputBase
                        id="search-bar"
                        value={searchTerm}
                        className="text-main-search"
                        onInput={(ev: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(ev.target.value)}
                        sx={{ width: "96%", border: "0px" }}
                        placeholder="Search..."
                        size="small" />
                      <IconButton
                        type="submit"
                        aria-label="search"
                        sx={{ width: "4%" }}
                        onClick={() => searchQuery(searchTerm)}
                        className="switch-to-advanced-btn"
                      >
                        {searchTerm ? (
                          <CloseIcon style={{ fill: "blue" }} onClick={handleCancel} />
                        ) : (
                          <ManageSearchIcon style={{ fill: "#435cd2" }} />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  <Grid container>
                    <Grid xs={12}>
                      {selectionVisable && (
                        <Button onClick={handleAllClickOpen} sx={{ fontSize: "0.8125rem", float: "right" }}>
                          <CheckCircleIcon /> Accept All Matches
                        </Button>
                      )}
                      {selectionVisable && (
                        <Button onClick={handleAllClickClaimOpen} sx={{ fontSize: "0.8125rem", float: "right" }}>
                          <CheckCircleIcon /> Claim All Companies
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div style={{ height: 500, width: "100%" }}>
                  <DataGridPro
                    columns={columns}
                    rows={supplierMatchView}
                    checkboxSelection
                    disableRowSelectionOnClick
                    pagination
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          modifiedDateTime: false,
                          createdDateTime: false,
                          uploadCompanyType: false,
                          uploadHoldingCompanyName: false,
                          uploadTradeDescription: false,
                          uploadClientVendorNumber: false,
                          uploadContactEmail: false,
                          uploadContactPhone: false,
                          uploadContactName: false,
                          matchedHoldingCompanyName: false,
                          matchedTradeDescription: false,
                        },
                      },
                    }}
                    onRowSelectionModelChange={handleSelectionModelChange}
                    slots={{
                      toolbar: CustomToolbar,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rowThreshold={0}
                    getRowId={(row: SupplierMatchViewModel) => row.uniqueNumber} />
                </div>
              </div>
            </Box>
          </Grid>
          <SweetAlert2 {...swalPropsChangeSearch} />
        </Grid>
      )}
      {selectedMatch?.clientCompanyId && (
        <Box>
          <ViewMatch uploadedCompanyData={selectedMatch as SupplierMatchViewModel} back={onBackClick} />
        </Box>
      )}
    </>
  );
};

export default DataGridProDemo;
