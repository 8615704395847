import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router";
import NetworkConnections from "../../components/Connect/ConnectionRequests/NetworkConnections";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { netconnNetworkConnectionActions } from "../../http/Redux/Store/NetworkAndConnectActions";
import { selectBaseCompanyInfo } from "../../http/Redux/Store/baseCompanyInfoSlice";
import { GetCompanyNetwork } from "../../http/NetworkAndConnect/Netconn";
import { MyConnections } from "../../util/enums/MyConnectionsTab";




const CompanyNetworkMain = () => {
  const dispatch = useAppDispatch();
  const { tab } = useParams() || "";

  const [, setShowLoader] = useState<boolean>(false);
  const companyData = useAppSelector(selectBaseCompanyInfo);
  const networkData = useAppSelector(x => x.companyNetworkConnections);

  const loadCompanyNetwork = useCallback(async (companyId: number) => {
    const companyNetwork = await GetCompanyNetwork(companyId);
    dispatch(netconnNetworkConnectionActions.setCompanyNetworkConnections(companyNetwork));

  }, [networkData?.clients?.length, networkData?.suppliers?.length]);

  useEffect(() => {
    loadCompanyNetwork(companyData.companyId);
  }, [tab, networkData?.clients?.length, networkData?.suppliers?.length]);

  return (
    <NetworkConnections backdropControl={setShowLoader} tabToLoad={tab as MyConnections} />
  );
};

export default CompanyNetworkMain;
