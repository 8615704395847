/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SweetAlert2 from "react-sweetalert2";
import "../../../components/Connect/SearchResult/SweetAlert.css";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";
import CompanyProfileService from "../../../http/CompanyProfileService";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCompanyProfile } from "../../../http/Redux/Store/companySlice";
import bufferProfile from "../../../util/images/filler.png";
import { fetchParticularProfileImage } from "../../../http/Redux/Store/UserProfileActions";
import { CompanyProfileModel } from "../../../http/Redux/Models/CompanyProfileModel";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import DialogModal from "../../../components/Dialogs/DialogModal";
import ButtonGroup from "../Sections/ButtonGroup";
import { GetBaseCompanyInfoViaUserId } from "../../../http/Company/company";
import { BaseCompanyInfo } from "../../../http/Company/Models/BaseCompanyInfo";


const UploadCompanyProfileModal = ({ staged }: { staged: boolean }) => {

  const companyProfile = useAppSelector(getCompanyProfile);
  const [company, setCompany] = useState<BaseCompanyInfo>({} as BaseCompanyInfo);
  const [companyProfileData, setCompanyProfileData] = useState<CompanyProfileModel>(companyProfile || {} as CompanyProfileModel);
  const [file, setFile] = useState<FileList | null>(null);
  const [profileFileName, setProfileFileName] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState("");
  const [updatedImage, setUpdatedImage] = useState(0);
  const [updatedInfo, setUpdatedInfo] = useState(0);
  const [id, setId] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [swalPropsChange, setSwalPropsChange] = useState({});
  const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    event.preventDefault();
    const formData = new FormData();
    if (file === null || file === undefined || file.length === 0) return;
    formData.append("File", file[0]);
    formData.append("FileName", file[0].name);
    setCompanyProfileData({ ...companyProfileData, fileName: file[0].name, companyId: company.companyId, id });


    if (companyProfile.id === undefined || companyProfile.id === 0) {
      const loader = async () => {
        const response = await CompanyProfileService.addProfileDetails(companyProfileData, staged);
        setId(response as number);
        setUpdatedInfo(200);
        const addImageStatus = await CompanyProfileService.uploadImage(company.companyId, formData, companyProfileData.fileName);
        setUpdatedImage(addImageStatus);
      };
      loader();
    }
    else {
      const loader = async () => {
        setId(companyProfile.id);
        const response = await CompanyProfileService.updateProfileDetails(companyProfileData, staged);
        setUpdatedInfo(200);
        setUpdatedInfo(response);
        const updateImageStatus = await CompanyProfileService.uploadImage(company.companyId, formData, companyProfile.fileName);
        setUpdatedImage(updateImageStatus);
      };
      loader();
    }

    setShowDrawer(false);
    setLoading(false);
  };

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {

    setImage("");
    const fileSelected = event.target.files![0];
    if (fileSelected.size < 1024 * 1024) {
      setFile(event.target.files);
      setHasImage(true);
    }
    else {
      setSwalPropsChange({
        show: true,
        title: "File to big must be less then 1MB",
        icon: "error",
        timer: Number(3000),
        showCancelButton: false,
        showConfirmButton: false,

      });
    }



  }

  const handleClose = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    if (company.companyId === undefined) {
      const loader = async () => {
        const result = await GetBaseCompanyInfoViaUserId(userAccount as string);
        setCompany(result);
      }
      loader();
    }
    if (file === null || file === undefined || file.length === 0) return;
    setProfileFileName(file[0].name);

    setCompanyProfileData({ ...companyProfileData, fileName: file[0].name, companyId: company.companyId, id });
    setImage(URL.createObjectURL(file[0]));

    if (updatedImage === 200 && updatedInfo === 200) {
      setShow(false);
      setSwalPropsChange({
        show: true,
        title: "Saved",
        icon: "success",
        timer: Number(timeVal),
        showCancelButton: false,
        showConfirmButton: false,

      });
      dispatch(fetchParticularProfileImage(userAccount as string));
    }


  }, [company.companyId, companyProfileData.id, id, profileFileName, file?.length, image.length, updatedImage, updatedInfo, show, dispatch]
  );

  return (
    <div>
      <LoadingBackdrop showBackdrop={show} />

      <div className="box-label-profile-button" onClick={() => setShowDrawer(true)}>
        <Stack className="box-label-profile-stack" direction="row" alignItems="center" gap={1}>
          <Typography>ATTACH FILE</Typography>
          <AttachmentIcon />
        </Stack>
      </div>
      <DialogModal
        isOpen={showDrawer}
        onHandleClose={handleClose}>
        <div className="dialog-wrap-content-box">
          <div className="sidebar__top_home" id="accountHeader">
            <div className="card-image">
              <img src={image?.length !== 0 ? image : bufferProfile} alt="" className="profile-section-profile-image-big" />
            </div>
            <div className="profile-section-profile-image-small-wrap">

              <Tooltip title="Upload Company Avatar">
                <Button component="label">
                  <EditIcon className="profile-section-profile-image-small" id="step-profile" />
                  <input type="file" hidden onChange={(e) => handleSelectFile(e)} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Typography noWrap>{profileFileName}</Typography>
          <ButtonGroup
            buttons={[
              {
                label: "Upload",
                align: "right",
                type: "primary",
                isLoading: loading,
                disabled: !hasImage,
                onClick: handleSubmit,
              },
            ]}
          />

        </div>
      </DialogModal>
      <SweetAlert2 {...swalPropsChange} />
    </div>
  )
};

export default UploadCompanyProfileModal;