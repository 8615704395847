import React, { useEffect, useState } from "react";
import { Box, Chip, Divider, Grid, IconButton, Paper, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "./css/Panels.css";
import { DataGridPro, DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import { RoleMessage } from "../../../http/CompanyUserManagement/Messages/RoleMessage";
import EditRoleDrawer from "./EditRoleDrawer";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import WithRequiredPermission from "../../WithRequiredPermission";
import AddRoleSideDrawer from "./AddRoleSideDrawer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ModulePermissions } from "../../../http/CompanyUserManagement/Messages/Module";
import { setModulePermissionState } from "../../../http/Redux/Store/ModulePermissionSlice";
import { GetPermissionsByModule } from "../../../http/CompanyUserManagement/userManagementApi";
import { Permission } from "../../../http/CompanyUserManagement/Messages/Permission";

const DetailPanelContent = ({ row: rowProp }: { row: RoleMessage }) => (
  <Stack
    sx={{ py: 2, height: 1, boxSizing: "border-box", backgroundColor: "#ECECEC" }}
    direction="column"
  >
    <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
      <Stack direction="column" spacing={1} sx={{ height: 1 }}>
        <Grid container>
          <Grid xs={12}>
            <h3>Permissions</h3>
            <Divider />
          </Grid>
          <Grid xs={12}>
            {rowProp?.permissions != null && rowProp?.permissions?.length > 0 ?
              <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: 1, my: 2 }}>
                {rowProp?.permissions?.map((x: Permission) =>
                  <Chip
                    key={`${rowProp?.id}_${x.id}`}
                    label={x.name}
                    className="chip-colored"
                    size="small"
                  />
                )
                }
              </Box> : "No role permission(s) assigned"}
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  </Stack>
);


const ViewRoles = (
  {
    roles,
    createNewRoleCallback,
  }
    :
    {
      roles: RoleMessage[],
      // eslint-disable-next-line no-unused-vars
      createNewRoleCallback: (newRole: RoleMessage) => void,
    }) => {
  const [showEditRoleDrawer, setShowEditRoleDrawer] = useState(false);
  const [editRoleDetails, setShowEditRoleDetails] = useState<RoleMessage>({} as RoleMessage);
  const [visibleRoles, setVisibleRoles] = useState<RoleMessage[]>([]);
  const [modulePermissions, setModulePermissions] = useState<ModulePermissions[]>([])
  const modulePermissionsState = useAppSelector((redux) => redux.modulePermissions?.modulePermissionsList)
  const dispatch = useAppDispatch();


  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    if (roles) {
      setVisibleRoles(roles); // sort in descending
    }
    const loader = async () => {
      if (modulePermissionsState == null) {
        const modulePermissionData = await GetPermissionsByModule();
        dispatch(setModulePermissionState(modulePermissionData));
        setModulePermissions(modulePermissionData)
      } else {
        setModulePermissions(modulePermissionsState);
      }
    }
    loader();
  }, [dispatch, modulePermissionsState, roles])

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Role",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "id",
      headerName: "Actions",
      minWidth: 130,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => {
            setShowEditRoleDetails(params?.row);
            setShowEditRoleDrawer(true);
          }}>
            <EditIcon />
          </IconButton>
        </div>
      ),
    },

  ];

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <WithRequiredPermission permission={PermissionClassification.EditManageUsers}>
        <AddRoleSideDrawer
          modulePermissions={modulePermissions}
          createNewRoleCallback={createNewRoleCallback}
        />
      </WithRequiredPermission>
      {showEditRoleDrawer &&
        <EditRoleDrawer
          onClose={() => { setShowEditRoleDrawer(false) }}
          onDeleteCallback={(roleId: number) => { setVisibleRoles(prevState => ([...prevState.filter((x) => x.id !== roleId)])) }}
          role={editRoleDetails} />
      }
      <DataGridPro
        sx={{ marginTop: 2 }}
        rows={visibleRoles ?? []}
        columns={columns}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        getRowId={(row: RoleMessage) => row?.id as number} />
    </Box>

  );

};

export default ViewRoles;