import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import CompanyWorkCentreHolderSlice from "./CompanyWorkCentreHolderSlice";
import { CompanyWorkCentreHolderModel } from "../Models/CompanyWorkCentreHolderModel";


export const companiesActions = CompanyWorkCentreHolderSlice.actions

export const fetchCompanyWorkCentreHolder = (dataId: number, matchId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: CompanyWorkCentreHolderModel = {
    dataCompanyId: dataId,
    matchCompanyId: matchId,
  }
  dispatch(companiesActions.setCompanyWorkCentreHolder(response))
}