import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Hi from "../../util/images/Hi.gif";
import bufferProfile from "../../util/images/filler.png";
import { protectedResources } from "../../authConfig";
import { GetCompanyDataById, SaveCompanyChanges } from "../../http/Company/company";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  removeBankAccFromState,
  removeContactFromState,
  setCompanyAddressState,
  setCompanyBankAccountState,
  setCompanyBeeDetailsState,
  setCompanyContactState,
  setCompanyViewModelState,
} from "../../http/Redux/Store/companySlice";

// CSS
import CompanyProfileSidePanel from "../../components/Company/CompanyProfileSidePanel/CompanyProfileSidePanel";
import CompanyHeadingPost from "../../components/Company/CompanyPost/CompanyHeadingPost";
import CompanyDetailsPost from "../../components/Company/CompanyPost/CompanyDetails/CompanyDetailsPost";
import CompanyTaxInformationPost from "../../components/Company/CompanyPost/CompanyDetails/CompanyTaxInformationPost";
import CompanyGeneralContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanyGeneralContactPost";
import CompanyPrimaryContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanyPrimaryContactPost";
import CompanySecondaryContactPost from "../../components/Company/CompanyPost/CompanyContact/CompanySecondaryContactPost";
import CompanyDocumentsPost from "../../components/Company/CompanyPost/CompanyDocuments/CompanyDocumentsPost";
import CompanyBankingDetailsPost from "../../components/Company/CompanyPost/CompanyBankDetails/CompanyBankingDetailsPost";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import { ContactModel } from "../../http/Company/Models/ContactModel";
import CompanyBEEDetails from "../../components/Company/CompanyPost/CompanyBEE/CompanyBEEDetails";
import { AddNewContact, SaveContactChanges, DeleteContact } from "../../http/Company/Contact";
import { AddNewBeeDetails, SaveBeeDetailChanges } from "../../http/Company/beeDetailsApi";
import { BeeDetailsModel } from "../../http/Company/Models/BeeDetailsModel";
import { AddressModel } from "../../http/Company/Models/AddressModel";
import { BankAccountDetailsModel } from "../../http/Company/Models/BankAccountDetailsModel";
import {
  AddNewBankAccount,
  DeleteBankAccount,
  UpdateBankAccount,
} from "../../http/Company/bankingDetails";
import CompanyAddressDetailsPost from "../../components/Company/CompanyPost/CompanyAddress/CompanyAddressDetailsPost";
import AddressType from "../../util/enums/AddressType";
import SaveAddressChanges from "../../http/Company/address";
import UnsavedChangeDialog from "../Company/UnsavedChangeDialog";
import usePrompt from "../../util/hooks/usePrompt";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import CompanyProductsAndServicesPost from "../../components/Company/CompanyPost/CompanyProductsAndServices/CompanyProductsAndServicesPost";
import { CompanyVerificationModel } from "../../http/Redux/Models/CompanyVerificationModel";
import { getCompanyVerifications } from "../../http/Redux/Store/CompanyVerificationSlice";
import ContactType from "../../util/enums/ContactType";
import { selectBeeSectorList } from "../../http/Redux/Store/BeeSectorListSlice";
import { fetchBeeSectors } from "../../http/Redux/Store/BeeSectorListActions";
import styles from "../Company/CompanyProfile.module.scss";
import { CompanyProfileImageModel } from "../../http/Redux/Models/CompanyProfileImageModel";
import CompanyProfileService from "../../http/CompanyProfileService";
import Tour from "../../components/Tours/PageTour";
import { fetchCompanyVerifications } from "../../http/Redux/Store/CompanyVerificationActions";
import { updateUnspscSelection } from "../../http/Configuration/configurationApi";
import CompanySpecificClientDetails from "../../components/Company/CompanySpecificClientDetails/CompanySpecificClientDetails";
import CompanyProfileSections from "../../util/enums/CompanyProfileSections";
import Shareholders from "../../components/Company/CompanyPost/CompanyShareholders/CompanyShareholders";

const WorkCentreCompanyProfile = () => {
  const [company, setCompany] = useState<CompanyViewModel>({} as CompanyViewModel);
  const beeSectors = useAppSelector(selectBeeSectorList);
  const { pagesectionid } = useParams();
  const { clientid, supplierid, status, staged, agentTaskId } = useParams();
  const parsedClientId = Number(clientid);

  const [companyComparer, setCompanyComparer] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [profileImage, setProfileImage] = useState<CompanyProfileImageModel>(
    {} as CompanyProfileImageModel
  );

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  // Cheap hack to show the correct section.
  // Will fix this to use Redux when a demo isn't looming FFS.
  // 1 = Details, 2 = Contacts, 3 = Address, etc
  const [pageSection, setPageSection] = useState<number>(1);

  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const [, toggleBeeSections] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [redirectPageNo, setRedirectPageNo] = useState<number>(0);
  const [stagedValue, setStagedValue] = useState(false);
  const navigate = useNavigate();

  // This tracks if the Company Specific Supplier Details component is dirty or not.
  const [cssdIsDirty, setCssdIsDirty] = useState<boolean>(false);
  const [pageChanging, setPageChanging] = useState<boolean>(false);

  const steps = [
    {
      target: ".step-share",
      title: "Share your Profile",
      placement: "right",
      disableOverlayClose: true,
      disableScrollParentFix: true,
      hideFooter: true,
      content: (
        <>
          <img src={Hi} alt="" className="small-blinkie" />
          <h3>Share your company profile to users both on and off platform.</h3>
          <br />
          <p>
            Your B1 LINK profile becomes your single page supplier registration profile for your new
            clients.
          </p>
        </>
      ),
    },
  ];

  const dispatch = useAppDispatch();

  const PageLoadLogic = useCallback(() => {
    if (company.companyBeeDetails?.id !== undefined || company.companyBeeDetails?.id > 0)
      toggleBeeSections(true);
  }, [company.companyBeeDetails?.id]);

  const HandleSameAsPrimaryContactSwitch = (copyValues: boolean, contactToUpdate: ContactModel) => {
    const contactBeingUpdated = { ...contactToUpdate };

    if (copyValues) {
      const primaryContact = company.companyContacts.find(
        (contact) => contact.contactTypeId === ContactType.Primary
      ) as ContactModel;

      // Update specific fields (leaving out addressId, etc)
      contactBeingUpdated.name = primaryContact?.name;
      contactBeingUpdated.email = primaryContact?.email;
      contactBeingUpdated.landLine = primaryContact?.landLine;
      contactBeingUpdated.mobile = primaryContact?.mobile;
      contactBeingUpdated.fax = primaryContact?.fax;
      contactBeingUpdated.website = primaryContact?.website;
      contactBeingUpdated.idNumber = primaryContact?.idNumber;
      contactBeingUpdated.username = primaryContact?.username;
      contactBeingUpdated.designation = primaryContact?.designation ?? "";
      contactBeingUpdated.isDeleted = primaryContact?.isDeleted;

      dispatch(setCompanyContactState(contactBeingUpdated));
      setIsDirty(true);
      return contactBeingUpdated;
    }

    contactBeingUpdated.name = "";
    contactBeingUpdated.email = "";
    contactBeingUpdated.landLine = "";
    contactBeingUpdated.mobile = "";
    contactBeingUpdated.fax = "";
    contactBeingUpdated.website = "";
    contactBeingUpdated.idNumber = "";
    contactBeingUpdated.username = "";
    contactBeingUpdated.designation = "";
    contactBeingUpdated.isDeleted = false;
    contactBeingUpdated.companyId = 0;

    dispatch(setCompanyContactState(contactBeingUpdated));
    if (contactBeingUpdated.id === 0) setIsDirty(false);
    return contactBeingUpdated;
  };

  /*
   * Used by the address components to determine if "Same As Physical" has been selected
   */
  const HandleSameAsPhysicalSwitch = (copyValues: boolean, addressToUpdate: AddressModel) => {
    const addressBeingUpdated = { ...addressToUpdate };

    if (copyValues) {
      const physicalAddress = company.companyAddresses.find(
        (addr) => addr.addressTypeId === AddressType.Physical
      ) as AddressModel;

      // Update specific fields (leaving out addressId, etc)
      addressBeingUpdated.countryId = physicalAddress?.countryId;
      addressBeingUpdated.provinceId = physicalAddress?.provinceId;
      addressBeingUpdated.line1 = physicalAddress?.line1;
      addressBeingUpdated.line2 = physicalAddress?.line2;
      addressBeingUpdated.suburb = physicalAddress?.suburb;
      addressBeingUpdated.city = physicalAddress?.city;
      addressBeingUpdated.postalCode = physicalAddress?.postalCode;

      dispatch(setCompanyAddressState(addressBeingUpdated));
      setIsDirty(true);
      return addressBeingUpdated;
    }

    dispatch(setCompanyAddressState(addressBeingUpdated));
    if (addressBeingUpdated.addressId === 0) setIsDirty(false);
    return addressBeingUpdated;
  };

  /*
   * Save details captured/changed for company.
   */
  const SaveCompanyDetails = (
    companyDetails: CompanyViewModel,
    selectedUnspscCodes?: Array<number>
  ) => {
    if (JSON.stringify(companyComparer) === JSON.stringify(companyDetails)) return;

    if (account && inProgress === "none") {
      let token = "";

      instance
        .acquireTokenSilent({
          scopes: protectedResources.companyApi.scopes,
          account,
        })
        .then(async (response) => {
          token = response.accessToken;
          await SaveCompanyChanges(token, companyDetails, stagedValue);
          setCompanyComparer(JSON.parse(JSON.stringify(companyDetails)));

          if (selectedUnspscCodes && selectedUnspscCodes.length > 0) {
            await updateUnspscSelection(selectedUnspscCodes);
          }

          setIsDirty(false);
          toast.success("Company data has been saved successfully.");
        })
        .catch((error) => {
          setIsDirty(true);
          toast.error(
            "An error occurred when saving the company data. Please try again, or contact support."
          );
          throw error;
        });
    }
  };

  /*
   * Save details captured/changed for a specific contact.
   */
  const SaveContactDetails = (contactDetails: ContactModel) => {
    const contactToSave = JSON.parse(JSON.stringify(contactDetails)); // Makes a deep copy. Perhaps a better way exists?

    if (account && inProgress === "none") {
      let token = "";

      instance
        .acquireTokenSilent({
          scopes: protectedResources.companyApi.scopes,
          account,
        })
        .then(async (response) => {
          token = response.accessToken;

          // If a new secondary contact is set up, these will be empty:
          if (contactToSave.companyId === undefined || contactToSave.companyId === 0) {
            contactToSave.companyId = company.companyId;

            // If the contactTypeId is 0, then save as a secondary, otherwise, save as General.
            if (contactToSave.contactTypeId === 0)
              contactToSave.contactTypeId = ContactType.Secondary;

            const result = await AddNewContact(token, contactToSave, stagedValue);

            contactToSave.id = parseInt(result.data, 10);
            dispatch(setCompanyContactState(contactToSave));
            dispatch(removeContactFromState(0)); // Probably a MUCH better way to handle this.
            toast.success("Contact data saved successfully.");
          } else {
            await SaveContactChanges(token, contactToSave, stagedValue);
            dispatch(setCompanyContactState(contactToSave));
            toast.success("Contact data saved successfully.");
          }
          setIsDirty(false);
        })
        .catch((error) => {
          toast.error(
            "An error occurred when saving the contact data. Please try again, or contact support."
          );
          throw error;
        });
    }
  };

  /*
   * Save details captured/changed for a specific address.
   */
  const SaveAddressDetails = async (addressDetails: AddressModel) => {
    const addressToSave = addressDetails;

    try {
      await SaveAddressChanges(addressToSave, stagedValue);
      dispatch(setCompanyAddressState(addressToSave));

      setIsDirty(false);
      toast.success("Address data saved successfully.");
    }
    catch (exception) {
      toast.error("An error occurred when saving the address data. Please try again, or contact support.");
    }
  };

  /*
   * Save B-BBEE details for a specific company
   */
  const SaveBeeDetails = async (beeDetailsInput: BeeDetailsModel) => {
    const beeDetailsToSave: BeeDetailsModel = JSON.parse(JSON.stringify(beeDetailsInput));

    try {
      // If new BEE details are being captured:
      if (!beeDetailsToSave.companyId) {
        beeDetailsToSave.companyId = company.companyId;
        if (!beeDetailsToSave.hasBEECertificate) beeDetailsToSave.hasBEECertificate = false;
        const result = await AddNewBeeDetails(beeDetailsToSave, stagedValue);
        beeDetailsToSave.id = parseInt(result.data, 10);
        dispatch(setCompanyBeeDetailsState(beeDetailsToSave));
      } else {
        if (!beeDetailsToSave.hasBEECertificate) beeDetailsToSave.hasBEECertificate = false;
        await SaveBeeDetailChanges(beeDetailsToSave, stagedValue);
        dispatch(setCompanyBeeDetailsState(beeDetailsToSave));
      }

      setIsDirty(false);
      toast.success("B-BBEE data has been saved successfully.");
    } catch (error) {
      toast.error(
        "An error occurred when saving the B-BBEE data. Please try again, or contact support."
      );
      throw error;
    }
  };

  /*
   * Save Bank Account details for a specific company
   */
  const SaveBankAccountDetails = async (bankAccountDetails: BankAccountDetailsModel) => {
    const bankAccountDetailsToSave: BankAccountDetailsModel = JSON.parse(
      JSON.stringify(bankAccountDetails)
    );
    try {
      // If new Bank account details are being captured:
      if (
        bankAccountDetailsToSave.companyId === undefined ||
        bankAccountDetailsToSave.companyId === 0
      ) {
        bankAccountDetailsToSave.companyId = company.companyId;
        const result = await AddNewBankAccount(bankAccountDetailsToSave, stagedValue);
        bankAccountDetailsToSave.id = result.data;
        dispatch(setCompanyBankAccountState(bankAccountDetailsToSave));
        dispatch(removeBankAccFromState(0));
        toast.success("New bank account saved successfully.");
      } else {
        await UpdateBankAccount(bankAccountDetailsToSave, stagedValue);
        dispatch(setCompanyBankAccountState(bankAccountDetailsToSave));
        toast.success("Bank account data saved successfully.");
      }

      setIsDirty(false);
    } catch (error) {
      toast.error(
        "An error occurred when saving bank account data. Please try again, or contact support"
      );
      throw error;
    }
  };

  /*
   * Deletes a contact record
   */
  const DeleteContactRecord = async (contact: ContactModel, stagedVal: boolean) => {
    const contactToDelete: ContactModel = JSON.parse(JSON.stringify(contact));

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this contact?",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const result = await DeleteContact(contactToDelete, stagedVal);
        if (result === 200 || result === 204) {
          dispatch(removeContactFromState(contactToDelete.id));
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((response) => {
        if (response.isConfirmed) {
          toast.success("Contact deleted successfully.");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occurred when deleting the contact. Please try again, or contact support."
        );
        throw error;
      });
  };

  /*
   * Deletes a bank record
   */
  const DeleteBankAccountRecord = async (
    bankAccount: BankAccountDetailsModel,
    stagedVal: boolean
  ) => {
    const bankAccountToDelete: BankAccountDetailsModel = JSON.parse(JSON.stringify(bankAccount));

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this bank account record?",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const result = await DeleteBankAccount(bankAccountToDelete, stagedVal);
        if (result === 200 || result === 204) {
          dispatch(removeBankAccFromState(bankAccountToDelete.id));
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((response) => {
        if (response.isConfirmed) {
          toast.success("Bank account deleted successfully.");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occurred when deleting the bank account. Please try again, or contact support."
        );
        throw error;
      });
  };

  const handleDialogOpen = () => setDialogOpen(true);

  // eslint-disable-next-line no-unused-vars
  const handleDialogClose = (pageNumber?: number) => {
    setDialogOpen(false);

    if (pageNumber && pageNumber > 0) {
      setPageSection(redirectPageNo);
    }
  };

  const revertChanges = () => {
    dispatch(setCompanyViewModelState(companyComparer));
    setIsDirty(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageSection === pageNumber) return;

    setPageChanging(true);

    if (cssdIsDirty) {
      const currentPageSection = pageSection;
      setPageSection(currentPageSection);
      return;
    }

    if (isDirty) {
      setRedirectPageNo(pageNumber);
      handleDialogOpen();
      return;
    }

    setPageSection(pageNumber);
    setPageChanging(false);
  };

  const allVerifications = useAppSelector(getCompanyVerifications);
  const currentStatus = Number(status);

  const loaderFunction = useCallback(async (companyIdToFetch: number, useStaged: boolean) => {
    setBackdropVisible(true);

    const result = await GetCompanyDataById(companyIdToFetch, useStaged);
    const profile = await CompanyProfileService.getParticularProfileImage(companyIdToFetch, false);
    dispatch(setCompanyViewModelState(result.data));
    dispatch(fetchCompanyVerifications(companyIdToFetch, useStaged));
    if (beeSectors.length === 0) dispatch(fetchBeeSectors());

    setCompany(result.data);
    if (!staged) {
      setProfileImage(profile.data);
    }
    setBackdropVisible(false);

    setCompanyComparer(JSON.parse(JSON.stringify(result)));
    PageLoadLogic();
  }, []);

  useEffect(() => {
    if (staged === "true") {
      setStagedValue(true);
      loaderFunction(parseInt(supplierid || "", 10), true);
    } else {
      setStagedValue(false);
      if (company.companyId === undefined) {
        loaderFunction(parseInt(supplierid || "", 10), false);
      }
    }

    if (pagesectionid) setPageSection(parseInt(pagesectionid, 10));
  }, [
    PageLoadLogic,
    account?.idTokenClaims?.extension_companyid,
    company.companyId,
    dispatch,
    supplierid,
    pagesectionid,
    staged,
    profileImage,
  ]);

  usePrompt("Unsaved changes will be lost", isDirty);
  const CallBack = (value: number) => {
    console.log(value);
  };

  const handleCloseCompanyProfile = () => {
    const template = `/agent/workcentre/0/${clientid}`;
    navigate(template);
  }

  return (
    <>
      <LoadingBackdrop showBackdrop={backdropVisible} />

      <div className="wrap-no-padding">
        <Tour runs={false} pageSteps={steps as []} callBack={CallBack} page="Profile-W" />
        <div className={styles.companyProfileBox}>
          <div className={styles.companyProfileSideBar}>
            <CompanyProfileSidePanel currentSection={pageSection} setSection={handlePageChange} showForWorkcenter />
          </div>
          <div className={styles.companyProfileContent}>
            <IconButton onClick={handleCloseCompanyProfile} className={styles.hoverCloseCompany}><CloseIcon /></IconButton>
            <Box className={styles.pageLayout}>
              <Box className={styles.box}>
                <div className={styles.companyViewBox}>
                  <div className={styles.companyViewRight}>
                    <div className={styles.imageBox}>
                      <div className="card-image">
                        <img
                          src={profileImage.url !== null && !staged ? profileImage.url : bufferProfile}
                          alt=""
                          className={styles.companyProfileImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.companyViewLeft}>
                    <h2>{company.registeredName}</h2>
                    <h3>{company.tradingName}</h3>
                  </div>
                </div>
              </Box>
              {pageSection === 1 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={currentStatus}
                    companyId={company.companyId}
                    clientId={parsedClientId}
                    staged={stagedValue}
                    agentPathId={Number(agentTaskId)}
                    workcentreAgentPath
                  />

                  <CompanyDetailsPost
                    headerText="General Company Details"
                    companyData={company}
                    workcentreAgentPath
                    agentTaskId={Number(agentTaskId)}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                  />

                  <CompanyTaxInformationPost
                    headerText="Tax Information"
                    companyData={company}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                    workcentreAgentPath
                    agentTaskId={Number(agentTaskId)}
                  />
                </div>
              )}
              {pageSection === 2 && (
                <div className="navigation-content">
                  <CompanyGeneralContactPost
                    headerText="General Company Contact"
                    contactData={
                      company.companyContacts.find(
                        (c) => c.contactTypeId === ContactType.GeneralCompany
                      ) || ({} as ContactModel)
                    }
                    SetDirtyCallback={setIsDirty}
                    OnCopyPrimary={HandleSameAsPrimaryContactSwitch}
                    OnContactSave={SaveContactDetails}
                    staged={stagedValue}
                    workcentreAgentPath
                    agentTaskId={Number(agentTaskId)}
                  />
                  <CompanyPrimaryContactPost
                    headerText="Primary Company Contact"
                    contactData={
                      company.companyContacts.find(
                        (c) => c.contactTypeId === ContactType.Primary
                      ) || ({} as ContactModel)
                    }
                    SetDirtyCallback={setIsDirty}
                    OnContactSave={SaveContactDetails}
                  />
                  <CompanySecondaryContactPost
                    contacts={company.companyContacts.filter(
                      (c) => c.contactTypeId === ContactType.Secondary
                    )}
                    SetDirtyCallback={setIsDirty}
                    OnContactSave={SaveContactDetails}
                    OnContactDelete={DeleteContactRecord}
                  />
                </div>
              )}
              {pageSection === 3 && (
                <div className="navigation-content">
                  <CompanyAddressDetailsPost
                    addresses={company.companyAddresses}
                    SetDirtyCallback={setIsDirty}
                    OnSaveAddress={SaveAddressDetails}
                    OnCopyPhysical={HandleSameAsPhysicalSwitch}
                  />
                </div>
              )}
              {pageSection === 4 && (
                <div className="navigation-content">
                  <CompanyBEEDetails
                    beeData={company.companyBeeDetails}
                    SetDirtyCallback={setIsDirty}
                    sectorList={beeSectors}
                    isWorkCentre
                    OnBeeSave={SaveBeeDetails}
                    showLoadingBackdrop={setBackdropVisible}
                  />
                </div>
              )}
              {pageSection === 10 && (
                <div className="navigation-content">
                  <CompanyHeadingPost
                    status={0}
                    companyId={company.companyId}
                    staged={stagedValue}
                    workcentreAgentPath={false}
                    agentPathId={0}
                  />

                  <Shareholders staged={stagedValue} companyId={company.companyId} />
                </div>
              )}
              {pageSection === 5 && (
                <div className="navigation-content">
                  <CompanyBankingDetailsPost
                    headerText="Company Bank Accounts"
                    bankAccounts={company.bankAccounts}
                    OnBankSave={SaveBankAccountDetails}
                    SetDirtyCallback={setIsDirty}
                    OnBankDelete={DeleteBankAccountRecord}
                    VerificationData={
                      allVerifications.filter((x) => x.pageSection === CompanyProfileSections.BankingDetails)[0] ||
                      ({} as CompanyVerificationModel)
                    }
                    staged={stagedValue}
                    workcentreAgentPath
                    agentTaskId={Number(agentTaskId)}
                  />
                </div>
              )}
              {pageSection === 6 && (
                <div className="navigation-content">
                  <CompanyProductsAndServicesPost
                    headerText="Global Product Offerings"
                    company={company as CompanyViewModel}
                    OnSave={SaveCompanyDetails}
                    SetDirtyCallback={setIsDirty}
                    staged={stagedValue}
                    workcentreAgentPath
                    agentTaskId={Number(agentTaskId)}
                  />
                </div>
              )}
              {pageSection === 7 && (
                <div className="navigation-content">
                  <CompanyDocumentsPost evaluatingCompanyId={0} evaluatedCompanyId={0} />
                </div>
              )}
              {pageSection === 9 && (
                <div className="navigation-content">
                  <CompanySpecificClientDetails
                    clientCompanyId={parseInt(clientid || "0", 10)}
                    supplierCompanyId={parseInt(supplierid || "0", 10)}
                    isDirty={cssdIsDirty}
                    staged={stagedValue}
                    setIsDirtyOnParent={setCssdIsDirty}
                    pageChanging={pageChanging}
                    setPageChanging={setPageChanging}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>
        <UnsavedChangeDialog
          openDialog={dialogOpen}
          onCloseOrCancel={() => {
            handleDialogClose();
          }}
          onDiscardChanges={() => {
            revertChanges();
            redirectPageNo > 0 ? handleDialogClose(redirectPageNo) : handleDialogClose();
          }}
          onSaveChanges={() => {
            SaveCompanyDetails(company);
            handleDialogClose();
          }}
        />
      </div>
    </>
  );
};

export default WorkCentreCompanyProfile;
