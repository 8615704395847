/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import OkIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadedIcon from "@mui/icons-material/CloudDownload";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import { randomId } from "@mui/x-data-grid-generator";
import { Alert, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, TextField, Tooltip, Typography, styled } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { DropzoneArea } from "material-ui-dropzone";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { getCompanyShareholders } from "../../../../http/Redux/Store/CompanyShareholdersSlice";
import { useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "../../../UI/LoadingOverlay";
import { addNewCompanyShareholderDocumentAsync } from "../../../../http/DocumentManagement/DocumentManagement";
import { DocumentTypes, ShareholderTypes, genderTypeToString, raceTypeToString, shareholderTypeDocToString, shareholderTypeToString } from "./ShareholderTableTypes";
import CompanyShareholdersService from "../../../../http/Company/CompanyShareholdersService";
import { CompanyShareholdersModel, CompanyShareholdersSaveModel } from "../../../../http/Redux/Models/CompanyShareholders";





interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setIsInEditMode: (isInEditMode: boolean) => boolean;
  setErrorRegex: (errorRegex: boolean) => boolean;
  inEditMode: boolean;
  setEditableRow: (editableRow: GridValidRowModel) => GridValidRowModel;
  companyId: number,
  percentageOver: boolean,
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel, setIsInEditMode, inEditMode, setErrorRegex, setEditableRow, companyId, percentageOver } = props;


  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        companyShareholderId: 0,
        companyId,
        fullName: "",
        idRegistrationNumber: "",
        disability: false,
        sharePercentage: "",
        contactNumber: "",
        title: "",
        genderId: 0,
        raceId: 0,
        shareholderType: 0,
        shareholderIdRegDocumentId: "00000000-0000-0000-0000-000000000000",
        typeId: 0,
        fileName: "",
        isNew: true,
      }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "fullName" },
    }));
    setIsInEditMode(true);
    setErrorRegex(true);
    setEditableRow({});
  };


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={inEditMode || percentageOver}>
        Add Shareholder
      </Button>
    </GridToolbarContainer>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface ViewCellProps {
  row: GridValidRowModel;
  isEditing: boolean;
  setDocument: (data: string) => void;
}

const ViewCell: React.FC<ViewCellProps> = ({ row, isEditing, setDocument }) => {
  const [open, setOpen] = React.useState(false);
  const [upload, setUploadFile] = React.useState<Array<File>>([] as Array<File>);
  const [newTitle, setTitle] = React.useState("");
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
  };



  const UploadFile = async () => {
    setIsLoading(true);

    await addNewCompanyShareholderDocumentAsync(
      row.companyId,
      false,
      new Date(0),
      row.typeId,
      userAccount as string,
      upload,
      row.companyShareholderId,
      row.id
    ).finally(() => {
      setIsLoading(false);
      handleClose();
      toast.success("Document Uploaded");
    });

    const updateRow = {
      companyShareholderId: row.companyShareholderId,
      companyId: row.companyId,
      fullName: row.fullName,
      fileName: upload[0].name,
      idRegistrationNumber: row.idRegistrationNumber,
      disability: row.disability ? row.disability : false,
      sharePercentage: row.sharePercentage,
      contactNumber: row.contactNumber,
      title: row.title,
      genderId: row.genderId,
      raceId: row.raceId,
      shareholderType: row.shareholderType,
      shareholderIdRegDocumentId: row.id,
    }

    await CompanyShareholdersService.update(updateRow as CompanyShareholdersSaveModel, false).finally(() => {
      setDocument(row.id);
    });




  };

  const HandleDownloadFile = async (fileNameToSave: string) => {
    const fileName = fileNameToSave;
    const url = `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/documents/${row.shareholderIdRegDocumentId}`;
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const windowUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = windowUrl;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
    });
    handleMenuClose();
  };

  React.useEffect(() => {
    if (upload.length !== 0) {
      setTitle(upload[0].name);
    }
  }, [upload, upload.length])


  return (
    <>
      <div style={{ textAlign: "center", width: "100%" }}>
        {row.companyShareholderId !== 0 && !row.isNew ? (
          <div>
            <GridActionsCellItem
              icon={<FilePresentIcon sx={{ color: (row.shareholderIdRegDocumentId !== "00000000-0000-0000-0000-000000000000" && row.shareholderIdRegDocumentId !== undefined && row.shareholderIdRegDocumentId !== null) ? "green" : "red" }} />}
              label="Upload"
              id="basic-button"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleMenuClick}
              sx={{
                color: "primary.main",
              }}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClickOpen}>
                <GridActionsCellItem
                  icon={<CloudUploadIcon />}
                  label="Upload"
                  sx={{
                    color: "primary.main",
                  }}
                  onClick={handleClickOpen}
                />
                Upload Document
              </MenuItem>
              {row.shareholderIdRegDocumentId !== "00000000-0000-0000-0000-000000000000" && row.shareholderIdRegDocumentId !== undefined && row.shareholderIdRegDocumentId !== null &&
                <MenuItem onClick={() => HandleDownloadFile(row.fileName)}>
                  <GridActionsCellItem
                    icon={<CloudDownloadedIcon />}
                    label="Download"
                    sx={{
                      color: "primary.main",
                    }}
                    onClick={() => HandleDownloadFile(row.fileName)} />
                  Download Document
                </MenuItem>
              }
            </Menu>
          </div>
        ) : (
          <div>
            <Tooltip title="Once you have saved your shareholder info you will be able to upload your required document. ">
              <InfoIcon sx={{ width: 20, height: 20, color: "orange", marginTop: 0.5 }} />
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        {open && (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Upload
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CancelIcon />
            </IconButton>
            <DialogContent dividers>
              <LoadingOverlay active={isLoading}>
                <Box height={300} width={500} sx={{ margin: 2 }}>
                  <DropzoneArea
                    filesLimit={1}
                    showAlerts={false}
                    dropzoneText="Drop File"
                    showPreviews={false}
                    showFileNames
                    showPreviewsInDropzone={false}
                    onChange={(files) => { setUploadFile(files) }}
                  />
                  <br />
                  <Typography>Upload: {newTitle}</Typography>
                </Box>
              </LoadingOverlay>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => UploadFile()} variant="contained">
                Save Upload
              </Button>
            </DialogActions>
          </BootstrapDialog>
        )}
      </div>
    </>
  )
};

const Shareholders = ({ staged, companyId }: { staged: boolean, companyId: number }) => {
  const companyShareholders = useAppSelector(getCompanyShareholders);
  const [document, setDocument] = React.useState("");
  const handleset = () => {
    const newRowsContainer: GridValidRowModel[] = [];
    companyShareholders.forEach(element => {
      newRowsContainer.push({ ...element, id: randomId(), typeId: element.shareholderType === ShareholderTypes.Person ? DocumentTypes.Id : DocumentTypes.Registration });
    });

    return newRowsContainer;
  }
  const initialRows: GridRowsProp = handleset() as GridValidRowModel[] || [];
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [editableRow, setEditableRow] = React.useState({} as GridValidRowModel);
  const [regNumberHeading, setRegNumberHeading] = React.useState("Id/Reg Number");
  const [errorRegex, setErrorRegex] = React.useState(true);
  const [errorFullNameRegex, setErrorFullNameRegex] = React.useState(true);
  const [errorPercentageRegex, setErrorPercentageRegex] = React.useState(true);
  const [inEditMode, setIsInEditMode] = React.useState(false);
  const [percentageOver, setPercentageOver] = React.useState(false);
  const [totalPercentError, setTotalPercentError] = React.useState(false);
  const [totalPercent, setTotalPercent] = React.useState<number>(0);
  const [reset, setReset] = React.useState(false);


  const handleGetPercentage = () => {
    const percentage: number = rows?.map(datum => Number(datum.sharePercentage)).reduce((a, b) => a + b);
    if (percentage > 100) {
      setTotalPercentError(true);
      setPercentageOver(true);
    }
    else if (percentage === 100) {
      setTotalPercentError(false);
      setPercentageOver(true);
    }
    else if (percentage < 100) {
      setTotalPercentError(true);
      setPercentageOver(false);
    }


    setTotalPercent(percentage);
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleShareholderType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegNumberHeading(Number(event.target.value) === ShareholderTypes.Person ? "ID Number" : "Registration");
    setEditableRow({ ...editableRow, shareholderType: event.target.value });
  }

  const handleIdRegNumberValidation = (event: string) => {
    const idRegex = /^\d{13}$/;
    const compRegRegex = /^\\d{4}\\\/\\d{6}\\\/\\d{2}$|^\\d{4}\\\/\\d{2}$/;
    const foreignCompRegRegex = /^(?=(?:[a-zA-Z0-9]|[\\/\\-]){1,15}$)(?=(?:[^\\/\\-]*[\\/\\-]){0,2}[^\\/\\-]*$)[a-zA-Z0-9\\/\\-]*$/;

    if (editableRow.shareholderType === 1) {
      const matchId = idRegex.test(event);

      if (matchId) {
        setErrorRegex(false);

      }
      else {
        setErrorRegex(true);
      }

    }
    else if (editableRow.shareholderType === 2) {
      const matchCompany = compRegRegex.test(event);

      if (matchCompany) {

        setErrorRegex(false);
      }
      else {
        const matchForeignCompany = foreignCompRegRegex.test(event);
        if (matchForeignCompany) {
          setErrorRegex(false);
        }
        else {
          setErrorRegex(true);
        }

      }
    }
    else {
      setErrorRegex(true);
    }
    setEditableRow({ ...editableRow, idRegistrationNumber: event })
  }
  const handleIdRegNumberValidationOnEdit = (row: GridValidRowModel) => {
    const idRegex = /^\d{13}$/;
    const compRegRegex = /^\\d{4}\\\/\\d{6}\\\/\\d{2}$|^\\d{4}\\\/\\d{2}$/;
    const foreignCompRegRegex = /^(?=(?:[a-zA-Z0-9]|[\\/\\-]){1,15}$)(?=(?:[^\\/\\-]*[\\/\\-]){0,2}[^\\/\\-]*$)[a-zA-Z0-9\\/\\-]*$/;

    if (row.shareholderType === ShareholderTypes.Person) {
      const matchId = idRegex.test(row.idRegistrationNumber);

      if (matchId) {
        setErrorRegex(false);

      }
      else {
        setErrorRegex(true);
      }

    }
    else if (editableRow.shareholderType === ShareholderTypes.Company) {
      const matchCompany = compRegRegex.test(row.idRegistrationNumber);

      if (matchCompany) {

        setErrorRegex(false);
      }
      else {
        const matchForeignCompany = foreignCompRegRegex.test(row.idRegistrationNumber);
        if (matchForeignCompany) {
          setErrorRegex(false);
        }
        else {
          setErrorRegex(true);
        }

      }
    }
    else {
      setErrorRegex(true);
    }
    setEditableRow({ ...editableRow, idRegistrationNumber: row.idRegistrationNumber })
  }

  const handleFullNameValidation = (event: string) => {
    const nameRegex = /(.*[a-z]){3}$/;
    const matchFullName = nameRegex.test(event);
    if (matchFullName) {
      setErrorFullNameRegex(false);

    }
    else {
      setErrorFullNameRegex(true);
    }

    setEditableRow({ ...editableRow, fullName: event });
  }

  const handlePercentageValidation = (value: string) => {
    const Regex = /^[1-9][0-9]?$|^100$/;
    const matchPercentage = Regex.test(value);

    if (matchPercentage) {
      setErrorPercentageRegex(false);

    }
    else {
      setErrorPercentageRegex(true);
    }
    setEditableRow({ ...editableRow, sharePercentage: value })
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    handleGetPercentage();
  };

  const handleEditClick = (id: GridRowId) => () => {
    const getRow = rows?.find(x => x.id === id);

    if (getRow) {
      handleFullNameValidation(getRow.fullName);
      handleIdRegNumberValidationOnEdit(getRow);
      handlePercentageValidation(getRow.sharePercentage);
      setEditableRow(getRow);
    }
    setIsInEditMode(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setIsInEditMode(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
    const getrow = rows?.find((row) => row.id === id);

    Swal.fire({
      title: "Are you sure you want to delete this shareholder?",
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const loader = async () => {
          await CompanyShareholdersService.delete(getrow?.companyId, getrow?.companyShareholderId, staged);
          toast.success("Shareholder Removed.");
          const percentage = totalPercent - (getrow?.sharePercentage as number);
          setTotalPercent(percentage);
        }
        loader();
      }
    });

  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    setIsInEditMode(false);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    handleGetPercentage();
    setEditableRow({});
  };

  const processRowUpdate = (newRow: GridRowModel) => {


    const updatedRow = {
      ...newRow,
      companyShareholderId: editableRow.companyShareholderId,
      companyId,
      fullName: editableRow.fullName,
      idRegistrationNumber: editableRow.idRegistrationNumber,
      disability: editableRow.disability,
      sharePercentage: editableRow.sharePercentage,
      contactNumber: editableRow.contactNumber,
      title: editableRow.title,
      typeId: editableRow.shareholderType === ShareholderTypes.Person ? DocumentTypes.Id : DocumentTypes.Registration,
      genderId: editableRow.genderId,
      raceId: editableRow.raceId,
      shareholderType: editableRow.shareholderType,
      fileName: editableRow.fileName,
      shareholderIdRegDocumentId: editableRow.shareholderIdRegDocumentId !== "00000000-0000-0000-0000-000000000000" ? editableRow.shareholderIdRegDocumentId : "00000000-0000-0000-0000-000000000000",
      isNew: false,
    };

    const createRow = {
      companyId,
      fullName: editableRow.fullName,
      idRegistrationNumber: editableRow.idRegistrationNumber,
      disability: editableRow.disability ? editableRow.disability : false,
      sharePercentage: editableRow.sharePercentage,
      contactNumber: editableRow.contactNumber,
      title: editableRow.title,
      genderId: editableRow.genderId,
      raceId: editableRow.raceId,
      fileName: editableRow.fileName !== undefined ? editableRow.fileName : "",
      shareholderType: editableRow.shareholderType,
      shareholderIdRegDocumentId: editableRow.shareholderIdRegDocumentId !== undefined ? editableRow.shareholderIdRegDocumentId : "00000000-0000-0000-0000-000000000000",
    }

    const updateRow = {
      companyShareholderId: editableRow.companyShareholderId,
      companyId,
      fullName: editableRow.fullName,
      idRegistrationNumber: editableRow.idRegistrationNumber,
      disability: editableRow.disability ? editableRow.disability : false,
      sharePercentage: editableRow.sharePercentage,
      contactNumber: editableRow.contactNumber,
      title: editableRow.title,
      genderId: editableRow.genderId,
      raceId: editableRow.raceId,
      fileName: editableRow.fileName,
      shareholderType: editableRow.shareholderType,
      shareholderIdRegDocumentId: editableRow.shareholderIdRegDocumentId,
    }

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    const newRowsContainer: GridValidRowModel[] = [];

    // Adding existing rows to the new container
    rows.forEach(row => {
      if (row.id !== newRow.id) {
        newRowsContainer.push({ ...row });
      }
    });

    // Appending the updatedRow to the new container
    newRowsContainer.push(updatedRow);
    if (newRowsContainer?.map(datum => Number(datum.sharePercentage)).reduce((a, b) => a + b) > 100) {
      setTotalPercentError(true);
    }
    else {
      setTotalPercentError(false);
    }

    setTotalPercent(newRowsContainer?.map(datum => Number(datum.sharePercentage)).reduce((a, b) => a + b));

    setEditableRow({});

    // Save

    const loader = async () => {
      const getrow = rows?.find((row) => row.id === newRow.id);
      if (getrow?.isNew) {
        await CompanyShareholdersService.create(createRow as CompanyShareholdersSaveModel, staged).finally(() => {
          setReset(true);
          toast.success("New shareholder added.");
        });

      }
      else {
        await CompanyShareholdersService.update(updateRow as CompanyShareholdersSaveModel, staged);
        toast.success("Shareholder Updated.");
      }
    }
    loader();

    return updatedRow;
  };



  const columns: GridColDef[] = [
    {
      field: "companyId",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "fileName",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "companyShareholderId",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      editable: true,
      headerName: "Title",
      width: 100,
      type: "singleSelect",
      disableColumnMenu: true,
      renderEditCell: () => (
        <TextField
          select
          sx={{ width: "100%" }}
          size="small"
          value={editableRow?.title}
          onChange={(event) => setEditableRow({ ...editableRow, title: event.target.value })}>
          <MenuItem key={1} value="Mr">Mr</MenuItem>
          <MenuItem key={2} value="Ms">Ms</MenuItem>
          <MenuItem key={3} value="Miss">Miss</MenuItem>
          <MenuItem key={4} value="Other">Other</MenuItem>
        </TextField >

      ),
    },
    {
      field: "fullName",
      headerName: "Full Name",
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      type: "text",
      editable: true,
      renderEditCell: () => (
        <TextField
          sx={{ width: "100%" }}
          size="small"
          error={errorFullNameRegex}
          focused={editableRow?.title && !editableRow?.fullName}
          disabled={!editableRow?.title}
          value={editableRow?.fullName}
          onChange={(event) => handleFullNameValidation(event.target.value)} />
      ),
    },
    {
      field: "contactNumber",
      editable: true,
      headerName: "Contact Number",
      disableColumnMenu: true,
      width: 180,
      renderEditCell: () => (
        <TextField
          size="small"
          focused={editableRow?.fullName && !editableRow?.contactNumber}
          disabled={errorFullNameRegex}
          value={editableRow?.contactNumber}
          onChange={(event) => setEditableRow({ ...editableRow, contactNumber: event.target.value })} />
      ),
    },
    {
      field: "genderId",
      editable: true,
      headerName: "Gender",
      disableColumnMenu: true,
      width: 110,
      type: "singleSelect",
      renderCell: (params) => (
        <div>{genderTypeToString(params.value)}</div>
      ),
      renderEditCell: () => (
        <TextField
          select
          focused={editableRow?.contactNumber && !editableRow?.genderId}
          disabled={!editableRow?.contactNumber}
          sx={{ width: "100%" }}
          size="small"
          value={editableRow?.genderId}
          onChange={(event) => setEditableRow({ ...editableRow, genderId: event.target.value })}>
          <MenuItem key={1} value={1}>Male</MenuItem>
          <MenuItem key={2} value={2}>Female</MenuItem>
          <MenuItem key={3} value={3}>Other</MenuItem>
        </TextField >

      ),
    },
    {
      field: "raceId",
      editable: true,
      headerName: "Race",
      disableColumnMenu: true,
      width: 100,
      type: "singleSelect",
      renderCell: (params) => (
        <div>{raceTypeToString(params.value)}</div>
      ),
      renderEditCell: () => (
        <TextField
          select
          focused={editableRow?.genderId && !editableRow?.raceId}
          disabled={!editableRow?.genderId}
          sx={{ width: "100%" }}
          size="small"
          value={editableRow?.raceId}
          onChange={(event) => setEditableRow({ ...editableRow, raceId: event.target.value })}>
          <MenuItem key={1} value={1}>White</MenuItem>
          <MenuItem key={2} value={2}>Black</MenuItem>
          <MenuItem key={3} value={3}>Other</MenuItem>
        </TextField >

      ),
    },
    {
      field: "sharePercentage",
      editable: true,
      disableColumnMenu: true,
      headerName: "Share %",
      width: 80,
      renderEditCell: () => (
        <TextField
          size="small"
          error={errorPercentageRegex}
          focused={editableRow?.raceId && !editableRow?.sharePercentage}
          disabled={!editableRow?.raceId}
          value={editableRow?.sharePercentage}
          onChange={(event) => handlePercentageValidation(event.target.value)} />
      ),
    },
    {
      field: "shareholderType",
      editable: true,
      headerName: "Shareholder Type",
      disableColumnMenu: true,
      width: 180,
      type: "singleSelect",
      renderCell: (params) => (
        <div>{shareholderTypeToString(params.value)}</div>
      ),
      renderEditCell: () => (
        <TextField
          select
          sx={{ width: "100%" }}
          size="small"
          focused={editableRow?.sharePercentage && !editableRow?.shareholderType}
          disabled={!editableRow?.sharePercentage}
          value={editableRow?.shareholderType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleShareholderType(event)}>
          <MenuItem key={1} value={1}>Person</MenuItem>
          <MenuItem key={2} value={2}>Company</MenuItem>
        </TextField >

      ),
    },
    {
      field: "idRegistrationNumber",
      headerName: regNumberHeading,
      type: "text",
      disableColumnMenu: true,
      width: 180,
      editable: true,
      renderEditCell: () => (
        <TextField
          size="small"
          error={errorRegex}
          focused={editableRow?.shareholderType && !editableRow?.idRegistrationNumber}
          disabled={!editableRow?.shareholderType}
          value={editableRow?.idRegistrationNumber}
          onChange={(event) => handleIdRegNumberValidation(event.target.value)}
          inputProps={{
            onBlur: () => { handleIdRegNumberValidation(editableRow?.idRegistrationNumber) },
          }} />
      ),
    },
    {
      field: "disability",
      editable: true,
      disableColumnMenu: true,
      headerName: "Disability",
      width: 80,
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {params.value ? (<OkIcon sx={{ width: 15, height: 15, color: "green" }} />) : (<CancelIcon sx={{ width: 15, height: 15, color: "red" }} />)}
        </div>
      ),
      renderEditCell: () => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Checkbox
            disabled={!editableRow?.raceId}
            checked={editableRow?.disability}
            onClick={() => setEditableRow({ ...editableRow, disability: (!editableRow?.disability) })}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
    {
      field: "shareholderIdRegDocumentId",
      headerName: "Document",
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => (

        <ViewCell row={params.row as GridValidRowModel} isEditing={inEditMode} setDocument={setDocument} />

      ),
    },
    {
      field: "actions",
      type: "actions",
      disableColumnMenu: true,
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "#3498db" }}
              disabled={errorRegex || errorFullNameRegex || errorPercentageRegex}
              className="textPrimary"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            disabled={inEditMode}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={inEditMode}
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  React.useEffect(() => {

    if (rows?.length !== 0) {
      handleGetPercentage();
    }

    if (reset || document !== "") {
      const loader = async () => {
        const result = await CompanyShareholdersService.getCompanyShareholdersModel(companyId, staged) as CompanyShareholdersModel[];
        const newRowsContainer: GridValidRowModel[] = [];
        result.forEach(element => {
          newRowsContainer.push({ ...element, id: randomId(), typeId: element.shareholderType === ShareholderTypes.Person ? DocumentTypes.Id : DocumentTypes.Registration });
        });
        setRows(newRowsContainer);
      }
      loader();
      setReset(false);
      setDocument("");
    }
  }, [rows?.length, totalPercent, totalPercentError, document, companyShareholders.length, reset, percentageOver])

  return (
    <Box
      sx={{
        padding: 2,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {totalPercentError ? (<Alert sx={{ marginBottom: 2 }} severity="error">Shareholders Percentage {totalPercent}%, percentage must be 100%</Alert>)
        : totalPercent === 0 ? (<Alert sx={{ marginBottom: 2 }} severity="error">Shareholders Percentage 0%</Alert>)
          : (<Alert sx={{ marginBottom: 2 }} severity="success">Shareholders Percentage {totalPercent}%</Alert>)}

      {rows?.find(x => x.shareholderIdRegDocumentId === null) ? (<Alert sx={{ marginBottom: 2 }} severity="error">{rows?.filter(x => x.shareholderIdRegDocumentId === null)[0].fullName} requires a {shareholderTypeDocToString(rows?.filter(x => x.shareholderIdRegDocumentId === null)[0].shareholderType)} document be uploaded.</Alert>) : ("")}
      <DataGridPro
        rows={rows}
        columns={columns}
        editMode="row"
        initialState={{
          columns: {
            columnVisibilityModel: {
              companyId: false,
              companyShareholderId: false,
              fileName: false,
            },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, setIsInEditMode, inEditMode, setErrorRegex, companyId, percentageOver },
        }}
      />
    </Box>
  );
}

export default Shareholders;