/* eslint-disable no-unused-vars */
import { ThemeOptions } from "@mui/material/styles";
import "./Theme.css";


const constantSize = "34px";  // Define the constant
const radius = "15px";
export const colorPrimaryActive = "#4B69F9";
export const colorPrimaryHover = "#6F87FA";
const white = "#ffffff";
export const colorSecondary = "#101820";
// const background = "#F0F0F1";
export const colorNotificationFailed = "#D81B60";
export const colorNotificationValid = "#3DBB95";
export const colorNotificationWarning = "#FF6F00";
export const colorPrimaryGrey = "#4E545B";
export const registrationPageGrey = "#EEEEEF";
export const secondaryGrey = "#C8CBCC";
export const colorLightGrey = "#e2e3e4";


export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export const pxToRem = (value: number) => `${value / 16}rem`;


export const responsiveFontSizes = ({ sm, md, lg }: { sm: number; md: number; lg: number }) => ({
  "@media (min-width:300px)": {
    fontSize: pxToRem(sm),
  },
  "@media (min-width:900px)": {
    fontSize: pxToRem(md),
  },
  "@media (min-width:1200px)": {
    fontSize: pxToRem(lg),
  },
})

export const primaryFont = "Montserrat";

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: colorPrimaryActive,
    },
    secondary: {
      main: colorSecondary,
    },
    error: {
      main: colorNotificationFailed,
    },
    warning: {
      main: colorNotificationWarning,
    },
    info: {
      main: colorPrimaryActive,
    },
    success: {
      main: colorNotificationValid,
    },
    divider: secondaryGrey,
  },
  typography: {
    fontFamily: primaryFont,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 800,
      lineHeight: 80 / 64,
      fontSize: pxToRem(40),
      ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
      fontWeight: 800,
      lineHeight: 64 / 48,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {

      color: colorNotificationValid,
    },
    subtitle2: {

      color: colorNotificationFailed,

    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ sm: 9, md: 14, lg: 16 }),
    },
    caption: {
      fontWeight: 800,
      fontSize: pxToRem(16),
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ sm: 10, md: 11, lg: 16 }),
      textTransform: "uppercase",
    },
    largeHeading: {
      fontWeight: 800,
      lineHeight: pxToRem(40),
      fontSize: pxToRem(36),
      ...responsiveFontSizes({ sm: 24.5, md: 33, lg: 36 }),
      textTransform: "uppercase",
    },
    pageTitle: {
      fontWeight: 700,
      ...responsiveFontSizes({ sm: 20, md: 23, lg: 26 }),
    },
    subHeading: {
      fontWeight: 500,
      lineHeight: pxToRem(29),
      ...responsiveFontSizes({ sm: 17, md: 22, lg: 24 }),
    },
    bodyCopy: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
    },
    link: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
    },
    labelSmall: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: 11, md: 12, lg: 13 }),
    },
    inputLabel: {
      fontWeight: 900,
      lineHeight: pxToRem(29),
      ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: radius,
          "&::placeholder": {
            color: colorSecondary, // Change placeholder text color to black
            opacity: 1,
          },
        },
        input: {
          border: "none",
          "&::placeholder": {
            color: colorSecondary, // Change placeholder text color to black
            opacity: 1,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: colorSecondary, // Change placeholder text color to black
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: colorSecondary, // Change placeholder text color to black
            opacity: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colorPrimaryActive, // Color of the dropdown arrow
        },
        select: {
          background: white,
          borderRadius: radius,
          padding: "18px 0px 18px 24px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: white,
          color: colorSecondary,
          borderRadius: radius,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: radius,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colorPrimaryActive,
          "& .MuiSvgIcon-root": {
            width: constantSize,
            height: constantSize,

          },
          "& .MuiTypography-root": {
            width: constantSize,
            height: constantSize,

          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            width: constantSize,
            height: constantSize,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 100,
          fontSize: "20px",
        },
      },
    },
  },
};

Object.assign(themeOptions, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
      },
      editor: {
        borderBottom: "1px solid gray",
      },
      controlsWrapper: {
        float: "right",
        marginRight: "10px",
      },
      button: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        borderRadius: "0",
      },
    },
  },
})

export default themeOptions;
