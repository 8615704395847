import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyDataById } from "../../../http/Company/company";

const useCompanyDetails = (companyId:number) => {
  const query = useQuery({
    queryKey: [queryKeys.companyDetails, companyId],
    queryFn: async () => (await GetCompanyDataById(companyId,false)).data,
  })

  return query;
}

export default useCompanyDetails;