import { KeyboardArrowDown, Remove } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  Collapse,
  List,
  ListItemButton,
  Button,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { FilterOptionList } from "../../../util/genericInterfaces/FilterOptionList";

interface SingleOptionSelectFilterProps {
  filterName: string;

  filterOptionsList: Array<FilterOptionList>;

  // eslint-disable-next-line no-unused-vars
  updateFilterModel: (updateValue: number, propertyToUpdate: keyof ConnectionSearchModel) => void;

  // eslint-disable-next-line react/no-unused-prop-types
  searchModelProperty: keyof ConnectionSearchModel;
}

// eslint-disable-next-line no-unused-vars
const SingleOptionSelectFilter = ({ filterName, filterOptionsList, updateFilterModel, searchModelProperty }: SingleOptionSelectFilterProps) => {
  const [openFilterBox, setOpenFilterBox] = useState<boolean>(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<number>(0);

  const handleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = parseInt(event.target.value, 10);
    setSelectedFilterOption(updatedValue);
    updateFilterModel(updatedValue, searchModelProperty);
  };

  const handleClear = () => {
    setSelectedFilterOption(0);
    updateFilterModel(0, searchModelProperty);
  }

  return (
    <>
      <ListItemButton onClick={() => setOpenFilterBox((old) => !old)}>
        <ListItemText primary={filterName} />
        {selectedFilterOption > 0 && (
          <Button variant="text" onClick={handleClear}>
            Clear
          </Button>
        )}
        {openFilterBox ? <Remove className="cl-lightGray" /> : <KeyboardArrowDown className="cl-lightGray" />}
      </ListItemButton>
      <Collapse in={openFilterBox} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <FormControl fullWidth>
            <Box className="advanced-filter-box">
              <RadioGroup name="radio-buttons-group">
                {filterOptionsList.map((option) => (
                  <FormControlLabel
                    control={
                      <Radio
                        key={`${option.id}-${option?.name ? option.name : option.value}`}
                        onChange={handleCheckboxChanged}
                        value={option.id}
                      />
                    }
                    label={(option?.name ? option.name : option.value)}
                  />
                ))}
              </RadioGroup>
            </Box>
          </FormControl>
        </List>
      </Collapse>
    </>
  );
};

export default SingleOptionSelectFilter;
