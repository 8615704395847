import { Box, Typography } from "@mui/material";
import { colorPrimaryActive } from "../../../util/MUI_Theme/Theme";
import Invite from "./Invite";
import ConnectionType from "../../../util/enums/ConnectionType";


// eslint-disable-next-line react/require-default-props
const InviteBase = ({ onHandleClose: onClose, connectionType }: { onHandleClose: () => void, connectionType: ConnectionType }) => {
  const title = connectionType === ConnectionType.Client ? "INVITE A NEW CLIENT" : "INVITE A NEW SUPPLIER";

  return (
    <Box>
      <Typography variant="h2" color={colorPrimaryActive} sx={{ textAlign: "center" }}>
        {title}
      </Typography>
      <Invite onHandleClose={onClose} connectionType={connectionType} />
    </Box>
  );
};

export default InviteBase;