import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { UserWorkCentreModel } from "../Models/UserWorkCentreModel";
import { userWorkCentreSlice } from "./UserWorkCentreSlice";
import UserWorkCentreService from "../../UserWorkCentreService";



export const userWorkCentreActions = userWorkCentreSlice.actions


export const fetchUserWorkCentre = (accountId: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: UserWorkCentreModel[] = await UserWorkCentreService.GetWorkCentreList(accountId);
  dispatch(userWorkCentreActions.setUserWorkCentre(response))
}
