import { Button, Tooltip } from "@mui/material";
import { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { SubscriptionVoucherModel } from "../../../../http/Redux/Models/SubscriptionVoucherModel";
import VoucherDetails from "./VoucherDetails";
import VoucherList from "./VoucherList";
import styles from "../../../../pages/Configuration/PlatformBase.module.scss";

export interface SearchProps {
  // eslint-disable-next-line no-unused-vars
  editIsOpenOnVouchers: (status: boolean) => void;
}

const Vouchers = ({ editIsOpenOnVouchers }: SearchProps) => {

  const [isVoucherView, setIsVoucherView] = useState(false);
  const [isVoucherList, setIsVoucherList] = useState(true);
  const [showBackButton, setShowBackButton] = useState(false);
  const [data, setData] = useState<SubscriptionVoucherModel>({} as SubscriptionVoucherModel);


  const getSelectedVoucher = (dataList: SubscriptionVoucherModel) => {

    setData(dataList);
  };

  const switchToEdit = (bool: boolean) => {
    if (bool) {
      setIsVoucherView(true);
      setIsVoucherList(false);
      editIsOpenOnVouchers(true);
      setShowBackButton(true);
    }
    else {
      editIsOpenOnVouchers(false);
    }
  }

  const handleBackClick = () => {
    setIsVoucherView(false);
    setIsVoucherList(true);
    editIsOpenOnVouchers(false);
  }

  return (

    <>
      <div className={isVoucherList ? "active" : "not-active"}>
        <VoucherList changeToEdit={switchToEdit} dataForEdit={getSelectedVoucher} />
      </div>
      <div className={isVoucherView ? "active" : "not-active"}>
        <div>
          <div className={styles.btnBoxSub}>
            <div className={showBackButton ? "active" : "not-active"}>
              <Tooltip title="Back To Voucher List">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={handleBackClick}><KeyboardBackspaceIcon /></Button>
              </Tooltip>
            </div>
          </div>
          <VoucherDetails data={data} />
        </div>
      </div>
    </>


  )
};

export default Vouchers;