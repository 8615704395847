import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";
import { RootState } from "../../../app/store";
import { GetCurrentUserPermissions } from "../../CompanyUserManagement/userManagementApi";

export const fetchCurrentUserPermissions = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response = await GetCurrentUserPermissions();
  dispatch(userSlice.actions.setAllowedPermissions(response))
};

export default fetchCurrentUserPermissions;