import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styles from "./SearchHistory.module.scss";
import { SearchHistoryModel } from "../../http/Redux/Models/SearchHistoryModel";



const SearchHistory = ({ history }: { history: SearchHistoryModel[] }) => {
  const [rows, setRows] = useState<Array<SearchHistoryModel>>([]);

  useEffect(() => {
    setRows(history);
  }, [history.length]);


  const columns: GridColDef[] = [
    { field: "searchTerm", headerName: "Term", minWidth: 150, flex: 1 },
    { field: "searchDate", headerName: "Date", minWidth: 150, flex: 1 },
    { field: "searchResult", headerName: "Result", minWidth: 150, flex: 1 },
  ];

  return (
    <div className={styles.mydataHeaderBox}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <Box sx={{ width: "100%" }}>
            <h1>Search History</h1>
            <br />
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SearchHistory;