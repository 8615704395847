import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { downloadFilePostWithBodyAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { ConnectionModel } from "../Connect/Models/ConnectionModel";
import { ConnectionRuleModel } from "./Models/ConnectionRuleModel";
import { NewConnectionDto } from "./Dtos/NewConnectionDto";
import { CompanyNetworkConnections } from "./Models/CompanyNetworkConnections";
import { DeleteFollowDto } from "./Dtos/DeleteFollowDto";
import { ConnectionSearchModel } from "./Models/ConnectionSearchModel";
import { SearchModel } from "./Models/SearchModel";
import NetconnSearchType, { netconnSearchTypeToString } from "../../util/enums/NetconnSearchType";
import { CompanyNetworkSearchResults } from "./Models/CompanyNetworkSearchResults";
import { DeleteConnectionDto } from "./Dtos/DeleteConnectionDto";
import { BuyerConnectionCompletedMessage } from "./Models/BuyerConnectionCompletedMessage";
import ConnectionType from "../../util/enums/ConnectionType";

const apiScopes = protectedResources.netconnWebApi.scopes;
const baseUrl = protectedResources.netconnWebApi.endpoint;

/*
 *  Retrieves all connections for a company ID (connections are other companies that have no connection to the passed company ID).
 */
export async function GetPotentialCompanyConnections(
  pageNumber: number,
  pageSize: number,
  companyId: number,
  searchQuery: string | undefined | null) {
  // eslint-disable-next-line max-len
  const url = `${baseUrl}/api/netconn/Connect/GetCompanyPotentialConnections?pageNumber=${pageNumber}&pageSize=${pageSize}&companyId=${companyId}&searchQuery=${searchQuery}`;
  return (await makeGetRequestAsync<ConnectionModel[]>(apiScopes, url)).data;
}

export async function GetPotentialCompanyConnectionsSearch(searchText: string, companyId: number) {
  const url = `${baseUrl}/api/netconn/Connect/GetConnectionSearch/${searchText}/${companyId}`;
  return (await makeGetRequestAsync<ConnectionModel[]>(apiScopes, url)).data;
}

/*
 *  Retrieves companies networked/connected to the company with the passed company ID.
 */
export async function GetCompanyNetwork(companyId: number) {
  const url = `${baseUrl}/api/netconn/Network/GetCompanyNetworkConnections/${companyId}`;
  return (await makeGetRequestAsync<CompanyNetworkConnections>(apiScopes, url)).data;
}

export async function GetRuleEvaluation(evaluatingCompanyId: number, evaluatedCompanyId: number) {
  const url = `${baseUrl}/api/netconn/ConnectionRule/GetCompanyConnectionRuleEval/${evaluatingCompanyId}/${evaluatedCompanyId}`;
  return (await makeGetRequestAsync<ConnectionRuleModel>(apiScopes, url)).data;
}

export async function AddNewConnection(connectionDto: NewConnectionDto) {
  const url = `${baseUrl}/api/netconn/Connect/AddNewConnection`;
  return (await makePostRequestAsync<void, NewConnectionDto>(apiScopes, url, connectionDto)).status;
}

export async function DeleteConnection(deleteConnectionData: DeleteConnectionDto) {
  const url = `${baseUrl}/api/netconn/Connect/DeleteConnection`;
  const result = await makePostRequestAsync(apiScopes, url, deleteConnectionData);
  return result;
}

export async function DeleteFollowConnection(deleteFollowDto: DeleteFollowDto) {
  const url = `${baseUrl}/api/netconn/Connect/DeleteFollowConnection`;
  return (await makePostRequestAsync(apiScopes, url, deleteFollowDto)).status;
}

export async function PerformSearch(searchQueryModel: ConnectionSearchModel) {
  const url = `${baseUrl}/api/netconn/Connect/Search`;
  return (await makePostRequestAsync<Array<SearchModel>, ConnectionSearchModel>(apiScopes, url, searchQueryModel)).data;
}

export async function PerformNetworkSearch(searchQueryModel: ConnectionSearchModel, netconnSearchType: NetconnSearchType) {
  const url = `${baseUrl}/api/netconn/Network/NetworkSearch/${netconnSearchType}`;
  return (await makePostRequestAsync<CompanyNetworkSearchResults, ConnectionSearchModel>(apiScopes, url, searchQueryModel)).data;
}

export async function DownloadSearchResultsExportAsync(searchQueryModel: ConnectionSearchModel, netconnSearchType: NetconnSearchType) {
  const downloadUrl = `${baseUrl}/api/netconn/Network/Export/${netconnSearchType}`;
  await downloadFilePostWithBodyAsync(
    apiScopes,
    downloadUrl,
    `my_${netconnSearchTypeToString(netconnSearchType)}_list_download.xlsx`,
    searchQueryModel);
}

export async function SendBuyerConnectionCompleteEmail(buyerConnCompletedMessage: BuyerConnectionCompletedMessage) {
  const url = `${baseUrl}/api/netconn/ConnectionRequest/SendBuyerConnectionCompletionEmail`;
  return (await makePostRequestAsync(apiScopes, url, buyerConnCompletedMessage)).status;
}

export async function CheckIfConnectionExists(fromCompanyId: number, toCompanyId: number, connectionTypeId: ConnectionType) {
  const url = `${baseUrl}/api/netconn/Connect/DoesConnectionExist/${fromCompanyId}/${toCompanyId}/${connectionTypeId}`;
  const result = await makeGetRequestAsync(apiScopes, url);

  return result;
}

export async function IsConnectedToCompany(companyId: number) {
  const url = `${baseUrl}/api/netconn/Connect/IsConnectedToCompany/${companyId}`;
  const result = await makeGetRequestAsync<boolean>(apiScopes, url);

  return result.data;
}

export async function GetLatestConnectionRequestId(fromCompanyId: number, toCompanyId: number) {
  const url = `${baseUrl}/api/netconn/ConnectionRequest/GetLatestConnectionRequestId/${fromCompanyId}/${toCompanyId}`;
  const result = await (await makeGetRequestAsync<number>(apiScopes, url)).data;
  return result;
}