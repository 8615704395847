import { ThunkAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { RootState } from "../../../app/store";
import { GetCurrentCompanyProfileCompletionSummary } from "../../Company/company"
import { setCompanyProfileCompletionSummary } from "./baseCompanyInfoSlice";

// eslint-disable-next-line import/prefer-default-export
export const fetchCurrentCompanyProfileCompletionSummary = (): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch) => {
    const response = await GetCurrentCompanyProfileCompletionSummary();
    dispatch(setCompanyProfileCompletionSummary(response));
  };
