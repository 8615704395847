// eslint-disable-next-line no-shadow,
export enum ComplianceVerificationTypeEnum {
  None = 0, // eslint-disable-line no-unused-vars
  CIPC = 1, // eslint-disable-line no-unused-vars
  PropertyInformation = 2,// eslint-disable-line no-unused-vars
  NonPreferredListing = 3,// eslint-disable-line no-unused-vars
  SARS = 4,// eslint-disable-line no-unused-vars
  SAFPS = 6,// eslint-disable-line no-unused-vars
  Persal = 11,// eslint-disable-line no-unused-vars
  BEE = 12,// eslint-disable-line no-unused-vars
}

export const ComplianceVerificationTypeToString = (value: ComplianceVerificationTypeEnum) => {
  switch (value) {
    case ComplianceVerificationTypeEnum.None:
      return "N/A";
    case ComplianceVerificationTypeEnum.CIPC:
      return "CIPC";
    case ComplianceVerificationTypeEnum.PropertyInformation:
      return "Property Information";
    case ComplianceVerificationTypeEnum.NonPreferredListing:
      return "Non-Preferred Listing";
    case ComplianceVerificationTypeEnum.SARS:
      return "SARS";
    case ComplianceVerificationTypeEnum.SAFPS:
      return "SAFPS";
    case ComplianceVerificationTypeEnum.Persal:
      return "Persal";
    case ComplianceVerificationTypeEnum.BEE:
      return "BEE";
    default:
      return "Unknown";
  }
}