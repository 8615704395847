import { useState } from "react";
import { Button, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import Swal from "sweetalert2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BaseCompanyInfo } from "../../http/Company/Models/BaseCompanyInfo";
import { GetSomeBasicCompanyInfoAsync } from "../../http/Company/company";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import CompanyVerificationService from "../../http/CompanyVerificationService";
import { PartnerManagedCompaniesModel } from "../../http/Redux/Models/PartnerManagedCompaniesModel";
import validateDayJsDate from "../../util/functions/dateValidator";

const AddClient = ({ back, partner }: { back: () => void, partner: number }) => {
  const [allCompanies, setAllCompanies] = useState<BaseCompanyInfo[]>([] as BaseCompanyInfo[]);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [reportingPeriodDate, setReportingPeriodDate] = useState<Dayjs>(dayjs(""));
  const [totalSuppliers, setTotalSuppliers] = useState(0);
  const [selectedSuppliers, setSelectedSuppliers] = useState(0);


  const handleSearchCompanies = (value: string) => {
    if (value === "") {
      setAllCompanies([]);
    } else {
      const loader = async () => {
        setBackdropLoading(true);
        const list = (
          await GetSomeBasicCompanyInfoAsync(value).finally(() => {
            setBackdropLoading(false);
          })
        ).data;
        setAllCompanies(list);
      };

      loader();
    }
  };

  const handleAddClientDB = () => {
    const loader = async () => {
      const ClientPartner = {} as PartnerManagedCompaniesModel;
      ClientPartner.clientCompanyId = isSelected;
      ClientPartner.managingPartnerCompanyId = partner;
      ClientPartner.suppliers = selectedSuppliers;
      ClientPartner.isActive = true;
      ClientPartner.stagedClient = false;
      ClientPartner.reportingPeriod = reportingPeriodDate.toDate();
      try {
        if (isSelected === 0) {
          Swal.fire(
            "Validation Error",
            "Please search for and select a valid client company",
            "error"
          );
          return;
        }

        const validDate =
          reportingPeriodDate && reportingPeriodDate.isValid()
            ? validateDayJsDate(reportingPeriodDate.toISOString().split("T")[0], "YYYY-MM-DD")
            : false;

        if (!validDate) {
          Swal.fire("Validation Error", "Please select a valid Reporting Period Date", "error");
          return;
        }

        ClientPartner.reportingPeriod = reportingPeriodDate.toDate();

        setBackdropLoading(true);
        await CompanyVerificationService.addDataManagementClient(ClientPartner);

        toast.success("Data Management Client data saved successfully!");
        back();
      } catch (error) {
        toast.error(
          "An error occurred saving the Data Management Client. Please try again, or contact support"
        );
        setBackdropLoading(false);
      } finally {
        setBackdropLoading(false);
      }
    };

    loader();
  };


  const getCompanyIdForClient = (id: number) => {

    const loader = async () => {
      setBackdropLoading(true);
      const total = await CompanyVerificationService.getTotalSuppliers(id).finally(() => {
        setBackdropLoading(false);
      });
      setTotalSuppliers(total);
      setIsSelected(id);
    };

    loader();
  };

  return (
    <Grid container sx={{ height: "500px", width: "50%" }}>
      <LoadingBackdrop showBackdrop={backdropLoading} />
      <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
        <Tooltip title="Back To Users">
          <IconButton onClick={back}><ArrowBackIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid xs={11}>
        <TextField
          sx={{ width: "100%" }}
          size="small"
          placeholder="Search For Company..."
          onChange={(event) => setSearchTerm(event.target.value)} />
      </Grid>
      <Grid xs={1}>
        <Button variant="contained" sx={{ marginLeft: 1, height: "40px" }} onClick={() => handleSearchCompanies(searchTerm)}><SearchIcon /></Button>
      </Grid>
      <Grid xs={12} sx={{ height: "300px" }}>
        {allCompanies.length !== 0 && (
          <div>
            <Typography>Select a company from the list below: </Typography>
            {allCompanies.map((company) => (
              <Button key={company.companyId}
                onClick={() => getCompanyIdForClient(company.companyId)}
                variant={isSelected === company.companyId ? "contained" : "outlined"}
                sx={{ marginRight: 2, marginTop: 2 }}>
                {company.companyRegisteredName} {company.countryId}
              </Button>
            ))}
          </div>
        )}
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ marginBottom: 1 }}>Total Suppliers :{totalSuppliers}</Typography>
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(event) => setSelectedSuppliers(Number(event.target.value) as number)}
          sx={{ width: "98%" }}
          size="small"
          id="outlined-basic"
          label="Managed (SLA Suppliers)"
          variant="outlined"
          type="number"
        />
      </Grid>
      <Grid xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Reporting Period"
            value={reportingPeriodDate}
            onChange={(ev) => {
              setReportingPeriodDate(ev || dayjs(""));
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: "100%" }} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} sx={{ textAlign: "right" }}>
        <Divider sx={{ marginBottom: 2 }} />
        <Button variant="contained" disabled={reportingPeriodDate.toString() === "Invalid Date" || selectedSuppliers === 0 || isSelected === 0} onClick={handleAddClientDB}>Add Client</Button>
      </Grid>
    </Grid>
  )
}

export default AddClient;