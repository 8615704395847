/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Card, CardHeader, CardContent, Box, Typography, Button, IconButton, Tooltip, CardActionArea } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridSelectionModel } from "@mui/x-data-grid";
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useEffect, useState } from "react";
import { SubscriptionPlanModel } from "../../http/Redux/Models/SubscriptionPlanModel";
import FormDialog from "../Dialogs/FormDialog";
import BillInterval, { BillIntervalToString } from "../../util/enums/BillInterval";
import styles from "./SubscriptionsView.module.scss";
import { CompanySubscriptionMessage } from "../../http/SubscriptionManagement/Models/CompanySubscription";
import PaymentStatusEnum from "../../http/SubscriptionManagement/Enums/PaymentStatusEnum";
import formatDate from "../../util/formatter/formatDate";

const columns: GridColDef[] = [
  { field: "productName", headerName: "Product Name", minWidth: 300 },
  { field: "value", headerName: "Price (R)", type: "number", minWidth: 150 },
];

const SubscriptionPlanCard = (
  {
    plan,
    isSelected,
    currentSubscription,
    selectPlanCallback,
    isAnnualSubscriptions = false,
    onSubmitHandler,
  }:
    {
      plan: SubscriptionPlanModel,
      currentSubscription?: CompanySubscriptionMessage,
      isSelected: boolean,
      // eslint-disable-next-line no-unused-vars
      selectPlanCallback: (selectedAddOns: GridRowId[], selectedPlanId: number, totalCostOfAddOns: number) => void,
      onSubmitHandler: () => void,
      isAnnualSubscriptions?: boolean,
    }) => {
  const [showAddonsDialog, setShowAddonsDialog] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [totalAddOnsCost, setTotalAddOnsCost] = useState<number>(0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const totalCostOfAddOns = (plan.productAddOns
      .filter(x => selectionModel.includes(x.id)))
      .reduce((accumilator, obj) => accumilator + obj.value, 0);
    setTotalAddOnsCost(totalCostOfAddOns);
  }, [selectionModel, plan.productAddOns, show])

  const billIntervalString = isAnnualSubscriptions ? "Annum" : BillIntervalToString(BillInterval.Month);
  const currentSubscriptionValue = currentSubscription?.subscriptionBilling?.value;
  const getButtonText = () => {
    if (currentSubscriptionValue != null && plan.amount > currentSubscriptionValue) return "UPGRADE"
    if (currentSubscriptionValue != null && plan.amount < currentSubscriptionValue) return "DOWNGRADE"
    return "SELECT";
  }

  const renderActionButton = () => {
    if (currentSubscription?.isPendingDowngrade) {
      return (
        <div style={{ width: "100%", padding: "10px", textAlign: "center", color: "white", borderRadius: "4px", backgroundColor: "rgb(67, 92, 210)" }}>
          {currentSubscription?.isPendingDowngrade &&
            <Typography>
              {`Pending downgrade to ${currentSubscription?.newPlanAfterDowngrade?.planCode} on  ${formatDate(currentSubscription?.effectiveCancellationDate ?? "")}`}
            </Typography>
          }
        </div>
      )
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmitHandler}
        style={{ fontSize: 16, padding: 5, marginTop: 10, width: "100%" }}
      >
        {getButtonText()}
      </Button>
    )
  }
  return (
    <>
      {
        showAddonsDialog &&
        <FormDialog
          key={plan.id}
          fullWidth
          maxWidth="sm"
          title="Subscription plan add on(s)"
          subDescription={`Select product(s) to add on to the ${plan.planCode} subscription`}
          handleClose={() => setShowAddonsDialog(false)}
          handleSubmit={() => setShowAddonsDialog(false)}
        >
          <>
            <Box>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={plan.productAddOns}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    if (isSelected) {
                      selectPlanCallback(newSelectionModel, plan.id, totalAddOnsCost);
                      setShow(true);
                    }
                  }}
                  selectionModel={selectionModel}
                />
              </div>
            </Box>

            <Box>
              <Typography variant="body1" gutterBottom>
                Cost of add ons: {`R ${totalAddOnsCost}`}
                <Typography variant="body2" color="textSecondary" component="span">/{billIntervalString} VAT incl</Typography>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Total cost (incl. subscription): {`R ${totalAddOnsCost + plan.amount}`}
                <Typography variant="body2" color="textSecondary" component="span">/{billIntervalString} VAT incl</Typography>
              </Typography>
            </Box>
          </>
        </FormDialog>
      }
      <Grid item xs={12} md={3}>
        <Card
          className="Sub-card"
          variant="outlined"
          style={{
            backgroundColor: isSelected || (plan.id === currentSubscription?.planId) ? "rgb(245, 250, 255)" : undefined,
            borderColor: isSelected || (plan.id === currentSubscription?.planId) ? "#435cd2" : undefined,
          }}
          onClick={() => { selectPlanCallback(selectionModel, plan.id, totalAddOnsCost); }}
        >
          <CardActionArea>
            <CardHeader
              style={{
                display: "flex",
                backgroundColor: "#435cd2",
                color: "#fff",
                textShadow: "0px 0px 1px black",
                fontWeight: 900,
                alignItems: "center",
              }}
              title={<span>{plan.planCode}
                <Tooltip title={plan.description ?? "n/a"}>
                  <IconButton>
                    <HelpIcon className="cl-white" />
                  </IconButton>
                </Tooltip>

              </span>} className="heading-sub-3" />
            <CardContent>
              <Box px={1}>
                {(plan.id === currentSubscription?.planId) &&
                  <Typography>
                    <b>{
                      `Current Plan ${currentSubscription.subscriptionBilling?.paymentStatus === PaymentStatusEnum.Pending ?
                        " (pending payment)" : ""}`
                    }</b>
                  </Typography>
                }
                <Typography variant="h5" component="h2" gutterBottom>
                  {`R ${plan.amount + totalAddOnsCost}`}
                  <Typography variant="h6" color="textSecondary" component="span">/{billIntervalString} VAT incl</Typography>
                </Typography>
                {
                  plan.features.map((feature) => (
                    <table className={styles.tableSubSection}>
                      <tr>
                        <th><TaskAltIcon fontSize="small" className="cl-green" /></th>
                        <td>{feature.featureName}</td>
                      </tr>
                    </table>
                  ))
                }
                {
                  plan.productAddOns.length > 0 &&

                  <Button
                    variant="contained"
                    color="primary"
                    hidden
                    style={{ fontSize: 12, padding: 5, margin: 5, display: "None" }}
                    onClick={() => setShowAddonsDialog(true)}>
                    <AddIcon style={{ fontSize: 12 }} />
                    SELECT ADD-ONS
                  </Button>
                }
                {isSelected &&
                  (
                    currentSubscription == null ||
                    currentSubscription?.planId !== plan.id
                  ) &&
                  (
                    renderActionButton()
                  )
                }

              </Box>
              <br />
              <br />
              {selectionModel.length > 0 &&

                <Box px={1}>
                  <Typography variant="h6" color="textSecondary" component="span">Add on products:</Typography>
                  {
                    plan.productAddOns
                      .filter(x => selectionModel.includes(x.id))
                      .map(x => (
                        <Typography style={{ display: "flex", alignItems: "center", flexWrap: "wrap", fontSize: 12 }}
                          color="textSecondary"
                          variant="inherit"
                          component="p">
                          <TaskAltIcon fontSize="small" /> {x.productName}
                        </Typography>
                      ))
                  }
                </Box>

              }

            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  )
}

export default SubscriptionPlanCard;