import Swal from "sweetalert2";

const timeVal = `${process.env.REACT_APP_TIMER_VAL}`;

const showLoadingSwal = (titleText?: string | undefined) => {
  Swal.fire({
    title: titleText || "Loading",
    showConfirmButton: true,
    allowEnterKey: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
  Swal.showLoading(confirmButton);
};

const showSuccessSwal = (titleText?: string | undefined) => {
  Swal.close(); // Close any loading/open dialogs.

  Swal.fire({
    title: titleText || "Operation completed successfully",
    icon: "success",
    timer: Number(timeVal),
    showCancelButton: false,
    showConfirmButton: false,
  });
}

const showErrorSwal = (titleText: string, messageText: string) => {
  Swal.close(); // Close any open modals.
  Swal.fire({
    title: titleText,
    text: messageText,
    icon: "error",
    allowEnterKey: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};

export {showSuccessSwal, showLoadingSwal, showErrorSwal}
