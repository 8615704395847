// import axios, {AxiosRequestConfig } from "axios";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { CountryModel } from "./Models/CountryModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves all countries
 */
export async function GetAllCountries() {
  const url = `${baseUrl}/api/Company/Country/Countries`;
  return makeGetRequestAsync<Array<CountryModel>>(apiScopes, url);
}

/*
 *  Retrieves a specific country based on a passed country ID
 */
export async function GetCountryById(countryId: string) {
  const url = `${baseUrl}/api/Company/Country/${countryId}`;
  return makeGetRequestAsync<CountryModel>(apiScopes, url);
}
