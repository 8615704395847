import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { CompanyBeeGroupModel } from "../../Company/Models/CompanyBeeGroupModel";
import companyBeeGroupSlice from "./CompanyBeeGroupSlice";
import { GetCompanyBeeGroup } from "../../Company/company";


export const viewCompanyBeeGroupActions = companyBeeGroupSlice.actions



export const fetchCompanyBeeGrouping = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: CompanyBeeGroupModel[] = await (await GetCompanyBeeGroup(companyId) as CompanyBeeGroupModel[]);
  dispatch(viewCompanyBeeGroupActions.setCompanyBeeGroup(response))

}


