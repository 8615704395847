/*
 * This "manager" will select the required environment scopes based on an environment variable value.
 * It's currently cheap and dirty in that it's a basic if-else instead of something switchy, etc.
 */

const getEnvironmentScopes = () => ({
  apiRoles: {
    // endpoint: "http://localhost:5000/getRoles",
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SPA_TEST_READ_SCOPE}`,
    ],
  },
  auditWebApi: {
    endpoint: `${process.env.REACT_APP_AUDIT_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  onboardingWebApi: {
    endpoint: `${process.env.REACT_APP_ONBOARDING_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  companyApi: {
    endpoint: `${process.env.REACT_APP_COMPANY_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  complianceWebApi: {
    endpoint: `${process.env.REACT_APP_COMPLIANCE_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  companyVerificationWebApi: {
    endpoint: `${process.env.REACT_APP_VERIFICATION_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  netconnWebApi: {
    endpoint: `${process.env.REACT_APP_NETCONN_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  notificationsWebApi: {
    endpoint: `${process.env.REACT_APP_SIGNALR_NOTIFICATIONS_APPLICATION_URL}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  cleanWebApi: {
    endpoint: `${process.env.REACT_APP_CLEAN_URL}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  userManagementWebApi: {
    endpoint: `${process.env.REACT_APP_USER_MANAGEMENT_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  documentManagementWebApi: {
    endpoint: `${process.env.REACT_APP_DOCMAN_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  configurationWebApi: {
    endpoint: `${process.env.REACT_APP_CONFIGURATION_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  subscriptionWebApi: {
    endpoint: `${process.env.REACT_APP_SUBSCRIPTIONS_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  billingWebApi: {
    endpoint: `${process.env.REACT_APP_BILLING_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
  mailerWebApi: {
    endpoint: `${process.env.REACT_APP_MAILER_WEBAPI_ENDPOINT}`,
    scopes: [
      `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_POLICY_URL}/${process.env.REACT_APP_SIGNEDIN_ACCESS_SCOPE}`,
    ],
  },
});

export default getEnvironmentScopes;