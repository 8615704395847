import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UserTourActivityArrayModel, UserTourActivityModel } from "../Models/UserTourActivityModel";
import { PackageMatrixArrayModel, PackageMatrixModel } from "../Models/PackageMatrixModel";

const initialUserTourActivityState: UserTourActivityArrayModel = {
  allUserTourActivity: [],
  particularUserTourActivity: {
    id: 0,
    accountId: "",
    isTourCanceled: false,
    tourPosition: 0,
    status: 0,
    dateCreated: new Date(),
    dateModified: new Date(),
  },
}


export const userTourActivitySlice = createSlice({
  name: "userTourActivity",
  initialState: initialUserTourActivityState,
  reducers: {
    setUserTourActivity(state, action: PayloadAction<UserTourActivityModel[]>) {
      state.allUserTourActivity = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularUserTourActivity(state, action: PayloadAction<UserTourActivityModel>) {
      state.particularUserTourActivity = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


export const getUserTourActivity = (state: RootState) => state.userTourActivity.allUserTourActivity;



const initialPackageMatrixState: PackageMatrixArrayModel = {
  allPackageMatrices: [],
}


export const packageMatrixSlice = createSlice({
  name: "packageMatrix",
  initialState: initialPackageMatrixState,
  reducers: {
    setPackageMatrices(state, action: PayloadAction<PackageMatrixModel[]>) {
      state.allPackageMatrices = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


export const getPackageMatrix = (state: RootState) => state.packageMatrix.allPackageMatrices;
