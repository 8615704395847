import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetCompanyRegistrationStatus } from "../../../http/Company/CompanyRegistration";
import { CompanyRegistrationMessage } from "../../../http/Company/Models/companyRegistrationMessage";

const useCompanyRegistrationInfo = () => {
  const query = useQuery<CompanyRegistrationMessage>({ queryKey: [queryKeys.companyRegistrationInfo], 
    queryFn: GetCompanyRegistrationStatus,
  })

  return query;
}

export default useCompanyRegistrationInfo;