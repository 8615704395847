// import axios, {AxiosRequestConfig } from "axios";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { CompanyTypeModel } from "./Models/CompanyTypeModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves all company types
 */
export async function GetAllCompanyTypes() {
  const url = `${baseUrl}/api/Company/CompanyType/CompanyTypes`;
  return makeGetRequestAsync<Array<CompanyTypeModel>>(apiScopes, url);
}

/*
 *  Retrieves a specific company type based on a passed company type ID
 */
export async function GetCompanyTypeById(companyTypeId: string) {
  const url = `${baseUrl}/api/Company/CompanyType?companyTypeId=${companyTypeId}`;
  return makeGetRequestAsync<CompanyTypeModel>(apiScopes, url);
}
