/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface SearchBarTextState {
  searchBarText: string;
}

const SearchBarSlice = createSlice({
  name: "searchBarText",
  initialState: "" as string,
  reducers: {
    setSearchBarState: (
      state,
      action: PayloadAction<string>
    ) => action.payload,
    clearSearchBarState: (
    ) => "",
  },
});

export const {
  setSearchBarState,
  clearSearchBarState,
} = SearchBarSlice.actions;

export const getSearchBarText = (state: RootState) => state.searchBarText;
export default SearchBarSlice;
