import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ValidationModel, ValidationArrayModel } from "../../Configuration/Validation/ValidationModel";

const initialValidationState: ValidationArrayModel = {
  allValidations: [],
  particularValidation: {
    id: 0,
    name: "",
    fieldName: "",
    fieldId: 0,
    pattern: "",
    minLength: 0,
    maxLength: 0,
    mask: "",
    defaultMessage: "",
    isActive: false,
    dateCreated: new Date(),
    dateModified: new Date(),
    createdBy: "",
    modifiedBy: "",
  },
}

const ValidationSlice = createSlice({
  name: "validation",
  initialState: initialValidationState,
  reducers: {
    setValidation(state, action: PayloadAction<ValidationModel[]>) {
      state.allValidations = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularValidation(state, action: PayloadAction<ValidationModel>) {
      state.particularValidation = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export default ValidationSlice;


export const getFieldValidations = (state: RootState) => state.validation.allValidations;