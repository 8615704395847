import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePostRequestAsync, makePUTRequestAsync } from "../Helpers/httpRequestHelpers";
import { CompanyMetadataModel } from "./Models/CompanyMetadataModel";
import { CompanyRegistrationMessage } from "./Models/companyRegistrationMessage";
import { CompanyRegistrationModel } from "./Models/CompanyRegistrationModel";
import { CompanyRegistrationStatusModel } from "./Models/CompanyRegistrationStatusModel";
import { UpsertCompanyRegistrationMessage } from "./Models/upsertCompanyRegistrationMessage";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 * Fetches a company's registration status using a CompanyMetadataModel object
 * (Company registration number, company type ID, and company country ID)
 */
export const CheckCompanyRegistrationByMetadata = async (
  companyMetadata: CompanyMetadataModel
) => {
  const url = `${baseUrl}/api/Company/CompanyRegistration/CheckRegStatusByMetaData`;
  const result = await makePostRequestAsync<
    CompanyRegistrationStatusModel,
    CompanyMetadataModel
  >(apiScopes, url, companyMetadata);
  return result.data;
};

/*
 * Fetches a company's registration status using a company ID
 */
export const CheckCompanyRegistrationByCompanyId = async (
  companyId: number
) => {
  const url = `${baseUrl}/api/Company/CompanyRegistration/CheckRegStatusByCompanyId`;
  const result = await makePostRequestAsync<
    CompanyRegistrationStatusModel,
    number
  >(apiScopes, url, companyId);
  return result.data;
};

/*
 * Creates a new company record using the passed company registration model
 * Returns a company ID
 */
export const CreateNewCompanyRecord = async (
  compRegData: CompanyRegistrationModel
) => {
  const url = `${baseUrl}/api/Company/CompanyRegistration/CreateNewCompanyRecord`;
  const result = await makePostRequestAsync<number, CompanyRegistrationModel>(
    apiScopes,
    url,
    compRegData
  );
  return result.data;
};

/*
 * Claims a company record using the passed company registration model
 * Returns a company ID
 */
export const ClaimExistingCompany = async (
  compRegData: CompanyRegistrationModel
) => {
  const url = `${baseUrl}/api/Company/CompanyRegistration/ClaimExistingCompany`;
  const result = await makePostRequestAsync<number, CompanyRegistrationModel>(
    apiScopes,
    url,
    compRegData
  );
  return result.data;
};

/*
 * Retrieves the company registration status
 */
export const GetCompanyRegistrationStatus = async () => {
  const url = `${baseUrl}/api/Company/CompanyRegistration`;
  const result = await makeGetRequestAsync<CompanyRegistrationMessage>(
    apiScopes,
    url
  );
  return result.data;
};

/*
 * Update or create the company registration status
 */
export const CreateOrUpdateCompanyRegistrationStatus = async (message: UpsertCompanyRegistrationMessage) => {
  const url = `${baseUrl}/api/Company/CompanyRegistration`;
  const result = await makePUTRequestAsync<void,UpsertCompanyRegistrationMessage>(
    apiScopes,
    url,
    message
  );
  return result.data;
};