import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { getCompanyDocumentsWithTypesAsync } from "../../../http/DocumentManagement/DocumentManagement";

const useCompanyDocuments = (companyId: number, isConnected?: boolean) => {
  const query = useQuery({
    queryKey: [queryKeys.companyDocuments, companyId],
    queryFn: async () => (await getCompanyDocumentsWithTypesAsync(companyId)).data,
    enabled: isConnected && !!companyId,
  })

  return query;
}

export default useCompanyDocuments;