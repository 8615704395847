import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CustomizedSteppers from "../Wizards/CustomizedSteppers";
import { colorNotificationFailed, pxToRem, registrationPageGrey } from "../../../util/MUI_Theme/Theme";
import BlurredBox from "./BlurredContainer";

interface RegistrationSteps {
  title: string,
}

const RegistrationBannerSection = ({
  activeStep,
  steps,
  errorMessages,
}: {
  activeStep: number,
  steps: RegistrationSteps[],
  errorMessages?: string[] | undefined, // eslint-disable-line react/require-default-props
}) => {
  const minHeight = 350 + (40 * (errorMessages?.length ?? 0))
  
  const hasErrors = errorMessages?.length != null && errorMessages?.length > 0
  return(
    <Box
      minHeight={pxToRem(minHeight)}
      sx={{
        display: "flex", flexWrap: "wrap",
        justifyContent: "flex-start",
        my: 10, px: 5, py: 10,
        backgroundColor: registrationPageGrey,
      }}>

      <BlurredBox blurContent={hasErrors}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={11}>
            <Box pb={3}>
              <Typography variant="largeHeading" pb={2}>
              YOUR ACCOUNT HAS BEEN CREATED
              </Typography>
              <Box my={2} />
              <Typography variant="subHeading">
              We need a little bit more information about your business to ensure that we understand what your company has to offer.
              </Typography>
              <Box my={1} />
              <Typography variant="subHeading">
              Please fill in the required fields below. All fields are <span style={{ color: "red" }}>*</span> mandatory unless stated otherwise.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </BlurredBox>
      {hasErrors && (
        <Card
          variant="outlined"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            top: `${window.innerHeight * 0.15}px`,
            borderRadius: "5px",
            margin: "20px",
            minWidth: "60%",
            zIndex: 3,
            backgroundColor: colorNotificationFailed,
            color: "#FFFFFF",
          }}
        >
          <CardContent>
            <Box p={2}>
              <Typography variant="subHeading" fontWeight={700}>
                This page contains missing information. To continue please amend/complete the following:
              </Typography>
              {errorMessages.map((message, index) => (
                <Box key={index}>
                  <Typography variant="subHeading">- {message}</Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={11} lg={10}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
          >
            <Grid item>
              <CustomizedSteppers steps={steps.map(x => ({ ...x }))} activeStep={activeStep} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )}
export default RegistrationBannerSection;
