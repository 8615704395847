import React, { useCallback, useEffect, useState } from "react";
import { Box, Checkbox, MenuItem, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEditSingleSelectCellProps,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import { CompanyBusinessLocationModel } from "../../../../../http/Company/Models/CompanyBusinessLocationModel";
import { CountryModel } from "../../../../../http/Company/Models/CountryModel";
import { ProvinceModel } from "../../../../../http/Company/Models/ProvinceModel";
import { GetCompanyBusinessLocations } from "../../../../../http/Company/CompanyBusinessLocations";
import EditToolbar from "./BusinessLocationEditToolbar";

interface BusinessLocationTableProps {
  companyId: number;

  // eslint-disable-next-line react/no-unused-prop-types
  staged: boolean;

  // eslint-disable-next-line react/no-unused-prop-types
  countryList: Array<CountryModel>;

  // eslint-disable-next-line react/no-unused-prop-types
  provinceList: Array<ProvinceModel>;

  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  handleAddCallback: (location: CompanyBusinessLocationModel) => void;

  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  handleEditCallback: (location: CompanyBusinessLocationModel) => void;

  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  handleDeleteCallback: (companyLocationId: number) => void;
}

const BusinessLocationTable = (props: BusinessLocationTableProps) => {
  const apiRef = useGridApiRef();

  const [rows, setRows] = React.useState<Array<CompanyBusinessLocationModel>>(
    [] as Array<CompanyBusinessLocationModel>
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [, setPrimaryLocation] = useState<boolean>(false);

  const [editPrimaryLocation, setEditPrimaryLocation] = useState<boolean>(false);

  // Used for enabling/disabling the Add Record button.
  const [edit, setEdit] = useState<boolean>(false);

  const getRowWithPrimaryLocation = (rowsToCheck: CompanyBusinessLocationModel[]) => {
    const rowsWithPrimaryLocation = rowsToCheck.filter((row) => row.primaryLocation);

    return rowsWithPrimaryLocation[0] || undefined;
  };

  const getUniqueBusinessLocationIdFromRow = (
    row: CompanyBusinessLocationModel | GridValidRowModel
  ) => {
    if (row.companyId === 0) return undefined;

    return `${row.companyId}|${row.countryId}|${row.provinceId}`;
  };

  const handleCountryValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string | number) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field: "countryId", value: newValue });
      apiRef.current.setEditCellValue({ id, field: "provinceId", value: 0 });
    },
    [apiRef]
  );

  const handleProvinceValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string | number) => {
      const newValue = event.target.value;

      apiRef.current.setEditCellValue({ id, field: "provinceId", value: newValue });
    },
    [apiRef]
  );

  const handlePrimaryLocationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string | number) => {
      setEditPrimaryLocation(!editPrimaryLocation);
      apiRef.current.setEditCellValue({
        id,
        field: "primaryLocation",
        value: !editPrimaryLocation,
      });
    },
    [apiRef, editPrimaryLocation]
  );

  const renderProvinceDropdown = useCallback(
    (params: GridEditSingleSelectCellProps) => {
      const filteredProvinceList = props.provinceList.filter(
        (x) => x.countryId === params.row.countryId
      );

      if (filteredProvinceList.length === 0) {
        return (
          <TextField select sx={{ width: "100%" }} size="small" value={999999} disabled>
            <MenuItem value={999999}>N/A</MenuItem>
          </TextField>
        );
      }

      return (
        <TextField
          select
          sx={{ width: "100%" }}
          size="small"
          value={params.row.provinceId || 0}
          onChange={(ev) => handleProvinceValueChange(ev, params.id)}
        >
          <MenuItem value={0}>Please Select</MenuItem>
          {filteredProvinceList.map((province) => (
            <MenuItem key={province.id} value={province.id}>
              {province.name}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    [handleProvinceValueChange, props.provinceList]
  );

  //-----------------------------
  // DataGrid Functions Below:
  //-----------------------------
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
    setEdit(false);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStart = () => {
    setEdit(true);
  }

  const validateDuplicates = (rowToCheck: CompanyBusinessLocationModel) => {
    const rowCheck = getUniqueBusinessLocationIdFromRow(rowToCheck);
    const currentRows = rows.filter((row) => !row.isNew).map((row) => getUniqueBusinessLocationIdFromRow(row));

    return currentRows.includes(rowCheck);
  }

  const checkIfValidProvinceSelected = (rowToCheck: CompanyBusinessLocationModel) => {
    const filteredProvinceList = props.provinceList.filter((prov) => prov.countryId === rowToCheck.countryId);

    // No provinces detected in the filtered list for the selected country, so 0 is a valid selection.
    if (filteredProvinceList.length === 0)
      return true;

    // If the province ID is 0 after the above check, the selection is invalid.
    if (rowToCheck.provinceId === 0)
      return false;

    return true;
  }

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const newRowId = apiRef.current.getRowId(newRow);

    const updatedRowModel: CompanyBusinessLocationModel = {
      companyId: props.companyId,
      countryId: newRow.countryId,
      provinceId: newRow.provinceId,
      primaryLocation: newRow.primaryLocation,
      isDeleted: false,
      // datagrid-specific fields below:
      id: newRow.id,
      isNew: newRow.isNew,
    };



    const dupeCheck = validateDuplicates(updatedRowModel);

    if (dupeCheck && newRow.isNew) {
      throw new Error("Duplicate locations are not allowed.");
    }

    if (dupeCheck && !newRow.isNew && (oldRow.primaryLocation === newRow.primaryLocation)) {
      throw new Error("Duplicate locations are not allowed.");
    }

    if (!checkIfValidProvinceSelected(updatedRowModel))
      throw new Error("Please select a province.");

    if (newRow.isNew)
      props.handleAddCallback(updatedRowModel);
    else
      props.handleEditCallback(updatedRowModel);

    updatedRowModel.isNew = false;

    setRows(rows.map((row) => (row.id === newRowId ? updatedRowModel : row)));

    return updatedRowModel;
  };

  const handleProcessRowFail = (error: any) => {
    Swal.fire("Validation Error", error.toString(), "error");
  }

  //--------------------------------------------
  // DataGrid Column Definition/functions below:
  //--------------------------------------------
  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);

    const primaryLocationRow = getRowWithPrimaryLocation(rows);
    if (primaryLocationRow && primaryLocationRow.id === id)
      setEditPrimaryLocation(true);

    handleRowModesModelChange({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "countryId" },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (edit) setEdit(!edit);

    handleRowModesModelChange({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setEditPrimaryLocation(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire(
      "Deletion confirmation",
      "Are you sure you want to delete this row?",
      "question"
    ).then((result) => {
      if (!result.isConfirmed) return;

      const rowToDelete = rows.find((row) => row.id === id) as CompanyBusinessLocationModel;
      setRows(rows.filter((row) => row.id !== id));
      setEditPrimaryLocation(false);

      props.handleDeleteCallback(
        rowToDelete.id
      );
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    if (edit) setEdit(!edit);

    handleRowModesModelChange({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }

    setEditPrimaryLocation(false);
  };

  const handleDisabled = (
    rowParams: GridRenderEditCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    const rowWithPrimary = getRowWithPrimaryLocation(rows);
    const currentRow = rowParams.id;

    if (!rowWithPrimary) return false;

    if (rowWithPrimary.id === currentRow) return false;

    return true;
  };

  const columnDefinition: GridColDef[] = [
    {
      field: "companyId",
      headerName: "Company ID",
    },
    {
      field: "countryId",
      headerName: "Country",
      width: 220,
      type: "singleSelect",
      editable: true,
      renderCell: (params) => (
        <div>{props.countryList.find((country) => country.id === params.row.countryId)?.name}</div>
      ),
      renderEditCell: (params) => (
        <TextField
          select
          sx={{ width: "100%" }}
          size="small"
          value={params.row.countryId || 0}
          onChange={(ev) => handleCountryValueChange(ev, params.id)}
        >
          <MenuItem value={0}>Please Select</MenuItem>
          {props.countryList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: "provinceId",
      editable: true,
      headerName: "Province",
      type: "singleSelect",
      width: 220,
      renderCell: (params) => (
        <div>
          {props.provinceList.find((province) => province.id === params.row.provinceId)?.name}
        </div>
      ),
      renderEditCell: (params) => renderProvinceDropdown(params),
      preProcessEditCellProps: (params) => {
        const filteredProvinceList = props.provinceList.filter(
          (prov) => prov.countryId === params.row.countryId
        );
        if (params.row.provinceId === 0 && filteredProvinceList.length > 0)
          return { ...params.props, error: true };

        return params.props;
      },
    },
    {
      field: "primaryLocation",
      headerName: "Primary Location",
      width: 220,
      editable: true,
      type: "boolean",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const currentPrimaryLocation = getRowWithPrimaryLocation(rows);

        // If the currentPrimaryLocation is not undefined, and the row ID for the row
        // that is set as primary is not the same as the id coming in from the params argument,
        // then evaluate if the user is providing valid data.
        if (currentPrimaryLocation && currentPrimaryLocation.id !== params.id) {
          const hasError = params.props.value; // If the value is true, it's wrong.
          return { ...params.props, error: hasError };
        }

        return params.props;
      },
      renderEditCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Checkbox
            disabled={handleDisabled(params)}
            checked={editPrimaryLocation}
            onChange={(ev) => handlePrimaryLocationChange(ev, params.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
            disabled={edit}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
            disabled={edit}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    const loader = async (companyId: number, staged: boolean) => {
      const results = await (await GetCompanyBusinessLocations(companyId, staged)).data;

      // setRows(resultsUpdatedWithRowIds);
      if (rows.length === 0) {
        const rowsHavePrimaryLocation = getRowWithPrimaryLocation(results);
        if (rowsHavePrimaryLocation) {
          setPrimaryLocation(true);
          setEditPrimaryLocation(false);
        }
        setRows(results);
      }
    };

    loader(props.companyId, props.staged);
  }, [props.companyId, props.staged, rows.length]);

  return (
    <Box
      sx={{
        marginBottom: 2,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .Mui-error": {
          // backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
          color: "text.error",
        },
      }}
    >
      <DataGridPro
        rows={rows}
        apiRef={apiRef}
        columns={columnDefinition}
        initialState={{
          columns: {
            columnVisibilityModel: {
              companyId: false,
            },
          },
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        onRowEditStart={handleRowEditStart}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => handleProcessRowFail(error)}
        getRowId={(row) => row.id}
        onRowDoubleClick={() => null}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, setEdit, editModeEnabled: edit },
        }}
      />
    </Box>
  );
};

export default BusinessLocationTable;

