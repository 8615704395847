/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum BeeRatingSource {
  verificationCertificate = 1,
  selfAssessment = 2,
  auditorAccountingLetter = 3,
  affidavit = 4,
  none = 5,
}

const beeRatingSourceToString = (status: BeeRatingSource): string => {
  switch (status) {
    case BeeRatingSource.verificationCertificate:
      return "Verification certificate";
    case BeeRatingSource.selfAssessment:
      return "Self Assessment";
    case BeeRatingSource.auditorAccountingLetter:
      return "Auditors/Accounting Officers letter";
    case BeeRatingSource.affidavit:
      return "Sworn Affidavit";
    case BeeRatingSource.none:
      return "None";
    default:
      return "Unknown";
  }
};

export {BeeRatingSource, beeRatingSourceToString};