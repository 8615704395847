import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetAllCompanyTypes } from "../../../http/Company/CompanyTypes";

const useCompanyTypes = () => {
  const query = useQuery({
    queryKey: [queryKeys.companyTypes],
    queryFn: async () => (await GetAllCompanyTypes()).data,
    staleTime: 600000, 
  })

  return query;
}

export default useCompanyTypes;