import { Button, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddValidationModel } from "../../http/Configuration/Validation/AddValidationModel";
import { FieldModel } from "../../http/Redux/Models/FieldModel";
import { useAppSelector } from "../../app/hooks";
import { AddValidation } from "../../http/Configuration/Validation/ValidationApi";
import FieldsDropDowns from "./Fields/FieldsDropDown";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { CountryModel } from "../../http/Company/Models/CountryModel";
import useCompanyTypes from "../../util/hooks/queries/useCompanyTypes";


const AddNewFieldValidation = ({ backToView }: { backToView: () => void }) => {
  const [editable, setEditable] = useState<AddValidationModel>({} as AddValidationModel);
  const [isActive, setIsActive] = useState(false);
  const [loading, isLoading] = useState(false);
  const allFields: FieldModel[] = useAppSelector((state: { validationFields: { allFields: FieldModel[]; }; }) => state.validationFields.allFields);

  const countries = useAppSelector(x => x.countryList);
  const { data: companyTypes } = useCompanyTypes();

  const handleSwitchOnClick = () => {
    if (isActive) {
      setIsActive(false);
    }
    else {
      setIsActive(true);
    }
  };
  const chooseField = (id: number) => {
    setEditable({ ...editable, fieldId: id });
  };

  const handleClickSave = (edit: AddValidationModel) => {

    isLoading(true);
    const modifiedValidation: AddValidationModel = edit;
    modifiedValidation.isActive = isActive;

    if (edit.mask === null || edit.mask === "") {
      modifiedValidation.mask = "none";
    }
    if (edit.pattern === null) {
      modifiedValidation.pattern = "";
    }
    const loader = async () => {
      await AddValidation(modifiedValidation).catch(() => {
        toast.error("New validation not added");
      }).finally(() => {
        isLoading(false);
        toast.success("New validation added successfully");
        backToView();
      });

    };
    loader();
  };

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Field Validation Table">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <FieldsDropDowns label="Fields" chooseField={chooseField} allFields={allFields} />
            </Grid>
            <Grid xs={6}>
              <TextField
                value={editable.name || ""}
                onChange={(ev) => { setEditable({ ...editable, name: ev.target.value }); }}
                id="name"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Validation Name"
                size="small"
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <TextField
                value={editable.pattern || ""}
                onChange={(ev) => { setEditable({ ...editable, pattern: ev.target.value }); }}
                id="pattern"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Pattern"
                multiline
                size="small"
                type="text"
                sx={{ width: "98%" }}
                variant="outlined" />
            </Grid>
            <Grid xs={6}>
              <TextField
                value={editable.minLength || ""}
                onChange={(ev) => { setEditable({ ...editable, minLength: parseInt(ev.target.value, 10) }); }}
                id="min-length"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Min Length"
                size="small"
                type="number"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <TextField
                value={editable.maxLength || ""}
                onChange={(ev) => { setEditable({ ...editable, maxLength: parseInt(ev.target.value, 10) }); }}
                sx={{ width: "98%" }}
                id="max-length"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Max Length"
                size="small"
                type="number"
                fullWidth
                variant="outlined" />
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Country"
                select
                value={editable.countryId ?? 0}
                size="small"
                fullWidth
                onChange={(ev) => { setEditable({ ...editable, countryId: parseInt(ev.target.value.toString(), 10) }); }}
              >
                <MenuItem value={0}>Please Select</MenuItem>
                {countries.map((country: CountryModel) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <TextField
                label="Company Type"
                select
                value={editable.companyTypeId ?? 0}
                size="small"
                sx={{ width: "98%" }}
                onChange={(ev) => { setEditable({ ...editable, companyTypeId: parseInt(ev.target.value.toString(), 10) }); }}>
                <MenuItem value={0}>Please Select</MenuItem>
                {companyTypes?.map((ct) => (
                  <MenuItem key={ct.id} value={ct.id}>
                    {ct.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={6}>
              <TextField
                value={editable.mask || ""}
                onChange={(ev) => { setEditable({ ...editable, mask: ev.target.value }); }}
                id="mask"
                label="Mask"
                size="small"
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={10}>
              <TextField
                value={editable.defaultMessage || ""}
                onChange={(ev) => { setEditable({ ...editable, defaultMessage: ev.target.value }); }}
                id="message"
                label="Default Message"
                size="small"
                type="text"
                multiline
                sx={{ width: "98%" }}
                variant="outlined" />
            </Grid>
            <Grid xs={2}>
              <FormControlLabel control={<Checkbox checked={isActive} onClick={handleSwitchOnClick} />} label="Is Active" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" className="form-add-btn" onClick={() => { handleClickSave(editable); }}>Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default AddNewFieldValidation;