/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UserCompanyModel } from "../Models/UserCompanyModel";

export interface UserCompanyListState {
  userCompanyList: Array<UserCompanyModel>;
}

const userCompanyListSlice = createSlice({
  name: "userCompanyList",
  initialState: [] as Array<UserCompanyModel>,
  reducers: {
    setUserCompanyListState: (state, action: PayloadAction<Array<UserCompanyModel>>) => action.payload,
    addUserCompanyToState(state, action: PayloadAction<UserCompanyModel>) {
      state = [...state, action.payload];
    },
    removeUserCompanyFromState(state, action: PayloadAction<number>) {
      state = state.filter((company) => company.companyId !== action.payload);
    },
  },
});

export const selectUserCompanyList = (state: RootState) => state.userCompanyList;
export default userCompanyListSlice;
