/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { AddressModel } from "../../Company/Models/AddressModel";
import { BankAccountDetailsModel } from "../../Company/Models/BankAccountDetailsModel";
import { BeeDetailsModel } from "../../Company/Models/BeeDetailsModel";
import { CompanyViewModel } from "../../Company/Models/CompanyViewModel";
import { ContactModel } from "../../Company/Models/ContactModel";
import { CompanyProfileArrayModel, CompanyProfileModel } from "../Models/CompanyProfileModel";
import { CompanyProfileImageArrayModel, CompanyProfileImageModel } from "../Models/CompanyProfileImageModel";
import { OverallComplianceStatusModel } from "../../Company/Models/OverallComplianceStatusModel";
import { ComplianceDetailsModel } from "../../Company/Models/ComplianceDetailsModel";

export interface CompanyState {
  company: CompanyViewModel;
}

const initialCompanyState = {} as CompanyViewModel;

const initialCompanyProfileState: CompanyProfileArrayModel = {
  particularCompanyProfile: {
    id: 0,
    fileName: "",
    companyId: 0,
  },
}

const initialCompanyProfileImageState: CompanyProfileImageArrayModel = {
  allCompanyProfileImages: [],
  particularCompanyProfileImage: {
    url: "",
  },
}

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {
    resetCompanyState: () => initialCompanyState,
    setCompanyViewModelState: (
      state,
      action: PayloadAction<CompanyViewModel>
    ) => {
      state.companyId = action.payload.companyId;
      state.parentCompanyAccountId = action.payload.parentCompanyAccountId;
      state.holdingCompanyName = action.payload.holdingCompanyName;
      state.registrationNumber = action.payload.registrationNumber;
      state.vatNumber = action.payload.vatNumber;
      state.registeredName = action.payload.registeredName;
      state.tradingName = action.payload.tradingName;
      state.tradeDescription = action.payload.tradeDescription;
      state.companyTypeId = action.payload.companyTypeId;
      state.incomeTaxNumber = action.payload.incomeTaxNumber;
      state.taxClearanceNumber = action.payload.taxClearanceNumber;
      state.taxClearanceNumberExpiryDate = action.payload.taxClearanceNumberExpiryDate;
      state.created = action.payload.created;
      state.modified = action.payload.modified;
      state.createdByUserId = action.payload.createdByUserId;
      state.deletedDate = action.payload.deletedDate;
      state.deletedByUserId = action.payload.deletedByUserId;
      state.isDeleted = action.payload.isDeleted;
      state.countryId = action.payload.countryId;
      state.adminUserEmail = action.payload.adminUserEmail;
      state.adminUserMobile = action.payload.adminUserMobile;
      state.adminUserName = action.payload.adminUserName;
      state.adminUserRole = action.payload.adminUserRole;
      state.adminUserUsername = action.payload.adminUserUsername;
      state.companyAddresses = action.payload.companyAddresses || [] as Array<AddressModel>;
      state.companyBeeDetails = action.payload.companyBeeDetails || {} as BeeDetailsModel;
      state.bankAccounts = action.payload.bankAccounts || [] as Array<BankAccountDetailsModel>;
      state.companyContacts = action.payload.companyContacts || [] as Array<ContactModel>;
      state.companyOverallComplianceStatus = action.payload.companyOverallComplianceStatus || {} as OverallComplianceStatusModel;
      state.complianceDetails = action.payload.complianceDetails || [] as Array<ComplianceDetailsModel>;
      state.selectedUnspscFamilies = action.payload.selectedUnspscFamilies || [] as Array<number>;
    },
    setCompanyBeeDetailsState: (
      state,
      action: PayloadAction<BeeDetailsModel>
    ) => {
      const beeDetails = state.companyBeeDetails;

      if (beeDetails) {
        beeDetails.sectorId = action.payload.sectorId;
        beeDetails.annualTurnover = action.payload.annualTurnover;
        beeDetails.boPercentage = action.payload.boPercentage;
        beeDetails.bwoPercentage = action.payload.bwoPercentage;
        beeDetails.certificateLevelId = action.payload.certificateLevelId;
        beeDetails.certificateExpiryDate = action.payload.certificateExpiryDate;
        beeDetails.isCurrent = action.payload.isCurrent;
        beeDetails.validationStatus = action.payload.validationStatus;
        beeDetails.validationDateTime = action.payload.validationDateTime;
        beeDetails.hasBEECertificate = action.payload.hasBEECertificate;
        beeDetails.certificateDocId = action.payload.certificateDocId;
        beeDetails.ratingSourceId = action.payload.ratingSourceId;
        beeDetails.verificationAgencyId = action.payload.verificationAgencyId;
        beeDetails.vas = action.payload.vas;
        beeDetails.localServiceProvider = action.payload.localServiceProvider;
        beeDetails.localGoodsManufacturer = action.payload.localGoodsManufacturer;
        beeDetails.isBlackOwned = action.payload.isBlackOwned;
        beeDetails.isBlackWomanOwned = action.payload.isBlackWomanOwned;
        beeDetails.isFlowThrough = action.payload.isFlowThrough;
        beeDetails.blackDesignatedGroup = action.payload.blackDesignatedGroup;
        beeDetails.blackYouth = action.payload.blackYouth;
        beeDetails.blackDisabled = action.payload.blackDisabled;
        beeDetails.blackUnemployed = action.payload.blackUnemployed;
        beeDetails.blackRural = action.payload.blackRural;
        beeDetails.blackMilitaryVet = action.payload.blackMilitaryVet;
        beeDetails.farmWorkers = action.payload.farmWorkers;
        return;
      }

      state.companyBeeDetails = action.payload;
    },
    setCompanyContactState: (state, action: PayloadAction<ContactModel>) => {
      const contacts = state.companyContacts;

      if (contacts) {
        const contactToUpdate = contacts.find(
          (c) => c.id === action.payload.id
        );

        if (contactToUpdate) {
          contactToUpdate.name = action.payload.name;
          contactToUpdate.email = action.payload.email;
          contactToUpdate.idNumber = action.payload.idNumber;
          contactToUpdate.landLine = action.payload.landLine;
          contactToUpdate.mobile = action.payload.mobile;
          contactToUpdate.fax = action.payload.fax;
          contactToUpdate.username = action.payload.username;
          contactToUpdate.designation = action.payload.designation;
          contactToUpdate.website = action.payload.website;
          return;
        }

        // state.companyContacts.push(action.payload);
        state.companyContacts = [...state.companyContacts, action.payload];
      }
    },
    removeContactFromState: (state, action: PayloadAction<number>) => {
      const contacts = state.companyContacts;

      if (contacts) {
        state.companyContacts = state.companyContacts.filter((contact) => contact.id !== action.payload);
      }
    },
    removeUnsavedContactsFromState: (state) => {
      const contacts = state.companyContacts;

      if (contacts) {
        state.companyContacts = state.companyContacts.filter((contact) => contact.companyId !== 0);
      }
    },
    setCompanyAddressState: (state, action: PayloadAction<AddressModel>) => {
      const addresses = state.companyAddresses;

      if (addresses) {
        const addressToUpdate = addresses.find(
          (c) => c.addressId === action.payload.addressId
        );

        if (addressToUpdate) {
          addressToUpdate.companyId = action.payload.companyId;
          addressToUpdate.municipalityId = action.payload.municipalityId;
          addressToUpdate.line1 = action.payload.line1;
          addressToUpdate.line2 = action.payload.line2;
          addressToUpdate.suburb = action.payload.suburb;
          addressToUpdate.city = action.payload.city;
          addressToUpdate.countryId = action.payload.countryId;
          addressToUpdate.provinceId = action.payload.provinceId;
          addressToUpdate.postalCode = action.payload.postalCode;
          return;
        }

        state.companyAddresses.push(action.payload);
      }
    },
    setCompanyBankAccountState: (
      state,
      action: PayloadAction<BankAccountDetailsModel>
    ) => {
      const bankAccounts = state.bankAccounts;

      if (bankAccounts) {
        const bankAccountToUpdate = bankAccounts.find(
          (c) => c.id === action.payload.id
        );

        if (bankAccountToUpdate) {
          bankAccountToUpdate.accountName = action.payload.accountName;
          bankAccountToUpdate.accountNumber = action.payload.accountNumber;
          bankAccountToUpdate.accountTypeId = action.payload.accountTypeId;
          bankAccountToUpdate.bankId = action.payload.bankId;
          bankAccountToUpdate.branchCode = action.payload.branchCode;
          bankAccountToUpdate.branchName = action.payload.branchName;
          return;
        }

        state.bankAccounts.push(action.payload);
      }
    },
    removeBankAccFromState: (state, action: PayloadAction<number>) => {
      const bankAccounts = state.bankAccounts;

      if (bankAccounts) {
        state.bankAccounts = state.bankAccounts.filter((account) => account.id !== action.payload);
      }
    },
    removeUnsavedBankAccFromState: (state) => {
      const bankAccounts = state.bankAccounts;

      if (bankAccounts) {
        state.bankAccounts = state.bankAccounts.filter((account) => account.companyId !== 0);
      }
    },
  },
});

export const {
  resetCompanyState,
  setCompanyViewModelState,
  setCompanyBeeDetailsState,
  setCompanyContactState,
  setCompanyAddressState,
  setCompanyBankAccountState,
  removeContactFromState,
  removeBankAccFromState,
  removeUnsavedContactsFromState,
  removeUnsavedBankAccFromState,
} = companySlice.actions;

export const selectCompany = (state: RootState) => state.company;
export const getCurrentCompanyId = (state: RootState) => state.baseCompanyInfo.companyId;
export default companySlice.reducer;

export const companyProfileImageSlice = createSlice({
  name: "companyProfileImage",
  initialState: initialCompanyProfileImageState,
  reducers: {
    setProfileImage(state, action: PayloadAction<CompanyProfileImageModel[]>) {
      state.allCompanyProfileImages = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularProfileImage(state, action: PayloadAction<CompanyProfileImageModel>) {
      state.particularCompanyProfileImage = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const companyProfileSlice = createSlice({
  name: "companyProfile",
  initialState: initialCompanyProfileState,
  reducers: {
    setParticularCompanyProfile(state, action: PayloadAction<CompanyProfileModel>) {
      state.particularCompanyProfile = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const getCompanyProfile = (state: RootState) => state.companyProfile.particularCompanyProfile;

export const getCompanyProfileImage = (state: RootState) => state.companyProfileImage.particularCompanyProfileImage;