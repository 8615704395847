import { useEffect, useState } from "react"

const useInputDebounce = <T,>(value: T, userDefinedDelay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const systemConfiguredDelay = process.env.REACT_APP_COMPANYPROFILE_DEBOUNCE_TIME;

  const delayToUse:number = !userDefinedDelay ? +(systemConfiguredDelay || "500") : userDefinedDelay;

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delayToUse);

      return () => {
        clearTimeout(handler);
      }

    }, [value, delayToUse]
  );

  return debouncedValue;
}

export default useInputDebounce;