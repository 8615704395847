import { Card, CardContent, Box, Rating } from "@mui/material";

const ProfileStatusCard = () => (
  <Box>
    <Card className="cardMain-CompanyProfile" sx={{
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      backdropFilter: "blur(6px)",
      borderRadius: "8px",
    }}
    >
      <CardContent>
        <Box className="cardHeader">PROFILE STATUS</Box>
        <Box p={1}><b>Completion:</b></Box>
        <Box p={1}>
          <strong style={{ float: "left" }}>My Overall Rating:</strong>
          <div style={{ paddingLeft: "5px", paddingTop: "2px", float: "left", paddingBottom: "10px" }} >
            <Rating />
          </div>
        </Box>
      </CardContent>
    </Card>
  </Box >
)

export default ProfileStatusCard;