
import { PermissionModel } from "./Redux/Models/PermissionModel";
import { protectedResources } from "../authConfig";
import makeGetRequestAsync from "../http/Helpers/httpRequestHelpers";

const apiScopes = protectedResources.userManagementWebApi.scopes;
const baseUrl = protectedResources.userManagementWebApi.endpoint;


export default {
  async getAllPermissions() {

    const url = `${baseUrl}/api/UserManagement/Permissions`;
    return (await makeGetRequestAsync<PermissionModel[]>(apiScopes, url)).data;
  },
  async getParticularPermission(id: number) {
    const url = `${baseUrl}/api/UserManagement/Permissions/${id}`;
    return (await makeGetRequestAsync<PermissionModel>(apiScopes, url)).data;
  },
}