/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { Grid, Card, TextField, InputAdornment, SelectChangeEvent } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/FilterList";
import useBeeLevels from "../../../util/hooks/queries/useBeeLevels";
import useProvinces from "../../../util/hooks/queries/useProvinces";
import ButtonGroup from "../../Registration/Sections/ButtonGroup";
import MenuProps, { defaultSearchOrderObject, displayMinimumSearchAlert } from "./ConnectionProps";
import FilterDropdown from "../SearchComponents/FilterDropdown";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { OrderByModel } from "../../../http/NetworkAndConnect/Models/OrderByModel";

const blackOwnershipRanges = [
  { id: 1, value: "0% - 29%" },
  { id: 2, value: "30% - 50%" },
  { id: 3, value: "51% - 99%" },
  { id: 4, value: "100%" },
];

const blackWomenOwnershipRanges = [
  { id: 1, value: "0% - 29%" },
  { id: 2, value: "30% - 50%" },
  { id: 3, value: "51% - 99%" },
  { id: 4, value: "100%" },
];


interface SearchByProps {
  executeSearch: (searchTextValue?: string) => Promise<void>;
}

const ConnectionSearchBy = ({ executeSearch }: SearchByProps) => {
  const { data: provincesList = [] } = useProvinces();
  const { data: beeLevels = [] } = useBeeLevels();
  const [searchText, setSearchText] = useState<string>("");
  const [selectedBlackOwnershipRanges, setSelectedBlackOwnershipRanges] = useState<Array<number>>([] as Array<number>);
  const [selectedBlackWomenOwnershipRanges, setSelectedBlackWomenOwnershipRanges] = useState<Array<number>>([] as Array<number>);
  const [connectionSearchModel, setConnectionSearchModel] = useState<ConnectionSearchModel>({ beeLevelIds: [] as Array<number>, provinceIds: [] as Array<number>, unspscSegmentIds: [] as Array<number>, unspscFamilyIds: [] as Array<number>, bOPercRanges: [] as Array<string>, bWOPercRanges: [] as Array<string>, searchOrderBys: [defaultSearchOrderObject] as Array<OrderByModel> } as ConnectionSearchModel);

  const handleSelectChange = (event: SelectChangeEvent<number[]>, propName: string) => {
    const localCopy = JSON.parse(JSON.stringify(connectionSearchModel));
    const newVal: ConnectionSearchModel = { ...localCopy, [propName]: event.target.value };
    setConnectionSearchModel(newVal);
  };

  const handleBlackOwnershipChange = (event: SelectChangeEvent<number[]>) => {
    const newSelection = event.target.value as Array<number>;
    setSelectedBlackOwnershipRanges([...newSelection]);
  };

  const handleBlackWOwnershipChange = (event: SelectChangeEvent<number[]>) => {
    const newSelection = event.target.value as Array<number>;
    setSelectedBlackWomenOwnershipRanges([...newSelection]);
  };

  const handleCompanyNameSearchTextChange = useCallback((searchTextValue: string) => {
    setSearchText(searchTextValue);
  }, []);

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchText.length < 3) {
        displayMinimumSearchAlert();
        return;
      }

      executeSearch(searchText);
    }
  };

  const clearSearchText = async () => {
    setSearchText("");
    await executeSearch();
  };

  const handleSearch = async () => {
    await executeSearch(searchText);
  };


  return (
    <Grid xs={12}>
      <Card className="cardSearch">
        <TextField
          onChange={(e) => handleCompanyNameSearchTextChange(e.target.value)}
          onKeyDown={handleKeyDownEvent}
          placeholder="Search By Company, Product Or Service Description"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="icon-primaryActive" />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%" }}
        />
      </Card>
      <Card className="cardAdvanced">
        <Grid container sx={{ padding: "20px" }}>
          <Grid item xs={12}>
            <FilterIcon className="icon-primaryActive" style={{ float: "left" }} />
            <h3 style={{ float: "left", marginBottom: 10 }}>Search By</h3>
            <br />
          </Grid>
        </Grid>
        <Grid container key="FilterContainer">
          <Grid item container xs={12} sx={{ paddingLeft: "20px" }} key="BasicFiltersRow1">
            <Grid xs={6}>
              <FilterDropdown
                label="Provinces"
                lookupList={provincesList.map((province) => ({
                  id: province.id,
                  value: province.name,
                }))}
                selectedOptionsList={
                  connectionSearchModel.provinceIds || ([] as Array<number>)
                }
                menuProps={MenuProps}
                changeHandleCallback={(ev) => handleSelectChange(ev, "provinceIds")}
              />
            </Grid>
            <Grid xs={6}>
              <FilterDropdown
                label="B-BBEE Level"
                lookupList={beeLevels}
                selectedOptionsList={
                  connectionSearchModel.beeLevelIds || ([] as Array<number>)
                }
                menuProps={MenuProps}
                changeHandleCallback={(ev) => handleSelectChange(ev, "beeLevelIds")}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ marginTop: "20px", marginBottom: "40px", paddingLeft: "20px" }}
            key="BasicFiltersRow2"
          >
            <Grid xs={6}>
              <FilterDropdown
                label="Black Ownership"
                lookupList={blackOwnershipRanges}
                selectedOptionsList={selectedBlackOwnershipRanges}
                menuProps={MenuProps}
                changeHandleCallback={handleBlackOwnershipChange}
              />
            </Grid>
            <Grid xs={6}>
              <FilterDropdown
                label="Black Women Ownership"
                lookupList={blackWomenOwnershipRanges}
                selectedOptionsList={selectedBlackWomenOwnershipRanges}
                menuProps={MenuProps}
                changeHandleCallback={handleBlackWOwnershipChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <ButtonGroup
          buttons={[
            {
              label: "CLEAR",
              align: "right",
              onClick: clearSearchText,
              type: "secondary",

            },
            {
              label: "SEARCH",
              align: "right",
              onClick: handleSearch,
              type: "outlined",

            },
          ]}
        />
      </Card>
    </Grid>
  )
}

export default ConnectionSearchBy;