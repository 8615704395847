import React from "react";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DashboardInsightsSupplierBySegmentSummary } from "../../../../http/Company/Models/InsightsSuppliersBySegmentsSummary";

interface SupplierByProdSegChartProps {
  chartData: Array<DashboardInsightsSupplierBySegmentSummary>;
}

const SupplierByProdSegChart = ({ chartData }: SupplierByProdSegChartProps) => {

  const totalSuppliers = (chartData && chartData.length > 0) ? chartData[0].totalRows : 0;
  const getChartCategories = (dataForChart: Array<DashboardInsightsSupplierBySegmentSummary>) => {
    if (dataForChart === null || dataForChart === undefined || dataForChart.length === 0)
      return [] as Array<string>;

    const parsedCategories = dataForChart.map((prodSegment) => prodSegment.unspscSegmentName);
    return parsedCategories;
  };

  const getChartDataSeries = (dataForChart: Array<DashboardInsightsSupplierBySegmentSummary>) => {
    if (dataForChart === null || dataForChart === undefined || dataForChart.length === 0)
      return [] as Array<number>;

    const parsedSeriesData = dataForChart.map((prodSegment) => prodSegment.suppliersInSegmentPercentage);
    return parsedSeriesData;
  }

  const options: ApexOptions = {
    chart: {
      height: 330,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#4B69F9", "#101820", "#4B69F9", "#6F87FA", "#4B69F9", "#101820", "#6F87FA"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000"],
        fontSize: "12",
      },
      formatter(val, opt) {
        return `${opt.w.globals.labels[opt.dataPointIndex]}`;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
        color: "#000",
        opacity: 1,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: getChartCategories(chartData),
      title: {
        text: `Percentage of suppliers (out of ${totalSuppliers} total suppliers).`,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      y: {
        formatter: (value) => `${value}% (of ${totalSuppliers} total suppliers)`,
      },
    },
  };

  const series = [
    {
      name: "",
      data: getChartDataSeries(chartData),
    },
  ];

  return <ReactApexChart type="bar" options={options} series={series} height={330} />;
};

export default SupplierByProdSegChart;
