import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import SubscriptionService, { getActiveCompanySubscription, getCurrentSubscriptionPermissions } from "../../SubscriptionManagement/SubscriptionApi";
import { RootState } from "../../../app/store"
import { HasSubscriptionModel } from "../../SubscriptionManagement/Models/HasSubscriptionModel";

import { SubscriptionFeatureModel } from "../Models/SubscriptionFeatureModel";
import { SubscriptionPlanModel } from "../Models/SubscriptionPlanModel";
import { SubscriptionProductAddonsModel } from "../Models/SubscriptionProductAddonsModel";
import { SubscriptionProductFeaturesModel } from "../Models/SubscriptionProductFeaturesModel";
import { SubscriptionProductModel } from "../Models/SubscriptionProductModel";
import { SubscriptionProductPlansModel } from "../Models/SubscriptionProductPlansModel";
import { SubscriptionVoucherModel } from "../Models/SubscriptionVoucherModel";
import { SubscriptionVoucherPlansModel } from "../Models/SubscriptionVoucherPlansModel";
import {
  subscriptionFeaturePermissionsSlice,
  subscriptionFeatureSlice,
  subscriptionPlanSlice,
  subscriptionProductAddonsSlice,
  subscriptionProductFeaturesSlice,
  subscriptionProductPlansSlice,
  subscriptionProductSlice,
  subscriptionVoucherPlansSlice,
  subscriptionVoucherSlice,
  hasSubscriptionsSlice,
  currentActiveSubscriptionsSlice,
} from "./SubscriptionSlice";
import { setSubscriptionPermissionsList } from "./baseCompanyInfoSlice";
import { CompanySubscription } from "../../SubscriptionManagement/Models/CompanySubscription";

export const subscriptionProductActions = subscriptionProductSlice.actions
export const subscriptionFeatureActions = subscriptionFeatureSlice.actions
export const subscriptionPlanActions = subscriptionPlanSlice.actions
export const subscriptionVoucherActions = subscriptionVoucherSlice.actions
export const subscriptionVoucherPlanActions = subscriptionVoucherPlansSlice.actions
export const subscriptionProductFeatureActions = subscriptionProductFeaturesSlice.actions
export const subscriptionProductPlanActions = subscriptionProductPlansSlice.actions
export const subscriptionProductAddonsActions = subscriptionProductAddonsSlice.actions
export const hasSubscriptionActions = hasSubscriptionsSlice.actions
export const subscriptionFeaturePermissionsActions = subscriptionFeaturePermissionsSlice.actions
export const subscriptionCurrentSubscriptionActions = currentActiveSubscriptionsSlice.actions

export const fetchHasSubscriptions = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: HasSubscriptionModel[] = await SubscriptionService.getHasSubscription();
  dispatch(hasSubscriptionActions.setHasSubscriptions(response))

}

export const fetchProducts = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionProductModel[] = await SubscriptionService.getAllProducts();
  dispatch(subscriptionProductActions.setProducts(response))

}

export const fetchFeatures = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionFeatureModel[] = await SubscriptionService.getAllFeatures();
  dispatch(subscriptionFeatureActions.setFeatures(response))
}

export const fetchPlans = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: SubscriptionPlanModel[] = await SubscriptionService.getAllPlans();
  dispatch(subscriptionPlanActions.setPlans(response))

}

export const fetchProductAddons = (productId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const response: SubscriptionProductAddonsModel[] = await SubscriptionService.getAllAddons(productId);
  dispatch(subscriptionProductAddonsActions.setProductAddons(response))

}

export const fetchProductFeatures = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionProductFeaturesModel[] = await SubscriptionService.getAllProductFeatures(id);
  dispatch(subscriptionProductFeatureActions.setProductFeatures(response))

}

export const fetchProductPlans = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionProductPlansModel[] = await SubscriptionService.getAllProductPlans(id);
  dispatch(subscriptionProductPlanActions.setProductPlans(response))

}


export const fetchVoucherPlans = (id: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionVoucherPlansModel[] = await SubscriptionService.getAllVoucherPlans(id);
  dispatch(subscriptionVoucherPlanActions.setVoucherPlans(response))

}
export const fetchVouchers = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SubscriptionVoucherModel[] = await SubscriptionService.getAllVouchers();
  dispatch(subscriptionVoucherActions.setVoucher(response))

}

export const fetchCurrentSubscriptionPermissions = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response = await getCurrentSubscriptionPermissions();
  dispatch(setSubscriptionPermissionsList(response))

}

export const fetchCurrentSubscription = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: CompanySubscription = await getActiveCompanySubscription() as CompanySubscription;
  dispatch(subscriptionCurrentSubscriptionActions.setParticularCurrentSubscription(response))

}