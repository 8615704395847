import { useState } from "react";
import { Button, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { BaseCompanyInfo } from "../../http/Company/Models/BaseCompanyInfo";
import { GetSomeBasicCompanyInfoAsync } from "../../http/Company/company";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { fetchDataManagementPartners } from "../../http/Redux/Store/DataManagementPartnerActions";
import { useAppDispatch } from "../../app/hooks";
import CompanyVerificationService from "../../http/CompanyVerificationService";

const AddPartner = ({ back }: { back: () => void }) => {
  const [allCompanies, setAllCompanies] = useState<BaseCompanyInfo[]>([] as BaseCompanyInfo[]);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useAppDispatch();

  const handleSearchCompanies = (value: string) => {
    if (value === "") {
      setAllCompanies([]);
    } else {
      const loader = async () => {
        setBackdropLoading(true);
        const list = (
          await GetSomeBasicCompanyInfoAsync(value).finally(() => {
            setBackdropLoading(false);
          })
        ).data;
        setAllCompanies(list);
      };

      loader();
    }
  };

  const handleAddPartnerDB = () => {
    const loader = async () => {
      setBackdropLoading(true);
      await CompanyVerificationService.addDataManagementPartner(isSelected).finally(() => {
        setBackdropLoading(false);
        toast.success("Data Partner Added")
      });

      dispatch(fetchDataManagementPartners());
      back();
    };

    loader();
  };

  return (
    <Grid container sx={{ height: "500px", width: "50%" }}>
      <LoadingBackdrop showBackdrop={backdropLoading} />
      <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
        <Tooltip title="Back To Users">
          <IconButton onClick={back}><ArrowBackIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid xs={11}>
        <TextField
          sx={{ width: "100%" }}
          size="small"
          placeholder="Search For Company..."
          onChange={(event) => setSearchTerm(event.target.value)} />
      </Grid>
      <Grid xs={1}>
        <Button variant="contained" sx={{ marginLeft: 1, height: "40px" }} onClick={() => handleSearchCompanies(searchTerm)}><SearchIcon /></Button>
      </Grid>
      <Grid xs={12} sx={{ height: "250px" }}>
        {allCompanies.length !== 0 && (
          <div>
            <Typography>Select a company from the list below: </Typography>
            {allCompanies.map((company) => (
              <Button key={company.companyId}
                onClick={() => setIsSelected(company.companyId)}
                variant={isSelected === company.companyId ? "contained" : "outlined"}
                sx={{ marginRight: 2, marginTop: 2 }}>
                {company.companyRegisteredName} {company.countryId}
              </Button>
            ))}
          </div>
        )}
      </Grid>
      <Grid xs={12} sx={{ textAlign: "right" }}>
        <Divider sx={{ marginBottom: 2 }} />
        <Button variant="contained" onClick={handleAddPartnerDB}>Add Partner</Button>
      </Grid>
    </Grid>
  )
}

export default AddPartner;