import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import {
  BusinessUnitSlice,
  CompanyCategorySlice,
  ContractingTierSlice,
  OnboardingCheckSlice,
  OnboardingConfigurationSetSlice,
  OnboardingRequestSlice,
  SupplierTypeSlice,
} from "./Store/OnBoardingSlice";
import { ErrorResponseModel } from "./Models/ErrorResponseModel";
import OnBoardingServices from "../OnBoardingServices";
import { CompanyViewModel } from "../Company/Models/CompanyViewModel";
import { OnboardingConfigurationSetModel } from "./Models/OnboardingConfigurationSetModel";
import { SpecificTypeDictionaryEntryModel } from "./Models/DictionaryEntryModel";
import { CompanyCategoryModel } from "./Models/CompanyCategoryModel";
import { OnboardingRequestModel } from "./Models/OnboardingRequestModel";



export const OnboardingCheckActions = OnboardingCheckSlice.actions;
export const OnboardingRequestActions = OnboardingRequestSlice.actions;
export const OnboardingConfigurationSetActions = OnboardingConfigurationSetSlice.actions;
export const BusinessUnitActions = BusinessUnitSlice.actions;
export const CompanyCategoryActions = CompanyCategorySlice.actions;
export const SupplierTypeActions = SupplierTypeSlice.actions;
export const ContractingTierActions = ContractingTierSlice.actions;


export const fetchOnboardingChecks = (company: CompanyViewModel, section: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: ErrorResponseModel[] = await OnBoardingServices.validate(company, section);
  dispatch(OnboardingCheckActions.setOnboardingCheck(response))

}

export const fetchOnboardingConfigurationSets = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: OnboardingConfigurationSetModel[] = await OnBoardingServices.getOnboardingConfigurationSet(companyId);
  dispatch(OnboardingConfigurationSetActions.setOnboardingConfigurationSet(response))

}


export const fetchOnboardingRequest = (companyId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: OnboardingRequestModel[] = await OnBoardingServices.getCompanyOnboardingRequests(companyId);
  dispatch(OnboardingRequestActions.setOnboardingRequest(response))

}


export const fetchBusinessUnits = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SpecificTypeDictionaryEntryModel[] = await OnBoardingServices.getBusinessUnits();
  dispatch(BusinessUnitActions.setOnBusinessUnit(response))

}

export const fetchCompanyCategory = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: CompanyCategoryModel[] = await OnBoardingServices.getCompanyCategoryManagers();
  dispatch(CompanyCategoryActions.setCompanyCategory(response))

}

export const fetchSupplierTypes = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SpecificTypeDictionaryEntryModel[] = await OnBoardingServices.getSupplierTypes();
  dispatch(SupplierTypeActions.setOnSupplierType(response))

}

export const fetchContractingTier = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {

  const response: SpecificTypeDictionaryEntryModel[] = await OnBoardingServices.getContractingTiers();
  dispatch(ContractingTierActions.setOnContractingTier(response))

}


