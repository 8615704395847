import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { TaxInformation } from "../../../../http/Company/Models/CompanyTaxInformation";
import { TaxIcon } from "../../../../util/MUI_Theme/Icons";
import TextField from "../Controls/TextField";
import DatePicker from "../Controls/DatePicker";
import formatDate from "../../../../util/formatter/formatDate";
import YesNoRadioButton from "../Controls/YesNoRadioButton";

interface TaxInfoProps {
  taxInfoModel: TaxInformation;
  sectionEnabled: boolean,
  vatSectionEnabled: boolean,
  // eslint-disable-next-line no-unused-vars
  updateSectionEnabled: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateVatSectionEnabled: (fieldName: string, value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateTaxInfoModel: (updatedModel: TaxInformation) => void;
}

const VAT_ENABLED_FIELD_NAME = "hasVatEnabled";

const TaxInfo = ({
  taxInfoModel,
  updateTaxInfoModel,
  sectionEnabled,
  vatSectionEnabled,
  updateSectionEnabled,
  updateVatSectionEnabled,
}: TaxInfoProps) => {
  const DEFAULT_OBJECT: TaxInformation = useMemo(
    () => ({
      companyId: taxInfoModel.companyId,
      taxClearanceNumber: null,
      taxClearanceNumberExpiryDate: null,
      vatNumber: null,
      vatRegistrationDate: null,
      taxClearanceNumberIssueDate: null,
    }),
    [taxInfoModel.companyId]
  );

  const [taxClearanceIssueDate, setTaxClearanceIssueDate] = useState<Dayjs | null>(null);
  const [taxClearanceExpiryDate, setTaxClearanceExpiryDate] = useState<Dayjs | null>(null);
  const [vatRegistrationDate, setVatRegistrationDate] = useState<Dayjs | null>(null);

  const handleTaxSectionRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      updateTaxInfoModel(DEFAULT_OBJECT);
      updateSectionEnabled(false);
      setTaxClearanceIssueDate(null);
      setTaxClearanceExpiryDate(null);
      setVatRegistrationDate(null);
      return;
    }

    updateSectionEnabled(true);
  };

  const handleVatRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "no") {
      updateVatSectionEnabled(VAT_ENABLED_FIELD_NAME, false);
      setVatRegistrationDate(null);
      return;
    }

    updateVatSectionEnabled(VAT_ENABLED_FIELD_NAME, true);
  };

  const handleTaxClearanceIssueDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...taxInfoModel,
        taxClearanceNumberIssueDate: null,
      } as TaxInformation;
      setTaxClearanceIssueDate(null);
      updateTaxInfoModel(newVal);
      return;
    }

    const newVal = {
      ...taxInfoModel,
      taxClearanceNumberIssueDate: formatDate(selectedDate.toDate().toString()),
      taxSectionEnabled: true,
    } as TaxInformation;
    setTaxClearanceIssueDate(selectedDate);
    updateTaxInfoModel(newVal);
  };

  const handleTaxClearanceExpiryDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...taxInfoModel,
        taxClearanceNumberExpiryDate: null,
      } as TaxInformation;

      setTaxClearanceExpiryDate(null);
      updateTaxInfoModel(newVal);
      return;
    }

    const newVal = {
      ...taxInfoModel,
      taxClearanceNumberExpiryDate: formatDate(selectedDate.toDate().toString()),
    } as TaxInformation;
    setTaxClearanceExpiryDate(selectedDate);
    updateTaxInfoModel(newVal);
  };

  const handleVatRegistrationDateChange = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      const newVal = {
        ...taxInfoModel,
        vatRegistrationDate: null,
      } as TaxInformation;
      setVatRegistrationDate(null);
      updateTaxInfoModel(newVal);
      return;
    }

    const newVal = {
      ...taxInfoModel,
      vatRegistrationDate: formatDate(selectedDate.toDate().toString()),
    } as TaxInformation;
    setVatRegistrationDate(selectedDate);
    updateTaxInfoModel(newVal);
  };

  useEffect(() => {
    setTaxClearanceIssueDate(dayjs(taxInfoModel.taxClearanceNumberIssueDate));
    setTaxClearanceExpiryDate(dayjs(taxInfoModel.taxClearanceNumberExpiryDate));
    setVatRegistrationDate(dayjs(taxInfoModel.vatRegistrationDate));
  }, [taxInfoModel.taxClearanceNumberExpiryDate, taxInfoModel.taxClearanceNumberIssueDate, taxInfoModel.vatNumber, taxInfoModel.vatRegistrationDate]);

  return (
    <Grid container sx={{ marginLeft: "20px", marginTop: "40px" }}>
      <Grid item xs={12} className="profile-view-labels">
        <TaxIcon /> SARS INFORMATION
      </Grid>
      <Grid item xs={12}>
        <YesNoRadioButton
          title="Registered for Tax?"
          valueCondition={sectionEnabled ? "yes" : "no"}
          onToggle={handleTaxSectionRadioBtnChange}
        />
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!sectionEnabled}
            label="Tax Clearance PIN"
            value={taxInfoModel?.taxClearanceNumber || ""}
            onChange={(e) => {
              const newVal = {
                ...taxInfoModel,
                taxClearanceNumber: e.target.value,
              } as TaxInformation;
              updateTaxInfoModel(newVal);
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disabled={!sectionEnabled}
            disableFuture
            value={taxClearanceIssueDate}
            title="Tax Clearance Pin Issue Date"
            width="90%"
            setDatePicker={handleTaxClearanceIssueDateChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disabled={!sectionEnabled}
            disablePast
            value={taxClearanceExpiryDate}
            title="Tax Clearance Pin Expiry Date"
            width="90%"
            setDatePicker={handleTaxClearanceExpiryDateChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <YesNoRadioButton
            disabled={!sectionEnabled}
            title="Registered for VAT?"
            valueCondition={vatSectionEnabled ? "yes" : "no"}
            onToggle={handleVatRadioBtnChange}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            required
            disabled={!vatSectionEnabled}
            label="VAT Number"
            value={taxInfoModel?.vatNumber || ""}
            onChange={(e) => {
              const newVal = {
                ...taxInfoModel,
                vatNumber: e.target.value,
              } as TaxInformation;
              updateTaxInfoModel(newVal);
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DatePicker
            required
            disableFuture
            disabled={!vatSectionEnabled}
            value={vatRegistrationDate}
            title="VAT Registration Date"
            width="90%"
            setDatePicker={handleVatRegistrationDateChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaxInfo;
