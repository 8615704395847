import { Box, Slide, Step, StepButton, Stepper, Typography, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import SwipeableViews from "react-swipeable-views";
// import RequestsMade from "./RequestsMade";
// import RequestsReceived from "./RequestsReceived";
import MadeConnectionRequests from "./MadeConnectionRequests";
import ReceivedConnectionRequests from "./ReceivedConnectionRequests";
import { MyConnections } from "../../../util/enums/MyConnectionsTab";

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const GetStepState = (index: number) => {
  if (index === 0) {
    return <CallMadeIcon sx={{ color: "#1976d2" }} />;
  }

  return <CallReceivedIcon sx={{ color: "#1976d2" }} />;
};

const CompanyConnect = ({ tab }: { tab: string }) => {
  const setTab = () => {
    if (tab === MyConnections.RequestReceived) {
      return 1;
    }
    return 0;
  };
  const [value, setValue] = useState(setTab);
  const theme = useTheme();

  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const steps = [
    {
      id: 0,
      label: "Requests Made",
      description: "",
      timeout: 1000,
    },
    {
      id: 1,
      label: "Requests Received",
      description: "",
      timeout: 1200,
    },
  ];

  return (
    <div className="config-header-box" style={{ height: "600px" }}>
      <div className="config-sub-box">
        <div className="config-sub-box-left">
          <Stepper
            activeStep={value}
            orientation="vertical"
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          >
            {steps.map((step) => (
              <Slide
                direction="right"
                in
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...{ timeout: step.timeout }}
              >
                <Step
                  disabled={false}
                  onClick={() => {
                    handleChangeTab(step.id);
                  }}
                >
                  <StepButton
                    disabled={false}
                    className="stepButton"
                    sx={{
                      fontWeight: 900,
                      borderRight: step.id === value ? "2px solid #435cd2" : "",
                    }}
                    // eslint-disable-next-line no-nested-ternary
                    icon={GetStepState(step.id)}
                    key={step.id}
                  >
                    {step.label}
                  </StepButton>
                </Step>
              </Slide>
            ))}
          </Stepper>
        </div>
        <div className="config-sub-box-right">
          <Box>
            <div style={{ width: "100%", height: "40px", paddingLeft: "20px", paddingTop: "10px" }}>
              <Typography>
                <h3>{steps[value].label}</h3>
              </Typography>
            </div>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Box sx={{ height: "480px" }}>
                  <MadeConnectionRequests />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Box sx={{ height: "480px" }}>
                  <ReceivedConnectionRequests />
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default CompanyConnect;
