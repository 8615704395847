import React, { useEffect, useState } from "react";

import { useAccount, useMsal } from "@azure/msal-react";

import dayjs, { Dayjs } from "dayjs";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCompany } from "../../../../http/Redux/Store/companySlice";
import {
  addNewCompanyDocumentAsync,
  deleteCompanyDocumentAsync,
  getCompanyDocumentsWithTypesAsync,
} from "../../../../http/DocumentManagement/DocumentManagement";
import { DocumentDtoModel } from "../../../../http/DocumentManagement/Models/DocumentDtoModel";
import DocumentUploader from "./DocumentUploader";
import LoadingBackdrop from "../../../Backdrop/LoadingBackdrop";
import DeleteDocumentDialog from "./DeleteDocumentDialog";
import UploadDocumentDialog from "./UploadDialogDialog";
import { DocumentTypeModel } from "../../../../http/Redux/Models/DocumentTypeModel";
import { selectDocumentTypeList } from "../../../../http/Redux/Store/DocumentTypesSlice";
import BasicDialog from "../../../Dialogs/BasicDialog";
import { fetchDocumentTypes } from "../../../../http/Redux/Store/DocumentTypeActions";
import DocumentType from "../../../../util/enums/DocumentType";
import Redirect from "./RedirectOnSuccess";



const CompanyDocumentsPost = ({ evaluatingCompanyId, evaluatedCompanyId }: { evaluatingCompanyId: number, evaluatedCompanyId: number }) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const docTypes: Array<DocumentTypeModel> = useAppSelector(selectDocumentTypeList);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const userAdId = account?.localAccountId || "";

  // Dialog Display State variables:
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showOverwriteDialog, setShowOverwriteDialog] = useState<boolean>(false);

  const [showBackDrop, setShowBackdrop] = useState<boolean>(false);
  const [documentToProcess, setDocumentToProcess] = useState<DocumentDtoModel>(
    {} as DocumentDtoModel
  );

  const [files, setFiles] = useState<Array<DocumentDtoModel>>([] as Array<DocumentDtoModel>);

  const [uploads, setUploadFiles] = useState<Array<File>>([] as Array<File>);
  const [refresh, setRefresh] = useState(false);

  const [doesDocExpire, setDoesDocExpire] = useState<boolean>(false);
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<Dayjs | null>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<number>(DocumentType.Unknown);
  const [shareDocument, setShareDocument] = useState<boolean>(false);



  const ResetUploadComponents = () => {
    setUploadFiles([] as Array<File>);
    setFiles([] as Array<DocumentDtoModel>);
    setDoesDocExpire(false);
    setSelectedExpiryDate(null);
    setSelectedDocumentType(0);
    setShareDocument(false);
  };

  const UploadFile = async () => {
    if (files.length === 0 && uploads.length === 0) return;

    if (showOverwriteDialog) setShowOverwriteDialog(false);

    await addNewCompanyDocumentAsync(
      company.companyId,
      doesDocExpire,
      doesDocExpire ? (selectedExpiryDate?.toDate() as Date) : new Date(0),
      selectedDocumentType as number,
      userAdId,
      uploads
    );

    ResetUploadComponents();
    setRefresh(true);
    setShowBackdrop(false);
  };

  const ShowFileUploadDialog = (filesToUpload: File[]) => {
    setShowUploadDialog(true);
    setUploadFiles(filesToUpload);
    setShowBackdrop(false);
  };

  const HandleDownloadFile = async (fileUrl: string, fileNameToSave: string) => {
    const fileName = fileNameToSave;
    console.log(fileUrl);
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const windowUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = windowUrl;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
    });
  };

  const HandleDeleteFile = async (fileId: string, userId: string) => {
    if (!fileId || !userId) return;

    await deleteCompanyDocumentAsync(company.companyId, fileId, userId);

    const newFileArray: Array<DocumentDtoModel> = files.map((docModel) => {
      if (docModel.id === fileId) {
        const replacementModel: DocumentDtoModel = {
          documentTypeName: docModel.documentTypeName,
          nonStandardUpload: false,
          displayOrder: docModel.displayOrder,
          documentUrl: "",
          id: "00000000-0000-0000-0000-000000000000",
          fileName: "",
          isDeleted: false,
          companyId: 0,
          documentExpires: false,
          typeId: docModel.typeId,
          classificationId: 0,
          effectiveDatetime: new Date(),
          created: new Date(),
          createdByUserId: "",
          modified: new Date(),
          modifiedByUserId: "",
          deleted: new Date(),
          deletedByUserId: "",
        };

        return replacementModel;
      }

      return docModel;
    });

    setFiles(newFileArray);
  };

  const HandleCompanyTypeChange = (newCompanyType: number) => {
    setSelectedDocumentType(newCompanyType);
  };

  const HandleExpiryDateChange = (newExpiryDate: Dayjs | null) => {
    setSelectedExpiryDate(newExpiryDate);
  };

  const HandleShareDocChange = (newShareValue: boolean) => {
    setShareDocument(newShareValue);
  };

  const HandleOverwriteDialogClose = () => {
    ResetUploadComponents();
    setShowOverwriteDialog(false);
  };

  const HandleUpload = () => {
    if (selectedDocumentType === 0) {
      Swal.fire({
        title: "No document type selected",
        text: "Please select a document type.",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }

    const typeCheck = files.some((file) => file.typeId === selectedDocumentType);
    if (typeCheck) {
      setShowUploadDialog(false);
      setShowOverwriteDialog(true);
      return;
    }

    setShowBackdrop(true);
    UploadFile();
  };


  useEffect(() => {
    setShowBackdrop(true);

    const loader = async () => {
      dispatch(fetchDocumentTypes());

      if (files.length === 0) {
        const companyFiles = (await getCompanyDocumentsWithTypesAsync(company.companyId)).data;
        setFiles(companyFiles);
      }

      setShowBackdrop(false);
      setRefresh(false);
    };
    loader();
  }, [files.length, setShowBackdrop, docTypes.length, docTypes.length, company.companyId, dispatch]);

  useEffect(() => {
    Swal.close();
  })

  return (
    <>
      <LoadingBackdrop showBackdrop={showBackDrop} />
      <DocumentUploader
        filesList={files}
        dropzoneOnChange={ShowFileUploadDialog}
        docRowSaveOnClick={HandleDownloadFile}
        showBackdrop={setShowBackdrop}
        setDocument={setDocumentToProcess}
        showDeleteDialog={setShowDeleteDialog}
      />

      <DeleteDocumentDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        documentToDelete={files[0]}
        setDocumentToDelete={setDocumentToProcess}
        confirmCallback={() => HandleDeleteFile(documentToProcess.id, userAdId)}
      />
      <Redirect evaluatingCompanyId={evaluatingCompanyId} evaluatedCompanyId={evaluatedCompanyId} refresh={refresh} />
      <UploadDocumentDialog
        open={showUploadDialog}
        setOpen={setShowUploadDialog}
        documentTypes={docTypes}
        doesDocExpire={doesDocExpire}
        handleDoesDocExpireChange={setDoesDocExpire}
        handleCompanyTypeChange={HandleCompanyTypeChange}
        handleExpiryDateChange={HandleExpiryDateChange}
        handleSharedChange={HandleShareDocChange}
        selectedDocumentType={selectedDocumentType || 0}
        expiryDate={selectedExpiryDate || dayjs(new Date())}
        setShareDoc={shareDocument || false}
        confirmCallback={HandleUpload}
        clearDocumentDtoArrayCallback={setFiles}
        clearUploadFileArrayCallback={setUploadFiles}
      />

      <BasicDialog
        openCloseSwitch={showOverwriteDialog}
        dialogTitle="Overwrite existing file?"
        dialogText="A document of this type already exists. Continuing will overwrite it."
        hideSecondaryButton
        okButtonText="Proceed"
        closeButtonText="Cancel"
        onOkClick={UploadFile}
        onClose={HandleOverwriteDialogClose}
      />
    </>
  );
};

export default CompanyDocumentsPost;
