import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./util/Styling/Base.css";
import "./util/Styling/Popover.css";
import { msalConfig } from "./authConfig";
import "./util/Styling/Index.css"

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>

    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App instance={msalInstance} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export default msalInstance;