import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import GetCompanyProfileView from "../../../http/Company/CompanyProfileViewApi";

const useCompanyProfileView = (companyId:number, isConnected: boolean) => {
  const query = useQuery({
    queryKey: [queryKeys.companyProfileView, companyId, isConnected],
    queryFn: async () => (await GetCompanyProfileView(companyId, isConnected)).data,
    enabled: !!companyId,
  })

  return query;
}

export default useCompanyProfileView;