import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import "./css/Panels.css";
import { CreateRoleMessage } from "../../../http/CompanyUserManagement/Messages/CreateRoleMessage";
import { CreateNewRoleAsync } from "../../../http/CompanyUserManagement/userManagementApi";
import TemporaryDrawer from "../../../pages/Compliance/TemporaryDrawer";
import { ModulePermissions } from "../../../http/CompanyUserManagement/Messages/Module";
import { ModulePermissionSelectionMessage } from "../../../http/CompanyUserManagement/Messages/ModulePermissionSelectionMessage";
import { RoleMessage } from "../../../http/CompanyUserManagement/Messages/RoleMessage";
import { Permission } from "../../../http/CompanyUserManagement/Messages/Permission";
import useFieldValidation from "../../../util/hooks/useFieldValidation";

const AddRoleSideDrawer = (
  {
    modulePermissions,
    createNewRoleCallback,
  }
    :
    {
      modulePermissions: ModulePermissions[],
      // eslint-disable-next-line no-unused-vars
      createNewRoleCallback: (newRole : RoleMessage) => void,
    }
) => {
  const [roleDetails, setRoleDetails] = useState<CreateRoleMessage>({} as CreateRoleMessage)
  const [isSavingData, setIsSavingData] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [permissionSelection, setPermissionSelection] = useState<ModulePermissionSelectionMessage[]>([])
  const [validateField] = useFieldValidation("companyProfile");
  const onSaveClickHandler = async () => {
    const isObjectEmpty = Object.keys(roleDetails).length === 0;
    if (!isObjectEmpty) {
      try {
        setIsSavingData(true);
        const newRole = await CreateNewRoleAsync({ ...roleDetails, rolePermissions: permissionSelection });
        const permissionIds = (permissionSelection.map(x => (
          [
            ...x.selectedPermissionIds,
          ]))).reduce((acc, curVal) => acc.concat(curVal), []);

        createNewRoleCallback(
          { 
            ...newRole, 
            permissions: [...permissionIds.map((x) => ({ id: x } as unknown as Permission))],
          });
        setRoleDetails({} as CreateRoleMessage); // clear object
        setShowDrawer(false);
        setIsSavingData(false);
      } catch (error) {
        setIsSavingData(false);
        throw error;
      }
    }
  };

  useEffect(() => {
    // initialize the selected permissions state
    setPermissionSelection(modulePermissions.map(mp => (
      {
        moduleId: mp.id,
        selectedPermissionIds: [],
      }
    )))
  }, [modulePermissions])


  const onHandlePermissionChange = (event: SelectChangeEvent<number[]>, moduleId: number) => {

    setPermissionSelection((prevState) => prevState.map((obj) => {
      if (obj.moduleId === moduleId) {
        return { ...obj, selectedPermissionIds: event.target.value as unknown as number[] }
      }
      return obj;
    }))
  };

  return (
    <React.Fragment>
      <Tooltip title="Add Role">
        <Button variant="outlined" onClick={() => setShowDrawer(true)}><AddIcon /></Button>
      </Tooltip>
      <TemporaryDrawer
        showDrawer={showDrawer}
        onCloseDrawerCallback={() => { setShowDrawer(false) }}
      >
        <>
          <Box className="add-panel-close-box">
            <IconButton onClick={() => setShowDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box role="presentation" className="add-role-panel-box">
            <div className="add-panel-image">
              <BuildCircleIcon className="add-panel-image-icon" />
            </div>
            <h3 className="add-panel-heading">Add New Role</h3>

            <div className="hr-line" />
            <TextField
              margin="dense"
              id="rolename"
              InputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              disabled={isSavingData}
              label="Role Name"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(ev) => { setRoleDetails({ ...roleDetails, name: ev.target.value }) }}
              error={validateField("Role Name", roleDetails?.name ?? "").isInvalid}
              helperText={validateField("Role Name", roleDetails?.name ?? "").validationErrorText}
            />
            <TextField
              margin="dense"
              id="description"
              InputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              disabled={isSavingData}
              label="Role Description"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(ev) => { setRoleDetails({ ...roleDetails, description: ev.target.value }) }}
              error={validateField("Role Description", roleDetails?.description ?? "").isInvalid}
              helperText={validateField("Role Description", roleDetails?.description ?? "").validationErrorText}
            />


            <div className="hr-line" />
            <br />
            <h6>Role Permissions</h6>
            {modulePermissions.map(module =>
              <FormControl sx={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}>
                <InputLabel sx={{ fontSize: "14px" }} id="demo-multiple-checkbox-dash-label">{module.name}</InputLabel>
                <Select
                  label={module.name}
                  id="demo-multiple-checkbox-dash"
                  multiple
                  margin="dense"
                  inputProps={{ style: { fontSize: 14 } }}
                  value={permissionSelection?.find(
                    (z) => z.moduleId === module.id)?.selectedPermissionIds ||
                    [] as Array<number>
                  }
                  size="small"
                  onChange={(ev) => onHandlePermissionChange(ev, module.id)}
                  input={<OutlinedInput label={module.name} />}
                  renderValue={(selected) => module.permissions?.filter((item) => selected.includes(item.id))?.map(x => x.description).join(",")}
                >
                  {module.permissions.map((permission) => (
                    <MenuItem key={permission.id} value={permission.id}>
                      <Checkbox
                        checked={permissionSelection?.find((z) => z.moduleId === module.id)?.selectedPermissionIds?.includes(permission.id)} />
                      <ListItemText primary={permission.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Box className="add-panel-close-box">
              <div className="hr-line" />
              <Button variant="outlined" onClick={() => onSaveClickHandler()}>Save</Button>
            </Box>
          </Box></>
      </TemporaryDrawer>
    </React.Fragment>
  );
};

export default AddRoleSideDrawer;


