import { useMutation, useQueryClient } from "@tanstack/react-query"
import queryKeys from "../queries/queryKeys";
import { CoidaDetails } from "../../../http/Company/Models/CoidaDetails";
import { AddCompanyCoidaDetails, DeleteCompanyCoidaDetails, UpdateCompanyCoidaDetails } from "../../../http/Company/CompanyCoidaDetails";
import { ComplianceMutatorProps } from "./Interfaces/ComplianceMutatorProps";

const useCompanyCoidaDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({mutationMode, mutationDetails} : ComplianceMutatorProps) => {
      const coidaObject = mutationDetails as CoidaDetails;

      if (mutationMode === "add") {
        await AddCompanyCoidaDetails(coidaObject);
        return;
      }

      if (mutationMode === "update") {
        await UpdateCompanyCoidaDetails(coidaObject);
        return;
      }

      await DeleteCompanyCoidaDetails(coidaObject);
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyCoidaDetails] });

      // Snapshot the previous value
      const currentCoidaDetails = queryClient.getQueryData([queryKeys.companyCoidaDetails]);

      // Return a context object with the snapshotted value
      return { currentCoidaDetails }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyCoidaDetails], context?.currentCoidaDetails);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyCoidaDetails] });
    },
  })
}

export default useCompanyCoidaDetailsMutation;