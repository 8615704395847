import { useAppSelector } from "../../app/hooks";
// eslint-disable-next-line no-unused-vars
import { isDevelopmentEnviroment } from "../../authConfig";
import PermissionClassification from "../enums/PermissionClassification";

const allowFullAccess = isDevelopmentEnviroment;

const useAllowedPermission = () => {
  const allowedUserPermission = useAppSelector((state) => state.user.allowedPermissions);
  const userPermissionList = allowedUserPermission.map(x => x.classification);
  const subscriptionPermissionIdList = useAppSelector((state) => state.baseCompanyInfo.subscriptionPermissionsList);

  const handleHasPermissionCheckFunction = (permission: PermissionClassification) => {
    const permissionId = allowedUserPermission.find(x => x.classification === permission)?.id ?? 0;
    return allowFullAccess ||
      (
        userPermissionList.includes(permission) // user has permission
        && (
          subscriptionPermissionIdList?.includes(permissionId) ||  // subscription has the permission
          permission >= PermissionClassification.GlobalCompanySwitching // or is a plaform permission
        )
      );
  }
  return handleHasPermissionCheckFunction
}

// This hook is for subscription-level permissions that are not high enough to be platform-level,
// but are not meant to be tied to specific user roles.
export const useHasSubscriptionPermission = () => {
  const allPermissionsList = useAppSelector(x => x.permission.allPermissions);
  const subscriptionPermissionIdList = useAppSelector((state) => state.baseCompanyInfo.subscriptionPermissionsList);

  const subPermissionClassificationList = allPermissionsList
    .filter((perm) => subscriptionPermissionIdList.includes(perm.id))
    .map((perm) => perm.classification);

  const handleHasSubscriptionPermissionCheck = (permission: PermissionClassification) =>
    allowFullAccess || subPermissionClassificationList.includes(permission);
  return handleHasSubscriptionPermissionCheck;
}

export const useHasAnyAllowedPermissions = () => {
  const permissionIsAllowed = useAllowedPermission();

  const handleHasPermissionCheckFunction = (permissions: PermissionClassification[]) =>
    allowFullAccess || permissions.some(p => permissionIsAllowed(p))
  return handleHasPermissionCheckFunction
}

export default useAllowedPermission;
