/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum AgentTaskAttentionTypes {
  Completed = 0,
  Low = 1,
  Moderate = 2,
  Urgent = 3,
}


const AgentTaskAttentionTypesToString = (attentionType: AgentTaskAttentionTypes): string => {
  switch (attentionType) {
    case AgentTaskAttentionTypes.Low:
      return "Low Priority";
    case AgentTaskAttentionTypes.Moderate:
      return "Moderate Priority";
    case AgentTaskAttentionTypes.Urgent:
      return "Urgent Priority";
    case AgentTaskAttentionTypes.Completed:
      return "Completed";
    default:
      return "Unknown";
  }
};

export { AgentTaskAttentionTypes, AgentTaskAttentionTypesToString };