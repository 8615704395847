import { Button, Accordion, AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SecondaryContact from "../CompanyContact/SecondaryContact";
import { ContactModel } from "../../../../http/Company/Models/ContactModel";
import { useAppDispatch } from "../../../../app/hooks";
import { setCompanyContactState } from "../../../../http/Redux/Store/companySlice";
import "../CompanyPost.css";
import ContactType from "../../../../util/enums/ContactType";
import styles from "../../../../pages/Company/CompanyProfile.module.scss";


const CompanySecondaryContactPost = ({
  contacts,
  SetDirtyCallback,
  OnContactSave,
  OnContactDelete,
}: {
  contacts: Array<ContactModel>;
  /* eslint-disable no-unused-vars */
  SetDirtyCallback: (isDirty: boolean) => void;
  OnContactSave: (contactDetails: ContactModel) => void;
  OnContactDelete: (contactToDelete: ContactModel, staged: boolean) => Promise<void>;
  /* eslint-disable no-unused-vars */
}) => {
  const dispatch = useAppDispatch();
  const [secondaryContacts, setSecondaryContacts] = useState<Array<React.ReactNode>>([]);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const AddSecondaryContact = () => {
    const pendingContactCheck = contacts.find((contact) => contact.id === 0);
    if (pendingContactCheck) {
      Swal.fire({
        title: "Warning",
        text: "Only one secondary contact can be processed at a time.",
        showCancelButton: false,
        icon: "warning",
      });
      return;
    }

    const initialObject = {
      companyId: 0,
      contactTypeId: ContactType.Secondary,
      email: "",
      fax: "",
      id: 0,
      idNumber: "",
      isDeleted: false,
      landLine: "",
      mobile: "",
      name: "",
      designation: "",
      username: "",
      website: "",
    }

    setSecondaryContacts([
      ...secondaryContacts,
      <SecondaryContact
        key={secondaryContacts.length + 1}
        contactData={initialObject}
        SetDirtyCallback={SetDirtyCallback}
        OnContactSave={OnContactSave}
        OnContactDelete={OnContactDelete}
      />,
    ]);

    dispatch(setCompanyContactState(initialObject));
  };

  const initialLoadCheck = useRef(true);

  useEffect(() => {
    const controls: Array<React.ReactNode> = contacts.map((sc) => (
      <SecondaryContact
        key={sc.id}
        contactData={sc}
        SetDirtyCallback={SetDirtyCallback}
        OnContactSave={OnContactSave}
        OnContactDelete={OnContactDelete}
      />
    ));
    setSecondaryContacts(controls);
    initialLoadCheck.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="Accord-Hide"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="Accord-heading"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">Additional Contacts</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.postBody}>
            <div>{secondaryContacts}</div>
            <div id="add-box" className={styles.btnBoxContact}>
              <Button variant="contained" className={styles.btn} onClick={AddSecondaryContact}>
                <AddCircleIcon className={styles.btnIcon} /> Add Additonal Contact
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>

  );
};

export default CompanySecondaryContactPost;
