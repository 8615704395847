// eslint-disable-next-line no-shadow
enum NotificationType { // eslint-disable-line no-unused-vars
  None = 0,// eslint-disable-line no-unused-vars
  App = 1,// eslint-disable-line no-unused-vars
  Email = 2,// eslint-disable-line no-unused-vars
  Both = 3,// eslint-disable-line no-unused-vars
}

export default NotificationType;

export const notificationToString = (value: NotificationType) => {
  switch (value) {
    case NotificationType.Both:
      return "Both"
    case NotificationType.App:
      return "App"
    case NotificationType.Email:
      return "Email"
    case NotificationType.None:
      return "None"
    default:
      return "Unknown";
  }
};