// eslint-disable-next-line no-shadow
enum BillCycle { // eslint-disable-line no-unused-vars
  Forever = 0,// eslint-disable-line no-unused-vars
  Onceoff = 1,// eslint-disable-line no-unused-vars
  Set = 2,// eslint-disable-line no-unused-vars
}

export default BillCycle;

export const BillCycleToString = (value: BillCycle) => {
  switch (value) {
    case BillCycle.Forever:
      return "Forever"
    case BillCycle.Onceoff:
      return "Once Off"
    default:
      return "Unknown";
  }
};