import { Box, Chip, Slide, Step, StepButton, Stepper, Typography, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AssignmentIcon from "@mui/icons-material/Assignment";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { platformHeadingsSubToString } from "../../util/enums/PlatformTabHeadings";
import VoucherTab from "../../components/Configuration/Subscriptions/Vouchers/VoucherTab";
import ProductView from "../../components/PlatformConfiguration/Subscriptions/ProductView";
import FeatureView from "../../components/PlatformConfiguration/Subscriptions/FeatureView";
import PlanView from "../../components/PlatformConfiguration/Subscriptions/PlanView";





interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const GetStepState = (index: number) => {

  if (index === 0) {
    return <InventoryIcon sx={{ color: "#1976d2" }} />
  }
  if (index === 1) {
    return <CategoryIcon sx={{ color: "#1976d2" }} />
  }
  if (index === 2) {
    return <AssignmentIcon sx={{ color: "#1976d2" }} />
  }
  if (index === 3) {
    return <LocalOfferIcon sx={{ color: "#1976d2" }} />
  }

  return <Chip label={index} sx={{ "& .MuiChip-label": { padding: 0 }, backgroundColor: "#1976d2", width: 25, height: 25, padding: 0, color: "white", marginLeft: "2px" }} />

}


const SubscriptionBase = () => {

  const theme = useTheme();
  const [value, setValue] = useState<number>(0);
  const [subHeading, setSubHeading] = useState("");


  const steps = [
    {
      id: 0,
      label: "Products",
      description: "Only able to toggle required State",
      timeout: 1600,
    },
    {
      id: 1,
      label: "Features",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
      timeout: 1800,
    },
    {
      id: 2,
      label: "Plans",
      description: "The System Permissions linked to Subscriptions and Fields",
      timeout: 2000,
    },
  ];


  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
    setSubHeading("");
  };


  const handleChangeIndex = (index: number) => {
    setValue(index);
  };



  const handleSetSubHeading = (newSub: string) => {
    setSubHeading(newSub);
  }



  return (

    <div className="config-header-box">
      <div className="config-sub-box">
        <div className="config-sub-box-left">
          <Stepper activeStep={value} orientation="vertical" sx={{ marginTop: "10px", marginBottom: "10px" }}>
            {steps.map((step) => (
              <Slide
                direction="right"
                in
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...({ timeout: step.timeout })}
              >
                <Step disabled={false} onClick={() => {
                  handleChangeTab(step.id)
                }}>
                  <StepButton
                    disabled={false}
                    className="stepButton"
                    sx={{ fontWeight: 900, borderRight: step.id === value ? "2px solid #435cd2" : "" }}
                    // eslint-disable-next-line no-nested-ternary
                    icon={GetStepState(step.id)}
                    key={step.id}>
                    {step.label}
                  </StepButton>
                </Step>
              </Slide>
            )
            )}
          </Stepper>
        </div>
        <div className="config-sub-box-right">
          <div style={{ width: "100%", height: "80px", paddingLeft: "20px", paddingTop: "10px" }}>
            <Typography>
              <h3 className="heading-sub">{platformHeadingsSubToString(value)}</h3>

              {subHeading.length > 0 &&
                <>
                  <div style={{ float: "left", marginLeft: "-5px", color: " #435cd2" }}>
                    <KeyboardArrowRightIcon />
                  </div>
                  <div style={{ float: "left" }}>
                    <Typography>
                      {subHeading}
                    </Typography>
                  </div>
                </>
              }
            </Typography>
          </div>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <ProductView handleSetSubHeading={handleSetSubHeading} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <FeatureView handleSetSubHeading={handleSetSubHeading} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <PlanView handleSetSubHeading={handleSetSubHeading} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <VoucherTab />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>

  )
};


export default SubscriptionBase;