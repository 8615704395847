import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetSectors } from "../../../http/Company/beeDetailsApi";
import { SectorModel } from "../../../http/Company/Models/SectorModel";

const useBeeSectors = () => {
  const query = useQuery({
    queryKey: [queryKeys.beeSectors],
    queryFn: async () => (await GetSectors()).data as Array<SectorModel>,
    staleTime: 600000,
  })

  return query;
}

export default useBeeSectors;