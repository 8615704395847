// eslint-disable-next-line no-shadow
enum PlatformHeadings { // eslint-disable-line no-unused-vars
  Fields = 0,// eslint-disable-line no-unused-vars
  Validations = 1,// eslint-disable-line no-unused-vars
  Permissions = 2,// eslint-disable-line no-unused-vars
}

// eslint-disable-next-line no-shadow
export enum PlatformHeadingsSub { // eslint-disable-line no-unused-vars
  Products = 0,// eslint-disable-line no-unused-vars
  Features = 1,// eslint-disable-line no-unused-vars
  Plans = 2,// eslint-disable-line no-unused-vars
  Vouchers = 3,// eslint-disable-line no-unused-vars


}

export default PlatformHeadings;

export const platformHeadingsToString = (value: PlatformHeadings) => {

  switch (value) {
    case PlatformHeadings.Fields:
      return "All Data Fields"
    case PlatformHeadings.Validations:
      return "All Field Validations"
    case PlatformHeadings.Permissions:
      return "Permissions"
    default:
      return "Unknown";
  }
};

export const platformHeadingsSubToString = (value: PlatformHeadingsSub) => {

  switch (value) {
    case PlatformHeadingsSub.Products:
      return "Products"
    case PlatformHeadingsSub.Features:
      return "Features"
    case PlatformHeadingsSub.Plans:
      return "Plans"
    case PlatformHeadingsSub.Vouchers:
      return "Vouchers"
    default:
      return "Unknown";
  }
};