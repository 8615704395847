import { protectedResources } from "../authConfig";
import makeGetRequestAsync, { makePatchFormRequestAsync, makePatchRequestAsync, makePostRequestAsync } from "../http/Helpers/httpRequestHelpers";
import { UserMessage } from "./CompanyUserManagement/Messages/UserMessage";
import { ProfileImageModel } from "./Redux/Models/ProfileImageModel";
import { SearchHistoryModel } from "./Redux/Models/SearchHistoryModel";
import { UserProfileModel } from "./Redux/Models/UserProfileModel";
import { UserRoleModel } from "./Redux/Models/UserRoleModel";


const apiScopes = protectedResources.userManagementWebApi.scopes;
const baseUrl = protectedResources.userManagementWebApi.endpoint;

export default {
  async getParticularProfileImage(accountId: string) {

    const url = `${baseUrl}/api/UserManagement/UserProfile/ProfileImage?accountId=${accountId}`;
    return makeGetRequestAsync<ProfileImageModel>(apiScopes, url);
  },
  async getParticularUserProfile(accountId: string) {

    const url = `${baseUrl}/api/UserManagement/UserProfile/User?accountId=${accountId}`;
    return makeGetRequestAsync<UserProfileModel>(apiScopes, url);
  },
  async uploadImage(accountId: string | undefined, formFile: FormData, oldProfileFileName: string) {

    const url = `${baseUrl}/api/UserManagement/UserProfile/UpdateProfileImage?accountId=${accountId}&oldProfileFileName=${oldProfileFileName}`;
    return (await (makePatchFormRequestAsync(apiScopes, url, formFile))).status;

  },
  async updateUser(userProfile: UserProfileModel) {
    const url = `${baseUrl}/api/UserManagement/UserProfile/Update`;
    return (await (makePatchRequestAsync(apiScopes, url, userProfile))).status;
  },
  async getUserRole(userId: number) {

    const url = `${baseUrl}/api/UserManagement/User/UserRole?userId=${userId}`;
    return makeGetRequestAsync<UserRoleModel>(apiScopes, url);
  },
  async getSearchHistory() {

    const url = `${baseUrl}/api/UserManagement/SearchHistory`;
    return makeGetRequestAsync<SearchHistoryModel[]>(apiScopes, url);
  },
  async AddSearchHistory(search: SearchHistoryModel) {

    const url = `${baseUrl}/api/UserManagement/SearchHistory`;
    return makePostRequestAsync(apiScopes, url, search);
  },
  async getAllCompanyUsers(companyId: number) {

    const url = `${baseUrl}/api/UserManagement/User/company/${companyId}`;
    return makeGetRequestAsync<UserMessage[]>(apiScopes, url);
  },

}