/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import MessageIcon from "@mui/icons-material/Message";
import { Badge, Box, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import Icon from "@mui/icons-material/Circle";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useBaseCompanyInfo from "../../util/hooks/queries/useBaseCompanyInfo";
import { NotificationModel } from "../../http/Notifications/Models/NotificationModel";
import { getUnreadNotifications } from "../../http/Redux/Store/NotificationsSlice";
import { fetchUnread } from "../../http/Redux/Store/NotificationActions";
import { logout } from "../../http/Redux/Store/userSlice";
import NotificationBar from "../../components/Dashboard/NotificationCards";


const NavigationHeader = () => {
  const { accounts, instance } = useMsal();
  const { data: currentCompany } = useBaseCompanyInfo();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const accountId = accounts[0].homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [anchorElCompany, setAnchorElCompany] = useState<null | HTMLElement>(null);
  const openCompany = Boolean(anchorElCompany);
  const [anchorElOffice, setAnchorElOffice] = useState<null | HTMLElement>(null);
  const openOffice = Boolean(anchorElOffice);
  const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null);
  const [anchorElMessaging, setAnchorElMessaging] = useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorElNotification);
  const openMessaging = Boolean(anchorElMessaging);
  const [anchorElLogout, setAnchorElLogout] = useState<null | HTMLElement>(null);
  const openLogout = Boolean(anchorElLogout);

  const unread = useAppSelector(getUnreadNotifications);
  const [unreadNotifications, setUnreadNotifications] = useState<Array<NotificationModel>>(
    [] as Array<NotificationModel>
  );


  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleClickMessage = (event: React.MouseEvent<HTMLElement>) => {
    // Functionality Still To Come Currently Messaging Will Only Redirect To Messaging (src\pages\ChatView.tsx)
    // eslint-disable-next-line no-console
    console.log("open Message Nav Box", event.currentTarget);
    // setAnchorElMessaging(event.currentTarget);
  };


  useEffect(() => {

    const refreshNotifications = async () => {

      dispatch(fetchUnread(accountId, false));
      setUnreadNotifications(unread);

    };
    refreshNotifications();

  }, [accountId, dispatch, unread.length]);


  const logoutOfApp = () => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    dispatch(logout());
    instance.logoutRedirect(logoutRequest);
  };

  const handleRefresh = (refresh: boolean) => {

    if (refresh) {
      const refreshNotifications = async () => {

        dispatch(fetchUnread(accountId, false));
        setUnreadNotifications(unread);


      };
      refreshNotifications();
    }
  }

  const handleClickOffice = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOffice(event.currentTarget);
  };


  const handleCloseOffice = () => {
    setAnchorElOffice(null);
  };

  const handleClickCompany = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCompany(event.currentTarget);
  };
  const handleCloseCompany = () => {
    setAnchorElCompany(null);
  };


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="top-navigation">
      <div className="logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="172.786" height="28.054" viewBox="0 0 172.786 28.054">
          <path id="B1LINK_logo_white" d="M40.123,7.014V28.054h9.092V0H33.559V7.014ZM13.941,20.96c2.06,0,3.111-.842,3.111-2.043v-.081c0-1.2-1.01-2.043-3.071-2.043H9.213V20.96Zm-.606-9.941c2.019,0,2.989-.678,2.989-1.922V9.017c0-1.24-1.009-1.922-3.029-1.922H9.213v3.926ZM0,0H15.838c3.921,0,6.384.881,8.042,2.525a5.973,5.973,0,0,1,1.737,4.367v.081c0,3.325-2.02,5.371-5.05,6.412,3.635,1.042,5.98,2.966,5.98,6.814v.079c0,4.49-3.717,7.776-10.868,7.776H0ZM172.786,28.054,158.7,14.083v-.112L172.785,0H161.357L150.751,10.52h-2.536V0h-9.09V28.054h9.091V17.534h2.536l10.606,10.52ZM77.408,21.041V0h-9.09V28.054H89.53V21.041ZM104.683,0H95.591V28.054h9.091Zm25.352,16.131V0H113.773Zm0,11.923H113.773V11.923Z" fill="#fff" />
        </svg>

      </div>
      <div className="menu-icon" onClick={() => toggleMobileMenu()}>
        <DensityMediumIcon />
      </div>
      <ul className={`nav-links ${isMobileMenuOpen ? "mobile" : ""}`}>
        <li><span onClick={() => { navigation("/home") }}>HOME</span></li>
        <li><span onClick={() => { navigation("/virtualmarketplace") }}>VIRTUAL MARKET PLACE</span></li>
        <li>
          <span
            aria-controls={openCompany ? "positioned-menu-company" : undefined}
            aria-haspopup="true"
            className={openCompany ? "focused-header" : ""}
            aria-expanded={openCompany ? "true" : undefined}
            onClick={handleClickCompany}>MY COMPANY</span>
          <Menu
            disableAutoFocusItem
            id="positioned-menu-company"
            aria-labelledby="positioned-button"
            anchorEl={anchorElCompany}
            open={openCompany}
            onClose={handleCloseCompany}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <MenuItem onClick={() => { navigation(`/viewprofile/${currentCompany?.companyId}`); setAnchorElCompany(null); }}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/audits"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Compliance</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/platformConfig"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Configuration</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/usermanagement"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Manage Users</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/mySubscriptions"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>My Subscriptions</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/compliance"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Audit</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/companyswitcher"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Register Another Company</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Invite</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { navigation("/accountPreferences"); setAnchorElCompany(null); }} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>My User</ListItemText>
            </MenuItem>
          </Menu>
        </li>
        <li>
          <span
            aria-controls={openOffice ? "positioned-menu-office" : undefined}
            aria-haspopup="true"
            className={openOffice ? "focused-header" : ""}
            aria-expanded={openOffice ? "true" : undefined}
            onClick={handleClickOffice}
          >MY OFFICE</span>
          <Menu
            id="positioned-menu-office"
            aria-labelledby="positioned-button"
            anchorEl={anchorElOffice}
            open={openOffice}
            onClose={handleCloseOffice}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={() => { navigation("/network"); setAnchorElOffice(null); }}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>My Connections</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>On-boarding</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText> Compliance Management</ListItemText>

            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>PP Report </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Supplier Updates</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("/adminDashboard")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Platform Admin</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("/agent/workcentre")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Agent Work Centre</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("/dataManagementPartner")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>Data Management Partner</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigation("")} disabled>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>ErFX</ListItemText>
            </MenuItem>
          </Menu>

        </li>
        <li><a style={{ textDecoration: "none", color: "#fff" }} href="https://b1sa.helpscoutdocs.com "> HELP & SUPPORT</a></li>
      </ul>
      <ul className="nav-links-outside">
        <li className="desktop-only">
          <Badge id="navigation-badge-message" badgeContent={0}
            color="error"
            aria-controls={openMessaging ? "positioned-menu-message" : undefined}
            aria-haspopup="true"
            className={openMessaging ? "focused-header" : ""}
            aria-expanded={openMessaging ? "true" : undefined}
            onClick={handleClickMessage}
          >
            <MessageIcon className="message-nav-icon" onClick={() => navigation("/messaging")} />
          </Badge>
        </li>
        <li className="desktop-only">
          <Badge id="navigation-badge" badgeContent={unreadNotifications.length}
            color="error"
            aria-controls={openNotification ? "positioned-menu-notification" : undefined}
            aria-haspopup="true"
            className={openNotification ? "focused-header" : ""}
            aria-expanded={openNotification ? "true" : undefined}
            onClick={handleClickNotification}
          >
            <NotificationsIcon className="notification-bell-icon" />
          </Badge>
          <Menu
            disableAutoFocusItem
            id="positioned-menu-notification"
            aria-labelledby="positioned-button"
            anchorEl={anchorElNotification}
            open={openNotification}
            onClose={handleCloseNotification}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >

            <Box p={2} sx={{ width: "60vh", height: "40vh", overflowY: "hidden" }}>
              <Box sx={{ overflowY: "scroll", height: "38vh" }}>
                <Typography variant="caption">Notifications</Typography>
                <Grid container>
                  {unreadNotifications.map((n) => (
                    <NotificationBar
                      displaySection="navigationBar"
                      notification={n}
                      handleRefresh={handleRefresh}
                      date={n.dateCreated as Date}
                      heading={n.entryName} />
                  ))}
                </Grid>
              </Box>
            </Box>
          </Menu>
        </li>
        <li className="desktop-only">
          <Box className="company-details-box">
            <Typography id="navigation-companyName">{currentCompany?.companyRegisteredName}</Typography>
            <Typography id="navigation-companyCategory">CORPORATE</Typography>
          </Box>
        </li>
        <li onClick={() => logoutOfApp()}>
          <span>LOGOUT</span>
        </li>
      </ul>
    </nav >
  );
};


export default NavigationHeader;

