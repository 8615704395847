/* eslint-disable react/require-default-props */

import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import HandymanIcon from "@mui/icons-material/Handyman";

interface RuleResultRowProps {
  type: string;
  name: string;
  linkFunction: () => void;
  isFail?: boolean;
  isDoc?: boolean;
}

const RuleResultRow = ({
  type,
  name,
  linkFunction,
  isFail = false,
  isDoc = false,
}: RuleResultRowProps) => (
  <Grid container>
    <Grid item xs={2} sx={{ color: isFail ? "red" : "green", textAlign: "center" }}>
      {isFail ? <CancelIcon /> : <DoneIcon />}
    </Grid>
    <Grid item xs={3}>
      <Typography variant="subtitle1">{type}</Typography>
    </Grid>
    <Grid item xs={isFail ? 3 : 7}>
      <Typography variant="subtitle1">{name}</Typography>
    </Grid>
    {isFail && (
      <Grid item xs={4}>
        <Tooltip title={isDoc ? "Click here to upload" : "Click here to add"}>
          <IconButton>
            <HandymanIcon onClick={linkFunction} />
          </IconButton>
        </Tooltip>
      </Grid>
    )}
  </Grid>
);

export default RuleResultRow;
