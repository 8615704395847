
import "./CompanyPost.css";
import VerificationRequest from "../../WorkCenter/Verification/VerificationRequest";


const CompanyHeadingPost = ({
  status = 0,
  companyId,
  clientId,
  staged,
  workcentreAgentPath,
  agentPathId,
}: {
  status: number;
  companyId: number,
  // eslint-disable-next-line react/require-default-props
  clientId?: number,
  staged: boolean,
  workcentreAgentPath: boolean,
  agentPathId: number,
}) => (


  <VerificationRequest status={status} companyId={companyId} staged={staged} workcentreAgentPath={workcentreAgentPath} agentPathId={agentPathId} clientId={clientId} />

);

export default CompanyHeadingPost;
