import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makeDeleteRequestNoBodyAsync, makePatchRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { UserCompanyModel } from "../Redux/Models/UserCompanyModel";
import { BaseCompanyInfo } from "./Models/BaseCompanyInfo";
import { BasicUser } from "./Models/BasicUserModel";
import { CompanyBeeGroupEditModel } from "./Models/CompanyBeeGroupEditModel";
import { CompanyViewModel } from "./Models/CompanyViewModel";
import { SupplierInviteModel } from "./Models/SupplierInviteModel";
import { ProfileShares } from "../Redux/Models/ProfileSharesModel";
import { CompanyProfileCompleteSummaryMessage } from "./Messages/companyProfileCompletionSummaryMessage";
import { CompanyRegistrationNumberModel } from "./Models/CompanyRegistrationNumberModel";
import { CompanyDuplicateCheckResponse } from "./Models/CompanyDuplicateCheckResponse";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves all companies stored in the database.
 */
export async function GetAllCompanies() {
  const url = `${baseUrl}/api/Company/Companies`;
  return makeGetRequestAsync<CompanyViewModel[]>(apiScopes, url);
}

/*
*  Retrieves all basic metadata
*/
export async function GetSomeBasicCompanyInfoAsync(searchTerm: string) {
  const url = `${baseUrl}/api/Company/GetSomeBasicCompanyInfoAsync/${searchTerm}`;
  return makeGetRequestAsync<BaseCompanyInfo[]>(apiScopes, url);
}


/*
 *  Retrieves a specific company based on a passed company ID
 */


export async function GetCompanyDataById(companyId: string | number, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyWithAdminById/${companyId}/${staged}`;
  return makeGetRequestAsync<CompanyViewModel>(apiScopes, url);
}



export async function GetCompanyDataByRegistration(registration: string, staged: boolean) {
  const url = `${baseUrl}/api/Company/CompanyByRegistration/${registration}/${staged}`;
  return makeGetRequestAsync<CompanyViewModel>(apiScopes, url);
}


/*
 *  Retrieves a specific company based on a passed user ID
 */
export const GetCompanyDataByUserId = async (userId: string) => {
  const url = `${baseUrl}/api/Company/CompanyWithUserId/${userId}`;
  return (
    makeGetRequestAsync<CompanyViewModel>(apiScopes, url)
  );
}

/*
 *  Updates company data
 */
export async function SaveCompanyChanges(accessToken: string, company: CompanyViewModel, staged: boolean) {
  const bearer = `Bearer ${accessToken}`

  const config: AxiosRequestConfig = {
    headers: {
      "Authorization": `${bearer}`,
      "Content-Type": "application/json",
    },
  }


  return axios.patch(`${baseUrl}/api/Company/UpdateCompany/${staged}`, company, config) as Promise<void>
}

/*
 *  Updates company data
 */
export async function SaveRegistrationCompanyChanges(company: CompanyViewModel) {
  await makePatchRequestAsync(apiScopes, `${baseUrl}/api/Company/UpdateCompany/${false}`, company);
}

/*
 *  Updates ENTIRE company data (including contacts, addresses, B-BBEE, bank accounts, and Products & Services);
 */
export async function SaveEntireCompany(company: CompanyViewModel, staged: boolean) {

  const url = `${baseUrl}/api/Company/UpdateEntireCompany/${staged}`;
  return makePatchRequestAsync(apiScopes, url, company);
}

/*
 * Retrieve companies associated to a user
 */
export async function GetUserCompanies() {
  const url = `${baseUrl}/api/Company/GetUserCompanies`;

  return (
    (await makeGetRequestAsync<Array<UserCompanyModel>>(apiScopes, url)).data
  );
}

/*
 * Sets user default company
 */
export async function SetDefaultUserCompany(accessToken: string, companyId: string, userId: string) {
  const bearer = `Bearer ${accessToken}`

  const config: AxiosRequestConfig = {
    headers: {
      "Authorization": `${bearer}`,
      "Content-Type": "application/json",
    },
  }

  const companyToChangeToDefault = {
    "companyId": companyId,
    "userId": userId,
  }

  return axios.patch(`${baseUrl}/api/Company/SetUserDefaultCompany`, companyToChangeToDefault, config) as Promise<void>
}

/*
 * Retrieves base company information with a user's AD ID.
 */
export async function GetBaseCompanyInfoViaUserId(userAdId: string) {
  const url = `${baseUrl}/api/Company/GetBaseCompanyInfoViaUserId/${userAdId}`;

  return (
    (await makeGetRequestAsync<BaseCompanyInfo>(apiScopes, url)).data
  );
}

/*
 * Retrieves base company information with a company ID.
 */

export async function GetBaseCompanyInfoViaCompanyId(companyId: string | number, staged: boolean) {
  const url = `${baseUrl}/api/Company/GetBaseCompanyInfoViaCompanyId/${companyId}/${staged}`;

  return (
    (await makeGetRequestAsync<BaseCompanyInfo>(apiScopes, url)).data
  );
}

/*
 * Retrieves the company admin user ID for the passed in company ID.
 */
export async function GetCompanyAdminUserId(companyId: number) {
  const url = `${baseUrl}/api/Company/GetCompanyAdminDetails/${companyId}`;

  try {
    const result = await makeGetRequestAsync<BasicUser>(apiScopes, url);
    return result.data.id;

  } catch (error) {
    if (error instanceof AxiosError) {
      if (error?.response?.status === 404) return "404";

      return "500";
    }
  }
}

export async function GetCompanyAdminUserInfo(companyId: number) {
  const url = `${baseUrl}/api/Company/GetCompanyAdminDetails/${companyId}`;

  const result = await makeGetRequestAsync<BasicUser>(apiScopes, url);
  return result;
}


/*
 * Sends an invitation email to a new supplier
 */
export async function SendNewSupplierInvite(supplierInvite: SupplierInviteModel) {
  const url = `${baseUrl}/api/Company/CompanyUser/SendSupplierInviteEmail`;
  return (await makePostRequestAsync(apiScopes, url, supplierInvite)).status;
}

export async function GetCompanyBeeGroup(companyId: number) {
  const url = `${baseUrl}/api/Company/CompanyBeeGroup/GetCompanyGrouping/${companyId}`;
  return (await makeGetRequestAsync(apiScopes, url)).data;
}
export async function AddCompanyBeeGroup(group: CompanyBeeGroupEditModel) {
  const url = `${baseUrl}/api/Company/CompanyBeeGroup`;
  return (await makePostRequestAsync(apiScopes, url, group)).status;
}
export async function UpdateCompanyBeeGroup(group: CompanyBeeGroupEditModel) {
  const url = `${baseUrl}/api/Company/CompanyBeeGroup`;
  return (await makePatchRequestAsync(apiScopes, url, group)).status;
}


export async function AddSharedProfile(share: ProfileShares, companyName: string) {
  const url = `${baseUrl}/api/CompanyPublicProfileShares/CompanyPublicProfileShare/ProfileShare?company=${companyName}`;

  return (
    (await makePostRequestAsync(apiScopes, url, share)).data
  );
}

/*
 * Remove my company from the platfrom
 */
export async function RemoveMyCompanyFromThePlatform() {
  const url = `${baseUrl}/api/Company/RemoveMyCompanyEntirely`;
  return (
    makeDeleteRequestNoBodyAsync(apiScopes, url)
  );
}

/*
 * Retreive company profile completion summary
 */
export async function GetCurrentCompanyProfileCompletionSummary() {
  const url = `${baseUrl}/api/Company/ProfileCompletion`;
  return (
    (await makeGetRequestAsync<CompanyProfileCompleteSummaryMessage>(apiScopes, url)).data
  );
}


export async function CheckIfCompanyExistsInDirectory(companyRegNo: string, companyTypeId?: number) {
  let url: string;
  const encodedCompanyRegNo = encodeURIComponent(companyRegNo)
  if (!companyTypeId)
    url = `${baseUrl}/api/Company/CheckIfCompanyExistsInDirectory/${encodedCompanyRegNo}/0`;
  else {
    url = `${baseUrl}/api/Company/CheckIfCompanyExistsInDirectory/${encodedCompanyRegNo}/${companyTypeId}`;
  }

  const result = await makeGetRequestAsync<number>(apiScopes, url);

  if (result.status !== 200)
    return undefined;

  return result.data;
}

export const checkDuplicateRegistrationNumber = async (company: CompanyRegistrationNumberModel) => {
  const url = `${baseUrl}/api/Company/CompanyDuplicateCheckByRegistrationNumber`;
  const response = await makePostRequestAsync<CompanyDuplicateCheckResponse, CompanyRegistrationNumberModel>(apiScopes, url, company);
  return response.data;
};