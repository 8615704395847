// eslint-disable-next-line no-shadow
enum AccountStatus { // eslint-disable-line no-unused-vars
  Active = 0,// eslint-disable-line no-unused-vars
  Deactivated = 1,// eslint-disable-line no-unused-vars
  Suspended = 2,// eslint-disable-line no-unused-vars
}

export default AccountStatus;

export const accountStatusToString = (value: AccountStatus) => {
  switch (value) {
    case AccountStatus.Active:
      return "Active"
    case AccountStatus.Deactivated:
      return "Deactivated"
    case AccountStatus.Suspended:
      return "Suspended"
    default:
      return "Unknown";
  }
};
