import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { BeeDetailsModel } from "../../../http/Company/Models/BeeDetailsModel";
import { GetMyCompanyBeeDetails } from "../../../http/Company/beeDetailsApi";

const useCompanyBeeDetails = () => {
  const query = useQuery({
    queryKey: [queryKeys.companyBeeDetails],
    queryFn: async () => (await GetMyCompanyBeeDetails()).data as BeeDetailsModel,
    staleTime: 600000,
  })

  return query;
}

export default useCompanyBeeDetails;