import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
// import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAccount, useMsal } from "@azure/msal-react";
import { permissions } from "../../../http/Configuration/Permissions/Permissions";
import AddPermission from "../../../http/Configuration/Permissions/addPermission";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";


const AddNewPermission = ({ backToView }: { backToView: () => void }) => {
  const [editable, setEditable] = useState<permissions>({} as permissions);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const handleSwitchOnClick = () => {
    if (isActive) {
      setIsActive(false);
    }
    else {
      setIsActive(true);
    }
  };
  const handleClickSave = (edit: permissions) => {
    setLoading(true);
    const modifiedValidation: permissions = edit;
    modifiedValidation.dateCreated = new Date();
    modifiedValidation.createdBy = userAccount as string;
    modifiedValidation.dateModified = new Date();
    modifiedValidation.modifiedBy = userAccount as string;

    const loader = async () => {
      await AddPermission(modifiedValidation).finally(() => {
        setLoading(false);
      });
    };
    loader();
  };


  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Field Validation Table">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                value={editable.name || ""}
                onChange={(ev) => { setEditable({ ...editable, name: ev.target.value }); }}
                id="name"
                sx={{ width: "98%" }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Permission Name"
                size="small"
                type="text"
                variant="outlined" />
            </Grid>
            <Grid xs={6}>
              <TextField
                value={editable.featureName || ""}
                onChange={(ev) => { setEditable({ ...editable, featureName: ev.target.value }); }}
                id="pattern"
                InputLabelProps={{ style: { fontSize: 14 } }}
                label="Feature"
                multiline
                size="small"
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={10}>
              <TextField
                value={editable.description || ""}
                onChange={(ev) => { setEditable({ ...editable, description: ev.target.value }); }}
                id="message"
                label="Description"
                size="small"
                type="text"
                multiline
                sx={{ width: "98%" }}
                variant="outlined" />
            </Grid>
            <Grid xs={2}>
              <FormControlLabel control={<Checkbox checked={isActive} onClick={handleSwitchOnClick} />} label="Is Active" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" className="form-add-btn" onClick={() => { handleClickSave(editable); }}>Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default AddNewPermission;