import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BaseSubscriptionInfoModel } from "../../SubscriptionManagement/Models/BaseSubscriptionInfoModel";
import { getBaseSubscriptionInfo } from "../../SubscriptionManagement/SubscriptionApi";
import baseSubscriptionInfoSlice from "./BaseSubscriptionInfoSlice";

export const BaseSubscriptionInfoActions = baseSubscriptionInfoSlice.actions;

export const fetchBaseSubscriptionInfo = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: BaseSubscriptionInfoModel = (await getBaseSubscriptionInfo());
  dispatch(BaseSubscriptionInfoActions.setBaseSubscriptionInfoState(apiResponse));
}