import { Button, Card, Grid, Typography } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router";
import DashboardStatsCard from "./DashboardComponents/DashboardStatsCard";
import ConnectionType from "../../util/enums/ConnectionType";
import AddNewConnectionButton from "./AddNewConnectionButton";
import { responsiveFontSizes } from "../../util/MUI_Theme/Theme";

const DashboardSupplierBuyerView = () => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} py={2}>
      <Grid container xs={12} spacing={2} alignItems="center" justifyContent="space-between" py={6}>
        <Grid item>
          <Typography sx={{ ...responsiveFontSizes({ lg: 35, md: 32, sm: 28 }) }}>MY CONNECTIONS</Typography>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Grid item>
            <AddNewConnectionButton connectionType={ConnectionType.Client}>
          INVITE NEW CLIENT
            </AddNewConnectionButton>
          </Grid>
          <Grid item>
            <AddNewConnectionButton connectionType={ConnectionType.Supplier}>
          INVITE NEW SUPPLIER
            </AddNewConnectionButton>
          </Grid>
          <Grid item>
            <Button
              onClick={() => navigate("/virtualmarketplace")}
              startIcon={<SearchIcon fontSize="large" />}
              variant="contained"
            >
              <Typography variant="button" p={2.5}>
            VIEW MARKETPLACE
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Card>
        <DashboardStatsCard />
      </Card>
    </Grid>
  )
};

export default DashboardSupplierBuyerView;