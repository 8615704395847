import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton, MobileStepper } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { PopupQuestionsModel } from "../../http/Redux/Models/PopupQuestionsModel";
import UserPromptActivityService from "../../http/UserPromptActivityService";
import styles from "../Tours/Tour.module.scss";
import DialogBox from "./Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserPromptActivity } from "../../http/Redux/Store/UserPromptActivitySlice";
import { UserPromptActivityModel } from "../../http/Redux/Models/UserPromptActivityModel";
import LoadingBackdrop from "../Backdrop/LoadingBackdrop";
import { fetchUserPromptActivity } from "../../http/Redux/Store/UserPromptActivityActions";
import { UserPopupActivityModel } from "../../http/Redux/Models/UserPopupActivityModel";
import ActivityStatus from "../../util/enums/ActivityStatus";

const Popup = ({ page, call, id }: { page: string, call: boolean, id: number }) => {
  const theme = useTheme();
  const [popups, setPopups] = useState<PopupQuestionsModel[]>([] as PopupQuestionsModel[]);
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const prompts = useAppSelector(getUserPromptActivity);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");
  const companyId = account?.idTokenClaims?.extension_companyid as number;
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [called, setCalled] = useState(false);
  const dispatch = useAppDispatch();
  const [popupId, setPopupId] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  useEffect(() => {

    if (popups.length === 0 && page !== "" && !called) {
      const loader = async () => {
        const result = await UserPromptActivityService.getAllPopupQuestions(userAccount as string);
        setPopups(result.filter((x) => x.page === page));
        setMaxSteps(result.filter((x) => x.page === page).length);
        setPopupId(result.find((x) => x.page === page)?.id as number);
      };
      loader();
    }
    if (call && !called) {
      setDialogOpen(true);
    }
    else {
      setDialogOpen(false);
    }

  }, [activeStep, call, page, popups.length, prompts.length, dialogOpen]);

  const handleCloseClick = () => {

    setIsLoadingSave(true);
    const prompt = prompts.find(x => x.id === id);
    const loaderPrompt = async () => {
      const newModel: UserPromptActivityModel = {
        id: prompt?.id,
        accountId: prompt?.accountId,
        companyId: prompt?.companyId,
        popupPromptId: prompt?.popupPromptId,
        status: ActivityStatus.Closed,
        dateCreated: prompt?.dateCreated,
        dateModified: new Date(),
      };
      const popupModel: UserPopupActivityModel = {
        id: popupId,
        seen: true,
      }
      await UserPromptActivityService.updateUserPopupActivity(popupModel);
      await UserPromptActivityService.updateUserPromptActivity(newModel).finally(() => {
        dispatch(fetchUserPromptActivity(userAccount as string, companyId));
        setIsLoadingSave(false);
        setCalled(true);
        setDialogOpen(false);
      });
    };

    loaderPrompt();
  };

  return (
    <>
      <LoadingBackdrop showBackdrop={isLoadingSave} />
      <Dialog open={dialogOpen} sx={{ padding: "10px" }}>
        <Box sx={{ textAlign: "right" }}>
          <IconButton sx={{ width: "20px", marginRight: "30px" }} onClick={() => handleCloseClick()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: "640px", width: "500px", padding: "10px" }}>
          <Box className={styles.carouselContent}>
            {popups[activeStep] ? <DialogBox handleClose={handleCloseClick} data={popups[activeStep]} /> : ""}
          </Box>
        </Box>
        <MobileStepper
          variant="progress"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 500, flexGrow: 1 }}
          nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>}
          backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>} />
      </Dialog>
    </>
  );
};

export default Popup;
