/* eslint-disable no-unused-vars */

import { Grid } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { MyConnections, MyConnectionsTabsToSearchTypes } from "../../../util/enums/MyConnectionsTab";
import OrderByType from "../../../util/enums/OrderByType";
import { CompanyNetworkConnections } from "../../../http/NetworkAndConnect/Models/CompanyNetworkConnections";
import { ConnectionSearchModel } from "../../../http/NetworkAndConnect/Models/ConnectionSearchModel";
import { OrderByModel } from "../../../http/NetworkAndConnect/Models/OrderByModel";
import { SearchModel } from "../../../http/NetworkAndConnect/Models/SearchModel";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import { PerformNetworkSearch } from "../../../http/NetworkAndConnect/Netconn";
import LoadingBackdrop from "../../../components/Backdrop/LoadingBackdrop";
import NetconnSearchType from "../../../util/enums/NetconnSearchType";
import ConnectionRequestService from "../../../http/ConnectionRequestService";
import ConnectionSearchBy from "./ConnectionSearchBy";
import { formattedResultsRequests } from "./ConnectionProps";
import ConnectionsTable from "./ConnectionTable";

interface ContainerProps {
  tab: string,
}


const ConnectionTab = ({ tab }: ContainerProps) => {
  const navigate = useNavigate();
  const [searchModelList, setSearchModelList] = useState<SearchModel[]>([] as SearchModel[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<Array<number>>([] as Array<number>);
  const [networkData] = useState<CompanyNetworkConnections>({} as CompanyNetworkConnections);
  const { data: baseCompanyInfo } = useBaseCompanyInfo();
  const numberOfPages = Math.round(totalRecords / numberOfRecordsToShow);




  // Pagination/Table state:
  const [selectedOrder, setSelectedOrder] = useState<OrderByModel>({
    fieldName: "RegisteredName",
    orderByDirection: OrderByType.Ascending,
  } as OrderByModel);



  const setNumberOfPages = useCallback(() => {
    const calc = Math.round(totalRecords / numberOfRecordsToShow);
    return calc;
  }, [numberOfRecordsToShow, totalRecords]);

  const handleOrderChange = (newSortOrder: string) => {
    const newSortDirection: OrderByType = newSortOrder.toLowerCase() === "a-z" ? OrderByType.Ascending : OrderByType.Descending;
    setSelectedOrder(
      { ...selectedOrder, orderByDirection: newSortDirection }
    );
  };

  const selectSpecificRecord = (recordCompanyId: number | null) => {
    if (recordCompanyId === null) return;

    if (!selectedRecords.includes(recordCompanyId)) {
      setSelectedRecords([...selectedRecords, recordCompanyId]);
      return;
    }

    const filteredValues = selectedRecords.filter((record) => record !== recordCompanyId);
    setSelectedRecords(filteredValues)
  }

  const selectAllRecords = (selectedCompanyIds: Array<number>) => {
    if (selectedCompanyIds.length < searchModelList.length) {
      const allCompanies = searchModelList.map((model) => model.companyId);
      setSelectedRecords(allCompanies as Array<number>);
      return;
    }

    setSelectedRecords([]);
  }

  const handleSelectRecordsChange = (newRowCount: number) => {
    setNumberOfRecordsToShow(newRowCount);
  };

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleNavigationToViewProfile = (companyId: number) => {
    navigate(`/viewprofile/${companyId}`);
  }


  const executeSearch = useCallback(
    async (searchTextValue = "") => {
      setIsLoading(true);

      const searchModel: ConnectionSearchModel = {
        companyId: baseCompanyInfo?.companyId as number,
        searchQuery: searchTextValue,
        searchPageNumber: currentPage - 1,
        searchRowNumber: numberOfRecordsToShow,
        searchOrderBys: [selectedOrder],
        vendorNumber: "",
        excludeFromSpend: false,
        myRegisteredName: "",
        myTradingAs: "",
        myIntegrationNumber: "",
        mySupplierNotes: "",
        partnerManagedSupplier: false,
        procureCheckVendorCreationStatus: 0,
      };

      try {
        if (MyConnectionsTabsToSearchTypes(tab as MyConnections) !== NetconnSearchType.RequestReceived) {
          const results = await PerformNetworkSearch(searchModel, MyConnectionsTabsToSearchTypes(tab as MyConnections));

          setSearchModelList(results.searchResults);
          setTotalRecords(results.searchResults.length > 0 ? results?.searchResults[0].totalRows : 0);
          setNumberOfRecordsToShow(searchModel.searchRowNumber as number);
          return;
        }

        const results = await ConnectionRequestService.getViewConnectionRequestsForCompany(baseCompanyInfo?.companyId as number);
        const formattedResults: SearchModel[] = formattedResultsRequests(results);

        setSearchModelList(formattedResults);
        setTotalRecords(formattedResults.length > 0 ? formattedResults[0].totalRows : 0);
        setNumberOfRecordsToShow(searchModel.searchRowNumber as number);
      }
      catch (error) {
        Swal.fire("Error", "Something went wrong fetching data. Please try again.", "error");
      }
      finally {
        setIsLoading(false);
      }
    },
    [baseCompanyInfo?.companyId, currentPage, tab, numberOfRecordsToShow, selectedOrder]
  );


  useEffect(() => {
    executeSearch();
  }, [executeSearch, tab]);

  useEffect(() => {
    setNumberOfPages();
  }, [searchModelList.length, selectedRecords.length, setNumberOfPages, tab]);




  return (
    <Grid container pb={2}>
      <Grid xs={12}>
        <LoadingBackdrop showBackdrop={isLoading} />
      </Grid>
      <Grid xs={12}>
        <ConnectionSearchBy executeSearch={executeSearch} />
      </Grid>
      <Grid container sx={{ marginTop: 3 }} spacing={2}>
        <ConnectionsTable
          tableSearchType={MyConnectionsTabsToSearchTypes(tab as MyConnections)}
          menuProps={undefined}
          totalRecordCount={totalRecords}
          dataSet={searchModelList}
          currentPage={currentPage}
          numberOfRows={numberOfRecordsToShow}
          numberOfPages={numberOfPages}
          selectedRows={selectedRecords}
          changeMainTableSort={handleOrderChange}
          changeCurrentPage={handlePageChange}
          changePageRowNumber={handleSelectRecordsChange}
          selectSpecificRow={selectSpecificRecord}
          selectAllRows={selectAllRecords}
          navigateToProfile={handleNavigationToViewProfile}
          networkConnections={networkData}
        />
      </Grid>
    </Grid>

  )
};


export default ConnectionTab;