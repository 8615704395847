import { useMutation, useQueryClient } from "@tanstack/react-query"
import queryKeys from "../queries/queryKeys";
import {
  AddCompanyProfileSettings,
  DeleteCompanyProfileSettings,
  UpdateCompanyProfileSettings,
} from "../../../http/Company/CompanyProfileSettingsApi";
import { CompanyProfileSettings } from "../../../http/Company/Models/CompanyProfileSettings";
import { ComplianceMutatorProps } from "./Interfaces/ComplianceMutatorProps";


const useCompanyProfileSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({mutationMode, mutationDetails} : ComplianceMutatorProps) => {
      const cpsObject = mutationDetails as CompanyProfileSettings;

      if (mutationMode === "add") {
        await AddCompanyProfileSettings(cpsObject);
        return;
      }

      if (mutationMode === "update") {
        await UpdateCompanyProfileSettings(cpsObject);
        return;
      }

      await DeleteCompanyProfileSettings(cpsObject.id);
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKeys.companyProfileSettings] });

      // Snapshot the previous value
      const currentCompanyProfileSettings = queryClient.getQueryData([queryKeys.companyProfileSettings]);

      // Return a context object with the snapshotted value
      return { currentCompanyProfileSettings }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKeys.companyProfileSettings], context?.currentCompanyProfileSettings);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.companyProfileSettings] });
    },
  })
}

export default useCompanyProfileSettingsMutation;