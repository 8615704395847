import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetBeeLevels } from "../../Company/beeDetailsApi";
import { SectorModel } from "../../Company/Models/SectorModel";
import beeLevelListSlice from "./BeeLevelListSlice";

export const beeLevelListActions = beeLevelListSlice.actions;

export const fetchBeeLevels = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<SectorModel> = (await GetBeeLevels()).data;
  dispatch(beeLevelListActions.setBeeLevelListState(apiResponse));
}