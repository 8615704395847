import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePUTRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { BbbeeClassificationCount } from "./Models/BbbeeClassificationCount";
import { BeeDetailsModel } from "./Models/BeeDetailsModel";
import { BeeLevelModel } from "./Models/BeeLevelModel";
import { CompanyBeeRatingStatusModel } from "./Models/CompanyBeeRatingStatusModel";
import { SectorModel } from "./Models/SectorModel";
import { TurnoverModel } from "./Models/TurnoverModel";

const baseUrl = protectedResources.companyApi.endpoint;
const apiScopes = protectedResources.companyApi.scopes;

export const SaveBeeDetailChanges = (beeDetails: BeeDetailsModel, staged: boolean) => {
  const url = `${baseUrl}/api/Company/Bee/UpdateBeeDetails/${staged}`;
  return makePostRequestAsync<void, BeeDetailsModel>(apiScopes, url, beeDetails);
}

export async function AddNewBeeDetails(beeDetails: BeeDetailsModel, staged: boolean) {
  const url = `${baseUrl}/api/Company/Bee/AddBeeDetails/${staged}`;
  return makePostRequestAsync<string, BeeDetailsModel>(apiScopes, url, beeDetails);
}

export async function GetSectors() {
  const url = `${baseUrl}/api/Company/Bee/GetSectors`;
  return makeGetRequestAsync<Array<SectorModel>>(apiScopes, url);
}

export async function GetBeeLevels() {
  const url = `${baseUrl}/api/Company/Bee/GetBeeLevels`;
  return makeGetRequestAsync<Array<BeeLevelModel>>(apiScopes, url);
}

export async function GetSectorTurnoverBands(sectorId: number) {
  const url = `${baseUrl}/api/Company/Bee/GetSectorTurnoverBands/${sectorId}`;
  return makeGetRequestAsync<Array<TurnoverModel>>(apiScopes, url);
}

export async function GetAllTurnoverBands() {
  const url = `${baseUrl}/api/Company/Bee/GetAllTurnoverBands`;
  return makeGetRequestAsync<Array<TurnoverModel>>(apiScopes, url);
}

export async function GetValidBeeLevels(sectorId: number, turnoverBandId: number, boPercentage: number) {
  const url = `${baseUrl}/api/Company/Bee/GetValidBeeLevels/${sectorId}/${turnoverBandId}/${boPercentage}`;
  return (await makeGetRequestAsync<Array<number>>(apiScopes, url)).data;
}

export const GetCompanyBeeDetails = async (companyId: number, staged:boolean) => {
  const url = `${baseUrl}/api/Company/Bee/GetBeeDetailsForCompany/${companyId}/${staged}`;
  return (await makeGetRequestAsync<BeeDetailsModel>(apiScopes, url)).data;
}

export const GetMyCompanyBeeDetails = async () => {
  const url = `${baseUrl}/api/Company/Bee/GetMyBeeDetailsForCompany`;
  const result = await makeGetRequestAsync<BeeDetailsModel>(apiScopes, url);
  return result;
}

export const GetCompanyBeeRatingStatus = async (companyId: number) => {
  const url = `${baseUrl}/api/Company/Bee/GetCompanyBeeRatingStatus/${companyId}`;
  return (await makeGetRequestAsync<CompanyBeeRatingStatusModel>(apiScopes, url)).data;
}

export const SaveCompanyBeeRatingStatus = async (modelToSave: CompanyBeeRatingStatusModel) => {
  const url = `${baseUrl}/api/Company/Bee/SaveCompanyBeeRatingStatus`;
  await makePUTRequestAsync(apiScopes, url, modelToSave);
}

export const GetCompanySupplierBeeClassficationStats = async () => {
  const url = `${baseUrl}/api/Company/Bee/GetCompanySupplierBeeClassificationCounts`;
  return (await makeGetRequestAsync<Array<BbbeeClassificationCount>>(apiScopes, url)).data;
}

export const DeleteBeeDetails = async (beeDetailsToDelete: BeeDetailsModel, isStaged = false) => {
  const url = `${baseUrl}/api/Company/Bee/DeleteBeeDetails/${isStaged}`;
  await makePostRequestAsync(apiScopes, url, beeDetailsToDelete);
}