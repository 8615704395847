import { useState } from "react";

import {
  Card,
} from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import Chat from "../components/Chat/Chat";
import SendNewMessage from "../components/Chat/SendNewMessage";
import BasePage from "./NewUI/BasePage";
import ButtonGroup from "./Registration/Sections/ButtonGroup";
import queryKeys from "../util/hooks/queries/queryKeys";

const ChatView = () => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [refreshChatView, setRefreshChatView] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleRefresh = () => {
    setRefreshChatView(true);
    const load = async () => {
      queryClient.refetchQueries({ queryKey: [queryKeys.messaging] });
    }
    load();
  }


  return (
    <BasePage>
      <div className="base-page-heading-box">
        <Card className="base-page-heading-card">
          <h1>Messaging</h1>
          <h3>Start a conversation with a company.</h3>
          <br />
          <ButtonGroup
            buttons={[
              {
                label: "Start A Conversation",
                align: "right",
                disabled: showModal,
                type: "outlined",
                onClick: handleOpen,
              },
            ]}
          />

        </Card>
        <Card className="base-page-chat-card">
          <Chat refreshChatView={refreshChatView} />
        </Card>
      </div>
      <SendNewMessage open={showModal} handleClose={handleClose} handleRefresh={handleRefresh} />
    </BasePage>
  );
}

export default ChatView;
