import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DocumentDtoModel } from "../../../../http/DocumentManagement/Models/DocumentDtoModel";

interface DeleteDocumentDialogProps {
  open: boolean;
  documentToDelete: DocumentDtoModel;

  // eslint-disable-next-line no-unused-vars
  setOpen: (display: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  setDocumentToDelete: (doc: DocumentDtoModel) => void;

  confirmCallback:() => void;
}

const DeleteDocumentDialog = (props: DeleteDocumentDialogProps) => {
  const {open, setOpen, documentToDelete, setDocumentToDelete, confirmCallback} = props;

  return (
    <Dialog open={open}>
      <DialogTitle>Delete file?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1">
            Are you sure you want to delete this file?
          </Typography>
          <Grid container sx={{ flexDirection: "column" }}>
            <Grid item xs={12}>
              <Typography component="div">
                <Box component="span" fontWeight="fontWeightBold">Document Type:</Box>
              </Typography>
              <Typography>{documentToDelete?.documentTypeName || ""}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight="fontWeightBold">File Name:</Box>
              </Typography>
              <Typography>{documentToDelete?.fileName || ""}</Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => { setDocumentToDelete({} as DocumentDtoModel); setOpen(false); }}>
          No
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            confirmCallback();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDocumentDialog;
