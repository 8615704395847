/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum NotificationEntity {
  None = 0,
  General = 1,
  Info = 2,
  Alert = 3,
  NetConn_Follow = 4,
  NetConn_Connected = 5,
  NetConn_Disconnected = 6,
  NetConn_Unfollowed = 7,
  NetConn_NewConnection = 8,
  Error = 9,
  NetConn_Connected_Supplier = 10,
  NetConn_Connected_Client = 11,
  NetConn_Connected_User = 12
}

export default NotificationEntity;

export const notificationEntityToString = (value: NotificationEntity) => {
  switch (value) {
    case NotificationEntity.None:
      return "None";
    case NotificationEntity.General:
      return "General";
    case NotificationEntity.Info:
      return "Info";
    case NotificationEntity.Alert:
      return "Alert";
    case NotificationEntity.NetConn_Follow:
      return "NetConn_Follow";
    case NotificationEntity.NetConn_Connected:
      return "NetConn_Connected";
    case NotificationEntity.NetConn_Disconnected:
      return "NetConn_Disconnected";
    case NotificationEntity.NetConn_Unfollowed:
      return "NetConn_Unfollowed";
    case NotificationEntity.NetConn_NewConnection:
      return "NetConn_NewConnection";
    case NotificationEntity.Error:
      return "Error";
    case NotificationEntity.NetConn_Connected_Supplier:
      return "NetConn_Connected_Supplier";
    case NotificationEntity.NetConn_Connected_Client:
      return "NetConn_Connected_Client";
    case NotificationEntity.NetConn_Connected_User:
      return "NetConn_Connected_User";
    default:
      return "Unknown Notification Entity";
  }
};
