import { useAppSelector } from "../../app/hooks";
import VendorSpendDataUpload from "../../components/Company/VendorSpendDataUpload";

const VendorSpendDetails = () => {
  const userProfile = useAppSelector((x) => x.userProfile);
  const baseCompanyInfo = useAppSelector((x) => x.baseCompanyInfo);

  return (
    <VendorSpendDataUpload
      companyId={baseCompanyInfo.companyId}
      userId={userProfile?.particularUserProfile?.accountId || ""}
    />
  );
};

export default VendorSpendDetails;
