import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ProvinceModel } from "../../Company/Models/ProvinceModel";
import { GetAllProvinces } from "../../Company/Province";
import provinceListSlice from "./ProvinceListSlice";

export const ProvinceListActions = provinceListSlice.actions;

export const fetchProvinces = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<ProvinceModel> = (await GetAllProvinces()).data;
  dispatch(ProvinceListActions.setProvinceListState(apiResponse));
}