export interface ValidationResponse {
  isInvalid: boolean,
  validationErrors: string[],
  invalidFields: string[],
}

interface ValidationRegexPatterns{
  pattern: RegExp,
  errorMessage:string,
}

interface CustomValidationRules {
  hasError: boolean,
  errorMessage: string,
}

interface ValidationRule<T extends string | number | boolean | undefined | null> {
  displayName: string;
  fieldName: string;
  fieldValue: T; // fieldValue is required
  required?: boolean;
  minLength?: T extends string ? number : never; // minLength is applies only when fieldValue is string
  maxLength?: T extends string ? number : never; // maxLength is applies only when fieldValue is string
  validationPatterns?: ValidationRegexPatterns[];

  customValidation?: CustomValidationRules[];
}


const useValidation = (validationRules: ValidationRule<string | number | boolean | undefined | null>[]) => {
  const validationErrors: string[] = [];
  const invalidFields: string[] = [];

  const validateFields = (): ValidationResponse => {

    validationRules.forEach((r) => {
      if (r.required && (r.fieldValue == null || r.fieldValue.toString().trim().length <= 0)){
        validationErrors.push(`${r.displayName} is required`);
        invalidFields.push(r.fieldName);
        return;
      }

      // mininimum text length
      if (r.minLength && r.fieldValue && (r.minLength > 0 && r.fieldValue.toString().trim().length < r.minLength))
      {
        validationErrors.push(`${r.displayName} does not meet minimum length requirement of ${r.minLength} characters`);
        invalidFields.push(r.fieldName);
        return;
      }

      // maximum text length
      if (r.maxLength && r.fieldValue && (r.maxLength > 0 && r.fieldValue.toString().trim().length > r.maxLength)) {
        validationErrors.push(`${r.fieldName} "exceeds the maximum length requirement"`);
        invalidFields.push(r.fieldName);
        return;
      }

      r.validationPatterns?.forEach(regexRule => {
        if (regexRule.pattern && r.fieldValue && !r.fieldValue.toString().trim().match(regexRule.pattern)) {
          validationErrors.push(regexRule.errorMessage ?? `${r.displayName} : "Invalid input"`);
          invalidFields.push(r.fieldName);
        }
      });

      r.customValidation?.forEach(cv => cv.hasError && validationErrors.push(`${r.displayName} : ${cv.errorMessage}`));
    })

    return {
      isInvalid: invalidFields.length > 0,
      validationErrors,
      invalidFields,
    } as ValidationResponse
  }
  return [validateFields] as const;
}
export default useValidation;
