import { Document, Page, StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import { DocumentDtoModel } from "../../../http/DocumentManagement/Models/DocumentDtoModel";
import { UnspscSegment } from "../../../http/Configuration/UnspscSegment";
import AddressType from "../../../util/enums/AddressType";
import icon from "../../../util/images/icons8-user-24.png";
import checkMark from "../../../util/images/check.png"
import ContactType from "../../../util/enums/ContactType";
import { CompanyTypeToString } from "../../../util/enums/CompanyTypes";
import formatDate from "../../../util/formatter/formatDate";
import { CompanyComplianceSummary } from "../../../http/Compliance/Models/CompanyComplianceSummary";
import { CountryModel } from "../../../http/Company/Models/CountryModel";
import { CompanyProfileViewModel } from "../../../http/Company/Models/CompanyProfileViewModel";
import { CompanyProfileSettings } from "../../Registration/RegistrationSteps/SupportingDocumentsStepFour";
import { ProvinceModel } from "../../../http/Company/Models/ProvinceModel";
import { SectorModel } from "../../../http/Company/Models/SectorModel";
import { TurnoverModel } from "../../../http/Company/Models/TurnoverModel";




const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  top_container: {
    padding: 20,
  },
  flex_container: {
    flexDirection: "row",
    width: "100%",
    marginTop: 2,
    backgroundColor: "#ffffff",
  },
  profileImage: {
    width: 80,
    height: 80,
    zIndex: 1,
  },
  subHeading: {
    fontWeight: "bold",
    fontSize: 14,
    width: "53.5%",
    marginBottom: 5,
    marginTop: 10,
    marginLeft: 10,
  },
  containers_top_status: {
    backgroundColor: "#4B69F9",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    textAlign: "center",
    padding: 5,
    color: "white",
    width: "100%",
    fontSize: 12,
    fontWeight: "bold",
  },
  containers_top_status_content: {
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
  },
  containers_top: {
    width: "30%",
    margin: 2,
    height: "80%",
    position: "relative",
    borderRadius: 10,
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  containers_content_flex_row: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 5,
  },
  chip: {
    borderRadius: 6,
    backgroundColor: "#F0F0F1",
    padding: 3,
    marginLeft: 5,
    marginRight: 5,
    marginTop: -1,
    textAlign: "center",
    fontSize: 8,
  },
  text: {
    marginBottom: 10,
  },
  text_box_container: {
    width: "50%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  text_box_container_text: {
    fontSize: 10,
    marginBottom: 5,
  },
  text_box_container_text_segments: {
    fontSize: 10,
    marginBottom: 10,
  },
  text_box_container_text_families: {
    fontSize: 8,
    marginBottom: 5,
    marginRight: 5,
    padding: 5,
    backgroundColor: "#F0F0F1",
    borderRadius: 12,
  },
  shadow: {
    position: "absolute",
    backgroundColor: "#0F0F0F", // Adjust shadow color as needed
    width: "30.4%",
    marginTop: 2,
    right: 2,
    height: "81%",
    borderRadius: 12,
    opacity: 0.1, // Adjust opacity for a subtle shadow effect
    zIndex: 2,
  },
  table_box: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
  text_box_container_heading: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  table: {
    display: "flex",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRow_heading:
  {
    width: "100%",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    alignItems: "center",
    padding: 8,
    display: "flex",
  },
  tableCell_compliance: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_document_attached: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_issue_date: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_expiry_date: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_status: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_document: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
  },
  tableCell_date: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    padding: 8,
    display: "flex",
    alignItems: "center",
  },
  tableCell_attach: {
    width: "25%",
    borderStyle: "solid",
    fontSize: 12,
    backgroundColor: "#4B69F9",
    color: "white",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
    alignItems: "center",
  },
  tableCell_compliance_cell: {
    width: "20%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_document_attached_cell: {
    width: "30%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_issue_date_cell: {
    width: "15%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_expiry_date_cell: {
    width: "15%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_status_cell: {
    width: "20%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_document_cell: {
    width: "50%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
  },
  tableCell_date_cell: {
    width: "25%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
    alignItems: "center",
  },
  tableCell_attach_cell: {
    width: "25%",
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#F0F0F1",
    padding: 8,
    display: "flex",
    alignItems: "center", // Aligns text vertically center
  },
  icon: {
    width: 12,
    height: 12,
  },
});


interface PDFProps {
  company: CompanyProfileViewModel,
  companyProfileSettings: CompanyProfileSettings,
  document: DocumentDtoModel[],
  selectedItems: UnspscSegment[],
  profileImage: string,
  companyComplianceSummary: CompanyComplianceSummary,
  countries: CountryModel[],
  provinces: ProvinceModel[],
  beeSectors: SectorModel[],
  annualTurnover: TurnoverModel[],
}



const MyPDF = ({ company, companyProfileSettings, document, selectedItems, profileImage, companyComplianceSummary, countries, provinces, beeSectors, annualTurnover }: PDFProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.top_container}>
        <View style={styles.flex_container}>
          <View>
            <Image src={profileImage} style={styles.profileImage} />
          </View>
          <Text style={styles.subHeading}>{company.companyDetails?.registeredName}</Text>
          <View style={styles.shadow} />
          <View style={styles.containers_top}>
            <View style={styles.containers_top_status}>
              <Text>Profile Status</Text>
            </View>

            <View style={styles.containers_top_status_content}>
              <Text style={styles.text}>Completion:</Text>
              <Text style={styles.text}>Required Documentation:</Text>
              <View style={styles.containers_content_flex_row}>
                <View>
                  <Text>B-BBEE</Text>
                </View>
                <View style={styles.chip}>
                  <Text>label</Text>
                </View>
                <View>
                  <Text>Compliance</Text>
                </View>
                <View style={styles.chip}>
                  <Text>label</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.table_box}>
        <View style={styles.table}>
          <View style={styles.tableRow_heading}><Text>COMPLIANCE SUMMARY</Text></View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance}>
              <Text>Compliance</Text>
            </View>
            <View style={styles.tableCell_document_attached}>
              <Text>Document Attached</Text>
            </View>
            <View style={styles.tableCell_issue_date}>
              <Text>Issue Date</Text>
            </View>
            <View style={styles.tableCell_expiry_date}>
              <Text>Expiry Date</Text>
            </View>
            <View style={styles.tableCell_status}>
              <Text>Status</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance_cell}>
              <Text>B-BBEE</Text>
            </View>
            <View style={styles.tableCell_document_attached_cell}>
              <Text>{companyComplianceSummary.beeCertificateUploaded ? <Image src={checkMark} style={styles.icon} /> : "N/A"}</Text>
            </View>
            <View style={styles.tableCell_issue_date_cell}>
              <Text>{formatDate(companyComplianceSummary.beeCertificateIssueDate as string)}</Text>
            </View>
            <View style={styles.tableCell_expiry_date_cell}>
              <Text>{formatDate(companyComplianceSummary.beeCertificateExpiryDate as string)}</Text>
            </View>
            <View style={styles.tableCell_status_cell}>
              <Text>{!companyComplianceSummary?.beeCertificateExpiryDate ? "Not Captured" : "Info Captured"}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance_cell}>
              <Text>Banking Details </Text>
            </View>
            <View style={styles.tableCell_document_attached_cell}>
              <Text>{companyComplianceSummary.bankLetterUploaded ? <Image src={checkMark} style={styles.icon} /> : "N/A"}</Text>
            </View>
            <View style={styles.tableCell_issue_date_cell}>
              <Text>{formatDate(companyComplianceSummary.bankLetterIssueDate as string)}</Text>
            </View>
            <View style={styles.tableCell_expiry_date_cell}>
              <Text>N/A</Text>
            </View>
            <View style={styles.tableCell_status_cell}>
              <Text>{!companyComplianceSummary.bankLetterUploaded ? "Not Captured" : "Info Captured"}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance_cell}>
              <Text>COIDA</Text>
            </View>
            <View style={styles.tableCell_document_attached_cell}>
              <Text>{companyComplianceSummary.coidaCertificateExpiryDate ? <Image src={checkMark} style={styles.icon} /> : "N/A"}</Text>
            </View>
            <View style={styles.tableCell_issue_date_cell}>
              <Text>{formatDate(companyComplianceSummary.coidaCertificateIssueDate as string)}</Text>
            </View>
            <View style={styles.tableCell_expiry_date_cell}>
              <Text>{formatDate(companyComplianceSummary.coidaCertificateExpiryDate as string)}</Text>
            </View>
            <View style={styles.tableCell_status_cell}>
              <Text>{!companyComplianceSummary.hasCoidaEnabled ? "Not Captured" : "Info Captured"}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance_cell}>
              <Text>TAX SARS</Text>
            </View>
            <View style={styles.tableCell_document_attached_cell}>
              <Text>{companyComplianceSummary.taxClearanceCertificateUploaded ? <Image src={checkMark} style={styles.icon} /> : "N/A"}</Text>
            </View>
            <View style={styles.tableCell_issue_date_cell}>
              <Text>{companyComplianceSummary.taxClearancePinIssueDate ? formatDate(companyComplianceSummary.taxClearancePinIssueDate as string) : ""}</Text>
            </View>
            <View style={styles.tableCell_expiry_date_cell}>
              <Text>{companyComplianceSummary.taxClearancePinExpiryDate ? formatDate(companyComplianceSummary.taxClearancePinExpiryDate as string) : ""}</Text>
            </View>
            <View style={styles.tableCell_status_cell}>
              <Text>{!companyComplianceSummary.hasTaxEnabled ? "Not Captured" : "Info Captured"}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_compliance_cell}>
              <Text>TAX VAT</Text>
            </View>
            <View style={styles.tableCell_document_attached_cell}>
              <Text>{companyComplianceSummary.vatCertificateUploaded ? <Image src={checkMark} style={styles.icon} /> : "N/A"}</Text>
            </View>
            <View style={styles.tableCell_issue_date_cell}>
              <Text>{companyComplianceSummary.vatRegistrationDate ? formatDate(companyComplianceSummary.vatRegistrationDate as string) : ""}</Text>
            </View>
            <View style={styles.tableCell_expiry_date_cell}>
              <Text>N/A</Text>
            </View>
            <View style={styles.tableCell_status_cell}>
              <Text>{!companyComplianceSummary.hasVatEnabled ? "Not Captured" : "Info Captured"}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.flex_container}>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> PRIMARY REPRESENTATIVE
          </Text>
          <Text style={styles.text_box_container_text}>
            Name:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Primary
              )?.name
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Email:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Primary
              )?.email
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Contact Number:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Primary
              )?.mobile
            }
          </Text>
        </View>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> SECONDARY REPRESENTATIVE
          </Text>
          <Text style={styles.text_box_container_text}>
            Name:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Secondary
              )?.name
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Email:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Secondary
              )?.email
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Contact Number:{" "}
            {
              company?.companyContacts?.find(
                (x) => x.contactTypeId === ContactType.Secondary
              )?.mobile
            }
          </Text>
        </View>
      </View>
      <View style={styles.flex_container}>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> REGISTRATION INFORMATION
          </Text>
          <Text style={styles.text_box_container_text}>
            Registered company name: {company.companyDetails?.registeredName}
          </Text>
          <Text style={styles.text_box_container_text}>
            Company registration number: {company.companyDetails?.registrationNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            Company trading name: {company.companyDetails?.tradingName}
          </Text>
          <Text style={styles.text_box_container_text}>
            Company type: {CompanyTypeToString(company.companyDetails?.companyTypeId || 0)}
          </Text>
          <Text style={styles.text_box_container_text}>
            Country of Origin: {countries?.find(x => x.id === company?.companyDetails?.countryId)?.name}
          </Text>
          <Text style={styles.text_box_container_text}>
            Holding company name: {company.companyDetails?.holdingCompanyName}
          </Text>
        </View>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> TAX INFORMATION
          </Text>
          <Text style={styles.text_box_container_text}>
            Registered for Tax: {companyProfileSettings.hasTaxEnabled ? "Yes" : "No"}
          </Text>
          <Text style={styles.text_box_container_text}>
            Company VAT number: {company?.companyDetails?.vatNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            VAT Registration Date: {company?.companyDetails?.vatRegistrationDate}
          </Text>
          <Text style={styles.text_box_container_text}>
            Tax clearance Pin: {company?.companyDetails?.taxClearanceNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            Tax Clearance Pin issue date: {" "}
            {company?.companyDetails?.taxClearanceNumberIssueDate ? formatDate(company?.companyDetails?.taxClearanceNumberIssueDate as string) : ""}
          </Text>
          <Text style={styles.text_box_container_text}>
            Tax clearance pin expiry date:{" "}
            {company?.companyDetails?.taxClearanceNumberExpiryDate ? formatDate(company?.companyDetails?.taxClearanceNumberExpiryDate || "") : ""}
          </Text>
        </View>
      </View>
      <View style={styles.flex_container}>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> PHYSICAL ADDRESS
          </Text>
          <Text style={styles.text_box_container_text}>
            Street address line 1:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Physical
              )?.line1
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Street address line 2:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Physical
              )?.line2
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Office block/complex:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Physical
              )?.officeBlockOrComplexName
            }
          </Text>
          <Text style={styles.text_box_container_text}>Unit number:</Text>
          <Text style={styles.text_box_container_text}>
            City:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Physical
              )?.city
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Province/State:{" "}
            {provinces.find(x => x.id === company.companyAddresses?.find(
              (i) => i.addressTypeId === AddressType.Physical
            )?.provinceId)?.name
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Country:{" "}
            {
              countries.find(x => x.id === company.companyAddresses?.find((i) => i.addressTypeId === AddressType.Physical
              )?.countryId)?.name
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Postal Code:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Physical
              )?.postalCode
            }
          </Text>
        </View>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> POSTAL ADDRESS
          </Text>
          <Text style={styles.text_box_container_text}>
            Street address line 1:{" "}
            {
              company.companyAddresses?.find((x) => x.addressTypeId === AddressType.Postal)
                ?.line1
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Street address line 2:{" "}
            {
              company.companyAddresses?.find(
                (x) => x.addressTypeId === AddressType.Postal
              )?.line2
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Office block/complex:{" "}
            {
              company.companyAddresses?.find((x) => x.addressTypeId === AddressType.Postal)
                ?.officeBlockOrComplexName
            }
          </Text>
          <Text style={styles.text_box_container_text}>Unit number:</Text>
          <Text style={styles.text_box_container_text}>
            City:{" "}
            {
              company.companyAddresses?.find((x) => x.addressTypeId === AddressType.Postal)
                ?.city
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Province/State:{" "}
            {provinces.find(x => x.id === company.companyAddresses?.find((i) => i.addressTypeId === AddressType.Postal)
              ?.provinceId)?.name
            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Country:{" "}
            {
              countries.find(x => x.id === company.companyAddresses?.find((i) => i.addressTypeId === AddressType.Postal)
                ?.countryId)?.name

            }
          </Text>
          <Text style={styles.text_box_container_text}>
            Postal Code:{" "}
            {
              company.companyAddresses?.find((x) => x.addressTypeId === AddressType.Postal)
                ?.postalCode
            }
          </Text>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.flex_container}>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> B-BBEE STATUS
          </Text>
          <Text style={styles.text_box_container_text}>
            Valid B-BBEE certificate affidavit:{" "}
            {companyProfileSettings.hasBeeEnabled}
          </Text>
          <Text style={styles.text_box_container_text}>
            B-BBEE certificate expiry date:{" "}
            {formatDate(company?.companyBeeDetails?.certificateIssueDate || "")}
          </Text>
          <Text style={styles.text_box_container_text}>
            B-BBEE certificate expiry date:{" "}
            {formatDate(company?.companyBeeDetails?.certificateExpiryDate || "")}
          </Text>
          <Text style={styles.text_box_container_text}>
            B-BBEE sector: {beeSectors.find(x => x.id === company.companyBeeDetails?.sectorId)?.value}
          </Text>
          <Text style={styles.text_box_container_text}>
            Annual turnover: {annualTurnover.find(x => x.id === company.companyBeeDetails?.annualTurnover)?.name}
          </Text>
          <Text style={styles.text_box_container_text}>
            Black ownership %: {company.companyBeeDetails?.boPercentage}
          </Text>
          <Text style={styles.text_box_container_text}>
            Black women ownership %: {company.companyBeeDetails?.bwoPercentage}
          </Text>
          <Text style={styles.text_box_container_text}>
            B-BBEE level: {company.companyBeeDetails?.certificateLevelId}
          </Text>
        </View>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> COIDA
          </Text>
          <Text style={styles.text_box_container_text}>
            Coida Registered:{" "}
            {companyProfileSettings.hasCoidaEnabled ? "Yes" : "No"}
          </Text>
          <Text style={styles.text_box_container_text}>
            Registration Number:{" "}
            {company?.companyCoidaDetails?.cfRegistrationNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            Certificate Number:{" "}
            {company?.companyCoidaDetails?.certificateNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            Issue Date: {formatDate(company?.companyCoidaDetails?.dateOfIssue || "")}
          </Text>
          <Text style={styles.text_box_container_text}>
            Expiry Date: {formatDate(company?.companyCoidaDetails?.expiryDate || "")}
          </Text>
        </View>
      </View>
      <View style={styles.flex_container}>
        <View style={styles.text_box_container}>
          <Text style={styles.text_box_container_heading}>
            {" "}
            <Image src={icon} style={styles.icon} /> BANK DETAILS
          </Text>
          <Text style={styles.text_box_container_text}>
            Do You Have A Bank Account: {companyProfileSettings.hasBankAccountDetailsEnabled ? "Yes" : "No"}
          </Text>
          <Text style={styles.text_box_container_text}>
            Bank Account Country: {countries.find(x => x.id === company.companyBankAccountDetails?.countryId)?.name}
          </Text>
          <Text style={styles.text_box_container_text}>
            Bank Name: {company.companyBankAccountDetails?.textBankName}
          </Text>
          <Text style={styles.text_box_container_text}>
            Account Number:{company.companyBankAccountDetails?.accountNumber}
          </Text>
          <Text style={styles.text_box_container_text}>
            Account Type: {company.companyBankAccountDetails?.textAccountType}
          </Text>
          <Text style={styles.text_box_container_text}>
            Branch Code: {company.companyBankAccountDetails?.branchCode}
          </Text>
          <Text style={styles.text_box_container_text}>
            Account Holder: {company.companyBankAccountDetails?.accountName}
          </Text>
          <Text style={styles.text_box_container_text}>
            Branch Name: {company.companyBankAccountDetails?.branchName}
          </Text>
          <Text style={styles.text_box_container_text}>
            SWIFT code: {company.companyBankAccountDetails?.swiftCode}
          </Text>
          <Text style={styles.text_box_container_text}>
            Primary Bank Account: {company.companyBankAccountDetails?.isPrimary ? "Yes" : "No"}
          </Text>
          <Text style={styles.text_box_container_text}>
            Bank letter Issue Date: {formatDate(company.companyBankAccountDetails?.dateOfIssue as string)}
          </Text>
        </View>
      </View>
      <View style={styles.table_box}>
        <View>
          <View>
            <Text style={styles.text_box_container_heading}>
              <Image src={icon} style={styles.icon} /> PRODUCT SECTORS/SEGMENTS
            </Text>
          </View>
          {selectedItems?.filter((x) => x != null).map((segments) => (
            <>
              <Text style={styles.text_box_container_text_segments}>{segments?.name}</Text>
              <View style={styles.flex_container}>
                {selectedItems &&
                  selectedItems?.filter((x) => x != null).map((records) =>
                    records?.families.map((item, index) => (
                      <View key={index} break>
                        <Text style={styles.chip}>{item.name}</Text>
                      </View>
                    ))
                  )}
              </View>
            </>
          ))}
        </View>
      </View>
      <View style={styles.table_box}>
        <View>
          <View>
            <Text style={styles.text_box_container_heading}>
              {" "}
              <Image src={icon} style={styles.icon} /> DOCUMENTS
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_document}>
              <Text>Document</Text>
            </View>
            <View style={styles.tableCell_date}>
              <Text>Expiry Date</Text>
            </View>
            <View style={styles.tableCell_attach}>
              <Text>Attached</Text>
            </View>
          </View>
          {document?.map((doc) => (
            <View style={styles.tableRow}>
              <View style={styles.tableCell_document_cell}>
                <Text>
                  {doc.documentTypeName}
                </Text>
              </View>
              <View style={styles.tableCell_date_cell}>
                <Text>{doc?.expiryDate?.toString().split("T")[0]}</Text>
              </View>
              <View style={styles.tableCell_attach_cell}>
                <Text>{doc?.documentUrl ? <Image src={checkMark} style={styles.icon} /> : ""}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>

    </Page>
  </Document>
);

export default MyPDF;