import { Box, Grid, Skeleton, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useEffect, useState } from "react";
import { DocumentDtoModel } from "../../../http/DocumentManagement/Models/DocumentDtoModel";

import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import CustomizedDialogs from "./UploadDialog";

interface ViewCellProps {
  row: DocumentDtoModel;
  companyId: number;
  resetDocuments: () => void;
}




export const GetRandomNumber = (max: number) => Math.floor(Math.random() * max);

const ViewCell: React.FC<ViewCellProps> = ({ row, companyId, resetDocuments }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetDocuments();
  };


  return (
    <div>
      {row.id === "00000000-0000-0000-0000-000000000000" &&
        <>
          <Grid container>
            <Grid xs={2} sx={{ marginRight: 2 }}>
              <CloudUploadIcon onClick={handleClickOpen} />
            </Grid>
          </Grid>
          <div>
            {open && (
              <CustomizedDialogs
                handleClose={handleClose}
                title={row.documentTypeName}
                open={open}
                documentType={row.typeId}
                companyId={companyId} />
            )}
          </div>
        </>
      }
    </div>
  )
};



const Documents = ({ companyId, files, resetDocuments }: { companyId: number, files: Array<DocumentDtoModel>, resetDocuments: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);


  const DocumentColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        params.value === "00000000-0000-0000-0000-000000000000" ? (
          <Tooltip title="Document not uploaded" >
            <WarningIcon sx={{ color: "#ff0000" }} />
          </Tooltip >
        ) : (
          <Tooltip title="Document not uploaded" >
            <CheckCircleIcon sx={{ color: "green" }} />
          </Tooltip >
        )
      ),
    },
    {
      field: "documentTypeName",
      headerName: "Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "displayOrder",
      headerName: "Actions",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ViewCell row={params.row as DocumentDtoModel} companyId={companyId} resetDocuments={resetDocuments} />
      ),
    },
  ];

  useEffect(() => {
    if (files.length === 0) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [files.length])

  return (
    <div>
      <LoadingBackdrop showBackdrop={isLoading} />
      {
        isLoading ? (
          <Box sx={{ mb: 2, width: "100%", padding: "10px" }} >
            <Skeleton variant="rounded" sx={{ width: "98%" }} height={450} />
          </Box >

        ) : (
          <Box sx={{ padding: 2, width: "100%", height: "fit-content" }}>
            <DataGridPro
              columns={DocumentColumns}
              rows={files}
              disableRowSelectionOnClick
              getRowId={(row: DocumentDtoModel) => row.displayOrder} />
          </Box>
        )}
    </div>

  )
}

export default Documents; 