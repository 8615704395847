import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CompaniesRequiringVerificationModel, CompaniesRequiringVerificationArrayModel } from "../Models/CompaniesRequiringVerificationModel";

const initialCompaniesRequiringVerificationState: CompaniesRequiringVerificationArrayModel = {
  allCompaniesRequiringVerification: [],
}

const CompaniesRequiringVerificationSlice = createSlice({
  name: "companiesRequiringVerification",
  initialState: initialCompaniesRequiringVerificationState,
  reducers: {
    setCompaniesRequiringVerification(state, action: PayloadAction<CompaniesRequiringVerificationModel[]>) {
      state.allCompaniesRequiringVerification = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export const getCompanies = (state: RootState) => state.companiesRequiringVerification.allCompaniesRequiringVerification;
export default CompaniesRequiringVerificationSlice;