import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GetBankAccountTypes } from "../../Company/bankingDetails";
import { BankAccountTypeModel } from "../../Company/Models/BankAccountTypeModel";
import bankAccountTypeListSlice from "./BankAccountTypeListSlice";

export const BankAccountTypeListActions = bankAccountTypeListSlice.actions;

export const fetchBankAccountTypes = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const apiResponse: Array<BankAccountTypeModel> = await (GetBankAccountTypes());
  dispatch(BankAccountTypeListActions.setbankAccountTypeListState(apiResponse));
}