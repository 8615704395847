import { useEffect, useState } from "react";
import { SubscriptionVoucherModel } from "../../../../http/Redux/Models/SubscriptionVoucherModel";
import "../../../Connect/SearchResult/SweetAlert.css";
import EditVouchers from "./EditVoucher";

const VoucherDetails = ({ data }: { data: SubscriptionVoucherModel }) => {

  const [datas, setData] = useState<SubscriptionVoucherModel>({} as SubscriptionVoucherModel);

  useEffect(() => {

    setData(data);

  }, [data]);

  return (

    <div>
      <div className="config-heading">
        <h2>Details</h2>
      </div>
      <EditVouchers data={datas} />
    </div>

  )
};

export default VoucherDetails;