import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ErrorResponseArrayModel, ErrorResponseModel } from "../Models/ErrorResponseModel";
import { OnboardingConfigurationSetArrayModel, OnboardingConfigurationSetModel } from "../Models/OnboardingConfigurationSetModel";
import { SpecificTypeDictionaryEntryArrayModel, SpecificTypeDictionaryEntryModel } from "../Models/DictionaryEntryModel";
import { CompanyCategoryArrayModel, CompanyCategoryModel } from "../Models/CompanyCategoryModel";
import { OnboardingRequestArrayModel, OnboardingRequestModel } from "../Models/OnboardingRequestModel";



const initialOnboardingCheckState: ErrorResponseArrayModel = {
  allOnboardingChecks: [],
}


const initialOnboardingRequestState: OnboardingRequestArrayModel = {
  allOnboardingRequests: [],
}

const initialOnboardingConfigurationSetState: OnboardingConfigurationSetArrayModel = {
  allOnboardingConfigurationSet: [],
}

const initialBusinessUnitState: SpecificTypeDictionaryEntryArrayModel = {
  allSpecificTypeDictionaryEntry: [],
}

const initialSupplierTypeState: SpecificTypeDictionaryEntryArrayModel = {
  allSpecificTypeDictionaryEntry: [],
}

const initialContractingTierState: SpecificTypeDictionaryEntryArrayModel = {
  allSpecificTypeDictionaryEntry: [],
}

const initialCompanyCategoryState: CompanyCategoryArrayModel = {
  allCompanyCategories: [],
}


const BusinessUnitSlice = createSlice({
  name: "businessUnit",
  initialState: initialBusinessUnitState,
  reducers: {
    setOnBusinessUnit(state, action: PayloadAction<SpecificTypeDictionaryEntryModel[]>) {
      state.allSpecificTypeDictionaryEntry = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


const OnboardingCheckSlice = createSlice({
  name: "onboardingCheck",
  initialState: initialOnboardingCheckState,
  reducers: {
    setOnboardingCheck(state, action: PayloadAction<ErrorResponseModel[]>) {
      state.allOnboardingChecks = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

const OnboardingRequestSlice = createSlice({
  name: "onboardingRequest",
  initialState: initialOnboardingRequestState,
  reducers: {
    setOnboardingRequest(state, action: PayloadAction<OnboardingRequestModel[]>) {
      state.allOnboardingRequests = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})


const OnboardingConfigurationSetSlice = createSlice({
  name: "onboardingConfigurationSet",
  initialState: initialOnboardingConfigurationSetState,
  reducers: {
    setOnboardingConfigurationSet(state, action: PayloadAction<OnboardingConfigurationSetModel[]>) {
      state.allOnboardingConfigurationSet = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

const CompanyCategorySlice = createSlice({
  name: "companyCategory",
  initialState: initialCompanyCategoryState,
  reducers: {
    setCompanyCategory(state, action: PayloadAction<CompanyCategoryModel[]>) {
      state.allCompanyCategories = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

const SupplierTypeSlice = createSlice({
  name: "supplierType",
  initialState: initialSupplierTypeState,
  reducers: {
    setOnSupplierType(state, action: PayloadAction<SpecificTypeDictionaryEntryModel[]>) {
      state.allSpecificTypeDictionaryEntry = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

const ContractingTierSlice = createSlice({
  name: "contractingTier",
  initialState: initialContractingTierState,
  reducers: {
    setOnContractingTier(state, action: PayloadAction<SpecificTypeDictionaryEntryModel[]>) {
      state.allSpecificTypeDictionaryEntry = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})



export { OnboardingConfigurationSetSlice, OnboardingCheckSlice, BusinessUnitSlice, CompanyCategorySlice, SupplierTypeSlice, ContractingTierSlice, OnboardingRequestSlice };



export const getAllOnboardingChecks = (state: RootState) => state.onboardingCheck.allOnboardingChecks;
export const getAllOnboardingRequest = (state: RootState) => state.onboardingRequest.allOnboardingRequests;
export const getAllOnboardingConfigurationSets = (state: RootState) => state.onboardingConfigurationSet.allOnboardingConfigurationSet;
export const getBusinessUnits = (state: RootState) => state.businessUnit.allSpecificTypeDictionaryEntry;
export const getCompanyCategories = (state: RootState) => state.companyCategory.allCompanyCategories;
export const getSupplierTypes = (state: RootState) => state.supplierType.allSpecificTypeDictionaryEntry;
export const getContractingTiers = (state: RootState) => state.contractingTier.allSpecificTypeDictionaryEntry;
