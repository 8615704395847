/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum CompanyRatings {
  None = 0,
  OneStar = 1,
  TwoStars = 2,
  ThreeStars = 3,
  FourStars = 4,
  FiveStars = 5,
}

export default CompanyRatings;