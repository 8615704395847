/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum UserPopupActivities {
  Welcome = 1,
  Upgrade = 2,
  Upload = 3,
  Tour = 4,
  Recommendation = 5,
  Info = 6,
  NewFeatures = 7,
  Completion = 8,
  Offer = 9,
  Rate = 10,
  Profile = 12,
  Subscriptions = 11,
  Network = 13,
  Message = 14,
  Invite = 15,
}

export default UserPopupActivities;

export const userPopupActivitiesToString = (value: UserPopupActivities) => {
  switch (value) {
    case UserPopupActivities.Welcome:
      return "Welcome"
    case UserPopupActivities.Upgrade:
      return "Upgrade"
    case UserPopupActivities.Upload:
      return "Upload"
    case UserPopupActivities.Tour:
      return "Tour"
    case UserPopupActivities.Invite:
      return "Invite"
    default:
      return "Unknown";
  }
};