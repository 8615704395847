/* eslint-disable no-unused-vars */
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { IRegistrationStepPageComponent, RegistrationStepResponse } from "./IRegistrationStepComponent";
import TextField from "../CustomComponents/Controls/TextField";
import { GetCompanyDataById, SaveEntireCompany } from "../../../http/Company/company";
import { CompanyViewModel } from "../../../http/Company/Models/CompanyViewModel";
import useBaseCompanyInfo from "../../../util/hooks/queries/useBaseCompanyInfo";
import { colorPrimaryActive } from "../../../util/MUI_Theme/Theme";
import { CompanyAdditionalSearchModel, CompanySearchModel } from "../../../http/Compliance/CompanySearchModel";
import { getInoxicoAdditionalCompanyDetails, getInoxicoCompanyDetails } from "../../../http/Compliance/complianceApi";
import { Company, InoxicoCompanyAdditionalSearchResults } from "../../../http/Compliance/CompanySearchResults";
import { formatDateTime } from "../../../util/formatter/formatDate";
import useDuplicateCheck from "../../../util/hooks/queries/useDuplicateCheck";
import useDuplicateChecker from "../../../util/hooks/useDuplicateChecker";

const CompanyVerificationStepZero = forwardRef<IRegistrationStepPageComponent, unknown>((_props, ref) => {

  const [companyData, setCompanyData] = useState<CompanyViewModel>({} as CompanyViewModel);
  const [, setIsLoading] = useState(true);
  const { data: baseCompanyInfo } = useBaseCompanyInfo();
  const [companySearchResults, setCompanySearchResults] = useState<Company>({} as Company);
  const [companyAdditionalSearchResults, setCompanyAdditionalSearchResults] = useState<InoxicoCompanyAdditionalSearchResults>({} as InoxicoCompanyAdditionalSearchResults);
  const [showCompanyNotFoundError, setShowCompanyNotFoundError] = useState(false);
  const [registrationDateValue, setRegistrationDateValue] = useState("");
  const {data: duplicateChecker} = useDuplicateCheck(baseCompanyInfo?.companyRegistrationNumber ?? "");
  const {checkDuplicate, DuplicateCheckModalComponent} = useDuplicateChecker();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const companyDetails = await GetCompanyDataById(baseCompanyInfo?.companyId ?? 0, false);
      setCompanyData(companyDetails.data);
      await new Promise(resolve => setTimeout(resolve, 2000)); // eslint-disable-line no-promise-executor-return
      setIsLoading(false);
    };
    if (baseCompanyInfo?.companyId)
      loadData();
  }, [baseCompanyInfo?.companyId])

  const onSubmitHandler = async () => {
    const response: RegistrationStepResponse = {
      submittedSuccessfully: true,
      errorMessages: [],
    };
    try {
      if(!duplicateChecker?.isDuplicate){
        if(companyData.registrationNumber !== companySearchResults.entityNumber){
          companyData.registrationNumber = companySearchResults.entityNumber;
        }
      
        if(companyData.registeredName !== companySearchResults.entityName){
          companyData.registeredName = companySearchResults.entityName;
        }
        await SaveEntireCompany(companyData, false);
      }
      else{
        checkDuplicate(duplicateChecker)
        response.submittedSuccessfully = false;
        response.errorMessages = ["Duplicate found. Please check registration number."];
      }
    } catch (error) {
      response.submittedSuccessfully = false;
      response.errorMessages = ["Something went wrong. Please try again."]
    }
    return response;
  };

  const onSaveAndContinueHandler = async () => {
    await SaveEntireCompany(companyData, false);
    return {
      submittedSuccessfully: true,
      errorMessages: [],
    };
  };

  // Expose the methods and properties useImperativeHandle (the will be accessible form the ref)
  useImperativeHandle(ref, () => ({
    onSubmit: onSubmitHandler,
    onSaveAndContinue: onSaveAndContinueHandler,
  }));

  // eslint-disable-next-line consistent-return
  const handleSearchCompany =  async () => {
    const companyRegistrationSearch = { companyRegistrationNumber:companyData.registrationNumber } as unknown as CompanySearchModel; 
    const results = await getInoxicoCompanyDetails(companyRegistrationSearch);
    if(results.companies.length !== 0){
      setShowCompanyNotFoundError(false);
      setCompanySearchResults(results.companies[0]);
      const companyAdditionalSearch = { companyInoxicoId:results.companies[0].entityNoxId } as unknown as CompanyAdditionalSearchModel; 
      const additionalResults = await getInoxicoAdditionalCompanyDetails(companyAdditionalSearch);
      setCompanyAdditionalSearchResults(additionalResults);

      if(companyAdditionalSearchResults.registrationDate !== undefined)
        setRegistrationDateValue(formatDateTime(companyAdditionalSearchResults.registrationDate));
    
    }else{
      setShowCompanyNotFoundError(true);
    }
  }

  const handleRegistrationNumberValue =  (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const companyNumber = ev.currentTarget.value;
    companyData.registrationNumber = companyNumber;
    setCompanyData((prevState) => ({ ...prevState }));
  }

  return (
    <Box mx={0} px={0}>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="pageTitle" color={colorPrimaryActive}>
                VERIFY YOUR COMPANY</Typography>
          </Box>
        </Grid>
        <Grid>
          <div className="text-label-box">
              We have identified a potential match for your company. Please verify if this is correct or initiate a new search.
          </div>
        </Grid>
      </Grid>
      <Grid container py={1}>
        <Grid xs={4} >
          <TextField
            name="companyRegistrationNumber"
            label="Company Registration Number"
            value={companyData.registrationNumber ?? ""}
            onChange={(ev) => handleRegistrationNumberValue(ev)}
            defaultErrorBehaviour
            error={showCompanyNotFoundError}
            helperText={showCompanyNotFoundError ? "*Company not found please try again." : ""}
          />
        </Grid>
        <Grid paddingTop={8} >
          <Button 
            variant="contained"
            onClick={(ev) => {
              handleSearchCompany();
            }}
          >
              search again
          </Button>
        </Grid>
      </Grid>
      
      <Grid container spacing={1} px={1} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="inputLabel">Company Name:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{companySearchResults?.entityName ?? ""}</Typography>
        </Grid>
      
        <Grid item xs={3}>
          <Typography variant="inputLabel">Company Registration Number:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{companySearchResults?.entityNumber ?? ""}</Typography>
        </Grid>
      
        <Grid item xs={3}>
          <Typography variant="inputLabel">Registered Date:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{registrationDateValue ?? ""}</Typography>
        </Grid>
      
        <Grid item xs={3}>
          <Typography variant="inputLabel">Company Type:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{companySearchResults?.companyType ?? ""}</Typography>
        </Grid>
      
        <Grid item xs={3}>
          <Typography variant="inputLabel">Business Status:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{companySearchResults?.companyStatus ?? ""}</Typography>
        </Grid>
      </Grid>  
      <DuplicateCheckModalComponent/><ToastContainer/>
    </Box>
  )
});

export default CompanyVerificationStepZero;