import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import acquireAccessToken from "./acquireAccessToken";
import acquireAccessTokenNoLogin from "./acquireAccessTokenNoLogin";

const makeGetRequestAsync = async <TReturn>(apiScopes: string[], url: string) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.get(url, config) as Promise<AxiosResponse<TReturn>>;
};
export default makeGetRequestAsync;

export const makeGetRequestAsyncNoAuth = async <T>(url: string) =>
  axios.get(url) as Promise<AxiosResponse<T>>;

export const makePostRequestAsync = async <TReturn, TBody>(
  apiScopes: string[],
  url: string,
  data?: TBody
) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  if (!data) {
    return axios.post(url, null, config) as Promise<AxiosResponse<TReturn>>;
  }
  return axios.post(url, data, config) as Promise<AxiosResponse<TReturn>>;
};

export const makePUTRequestAsync = async <TReturn, TBody>(
  apiScopes: string[],
  url: string,
  data: TBody
) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.put(url, data, config) as Promise<AxiosResponse<TReturn>>;
};

export const makePatchRequestAsync = async <TReturn, TBody>(
  apiScopes: string[],
  url: string,
  data: TBody
) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.patch(url, data, config) as Promise<AxiosResponse<TReturn>>;
};

export const makeDeleteRequestNoBodyAsync = async <TReturn>(apiScopes: string[], url: string) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.delete(url, config) as Promise<AxiosResponse<TReturn>>;
};

export const makePatchRequestNoBodyAsync = async <TReturn>(apiScopes: string[], url: string) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.post(url, config) as Promise<AxiosResponse<TReturn>>;
};

export const makePostRequestAsyncNoAuth = async <TReturn, TBody>(url: string, data: TBody) =>
  axios.post(url, data) as Promise<AxiosResponse<TReturn>>;

export const makePostFormRequestAsync = async <TReturn, TBody>(
  apiScopes: string[],
  url: string,
  data: TBody
) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `${bearer}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return axios.post(url, data, config) as Promise<AxiosResponse<TReturn>>;
};

export const makePatchFormRequestAsync = async <TReturn, TBody>(
  apiScopes: string[],
  url: string,
  data: TBody
) => {
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `${bearer}`,

      "Content-Type": "multipart/form-data",
    },
  };

  return axios.patch(url, data, config) as Promise<AxiosResponse<TReturn>>;
};

export const makeGetRequestAsyncNoLogin = async <T>(apiScopes: string[], url: string) => {
  const accessToken = await acquireAccessTokenNoLogin(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    headers: { Authorization: `${bearer}` },
  };
  return axios.get(url, config) as Promise<AxiosResponse<T>>;
};

export const downloadFileAsync = async (
  apiScopes: string[], 
  url: string, 
  fileName?: string, 
  onDownloadProgressCallback?: (percetageDownloadProgress: number) => void) => {  // eslint-disable-line no-unused-vars
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    url,
    headers: { Authorization: `${bearer}` },
    responseType: "blob",
    method: "GET",
    onDownloadProgress: (progressEvent: ProgressEvent) => onDownloadProgressCallback &&
      onDownloadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
  };
  return axios({ ...config }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    let fName = fileName ?? "downloaded-file.xlsx"; // default file name
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match) {
        fName = match[1];
      }
    }

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fName); // or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export const downloadFilePostWithBodyAsync = async (
  apiScopes: string[],
  url: string,
  fileName?: string,
  data?:unknown,
  onDownloadProgressCallback?: (percetageDownloadProgress: number) => void) => {  // eslint-disable-line no-unused-vars
  const accessToken = await acquireAccessToken(apiScopes);
  const bearer = `Bearer ${accessToken}`;
  const config: AxiosRequestConfig = {
    url,
    headers: { Authorization: `${bearer}` },
    responseType: "blob",
    method: "POST",
    data,
    onDownloadProgress: (progressEvent: ProgressEvent) => onDownloadProgressCallback &&
      onDownloadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
  };
  return axios({ ...config,data }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    let fName = fileName ?? "downloaded-file.xlsx"; // default file name
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match) {
        fName = match[1];
      }
    }

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fName); // or any other extension
    document.body.appendChild(link);
    link.click();
  });
}