/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum BeeClassification {
  eme = 1,
  qse = 2,
  generic = 3,
}

const beeClassificationToString = (classification: BeeClassification): string => {
  switch (classification) {
    case BeeClassification.eme:
      return "EME";
    case BeeClassification.qse:
      return "QSE";
    case BeeClassification.generic:
      return "Generic";
    default:
      return "Unknown";
  }
};

export {BeeClassification, beeClassificationToString};