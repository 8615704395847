/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BaseSubscriptionInfoModel } from "../../SubscriptionManagement/Models/BaseSubscriptionInfoModel";

export interface BaseSubscriptionInfoState {
  baseSubscriptionInfo: BaseSubscriptionInfoModel;
}

const baseSubscriptionInfoSlice = createSlice({
  name: "baseSubscriptionInfo",
  initialState: {} as BaseSubscriptionInfoModel,
  reducers: {
    setBaseSubscriptionInfoState: (
      state,
      action: PayloadAction<BaseSubscriptionInfoModel>
    ) => action.payload,
  },
});

export const selectBaseSubscriptionInfo = (state: RootState) => state.baseSubscriptionInfo;
export default baseSubscriptionInfoSlice;
