import { Box, Typography, Tabs, Tab } from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { useParams } from "react-router";
import WorkCentreMatch from "./AgentWorkCentrePages/WorkCentreMatch";
import LoadingBackdrop from "../../components/Backdrop/LoadingBackdrop";
import SupplierManagement from "./AgentWorkCentrePages/SupplierManagement";
import TaskList from "./AgentWorkCentrePages/TaskList";
import SupervisorTaskList from "./AgentSupervisor/SupervisorTaskList";
import PermissionClassification from "../../util/enums/PermissionClassification";
import { useHasAnyAllowedPermissions } from "../../util/hooks/useAllowedPermission";
import BasePage from "../BasePage";


LicenseInfo.setLicenseKey("519a9329e82d8a60908a5dcbbca6994dTz02ODUzMyxFPTE3MTgyNjM5NTc5NDcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AgentWorkCentre = () => {
  const [value, setValue] = useState(0);
  const [loading] = useState(false);
  const hasAllowedPermission = useHasAnyAllowedPermissions();
  const { tab, clientId } = useParams();
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab) {
      a11yProps(Number(tab));
    }
  }, [])

  return (
    <div>
      <LoadingBackdrop showBackdrop={loading} />
      <BasePage pageHeader="Agent Work Centre">
        <Box sx={{ width: "100%", paddingLeft: 3, paddingRight: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Supplier Management" {...a11yProps(0)} />
              <Tab label="My Tasks" {...a11yProps(1)} />
              <Tab label="Match & Clean" {...a11yProps(2)} />
              {hasAllowedPermission([PermissionClassification.SuperAgent]) && (
                <Tab label="All Allocated Tasks" {...a11yProps(3)} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <SupplierManagement clientId={Number(clientId)} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TaskList />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <WorkCentreMatch />
          </TabPanel>
          {hasAllowedPermission([PermissionClassification.SuperAgent]) && (
            <TabPanel value={value} index={3}>
              <SupervisorTaskList />
            </TabPanel>
          )}
        </Box>
      </BasePage>
    </div >
  );
};


export default AgentWorkCentre;