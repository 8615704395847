import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { checkDuplicateRegistrationNumber } from "../../../http/Company/company";
import { CompanyRegistrationNumberModel } from "../../../http/Company/Models/CompanyRegistrationNumberModel";
  
const useDuplicateCheck = (registrationNumber: string) => {
  const model: CompanyRegistrationNumberModel = { companyRegistrationNumber: registrationNumber };
  const query = useQuery({
    queryKey: [queryKeys.companyDetails, registrationNumber],
    queryFn: async () => (checkDuplicateRegistrationNumber(model)),
  })
  
  return query;
}
  
export default useDuplicateCheck;