import { useState } from "react";
import { toast } from "react-toastify";
import { GetCompanyAdminUserId } from "../../http/Company/company";
import { CompanyDuplicateCheckResponse } from "../../http/Company/Models/CompanyDuplicateCheckResponse";
import DuplicateCheckModal from "../../pages/Registration/Sections/DuplicateCheckModal";
import useBaseCompanyInfo from "./queries/useBaseCompanyInfo";
import { NotificationMessage } from "../../http/Notifications/Models/NotificationMessage";
import ServiceType from "../enums/ServiceType";
import NotificationEntity from "../enums/NotificationEntity";
import NotificationSeverity from "../enums/NotificationSeverity";
import { AddNewNotification } from "../../http/Notifications/Notification";
import useUserProfile from "./queries/useUserProfile";

const useDuplicateChecker = () => {
  const [duplicateCompanyId, setDuplicateCompanyId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const { data: baseCompanyInfo } = useBaseCompanyInfo();
  const { data: userProfile } = useUserProfile();

  const checkDuplicate =  (response : CompanyDuplicateCheckResponse) => {
    try {
      setDuplicateCompanyId(response.duplicateCompanyId);
      if (response.isDuplicate) {
        setShowModal(true);
      }
    } catch (err) {
      toast.error("Error checking duplicate registration number");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const sendUserConnectNotification = async (toCompanyId: number) => {
    const sender = userProfile?.data.accountId || "";
    const recipientResult = await GetCompanyAdminUserId(toCompanyId);
    const senderCompanyName: string = baseCompanyInfo?.companyRegisteredName || "";
    const senderCompanyId: number = baseCompanyInfo?.companyId || 0;

    if (!sender){
      toast.error("Unable to send message. Sender not provided.")  
      throw new Error("Unable to send message. Sender not provided.");
    }
      

    if (!recipientResult) {
      toast.error("Recipient not found")  
      throw new Error("Recipient not found");
    }

    if (recipientResult === "404") {
      toast.error("Unable to send message. Recipient was not found in the system.")  
      throw new Error("Recipient was not found in the system.");
    }

    const recipient: string = recipientResult;

    const notificationMsg: NotificationMessage = {
      recipientAccountId: recipient,
      senderAccountId: sender,
      serviceType: ServiceType.NetworkConnect,
      notificationType: NotificationEntity.NetConn_Connected_User,
      notificationSeverityLevel: NotificationSeverity.Success,
      // eslint-disable-next-line max-len
      message: `The user "${userProfile?.data.name} ${userProfile?.data.surname}" "${userProfile?.data.email}" from "${senderCompanyName}" has requested to join your company!`,
      senderCompanyId: senderCompanyId || 0,
      buttonTitle: "Invite User",
      redirectUrl: "/usermanagement",
    }

    AddNewNotification(notificationMsg)
      .then(() => {
        toast.success("Request sent to the company admin");
        closeModal();
      })
      .catch((error) => {
        toast.error("Error sending request to join company");
        throw (error);
      });
  }

  const requestToJoinCompany = async () => {
    try {
      sendUserConnectNotification(duplicateCompanyId || 0);
    } catch (err) {
      toast.error("Error sending request to join company");
    }
  };

  const DuplicateCheckModalComponent = () => (
    <DuplicateCheckModal
      open={showModal}
      onRequestJoin={requestToJoinCompany}
      onClose={closeModal}
    />
  );

  return {
    checkDuplicate,
    DuplicateCheckModalComponent,
  };
};

export default useDuplicateChecker;