import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { FieldModel, FieldArrayModel } from "../Models/FieldModel";

const initialFieldState: FieldArrayModel = {
  allFields: [],
  particularField: {
    id: 0,
    name: "",
    label: "",
    variant: "",
    page: "",
    tab: "",
    fieldGroup: "",
    affinity: "",
    fieldOrder: 0,
    dateCreated: new Date,
    isActive: false,
    dateModified: new Date,
    modifiedBy: "",
    createdBy: "",
    type: "",
  },
}

const FieldSlice = createSlice({
  name: "validationFields",
  initialState: initialFieldState,
  reducers: {
    setField(state, action: PayloadAction<FieldModel[]>) {
      state.allFields = action.payload; // eslint-disable-line no-param-reassign
    },
    setParticularField(state, action: PayloadAction<FieldModel>) {
      state.particularField = action.payload; // eslint-disable-line no-param-reassign
    },
  },
})

export default FieldSlice;


export const getFields = (state: RootState) => state.validationFields.allFields;