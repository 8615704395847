/* eslint-disable react/require-default-props */
import { FormControl, FormLabel, Typography, FormGroup, Grid, FormControlLabel, Checkbox, TextField, Box } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import debounce from "lodash.debounce";
import FormDialog from "../../Dialogs/FormDialog";
import InLineSpinner from "../../LoadingSpinners/InlineSpinner";

interface CategoryOptions{
  id: number,
  name: string,
}
interface CategorySelectorOptions extends CategoryOptions {
  subCategories: CategoryOptions[],
}

const MutliLevelItemSelector = ({
  options,
  subCategorySelection,
  isLoadingSearchResults,
  handleClose,
  onSubmitSelectionCallback,
  title,
  description,
  subDescription,
}: {
  options: CategorySelectorOptions[],
  subCategorySelection: number[],
  isLoadingSearchResults: boolean,
  handleClose: () => void,
  // eslint-disable-next-line no-unused-vars
  onSubmitSelectionCallback: (selection: number[]) => void,
  title: string,
  description: string,
  subDescription?: string,
}) => {
  const [internalSelection, setInternalSelection] = useState<number[]>(subCategorySelection)
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<CategorySelectorOptions[]>([]);

  const handleOnGlobalCategoryChange = (checked: boolean, currentItemId: number) => {
    checked ?
      setInternalSelection(prevState => [...prevState, currentItemId])
      :
      setInternalSelection(prevState => prevState.filter((x) => x !== currentItemId));
  }

  const handleOnSearchTextChange = (searchTextStr: string) => {
    setSearchText(searchTextStr);
    const foundCategories = searchTextStr.length > 0 ?
      options.filter((opt) => opt.name.toLowerCase().includes(searchTextStr.toLowerCase()) ||
        opt.subCategories.some((subCat) => subCat.name.toLowerCase().includes(searchTextStr.toLowerCase()))) :
      options;
    setSearchResults(foundCategories);
  }

  useEffect(() => {
    setInternalSelection(subCategorySelection)
  }, [subCategorySelection])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResults = useMemo(() => debounce(handleOnSearchTextChange, 300), []);

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  const renderCategoryCheckList = () => (searchText.length > 0 ? searchResults : options).map((opt: CategorySelectorOptions) => (
    <FormControl key={opt.id} sx={{ m: 3, mb: 4, width: "100%" }} component="fieldset" variant="standard">
      <FormLabel component="legend">
        <Typography variant="h5" component="p">
          {opt.name}
        </Typography>
      </FormLabel>
      <FormGroup key={opt.id} row>
        <Grid item container xs={12} >
          {
            opt.subCategories.map((subCat: CategoryOptions) => (
              <Grid item xs={4} key={subCat.id}>
                <FormControlLabel
                  key={subCat.id}
                  control={
                    <Checkbox key={subCat.id}
                      checked={internalSelection.includes(subCat.id)}
                      onChange={(ev) => handleOnGlobalCategoryChange(ev.target.checked, subCat.id)}
                    />
                  }
                  label={subCat.name}
                />
              </Grid>
            ))
          }
        </Grid>
      </FormGroup>
    </FormControl>
  ))

  return (
    <FormDialog
      submitButtonText="Save Changes"
      closeButtonText="Discard Changes"
      handleClose={handleClose}
      handleSubmit={() => {
        onSubmitSelectionCallback(internalSelection);
        handleClose();
      }}
      title={title}
      description={description}
      subDescription={subDescription}
    >
      <div>
        <Grid item container xs={12} sx={{ m: 1 }} spacing={2}>
          <>
            <TextField
              label="search for products or services"
              variant="filled"
              onChange={(ev) => debouncedResults(ev.target.value)}
              sx={{ my: 3, width: "90%" }}
            />
            {isLoadingSearchResults && <InLineSpinner />}
            {
              isLoadingSearchResults === false && searchText.length > 0 && options.length === 0 ?
                <Box>
                  <b>No results found</b>
                </Box> :
                renderCategoryCheckList()
            }
          </>
        </Grid>
      </div>
    </FormDialog>
  )
}

export default MutliLevelItemSelector;