import {
  useQuery,
} from "@tanstack/react-query"
import queryKeys from "./queryKeys";
import { GetBankAccountTypes } from "../../../http/Company/bankingDetails";

const useBankAccountTypes = () => {
  const query = useQuery({
    queryKey: [queryKeys.bankAccountTypes],
    queryFn: async () => (GetBankAccountTypes()),
    staleTime: 600000,
  })

  return query;
}

export default useBankAccountTypes;