/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Permission } from "../../CompanyUserManagement/Messages/Permission";

export interface UserState {
  user: {
    photoUrl: string,
    email: Array<string>,
    displayName: string,
  };
  allowedPermissions: Permission[],
}

const initialState: UserState = {
  user: { photoUrl: "", email: [""], displayName: "" },
  allowedPermissions:[],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = { photoUrl: "", email: [""], displayName: "" };
    },
    setAllowedPermissions: (state,action) => {
      state.allowedPermissions = action.payload;
    },
  },
});

export const { login, logout, setAllowedPermissions } = userSlice.actions;


export const selectUser = (state: RootState) => state.user.user;
export const selectUserPermission = (state: RootState) => state.user.allowedPermissions;
export default userSlice.reducer;