import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPencilSquare, faCancel } from "@fortawesome/free-solid-svg-icons";

import "../CompanyPost/CompanyPost.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import styles from "../../../pages/Company/CompanyProfile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import WithRequiredPermission from "../../WithRequiredPermission";
import PermissionClassification from "../../../util/enums/PermissionClassification";
import { CompanySpecificSupplierDetailModel } from "../../../http/Company/Models/CompanySpecificClientDetailModel";
import {
  addCompanySpecificSupplierInfo,
  doesRecordExist,
  getCompanySpecificSupplierDetail,
  updateCompanySpecificSupplierInfo,
} from "../../../http/Company/CompanySpecificSupplierDetails";
import { fetchExclusionReasons } from "../../../http/Redux/Store/ExclusionReasonListActions";
import MyLoader from "../../UI/LoadingOverlay";
import { ValidationResult } from "../../../util/helperObjects/ValidationResult";
import UnsavedChangeDialog from "../../../pages/Company/UnsavedChangeDialog";
import ClientSpecificSupplierCategories from "./ClientSpecificSupplierCategories/ClientSpecificSupplierCategories";

interface CompanySpecificSupplierDetailProps {
  clientCompanyId: number;
  supplierCompanyId: number;
  pageChanging: boolean;
  isDirty: boolean;
  staged: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsDirtyOnParent: (isDirty: boolean) => void;

  // eslint-disable-next-line no-unused-vars
  setPageChanging: (changePage: boolean) => void;
}

const CompanySpecificClientDetails = ({ ...props }: CompanySpecificSupplierDetailProps) => {
  const dispatch = useAppDispatch();
  const initialLoad = useRef(true);

  const exclusionReasons = useAppSelector((x) => x.exclusionReasonList);

  const [edit, setEdit] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [supplierDataComparison, setSupplierDataComparison] =
    useState<CompanySpecificSupplierDetailModel>({} as CompanySpecificSupplierDetailModel);

  const [supplierData, setSupplierData] = useState<CompanySpecificSupplierDetailModel>(
    {} as CompanySpecificSupplierDetailModel
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const validateEntries = (dataToValidate: CompanySpecificSupplierDetailModel) => {
    if (
      dataToValidate.excludeFromSpend &&
      (dataToValidate.exclusionReasonId === null || dataToValidate.exclusionReasonId < 0)
    )
      return {
        result: false,
        message: "Please select an exclusion reason.",
      } as ValidationResult;

    if (!dataToValidate.mySupplierName)
      return {
        result: false,
        message: "Please provide a supplier name.",
      } as ValidationResult;

    if (!dataToValidate.mySupplierNumber)
      return {
        result: false,
        message: "Please provide a supplier number.",
      } as ValidationResult;

    return {
      result: true,
      message: "",
    } as ValidationResult;
  };

  // eslint-disable-next-line no-unused-vars
  const doesSupplierRecordExist = async (clientId: number, supplierId: number) => {
    try {
      setIsLoading(true);
      const result = await doesRecordExist(clientId, supplierId, props.staged);

      // 409 represents a conflict. This means the record already exists.
      if (result.status === 409) return true;

      return false;
    } catch (error) {
      toast.error(
        "An error occurred when checking if a record already exists for this client/supplier pair."
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const saveCompanySpecificSupplierDetails = async (
    supplierDetails: CompanySpecificSupplierDetailModel,
    newRecord: boolean
  ) => {
    const detailsToSave: CompanySpecificSupplierDetailModel = JSON.parse(
      JSON.stringify(supplierDetails)
    );

    try {
      setIsLoading(true);
      if (newRecord) {
        await addCompanySpecificSupplierInfo(detailsToSave, props.staged);
      } else {
        await updateCompanySpecificSupplierInfo(detailsToSave, props.staged);
      }

      toast.success("Supplier data has been saved successfully.");
    } catch (error) {
      toast.error(
        "An error occurred when saving the Supplier data. Please try again, or contact support."
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const performDirtyCheck = (checkVal: CompanySpecificSupplierDetailModel) => {
    if (JSON.stringify(checkVal) !== JSON.stringify(supplierDataComparison)) {
      props.setIsDirtyOnParent(true);
      return;
    }

    props.setIsDirtyOnParent(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: string
  ) => {
    const newVal = { ...supplierData, [propName]: event.target.value };
    setSupplierData(newVal);
    performDirtyCheck(newVal);
  };

  const handleExcludeFromSpendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    const newVal: CompanySpecificSupplierDetailModel = {
      ...supplierData,
      excludeFromSpend: checked,
      exclusionReasonId: checked ? supplierData.exclusionReasonId || 0 : 0,
      doNotContact: true,
    };
    setSupplierData(newVal);
    performDirtyCheck(newVal);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, propName: string) => {
    const newVal = { ...supplierData, [propName]: event?.target.checked };
    setSupplierData(newVal);
    performDirtyCheck(newVal);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    props.setPageChanging(false);
  }

  const handleSave = async () => {
    const validEntries: ValidationResult = validateEntries(supplierData);

    if (validEntries.result === false) {
      Swal.fire("Validation error", validEntries.message, "error");
      return;
    }

    // recordCheck is true if the record already exists.
    const recordCheck = await doesSupplierRecordExist(
      props.clientCompanyId,
      props.supplierCompanyId
    );

    // Save a new record
    if (!recordCheck) {
      saveCompanySpecificSupplierDetails(supplierData, true);
      setSupplierDataComparison({ ...supplierData });
      props.setPageChanging(false);
      props.setIsDirtyOnParent(false);
      return;
    }

    saveCompanySpecificSupplierDetails(supplierData, false);
    setSupplierDataComparison({ ...supplierData });
    props.setPageChanging(false);
    props.setIsDirtyOnParent(false);
  };

  const handleRevert = () => {
    setSupplierData(supplierDataComparison);
    props.setPageChanging(false);
    props.setIsDirtyOnParent(false);
  }

  const EnableEdit = async () => {
    if (edit) {
      handleSave();
      setEdit(false);
    }

    setEdit(!edit);
  };

  const CancelEdit = () => {
    handleRevert();
    setEdit(false);
  };

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      if (!exclusionReasons || exclusionReasons.length === 0)
        await dispatch(fetchExclusionReasons(props.staged));

      const supplierDataFromApi = await getCompanySpecificSupplierDetail(
        props.clientCompanyId,
        props.supplierCompanyId,
        props.staged
      );

      let finalSupplierData: CompanySpecificSupplierDetailModel;

      if (Object.keys(supplierDataFromApi).length === 0) {
        finalSupplierData = {
          clientCompanyId: props.clientCompanyId,
          supplierCompanyId: props.supplierCompanyId,
          partnerManagedSupplier: false,
        } as CompanySpecificSupplierDetailModel;
      } else {
        finalSupplierData = supplierDataFromApi;
      }

      setSupplierDataComparison(JSON.parse(JSON.stringify(finalSupplierData)));
      setSupplierData(finalSupplierData);
      setIsLoading(false);
    };

    if (initialLoad.current) {
      loader();

      initialLoad.current = false;
    }
  }, [props.isDirty]);

  useEffect(() => {
    if (props.pageChanging && props.isDirty) {
      setDialogOpen(true);
    }
  }, [props.isDirty, props.pageChanging]);

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        id="step-address"
        onChange={handleAccordionChange("panel1")}
        className={styles.firstAccord}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expand-icon-white" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="Accord-heading"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3 className="heading-sub-3">Company-Specific Supplier Details</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MyLoader active={isLoading}>
            <div className={styles.editSave}>
              <WithRequiredPermission permission={PermissionClassification.EditCompanyprofile}>
                {!edit && (
                  <Tooltip title="Edit">
                    <FontAwesomeIcon
                      icon={faPencilSquare}
                      aria-hidden="true"
                      id="edit-supplier-details"
                      onClick={EnableEdit}
                      className="hover-cursor"
                    />
                  </Tooltip>
                )}
                {edit && (
                  <div className={styles.btnBox}>
                    <div className={styles.btnBoxLeft}>
                      <Tooltip title="Save">
                        <FontAwesomeIcon
                          icon={faFloppyDisk}
                          aria-hidden="true"
                          id="edit-save-supplier"
                          onClick={EnableEdit}
                          className="hover-cursor"
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.btnBoxRight}>
                      <Tooltip title="Cancel Edit">
                        <FontAwesomeIcon
                          icon={faCancel}
                          aria-hidden="true"
                          id="edit-cancel-supplier"
                          onClick={CancelEdit}
                          className="hover-cursor"
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </WithRequiredPermission>
            </div>
            <div className={styles.postBody}>
              <div className={styles.postInfo}>
                <Grid container className="g-container" spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      disabled={!edit}
                      sx={{ width: "100%" }}
                      id="vendor-number"
                      value={supplierData.mySupplierNumber || ""}
                      label="My Supplier Number/Vendor Number"
                      size="small"
                      onChange={(ev) => handleValueChange(ev, "mySupplierNumber")}
                      error={edit && !supplierData.mySupplierNumber}
                      helperText={
                        edit &&
                        !supplierData.mySupplierNumber &&
                        "Please enter a supplier/vendor number"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Do Not Contact (No RFI - for this client only)"
                      control={
                        <Checkbox
                          checked={supplierData.doNotContact || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            handleCheckboxChange(ev, "doNotContact");
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="g-container" spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      disabled={!edit}
                      sx={{ width: "100%" }}
                      id="my-supplier-name"
                      value={supplierData.mySupplierName || ""}
                      label="My Supplier Name"
                      size="small"
                      onChange={(ev) => handleValueChange(ev, "mySupplierName")}
                      error={edit && !supplierData.mySupplierName}
                      helperText={
                        edit && !supplierData.mySupplierName && "Please enter a supplier name"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="g-container" spacing={2}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Three Year Contract"
                      control={
                        <Checkbox
                          checked={supplierData.threeYearContract || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            setSupplierData((prevState) => ({
                              ...prevState,
                              threeYearContract: ev.target?.checked ?? false,
                            }));
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Supplier Development Beneficiary"
                      control={
                        <Checkbox
                          checked={supplierData.supplierDevelopmentBeneficiary || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            setSupplierData((prevState) => ({
                              ...prevState,
                              supplierDevelopmentBeneficiary: ev.target?.checked ?? false,
                            }));
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Local Supplier"
                      control={
                        <Checkbox
                          checked={supplierData.localSupplier || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            setSupplierData((prevState) => ({
                              ...prevState,
                              localSupplier: ev.target?.checked ?? false,
                            }));
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Exclude from spend"
                      control={
                        <Checkbox
                          checked={supplierData.excludeFromSpend || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            handleExcludeFromSpendChange(ev);
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Exclude from spend"
                      control={
                        <Checkbox
                          checked={supplierData.excludeFromSpend || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            handleExcludeFromSpendChange(ev);
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {supplierData.excludeFromSpend && (
                      <TextField
                        select
                        label="Exclusion Reason"
                        id="exclusion-reason"
                        margin="dense"
                        inputProps={{ style: { fontSize: 14 } }}
                        sx={{ width: "100%", marginTop: 0 }}
                        value={
                          supplierData.exclusionReasonId?.toString() ||
                          supplierData.exclusionReasonId?.toString() === "0"
                        }
                        size="small"
                        disabled={!edit}
                        error={
                          edit &&
                          supplierData.excludeFromSpend &&
                          (supplierData.exclusionReasonId ?? "") === ""
                        }
                        helperText={
                          edit &&
                          supplierData.excludeFromSpend &&
                          (supplierData.exclusionReasonId ?? "") === "" &&
                          "Please select an exclusion reason"
                        }
                        onChange={(ev) => {
                          ev.preventDefault();
                          handleValueChange(ev, "exclusionReasonId");
                        }}
                      >
                        <MenuItem value="">
                          <em>Please Select</em>
                        </MenuItem>
                        {exclusionReasons.map((exReason) => (
                          <MenuItem value={exReason.exclusionReasonId}>
                            {exReason.shortReason}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
                <Grid container className="g-container" spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      label="3 Year Contact (SD Recipient)"
                      control={
                        <Checkbox
                          checked={supplierData.threeYearContract || false}
                          disabled={!edit}
                          onChange={(ev) => {
                            handleCheckboxChange(ev, "threeYearContract");
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                {/* <Grid container className="g-container" spacing={2}>
                  <Grid item xs={12}>
                    <ClientSpecificSupplierCategories
                      clientCompanyId={props.clientCompanyId}
                      supplierCompanyId={props.supplierCompanyId}
                    />
                  </Grid>
                </Grid> */}
              </div>
            </div>
          </MyLoader>
        </AccordionDetails>
      </Accordion>
      <ClientSpecificSupplierCategories
        clientCompanyId={props.clientCompanyId}
        supplierCompanyId={props.supplierCompanyId}
      />

      <UnsavedChangeDialog
        openDialog={dialogOpen}
        onCloseOrCancel={() => {
          handleDialogClose();
        }}
        onDiscardChanges={() => {
          handleRevert();
          handleDialogClose();
        }}
        onSaveChanges={() => {
          handleSave();
          handleDialogClose();
        }}
      />
    </>
  );
};

export default CompanySpecificClientDetails;
