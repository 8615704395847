import React from "react";
import { Box, Typography } from "@mui/material";
import DialogModal from "../../../components/Dialogs/DialogModal";
import ButtonGroup from "./ButtonGroup";
import { colorNotificationFailed } from "../../../util/MUI_Theme/Theme";

interface DuplicateCheckModalProps {
  open: boolean;
  onRequestJoin: () => void;
  onClose: () => void;
}

const DuplicateCheckModal: React.FC<DuplicateCheckModalProps> = ({ open, onRequestJoin, onClose }) => (
  <DialogModal
    isOpen={open}
    onHandleClose={onClose}
    aria-labelledby="modal-duplicate-check"
    aria-describedby="modal-duplicate-check-description"
  >
    <Box pb={5} px={6}>
      <Typography variant="h3" color={colorNotificationFailed} >Duplicate Registration Number Detected.</Typography>
      <Box p={4}>
        <Typography variant="h5" sx={{ mb: 2 }} >
            We detected a duplicate in our database of this registration number.
        </Typography >
        <Typography variant="h5" > 
            You can request to join the company, whereby a notification will be sent to the admin or contact support for further assistance.
        </Typography>
      </Box>
    </Box>

    <ButtonGroup
      buttons={[{
        widthInPx: 290,
        label: "Cancel",
        align: "left",
        type: "secondary",
        onClick: onClose,
      },
      {
        widthInPx: 290,
        label: "Request to Join Company",
        align: "right",
        type: "primary",
        onClick: onRequestJoin,
      }]}
    />
  </DialogModal>

);

export default DuplicateCheckModal;
