import { useMemo } from "react";
import { UnspscSegment } from "../../../http/Configuration/UnspscSegment";

const useSimpleTextSearchSegmentsAndFamilies = (segments: UnspscSegment[]) => {
  const search = useMemo(() => (searchText: string) => {
    const filteredSegmentAndFamilies = segments.map(seg => ({
      ...seg,
      families: seg.families.filter(fam =>
      // Check if either segment name or family name contains the search text
        seg.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          fam.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      ),
    })).filter(seg => seg.families.length > 0);

    return filteredSegmentAndFamilies;
  }, [segments]);

  return { search };
}

export default useSimpleTextSearchSegmentsAndFamilies;
