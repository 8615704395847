import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import { useAccount, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import MultiItemSelector from "../../MultiItemSelector/MultiItemSelector";
import { SubscriptionProductModel } from "../../../http/Redux/Models/SubscriptionProductModel";
import {
  fetchFeatures,
  fetchPlans,
  fetchProductAddons,
  fetchProductFeatures,
  fetchProductPlans,
  fetchProducts,
} from "../../../http/Redux/Store/SubscriptionActions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SubscriptionFeatureModel } from "../../../http/Redux/Models/SubscriptionFeatureModel";
import { getAllFeatures, getAllPlans, getAllProducts } from "../../../http/Redux/Store/SubscriptionSlice";
import { SubscriptionPlanModel } from "../../../http/Redux/Models/SubscriptionPlanModel";
import SubscriptionService from "../../../http/SubscriptionManagement/SubscriptionApi";
import { ProductFeatures } from "../../../http/Redux/Models/ProductFeatures";
import { ProductPlans } from "../../../http/Redux/Models/ProductPlans";
import { ProductAddon } from "../../../http/Redux/Models/ProductAddon";


const EditProduct = ({ backToView, selectedProduct }: { backToView: () => void, selectedProduct: SubscriptionProductModel }) => {
  const features: SubscriptionFeatureModel[] = useAppSelector(getAllFeatures);
  const plans: SubscriptionPlanModel[] = useAppSelector(getAllPlans);
  const addons: SubscriptionProductModel[] = useAppSelector(getAllProducts);


  const productFeatures = useAppSelector((x) => x.subscriptionProductFeatures.allViewSubscriptionProductFeatures);
  const productPlans = useAppSelector((x) => x.subscriptionproductPlans.allViewSubscriptionProductPlans);
  const productAddons = useAppSelector((x) => x.subscriptionProductAddons.allViewSubscriptionProductAddons);

  const [selectedFeatures, setSelectedFeatures] = useState<number[]>([]);
  const [showFeatures, setShowFeatures] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState<number[]>([]);
  const [showPlans, setShowPlans] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState<number[]>([]);
  const [showAddons, setShowAddons] = useState(false);
  const [loading, isLoading] = useState(false);
  const [product, setProduct] = useState<SubscriptionProductModel>(selectedProduct as SubscriptionProductModel);
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  useEffect(() => {

    setSelectedFeatures(productFeatures.map(x => x.featureId));
    setSelectedPlans(productPlans.map(x => x.planId));
    setSelectedAddons(productAddons.map(x => x.addonId));

  }, [])


  useEffect(() => {

    if (features.length === 0 || productFeatures.length === 0) {
      const loader = async () => {
        isLoading(true);
        dispatch(fetchFeatures());
        dispatch(fetchPlans());
        dispatch(fetchProducts());
        dispatch(fetchProductFeatures(product.id));
        dispatch(fetchProductPlans(product.id));
        dispatch(fetchProductAddons(product.id));
        isLoading(false);
      }
      loader();
    }

  }, [])

  const handleSave = async () => {
    const addProducts: SubscriptionProductModel = {} as SubscriptionProductModel;
    addProducts.dateModifed = new Date();
    addProducts.productName = product.productName;
    addProducts.createdBy = product.createdBy;
    addProducts.dateCreated = product.dateCreated;
    addProducts.id = product.id;
    addProducts.validStart = product.validStart;
    addProducts.validEnd = product.validEnd;
    addProducts.modifiedBy = userAccount || null;
    addProducts.isAddon = product.isAddon === undefined ? false : product.isAddon;
    addProducts.isActive = product.isActive === undefined ? false : product.isActive;
    addProducts.displayInWidget = product.displayInWidget === undefined ? 0 : product.displayInWidget;
    addProducts.baseSubscription = product.baseSubscription === undefined ? false : product.baseSubscription;

    try {

      isLoading(true);
      await SubscriptionService.updateProduct(addProducts);

      const featuresToRemove = productFeatures.filter(element => !selectedFeatures.includes(element.featureId));
      const featuresToAdd = selectedFeatures.filter(Id => !productFeatures.some(element => element.featureId === Id));

      featuresToAdd.forEach(async element => {

        const add: ProductFeatures = {} as ProductFeatures;
        add.featureId = element;
        add.productId = product.id as number;
        add.dateCreated = new Date();
        add.createdBy = userAccount || null;

        await SubscriptionService.addProductFeatures(add);



      });
      featuresToRemove.forEach(async element => {
        await SubscriptionService.deleteProductFeatures(element.productFeatureId);
      });

      const plansToRemove = productPlans.filter(element => !selectedPlans.includes(element.planId));
      const plansToAdd = selectedPlans.filter(Id => !productPlans.some(element => element.planId === Id));

      plansToAdd.forEach(async element => {
        const add: ProductPlans = {} as ProductPlans;
        add.planId = element;
        add.productId = product.id as number;
        add.dateCreated = new Date();
        add.createdBy = userAccount || null;

        await SubscriptionService.addProductPlans(add);

      });

      plansToRemove.forEach(async element => {
        await SubscriptionService.deleteProductPlans(element.productPlanId);
      });

      const addonsToRemove = productAddons.filter(element => !selectedAddons.includes(element.addonId));
      const addonsToAdd = selectedAddons.filter(Id => !productAddons.some(element => element.addonId === Id));

      addonsToAdd.forEach(async element => {
        const add: ProductAddon = {} as ProductAddon;
        add.addonId = element;
        add.productId = product.id as number;
        add.dateCreated = new Date();
        add.createdBy = userAccount || null;

        await SubscriptionService.addProductAddon(add);

      });
      addonsToRemove.forEach(async element => {
        await SubscriptionService.deleteProductAddons(element.productAddonId);
      });

    }
    catch {
      isLoading(false);
      toast.success("Product Failed to Update");
    } finally {
      isLoading(false);
      toast.success("Product Updated");
      backToView();
    }
  }

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Users">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                label="Name"
                size="small"
                type="text"
                sx={{ width: "98%" }}
                variant="outlined"
                name="firstName"
                value={product?.productName ?? ""}
                onChange={(ev) => setProduct({ ...product, productName: ev.target.value })}
              />
            </Grid>
            <Grid xs={6}>
              <TextField value={product.redirectURL}
                onChange={(ev) => { setProduct({ ...product, redirectURL: ev.target.value }); }}
                id="name"
                label="Redirect URL"
                size="small"
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={product.isActive}
                  onClick={() => setProduct({ ...product, isActive: !product.isActive })} />}
                label="Is Active" />
            </Grid>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={product.baseSubscription}
                  onClick={() => setProduct({ ...product, baseSubscription: !product.baseSubscription })} />}
                label="Base Subscription" />
            </Grid>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={product.isAddon}
                  onClick={() => setProduct({ ...product, isAddon: !product.isAddon })} />}
                label="Is Addon" />
            </Grid>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={product.displayInWidget !== 0 && product.displayInWidget !== undefined}
                  onClick={() => setProduct({ ...product, displayInWidget: product.displayInWidget !== 0 ? 1 : 0 })} />}
                label="Display In Widget" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  value={product.validStart ?? ""}
                  onChange={(ev) => setProduct({ ...product, validStart: ev as Date })}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ width: "98%" }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  value={product.validEnd ?? ""}
                  onChange={(ev) => setProduct({ ...product, validEnd: ev as Date })}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={4}>
              <Tooltip title="Select Features">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={() => setShowFeatures(true)}>
                  <AddIcon /> Select Features
                </Button>
              </Tooltip>
              {showFeatures &&
                <MultiItemSelector
                  title="Features"
                  description="Please select a list of features"
                  hasSearchInput
                  onSubmitSelectionCallback={(permissionIdList) => { setSelectedFeatures(permissionIdList); }}
                  handleClose={() => setShowFeatures(false)}
                  optionsList={features.map((x) => ({ id: x.id, displayValue: x.featureName }))}
                  currentSelectionArray={selectedFeatures}
                />
              }
            </Grid>
            <Grid xs={4}>
              <Tooltip title="Select Plans">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={() => setShowPlans(true)}>
                  <AddIcon /> Select Plans
                </Button>
              </Tooltip>
              {showPlans &&
                <MultiItemSelector
                  title="Plans"
                  description="Please select a list of Plans"
                  hasSearchInput
                  onSubmitSelectionCallback={(permissionIdList) => { setSelectedPlans(permissionIdList); }}
                  handleClose={() => setShowPlans(false)}
                  optionsList={plans.map((x) => ({ id: x.id, displayValue: x.planCode }))}
                  currentSelectionArray={selectedPlans}
                />
              }
            </Grid>
            <Grid xs={4}>
              <Tooltip title="Select Addons">
                <Button variant="outlined" className="form-edit-btn-sub" onClick={() => setShowAddons(true)}>
                  <AddIcon /> Select Addons
                </Button>
              </Tooltip>
              {showAddons &&
                <MultiItemSelector
                  title="Addons"
                  description="Please select a list of addons"
                  hasSearchInput
                  onSubmitSelectionCallback={(permissionIdList) => { setSelectedAddons(permissionIdList); }}
                  handleClose={() => setShowAddons(false)}
                  optionsList={addons.filter(x => x.isAddon).map((x) => ({ id: x.id, displayValue: x.productName }))}
                  currentSelectionArray={selectedAddons}
                />
              }
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" onClick={() => handleSave()} className="form-add-btn">Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};


export default EditProduct;