/* eslint-disable no-unused-vars */
import { Badge, BadgeProps, Button, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/Check";
import { OnBoardingRuleSetViewModel } from "../../http/Redux/Models/OnBoardingRuleSetViewModel";
import { ArtefactToString, ArtefactTypes, ChangedArtefacts, StepsModel } from "./StepsModel";
import SetupDropDown from "./SetupDropDown";
import { CompanyViewModel } from "../../http/Company/Models/CompanyViewModel";
import { DocumentDtoModel } from "../../http/DocumentManagement/Models/DocumentDtoModel";


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 19,
  },
}));

const GetSetState = ({ Set, handleChange, handleNavigation, steps, company, files }: {
  Set: OnBoardingRuleSetViewModel,
  handleChange: (value: ChangedArtefacts) => void,
  steps: StepsModel[],
  company: CompanyViewModel,
  handleNavigation: (description: string, length: number, index: number) => void,
  files: DocumentDtoModel[]

}) => {

  const [theValue, setTheValue] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>();
  const [documentUploaded, setDocumentUploaded] = useState(false);

  const handleCheckReg = () => {
    const regex = /^(null|undefined)$/;

    if (Set.mandatory) {
      const isMandatory = regex.test((company as any)[ArtefactToString(Set.artefactId as number)]);
      return isMandatory;
    }
    return false;
  }

  useEffect(() => {
    setCurrentValue((company as any)[ArtefactToString(Set.artefactId as number)]);
    setDocumentUploaded(files.find(x => x.typeId === Set.documentType)?.companyId !== 0);
  }, [documentUploaded, files.length])

  const handleSendToList = (newValue: any, artefact: number) => {
    const newList = {} as ChangedArtefacts;
    newList.value = newValue;
    newList.artefactId = artefact;
    handleChange(newList);
  }
  if (Set.artefactTypeId === ArtefactTypes.Text) {
    return (
      <StyledBadge sx={{ width: "97%" }} badgeContent={currentValue ? <CheckCircleIcon sx={{ width: 8, height: 8 }} /> : <ErrorIcon sx={{ width: 8, height: 8 }} />} color={currentValue ? "success" : "error"}>
        <TextField
          size="small"
          error={handleCheckReg()}
          type="text"
          value={currentValue}
          onChange={(ev) => {
            setTheValue(ev.target.value);
            setCurrentValue(ev.target.value);
          }}
          inputProps={{
            onBlur: () => { handleSendToList(theValue, Set.artefactId) },
          }}
          sx={{ width: "100%" }}
          label={Set.artefactName} />
      </StyledBadge>

    )
  }
  if (Set.artefactTypeId === ArtefactTypes.Number) {
    return (

      <StyledBadge sx={{ width: "97%" }} badgeContent={currentValue ? <CheckCircleIcon sx={{ width: 8, height: 8 }} /> : <ErrorIcon sx={{ width: 8, height: 8 }} />} color={currentValue ? "success" : "error"}>
        <TextField
          size="small"
          type="text"
          value={currentValue}
          onChange={(ev) => {
            setTheValue(ev.target.value);
            setCurrentValue(ev.target.value);
          }}
          inputProps={{
            onBlur: () => { handleSendToList(theValue, Set.artefactId) },
          }}
          sx={{ width: "98%" }}
          label={Set.artefactName} />
      </StyledBadge>
    )
  }
  if (Set.artefactTypeId === ArtefactTypes.Document) {
    return (
      <StyledBadge sx={{ width: "97%" }} badgeContent={documentUploaded ? <CheckCircleIcon sx={{ width: 8, height: 8 }} /> : <ErrorIcon sx={{ width: 8, height: 8 }} />} color={documentUploaded ? "success" : "error"}>
        <Button
          component="label"
          variant="outlined"
          sx={{ width: "100%", marginTop: "3px" }}
          disabled={documentUploaded}
          onClick={() => handleNavigation(steps[steps.length - 1].description as string, steps.length, steps.length - 1)}
          startIcon={<CloudUploadIcon />}>
          <p>{documentUploaded}</p>
          Upload {Set.artefactName}
        </Button>
      </StyledBadge>

    )
  }
  if (Set.artefactTypeId === ArtefactTypes.Dropdown) {
    return (
      <StyledBadge sx={{ width: "97%" }} badgeContent={currentValue ? <CheckCircleIcon sx={{ width: 8, height: 8 }} /> : <ErrorIcon sx={{ width: 8, height: 8 }} />} color={currentValue ? "success" : "error"}>
        <SetupDropDown Set={Set} handleSendToList={handleSendToList} company={company} />
      </StyledBadge>
    )
  }

  return <TextField label="Default" onChange={(ev) => handleSendToList(ev.target.value, Set.artefactId)} />

}

export default GetSetState;