import { LinearProgressProps } from "@material-ui/core";
import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

const LinearProgressWithLabel = (props: LinearProgressProps & {value: number}) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box sx={{ width: "100%", mr: 1 }}>
      <LinearProgress variant="determinate" {...props} color="success" />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  </Box>
);

export default LinearProgressWithLabel;
