import { protectedResources } from "../../authConfig";
import makeGetRequestAsync, { makePUTRequestAsync, makePostRequestAsync } from "../Helpers/httpRequestHelpers";
import { CoidaDetails } from "./Models/CoidaDetails";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

export async function GetCompanyCoidaDetails(companyId: number) {
  const url = `${baseUrl}/api/Company/CompanyCoidaDetails/GetCompanyCoidaDetails/${companyId}`;
  return makeGetRequestAsync<CoidaDetails>(apiScopes, url);
}

export async function AddCompanyCoidaDetails(companyCoidaDetails: CoidaDetails) {
  const url = `${baseUrl}/api/Company/CompanyCoidaDetails/AddCompanyCoidaDetails`;
  return makePUTRequestAsync<void,CoidaDetails>(apiScopes, url, companyCoidaDetails);
}

export async function UpdateCompanyCoidaDetails(companyCoidaDetails: CoidaDetails) {
  const url = `${baseUrl}/api/Company/CompanyCoidaDetails/UpdateCompanyCoidaDetails`;
  return makePostRequestAsync<void,CoidaDetails>(apiScopes, url, companyCoidaDetails);
}

export async function DeleteCompanyCoidaDetails(companyCoidaDetails: CoidaDetails) {
  const url = `${baseUrl}/api/Company/CompanyCoidaDetails/DeleteCompanyCoidaDetails`;
  return makePostRequestAsync<void,CoidaDetails>(apiScopes, url, companyCoidaDetails);
}