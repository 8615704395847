/* eslint-disable no-shadow */

import { colorNotificationFailed, colorNotificationValid, colorNotificationWarning, colorPrimaryActive } from "../MUI_Theme/Theme";

/* eslint-disable no-unused-vars */
export enum NotificationEntityTypes {
  Message = 5,
  Documents = 9,
  Onboarding = 10,
  Netconn = 7,
  ProfileStatus = 11,
}

export default NotificationEntityTypes;

export const getNotificationColor = (value: NotificationEntityTypes) => {
  switch (value) {
    case NotificationEntityTypes.Message:
      return colorPrimaryActive;
    case NotificationEntityTypes.Onboarding:
      return colorNotificationWarning;
    case NotificationEntityTypes.Documents:
      return colorNotificationValid;
    case NotificationEntityTypes.Netconn:
      return colorPrimaryActive;
    case NotificationEntityTypes.ProfileStatus:
      return colorNotificationFailed
    default:
      return colorNotificationFailed;
  }
};
