/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import NetconnSearchType from "./NetconnSearchType";

export enum MyConnections {
  Clients = "clients",
  Suppliers = "suppliers",
  InvitedConnections = "invited",
  RequestReceived = "received",
  RequestMade = "made"
}


export const MyConnectionsTabsToSearchTypes = (value: MyConnections) => {
  switch (value) {
    case MyConnections.Clients:
      return NetconnSearchType.Client;
    case MyConnections.Suppliers:
      return NetconnSearchType.Supplier;
    case MyConnections.RequestMade:
      return NetconnSearchType.RequestReceived;
    case MyConnections.RequestReceived:
      return NetconnSearchType.RequestReceived;
    case MyConnections.InvitedConnections:
      return NetconnSearchType.None;
    default:
      return NetconnSearchType.None;
  }
};


export const MyConnectionsStringToTabs = (value: MyConnections) => {
  switch (value) {
    case MyConnections.Clients:
      return 0;
    case MyConnections.Suppliers:
      return 1;
    case MyConnections.RequestReceived:
      return 2;
    case MyConnections.RequestMade:
      return 2;
    case MyConnections.InvitedConnections:
      return 3;
    default:
      return 0;
  }
};


export const MyConnectionsTabs = (value: number) => {
  switch (value) {
    case MyConnectionsStringToTabs(MyConnections.Clients):
      return "clients";
    case MyConnectionsStringToTabs(MyConnections.Suppliers):
      return "suppliers";
    case MyConnectionsStringToTabs(MyConnections.RequestReceived):
      return "received";
    case MyConnectionsStringToTabs(MyConnections.RequestMade):
      return "made";
    case MyConnectionsStringToTabs(MyConnections.InvitedConnections):
      return "invite";
    default:
      return "clients";
  }
};