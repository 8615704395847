// import axios, {AxiosRequestConfig } from "axios";
import { protectedResources } from "../../authConfig";
import makeGetRequestAsync from "../Helpers/httpRequestHelpers";
import { ProvinceModel } from "./Models/ProvinceModel";

const apiScopes = protectedResources.companyApi.scopes;
const baseUrl = protectedResources.companyApi.endpoint;

/*
 *  Retrieves all provinces
 */
export async function GetAllProvinces() {
  const url = `${baseUrl}/api/Company/Province/Provinces`;
  return makeGetRequestAsync<Array<ProvinceModel>>(apiScopes, url);
}

/*
 *  Retrieves a specific province based on a passed province ID
 */
export async function GetProvinceById(provinceId: string) {
  const url = `${baseUrl}/api/Company/Province/${provinceId}`;
  return makeGetRequestAsync<ProvinceModel>(apiScopes, url);
}

/*
 *  Retrieves all provinces based on a passed country ID
 */
export async function GetProvincesByCountryId(countryId: string) {
  const url = `${baseUrl}/api/Company/Province/ProvincesByCountryId/${countryId}`;
  return makeGetRequestAsync<Array<ProvinceModel>>(apiScopes, url);
}
