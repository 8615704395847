import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import formatDate, { formatDateTime } from "../../util/formatter/formatDate";
import { SubscriptionBillingHistoryMessage } from "../../http/SubscriptionManagement/Messages/SubscriptionBillingHistoryMessage";
import { getSubscriptionBillingHistory } from "../../http/SubscriptionManagement/SubscriptionApi";
import { paymentStatusColor, paymentStatusToString } from "../../http/SubscriptionManagement/Enums/PaymentStatusEnum";



// Define the columns for the DataGrid
const columns = [
  {
    field: "subscriptionEffectiveDate",
    flex: 1,
    headerName: "Effective date",
    valueGetter: (params: { row: SubscriptionBillingHistoryMessage }) => formatDate(params.row.subscriptionEffectiveDate),

  },
  {
    field: "planCode",
    headerName: "Current subscription plan",
    flex: 1,
  },
  {
    field: "isUpgradeProRataPayment",
    headerName: "Payment type",
    flex: 1,
    renderCell: (params: { row: SubscriptionBillingHistoryMessage }) => (params.row.isUpgradeProRataPayment ?
      "Pro-rata upgrade fee" :
      "Recurring subscription payment"),
  },
  {
    field: "expectedPaymentDate",
    headerName: "Expected payment date",
    flex: 1,
    valueGetter: (params: { row: SubscriptionBillingHistoryMessage }) => formatDateTime(params.row.expectedPaymentDate),
  },
  {
    field: "paymentStatus",
    headerName: "Payment status",
    flex: 1,
    renderCell: (params: { row: SubscriptionBillingHistoryMessage }) =>
      <Typography color={paymentStatusColor(params.row.paymentStatus)}>
        {paymentStatusToString(params.row.paymentStatus)}
      </Typography>,
  },
  {
    field: "value", headerName: "Price",
    flex: 1,
    renderCell: (params: { row: SubscriptionBillingHistoryMessage }) =>
      (`R ${params.row.value}`),
  },
];

const SubscriptionBillingHistoryTable = () => {
  const [rows, setRows] = useState<SubscriptionBillingHistoryMessage[]>([]);

  useEffect(() => {
    const loader = async () => {
      try {
        const data = await getSubscriptionBillingHistory();
        setRows(data);
      } catch (error) {
        toast.error("Error loading subscription billing history");
      }
    }

    loader();

  }, [])


  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows}
        columns={columns}
        pageSize={10} />
    </div>
  )
};

export default SubscriptionBillingHistoryTable;