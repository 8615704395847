import { useState } from "react";

import { Alert, AlertTitle, Box, IconButton, Tooltip } from "@mui/material";

import { LinkItEmail, LinkItUrl } from "react-linkify-it";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { NotificationModel } from "../../../http/Notifications/Models/NotificationModel";
import { MarkNotificationAsRead } from "../../../http/Notifications/Notification";
import { notificationSeverityToString } from "../../../util/enums/NotificationSeverity";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";

const Notification = ({ notification, refresh }: { notification: NotificationModel, refresh: () => void }) => {
  const [isRead, setIsRead] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const handleMarkAsRead = async () => {

    setBackdropVisible(true);
    await MarkNotificationAsRead({
      id: notification.id,
      notificationSeverityId: notification.notificationSeverityId,
      notificationEntityId: notification.notificationEntityId,
      recipientAccountId: notification.recipientAccountId,
      senderAccountId: notification.senderAccountId,
      message: notification.message,
      isRead: true,
    }).finally(() => {
      setBackdropVisible(false);
      refresh();
    });

    setIsRead(true);

    toast.success("Notification marked as read.");
  };

  return (
    <div>
      <LoadingBackdrop showBackdrop={backdropVisible} />
      {!isRead && (
        <Box key={notification.id} mb={1}>
          <Alert
            severity={notificationSeverityToString(notification.notificationSeverityId)}
            action={
              notification.isRead ? (
                <Tooltip title="Read" placement="right">
                  <IconButton aria-label="Read" disabled color="primary">
                    <MarkChatReadIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Mark as read" placement="right">
                  <IconButton
                    aria-label="Mark as read"
                    onClick={() => handleMarkAsRead()}
                    disabled={isRead}
                  >
                    <MarkChatUnreadIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <AlertTitle>
              {notification.entryName}
            </AlertTitle>
            <LinkItEmail>
              <LinkItUrl>{notification.message}</LinkItUrl>
            </LinkItEmail>
          </Alert>
        </Box>
      )}
    </div>
  );
};

export default Notification;
