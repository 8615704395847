/* eslint-disable no-nested-ternary */
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../Backdrop/LoadingBackdrop";
import SubscriptionService from "../../../http/SubscriptionManagement/SubscriptionApi";
import { SubscriptionPlanModel } from "../../../http/Redux/Models/SubscriptionPlanModel";
import BillInterval from "../../../util/enums/BillInterval";
import BillCycle from "../../../util/enums/BillingCycle";


const AddPlan = ({ backToView }: { backToView: () => void }) => {
  const [loading, isLoading] = useState(false);
  const [plan, setPlan] = useState<SubscriptionPlanModel>({} as SubscriptionPlanModel);
  const [disableInt, setDisableInt] = useState(true);
  const [billEvery, setBillEvery] = useState("");
  const [billEveryActive, setBillEveryActive] = useState(true);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userAccountReplace = process.env.REACT_APP_HOMEACCOUNT_STRING_REPLACE || "";
  const userAccount = account?.homeAccountId.split(".")[0].replace(userAccountReplace, "");

  const handleSave = async () => {
    const add: SubscriptionPlanModel = plan;
    add.dateCreated = new Date();
    add.createdBy = userAccount || undefined;
    add.isActive = plan.isActive === undefined ? false : plan.isActive;

    try {
      isLoading(true);
      await SubscriptionService.addPlan(add);
    }
    catch {
      isLoading(false);
      toast.success("Plan Failed to Added");
    }
    finally {
      isLoading(false);
      toast.success("Plan Added");
      backToView();
    }
  }

  const handleBillingCycle = (cycle: number) => {
    if (cycle === BillCycle.Forever) {
      setBillEveryActive(true);
      if (billEvery !== "Month/s" && billEvery !== "Year/s") {
        setBillEvery("");
      }
      setPlan({ ...plan, billingCycle: cycle });
      setDisableInt(false);
    }
    else if (cycle === BillCycle.Onceoff) {
      setBillEveryActive(true);
      setBillEvery("Once off");
      setPlan({ ...plan, billingCycle: cycle });
      setDisableInt(true);
    }
    else if (cycle === BillCycle.Set) {
      setBillEveryActive(false);
      setBillEvery(plan.billInterval === BillInterval.Month ? "Month/s" : plan.billInterval === BillInterval.Annual ? "Year/s" : "");
      setPlan({ ...plan, billingCycle: cycle });
      setDisableInt(false);
    }

  }

  const handleBillingInt = (int: number) => {
    if (int === BillInterval.Month) {
      setBillEveryActive(false);
      setBillEvery("Month/s");
      setPlan({ ...plan, billInterval: int });
    }
    else if (int === BillInterval.Annual) {
      setBillEveryActive(false);
      setBillEvery("Year/s");
      setPlan({ ...plan, billInterval: int });
    }
  }

  return (
    <>
      <LoadingBackdrop showBackdrop={loading} />
      <Grid container>
        <Grid xs={0.5} sx={{ paddingBottom: "10px", minWidth: "80px" }}>
          <Tooltip title="Back To Users">
            <IconButton onClick={backToView}><ArrowBackIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={6} sx={{ minWidth: "700px" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField value={plan.planCode}
                onChange={(ev) => { setPlan({ ...plan, planCode: ev.target.value }); }}
                id="code"
                label="Code"
                size="small"
                sx={{ width: "98%" }}
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
            <Grid xs={6}>
              <TextField
                onChange={(ev) => { setPlan({ ...plan, amount: Number(ev.target.value) }); }}
                value={plan.amount}
                id="name"
                InputProps={{
                  startAdornment: <InputAdornment position="start" >R</InputAdornment>,
                }}
                placeholder="00.00"
                label="Cost of Plan"
                size="small"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Billing Cycle</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(ev) => handleBillingCycle(Number(ev.target.value))}>
                  <FormControlLabel value={BillCycle.Onceoff} control={<Radio />} label="Once Off" />
                  <FormControlLabel value={BillCycle.Forever} control={<Radio />} label="Forever" />
                  <FormControlLabel value={BillCycle.Set} control={<Radio />} label="Set" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl disabled={disableInt}>
                <FormLabel id="demo-row-radio-buttons-group-label">Type of Plan</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(ev) => handleBillingInt(Number(ev.target.value))}>
                  <FormControlLabel value={BillInterval.Month} control={<Radio />} label="Monthly" />
                  <FormControlLabel value={BillInterval.Annual} control={<Radio />} label="Annual" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={6}>
              {billEvery === "Once off" ? (<Typography>Will be changed once Immediately</Typography>) : (
                <TextField value={plan.billEvery}
                  onChange={(ev) => { setPlan({ ...plan, billEvery: Number(ev.target.value) }); }}
                  id="billedEvery"
                  disabled={billEveryActive}
                  label="Bill Every"
                  size="small"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" >
                      {billEvery}
                    </InputAdornment>,
                  }}
                  type="number"
                  sx={{ width: "98%" }}
                  variant="outlined" />
              )}
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Trial Days"
                size="small"
                type="text"
                select
                fullWidth
                variant="outlined"
                value={plan.trialDays}
                onChange={(ev) => setPlan({ ...plan, trialDays: Number(ev.target.value) })}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={3}>
              <FormControlLabel
                control={<Checkbox checked={plan.isActive}
                  onClick={() => setPlan({ ...plan, isActive: !plan.isActive })} />}
                label="Is Active" />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid xs={12}>
              <TextField value={plan.description}
                onChange={(ev) => { setPlan({ ...plan, description: ev.target.value }); }}
                multiline
                id="name"
                label="Description"
                size="small"
                type="text"
                fullWidth
                variant="outlined" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <div className="hr-line" />
              <Button variant="contained" onClick={() => handleSave()} className="form-add-btn">Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  )
};


export default AddPlan;